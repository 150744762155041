// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';
import _findIndex from 'lodash/findIndex';

// Components
import Player from '../../presentationals/player';

// Actions
import {
  play,
  pause,
  seek,
  close,
  goToNextTrack,
  goToPrevTrack,
} from '../../../store/actions/PlayerActions';
import { triggerAutosaveCheck } from '../../../store/actions/SidePanelActions';

// Constants
import * as states from '../../../store/constants/PlayerStates';

// =============================
// Component
// =============================

function mapStateToProps({ player }) {
  const nextTrackIndex = _findIndex(
    player.trackListing,
    track => track && track.audiofile.original,
    player.contextPosition + 1,
  );

  return {
    opened: player.isOpened,
    current: player.current,
    hasNext: nextTrackIndex !== -1,
    getCurrentTime: player.getCurrentTime,
    isPlaying: player.playerState === states.PLAYING,
    isPaused: player.playerState === states.PAUSED,
    isEnded: player.playerState === states.ENDED,
  };
}

export default connect(mapStateToProps, {
  play,
  pause,
  seek,
  close,
  goToNextTrack,
  goToPrevTrack,
  triggerPanelAutosaveCheck: triggerAutosaveCheck,
})(Player);
