// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// Config
import { i18n } from '../config/i18n';

// =============================
// Helpers
// =============================

export default function determineError(err) {
  if (!err.response) return i18n.t('errors:global.network_error');

  const { data } = err.response;
  switch (true) {
    case data.key === 'validation_error' && data.message.includes('transaction number'):
      return i18n.t('errors:global.transaction_ongoing');

    case data.key === 'ingestion_ongoing':
      return i18n.t('errors:global.ingestion_ongoing');

    case data.key === 'invalid_file_name':
      return i18n.t('errors:global.invalid_file_name');

    case data.key === 'database_error':
      return i18n.t('errors:global.database_error');

    case data.key === 'job_worker_error':
      return i18n.t('errors:global.job_worker_error');

    case data.key === 'api_not_found':
      return i18n.t('errors:global.api_not_found');

    case data.key === 'invalid_token':
      return i18n.t('errors:global.invalid_token_error');

    case data.key === 'invalid_role':
      return i18n.t('errors:global.invalid_role_error');

    case data.key === 'inactive_subscription':
      return i18n.t('errors:global.inactive_subscription');

    case data.key === 'permission_needed':
      return i18n.t('errors:global.permission_needed');

    case data.key === 'config_not_found':
      return i18n.t('errors:global.config_not_found');

    case data.key === 'maintenance_ongoing':
      return i18n.t('errors:global.maintenance_ongoing');

    default:
      return i18n.t('errors:global.default_error');
  }
}

export function getModoActivateError(config) {
  const base = i18n.t('errors:modo.general.activation_failed');
  const errors = [];

  const translations = {
    website_title: i18n.t('errors:modo.general.activation_website_title'),
    'url_config.website_url': i18n.t('errors:modo.general.activation_website_url'),
    default_language: i18n.t('errors:modo.general.activation_default_language'),
    languages: i18n.t('errors:modo.general.activation_languages'),
  };

  Object.keys(translations).forEach((key) => {
    if (_isEmpty(_get(config, key))) {
      errors.push(_get(translations, key));
    }
  });

  if (errors.length === 0) return null;
  return `${base} ${errors.join(', ')}`;
}
