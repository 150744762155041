// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const OtherCustomisations = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 297 300"
    {...rest}
  >
    <path
      d="M197 160l-127 127c-38,38 -95,-19 -58,-57l128 -128c-7,-27 1,-57 21,-78 45,-44 117,-24 135,35l1 4 -57 0 -21 18 21 22 57 0 -2 4c-15,42 -54,64 -98,53zm83 -46l-44 0 -34 -33 34 -29 45 0c-20,-43 -78,-54 -112,-20 -19,19 -25,47 -17,72l1 1 -133 133c-27,27 14,69 42,41l132 -132 2 1c32,10 68,-4 84,-34zm-229 154c-13,13 -33,-6 -20,-19 13,-13 33,7 20,19z"
    />
  </Svg>
));

OtherCustomisations.displayName = 'OtherCustomisations';

OtherCustomisations.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

OtherCustomisations.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '1.4rem',
};

export default withTheme(OtherCustomisations);
