// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Chip, Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const PreviewTagsWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const PreviewTagsTitle = styled.div`
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const TagCategoryWrapper = styled.div`
  color: #b2b2b2;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 2.1rem;
  padding-bottom: 0.5rem;
  width: 100%;

  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

export const TagCategoryHeader = styled.div`
  border-bottom: 0.1rem solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  line-height: 2.8rem;

  ${({ opened }) => opened && css`
    margin-bottom: 2rem;
  `};
`;

export const TagList = styled.div`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
`;

export const TagCategoryArrow = styled(Icons.ArrowDropdown).attrs(({ opened }) => ({
  dir: opened ? 'north' : 'south',
  fill: '#b2b2b2',
  width: '1rem',
}))``;

export const TagCategoryChildrenWrapper = styled.div`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  margin-left: 1.2rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ isRoot }) => isRoot && css`
    color: #191919;
  `};
`;

export const CountWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 2rem;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  margin-left: 0.5rem;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 2rem;
  justify-content: center;
  padding: 0 0.8rem;
`;

export const TagSubCategoryWrapper = styled.div`
  border-bottom: 0.1rem solid #e5e5e5;
  color: #b2b2b2;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.7rem;
  padding-bottom: 0.5rem;
  width: 100%;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  ${({ opened }) => opened && css`
    margin-bottom: 2rem;
  `};
`;

export const TagSubCategoryArrow = styled(Icons.ArrowDropdown).attrs(({ opened }) => ({
  dir: opened ? 'north' : 'south',
  fill: '#b2b2b2',
  width: '1rem',
}))``;

export const TagSubCategoryChildrenWrapper = styled.div`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
`;

function getFillVariant(status) {
  switch (status) {
    case 0:
      return 'outline';

    case 1:
      return 'faded';

    case 2:
    default:
      return 'plain';
  }
}

export const StyledChip = styled(Chip).attrs(({ color, status }) => ({
  color,
  fillVariant: getFillVariant(status),
  size: 'small',
}))``;
