// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Components
import MetaUploader from '../../presentationals/metaUploader';

// =============================
// Component
// =============================

function mapStateToProps({ user }) {
  return {
    userToken: user.token,
  };
}

export default connect(mapStateToProps)(MetaUploader);
