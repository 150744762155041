// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Div } from '@mewo/components';
import trackplaceholder from '@mewo/components/assets/images/placeholders/track_500x500.png?webp';
import _get from 'lodash/get';
import _difference from 'lodash/difference';
import _isNumber from 'lodash/isNumber';

// Config
import { withTranslation } from '../../../../config/i18n';

// Components
import Autosave from '../../../other/autosave';
import DraggableFields from '../../../other/draggableFields';
import ApiSelectInput from '../../../containers/inputs/apiSelectInput';
import MetaUploader from '../../../containers/metaUploader';

// Actions
import { triggerAutosaveCheck } from '../../../../store/actions/SidePanelActions';
import {
  pause as pauseBase,
  play as playBase,
  setCurrentTrack as setCurrentTrackBase,
} from '../../../../store/actions/PlayerActions';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getGenericDocCoverUrl, presentDuration } from '../../../../helpers/meta-common';
import { getDocName, getNameWithFallback, getSearchName } from '../../../../helpers/doc-names';
import { getApiUrl } from '../../../../helpers/misc';

// Constants
import * as spts from '../../../../store/constants/SidePanelTypes';
import * as states from '../../../../store/constants/PlayerStates';
import * as playerCtx from '../../../../store/constants/PlayerContexts';

// Styles
import {
  MiscHelper,
  SectionBox,
  GenericText,
  InputLabelHelper,
  GenericLoaderWrapper,
  LineLoaderWrapper,
  LineLoader,
  LineInnerLoader,
} from '../common.styles';
import {
  Wrapper,
  StyledForm,
  TrackNumber,
  InnerWrapper,
  OuterGridWrapper,
  GridWrapper,
  InvertOrderButton,
  CoverWrapper,
  Controls,
  PauseIcon,
  PlayIcon,
  Cover,
  Title,
  DisplayArtists,
  DisplayArtist,
  Version,
  Duration,
  Tenant,
  DragIcon,
  Actions,
  DuplicateButton,
  AgentsStatusWrapper,
  AgentsStatusChip,
  AgentsStatusTooltip,
  DeleteButton,
} from './styles';

// =============================
// Component
// =============================

class DocumentTracks extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isModifying: PropTypes.bool.isRequired,
    tenantId: PropTypes.string.isRequired,
    entity: PropTypes.oneOf(['album', 'playlist', 'brief']).isRequired,
    parentDocument: PropTypes.oneOfType([
      pth.playlist,
      pth.brief,
      pth.album,
    ]).isRequired,
    data: PropTypes.arrayOf(pth.smallTrack).isRequired,
    documentRights: PropTypes.shape({
      canWrite: PropTypes.bool.isRequired,
      isOwner: PropTypes.bool.isRequired,
      providerId: PropTypes.string,
    }).isRequired,
    contextId: PropTypes.string,
    contextName: PropTypes.string,
    contextPosition: PropTypes.number,
    isPlaying: PropTypes.bool.isRequired,
    setCurrentTrack: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    modifyAdditional: PropTypes.func.isRequired,
    relationsNotification: PropTypes.shape({
      id: PropTypes.string,
      progress: PropTypes.number,
      isAudioIngestion: PropTypes.bool,
    }),
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contextId: null,
    contextName: null,
    contextPosition: null,
    relationsNotification: null,
  };

  pickFormData = (data, init = false) => {
    if (init) return { ids: data.map(({ id }) => id) };
    return data;
  };

  openDocumentPanel = (entity, doc) => {
    const { triggerPanelAutosaveCheck } = this.props;

    triggerPanelAutosaveCheck('open', [spts.metaMapping[entity], doc.id]);
  };

  getPlayerContext = () => {
    const { entity } = this.props;

    switch (entity) {
      case 'album':
        return playerCtx.ALBUM;

      case 'brief':
        return playerCtx.BRIEF;

      case 'playlist':
      default:
        return playerCtx.PLAYLIST;
    }
  };

  getPlayerIsCurrent = (trackIndex) => {
    const {
      parentDocument,
      contextId,
      contextName,
      contextPosition,
    } = this.props;

    return contextId === parentDocument.id
      && contextName === this.getPlayerContext()
      && contextPosition === trackIndex;
  };

  handlePlayClick = (track, trackIndex) => {
    const {
      parentDocument,
      data,
      setCurrentTrack,
      play,
    } = this.props;

    if (this.getPlayerIsCurrent(trackIndex)) {
      return play();
    }

    return setCurrentTrack(
      track,
      this.getPlayerContext(),
      parentDocument.id,
      trackIndex,
      data,
    );
  };

  renderPlayerControls = (track, trackIndex) => {
    const { isPlaying, pause } = this.props;

    if (!track.audiofile.original) return null;

    const isCurrent = this.getPlayerIsCurrent(trackIndex);

    return (
      <Controls>
        {(isPlaying && isCurrent)
          ? <PauseIcon onClick={pause} />
          : <PlayIcon onClick={() => this.handlePlayClick(track, trackIndex)} />}
      </Controls>
    );
  };

  renderTrackLine = (track, trackIndex, dragHandleProps) => {
    const {
      isLoading,
      isModifying,
      tenantId,
      entity,
      data,
      documentRights,
      parentDocument,
      modifyAdditional,
      relationsNotification,
      i18n: { language },
      t,
    } = this.props;

    const canWrite = documentRights.canWrite || entity === 'brief';

    const isTrackOwner = track.tenant.id === tenantId;

    const displayAgentsStatus = entity === 'playlist'
      && documentRights.isOwner
      && parentDocument.public
      && !!parentDocument.agents.length;

    let agentsStatus = 0;

    if (displayAgentsStatus && isTrackOwner) {
      const agentsDiff = !!_difference(parentDocument.agents, track.agents).length;

      if (track.public) {
        const documentAgentsCount = parentDocument.agents.length;

        if (documentAgentsCount - agentsDiff < documentAgentsCount) {
          agentsStatus = 1;
        } else if (!agentsDiff) {
          agentsStatus = 2;
        }
      }
    }

    const formDisabled = !canWrite
      || isLoading
      || isModifying
      || !!relationsNotification;

    const tenantName = track.tenant.company_name
      || `${track.tenant.first_name} ${track.tenant.last_name}`;
    const versionName = track.version
      ? getNameWithFallback(track.version.names, language)
      : null;

    // - For albums we pass by the tracks PUT endpoint
    // to modify the track directly
    // - For playlists & briefs we pass by it's own tracks endpoint
    // to remove from the list of tracks
    const deleteFnc = entity === 'album'
      ? () => modifyAdditional(
        `/meta/tracks/${track.id}`,
        'put',
        { album: null },
        'tracks',
        false,
        'tracks',
      ) : () => modifyAdditional(
        'tracks',
        'delete',
        { ids: [track.id] },
        'tracks',
      );

    return (
      <InnerWrapper>
        {/* No re-order if not owner of the parent document */}
        {documentRights.isOwner && (
          <div {...dragHandleProps}>
            <DragIcon />
          </div>
        )}
        <CoverWrapper>
          {this.renderPlayerControls(track, trackIndex)}
          <Cover
            placeholder={trackplaceholder}
            cover={getGenericDocCoverUrl(track.album?.image, 'small')}
          />
        </CoverWrapper>
        <OuterGridWrapper>
          <GridWrapper>
            <Title
              title={track.display_title || track.title}
              onClick={() => this.openDocumentPanel('track', track)}
            >
              {track.display_title || track.title}
            </Title>
            <Version title={versionName || ''}>
              {versionName}
            </Version>
            <Duration>
              {track.duration ? presentDuration(track.duration) : null}
            </Duration>
            <Tenant title={tenantName}>{tenantName}</Tenant>
          </GridWrapper>
          {!!track.display_artists.length && (
            <DisplayArtists>
              {/* Only show 10 display artists maximum */}
              {track.display_artists.slice(0, 10).map((displayArtist, i) => (
                <DisplayArtist
                  key={`${displayArtist.artist.id}-${displayArtist.alias || displayArtist.artist.full_name}`}
                  title={displayArtist.alias || displayArtist.artist.full_name}
                  onClick={() => this.openDocumentPanel('artist', displayArtist.artist)}
                >
                  {i > 0 && (
                    <Fragment>,&nbsp;</Fragment>
                  )}
                  {displayArtist.alias || displayArtist.artist.full_name}
                </DisplayArtist>
              ))}
            </DisplayArtists>
          )}
        </OuterGridWrapper>
        <Actions>
          {/* Allow duplicate only on albums for parent document owner */}
          {entity === 'album' && documentRights.isOwner && data.length < 500 && (
            <DuplicateButton
              type="button"
              onClick={() => modifyAdditional(
                `/meta/tracks/${track.id}/duplicate`,
                'post',
                {},
                'tracks',
                false,
                'tracks',
              )}
              disabled={formDisabled}
            >
              {t(`pages:meta.${entity}s.panel.duplicate_track`)}
            </DuplicateButton>
          )}
          {displayAgentsStatus && (
            <AgentsStatusWrapper>
              {/* Show tracks agents status on playlists for parent document owner */}
              {/* Or show invisible placeholder to conserve alignment */}
              {isTrackOwner ? (
                <AgentsStatusTooltip
                  content={t(`pages:meta.${entity}s.panel.agents_status_tooltip`)}
                >
                  <AgentsStatusChip
                    status={agentsStatus}
                    name={t(`pages:meta.${entity}s.panel.agents_status`)}
                  />
                </AgentsStatusTooltip>
              ) : (
                <AgentsStatusChip
                  hide
                  status={agentsStatus}
                  name={t(`pages:meta.${entity}s.panel.agents_status`)}
                />
              )}
            </AgentsStatusWrapper>
          )}
          {/*
            User can delete a track from list if
            - If the parent document is playlist and he owns the document
            - He is an agent with write rights of the parent document
            - If the parent document is a brief, tracks that are shown can be deleted
          */}
          {(
            (documentRights.canWrite && entity === 'playlist')
            || (!documentRights.isOwner && entity === 'brief')
          ) && (
            <DeleteButton
              type="button"
              onClick={deleteFnc}
              disabled={isLoading || isModifying}
            >
              {t('common:form.remove')}
            </DeleteButton>
          )}
        </Actions>
      </InnerWrapper>
    );
  };

  getTrackNumber = (track, index) => {
    const { entity } = this.props;

    if (entity === 'album' && _isNumber(track.track_number)) {
      return `#${track.track_number}`;
    }

    return `#${index + 1}`;
  };

  render() {
    const {
      i18n: { language },
      isLoading,
      isModifying,
      entity,
      parentDocument,
      data,
      documentRights,
      modifyAdditional,
      relationsNotification,
      t,
    } = this.props;

    const canWrite = documentRights.canWrite || entity === 'brief';

    // Show no tracks when
    // - User cannot write AND entity is not a brief
    // - AND Tracks are not being loaded
    // - AND There are no tracks
    if (!canWrite && !isLoading && !data.length) {
      return (
        <SectionBox>
          <GenericText>
            {t(`pages:meta.${entity}s.panel.no_tracks`)}
          </GenericText>
        </SectionBox>
      );
    }

    const formDisabled = !canWrite
      || isLoading
      || isModifying
      || !!relationsNotification;

    // - For albums we pass by the tracks PUT endpoint
    // to modify the track directly
    // - For playlists & briefs we pass by it's own tracks endpoint
    // to add to the list of tracks
    const addFnc = entity === 'album'
      ? ({ value }) => modifyAdditional(
        `/meta/tracks/${value}`,
        'put',
        { album: parentDocument.id },
        'tracks',
        false,
        'tracks',
      ) : ({ value }) => modifyAdditional(
        'tracks',
        'post',
        { ids: [...data.map(({ id }) => id), value] },
        'tracks',
      );

    let tracksASIQuery = '';

    if (documentRights.providerId) {
      tracksASIQuery = entity === 'album'
        ? `&provider_id=${documentRights.providerId}`
        : `&as_tenant_id=${documentRights.providerId}`;
    } else if (entity !== 'album') {
      tracksASIQuery = '&all_documents=1';
    }

    return (
      <Fragment>
        {/* No re-order if not owner of the parent document */}
        {(documentRights.isOwner && data.length > 1) && (
          <InvertOrderButton
            disabled={formDisabled}
            onClick={() => modifyAdditional(
              'tracks/reorder',
              'post',
              { ids: data.map(({ id }) => id).reverse() },
              'tracks',
            )}
          >
            {t(`pages:meta.${entity}s.panel.invert_tracks_order`)}
          </InvertOrderButton>
        )}

        {/** Maximum for this item is always 500 items */}
        {canWrite && (
          <SectionBox>
            {data.length < 500 && (
              <ApiSelectInput
                name="tracks_adder"
                label={t(`pages:meta.${entity}s.panel.add_track`)}
                entity="meta/tracks"
                type="text"
                getEntityLabel={doc => getDocName('track')(doc, language)}
                getSearchEntityLabel={getSearchName('track')}
                listAdditionalQuery={tracksASIQuery}
                showResetBtn={false}
                onChange={addFnc}
                disabled={isLoading || isModifying}
                // Creation only for albums
                onTheFlyEnabled={entity === 'album' && documentRights.isOwner}
                onTheFlyField="title"
              />
            )}
            {data.length > 500 && (
              <MiscHelper mt="1.2rem" isError>
                {t(`pages:meta.${entity}s.panel.too_many_tracks`)}
              </MiscHelper>
            )}
            {/* In album context, a tenant cannot create a track for a provider */}
            {/* In playlist & brief context,
            a tenant can add his own tracks to the provider playlist */}
            {data.length < 500 && (documentRights.isOwner || entity !== 'album') && (
              <Div mt="2rem">
                <InputLabelHelper>
                  {t(`pages:meta.${entity}s.panel.or_upload_audiofiles`)}
                </InputLabelHelper>
                {(!!relationsNotification && relationsNotification.isAudioIngestion) ? (
                  <GenericLoaderWrapper mt="2rem">
                    {t(`pages:meta.${entity}s.panel.is_ingesting_audiofiles`)}
                    <LineLoaderWrapper>
                      <LineLoader>
                        <LineInnerLoader progress={relationsNotification.progress} />
                      </LineLoader>
                    </LineLoaderWrapper>
                  </GenericLoaderWrapper>
                ) : (
                  <MetaUploader
                    context="tracks"
                    disabled={formDisabled}
                    height="10rem"
                    endpoint={getApiUrl(`meta/uploads/binaries/${entity}/${parentDocument.id}`)}
                    maxNumberOfFiles={500 - data.length}
                    onUploadComplete={() => modifyAdditional(
                      'tracks/ingest',
                      'post',
                      {},
                      'ingest',
                    )}
                  />
                )}
              </Div>
            )}
          </SectionBox>
        )}

        {/* No re-order if not owner of the parent document */}
        {!documentRights.isOwner && data.map((track, index) => (
          <Wrapper key={track.id}>
            <TrackNumber>
              {this.getTrackNumber(track, index)}
            </TrackNumber>
            {this.renderTrackLine(track, index)}
          </Wrapper>
        ))}

        {/*
          We use form here because we want the reorder by drag & drop
          to take effect immediately and not wait for api response.
          This is an exception in the usage of modifyAdditional.
        */}
        {documentRights.isOwner && (
          <Form
            onSubmit={() => {}}
            mutators={{ ...arrayMutators }}
            initialValues={{ data }}
            subscription={{ values: true }}
          >
            {({ values }) => (
              <Fragment>
                <Autosave
                  debounce={100}
                  save={nextValues => modifyAdditional(
                    'tracks/reorder',
                    'post',
                    { ids: nextValues.data.map(({ id }) => id) },
                    'tracks',
                  )}
                />

                <DraggableFields name="data" disabled={formDisabled}>
                  {({ fieldName, index, innerRef, draggableProps, dragHandleProps }) => {
                    const track = _get(values, fieldName);

                    return (
                      <StyledForm
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                        key={index} // eslint-disable-line react/no-array-index-key
                        ref={innerRef}
                        {...draggableProps}
                      >
                        <TrackNumber>
                          {this.getTrackNumber(track, index)}
                        </TrackNumber>
                        {this.renderTrackLine(track, index, dragHandleProps)}
                      </StyledForm>
                    );
                  }}
                </DraggableFields>
              </Fragment>
            )}
          </Form>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps({ player }) {
  return {
    contextId: player.contextId,
    contextName: player.contextName,
    contextPosition: player.contextPosition,
    isPlaying: player.playerState === states.PLAYING,
  };
}

export default compose(
  connect(mapStateToProps, {
    setCurrentTrack: setCurrentTrackBase,
    play: playBase,
    pause: pauseBase,
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
  }),
  withTranslation(['pages', 'common']),
)(DocumentTracks);
