// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import withGoogle from '../../hoc/withGoogle';

// =============================
// Component
// =============================

class WithAddress extends Component {
  static propTypes = {
    placeId: PropTypes.string,
    children: PropTypes.func.isRequired,
    /** withGoogle */
    googleApisLoaded: PropTypes.bool.isRequired,
    googleGeocoding: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    placeId: undefined,
    googleGeocoding: undefined,
  };

  state = {
    reversedGeocodingAddress: null,
  };

  componentDidMount() {
    const { googleApisLoaded } = this.props;
    const { reversedGeocodingAddress } = this.state;

    if (googleApisLoaded && !reversedGeocodingAddress) {
      this.reverseAddressGeocoding();
    }
  }

  componentDidUpdate(prevProps) {
    const { googleApisLoaded } = this.props;
    const { googleApisLoaded: googleApisWasLoaded } = prevProps;
    const { reversedGeocodingAddress } = this.state;

    if (!googleApisWasLoaded && googleApisLoaded && !reversedGeocodingAddress) {
      this.reverseAddressGeocoding();
    }
  }

  reverseAddressGeocoding() {
    const { placeId, googleGeocoding } = this.props;

    if (!placeId) return;

    googleGeocoding.geocode({ placeId }, (result, status) => {
      if (status === 'OK') {
        this.setState({
          reversedGeocodingAddress: result[0].formatted_address,
        });
      }
    });
  }

  render() {
    const { children } = this.props;
    const { reversedGeocodingAddress } = this.state;

    return children(reversedGeocodingAddress);
  }
}

export default withGoogle(WithAddress);
