// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { alpha } from '@material-ui/core';
import { AppThemeProvider, Icons, DropdownContext } from '@mewo/components';
import Favicon from 'react-favicon';
import { withRouter } from 'next/router';
import _some from 'lodash/some';
import _get from 'lodash/get';
import Cookies from 'js-cookie';

import { i18n, withTranslation } from '../config/i18n';

import {
  initializeApp,
  setLocalPreferences,
  setLocalMetaColumnsPreferences,
} from '../store/actions/CoreActions';
import { getUserData, logout } from '../store/actions/AuthActions';
import { checkUserNotifications } from '../store/actions/PagesActions';
import { modifyConfigPlan } from '../store/actions/ModoActions';

import AppShell from '../components/presentationals/appShell';
import Link from '../components/other/link';
import Loader from '../components/containers/loader';
import PremiumModal from '../components/containers/premiumModal';
import NotificationsList from '../components/containers/notificationsList';
import SidePanel from '../components/containers/sidePanel';
import RouterModal from '../components/containers/routerModal';
import MewoDevTools from '../components/presentationals/devTools';
import PlayerCore from '../components/other/playerCore';
import Player from '../components/containers/player';
import FilterBar from '../components/containers/filterBar';
import dashboardMenu from '../components/other/dashboardMenu';

import locales from '../config/locales';

import transformNotifications from '../helpers/notifications';
import { buildTextNode } from '../helpers/locales';
import docs from '../helpers/docs';
import * as pth from '../helpers/proptypes';

import { GoToNotificationManager } from './styles';
import { FullSize, GlobalStyle } from '../components/layouts/global.styles';

// =============================
// Page
// =============================

class Layout extends Component {
  static propTypes = {
    router: PropTypes.shape({
      asPath: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      query: PropTypes.object,
    }).isRequired,
    ready: PropTypes.bool.isRequired,
    initializeApp: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired,
    checkUserNotifications: PropTypes.func.isRequired,
    setLocalPreferences: PropTypes.func.isRequired,
    setLocalMetaColumnsPreferences: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    userData: pth.user,
    userSubscriptions: pth.userSubscriptions.isRequired,
    latestNotifications: PropTypes.arrayOf(pth.userNotification).isRequired,
    modoConfigId: PropTypes.string,
    modoConfigData: pth.modoConfig.isRequired,
    modoConfigActive: PropTypes.bool.isRequired,
    modifyConfigPlan: PropTypes.func.isRequired,
    playerIsOpened: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userData: {},
    modoConfigId: null,
  };

  state = {
    showLoader: true,
  };

  componentDidMount() {
    const { router } = this.props;

    const token = Cookies.get('xAuth');
    const adminToken = _get(router, 'query.adminToken');

    // eslint-disable-next-line react/destructuring-assignment
    this.props.setLocalPreferences();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setLocalMetaColumnsPreferences();

    if (adminToken || token) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.getUserData(adminToken || token, !!adminToken);
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.initializeApp();
    }
  }

  componentDidUpdate(prevProps) {
    const { ready } = this.props;

    if (ready && prevProps.ready !== ready) {
      setTimeout(() => this.setState({ showLoader: false }), 500);
    }
  }

  getWrappedChild = () => {
    const {
      children,
      router,
      isAdmin,
      userData,
      userSubscriptions,
      latestNotifications,
      modoConfigId,
      modoConfigData,
      modoConfigActive,
      playerIsOpened,
      t,
    } = this.props;

    const noSubscriptions = !_get(userSubscriptions, 'meta.active', false)
      && !_get(userSubscriptions, 'modo.active', false)
      && !_get(userSubscriptions, 'maia.active', false);

    // Meta
    const withMetaHeader = _some(['/meta/'], e => router.asPath.indexOf(e) >= 0);

    const metaHeaderConfig = withMetaHeader ? {
      activePage: router.asPath,
    } : null;

    // Modo
    // NOTE: We could check if there is an object id in path too
    const withModoMenu = _some(['/modo/'], e => router.asPath.indexOf(e) >= 0);

    const modoMenuConfig = withModoMenu && modoConfigId
      ? {
        modoId: modoConfigId,
        activePage: router.asPath,
        modoData: modoConfigData,
        lang: {
          ModoStatus: {
            status: t('components:modo_menu.status'),
            active: t('components:modo_menu.active'),
            inactive: t('components:modo_menu.inactive'),
          },
        },
        // eslint-disable-next-line react/destructuring-assignment
        modifyModoPlan: this.props.modifyConfigPlan,
        status: modoConfigActive,
        activationTooltip: buildTextNode(
          t('components:modo_menu.inactive_tooltip'),
          '/modo/[modoId]/general',
          `/modo/${modoConfigId}/general`,
        ),
      }
      : null;

    // prettier-ignore
    return (
      <Fragment>
        <AppShell
          faq={(
            <Link href={docs.website} target="_blank" rel="noopener noreferrer">
              <Icons.Faq />
            </Link>
          )}
          logo={(
            <Link href="/dashboard" noHover>
              <Icons.LgApp />
            </Link>
          )}
          HeaderDropdownProps={{
            // eslint-disable-next-line react/destructuring-assignment
            links: dashboardMenu(this.props.logout, noSubscriptions),
            activePage: router.asPath,
            user: userData,
          }}
          footer={`\u00a9 ${new Date().getFullYear()} Mewo. ${t('common:footer_content')}`}
          NotificationManagerProps={{
            renderGotoManagerLink: cldr => (
              <DropdownContext.Consumer>
                {({ closeDropdown }) => (
                  <GoToNotificationManager
                    href="/dashboard/notifications"
                    noHover
                    onClick={closeDropdown}
                  >
                    {cldr}
                  </GoToNotificationManager>
                )}
              </DropdownContext.Consumer>
            ),
            lang: {
              notifications: t('components:notification_manager.notifications'),
              markAllRead: t('components:notification_manager.mark_all_read'),
              seeMore: t('components:notification_manager.see_more'),
              youDontHaveNotification: t('components:notification_manager.no_notifications'),
            },
            notifications: latestNotifications,
            markAllRead: () => {
              // eslint-disable-next-line react/destructuring-assignment
              this.props.checkUserNotifications();
            },
          }}
          isAdmin={isAdmin}
          MetaHeaderProps={metaHeaderConfig}
          ModoMenuProps={modoMenuConfig}
          playerIsOpened={playerIsOpened}
        >
          {children}
        </AppShell>
        <PremiumModal />
      </Fragment>
    );
  };

  render() {
    const { children, router, isLogged } = this.props;
    const { showLoader } = this.state;

    // Shell only exists when user is logged, this prevents blinking
    const hasShell = _some(['/dashboard', '/meta', '/modo'], e => router.asPath.indexOf(e) >= 0);

    // Player & Player core only exists on Meta
    const isMeta = router.asPath.indexOf('/meta') >= 0;
    // Filter bar only exists on Meta tracks
    const isMetaTracks = router.asPath.indexOf('/meta/tracks') >= 0;

    // NOTE: All colors needed by the Components project of used components within Admin-v2
    const themeOverride = {
      colors: {
        background: '#f4f4f4',
        text: '#7f7f7f',
        primary: '#39f',
        primaryText: '#ffffff',
        secondary: '#ffffff',
        secondaryText: '#000000',
        boxShadow: 'rgba(0, 0, 0, 0.1)',
        dropdown: {
          background: '#ffffff',
          text: '#7f7f7f',
        },
        chip: {
          background: '#ffffff',
          text: '#7f7f7f',
          outlineBackground: '#7f7f7f',
          outlineText: '#7f7f7f',
          backgroundAlternate: '#7f7f7f',
          textAlternate: '#ffffff',
          outlineBackgroundAlternate: '#7f7f7f',
          outlineTextAlternate: '#7f7f7f',
        },
        input: {
          background: '#f4f4f4', // Used for filter bar input backgrounds
          text: '#333333',
          helpers: { // For helper icons (reset, mandatory...)
            background: '#7f7f7f',
            text: '#ffffff',
          },
        },
        notification: {
          background: '#ffffff',
          text: '#7f7f7f',
          primary: '#39f',
          primaryText: '#ffffff',
          boxShadow: 'rgba(0, 0, 0, 0.1)',
        },
        tooltip: {
          background: '#ffffff',
          text: '#7f7f7f',
          primary: '#39f',
          primaryText: '#ffffff',
          boxShadow: 'rgba(0, 0, 0, 0.1)',
        },
        type: {
          cancel: '#828282',
          error: '#e5414f',
          success: '#44bd32',
          warning: '#fa9917',
        },
        black: '#000000',
        white: '#ffffff',
        // The values of filterbar are used to override colors
        // within context of filterBar (search input, lyrics input)
        // Use them through withCustomTheme
        filterBar: {
          background: '#ffffff',
          text: '#7f7f7f',
          primary: '#39f',
          primaryText: '#ffffff',
          input: {
            background: '#f4f4f4', // Used for filter bar input backgrounds
            text: '#333333',
            helpers: { // For helper icons (reset, mandatory...)
              background: '#7f7f7f',
              text: '#ffffff',
            },
          },
        },
      },
    };

    const muiThemeOverride = {
      // https://material-ui.com/customization/palette/
      palette: {
        primary: {
          main: themeOverride.colors.primary,
          contrastText: themeOverride.colors.primaryText,
        },
      },
      shadows: Array(25).fill('none'), // Remove every box-shadow on every material ui components
      shape: {
        borderRadius: 5,
      },
      overrides: {
        MuiPickersCalendarHeader: {
          iconButton: {
            color: themeOverride.colors.dropdown.text,
            '&:disabled': {
              color: alpha(themeOverride.colors.dropdown.text, 0.3),
            },
            '&:hover': {
              backgroundColor: alpha(themeOverride.colors.dropdown.text, 0.1),
            },
          },
          switchHeader: {
            color: themeOverride.colors.dropdown.text,
          },

        },
        MuiPickersDay: {
          day: {
            color: themeOverride.colors.dropdown.text,
            '&:hover': {
              backgroundColor: alpha(themeOverride.colors.dropdown.text, 0.1),
            },
          },
          daySelected: {
            backgroundColor: themeOverride.colors.primary,
            color: themeOverride.colors.primaryText,
          },
          dayDisabled: {
            color: alpha(themeOverride.colors.dropdown.text, 0.3),
          },
          current: {
            color: themeOverride.colors.primary,
          },
        },
      },
      props: {
        MuiIconButton: {
          disableRipple: true,
        },
      },
    };

    return (
      <Fragment>
        <AppThemeProvider
          locale={i18n.language || locales.defaultLanguage}
          themeOverride={themeOverride}
          muiThemeOverride={muiThemeOverride}
        >
          <GlobalStyle />
          {showLoader && <Loader ready={!showLoader} type="init" />}
          {!showLoader && (
            <Fragment>
              <MewoDevTools />
              <Favicon url="/static/favicon.png" />
              <Loader />
              {hasShell && isLogged ? this.getWrappedChild() : <FullSize>{children}</FullSize>}
            </Fragment>
          )}
          {isMeta && (
            <Fragment>
              {isMetaTracks && <FilterBar />}
              <PlayerCore />
              <Player />
            </Fragment>
          )}
          <SidePanel />
          <RouterModal />
          <NotificationsList />
        </AppThemeProvider>
      </Fragment>
    );
  }
}

function mapStateToProps({ user, core, modo, player }) {
  return {
    ready: core.ready,
    isAdmin: user.isAdmin,
    isLogged: user.isLogged,
    userData: user.data,
    userSubscriptions: user.subscriptions,
    latestNotifications: transformNotifications(user.notifications),
    modoConfigId: _get(modo, 'config.id'),
    modoConfigData: _get(modo, 'config.fullData'),
    modoConfigActive: _get(modo, 'config.activeData.active', false),
    playerIsOpened: player.isOpened,
  };
}

export default compose(
  withRouter,
  withTranslation(['common', 'components']),
  connect(mapStateToProps, {
    initializeApp,
    getUserData,
    setLocalPreferences,
    setLocalMetaColumnsPreferences,
    checkUserNotifications,
    modifyConfigPlan,
    logout,
  }),
)(Layout);
