// =============================
// Imports
// =============================

// External Dependencies
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Router from 'next/router';
import { Icons } from '@mewo/components';
import playlistplaceholder from '@mewo/components/assets/images/placeholders/playlist_500x500.png?webp';
import _get from 'lodash/get';

// Config
import { withTranslation } from '../../../../config/i18n';
import entityConfig from '../../meta/playlists/config';

// Actions
import { panelActions, triggerAutosaveCheck } from '../../../../store/actions/SidePanelActions';
import { createMetaPitch as createMetaPitchBase } from '../../../../store/actions/MetaActions';

// Constants
import * as spts from '../../../../store/constants/SidePanelTypes';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import MetaTags from '../../../other/metaTags';
import DocumentStatus from '../documentStatus';
import DocumentTracks from '../documentTracks';
import Autosave from '../../../other/autosave';
import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import InputWithLanguage from '../../../containers/inputWithLanguage';
import TextAreaInput from '../../../presentationals/inputs/textAreaInput';
import MetaRightsInformations from '../metaRightsInformations';
import MetaDownloadDropdown from '../../../other/metaDownloadDropdown';
import WithMetaSearchRoute from '../../../faac/withMetaSearchRoute';
import Uploader from '../../../presentationals/uploader';
import MetaCustomFields from '../metaCustomFields';
import CopyLink from '../copyLink';
import Autotag from '../autotag';

import validator from '../../../../validators/meta/playlist';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getSiteOrigin } from '../../../../helpers/misc';
import { getDocumentRights, getGenericDocCoverUrl } from '../../../../helpers/meta-common';
import { formatDateString } from '../../../../helpers/dates';

// Styles
import {
  GenericAction,
  DocumentBody,
  DocumentCreatedAt,
  DocumentName,
  DocumentType,
  DownloadActionIcon,
  DownloadActionWrapper,
  FileHelper,
  GenericCover,
  GenericOwnership,
  GenericOwnershipCount,
  GenericSearchIcon,
  GenericSearchLink,
  LeftColumn,
  LineInnerLoader,
  LineLoader,
  LineLoaderWrapper,
  MiscHelper,
  RelationJobTooltip,
  RelationsWrapper,
  RelationTitleWrapper,
  RightColumn,
  SectionBox,
  SectionNotice,
  SectionTitle,
  TabElement,
  TabLink,
  TabLinksWrapper,
} from '../common.styles';
import { InputWrapper } from '../../global.styles';

// =============================
// Component
// =============================

const entity = 'playlist';

class MetaPlaylist extends Component {
  static propTypes = {
    tenantId: PropTypes.string.isRequired,
    panel: PropTypes.shape({
      data: pth.playlist,
      additionalData: PropTypes.shape({
        tracks: PropTypes.shape({
          data: PropTypes.arrayOf(pth.smallTrack),
        }),
        tags: PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.shape({
            status: PropTypes.number,
            tag: pth.tag,
          })),
        }),
        autotagging: PropTypes.shape({
          data: PropTypes.shape({
            nb_tracks: PropTypes.number,
            nb_tracks_not_autotagged: PropTypes.number,
          }),
        }),
      }),
      isLoading: PropTypes.bool.isRequired,
      'isLoading-tags': PropTypes.bool,
      'isLoading-tracks': PropTypes.bool,
      isModifying: PropTypes.bool.isRequired,
      'isModifying-ingest': PropTypes.bool,
      'isModifying-tags': PropTypes.bool,
      'isModifying-tracks': PropTypes.bool,
      isAutotagging: PropTypes.bool.isRequired,
      isDuplicating: PropTypes.bool.isRequired,
      isUploading: PropTypes.bool.isRequired,
      isDeletingUpload: PropTypes.bool.isRequired,
      isDeleting: PropTypes.bool.isRequired,
      uploadProgress: PropTypes.number.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    relationsNotifications: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      progress: PropTypes.number,
      isAudioIngestion: PropTypes.bool,
    })),
    customFields: pth.customFieldsConfig.isRequired,
    providers: PropTypes.arrayOf(pth.provider).isRequired,
    modoConfigsCount: PropTypes.number.isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    createMetaPitch: PropTypes.func.isRequired,
    isCreatingPitch: PropTypes.bool.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    relationsNotifications: [],
  };

  componentDidUpdate(prevProps) {
    const { relationsNotifications: prevRelationsNotifications } = prevProps;
    const { relationsNotifications, panel: { data } } = this.props;

    if (data) {
      const prevAudioRelationsNotification = prevRelationsNotifications
        .find(notification => notification.id === data.id && notification.isAudioIngestion);

      const audioRelationsNotification = relationsNotifications
        .find(notification => notification.id === data.id && notification.isAudioIngestion);

      if (
        typeof this.refreshPanel === 'function'
        && !!prevAudioRelationsNotification
        && !audioRelationsNotification
      ) {
        this.refreshPanel(true);
      }
    }
  }

  pickFormData = (data, documentRights, init = false) => {
    if (!documentRights.canWrite && !init) {
      return {
        showcase: _get(data, 'showcase', []),
      };
    }

    const keys = {
      title: _get(data, 'title', ''),
      descriptions: _get(data, 'descriptions', [])
        .map(({ locale, value }) => ({ locale, value })),
      showcase: init
        ? _get(data, 'showcase', []).map(({ id }) => id)
        : _get(data, 'showcase', []),
    };

    if (documentRights.canAccessNotes) {
      keys.notes = _get(data, 'notes', '');
    }

    if (documentRights.isOwner) {
      keys.public = _get(data, 'public', false);
      keys.agents = _get(data, 'agents', []);

      keys.custom = _get(data, 'custom', {});
    }

    return keys;
  };

  renderPanelAnchors = (data) => {
    const { customFields, providers, t } = this.props;

    const documentRights = getDocumentRights(
      data.owned_by_tenant,
      data.tenant.id,
      providers,
    );

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    // Owner view:
    // - Tracks
    // - Tags
    // - Info
    // - Custom fields (if exists)
    // - Agents
    // - Modo
    // - Uploads

    // Agents normal view:
    // - Tracks
    // - Tags
    // - Info
    // - Providers
    // - Modo
    // - Uploads
    return (
      <TabLinksWrapper>
        <TabLink name="tracks">
          {t(`pages:meta.${entity}s.panel.tracks_anchor`)}
        </TabLink>
        <TabLink name="tags">
          {t(`pages:meta.${entity}s.panel.tags_anchor`)}
        </TabLink>
        <TabLink name="general_information">
          {t(`pages:meta.${entity}s.panel.general_information_anchor`)}
        </TabLink>
        {(documentRights.isOwner && hasCustomFields) && (
          <TabLink name="custom_fields_information">
            {t(`pages:meta.${entity}s.panel.custom_fields_information_anchor`)}
          </TabLink>
        )}
        {documentRights.isOwner && (
          <TabLink name="agents_information">
            {t(`pages:meta.${entity}s.panel.agents_information_anchor`)}
          </TabLink>
        )}
        {!documentRights.isOwner && (
          <TabLink name="providers_information">
            {t(`pages:meta.${entity}s.panel.providers_information_anchor`)}
          </TabLink>
        )}
        <TabLink name="modo_information">
          {t(`pages:meta.${entity}s.panel.modo_information_anchor`)}
        </TabLink>
        <TabLink name="uploads_information">
          {t(`pages:meta.${entity}s.panel.uploads_information_anchor`)}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  render() {
    const {
      tenantId,
      panel,
      localPreferences,
      relationsNotifications,
      customFields,
      providers,
      modoConfigsCount,
      i18n: { language },
      createMetaPitch,
      isCreatingPitch,
      triggerPanelAutosaveCheck,
      t,
    } = this.props;

    const {
      data,
      additionalData,
      isModifying,
      isAutotagging,
      isDuplicating,
      isUploading,
      isDeletingUpload,
      isDeleting,
      uploadProgress,
    } = panel;

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/${entity}s?panel=${data.id}`
      : '';

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[spts.META_PLAYLIST_PANEL].get}
        getAdditionalOptions={{
          fnc: panelActions[spts.META_PLAYLIST_PANEL].getAdditional,
          list: [
            ['tracks', 'tracks'],
            ['tracks/tags', 'tags'],
            [
              `/meta/autotagging/${entity}/${panel.id}/data`,
              'autotagging',
              '',
              panelData => panelData?.owned_by_tenant,
            ],
          ],
        }}
        requestGridRefresh={panelActions[spts.META_PLAYLIST_PANEL].requestGridRefresh}
        modify={panelActions[spts.META_PLAYLIST_PANEL].modify}
        modifyAdditional={panelActions[spts.META_PLAYLIST_PANEL].modifyAdditional}
        autotagOptions={{
          fnc: panelActions[spts.META_PLAYLIST_PANEL].autotag,
          getAdditionalList: [
            ['tracks/tags', 'tags'],
            [
              `/meta/autotagging/${entity}/${panel.id}/data`,
              'autotagging',
            ],
          ],
        }}
        duplicate={panelActions[spts.META_PLAYLIST_PANEL].duplicate}
        uploadFile={panelActions[spts.META_PLAYLIST_PANEL].uploadFile}
        deleteFile={panelActions[spts.META_PLAYLIST_PANEL].deleteFile}
        del={panelActions[spts.META_PLAYLIST_PANEL].delete}
        lang={{
          autotagTitle: t(`pages:meta.${entity}s.autotag_documents`, { name: data?.title }),
          autotagDesc: t(`pages:meta.${entity}s.autotag_documents_notice`),
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {({
          refreshPanel,
          modifyDocument,
          modifyAdditional,
          autotagDocument,
          duplicateDocument,
          uploadDocumentFile,
          deleteDocumentFile,
          deleteDocument,
        }) => {
          if (!data) return null;

          this.refreshPanel = refreshPanel;

          const relationsNotification = relationsNotifications
            .find(notification => notification.id === data.id);

          const documentRights = getDocumentRights(
            data.owned_by_tenant,
            data.tenant.id,
            providers,
          );

          const image = _get(data, 'image.original.url')
            ? {
              name: _get(data, 'image.original.file_name'),
              url: _get(data, 'image.original.url'),
            }
            : null;

          let uploadStatus = data.has_file ? 2 : 0;
          // This needs to be last as it overwrites everything
          if (isUploading || isDeletingUpload) uploadStatus = 1;

          const formDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isUploading
            || isDeletingUpload;
          const writableForAgentDisabled = !!relationsNotification
            || isUploading
            || isDeletingUpload;
          const uploadDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isModifying;

          return (
            <Fragment>
              <LeftColumn>
                <GenericCover
                  placeholder={playlistplaceholder}
                  cover={getGenericDocCoverUrl(data.image)}
                />
                <DocumentName>
                  {data.title}
                </DocumentName>
                <DocumentType>
                  {t(`common:entities.${entity}`)}
                </DocumentType>
                <Autotag.Status
                  autotaggingData={_get(additionalData, 'autotagging.data')}
                  documentRights={documentRights}
                  entity={entity}
                  t={t}
                />
                <DocumentCreatedAt>
                  {t(`pages:meta.${entity}s.panel.created_at`)}
                  :&nbsp;
                  {formatDateString(data.created_at, localPreferences.dateFormat, true)}
                </DocumentCreatedAt>
                <CopyLink
                  link={shareLink}
                  content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                  tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
                />

                {documentRights.isOwner && (
                  <Fragment>
                    {!!_get(data, 'pitches', []).length && (
                      <GenericAction
                        onClick={() => triggerPanelAutosaveCheck(
                          'open',
                          [spts.META_PITCH_PANEL, data.pitches[0]],
                        )}
                        disabled={isCreatingPitch}
                      >
                        {t(`pages:meta.${entity}s.open_pitch`)}
                      </GenericAction>
                    )}

                    {(modoConfigsCount > 1 || !_get(data, 'pitches', []).length) && (
                      <GenericAction
                        onClick={() => createMetaPitch({ type: 'playlist', entity: data.id })}
                        disabled={isCreatingPitch}
                      >
                        {t(`pages:meta.${entity}s.create_pitch`)}
                      </GenericAction>
                    )}
                  </Fragment>
                )}

                {!!_get(additionalData, 'tracks.data.length') && (
                  <GenericOwnership>
                    <GenericOwnershipCount>
                      {t('common:entities.number_tracks', {
                        count: _get(additionalData, 'tracks.data.length'),
                      })}
                    </GenericOwnershipCount>
                  </GenericOwnership>
                )}

                <Autotag.ColumnAction
                  autotagDocument={autotagDocument}
                  autotaggingData={_get(additionalData, 'autotagging.data')}
                  documentRights={documentRights}
                  entity={entity}
                  t={t}
                />

                <MetaDownloadDropdown
                  entity={entity}
                  documentId={data.id}
                  documentName={data.title}
                  canDownload={!!_get(additionalData, 'tracks.data.length')}
                  togglerElement={(
                    <DownloadActionWrapper>
                      <DownloadActionIcon />
                      {t(`pages:meta.${entity}s.downloads.toggler_title`)}
                    </DownloadActionWrapper>
                  )}
                />

                {!!_get(additionalData, 'tracks.data.length') && (
                  <WithMetaSearchRoute
                    entity="track"
                    filters={{ internal_refs: [[data.id, `${t(`common:entities.${entity}`)}: ${data.title}`]] }}
                    resetQuery
                  >
                    {routeQuery => (
                      <GenericSearchLink onClick={() => Router.push(`/meta/tracks${routeQuery}`)}>
                        <GenericSearchIcon />
                        {t(`pages:meta.${entity}s.panel.search_tracks`)}
                      </GenericSearchLink>
                    )}
                  </WithMetaSearchRoute>
                )}

                {!!relationsNotification && (
                  <RelationsWrapper>
                    <RelationTitleWrapper>
                      {t(`pages:meta.${entity}s.panel.ongoing_relations_job`)}
                      <RelationJobTooltip
                        content={t(`pages:meta.${entity}s.panel.ongoing_relations_job_tooltip`)}
                      >
                        <Icons.Informations />
                      </RelationJobTooltip>
                    </RelationTitleWrapper>
                    <LineLoaderWrapper>
                      <LineLoader>
                        <LineInnerLoader progress={relationsNotification.progress} />
                      </LineLoader>
                    </LineLoaderWrapper>
                  </RelationsWrapper>
                )}
              </LeftColumn>
              <RightColumn initTab="tracks">
                <DocumentStatus
                  isModifying={(
                    isModifying
                    || _get(panel, 'isModifying-tags', false)
                    || _get(panel, 'isModifying-tracks', false)
                    || _get(panel, 'isModifying-ingest', false)
                  )}
                  isAutotagging={isAutotagging}
                  isDuplicating={isDuplicating}
                  isDeleting={isDeleting}
                  localPreferences={localPreferences}
                  documentRights={documentRights}
                  updatedAt={data.updated_at}
                  duplicateDocument={duplicateDocument}
                  deleteDocument={deleteDocument}
                />
                {this.renderPanelAnchors(data)}
                <DocumentBody>
                  <TabElement name="tracks">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.tracks_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.tracks_information_notice`)}
                    </SectionNotice>
                    <DocumentTracks
                      isLoading={_get(panel, 'isLoading-tracks', false)}
                      isModifying={_get(panel, 'isModifying-tracks', false)
                          || _get(panel, 'isModifying-ingest', false)}
                      tenantId={tenantId}
                      entity={entity}
                      parentDocument={data}
                      relationsNotification={relationsNotification}
                      data={_get(additionalData, 'tracks.data', [])}
                      documentRights={documentRights}
                      modifyAdditional={modifyAdditional}
                    />
                  </TabElement>

                  <TabElement name="tags">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.tags_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.tags_information_notice`)}
                    </SectionNotice>
                    <Autotag.PanelSection
                      autotagDocument={autotagDocument}
                      autotaggingData={_get(additionalData, 'autotagging.data')}
                      documentRights={documentRights}
                      entity={entity}
                      t={t}
                    />
                    <SectionBox>
                      {!_get(panel, 'isLoading-tags', false) && (
                      <MetaTags
                        currentTagsTitle={t(`pages:meta.${entity}s.panel.current_tags`)}
                        statusData={_get(additionalData, 'tags.data', [])}
                        disabled={(
                              !documentRights.canWrite
                              || isAutotagging
                              || _get(panel, 'isModifying-tags', false)
                              || !_get(additionalData, 'tracks.data.length')
                            )}
                        onAdd={tagId => modifyAdditional(
                          'tracks/tags',
                          'post',
                          { added: [tagId] },
                          'tags',
                        )}
                        onRemove={tagId => modifyAdditional(
                          'tracks/tags',
                          'post',
                          { removed: [tagId] },
                          'tags',
                        )}
                      />
                      )}
                    </SectionBox>
                  </TabElement>

                  <Form
                    onSubmit={() => {}}
                    initialValues={this.pickFormData(data, documentRights, true)}
                    subscription={{ values: true }}
                    keepDirtyOnReinitialize
                    mutators={{ ...arrayMutators }}
                  >
                    {() => (
                      <Fragment>
                        <Autosave
                          debounce={5000}
                          save={modifyDocument}
                          nullValuesTransformer={formValues => this.pickFormData(
                            formValues, documentRights, false,
                          )}
                          autosavePanelId={`${spts.META_PLAYLIST_PANEL}_${data.id}`}
                        />
                        <TabElement name="general_information">
                          <SectionTitle>
                            {t(`pages:meta.${entity}s.panel.general_information`)}
                          </SectionTitle>
                          <SectionNotice>
                            {t(`pages:meta.${entity}s.panel.general_information_notice`)}
                          </SectionNotice>
                          <SectionBox>
                            <InputWrapper>
                              <Field
                                name="title"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.title`)}
                                fullWidth
                                validate={validator.title}
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <Field
                                name="descriptions"
                                component={MewoInput}
                                use={InputWithLanguage}
                                defaultLanguage={language}
                                label={t(`pages:meta.${entity}s.panel.descriptions`)}
                                fullWidth
                                isTranslatable
                                inputType="textarea"
                                context="autosave"
                                disabled={formDisabled}
                                height="12rem"
                              />
                            </InputWrapper>
                          </SectionBox>
                          {documentRights.canAccessNotes && (
                            <SectionBox>
                              <Field
                                name="notes"
                                component={MewoInput}
                                use={TextAreaInput}
                                label={t(`pages:meta.${entity}s.panel.notes`)}
                                fullWidth
                                context="autosave"
                                disabled={formDisabled}
                                height="12rem"
                              />
                            </SectionBox>
                          )}
                        </TabElement>

                        {documentRights.isOwner && hasCustomFields && (
                          <TabElement name="custom_fields_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.custom_fields_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.custom_fields_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <MetaCustomFields entity={entity} formDisabled={formDisabled} />
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}

                        <MetaRightsInformations
                          entity={entity}
                          docAgents={data.agents}
                          docModos={data.showcase}
                          docPublic={data.public}
                          documentRights={documentRights}
                          formDisabled={formDisabled}
                          modifyAdditional={modifyAdditional}
                          writableForAgentDisabled={writableForAgentDisabled}
                        />
                      </Fragment>
                    )}
                  </Form>

                  <TabElement name="uploads_information">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.uploads_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.uploads_information_notice`)}
                    </SectionNotice>
                    <SectionBox>
                      <Uploader
                        name="image"
                        value={image}
                        status={uploadStatus}
                        disabled={uploadDisabled}
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        onChange={(nextValue) => {
                          if (!nextValue) return deleteDocumentFile();
                          return uploadDocumentFile(nextValue);
                        }}
                        maxSize={entityConfig.panelUploads.image.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.image`)}
                      </FileHelper>
                      {_get(data, 'image.error_key') && (
                      <MiscHelper mt="1.2rem" isError>
                        {t(`errors:conversion.${_get(data, 'image.error_key')}_desc`)}
                      </MiscHelper>
                      )}
                    </SectionBox>
                  </TabElement>
                </DocumentBody>
              </RightColumn>
            </Fragment>
          );
        }}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core, user, meta, modo }) {
  return {
    tenantId: user.data.tenant_id || user.data.id,
    localPreferences: core.localPreferences,
    relationsNotifications: user.relationsNotifications.playlists,
    customFields: meta.customFields.data,
    providers: meta.providers,
    isCreatingPitch: meta.pitchs.isCreating,
    modoConfigsCount: modo.configs.data.length,
  };
}

export default compose(
  connect(mapStateToProps, {
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
    createMetaPitch: createMetaPitchBase,
  }),
  withTranslation(['common', 'pages', 'components', 'errors']),
)(MetaPlaylist);
