// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from '../../../config/i18n';

import WithMetaSearchRoute from '../../faac/withMetaSearchRoute';

import {
  HeaderWrapper,
  MetaLinksWrapper,
  HeaderLink,
  LinkCounter,
  RightsHoldersToggler,
  StyledDropdown,
  RightsHoldersLink,
  RightBlock,
} from './styles';

// =============================
// Component
// ===============s==============

class MetaHeader extends Component {
  static propTypes = {
    /** Currently active page. */
    activePage: PropTypes.string.isRequired,
    /** Meta init state. */
    isInitialized: PropTypes.bool.isRequired,
    /** Number of documents per collection for current search query */
    count: PropTypes.shape({
      track: PropTypes.number,
      album: PropTypes.number,
      playlist: PropTypes.number,
      pitch: PropTypes.number,
      brief: PropTypes.number,
      catalog: PropTypes.number,
      artist: PropTypes.number,
      label: PropTypes.number,
      publisher: PropTypes.number,
      people: PropTypes.number,
      ingestion: PropTypes.number,
    }).isRequired,
    /** Translation function. */
    t: PropTypes.func.isRequired,
  };

  getLinkCount = (count) => {
    if (count >= 1000000) {
      return `${Math.floor(count / 1000000)}m +`;
    }

    if (count >= 1000) {
      return `${Math.floor(count / 1000)}k +`;
    }

    return count;
  };

  generateLink = (entity) => {
    const { count, activePage, t } = this.props;
    const entityRouteMap = {
      pitch: 'drop',
    };

    const baseRoute = `/meta/${entityRouteMap[entity] || entity}s`;
    const active = activePage.indexOf(baseRoute) === 0;

    const LinkComponent = ['artist', 'label', 'publisher', 'people'].includes(entity)
      ? RightsHoldersLink
      : HeaderLink;

    return (
      <WithMetaSearchRoute entity={entity} stayOnPage>
        {routeQuery => (
          <LinkComponent
            entity={entity}
            active={active}
            href={`${baseRoute}${routeQuery}`}
          >
            {t(`pages:meta.${entity}s.title`)}
            &nbsp;
            <LinkCounter active={active}>
              {this.getLinkCount(count[entity])}
            </LinkCounter>
          </LinkComponent>
        )}
      </WithMetaSearchRoute>
    );
  };

  generateOwnersDropdown = () => {
    const { count, activePage, t } = this.props;

    const baseRoutes = [
      '/meta/artists',
      '/meta/labels',
      '/meta/publishers',
      '/meta/peoples',
    ];

    const totalCount = ['artist', 'label', 'publisher', 'people']
      .reduce((prev, curr) => prev + count[curr], 0);

    const active = !!(baseRoutes.find(baseRoute => activePage.indexOf(baseRoute) === 0));

    return (

      <StyledDropdown
        active={active}
        defaultName={activePage}
        options={[
          { name: 'artist', label: 'artist' },
          { name: 'label', label: 'label' },
          { name: 'publisher', label: 'publisher' },
          { name: 'people', label: 'people' },
        ]}
        renderOption={option => (
          <Fragment key={option.name}>{this.generateLink(option.name)}</Fragment>
        )}
        togglerElement={(
          <RightsHoldersToggler active={active}>
            {t('pages:meta.rights_holders.title')}
            &nbsp;
            <LinkCounter active={active}>
              {this.getLinkCount(totalCount)}
            </LinkCounter>
          </RightsHoldersToggler>
        )}
      />
    );
  };

  render() {
    const { isInitialized } = this.props;

    if (!isInitialized) return null;

    return (
      <HeaderWrapper>
        <MetaLinksWrapper>
          {['track', 'album', 'playlist', 'catalog'].map(entity => (
            <Fragment key={entity}>{this.generateLink(entity)}</Fragment>
          ))}
          {this.generateOwnersDropdown()}
          <RightBlock>
            {['brief', 'pitch', 'ingestion'].map(entity => (
              <Fragment key={entity}>{this.generateLink(entity)}</Fragment>
            ))}
          </RightBlock>
        </MetaLinksWrapper>
      </HeaderWrapper>
    );
  }
}

function mapStateToProps({ meta }) {
  return {
    isInitialized: meta.isInitialized,
    count: {
      track: meta.tracks.total,
      album: meta.albums.total,
      playlist: meta.playlists.total,
      pitch: meta.pitchs.total,
      brief: meta.briefs.total,
      catalog: meta.catalogs.total,
      artist: meta.artists.total,
      label: meta.labels.total,
      publisher: meta.publishers.total,
      people: meta.peoples.total,
      ingestion: meta.ingestions.total,
    },
  };
}

export default compose(
  withTranslation(['pages']),
  connect(mapStateToProps),
)(MetaHeader);
