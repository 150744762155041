// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  height: 6rem;
  margin-left: auto;
`;

export const Label = styled.div`
  margin-right: 0.8rem;
`;

export const IconWrapper = styled.div`
  margin-left: 0.8rem;
`;
