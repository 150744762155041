// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Router from 'next/router';
import _get from 'lodash/get';

import { withTranslation } from '../../../config/i18n';

import { toggleFilterBar } from '../../../store/actions/MetaActions';

import FilterBar from '../../presentationals/filterBar';

import * as pth from '../../../helpers/proptypes';
import * as searchHelpers from '../../../helpers/meta-search';
import { camelCaseKeysDeep, snakeCaseKeysDeep } from '../../../helpers/misc';

// =============================
// Component
// =============================

class FilterBarContainer extends PureComponent {
  static propTypes = {
    /** Search filters (after being filtered by availability). */
    facets: pth.tracksFacets.isRequired,
    /** Current i18n settings */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Initial facets */
    initialFacets: pth.tracksFacets.isRequired,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: PropTypes.bool.isRequired,
    /** If True, MusicPlayer is opened. */
    isMusicPlayerOpened: PropTypes.bool.isRequired,
    /** If True, meta has loaded init data. */
    metaIsInitialized: PropTypes.bool.isRequired,
    /** Current tracks search query */
    // eslint-disable-next-line react/forbid-prop-types
    searchQuery: PropTypes.object.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
    /** All Tags Tree */
    tags: PropTypes.arrayOf(pth.tagCategory).isRequired,
    /** All Versions. */
    versions: PropTypes.arrayOf(pth.trackVersion).isRequired,
  };

  handleChange = (value) => {
    const { searchQuery } = this.props;

    const query = searchHelpers.reduxObjectToQueryString('track', 0, {
      filters: {
        ...searchQuery.filters,
        ...snakeCaseKeysDeep(value),
      },
    });

    Router.push(`/meta/tracks${query}`);
  };

  render() {
    const {
      facets,
      i18n: { language },
      initialFacets,
      isDoingMaiaSearch,
      metaIsInitialized,
      searchQuery,
      t,
      tags,
      versions,
      ...rest
    } = this.props;

    const lang = {
      bpm: t('components:filter_bar.bpm'),
      duration: t('components:filter_bar.duration'),
      input: {
        search: t('components:filter_bar.search'),
        pressEnter: t('components:filter_bar.press_enter'),
        Label: {
          optional: t('components:mewo_input.Label.optional'),
        },
      },
      lyrics: t('components:filter_bar.lyrics'),
      maiaLoading: t('components:filter_bar.maia_loading'),
      noTags: t('components:filter_bar.no_tags'),
      onlyStems: t('components:filter_bar.only_stems'),
      showUnavailableFilters: t('components:filter_bar.show_unavailable_filters'),
      stems: t('components:filter_bar.stems'),
      title: t('components:filter_bar.title'),
      versions: t('components:filter_bar.versions'),
      year: t('components:filter_bar.year'),
    };

    if (!metaIsInitialized) return null;

    return (
      <FilterBar
        facets={{
          tags: facets.tags || {},
          versions: facets.versions || {},
          duration: facets.duration?.map(v => camelCaseKeysDeep(v)) || [],
          bpm: facets.bpm?.map(v => camelCaseKeysDeep(v)) || [],
          year: facets.year?.map(v => camelCaseKeysDeep(v)) || [],
        }}
        initialFacets={{
          tags: initialFacets.tags,
          versions: initialFacets.versions,
          duration: initialFacets.duration.map(v => camelCaseKeysDeep(v)),
          bpm: initialFacets.bpm.map(v => camelCaseKeysDeep(v)),
          year: initialFacets.year.map(v => camelCaseKeysDeep(v)),
        }}
        displaySearchBar={!isDoingMaiaSearch}
        lang={lang}
        locale={language}
        maiaLoading={isDoingMaiaSearch}
        onChange={this.handleChange}
        onLyricsChange={value => this.handleChange({ lyrics: value })}
        value={{
          tags: _get(searchQuery.filters, 'tags', []),
          tagsOr: _get(searchQuery.filters, 'tags_or', []),
          tagsNot: _get(searchQuery.filters, 'tags_not', []),
          versions: _get(searchQuery.filters, 'versions', []),
          versionsNot: _get(searchQuery.filters, 'versions_not', []),
          stems: _get(searchQuery.filters, 'stems', false),
          lyrics: _get(searchQuery.filters, 'lyrics', ''),
          duration: _get(searchQuery.filters, 'duration', undefined),
          year: _get(searchQuery.filters, 'year', undefined),
          bpm: _get(searchQuery.filters, 'bpm', undefined),
        }}
        showUnavailableFilters
        searchFeatures={{
          duration: true,
          year: true,
          bpm: true,
          lyrics: true,
          stems: true,
        }}
        tags={tags}
        versions={versions}
        {...rest}
      />
    );
  }
}

function mapStateToProps({ options, player, meta }) {
  return {
    facets: meta.tracks.facets,
    initialFacets: options.data.initial_facets,
    // NOTE: We never need to show a loading state when doing Maia search
    isDoingMaiaSearch: false,
    isMusicPlayerOpened: player.isOpened,
    metaIsInitialized: meta.isInitialized,
    opened: meta.filterBar.isOpened,
    searchQuery: meta.tracks.query,
    tags: options.data.tags,
    versions: options.data.trackversions,
  };
}

export default compose(
  withTranslation('components'),
  connect(mapStateToProps, {
    onClose: () => toggleFilterBar(false),
  }),
)(FilterBarContainer);
