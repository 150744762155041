// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

// Components
import Pie from '../../../../../other/charts/pie/index';
import List from '../../../../../other/charts/list/index';

// Helpers
import { getTrackName } from '../../../../../../helpers/doc-names';
import * as pth from '../../proptypes';

// Styles
import {
  Inline,
  UserPieStatBox,
  UserSingleStatBox,
  StatTitle,
  StatNumberValue,
  TitleAsSeparator,
} from '../../../../modo/stats/styles';

// =============================
// Component
// =============================

class DownloadsStats extends Component {
  static propTypes = {
    downloadsData: pth.statsDownload.isRequired,
    isFetching: PropTypes.bool.isRequired,
    currentTimeframe: PropTypes.string.isRequired,
    getStats: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { downloadsData, isFetching, currentTimeframe, getStats, locale, t } = this.props;

    const topTracks = _get(downloadsData, 'top_tracks', [])
      .slice(0, 20) // Top 20 only
      .map(d => ({ value: getTrackName(d, locale), count: d.download_count }));

    const topCatalogs = _get(downloadsData, 'top_catalogs', [])
      .slice(0, 6) // Top 6 only
      .map(c => ({ id: c.name, value: c.download_count }));

    return (
      <Fragment>
        <TitleAsSeparator mt="3rem" mb="2rem">
          {t('pages:modo.stats.downloads')}
        </TitleAsSeparator>
        <Inline>
          <List
            title={t('pages:modo.stats.top_tracks')}
            header={{
              value: t('pages:modo.stats.track_title'),
              count: t('pages:modo.stats.number_of_downloads'),
            }}
            width={48.9}
            data={topTracks}
            isLoadingPeriod={isFetching}
            currentPeriod={currentTimeframe}
            getByPeriod={period => getStats(period)}
            maxItemsFolded={8}
          />
        </Inline>
        <Inline ml="2rem">
          <UserPieStatBox>
            <Pie charts={topCatalogs} />
          </UserPieStatBox>
          <UserSingleStatBox mt="2rem">
            <StatTitle mb="1.5rem">{t('pages:modo.stats.downloads_total')}</StatTitle>
            <StatNumberValue>{_get(downloadsData, 'total', 0)}</StatNumberValue>
          </UserSingleStatBox>
        </Inline>
      </Fragment>
    );
  }
}

export default DownloadsStats;
