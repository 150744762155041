// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isCancel } from 'axios';

// Config
import { withTranslation } from '../../../../config/i18n';

// Helpers
import { getApiUrl } from '../../../../helpers/misc';

// Components
import ApiSelectInput from '../../../presentationals/inputs/apiSelectInput';

// Actions
import { setNotification as setNotificationBase } from '../../../../store/actions/NotificationActions';

// Constants
import * as ntfs from '../../../../store/constants/NotificationTypes';

// =============================
// Component
// =============================

class ApiSelectInputContainer extends Component {
  static propTypes = {
    setNotification: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  handleOnFetchError = (error) => {
    const { setNotification, t } = this.props;

    if (!isCancel(error)) {
      setNotification({
        notificationKey: ntfs.API_SELECT_INPUT_FETCH_ERROR,
        message: t('errors:api_select_input.generic_fetch_error'),
        type: 'error',
        duration: 12000,
      });
    }
  };

  handleOnTheFlyCreationError = (error) => {
    const { setNotification, t } = this.props;

    if (!isCancel(error)) {
      setNotification({
        notificationKey: ntfs.API_SELECT_INPUT_CREATION_ERROR,
        message: t('errors:api_select_input.generic_creation_error'),
        type: 'error',
        duration: 12000,
      });
    }
  };

  render() {
    const { setNotification, ...rest } = this.props;

    return (
      <ApiSelectInput
        {...rest}
        apiUrl={getApiUrl()}
        onFetchError={this.handleOnFetchError}
        onTheFlyCreationError={this.handleOnTheFlyCreationError}
      />
    );
  }
}

function mapStateToProps({ user }) {
  return {
    userToken: user.token,
  };
}

export default compose(
  connect(mapStateToProps, {
    setNotification: setNotificationBase,
  }),
  withTranslation(['errors', 'components']),
)(ApiSelectInputContainer);
