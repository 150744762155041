/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// Constants
import { CLOSE_PREMIUM_MODAL } from '../constants/ActionTypes';

// =============================
// Actions
// =============================

export function closePremiumModal() {
  return {
    type: CLOSE_PREMIUM_MODAL,
  };
}
