// =============================
// Imports
// =============================

import _pickBy from 'lodash/pickBy';

// =============================
// Helpers
// =============================

// A few terms here:
//
// Redux Search Object - search representation in Redux state
// Router Query Object - search object in query parsed as object (provided by next router)
// API Search Object - search object sent to API as data
// Filter Bar Object - search object consumed by FilterBar component
// Query String - search object in query string format for url bar
// Query Keys - list of keys within search object used for search
//
// Redux Search Object is the main, most comprehensive representation, the rest
// are projections for different consumers

// These helpers are used for search for the following pages:
//
// - [Dashboard] Agents
// - [Dashboard] Collaborator
// - [Dashboard] Providers
// - [Dashboard] Notifications
// - [Modo] Config pages
// - [Modo] Users

// In order to succesfully implement this, here are some pointers:
//
// - Within the redux store, all possible query values should be set as undefined.
// For example, if you check the "collaborators" object within the reducer "pages.js",
// you will see an object { sort: undefined } for the query key. This means that
// the only search query possible here is changing the sort key.
// - Redux Actions should handle the query parameter as being the Redux Search Object.
// - Redux Actions should take care of updating the redux store the query values when they exist.
// - Always build the projections for different consumers using these helpers.

export const reduxObjectKeys = ['search', 'sort', 'sort_direction'];
export const queryStringKeys = ['search', 'sort', 'sort_direction', 'page'];

/**
 * Transform object to Query String - Leaves only meaningful non empty values
 *
 * @param {object} query - Redux Search Object or Router Query Object
 * Add page to object if it's the Redux Search Object.
 * @param {string[]} keys - List of keys to be picked within object
 * @return {string} - Query string
 */
export const compactQueryString = (query, keys) => {
  const queryParams = new URLSearchParams();

  const compactedParams = _pickBy(query, (v, k) => keys.includes(k) && v);

  Object.keys(compactedParams).forEach((key) => {
    queryParams.append(key, compactedParams[key]);
  });

  const stringQuery = queryParams.toString();

  return stringQuery ? `?${stringQuery}` : '';
};

/**
 * Get current page from Router Query Object
 *
 * @param {object} query - Router Query Object
 * @return {number} - Current page number (starts at 0)
 */
export const extractPageFromRouterQuery = query => query.page || 0;

/**
 * Transform Router Query Object to Redux Search Object
 *
 * @param {object} query - Router Query Object
 * @return {object} - Redux Search Object
 */
export const routerQueryToReduxObject = query => _pickBy(
  query,
  (v, k) => reduxObjectKeys.includes(k) && v,
);

/**
 * Transform Router Query Object to Query String
 *
 * @param {object} query - Router Query Object
 * @return {string} - Query string
 */
export const routerQueryToQueryString = query => compactQueryString(query, queryStringKeys);

/**
 * Transform Redux Search Object to Query String
 *
 * @param {number} page - Current or target page
 * @param {object} query - Router Query Object
 * @return {string} - Query string
 */
export const reduxObjectToQueryString = (page, query) => compactQueryString(
  { ...query, page },
  queryStringKeys,
);
