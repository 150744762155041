// =============================
// Imports
// =============================

// External dependencies
import Router from 'next/router';

// Actions
import { getLatestNotifications } from '../actions/UserActions';
import { getUserNotifications } from '../actions/PagesActions';
import { getConfigPageModules } from '../actions/ModoActions';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Middleware
// =============================

const pagesMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.CHECK_USER_NOTIFICATIONS_SUCCESS: {
      const { page, query } = store.getState().pages.notifications;
      store.dispatch(getLatestNotifications());

      // If user has been to the notification page at least once
      // if not page would be `null`
      if (typeof page === 'number' && page >= 0) {
        store.dispatch(getUserNotifications(page, query));
      }

      return next(action);
    }

    case action.type === acts.GET_MODO_CONFIG_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_PAGE_FAILURE: {
      // Redirect to dashboard if config or config page does not exist
      Router.push('/dashboard');
      return next(action);
    }

    case action.type === acts.GET_MODO_CONFIG_PAGE_SUCCESS: {
      store.dispatch(getConfigPageModules());
      return next(action);
    }

    case action.type === acts.DELETE_MODO_CONFIG_PAGE_SUCCESS: {
      if (action.payload.redirect) {
        const configId = store.getState().modo.config.id;
        // Redirect modo pages if necessary
        Router.push('/modo/[modoId]/pages', `/modo/${configId}/pages`);
      }

      return next(action);
    }

    case action.type === acts.CREATE_MODO_CONFIG_PAGE_SUCCESS:
    case action.type === acts.DUPLICATE_MODO_CONFIG_PAGE_SUCCESS: {
      const configId = store.getState().modo.config.id;
      const pageId = action.payload.id;
      // Redirect modo page once it is created
      Router.push('/modo/[modoId]/custompage/[pageId]', `/modo/${configId}/custompage/${pageId}`);
      return next(action);
    }

    default:
      return next(action);
  }
};

export default pagesMiddleware;
