// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

// =============================
// Component
// =============================

class DraggableFields extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onDragEnd: PropTypes.func,
    children: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onDragEnd: undefined,
    disabled: false,
  };

  onDragEnd = fields => ({ source, destination }) => {
    const { onDragEnd } = this.props;
    if (!destination) return;

    if (onDragEnd) {
      onDragEnd(source.index, destination.index);
      return;
    }

    fields.move(source.index, destination.index);
  };

  render() {
    const { name, children, disabled } = this.props;

    return (
      <FieldArray name={name}>
        {({ fields }) => (
          <DragDropContext onDragEnd={this.onDragEnd(fields)}>
            <Droppable droppableId={`droppable-${name}`} type={name}>
              {droppableProvided => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {fields.map((fieldName, index) => (
                    <Draggable
                      draggableId={`draggable-${name}-${index}`}
                      index={index}
                      type={name}
                      key={index} // eslint-disable-line react/no-array-index-key
                      isDragDisabled={disabled}
                    >
                      {provided => children({
                        fields,
                        fieldName,
                        index,
                        ...provided,
                      })}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </FieldArray>
    );
  }
}

export default DraggableFields;
