// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  isInitialized: false,
  isInitializing: false,
  agents: [],
  providers: [],
  customFields: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
  },
  bulkModal: {
    opened: false,
    entity: null,
    isFetchingData: false,
    isUpdating: false,
    data: {},
    ids: [],
  },
  filterBar: {
    isOpened: false,
  },
  searchSettings: {
    searchInDescriptions: false,
  },
  peoples: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  publishers: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isExportingTracks: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  labels: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isExportingTracks: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  artists: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isExportingTracks: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  catalogs: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isExportingTracks: false,
    isArchivingTracks: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  albums: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isExportingTracks: false,
    isArchivingTracks: false,
    isDownloadingCopyright: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  playlists: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isExportingTracks: false,
    isArchivingTracks: false,
    isDownloadingCopyright: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  refs: {
    isHandlingTracks: [],
  },
  pitchs: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  briefs: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExporting: false,
    isExportingTracks: false,
    isArchivingTracks: false,
    isDownloadingCopyright: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  tracks: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isExportingTracks: false,
    isArchivingTracks: false,
    isDownloadingAudiofile: false,
    isDownloadingCopyright: false,
    isLauchingSimilaritySearch: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    uploads: [],
    facets: {},
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
  ingestions: {
    isFetching: false,
    isCreating: false,
    isUpdatingPostJob: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
      filters: {},
    },
    total: null,
  },
};

// =============================
// Reducer
// =============================

export default function meta(state = initialState, action) {
  switch (action.type) {
    /* Init */
    case acts.SET_META_INITIALIZATION_DATA:
      return {
        ...state,
        agents: action.payload.agents,
        providers: action.payload.providers,
        peoples: {
          ...state.peoples,
          ...action.payload.peoples,
        },
        publishers: {
          ...state.publishers,
          ...action.payload.publishers,
        },
        labels: {
          ...state.labels,
          ...action.payload.labels,
        },
        artists: {
          ...state.artists,
          ...action.payload.artists,
        },
        catalogs: {
          ...state.catalogs,
          ...action.payload.catalogs,
        },
        playlists: {
          ...state.playlists,
          ...action.payload.playlists,
        },
        pitchs: {
          ...state.pitchs,
          ...action.payload.pitchs,
        },
        briefs: {
          ...state.briefs,
          ...action.payload.briefs,
        },
        albums: {
          ...state.albums,
          ...action.payload.albums,
        },
        tracks: {
          ...state.tracks,
          ...action.payload.tracks,
        },
        ingestions: {
          ...state.ingestions,
          ...action.payload.ingestions,
        },
      };

    case acts.INITIALIZE_META_LOADING:
      return {
        ...state,
        isInitializing: true,
      };

    case acts.INITIALIZE_META_SUCCESS:
      return {
        ...state,
        isInitialized: true,
        isInitializing: false,
      };

    case acts.INITIALIZE_META_FAILURE:
      return {
        ...state,
        isInitializing: false,
      };

    /* Meta Agents & Providers */
    case acts.CREATE_USER_AGENT_SUCCESS:
      return {
        ...state,
        agents: [
          ...state.agents,
          action.payload.agent,
        ],
      };

    case acts.DELETE_USER_AGENT_SUCCESS:
      return {
        ...state,
        agents: [
          ...state.agents
            .filter(agent => agent.id !== action.payload.agentId),
        ],
      };

    case acts.DELETE_USER_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: [
          ...state.providers
            .filter(provider => provider.id !== action.payload.providerId),
        ],
      };

    /* Custom fields */
    case acts.SET_META_CUSTOM_FIELDS:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          data: action.payload,
        },
      };

    case acts.GET_META_CUSTOM_FIELDS_LOADING:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetching: true,
        },
      };

    case acts.GET_META_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_META_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetching: false,
        },
      };

    /* Bulk modal */
    case acts.OPEN_META_BULK_MODAL:
      return {
        ...state,
        bulkModal: {
          ...state.bulkModal,
          opened: true,
          entity: action.payload.entity,
          ids: action.payload.ids,
        },
      };

    case acts.GET_META_BULK_MODAL_DATA_LOADING:
      return {
        ...state,
        bulkModal: {
          ...state.bulkModal,
          isFetchingData: true,
        },
      };

    case acts.SET_META_BULK_MODAL_DATA:
      return {
        ...state,
        bulkModal: {
          ...state.bulkModal,
          data: action.payload,
        },
      };

    case acts.GET_META_BULK_MODAL_DATA_SUCCESS:
      return {
        ...state,
        bulkModal: {
          ...state.bulkModal,
          isFetchingData: false,
        },
      };

    case acts.UPDATE_META_BULK_LOADING:
      return {
        ...state,
        bulkModal: {
          ...state.bulkModal,
          isUpdating: true,
        },
      };

    case acts.UPDATE_META_BULK_SUCCESS:
      return {
        ...state,
        bulkModal: {
          ...state.bulkModal,
          isUpdating: false,
        },
      };

    case acts.CLOSE_META_BULK_MODAL:
    case acts.GET_META_BULK_MODAL_DATA_FAILURE:
    case acts.UPDATE_META_BULK_FAILURE:
      return {
        ...state,
        bulkModal: {
          ...initialState.bulkModal,
        },
      };

    /* Filter Bar */
    case acts.TOGGLE_META_FILTER_BAR:
      return {
        ...state,
        filterBar: {
          ...state.filterBar,
          isOpened: action.payload,
        },
      };

    /* Search */
    case acts.SET_META_SEARCH_SETTINGS:
      return {
        ...state,
        searchSettings: {
          ...state.searchSettings,
          ...action.payload,
        },
      };

    // Reset initialization when user logs out
    case acts.LOGOUT_USER_SUCCESS:
      return {
        ...initialState,
      };

    /* Meta Peoples */
    case acts.SET_META_PEOPLES:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          ...action.payload,
        },
      };

    case acts.GET_META_PEOPLES_LOADING:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isFetching: true,
        },
      };

    case acts.GET_META_PEOPLES_SUCCESS:
    case acts.GET_META_PEOPLES_FAILURE:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isFetching: false,
        },
      };

    case acts.CREATE_META_PEOPLE_LOADING:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isCreating: true,
        },
      };

    case acts.CREATE_META_PEOPLE_SUCCESS:
    case acts.CREATE_META_PEOPLE_FAILURE:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_PEOPLE_LOADING:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isModifying: [
            ...state.peoples.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_PEOPLE_SUCCESS:
    case acts.MODIFY_META_PEOPLE_FAILURE:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isModifying: [
            ...state.peoples.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_PEOPLES_LOADING:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_PEOPLES_SUCCESS:
    case acts.DELETE_META_PEOPLES_FAILURE:
      return {
        ...state,
        peoples: {
          ...state.peoples,
          isDeleting: false,
        },
      };

    /* Meta Publishers */
    case acts.SET_META_PUBLISHERS:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          ...action.payload,
        },
      };

    case acts.GET_META_PUBLISHERS_LOADING:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isFetching: true,
        },
      };

    case acts.GET_META_PUBLISHERS_SUCCESS:
    case acts.GET_META_PUBLISHERS_FAILURE:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isFetching: false,
        },
      };

    case acts.CREATE_META_PUBLISHER_LOADING:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isCreating: true,
        },
      };

    case acts.CREATE_META_PUBLISHER_SUCCESS:
    case acts.CREATE_META_PUBLISHER_FAILURE:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_PUBLISHER_LOADING:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isModifying: [
            ...state.publishers.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_PUBLISHER_SUCCESS:
    case acts.MODIFY_META_PUBLISHER_FAILURE:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isModifying: [
            ...state.publishers.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_PUBLISHER_FILE_PROGRESS:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          uploads: [
            ...state.publishers.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_PUBLISHER_FILE_LOADING:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          uploads: [
            ...state.publishers.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_PUBLISHER_FILE_SUCCESS:
    case acts.UPLOAD_META_PUBLISHER_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_PUBLISHER_FILE:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          uploads: [
            ...state.publishers.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_PUBLISHERS_LOADING:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_PUBLISHERS_SUCCESS:
    case acts.DELETE_META_PUBLISHERS_FAILURE:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_PUBLISHERS_LOADING:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_PUBLISHERS_SUCCESS:
    case acts.EXPORT_META_PUBLISHERS_FAILURE:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isExporting: false,
        },
      };

    case acts.EXPORT_META_PUBLISHER_TRACKS_LOADING:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_PUBLISHER_TRACKS_SUCCESS:
    case acts.EXPORT_META_PUBLISHER_TRACKS_FAILURE:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          isExportingTracks: false,
        },
      };

    /* Meta Labels */
    case acts.SET_META_LABELS:
      return {
        ...state,
        labels: {
          ...state.labels,
          ...action.payload,
        },
      };

    case acts.GET_META_LABELS_LOADING:
      return {
        ...state,
        labels: {
          ...state.labels,
          isFetching: true,
        },
      };

    case acts.GET_META_LABELS_SUCCESS:
    case acts.GET_META_LABELS_FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          isFetching: false,
        },
      };

    case acts.CREATE_META_LABEL_LOADING:
      return {
        ...state,
        labels: {
          ...state.labels,
          isCreating: true,
        },
      };

    case acts.CREATE_META_LABEL_SUCCESS:
    case acts.CREATE_META_LABEL_FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_LABEL_LOADING:
      return {
        ...state,
        labels: {
          ...state.labels,
          isModifying: [
            ...state.labels.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_LABEL_SUCCESS:
    case acts.MODIFY_META_LABEL_FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          isModifying: [
            ...state.labels.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_LABEL_FILE_PROGRESS:
      return {
        ...state,
        labels: {
          ...state.labels,
          uploads: [
            ...state.labels.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_LABEL_FILE_LOADING:
      return {
        ...state,
        labels: {
          ...state.labels,
          uploads: [
            ...state.labels.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_LABEL_FILE_SUCCESS:
    case acts.UPLOAD_META_LABEL_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_LABEL_FILE:
      return {
        ...state,
        labels: {
          ...state.labels,
          uploads: [
            ...state.labels.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_LABELS_LOADING:
      return {
        ...state,
        labels: {
          ...state.labels,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_LABELS_SUCCESS:
    case acts.DELETE_META_LABELS_FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_LABELS_LOADING:
      return {
        ...state,
        labels: {
          ...state.labels,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_LABELS_SUCCESS:
    case acts.EXPORT_META_LABELS_FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          isExporting: false,
        },
      };

    case acts.EXPORT_META_LABEL_TRACKS_LOADING:
      return {
        ...state,
        labels: {
          ...state.labels,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_LABEL_TRACKS_SUCCESS:
    case acts.EXPORT_META_LABEL_TRACKS_FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          isExportingTracks: false,
        },
      };

    /* Meta Artists */
    case acts.SET_META_ARTISTS:
      return {
        ...state,
        artists: {
          ...state.artists,
          ...action.payload,
        },
      };

    case acts.GET_META_ARTISTS_LOADING:
      return {
        ...state,
        artists: {
          ...state.artists,
          isFetching: true,
        },
      };

    case acts.GET_META_ARTISTS_SUCCESS:
    case acts.GET_META_ARTISTS_FAILURE:
      return {
        ...state,
        artists: {
          ...state.artists,
          isFetching: false,
        },
      };

    case acts.CREATE_META_ARTIST_LOADING:
      return {
        ...state,
        artists: {
          ...state.artists,
          isCreating: true,
        },
      };

    case acts.CREATE_META_ARTIST_SUCCESS:
    case acts.CREATE_META_ARTIST_FAILURE:
      return {
        ...state,
        artists: {
          ...state.artists,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_ARTIST_LOADING:
      return {
        ...state,
        artists: {
          ...state.artists,
          isModifying: [
            ...state.artists.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_ARTIST_SUCCESS:
    case acts.MODIFY_META_ARTIST_FAILURE:
      return {
        ...state,
        artists: {
          ...state.artists,
          isModifying: [
            ...state.artists.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_ARTIST_FILE_PROGRESS:
      return {
        ...state,
        artists: {
          ...state.artists,
          uploads: [
            ...state.artists.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_ARTIST_FILE_LOADING:
      return {
        ...state,
        artists: {
          ...state.artists,
          uploads: [
            ...state.artists.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_ARTIST_FILE_SUCCESS:
    case acts.UPLOAD_META_ARTIST_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_ARTIST_FILE:
      return {
        ...state,
        artists: {
          ...state.artists,
          uploads: [
            ...state.artists.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_ARTISTS_LOADING:
      return {
        ...state,
        artists: {
          ...state.artists,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_ARTISTS_SUCCESS:
    case acts.DELETE_META_ARTISTS_FAILURE:
      return {
        ...state,
        artists: {
          ...state.artists,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_ARTISTS_LOADING:
      return {
        ...state,
        artists: {
          ...state.artists,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_ARTISTS_SUCCESS:
    case acts.EXPORT_META_ARTISTS_FAILURE:
      return {
        ...state,
        artists: {
          ...state.artists,
          isExporting: false,
        },
      };

    case acts.EXPORT_META_ARTIST_TRACKS_LOADING:
      return {
        ...state,
        artists: {
          ...state.artists,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_ARTIST_TRACKS_SUCCESS:
    case acts.EXPORT_META_ARTIST_TRACKS_FAILURE:
      return {
        ...state,
        artists: {
          ...state.artists,
          isExportingTracks: false,
        },
      };

    /* Meta Catalogs */
    case acts.SET_META_CATALOGS:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          ...action.payload,
        },
      };

    case acts.GET_META_CATALOGS_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isFetching: true,
        },
      };

    case acts.GET_META_CATALOGS_SUCCESS:
    case acts.GET_META_CATALOGS_FAILURE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isFetching: false,
        },
      };

    case acts.CREATE_META_CATALOG_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isCreating: true,
        },
      };

    case acts.CREATE_META_CATALOG_SUCCESS:
    case acts.CREATE_META_CATALOG_FAILURE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_CATALOG_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isModifying: [
            ...state.catalogs.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_CATALOG_SUCCESS:
    case acts.MODIFY_META_CATALOG_FAILURE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isModifying: [
            ...state.catalogs.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_CATALOG_FILE_PROGRESS:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          uploads: [
            ...state.catalogs.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_CATALOG_FILE_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          uploads: [
            ...state.catalogs.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_CATALOG_FILE_SUCCESS:
    case acts.UPLOAD_META_CATALOG_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_CATALOG_FILE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          uploads: [
            ...state.catalogs.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_CATALOGS_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_CATALOGS_SUCCESS:
    case acts.DELETE_META_CATALOGS_FAILURE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_CATALOGS_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_CATALOGS_SUCCESS:
    case acts.EXPORT_META_CATALOGS_FAILURE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isExporting: false,
        },
      };

    case acts.EXPORT_META_CATALOG_TRACKS_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_CATALOG_TRACKS_SUCCESS:
    case acts.EXPORT_META_CATALOG_TRACKS_FAILURE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isExportingTracks: false,
        },
      };

    case acts.ARCHIVE_META_CATALOG_TRACKS_LOADING:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isArchivingTracks: true,
        },
      };

    case acts.ARCHIVE_META_CATALOG_TRACKS_SUCCESS:
    case acts.ARCHIVE_META_CATALOG_TRACKS_FAILURE:
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          isArchivingTracks: false,
        },
      };

    /* Meta Albums */
    case acts.SET_META_ALBUMS:
      return {
        ...state,
        albums: {
          ...state.albums,
          ...action.payload,
        },
      };

    case acts.GET_META_ALBUMS_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isFetching: true,
        },
      };

    case acts.GET_META_ALBUMS_SUCCESS:
    case acts.GET_META_ALBUMS_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isFetching: false,
        },
      };

    case acts.CREATE_META_ALBUM_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isCreating: true,
        },
      };

    case acts.CREATE_META_ALBUM_SUCCESS:
    case acts.CREATE_META_ALBUM_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_ALBUM_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isModifying: [
            ...state.albums.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_ALBUM_SUCCESS:
    case acts.MODIFY_META_ALBUM_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isModifying: [
            ...state.albums.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_ALBUM_FILE_PROGRESS:
      return {
        ...state,
        albums: {
          ...state.albums,
          uploads: [
            ...state.albums.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_ALBUM_FILE_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          uploads: [
            ...state.albums.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_ALBUM_FILE_SUCCESS:
    case acts.UPLOAD_META_ALBUM_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_ALBUM_FILE:
      return {
        ...state,
        albums: {
          ...state.albums,
          uploads: [
            ...state.albums.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_ALBUMS_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_ALBUMS_SUCCESS:
    case acts.DELETE_META_ALBUMS_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_ALBUMS_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_ALBUMS_SUCCESS:
    case acts.EXPORT_META_ALBUMS_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isExporting: false,
        },
      };

    case acts.EXPORT_META_ALBUM_TRACKS_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_ALBUM_TRACKS_SUCCESS:
    case acts.EXPORT_META_ALBUM_TRACKS_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isExportingTracks: false,
        },
      };

    case acts.ARCHIVE_META_ALBUM_TRACKS_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isArchivingTracks: true,
        },
      };

    case acts.ARCHIVE_META_ALBUM_TRACKS_SUCCESS:
    case acts.ARCHIVE_META_ALBUM_TRACKS_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isArchivingTracks: false,
        },
      };

    case acts.DOWNLOAD_META_ALBUM_COPYRIGHT_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isDownloadingCopyright: true,
        },
      };

    case acts.DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS:
    case acts.DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isDownloadingCopyright: false,
        },
      };

    /* Meta Playlists */
    case acts.SET_META_PLAYLISTS:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          ...action.payload,
        },
      };

    case acts.GET_META_PLAYLISTS_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetching: true,
        },
      };

    case acts.GET_META_PLAYLISTS_SUCCESS:
    case acts.GET_META_PLAYLISTS_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetching: false,
        },
      };

    case acts.CREATE_META_PLAYLIST_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isCreating: true,
        },
      };

    case acts.CREATE_META_PLAYLIST_SUCCESS:
    case acts.CREATE_META_PLAYLIST_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_PLAYLIST_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isModifying: [
            ...state.playlists.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_PLAYLIST_SUCCESS:
    case acts.MODIFY_META_PLAYLIST_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isModifying: [
            ...state.playlists.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_PLAYLIST_FILE_PROGRESS:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          uploads: [
            ...state.playlists.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_PLAYLIST_FILE_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          uploads: [
            ...state.playlists.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_PLAYLIST_FILE_SUCCESS:
    case acts.UPLOAD_META_PLAYLIST_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_PLAYLIST_FILE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          uploads: [
            ...state.playlists.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_PLAYLISTS_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_PLAYLISTS_SUCCESS:
    case acts.DELETE_META_PLAYLISTS_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_PLAYLISTS_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_PLAYLISTS_SUCCESS:
    case acts.EXPORT_META_PLAYLISTS_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isExporting: false,
        },
      };

    case acts.EXPORT_META_PLAYLIST_TRACKS_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_PLAYLIST_TRACKS_SUCCESS:
    case acts.EXPORT_META_PLAYLIST_TRACKS_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isExportingTracks: false,
        },
      };

    case acts.ARCHIVE_META_PLAYLIST_TRACKS_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isArchivingTracks: true,
        },
      };

    case acts.ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS:
    case acts.ARCHIVE_META_PLAYLIST_TRACKS_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isArchivingTracks: false,
        },
      };

    case acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isDownloadingCopyright: true,
        },
      };

    case acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS:
    case acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isDownloadingCopyright: false,
        },
      };

    /* Meta Refs */
    case acts.ADD_META_TRACKS_TO_REF_LOADING:
    case acts.REMOVE_META_TRACKS_FROM_REF_LOADING:
      return {
        ...state,
        refs: {
          ...state.refs,
          isHandlingTracks: [
            ...state.refs.isHandlingTracks,
            action.payload.id,
          ],
        },
      };

    case acts.ADD_META_TRACKS_TO_REF_SUCCESS:
    case acts.ADD_META_TRACKS_TO_REF_FAILURE:
    case acts.REMOVE_META_TRACKS_FROM_REF_SUCCESS:
    case acts.REMOVE_META_TRACKS_FROM_REF_FAILURE:
      return {
        ...state,
        refs: {
          ...state.refs,
          isHandlingTracks: [
            ...state.refs.isHandlingTracks
              .filter(refId => refId !== action.payload.id),
          ],
        },
      };

    /* Meta Pitchs */
    case acts.SET_META_PITCHS:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          ...action.payload,
        },
      };

    case acts.GET_META_PITCHS_LOADING:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isFetching: true,
        },
      };

    case acts.GET_META_PITCHS_SUCCESS:
    case acts.GET_META_PITCHS_FAILURE:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isFetching: false,
        },
      };

    case acts.CREATE_META_PITCH_LOADING:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isCreating: true,
        },
      };

    case acts.CREATE_META_PITCH_SUCCESS:
    case acts.CREATE_META_PITCH_FAILURE:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_PITCH_LOADING:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isModifying: [
            ...state.pitchs.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_PITCH_SUCCESS:
    case acts.MODIFY_META_PITCH_FAILURE:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isModifying: [
            ...state.pitchs.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_PITCHS_LOADING:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_PITCHS_SUCCESS:
    case acts.DELETE_META_PITCHS_FAILURE:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_PITCHS_LOADING:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_PITCHS_SUCCESS:
    case acts.EXPORT_META_PITCHS_FAILURE:
      return {
        ...state,
        pitchs: {
          ...state.pitchs,
          isExporting: false,
        },
      };

    /* Meta Briefs */
    case acts.SET_META_BRIEFS:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          ...action.payload,
        },
      };

    case acts.GET_META_BRIEFS_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isFetching: true,
        },
      };

    case acts.GET_META_BRIEFS_SUCCESS:
    case acts.GET_META_BRIEFS_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isFetching: false,
        },
      };

    case acts.CREATE_META_BRIEF_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isCreating: true,
        },
      };

    case acts.CREATE_META_BRIEF_SUCCESS:
    case acts.CREATE_META_BRIEF_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_BRIEF_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isModifying: [
            ...state.briefs.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_BRIEF_SUCCESS:
    case acts.MODIFY_META_BRIEF_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isModifying: [
            ...state.briefs.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_BRIEF_FILE_PROGRESS:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          uploads: [
            ...state.briefs.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_BRIEF_FILE_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          uploads: [
            ...state.briefs.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_BRIEF_FILE_SUCCESS:
    case acts.UPLOAD_META_BRIEF_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_BRIEF_FILE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          uploads: [
            ...state.briefs.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_BRIEFS_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_BRIEFS_SUCCESS:
    case acts.DELETE_META_BRIEFS_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_BRIEFS_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isExporting: true,
        },
      };

    case acts.EXPORT_META_BRIEFS_SUCCESS:
    case acts.EXPORT_META_BRIEFS_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isExporting: false,
        },
      };

    case acts.EXPORT_META_BRIEF_TRACKS_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_BRIEF_TRACKS_SUCCESS:
    case acts.EXPORT_META_BRIEF_TRACKS_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isExportingTracks: false,
        },
      };

    case acts.ARCHIVE_META_BRIEF_TRACKS_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isArchivingTracks: true,
        },
      };

    case acts.ARCHIVE_META_BRIEF_TRACKS_SUCCESS:
    case acts.ARCHIVE_META_BRIEF_TRACKS_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isArchivingTracks: false,
        },
      };

    case acts.DOWNLOAD_META_BRIEF_COPYRIGHT_LOADING:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isDownloadingCopyright: true,
        },
      };

    case acts.DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS:
    case acts.DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isDownloadingCopyright: false,
        },
      };

    /* Meta Tracks */
    case acts.SET_META_TRACKS:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          ...action.payload,
        },
      };

    // NOTE: This keeps similarity score when refreshing
    case acts.REFRESH_META_TRACKS:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          data: [
            ...action.payload.data.map((track, i) => ({
              ...track,
              ...(state.tracks.data[i]?.similarity
                ? { similarity: state.tracks.data[i]?.similarity }
                : {}),
            })),
          ],
        },
      };

    case acts.GET_META_TRACKS_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isFetching: true,
        },
      };

    case acts.GET_META_TRACKS_SUCCESS:
    case acts.GET_META_TRACKS_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isFetching: false,
        },
      };

    case acts.CREATE_META_TRACK_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isCreating: true,
        },
      };

    case acts.CREATE_META_TRACK_SUCCESS:
    case acts.CREATE_META_TRACK_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isCreating: false,
        },
      };

    case acts.MODIFY_META_TRACK_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isModifying: [
            ...state.tracks.isModifying,
            action.payload.id,
          ],
        },
      };

    case acts.MODIFY_META_TRACK_SUCCESS:
    case acts.MODIFY_META_TRACK_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isModifying: [
            ...state.tracks.isModifying.filter(docId => docId !== action.payload.id),
          ],
        },
      };

    case acts.SET_UPLOAD_META_TRACK_FILE_PROGRESS:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          uploads: [
            ...state.tracks.uploads
              .map((u) => {
                if (u.id !== action.payload.id) return u;
                return { ...u, progress: action.payload.progress };
              }),
          ],
        },
      };

    case acts.UPLOAD_META_TRACK_FILE_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          uploads: [
            ...state.tracks.uploads,
            { id: action.payload.id, progress: 0 },
          ],
        },
      };

    case acts.UPLOAD_META_TRACK_FILE_SUCCESS:
    case acts.UPLOAD_META_TRACK_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_META_TRACK_FILE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          uploads: [
            ...state.tracks.uploads.filter(u => u.id !== action.payload.id),
          ],
        },
      };

    case acts.DELETE_META_TRACKS_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_TRACKS_SUCCESS:
    case acts.DELETE_META_TRACKS_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isDeleting: false,
        },
      };

    case acts.EXPORT_META_TRACK_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isExportingTracks: true,
        },
      };

    case acts.EXPORT_META_TRACK_SUCCESS:
    case acts.EXPORT_META_TRACK_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isExportingTracks: false,
        },
      };

    case acts.ARCHIVE_META_TRACK_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isArchivingTracks: true,
        },
      };

    case acts.ARCHIVE_META_TRACK_SUCCESS:
    case acts.ARCHIVE_META_TRACK_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isArchivingTracks: false,
        },
      };

    case acts.DOWNLOAD_META_TRACK_AUDIOFILE_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isDownloadingAudiofile: true,
        },
      };

    case acts.DOWNLOAD_META_TRACK_AUDIOFILE_SUCCESS:
    case acts.DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isDownloadingAudiofile: false,
        },
      };

    case acts.DOWNLOAD_META_TRACK_COPYRIGHT_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isDownloadingCopyright: true,
        },
      };

    case acts.DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS:
    case acts.DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isDownloadingCopyright: false,
        },
      };

    case acts.LAUNCH_SIMILARITY_SEARCH_LOADING:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isLauchingSimilaritySearch: true,
        },
      };

    case acts.LAUNCH_SIMILARITY_SEARCH_SUCCESS:
    case acts.LAUNCH_SIMILARITY_SEARCH_FAILURE:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          isLauchingSimilaritySearch: false,
        },
      };

    /* Meta Tracks */
    case acts.SET_META_INGESTIONS:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          ...action.payload,
        },
      };

    case acts.GET_META_INGESTIONS_LOADING:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isFetching: true,
        },
      };

    case acts.GET_META_INGESTIONS_SUCCESS:
    case acts.GET_META_INGESTIONS_FAILURE:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isFetching: false,
        },
      };

    case acts.CREATE_META_INGESTION_LOADING:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isCreating: true,
        },
      };

    case acts.CREATE_META_INGESTION_SUCCESS:
    case acts.CREATE_META_INGESTION_FAILURE:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isCreating: false,
        },
      };

    case acts.DELETE_META_INGESTIONS_LOADING:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isDeleting: true,
        },
      };

    case acts.DELETE_META_INGESTIONS_SUCCESS:
    case acts.DELETE_META_INGESTIONS_FAILURE:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isDeleting: false,
        },
      };

    case acts.UPDATE_META_INGESTION_POST_JOB:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          data: [
            ...state.ingestions.data.map((ingestion) => {
              if (action.payload.id !== ingestion.id) return ingestion;
              return action.payload;
            }),
          ],
        },
      };

    case acts.UPDATE_META_INGESTION_POST_JOB_LOADING:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isUpdatingPostJob: true,
        },
      };

    case acts.UPDATE_META_INGESTION_POST_JOB_SUCCESS:
    case acts.UPDATE_META_INGESTION_POST_JOB_FAILURE:
      return {
        ...state,
        ingestions: {
          ...state.ingestions,
          isUpdatingPostJob: false,
        },
      };

    default:
      return state;
  }
}
