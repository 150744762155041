// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { DateDisplayer } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../config/i18n';

import Link from '../../other/link';
import Modal from '../modal';
import MewoInput from '../mewoInput';
import ModoStatus from './status';

import * as pth from '../../../helpers/proptypes';

import {
  Wrapper,
  BackIcon,
  BackLink,
  FlexWrapper,
  RightSection,
  SiteTitle,
  LastTimeModified,
  ChangePlanButton,
  GoToSiteButton,
  GoToIcon,
} from './styles';
import {
  ModalContent,
  ModalDesc,
  ModalTitle,
  StyledButton,
} from '../../layouts/global.styles';
// NOTE: Modal styles is same as listing
import {
  ModalForm,
  PricingHelper,
  StyledListInput,
} from '../../layouts/modo/listing/styles';

// =============================
// Component
// =============================

class SubHeader extends Component {
  static propTypes = {
    /** SubHeader content */
    children: PropTypes.node,
    /** Modo data */
    modoData: pth.modoConfig.isRequired,
    /** Translation strings. */
    lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Modify Modo plan action */
    modifyModoPlan: PropTypes.func.isRequired,
    /** If True, status is ON */
    status: PropTypes.bool.isRequired,
    /** Activation tooltip */
    activationTooltip: PropTypes.node.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: null,
  };

  state = {
    modals: {
      modifyConfig: { active: false },
    },
  };

  triggerModal = (modal, status, data = {}) => {
    this.setState(state => ({
      modals: {
        ...state.modals,
        [modal]: { active: status, data: status ? data : {} },
      },
    }));
  };

  modifyConfigPlan = (data) => {
    const { modifyModoPlan } = this.props;

    this.triggerModal('modifyConfig', false);
    return modifyModoPlan(data);
  };

  render() {
    const { modoData, lang, status, activationTooltip, children, t } = this.props;
    const { modals: { modifyConfig } } = this.state;

    const currentPlan = _get(modoData, 'permission_type', 'modo_bronze')
      .replace('_no_billing', '')
      .replace('_billing', '');

    const updatedAt = _get(modoData, 'updated_at', new Date());

    return (
      <Wrapper>
        <BackLink href="/modo">
          <BackIcon />
          {t('components:modo_sub_header.back_to_modo_list')}
        </BackLink>

        <FlexWrapper>
          <div>
            <SiteTitle>{_get(modoData, 'website_title')}</SiteTitle>
            <LastTimeModified>
              {t('components:modo_sub_header.last_modified')}
              :&nbsp;
              <DateDisplayer variant="relative" date={new Date(updatedAt).toISOString()} />
            </LastTimeModified>
          </div>
          <RightSection>
            <ModoStatus
              lang={lang.ModoStatus}
              status={status}
              activationTooltip={activationTooltip}
            />
            <ChangePlanButton onClick={() => this.triggerModal('modifyConfig', true)}>
              {t('components:modo_sub_header.change_plan')}
            </ChangePlanButton>
            {_get(modoData, 'active') && (
              <Link
                href={`https://${_get(modoData, 'url_config.website_url')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GoToSiteButton>
                  <GoToIcon />
                  {t('components:modo_sub_header.go_to_website')}
                </GoToSiteButton>
              </Link>
            )}
          </RightSection>
        </FlexWrapper>

        {children}

        <Modal open={modifyConfig.active} onClose={() => this.triggerModal('modifyConfig', false)}>
          <ModalContent width="35">
            <ModalTitle>{t('components:modo_sub_header.modify_plan')}</ModalTitle>
            <ModalDesc>{t('components:modo_sub_header.modify_plan_notice')}</ModalDesc>

            <Form
              onSubmit={this.modifyConfigPlan}
              initialValues={{ plan: currentPlan }}
            >
              {({ handleSubmit, submitting }) => (
                <ModalForm onSubmit={handleSubmit}>
                  <Field
                    name="plan"
                    component={MewoInput}
                    use={StyledListInput}
                    options={[
                      {
                        value: 'modo_bronze',
                        price: parseInt(process.env.MODO_BRONZE_PRICE, 10),
                        label: t('common:misc.modo_bronze'),
                        currency: '€',
                        renewEach: 'month',
                      },
                      {
                        value: 'modo_silver',
                        price: parseInt(process.env.MODO_SILVER_PRICE, 10),
                        label: t('common:misc.modo_silver'),
                        currency: '€',
                        renewEach: 'month',
                      },
                      {
                        value: 'modo_gold',
                        price: parseInt(process.env.MODO_GOLD_PRICE, 10),
                        label: t('common:misc.modo_gold'),
                        currency: '€',
                        renewEach: 'month',
                      },
                    ]}
                  />
                  <PricingHelper>
                    {t('pages:modo.listing.contact_us_for_plan_info')}
                    &nbsp;
                    <a href={`mailto:${process.env.SUPPORT_MAILER}`}>
                      {process.env.SUPPORT_MAILER}
                    </a>
                  </PricingHelper>
                  <StyledButton type="submit" disabled={submitting} fullWidth>
                    {t('common:form.modify')}
                  </StyledButton>
                </ModalForm>
              )}
            </Form>
          </ModalContent>
        </Modal>
      </Wrapper>
    );
  }
}

export default withTranslation(['pages', 'components', 'common'])(SubHeader);
