// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Div } from '@mewo/components';

// =============================
// Styles
// =============================

export const Inline = styled(Div)`
  display: inline-block;
  vertical-align: top;
`;

export const CommonBox = styled(Div)`
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
`;

export const UserSingleStatBox = styled(CommonBox)`
  height: 11rem;
  width: 20rem;
`;

export const UserLineStatBox = styled(CommonBox)`
  height: 24rem;
  width: 46rem;
`;

export const SearchLineStatBox = styled(CommonBox)`
  height: 38rem;
  width: ${({ width }) => width || '68rem'};
`;

export const UserGeoMapStatBox = styled(CommonBox)`
  height: 41rem;
  width: 68rem;
`;

export const UserPieStatBox = styled(CommonBox)`
  height: 36rem;
  width: 20rem;
`;

export const StatTitle = styled(Div)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const StatNumberValue = styled(Div)`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  width: 100%;
`;

export const TitleAsSeparator = styled(Div)`
  color: #191919;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  max-width: 30rem;
`;
