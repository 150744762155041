// =============================
// Imports
// =============================

import { Tooltip } from '@mewo/components';

// =============================
// Component
// =============================

const TooltipWithProps = props => <Tooltip {...props} />;

export default TooltipWithProps;
