// =============================
// Imports
// =============================

import { alpha } from '@material-ui/core';
import styled, { css } from 'styled-components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div``;

export const Item = styled.div`
  align-items: center;
  border: 0.2rem solid ${({ theme }) => alpha(theme.colors.text, 0.6)};
  border-radius: 1rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4rem 1fr 8rem;
  grid-gap: 1.2rem;
  padding: 1.6rem;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &:hover {
    box-shadow: 0 0.2rem 0.4rem 0.1rem ${({ theme }) => theme.colors.boxShadow};
  }

  ${({ selected }) => (selected
    ? css`
          border: 0.2rem solid ${({ theme }) => theme.colors.primary};
          box-shadow: 0 0.2rem 0.4rem 0.1rem ${({ theme }) => theme.colors.boxShadow};
        `
    : css`
          border: 0.2rem solid ${({ theme }) => alpha(theme.colors.text, 0.6)};
        `)};
`;

export const ItemCheckBox = styled.div`
  background-color: #fff;
  border: 0.2rem solid ${({ theme }) => alpha(theme.colors.text, 0.6)};
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;

  ${({ selected, theme }) => selected
    && css`
      border: 0.4rem solid ${theme.colors.primary};
    `};
`;

export const ItemName = styled.div`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const ItemPrice = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  justify-content: flex-start;
`;

export const RenewDate = styled.div`
  color: ${({ theme }) => alpha(theme.colors.text, 0.6)};
  font-size: 1.2rem;
  margin-left: 0.4rem;
`;
