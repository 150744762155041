// =============================
// Imports
// =============================

import { memo } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Button, Div, Icons } from '@mewo/components';

import { DocumentBody } from './panels/common.styles';
import Pagination from '../presentationals/pagination';

// =============================
// Layout Styles
// =============================

export const GlobalStyle = memo(createGlobalStyle`
  /** We use wildcard because is-locked- might not be the 1st class on body. */
  body[class*="is-locked-"] {
    /** We need to apply those style to DocumentBody since its the element
    doing the scroll on Admin panels instead of body. */
    ${DocumentBody} {
      overflow: hidden;
      position: relative;
    }
  }
`);

export const FullSize = styled.div`
  height: 100vh;
  max-width: 100%; /** https://stackoverflow.com/a/23367686 */
  width: 100vw;
`;

export const InheritSize = styled.div`
  height: 100%;
  width: 100%;
`;

export const WrapperPageNotFound = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;

  /** Fallback for when parent is not flex. (like localhost:3000/whatever) */
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const CenteredWrapper = styled(Div)`
  display: flex;
  margin: auto;
`;

// =============================
// Input Styles
// =============================

export const InputWrapper = styled(Div)`
  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }

  > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const InputDouble = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 1.2rem;
    width: calc(50% - 1.2rem);
  }

  > *:last-child {
    margin-left: 1.2rem;
    width: calc(50% - 1.2rem);
  }
`;

export const InputTriple = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 1.2rem;
    width: calc(100% / 3 - 1.2rem);
  }

  > *:nth-child(2) {
    margin: 0 1.2rem;
    width: calc(100% / 3 - 1.2rem);
  }

  > *:last-child {
    margin-left: 1.2rem;
    width: calc(100% / 3 - 1.2rem);
  }
`;

export const InputQuadruple = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 1.2rem;
    width: calc(25% - 1.2rem);
  }
  > *:nth-child(2) {
    margin: 0 1.2rem;
    width: calc(25% - 1.2rem);
  }

  > *:nth-child(3) {
    margin: 0 1.2rem;
    width: calc(25% - 1.2rem);
  }

  > *:last-child {
    margin-left: 1.2rem;
    width: calc(25% - 1.2rem);
  }
`;

// =============================
// Modal Styles
// =============================

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  line-height: 1.4;

  ${({ width }) => width
    && css`
      width: ${width}rem;
    `}
`;

export const ModalTitle = styled(Div)`
  color: #191919;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 1.6rem;
  text-align: center;
`;

export const ModalDesc = styled(Div)`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 4.8rem;
  text-align: center;
  width: 60rem;
`;

// =============================
// Button Styles
// =============================

export const StyledButton = styled(Button).attrs(({ color, fillVariant }) => ({
  color: color || 'primary',
  fillVariant: fillVariant || 'plain',
}))``;

export const ButtonWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: 1.2rem;
  }

  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
    `};
`;

// =============================
// Others Styles
// =============================

export const StyledPagination = styled(Pagination)`
  margin-top: 4rem;
`;

export const NoResult = styled(Icons.ErrorNoResult).attrs({
  fill: '#b2b2b2',
})`
  &:not(:last-child) {
    margin: 0 auto 1.2rem;
  }
`;

export const ListingVeil = styled(Div)`
  opacity: ${({ active }) => (active ? 0.7 : 1)};
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
`;
