// =============================
// Imports
// =============================

// External dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';

// Config
import { withTranslation } from '../../../config/i18n';

// Components
import InputForLanguage from '../inputForLanguage';

// Helpers
import { getLanguagesForInputWithLanguageSwitcher, getLocale } from '../../../helpers/misc';
import * as pth from '../../../helpers/proptypes';

// Styles
import { Wrapper, StyledLangSwitcher } from './styles';

// =============================
// Component
// =============================

class InputWithLanguage extends Component {
  static propTypes = {
    value: pth.descriptions.isRequired,
    label: PropTypes.string.isRequired,
    defaultLanguage: PropTypes.string.isRequired,
    languages: PropTypes.objectOf(pth.language),
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    languages: undefined,
  };

  constructor(props) {
    super(props);
    const { languages, defaultLanguage, value: translations } = this.props;

    this.state = {
      locale: translations.length
        ? getLocale(
          languages,
          translations.map(t => t.locale),
        )
        : defaultLanguage,
    };
  }

  changeLocale = (nextLocale) => {
    this.setState({ locale: nextLocale });
  };

  render() {
    const { value, label, languages, onChange, t, ...rest } = this.props;
    const { locale } = this.state;

    return (
      <Wrapper>
        <InputForLanguage
          {...rest}
          label={label}
          onChange={onChange}
          value={value}
          locale={locale}
        />
        <StyledLangSwitcher
          languages={getLanguagesForInputWithLanguageSwitcher(languages, value)}
          activeLang={locale}
          onChange={this.changeLocale}
        />
      </Wrapper>
    );
  }
}

export default withTranslation(['components'])(InputWithLanguage);
