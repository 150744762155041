// =============================
// Imports
// =============================

import { required, mustBeEmail, mustBeUniqueInList } from '../index';

// =============================
// Validator
// =============================

const validator = {
  full_name: required,
  artist_email: mustBeEmail,
  aliases: compareValues => mustBeUniqueInList(compareValues),
};

export default validator;
