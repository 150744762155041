// =============================
// Imports
// =============================

import { Component } from 'react';

import { StyledCheckboxInput } from './styles';

// =============================
// Component
// =============================

class CheckboxInput extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <StyledCheckboxInput {...rest} />
    );
  }
}

export default CheckboxInput;
