// =============================
// Imports
// =============================

import { required } from '../index';

// =============================
// Validator
// =============================

const validator = {
  label_name: required,
};

export default validator;
