// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Div } from '@mewo/components';

import { StyledButton } from '../../global.styles';
import Audiofile from '../icons/Audiofile';
import Metadata from '../icons/Metadata';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const DocumentHeader = styled.div`
  width: 100%;
  padding: 0 3.2rem;
`;

export const DocumentInnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  margin-top: 2rem;
  width: 100%;
`;

export const DocumentName = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: #191919;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.2;
  margin-bottom: 1rem;
  width: 60rem;
`;

export const DocumentCreatedAt = styled.div`
  font-size: 1.2rem;
  line-height: 1.2;
`;

export const DocumentInformations = styled.div``;

export const StepsHeader = styled.div`
  border-bottom: 0.1rem solid #e5e5e5;
  display: flex;
  margin-top: 2rem;
  height: 3rem;
  justify-content: space-between;
  width: 100%;
`;

export const StepTitle = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  transition: ${({ theme }) => theme.transition.fast};

  ${({ active }) => active && css`
    color: #191919;
    font-size: 1.4rem;
  `};
`;

export const DocumentBody = styled.div.attrs({
  id: 'panel-body',
})`
  height: calc(100% - 16.5rem);
  overflow: auto;
  padding: 0 3.2rem 3.2rem;
  width: 100%;
  ${({ theme }) => theme.helpers.hideScrollbar};
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StepNotice = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.5;
  margin: 3rem auto;
  width: 60rem;
  text-align: center;
`;

export const GenericText = styled(Div)`
  line-height: 1.5;
  width: 80rem;
  text-align: center;
`;

export const TypeSelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 51rem;
`;

export const TypeChoiceWrapper = styled.div`
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 0.5rem 1rem 0 rgba(19, 93, 125, 0.07);
  cursor: pointer;
  border-radius: 2rem;
  box-sizing: border-box;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 25rem;
  padding: 2rem;
  position: relative;
  transition-duration: ${({ theme }) => theme.transition.fast};
  width: 23rem;

  &:hover {
    box-shadow: 0 1.5rem 3rem 0 rgba(19, 93, 125, 0.07);
    transform: translate3d(0, -1rem, 0);
  }
`;

export const AudiofileIcon = styled(Audiofile).attrs({
  width: '6rem',
})``;

export const MetadataIcon = styled(Metadata).attrs({
  width: '6rem',
})``;

export const TypeChoiceText = styled.div`
  font-size: 1.5rem;
  line-height: 1.8rem;
  text-align: center;
  width: 19rem;

  &:nth-child(2) {
    margin-top: 3.5rem;
  }
`;

export const FilePathWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const FilePathPreview = styled.div`
  background-color: #fff6ef;
  border: 0.1rem #ff9999 solid;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 0.5rem;
  max-width: 80rem;
  white-space: nowrap;
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StepButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3.6rem;
`;

export const PrevStepButton = styled(StyledButton).attrs({
  color: 'cancel',
  fillVariant: 'outline',
})``;

export const NextStepButton = styled(StyledButton).attrs(({ isLauncher }) => ({
  color: isLauncher ? 'success' : 'primary',
}))`
  margin-left: auto;
`;

export const BackToBeginningButton = styled(StyledButton).attrs({
  color: 'cancel',
})``;

export const FileNameContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

export const FileName = styled.a`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.input.text};
  flex-shrink: 1;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-right: 4rem;
  text-decoration: none;
  transition: opacity ${({ theme }) => theme.transition.medium};

  ${({ href }) => href
    && css`
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    `};
`;

export const UploadStatus = styled.div`
  background-color: ${({ theme }) => theme.colors.type.success};
  border-radius: 50%;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
`;
