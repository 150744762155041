// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isFetching: false,
  data: {
    initial_facets: {},
    languages: {},
    territories: [],
    albumtypes: [],
    tonalitykeys: [],
    trackversions: [],
    tempos: [],
    rightstypes: [],
    tags: [],
    tags_only: [],
    mechanicalrightssocieties: [],
    neighboringrightssocieties: [],
    performingrightssocieties: [],
    userindustries: [],
  },
};

// =============================
// Reducer
// =============================

export default function options(state = initialState, action) {
  switch (action.type) {
    case acts.GET_OPTIONS_LOADING:
      return {
        ...state,
        isFetching: true,
      };

    case acts.SET_OPTIONS: {
      const data = {};

      Object.keys(action.payload).forEach((key) => {
        if (key === 'initial_facets') {
          data[key] = {
            ...state.data[key],
            ...action.payload[key],
          };
        } else {
          data[key] = action.payload[key];
        }
      });

      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
      };
    }

    case acts.GET_OPTIONS_SUCCESS:
    case acts.GET_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
