/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const CenteredWrapper = styled.div`
  align-items: center;
  border: 0.1rem dashed #e5e5e5;
  border-radius: 1rem;
  display: flex;
  height: 32rem;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  width: 16rem;
`;
