// =============================
// Imports
// =============================

import _get from 'lodash/get';

import * as renderersType from '../entities/renderers/types';

import { composeValidators, maxNumber, minNumber, mustBeNumber, required } from '../../../../validators';

import { getApiSelectInputData, presentDuration } from '../../../../helpers/meta-common';

import { chipColors } from '../variables';

// =============================
// Config
// =============================

const config = {
  columns: [
    {
      key: 'similarity_search',
      renderComponent: {
        type: renderersType.SIMILARITY_SEARCH,
      },
      readableWidth: 1.7,
      immutableColumn: true,
      withoutColumnHeader: true,
    },
    {
      key: 'image',
      renderComponent: {
        type: renderersType.IMAGE,
      },
      readableWidth: 5,
      immutableColumn: true,
      withoutColumnHeader: true,
    },
    {
      key: 'tenant',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (tenant) => {
          if (tenant.company_name) return tenant.company_name;
          return `${tenant.first_name} ${tenant.last_name}`;
        },
        getSearchProps: (document) => {
          const { tenant } = document;

          return {
            entity: 'track',
            filters: {
              tenants: [[
                tenant.id,
                tenant.company_name || `${tenant.first_name} ${tenant.last_name}`,
              ]],
            },
            resetQuery: true,
          };
        },
      },
      readableWidth: 12,
      immutableColumn: true,
    },
    {
      key: 'title',
      renderComponent: {
        type: renderersType.TEXT,
        validator: required,
        isPanelOpener: true,
        getSearchProps: document => ({
          entity: 'track',
          filters: { ref_id: [document.ref_id, document.title] },
          resetQuery: true,
          resetAllFilters: true,
        }),
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      immutableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'similarity',
      renderComponent: {
        type: renderersType.SIMILARITY,
      },
      readableWidth: 7,
      immutableColumn: true,
    },
    {
      key: 'popularity',
      renderComponent: {
        type: renderersType.SIMILARITY,
      },
      readableWidth: 7,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'music_type',
      renderComponent: {
        type: renderersType.MUSIC_TYPE,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'version',
      renderComponent: {
        type: renderersType.TRACK_VERSION,
        validator: required,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'display_artists',
      renderComponent: {
        type: renderersType.DISPLAY_ARTISTS,
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'waveform',
      renderComponent: {
        type: renderersType.WAVEFORM,
      },
      readableWidth: 30,
      editableColumn: true,
    },
    {
      key: 'duration',
      renderComponent: {
        type: renderersType.NUMBER,
        transformToDisplay: presentDuration,
      },
      readableWidth: 10,
      writableWidth: 10,
      editable: false,
      editableColumn: true,
      sortableColumn: false,
    },
    {
      key: 'album',
      renderComponent: {
        type: renderersType.META_ENTITY,
        entity: 'album',
        isPanelOpener: true,
        transformToDisplay: album => album.title,
        onTheFlyField: 'title',
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'release_date',
      renderComponent: {
        type: renderersType.DATE,
        validator: required,
        withTime: false,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'approved',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      writableWidth: 10,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'display_title',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'track_ref',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'catalog',
      renderComponent: {
        type: renderersType.META_ENTITY,
        entity: 'catalog',
        isPanelOpener: true,
        transformToDisplay: catalog => catalog.name,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: false,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'track_number',
      renderComponent: {
        type: renderersType.NUMBER,
        validator: composeValidators(mustBeNumber, minNumber(1), maxNumber(500)),
      },
      readableWidth: 10,
      writableWidth: 10,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'rating',
      renderComponent: {
        type: renderersType.RATING,
      },
      readableWidth: 20,
      writableWidth: 20,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'isrc',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'iswc',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'descriptions',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (descriptions, { gridsLanguage }) => {
          const descriptionInLanguage = descriptions.find(desc => desc.locale === gridsLanguage);

          return descriptionInLanguage ? descriptionInLanguage.value : '';
        },
        editVariant: 'descriptions',
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'lyrics',
      dataKey: 'lyrics',
      renderComponent: {
        type: renderersType.TEXT,
        editVariant: 'textarea',
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'performers',
      dataKey: 'artists_master_ownerships',
      renderComponent: {
        type: renderersType.OWNERSHIPS,
        ownershipEntity: 'artist',
        ownershipRightsType: 'performer',
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'featuring_artists',
      dataKey: 'artists_master_ownerships',
      renderComponent: {
        type: renderersType.OWNERSHIPS,
        ownershipRightsType: 'featuring-artist',
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'remixers',
      dataKey: 'artists_master_ownerships',
      renderComponent: {
        type: renderersType.OWNERSHIPS,
        ownershipEntity: 'artist',
        ownershipRightsType: 'remixer',
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'authors',
      dataKey: 'artists_publishing_ownerships',
      renderComponent: {
        type: renderersType.OWNERSHIPS,
        ownershipEntity: 'artist',
        ownershipRightsType: 'author',
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'composers',
      dataKey: 'artists_publishing_ownerships',
      renderComponent: {
        type: renderersType.OWNERSHIPS,
        ownershipEntity: 'artist',
        ownershipRightsType: 'composer',
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'labels',
      dataKey: 'master_ownerships',
      renderComponent: {
        type: renderersType.OWNERSHIPS,
        ownershipEntity: 'label',
        ownershipRightsType: 'label',
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'publishers',
      dataKey: 'publishing_ownerships',
      renderComponent: {
        type: renderersType.OWNERSHIPS,
        ownershipEntity: 'publisher',
        ownershipRightsType: 'publisher',
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'bpm',
      renderComponent: {
        type: renderersType.GENERIC_CHIP,
        color: chipColors.bpm,
        transformToDisplay: (bpm) => {
          if (bpm) return parseInt(bpm, 10).toString();
          return null;
        },
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'tempo',
      renderComponent: {
        type: renderersType.GENERIC_CHIP,
        color: chipColors.tempo,
        transformToDisplay: (tempo, { gridsLanguage }) => {
          const nameInLanguage = tempo?.names.find(name => name.locale === gridsLanguage);

          return nameInLanguage ? nameInLanguage.value : '';
        },
        getSearchProps: document => ({
          entity: 'track',
          filters: { tempos: [document.tempo.id] },
          resetQuery: true,
        }),
      },
      readableWidth: 15,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'tonality_key',
      renderComponent: {
        type: renderersType.GENERIC_CHIP,
        color: chipColors.tonality_key,
        transformToDisplay: (tonalityKey) => {
          if (tonalityKey) return `${tonalityKey.key.toUpperCase()} ${tonalityKey.tonality}`;
          return '';
        },
        getSearchProps: document => ({
          entity: 'track',
          filters: { tonality_keys: [document.tonality_key.id] },
          resetQuery: true,
        }),
      },
      readableWidth: 15,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'notes',
      renderComponent: {
        type: renderersType.NOTES,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'agents',
      renderComponent: {
        type: renderersType.AGENTS,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'showcase',
      renderComponent: {
        type: renderersType.SHOWCASE,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'grouping',
      renderComponent: {
        type: renderersType.GROUPING,
      },
      readableWidth: 15,
      editableColumn: true,
      sortableColumn: false,
    },
    {
      key: 'has_file',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'has_stems',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'attachments',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: attachments => attachments.map(a => a.name).join(', '),
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: false,
    },
    {
      key: 'has_conversion_error',
      dataKey: 'audiofile.error_key',
      columnKey: 'conversion_error',
      renderComponent: {
        type: renderersType.TEXT,
        displayVariant: 'error',
        transformToDisplay: (errorKey, { t }) => t(`errors:conversion.${errorKey}`),
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'created_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'updated_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
  ],
  gridModifyPick: (data, init = false, documentRights) => {
    if (!documentRights.canWrite && !init) {
      return {
        approved: _get(data, 'approved', false),
        rating: _get(data, 'rating', null),
      };
    }

    return {
      approved: _get(data, 'approved', false),
      title: _get(data, 'title', ''),
      music_type: _get(data, 'music_type', 'core'),
      display_title: _get(data, 'display_title', ''),
      track_ref: _get(data, 'track_ref', ''),
      album: getApiSelectInputData(data.album, 'album', init),
      track_number: _get(data, 'track_number', null),
      rating: _get(data, 'rating', null),
      version: init
        ? _get(data, 'version.id', null)
        : _get(data, 'version', null),
      lyrics: _get(data, 'lyrics', ''),
      isrc: _get(data, 'isrc', ''),
      iswc: _get(data, 'iswc', ''),
      release_date: _get(data, 'release_date', null),
      descriptions: _get(data, 'descriptions', [])
        .map(({ locale, value }) => ({ locale, value })),
      notes: _get(data, 'notes', ''),
    };
  },
  gridUpload: {
    key: 'audiofile',
    maxSize: 200000000,
  },
  panelUploads: {
    audiofile: {
      maxSize: 200000000,
    },
    stems: {
      maxSize: 1000000000,
    },
    attachment: {
      maxSize: 1000000000,
    },
  },
};

export default config;
