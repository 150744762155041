// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { HeaderDropdown } from '../dropdown';
import MetaHeader from '../../other/metaHeader';

import * as pth from '../../../helpers/proptypes';

import { Wrapper, FaqContainer, HeaderRight, StyledNotificationManager } from './styles';

// =============================
// Component
// =============================

function Header({
  faq,
  HeaderDropdownProps,
  MetaHeaderProps,
  logo,
  NotificationManagerProps,
}) {
  return (
    <Wrapper>
      {logo}
      {MetaHeaderProps && <MetaHeader {...MetaHeaderProps} />}
      <HeaderRight>
        {faq && <FaqContainer>{faq}</FaqContainer>}
        <StyledNotificationManager
          renderGotoManagerLink={NotificationManagerProps.renderGotoManagerLink}
          lang={NotificationManagerProps.lang}
          markAllRead={NotificationManagerProps.markAllRead}
          notifications={NotificationManagerProps.notifications}
        />
        <HeaderDropdown
          activePage={HeaderDropdownProps.activePage}
          user={HeaderDropdownProps.user}
          links={HeaderDropdownProps.links}
        />
      </HeaderRight>
    </Wrapper>
  );
}

Header.propTypes = {
  /** Faq node */
  faq: PropTypes.node,
  /** Object pass down to HeaderDropdown */
  HeaderDropdownProps: PropTypes.shape({
    /** Array of links */
    links: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.func,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    /** Active page */
    activePage: PropTypes.string.isRequired,
    /** User object */
    user: pth.user,
  }).isRequired,
  /** Logo */
  logo: PropTypes.node,
  /** Object pass down to MetaHeader */
  MetaHeaderProps: PropTypes.shape({
    /** Currently active page. */
    activePage: PropTypes.string.isRequired,
  }),
  /** Object pass down to Notification Manager */
  NotificationManagerProps: PropTypes.shape({
    /** Function used to navigate to Notification Manager */
    renderGotoManagerLink: PropTypes.func.isRequired,
    /** Translation strings */
    // eslint-disable-next-line react/forbid-prop-types
    lang: PropTypes.object.isRequired,
    /** Function used to mark all notifications as read */
    markAllRead: PropTypes.func.isRequired,
    /** Array of notifications  */
    notifications: PropTypes.arrayOf(pth.userNotification).isRequired,
  }).isRequired,
};

Header.defaultProps = {
  faq: null,
  logo: null,
  MetaHeaderProps: null,
};

export default Header;
