// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';

import { Icons } from '@mewo/components';

import { StyledButton } from '../../../global.styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DropzoneInnerContainer = styled.div`
  align-items: center;
  border: 0.1rem dashed #b2b2b2;
  border-radius: 0.8rem;
  cursor: pointer;
  display: flex;
  height: 20rem;
  justify-content: center;
  outline: 0;
  padding: 1rem;
  width: 100%;

  ${({ isDragActive }) => !isDragActive
    && css`
      &:hover {
        background-color: ${({ theme }) => alpha(theme.colors.primary, 0.1)};
        border: 0.1rem dashed ${({ theme }) => theme.colors.primary};
      }
    `};

  ${({ isDragAccept }) => isDragAccept
    && css`
      background-color: ${({ theme }) => alpha(theme.colors.type.success, 0.1)};
      border: 0.1rem dashed ${({ theme }) => theme.colors.type.success};
    `};

  ${({ isDragReject }) => isDragReject
    && css`
      background-color: ${({ theme }) => alpha(theme.colors.type.error, 0.1)};
      border: 0.1rem dashed ${({ theme }) => theme.colors.type.error};
    `};
`;

export const Button = styled(StyledButton)`
  margin-top: 1.2rem;
`;

export const UploadIcon = styled(Icons.Upload)`
  margin-bottom: 0.8rem;
`;
