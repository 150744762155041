// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { lighten } from '@material-ui/core';
import { Dropdown } from '@mewo/components';

// =============================
// Styles
// =============================

export const StyledDropdown = styled(Dropdown).attrs({
  dropdownStyles: css`
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.4rem 1.5rem 0 rgba(19, 93, 125, 0.07);
    width: 33rem;
  `,
  withoutStyles: true,
})``;

export const Header = styled.div`
  align-items: center;
  border-bottom: 0.1rem solid #f4f4f4;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  padding: 0 2rem;
  width: 100%;
`;

export const Title = styled.div`
  color: #191919;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const MarkAllRead = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const Content = styled.div`
  width: 33rem;
`;

export const Footer = styled.div`
  align-items: center;
  border-top: 0.1rem solid #e5e5e5;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 3.5rem;
  justify-content: center;
  padding: 0 2rem;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => lighten(theme.colors.primary, 0.975)};
  }
`;

export const NoNotification = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  height: 6rem;
  justify-content: center;
`;
