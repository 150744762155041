// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import _has from 'lodash/has';
import _isEqual from 'lodash/isEqual';

// Actions
import {
  setHasAutosave as setHasAutosaveBase,
  resolveAutosaveCheck as resolveAutosaveCheckBase,
} from '../../../store/actions/SidePanelActions';

// Helpers
import * as pth from '../../../helpers/proptypes';

// =============================
// Component
// =============================

class AutoSave extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    initialValues: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.object.isRequired,
    debounce: PropTypes.number,
    save: PropTypes.func,
    nullValuesTransformer: PropTypes.func,
    // Panels autosave props
    autosavePanelId: PropTypes.string,
    panels: PropTypes.arrayOf(pth.sidePanel).isRequired,
    postAutosaveAction: PropTypes.shape({
      actionName: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      actionArgs: PropTypes.array,
    }).isRequired,
    setHasAutosave: PropTypes.func.isRequired,
    resolveAutosaveCheck: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autosavePanelId: null,
    save: () => {},
    nullValuesTransformer: v => v,
    debounce: null,
  };

  constructor(props) {
    super(props);
    this.state = { values: props.values };
  }

  componentDidMount() {
    const { panels, autosavePanelId, setHasAutosave } = this.props;

    if (panels.length && autosavePanelId) {
      setHasAutosave({ uuid: panels[0].uuid, value: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      debounce,
      values,
      initialValues,
      autosavePanelId,
      panels,
      postAutosaveAction,
      resolveAutosaveCheck,
    } = this.props;

    if (!_isEqual(prevProps.initialValues, initialValues)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ values: initialValues });
    }

    if (!_isEqual(prevProps.values, values) && debounce) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(this.save, debounce);
    }

    if (
      autosavePanelId
      && panels.length
      && autosavePanelId === `${panels[0].type}_${panels[0].id}`
      && !prevProps.postAutosaveAction.actionName
      && postAutosaveAction.actionName
    ) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.save(false, resolveAutosaveCheck);
    }
  }

  componentWillUnmount() {
    const { panels, autosavePanelId, setHasAutosave } = this.props;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.save(true);

    if (panels.length && autosavePanelId) {
      setHasAutosave({ uuid: panels[0].uuid, value: false });
    }
  }

  save = async (willUnmount = false, postSaveAction = null) => {
    const { values: _stateValues } = this.state;
    const { values: _values, errors, save, nullValuesTransformer } = this.props;

    const stateValues = nullValuesTransformer(_stateValues);
    const values = nullValuesTransformer(_values);

    const fieldsWithErrors = Object.keys(values).filter(key => _has(errors, key));

    const hasNoErrors = !Object.keys(fieldsWithErrors).length;
    const isDifferent = !_isEqual(stateValues, values);

    /**
     * NOTE: Only submit if there are no errors and form is different
     * Document further if necessary
     */
    if (hasNoErrors && isDifferent) {
      if (willUnmount) {
        // values have changed
        save(values, stateValues);
      } else {
        // values have changed
        this.setState({ values }, async () => {
          await save(values, stateValues);

          if (postSaveAction && typeof postSaveAction === 'function') {
            postSaveAction();
          }
        });
      }
    } else if (postSaveAction && typeof postSaveAction === 'function') {
      postSaveAction();
    }
  };

  render() {
    return null;
  }
}

function mapStateToProps({ sidepanel }) {
  return {
    panels: sidepanel.panels,
    postAutosaveAction: sidepanel.postAutosaveAction,
  };
}

const ConnectedAutoSave = connect(mapStateToProps, {
  setHasAutosave: setHasAutosaveBase,
  resolveAutosaveCheck: resolveAutosaveCheckBase,
})(AutoSave);

const SpyedAutoSave = props => (
  <FormSpy
    {...props}
    subscription={{ initialValues: true, values: true, errors: true }}
    component={ConnectedAutoSave}
  />
);

export default SpyedAutoSave;
