// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import {
  ApiSelectInput,
  CheckboxInput,
  RadioInput,
  ColorPicker,
  DatePicker,
  GoogleFontsInput,
  GooglePlacesInput,
  Input,
  RatingInput,
  SelectInput,
  SwitchInput,
  TextArea,
  TextToInput,
} from '@mewo/components';

// =============================
// Styles
// =============================

export const StyledApiSelectInput = styled(ApiSelectInput).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledCheckboxInput = styled(CheckboxInput)``;

export const StyledRadioInput = styled(RadioInput)``;

export const StyledColorPicker = styled(ColorPicker).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledDatePicker = styled(DatePicker).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledGoogleFontsInput = styled(GoogleFontsInput).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledGooglePlacesInput = styled(GooglePlacesInput).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledInput = styled(Input).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  ${({ withBackground }) => (withBackground
    ? css`
          & .containerClassName {
            background: #fff;
            border: 0;
          }
        `
    : css`
          & .containerClassName {
            border: 0.1rem solid #e5e5e5;
          }
        `)};
`;

export const StyledRatingInput = styled(RatingInput).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledSelectInput = styled(SelectInput).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  ${({ withBackground }) => (withBackground
    ? css`
          & .containerClassName {
            background: #fff;
            border: 0;
          }
        `
    : css`
          & .containerClassName {
            border: 0.1rem solid #e5e5e5;
          }
        `)};
`;

export const StyledSwitchInput = styled(SwitchInput).attrs(() => ({
  containerClassName: 'containerClassName',
  type: 'boolean',
  colors: {
    switchBackground: '#b2b2b2',
    switchCircle: '#ffffff',
  },
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledTextArea = styled(TextArea).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  & .containerClassName {
    border: 0.1rem solid #e5e5e5;
  }
`;

export const StyledTextToInput = styled(TextToInput)``;
