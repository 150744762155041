// =============================
// Imports
// =============================

// External Dependencies
import { Icons } from '@mewo/components';
import styled, { css } from 'styled-components';

// Variables
import { musicPlayerHeight } from '../player/variables';

// =============================
// Styled Components
// =============================

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: ${({ theme }) => theme.transition.medium};
  right: -10rem;
  width: 102.4rem;
  z-index: ${({ theme }) => theme.zIndex.sidePanel};

  ${({ isOpened }) => isOpened
    && css`
      opacity: 1;
      pointer-events: auto;
      right: 0;
    `};

  ${({ playerIsOpened }) => playerIsOpened && css`
    padding-bottom: ${musicPlayerHeight};
  `};
`;

export const PrevZone = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  transition: background-color ${({ theme }) => theme.transition.medium};
  width: 4rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const PrevIcon = styled(Icons.ArrowDropdown).attrs(() => ({
  fill: '#fff',
  width: '2rem',
}))`
  margin-left: -0.2rem;
  transform: rotate(90deg);
`;

export const SubWrapper = styled.div`
  background-color: #fff;
  display: flex;
  height: 100%;
  position: relative;
  width: 98.4rem;
`;
