// =============================
// Imports
// =============================

import styled, { css, keyframes } from 'styled-components';

import {
  contentPaddingY,
  contentPaddingX,
  contentPaddingXSxga,
} from './variables';
import { musicPlayerHeight } from '../player/variables';
import { headerHeight } from '../header/styles';
import { footerHeight } from '../footer/styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

const slideDown = keyframes`
  from {
    transform: translate3d(0, 3rem, 0);
  }

  to {
    transform: translate3d(0 , 0, 0);
  }
`;

export const Content = styled.div`
  animation: ${slideDown} ${({ theme }) => theme.transition.medium} ease-out 1;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;

  ${({ noHorizontalPadding }) => noHorizontalPadding
    && css`
      padding: ${contentPaddingY} 0;
    `};

  ${({ noPadding, noHorizontalPadding, theme }) => (!noPadding && !noHorizontalPadding)
    && css`
      padding: ${contentPaddingY} ${contentPaddingX};

      ${theme.mediaAbove.sxga`
        padding: ${contentPaddingY} ${contentPaddingXSxga};
      `};
    `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-top: ${({ disableTopPadding }) => (disableTopPadding ? 0 : headerHeight)};
  width: 100%;

  ${({ theme, withPadding }) => withPadding && css`
    padding-left: 22rem;

    ${theme.mediaAbove.sxga`
      padding-left: 24rem;
    `};
  `};

  ${({ playerIsOpened }) => playerIsOpened && css`
    padding-bottom: calc(${musicPlayerHeight} - ${footerHeight});
  `};
`;
