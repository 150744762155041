// =============================
// Imports
// =============================

// External dependencies
import App from 'next/app';
import { compose } from 'redux';

// Config
import { appWithTranslation } from '../config/i18n';

// Store
import storeWrapper from '../store';

// Components
import Business from '../app/business';
import Realtime from '../app/realtime';
import Layout from '../app/layout';

// =============================
// Performance
// =============================

export function reportWebVitals({ id, name, label, value }) {
  if (typeof window !== 'undefined' && window.gtag && process.env.GA_CODE) {
    // Use `window.gtag` if you initialized Google Analytics as this example:
    // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  }
}

// =============================
// App
// =============================

class MewoApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    return (
      <Business>
        <Realtime>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Realtime>
      </Business>
    );
  }
}

export default compose(storeWrapper.withRedux, appWithTranslation)(MewoApp);
