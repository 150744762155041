// =============================
// Imports
// =============================

import _get from 'lodash/get';

import { i18n } from '../../../../config/i18n';

import * as renderersType from '../entities/renderers/types';

import { required } from '../../../../validators';

// =============================
// Config
// =============================

const config = {
  columns: [
    {
      key: 'image',
      renderComponent: {
        type: renderersType.IMAGE,
      },
      readableWidth: 5,
      immutableColumn: true,
      withoutColumnHeader: true,
    },
    {
      key: 'tenant',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (tenant) => {
          if (tenant.company_name) return tenant.company_name;
          return `${tenant.first_name} ${tenant.last_name}`;
        },
        getSearchProps: (document) => {
          const { tenant } = document;

          return {
            entity: 'brief',
            filters: {
              tenants: [[
                tenant.id,
                tenant.company_name || `${tenant.first_name} ${tenant.last_name}`,
              ]],
            },
            resetQuery: true,
          };
        },
      },
      readableWidth: 12,
      immutableColumn: true,
    },
    {
      key: 'created_by',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: user => `${user.first_name} ${user.last_name}`,
        getSearchProps: (document) => {
          const { created_by: user } = document;

          return {
            entity: 'brief',
            filters: {
              created_by: [[
                user.id,
                `${user.first_name} ${user.last_name}`,
              ]],
            },
            resetQuery: true,
          };
        },
      },
      readableWidth: 12,
      immutableColumn: true,
    },
    {
      key: 'title',
      renderComponent: {
        type: renderersType.TEXT,
        validator: required,
        isPanelOpener: true,
        getSearchProps: document => ({
          entity: 'track',
          filters: { internal_refs: [[document.id, `${i18n.t('common:entities.brief')}: ${document.title}`]] },
          resetQuery: true,
        }),
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      immutableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'descriptions',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (descriptions, { gridsLanguage }) => {
          const descriptionInLanguage = descriptions.find(desc => desc.locale === gridsLanguage);

          return descriptionInLanguage ? descriptionInLanguage.value : '';
        },
        editVariant: 'descriptions',
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'budget',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (budget, opts, document) => `${budget} ${document.currency}`,
      },
      readableWidth: 12,
      writableWidth: 12,
      editable: false,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'deadline',
      renderComponent: {
        type: renderersType.DATE,
        withTime: false,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: document => document.owned_by_tenant,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'notes',
      renderComponent: {
        type: renderersType.NOTES,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'agents',
      renderComponent: {
        type: renderersType.AGENTS,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'has_file',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'has_conversion_error',
      dataKey: 'image.error_key',
      columnKey: 'conversion_error',
      renderComponent: {
        type: renderersType.TEXT,
        displayVariant: 'error',
        transformToDisplay: (errorKey, { t }) => t(`errors:conversion.${errorKey}`),
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'created_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'updated_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
  ],
  gridModifyPick: (data, init, documentRights) => {
    if (!documentRights.isOwner) {
      return {
        title: _get(data, 'title', ''),
        descriptions: _get(data, 'descriptions', []),
        notes: _get(data, 'notes', ''),
      };
    }

    return {
      title: _get(data, 'title', ''),
      descriptions: _get(data, 'descriptions', [])
        .map(({ locale, value }) => ({ locale, value })),
      deadline: _get(data, 'deadline', null),
      notes: _get(data, 'notes', ''),
    };
  },
  gridUpload: {
    key: 'image',
    maxSize: 20000000,
  },
  panelUploads: {
    image: {
      maxSize: 20000000,
    },
  },
};

export default config;
