// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import { DropdownContext, Icons } from '@mewo/components';

import Notification from './notification';

import {
  Header,
  Title,
  MarkAllRead,
  Content,
  Footer,
  NoNotification,
  StyledDropdown,
} from './styles';

// =============================
// Component
// =============================

class NotificationManager extends PureComponent {
  static propTypes = {
    /** Props used by styled-component to override style */
    className: PropTypes.string,
    /** Function used to get link component that wraps go to manager action */
    renderGotoManagerLink: PropTypes.func,
    /** Translation strings */
    lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Function used to mark all notifications as read */
    markAllRead: PropTypes.func.isRequired,
    /** Array of notifications  */
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        message: PropTypes.node,
        seen: PropTypes.bool.isRequired,
      }),
    ),
    /** Router object */
    router: PropTypes.shape({
      asPath: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    className: '',
    renderGotoManagerLink: null,
    notifications: [],
  };

  constructor(props) {
    super(props);

    this.closeDropdown = null;
  }

  componentDidUpdate(prevProps) {
    const { router: { asPath: prevAsPath } } = prevProps;
    const { router: { asPath: nextAsPath } } = this.props;

    if (
      prevAsPath !== nextAsPath
      && this.closeDropdown
      && typeof this.closeDropdown === 'function'
    ) {
      this.closeDropdown();
    }
  }

  handleMarkAllRead = (closeDropdown) => {
    const { markAllRead } = this.props;

    closeDropdown();
    markAllRead();
  };

  render() {
    const { className, renderGotoManagerLink, lang, notifications } = this.props;

    return (
      <StyledDropdown
        className={className}
        footer={
          !!notifications.length
          && renderGotoManagerLink
          && renderGotoManagerLink(<Footer>{lang.seeMore}</Footer>)
        }
        placement="bottom-start"
        header={(
          <DropdownContext.Consumer>
            {({ closeDropdown }) => {
              this.closeDropdown = closeDropdown;

              return (
                <Header>
                  <Title>{lang.notifications}</Title>
                  {!!notifications.find(n => !n.seen) && (
                    <MarkAllRead onClick={() => this.handleMarkAllRead(closeDropdown)}>
                      {lang.markAllRead}
                    </MarkAllRead>
                  )}
                </Header>
              );
            }}
          </DropdownContext.Consumer>
        )}
        togglerElement={(
          <Icons.NotificationBell
            hasNotifications={!!notifications.find(n => !n.seen)}
            onClick={this.handleClick}
          />
        )}
      >
        <Content>
          {notifications.map(notification => (
            <Notification
              seen={notification.seen}
              key={notification.id}
              date={notification.date}
              message={notification.message}
            />
          ))}

          {!notifications.length && <NoNotification>{lang.youDontHaveNotification}</NoNotification>}
        </Content>
      </StyledDropdown>
    );
  }
}

export default withRouter(NotificationManager);
