// =============================
// Imports
// =============================

// External Dependencies
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Router from 'next/router';
import labelpublisherplaceholder from '@mewo/components/assets/images/placeholders/label-publisher_500x500.png?webp';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

// Config
import { withTranslation } from '../../../../config/i18n';
import entityConfig from '../../meta/labels/config';

// Actions
import { panelActions } from '../../../../store/actions/SidePanelActions';

// Constants
import { META_LABEL_PANEL } from '../../../../store/constants/SidePanelTypes';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import DocumentStatus from '../documentStatus';
import Autosave from '../../../other/autosave';
import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import InputWithLanguage from '../../../containers/inputWithLanguage';
import TextAreaInput from '../../../presentationals/inputs/textAreaInput';
import SelectInput from '../../../presentationals/inputs/selectInput';
import MetaRightsInformations from '../metaRightsInformations';
import Uploader from '../../../presentationals/uploader';
import MetaCustomFields from '../metaCustomFields';
import WithMetaSearchRoute from '../../../faac/withMetaSearchRoute';
import ContactsInput from '../../../containers/contactsInput';
import MetaDownloadDropdown from '../../../other/metaDownloadDropdown';
import CopyLink from '../copyLink';

import validator from '../../../../validators/meta/label';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getSiteOrigin } from '../../../../helpers/misc';
import { getApiSelectInputData, getDocumentRights, getGenericDocCoverUrl } from '../../../../helpers/meta-common';
import { formatDateString } from '../../../../helpers/dates';

// Styles
import {
  DocumentBody,
  DocumentCreatedAt,
  DocumentName,
  DocumentType,
  DownloadActionIcon,
  DownloadActionWrapper,
  FileHelper,
  GenericCover,
  GenericOwnership,
  GenericOwnershipCount,
  GenericSearchIcon,
  GenericSearchLink,
  LeftColumn,
  MiscHelper,
  RightColumn,
  SectionBox,
  SectionNotice,
  SectionTitle,
  TabElement,
  TabLink,
  TabLinksWrapper,
} from '../common.styles';
import { InputDouble, InputWrapper } from '../../global.styles';

// =============================
// Component
// =============================

const entity = 'label';

class MetaLabel extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      data: pth.label,
      additionalData: PropTypes.shape({
        ownerships: PropTypes.shape({
          data: PropTypes.number,
        }),
      }),
      isModifying: PropTypes.bool.isRequired,
      isDuplicating: PropTypes.bool.isRequired,
      isUploading: PropTypes.bool.isRequired,
      isDeletingUpload: PropTypes.bool.isRequired,
      isDeleting: PropTypes.bool.isRequired,
      uploadProgress: PropTypes.number.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    customFields: pth.customFieldsConfig.isRequired,
    nrs: PropTypes.arrayOf(pth.rightsSociety).isRequired,
    providers: PropTypes.arrayOf(pth.provider).isRequired,
    territories: PropTypes.arrayOf(pth.territory).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  pickFormData = (data, documentRights, init = false) => {
    const keys = {
      label_name: _get(data, 'label_name', ''),
      label_code: _get(data, 'label_code', ''),
      descriptions: _get(data, 'descriptions', [])
        .map(({ locale, value }) => ({ locale, value })),
      territories: _get(data, 'territories', []),
      ipn: _get(data, 'ipn', ''),
      neighboring_rights_society: init
        ? _get(data, 'neighboring_rights_society.id', null)
        : _get(data, 'neighboring_rights_society', null),
    };

    if (documentRights.isOwner) {
      keys.contacts = _get(data, 'contacts', [])
        .filter(people => (init ? true : !!people.people.value))
        .map((people) => {
          if (init) {
            return {
              people: getApiSelectInputData(people, 'people', init),
              role: people.role,
            };
          }

          return {
            people: getApiSelectInputData(people.people, 'people', init),
            role: people.role,
          };
        });
    }

    if (documentRights.canAccessNotes) {
      keys.notes = _get(data, 'notes', '');
    }

    if (documentRights.isOwner) {
      keys.custom = _get(data, 'custom', {});
    }

    return keys;
  };

  renderPanelAnchors = (data) => {
    const { customFields, providers, t } = this.props;

    const documentRights = getDocumentRights(
      data.owned_by_tenant,
      data.tenant.id,
      providers,
    );

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    return (
      <TabLinksWrapper>
        <TabLink name="general_information">
          {t(`pages:meta.${entity}s.panel.general_information_anchor`)}
        </TabLink>
        {(documentRights.isOwner || !!data.contacts?.length) && (
          <TabLink name="contacts_information">
            {t(`pages:meta.${entity}s.panel.contacts_information_anchor`)}
          </TabLink>
        )}
        {(documentRights.isOwner && hasCustomFields) && (
          <TabLink name="custom_fields_information">
            {t(`pages:meta.${entity}s.panel.custom_fields_information_anchor`)}
          </TabLink>
        )}
        {documentRights.isOwner && (
          <TabLink name="agents_information">
            {t(`pages:meta.${entity}s.panel.agents_information_anchor`)}
          </TabLink>
        )}
        {!documentRights.isOwner && (
          <TabLink name="providers_information">
            {t(`pages:meta.${entity}s.panel.providers_information_anchor`)}
          </TabLink>
        )}
        <TabLink name="modo_information">
          {t(`pages:meta.${entity}s.panel.modo_information_anchor`)}
        </TabLink>
        <TabLink name="uploads_information">
          {t(`pages:meta.${entity}s.panel.uploads_information_anchor`)}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  render() {
    const {
      panel,
      localPreferences,
      customFields,
      nrs,
      territories,
      providers,
      i18n: { language },
      t,
    } = this.props;
    const {
      data,
      additionalData,
      isModifying,
      isDuplicating,
      isUploading,
      isDeletingUpload,
      isDeleting,
      uploadProgress,
    } = panel;

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/${entity}s?panel=${data.id}`
      : '';

    const countryOptions = territories
      .filter(({ code }) => code !== 'WW')
      .map(({ code, name }) => ({ label: name, value: code }));

    const nrsOptions = _sortBy(
      nrs.map(({ id, name }) => ({ label: name, value: id })),
      'label',
    );

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[META_LABEL_PANEL].get}
        getAdditionalOptions={{
          fnc: panelActions[META_LABEL_PANEL].getAdditional,
          list: [['count/ownerships', 'ownerships']],
        }}
        modify={panelActions[META_LABEL_PANEL].modify}
        duplicate={panelActions[META_LABEL_PANEL].duplicate}
        uploadFile={panelActions[META_LABEL_PANEL].uploadFile}
        deleteFile={panelActions[META_LABEL_PANEL].deleteFile}
        del={panelActions[META_LABEL_PANEL].delete}
        lang={{
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {({
          modifyDocument,
          duplicateDocument,
          uploadDocumentFile,
          deleteDocumentFile,
          deleteDocument,
        }) => {
          if (!data) return null;

          const documentRights = getDocumentRights(
            data.owned_by_tenant,
            data.tenant.id,
            providers,
          );

          const image = _get(data, 'image.original.url')
            ? {
              name: _get(data, 'image.original.file_name'),
              url: _get(data, 'image.original.url'),
            }
            : null;

          let uploadStatus = data.has_file ? 2 : 0;
          // This needs to be last as it overwrites everything
          if (isUploading || isDeletingUpload) uploadStatus = 1;

          const formDisabled = !documentRights.canWrite || isUploading || isDeletingUpload;
          const uploadDisabled = !documentRights.canWrite || isModifying;

          return (
            <Fragment>
              <LeftColumn>
                <GenericCover
                  placeholder={labelpublisherplaceholder}
                  cover={getGenericDocCoverUrl(data.image)}
                />
                <DocumentName>
                  {data.label_name}
                </DocumentName>
                <DocumentType>
                  {t(`common:entities.${entity}`)}
                </DocumentType>
                <DocumentCreatedAt>
                  {t(`pages:meta.${entity}s.panel.created_at`)}
                  :&nbsp;
                  {formatDateString(data.created_at, localPreferences.dateFormat, true)}
                </DocumentCreatedAt>
                <CopyLink
                  link={shareLink}
                  content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                  tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
                />

                {!!_get(additionalData, 'ownerships.data') && (
                  <GenericOwnership>
                    <GenericOwnershipCount>
                      {_get(additionalData, 'ownerships.data')}
                      &nbsp;
                      {t(`pages:meta.${entity}s.panel.ownerships`, {
                        count: _get(additionalData, 'ownerships.data'),
                      })}
                    </GenericOwnershipCount>
                  </GenericOwnership>
                )}

                <MetaDownloadDropdown
                  entity={entity}
                  documentId={data.id}
                  documentName={data.label_name}
                  canDownload={!!_get(additionalData, 'ownerships.data')}
                  togglerElement={(
                    <DownloadActionWrapper>
                      <DownloadActionIcon />
                      {t(`pages:meta.${entity}s.downloads.toggler_title`)}
                    </DownloadActionWrapper>
                  )}
                />

                {!!_get(additionalData, 'ownerships.data') && (
                  <WithMetaSearchRoute
                    entity="track"
                    filters={{ labels: [[data.id, data.label_name]] }}
                    resetQuery
                  >
                    {routeQuery => (
                      <GenericSearchLink onClick={() => Router.push(`/meta/tracks${routeQuery}`)}>
                        <GenericSearchIcon />
                        {t(`pages:meta.${entity}s.panel.search_tracks`)}
                      </GenericSearchLink>
                    )}
                  </WithMetaSearchRoute>
                )}
              </LeftColumn>
              <RightColumn initTab="general_information">
                <DocumentStatus
                  isModifying={isModifying}
                  isDuplicating={isDuplicating}
                  isDeleting={isDeleting}
                  localPreferences={localPreferences}
                  documentRights={documentRights}
                  updatedAt={data.updated_at}
                  duplicateDocument={duplicateDocument}
                  deleteDocument={deleteDocument}
                />
                {this.renderPanelAnchors(data)}
                <DocumentBody>
                  <Form
                    onSubmit={() => {}}
                    initialValues={this.pickFormData(data, documentRights, true)}
                    subscription={{ values: true }}
                    mutators={{ ...arrayMutators }}
                    keepDirtyOnReinitialize
                  >
                    {({ form, values }) => (
                      <Fragment>
                        <Autosave
                          debounce={5000}
                          save={modifyDocument}
                          nullValuesTransformer={formValues => this.pickFormData(
                            formValues, documentRights, false,
                          )}
                          autosavePanelId={`${META_LABEL_PANEL}_${data.id}`}
                        />
                        <TabElement name="general_information">
                          <SectionTitle>{t(`pages:meta.${entity}s.panel.general_information`)}</SectionTitle>
                          <SectionNotice>
                            {t(`pages:meta.${entity}s.panel.general_information_notice`)}
                          </SectionNotice>
                          <SectionBox>
                            <InputWrapper>
                              <Field
                                name="label_name"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.label_name`)}
                                fullWidth
                                validate={validator.label_name}
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <InputDouble>
                                <Field
                                  name="label_code"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.label_code`)}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <Field
                                  name="territories"
                                  component={MewoInput}
                                  use={SelectInput}
                                  type="text"
                                  label={t('components:meta_territories_select.label')}
                                  options={countryOptions}
                                  searchable
                                  multiple
                                  selectAllHelper
                                  selectAllLabel={t('components:meta_territories_select.worldwide')}
                                  renderOptionsVariant="check"
                                  renderValuesVariant="count"
                                  countName={t('components:meta_territories_select.territory')}
                                  countNamePlural={t('components:meta_territories_select.territories')}
                                  countAll={t('components:meta_territories_select.worldwide')}
                                  selectAllValue="WW"
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                              </InputDouble>
                            </InputWrapper>
                          </SectionBox>
                          <SectionBox>
                            <InputWrapper>
                              <Field
                                name="ipn"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.ipn`)}
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <Field
                                name="neighboring_rights_society"
                                component={MewoInput}
                                use={SelectInput}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.neighboring_rights_society`)}
                                options={nrsOptions}
                                searchable
                                context="autosave"
                                disabled={formDisabled}
                              />
                            </InputWrapper>
                          </SectionBox>
                          <SectionBox>
                            <Field
                              name="descriptions"
                              component={MewoInput}
                              use={InputWithLanguage}
                              defaultLanguage={language}
                              label={t(`pages:meta.${entity}s.panel.descriptions`)}
                              fullWidth
                              isTranslatable
                              inputType="textarea"
                              context="autosave"
                              disabled={formDisabled}
                              height="12rem"
                            />
                          </SectionBox>
                          {documentRights.canAccessNotes && (
                            <SectionBox>
                              <Field
                                name="notes"
                                component={MewoInput}
                                use={TextAreaInput}
                                label={t(`pages:meta.${entity}s.panel.notes`)}
                                fullWidth
                                context="autosave"
                                disabled={formDisabled}
                                height="12rem"
                              />
                            </SectionBox>
                          )}
                        </TabElement>

                        {(documentRights.isOwner || !!data.contacts?.length) && (
                          <TabElement name="contacts_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.contacts_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.contacts_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <ContactsInput
                                  data={data.contacts}
                                  form={form}
                                  formValues={values}
                                  name="contacts"
                                  disabled={formDisabled}
                                  documentRights={documentRights}
                                />
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}

                        {documentRights.isOwner && hasCustomFields && (
                          <TabElement name="custom_fields_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.custom_fields_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.custom_fields_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <MetaCustomFields entity={entity} formDisabled={formDisabled} />
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}

                        <MetaRightsInformations
                          entity={entity}
                          docAgents={data.agents}
                          docModos={data.showcase}
                          documentRights={documentRights}
                          formDisabled={formDisabled}
                          writableForAgentDisabled
                        />
                      </Fragment>
                    )}
                  </Form>

                  <TabElement name="uploads_information">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.uploads_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.uploads_information_notice`)}
                    </SectionNotice>
                    <SectionBox>
                      <Uploader
                        name="image"
                        value={image}
                        status={uploadStatus}
                        disabled={uploadDisabled}
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        onChange={(nextValue) => {
                          if (!nextValue) return deleteDocumentFile();
                          return uploadDocumentFile(nextValue);
                        }}
                        maxSize={entityConfig.panelUploads.image.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.image`)}
                      </FileHelper>
                      {_get(data, 'image.error_key') && (
                        <MiscHelper mt="1.2rem" isError>
                          {t(`errors:conversion.${_get(data, 'image.error_key')}_desc`)}
                        </MiscHelper>
                      )}
                    </SectionBox>
                  </TabElement>
                </DocumentBody>
              </RightColumn>
            </Fragment>
          );
        }}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core, options, meta }) {
  return {
    localPreferences: core.localPreferences,
    customFields: meta.customFields.data,
    territories: options.data.territories,
    nrs: options.data.neighboringrightssocieties,
    providers: meta.providers,
  };
}

export default compose(
  connect(mapStateToProps),
  withTranslation(['common', 'pages', 'components', 'errors']),
)(MetaLabel);
