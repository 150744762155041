// =============================
// Imports
// =============================

// Config
import locales from '../../config/locales';
import dateFormats from '../../config/date-formats';
import metaLocales from '../../config/meta-locales';

// Meta entity configs
import artistsConfig from '../../components/layouts/meta/artists/config';
import publishersConfig from '../../components/layouts/meta/publishers/config';
import labelsConfig from '../../components/layouts/meta/labels/config';
import peoplesConfig from '../../components/layouts/meta/peoples/config';
import catalogsConfig from '../../components/layouts/meta/catalogs/config';
import playlistsConfig from '../../components/layouts/meta/playlists/config';
import pitchsConfig from '../../components/layouts/meta/pitchs/config';
import briefsConfig from '../../components/layouts/meta/briefs/config';
import albumsConfig from '../../components/layouts/meta/albums/config';
import tracksConfig from '../../components/layouts/meta/tracks/config';
import ingestionsConfig from '../../components/layouts/meta/ingestions/config';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  ready: false,
  hydrated: false,
  routerModal: {
    isOpened: false,
    nextHref: null,
    nextAsPath: null,
  },
  localPreferences: {
    language: locales.defaultLanguage,
    dateFormat: dateFormats.defaultDateFormat,
    meta: {
      gridsLanguage: metaLocales.defaultLanguage,
      showTracksTags: 'true',
      columns: {
        artist: artistsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        publisher: publishersConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        label: labelsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        people: peoplesConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        catalog: catalogsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        playlist: playlistsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        pitch: pitchsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        brief: briefsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        album: albumsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        track: tracksConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
        ingestion: ingestionsConfig.columns
          .filter(column => column.editableColumn)
          .map(column => column.key),
      },
    },
  },
};

// =============================
// Reducer
// =============================

export default function core(state = initialState, action) {
  switch (action.type) {
    case acts.INITIALIZE_APP:
      return {
        ...state,
        ready: true,
      };

    case acts.OPEN_ROUTER_MODAL:
      return {
        ...state,
        routerModal: {
          ...state.routerModal,
          isOpened: true,
          nextHref: action.payload.nextHref,
          nextAsPath: action.payload.nextAsPath,
        },
      };

    case acts.CLOSE_ROUTER_MODAL:
      return {
        ...state,
        routerModal: initialState.routerModal,
      };

    case acts.SET_LOCAL_PREFERENCES:
      return {
        ...state,
        localPreferences: {
          ...state.localPreferences,
          ...action.payload,
          meta: {
            ...state.localPreferences.meta,
            ...action.payload.meta,
            columns: {
              ...state.localPreferences.meta.columns,
            },
          },
        },
      };

    case acts.SET_LOCAL_META_COLUMNS_PREFERENCES:
      return {
        ...state,
        localPreferences: {
          ...state.localPreferences,
          meta: {
            ...state.localPreferences.meta,
            columns: {
              ...state.localPreferences.meta.columns,
              ...action.payload.meta.columns,
            },
          },
        },
      };

    default:
      return state;
  }
}
