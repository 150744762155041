// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { Flex, Div } from '@mewo/components';
import trackplaceholder from '@mewo/components/assets/images/placeholders/track_150x150.png?webp';
import _get from 'lodash/get';
import _some from 'lodash/some';

import WithCurrentTime from '../../faac/withCurrentTime';
import MetaDownloadDropdown from '../../other/metaDownloadDropdown';
import WithMetaSearchRoute from '../../faac/withMetaSearchRoute';
import MetaRefAdder from '../../other/metaRefAdder';

import { getGenericDocCoverUrl, presentDuration } from '../../../helpers/meta-common';
import { getNextSeekValue } from '../../../helpers/player';
import * as pth from '../../../helpers/proptypes';

import * as spts from '../../../store/constants/SidePanelTypes';

import {
  Wrapper,
  Cover,
  TitleArtistsWrapper,
  TrackTitle,
  DisplayArtists,
  DisplayArtist,
  Controls,
  PrevIcon,
  NextIcon,
  PauseIcon,
  PlayIcon,
  WaveformContainer,
  StyledWaveform,
  Timeline,
  CurrentTime,
  TrackTime,
  Interactions,
  SimilaritySearchIcon,
  DownloadsIcon,
  AddToRefIcon,
  ClosePlayerIcon,
} from './styles';

// =============================
// Component
// =============================

class Player extends PureComponent {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    current: pth.playerTrack,
    hasNext: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isPaused: PropTypes.bool.isRequired,
    isEnded: PropTypes.bool.isRequired,
    getCurrentTime: PropTypes.func,
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    seek: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    goToNextTrack: PropTypes.func.isRequired,
    goToPrevTrack: PropTypes.func.isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
  };

  static defaultProps = {
    current: null,
    getCurrentTime: null,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { current, isPlaying, isPaused, isEnded, play, pause } = this.props;

    const targetTagName = _get(e, 'target.tagName', '').toLowerCase();

    // When pressing on space bar when there is a current
    // track and target is not an input or textarea
    if (
      !!current
      && e.keyCode === 32
      && !_some(['input', 'textarea'], tag => tag === targetTagName)
    ) {
      e.preventDefault();

      if (isPlaying) {
        pause();
      }

      if (isPaused || isEnded) {
        play();
      }
    }
  };

  handleSeek = (seekValue) => {
    const { current, seek } = this.props;

    seek(getNextSeekValue(current.duration || 0, seekValue));
  };

  handleClose = () => {
    const { isPlaying, pause, close } = this.props;

    close();

    if (isPlaying) {
      pause();
    }
  };

  openDocumentPanel = (entity, doc) => {
    const { triggerPanelAutosaveCheck } = this.props;

    triggerPanelAutosaveCheck('open', [spts.metaMapping[entity], doc.id]);
  };

  renderSimilaritySearchAction = () => {
    const { current } = this.props;

    if (!current.audiofile.is_converted) return null;

    return (
      <WithMetaSearchRoute
        entity="track"
        filters={{ track_id: [current.id, current.title] }}
        resetQuery
        resetAllFilters
      >
        {routeQuery => (
          <SimilaritySearchIcon
            onClick={() => Router.push(`/meta/tracks${routeQuery}`)}
          />
        )}
      </WithMetaSearchRoute>
    );
  };

  render() {
    const {
      opened,
      current,
      hasNext,
      isPlaying,
      isPaused,
      getCurrentTime,
      play,
      pause,
      goToNextTrack,
      goToPrevTrack,
    } = this.props;

    if (!opened || !current) return null;

    const waveformUrl = current.waveform.small?.url
      || current.waveform.large?.url;

    const trackTitle = current.display_title || current.title;

    // Status for get current time
    let status = 0;
    if (isPaused) status = 1;
    if (isPlaying) status = 2;

    return (
      <Wrapper className="ignore-react-onclickoutside">
        <Cover
          placeholder={trackplaceholder}
          cover={getGenericDocCoverUrl(current.album?.image, 'small')}
          onClick={current.album
            ? () => this.openDocumentPanel('album', current.album)
            : null}
        />
        <TitleArtistsWrapper>
          <TrackTitle
            title={trackTitle}
            onClick={() => this.openDocumentPanel('track', current)}
          >
            {trackTitle}
          </TrackTitle>
          <DisplayArtists>
            {current.display_artists.map((displayArtist, i) => (
              <Div
                key={`${displayArtist.artist.id}-${displayArtist.alias || displayArtist.artist.full_name}`}
                display="inline"
              >
                {i > 0 && (
                  <Div display="inline">,&nbsp;</Div>
                )}
                <DisplayArtist
                  title={displayArtist.alias || displayArtist.artist.full_name}
                  onClick={() => this.openDocumentPanel('artist', displayArtist.artist)}
                >
                  {displayArtist.alias || displayArtist.artist.full_name}
                </DisplayArtist>
              </Div>
            ))}
          </DisplayArtists>
        </TitleArtistsWrapper>

        <Controls>
          <PrevIcon onClick={goToPrevTrack} />
          {!isPlaying && <PlayIcon onClick={play} />}
          {isPlaying && <PauseIcon onClick={pause} />}
          <NextIcon onClick={goToNextTrack} disabled={!hasNext} />
        </Controls>

        <WithCurrentTime status={status} getCurrentTime={getCurrentTime}>
          {currentTime => (
            <Flex>
              <WaveformContainer>
                {waveformUrl && (
                  <StyledWaveform
                    progress={(currentTime / (current.duration || 0)) * 100}
                    svg={waveformUrl}
                    onSeek={this.handleSeek}
                  />
                )}
              </WaveformContainer>

              <Timeline>
                <CurrentTime>{presentDuration(currentTime)}</CurrentTime>
                <TrackTime>{presentDuration(current.duration || 0)}</TrackTime>
              </Timeline>
            </Flex>
          )}
        </WithCurrentTime>

        <Interactions>
          {this.renderSimilaritySearchAction()}
          <MetaRefAdder
            placement="top-end"
            tracks={[current.id]}
            togglerElement={<AddToRefIcon />}
          />
          <MetaDownloadDropdown
            placement="top-end"
            entity="track"
            documentId={current.id}
            documentName={current.title}
            originalAudioLink={current.audiofile.original?.url}
            hdMp3AudioLink={current.audiofile.hd_mp3?.url}
            stemsLink={current.stems.original?.url}
            canDownload
            togglerElement={<DownloadsIcon />}
          />
          <ClosePlayerIcon onClick={this.handleClose} />
        </Interactions>
      </Wrapper>
    );
  }
}

export default Player;
