// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import TabsContext from './context';

// =============================
// Component
// =============================

const Tab = (props) => {
  const { className, children, disabled, name, selectedClassName } = props;

  return (
    <TabsContext.Consumer>
      {({ currentTab, setCurrentTab }) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className={`${className} ${currentTab === name && selectedClassName ? selectedClassName : ''}`}
          onClick={(currentTab !== name && !disabled) ? () => setCurrentTab(name) : null}
        >
          {children}
        </div>
      )}
    </TabsContext.Consumer>
  );
};

Tab.propTypes = {
  /** Props used by styled component to override styles. */
  className: PropTypes.string,
  /** Children */
  children: PropTypes.node.isRequired,
  /** Pass to true to disable click action */
  disabled: PropTypes.bool,
  /** Tab name, must be equal to associated tab panel name */
  name: PropTypes.string.isRequired,
  /** Props used by styled component to override styles. */
  selectedClassName: PropTypes.string,
};

Tab.defaultProps = {
  className: '',
  disabled: false,
  selectedClassName: '',
};

export default Tab;
