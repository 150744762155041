// =============================
// Imports
// =============================

import styled from 'styled-components';

import Link from '../components/other/link';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const GoToNotificationManager = styled(Link)`
  width: 100%;
`;
