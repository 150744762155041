// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@nivo/pie';

// Hoc
import { withTranslation } from '../../../../config/i18n';

// Styles
import { CenteredWrapper } from './styles';

// Other
import colors from '../colors';

// =============================
// Component
// =============================

class PieChart extends Component {
  static propTypes = {
    charts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    width: 160,
    height: 320,
  };

  render() {
    const { charts, width, height, t } = this.props;

    if (!charts.length) {
      return <CenteredWrapper>{t('components:stats.no_data')}</CenteredWrapper>;
    }

    const marginBottom = charts.length ? 20 * (charts.length - 1) + 30 : 0;
    const legendsTranslateY = charts.length ? 20 * (charts.length - 1) + 30 : 0;

    /* Prevents text overflow */
    const transformedCharts = charts.map((_v) => {
      const v = _v;
      if (v.id.length > 26) return { ...v, id: `${v.id.substr(0, 26)}...` };
      v.label = v.id;
      return v;
    });

    return (
      <Pie
        data={transformedCharts}
        width={width}
        height={height}
        margin={{ bottom: marginBottom }}
        /* Inner radius */
        innerRadius={0.7}
        /* Sort by biggest value first */
        sortByValue
        /* Colors */
        colors={colors.slice().reverse()}
        /* No radial, arc and slices labels */
        enableSlicesLabels={false}
        enableRadialLabels={false}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        /* Legends */
        legends={[
          {
            anchor: 'bottom',
            direction: 'column',
            translateY: legendsTranslateY,
            translateX: -55,
            itemWidth: 50,
            itemHeight: 18,
            itemTextColor: '#999',
            symbolSize: 12,
            symbolShape: 'circle',
          },
        ]}
      />
    );
  }
}

export default withTranslation(['components'])(PieChart);
