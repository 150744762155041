// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { Flex, Typo } from '@mewo/components';

import {
  StyledModal,
  StyledBackdrop,
  StyledFade,
  CloseWHitBox,
  CloseModal,
  Content,
  Title,
  Message,
  Image,
  Button,
} from './styles';

// =============================
// Component
// =============================

function PremiumModal({ gotoPremium, lang, open, onClose, premiumNopeSrc }) {
  return (
    <StyledModal BackdropComponent={StyledBackdrop} onClose={onClose} open={open}>
      <StyledFade in={open}>
        <div>
          <Content>
            <CloseWHitBox onClick={onClose}>
              <CloseModal width="1rem" />
            </CloseWHitBox>
            <Flex direction="column">
              {premiumNopeSrc && <Image alt="Premium" src={premiumNopeSrc} />}
              <Title>{lang.title}</Title>
              <Message>{lang.message}</Message>
              <Button onClick={gotoPremium}>{lang.button}</Button>
              <Typo align="center" centered cursor="pointer" size={1.2} onClick={onClose}>
                {lang.chooseLater}
              </Typo>
            </Flex>
          </Content>
        </div>
      </StyledFade>
    </StyledModal>
  );
}

PremiumModal.propTypes = {
  /** Function executed when button is clicked */
  gotoPremium: PropTypes.func.isRequired,
  /** Translation strings */
  lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  /** onClose handler */
  onClose: PropTypes.func.isRequired,
  /** If true, PremiumModal is open */
  open: PropTypes.bool,
  /** Premium nope image src */
  premiumNopeSrc: PropTypes.string,
};

PremiumModal.defaultProps = {
  open: false,
  premiumNopeSrc: null,
};

export default PremiumModal;
