// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 22rem;
  padding: 3.2rem;
  width: 29rem;
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 5rem;
  margin-bottom: 3rem;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: #191919;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 100%;
`;

export const Url = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 1.6rem;
  width: 100%;
`;

export const ActiveWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.type.error};
  display: flex;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};

  ${({ active }) => active
    && css`
      color: ${({ theme }) => theme.colors.type.success};
    `};
`;

export const ActiveIndicator = styled.div`
  background-color: ${({ theme }) => theme.colors.type.error};
  border-radius: 50%;
  height: 1rem;
  margin-right: 0.4rem;
  width: 1rem;
  ${({ active }) => active
    && css`
      background-color: ${({ theme }) => theme.colors.type.success};
    `};
`;
