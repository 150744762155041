// =============================
// Imports
// =============================

import { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const ModuleLinks = memo(
  forwardRef(({ fill, onClick, theme, title, ...rest }, ref) => (
    <Svg
      fill={fill || theme.colors.text}
      onClick={onClick}
      ref={ref}
      title={title}
      viewBox="0 0 100 100"
      {...rest}
    >
      <path d="M39.39,80.41a14,14,0,0,1-19.8-19.8L35.15,45.05a14,14,0,0,1,19.8,0,3,3,0,0,1-4.24,4.24,7.93,7.93,0,0,0-11.32,0L23.84,64.85A8,8,0,0,0,35.15,76.16L50,61.31a3,3,0,1,1,4.3,4.18l-.06.07ZM64.85,55a14,14,0,0,1-19.8,0c-1.42-1.42-1.88-3.28-.46-4.7a3.64,3.64,0,0,1,5.16,0l-.46.46a7.93,7.93,0,0,0,11.32,0L76.16,35.15A8,8,0,1,0,64.85,23.84L50,38.69a3,3,0,1,1-4.3-4.19l.06-.06L60.61,19.59a14,14,0,0,1,19.8,19.8Z" />
    </Svg>
  )),
);

ModuleLinks.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

ModuleLinks.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '2rem',
};

export default withTheme(ModuleLinks);
