// =============================
// Imports
// =============================

import { FilterBar } from './styles';

// =============================
// Exports
// =============================

export default FilterBar;
