// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { DropdownList } from '@mewo/components';

// =============================
// Styles
// =============================

export const StyledDropdown = styled(DropdownList).attrs(({ placement, theme }) => ({
  placement,
  contentStyles: css`
    max-height: none;
  `,
  dropdownStyles: css`
    background: ${theme.colors.dropdown.background};
    box-shadow: 0 0.4rem 1.5rem 0 ${theme.colors.boxShadow};
    color: #191919;
    font-size: 1.2rem;
    font-weight: ${theme.fontWeight.semibold};
    padding: 0.8rem 0;
  `,
  dropdownClassName: 'ignore-react-onclickoutside',
  optionsContainerStyles: css`
    max-height: 20rem;
  `,
  withoutStyles: true,
}))``;

export const Option = styled.div`
  text-decoration: none;
  transition: color ${({ theme }) => theme.transition.medium},
    opacity ${({ theme }) => theme.transition.medium};
  padding: 0.8rem 1.2rem;
  white-space: nowrap;
  width: 100%;

  ${({ disabled, action }) => (disabled
    ? css`
      pointer-events: none;
      opacity: ${action ? 0.7 : 1};
    `
    : css`
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    `)};
`;

export const OptionLink = styled(Option).attrs({
  as: 'a',
})`
  display: block;
`;
