// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  show: false,
  message: null,
  context: null,
  description: null,
};

// =============================
// Reducer
// =============================

export default function core(state = initialState, action) {
  switch (action.type) {
    case acts.OPEN_PREMIUM_MODAL:
      return {
        ...state,
        show: true,
        message: action.payload.message,
        context: action.payload.context,
        description: action.payload.description,
      };

    case acts.CLOSE_PREMIUM_MODAL:
      return {
        ...state,
        show: false,
        message: null,
        context: null,
        description: null,
      };

    default:
      return state;
  }
}
