// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import artistplaceholder from '@mewo/components/assets/images/placeholders/artist_500x500.png?webp';
import { Field, Form } from 'react-final-form';
import _get from 'lodash/get';

// Config
import { withTranslation } from '../../../../config/i18n';

// Actions
import { panelActions } from '../../../../store/actions/SidePanelActions';

// Constants
import { META_PEOPLE_PANEL } from '../../../../store/constants/SidePanelTypes';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import DocumentStatus from '../documentStatus';
import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import Autosave from '../../../other/autosave';
import GooglePlacesInput from '../../../presentationals/inputs/googlePlacesInput';
import CopyLink from '../copyLink';

import validator from '../../../../validators/meta/people';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getSiteOrigin } from '../../../../helpers/misc';
import { formatDateString } from '../../../../helpers/dates';

// Styles
import {
  LeftColumn,
  RightColumn,
  GenericCover,
  DocumentName,
  DocumentType,
  DocumentCreatedAt,
  DocumentBody,
  SectionTitle,
  SectionNotice,
  SectionBox,
  TabLinksWrapper,
  TabLink,
  TabElement,
} from '../common.styles';
import { InputDouble, InputWrapper } from '../../global.styles';

// =============================
// Component
// =============================

const entity = 'people';

class MetaPeople extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      data: pth.people,
      isModifying: PropTypes.bool.isRequired,
      isDuplicating: PropTypes.bool.isRequired,
      isDeleting: PropTypes.bool.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    t: PropTypes.func.isRequired,
  };

  pickFormData = (data) => {
    const keys = [
      'full_name',
      'email',
      'address',
      'company_name',
      'job',
      'phone_number',
      'secondary_phone_number',
    ];

    const transformed = {};

    keys.forEach((key) => {
      transformed[key] = _get(data, key, '');
    });

    return transformed;
  };

  renderPanelAnchors = () => {
    const { t } = this.props;

    return (
      <TabLinksWrapper>
        <TabLink name="general_information">
          {t(`pages:meta.${entity}s.panel.general_information_anchor`)}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  render() {
    const { panel, localPreferences, t } = this.props;
    const { data, isModifying, isDuplicating, isDeleting } = panel;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/${entity}s?panel=${data.id}`
      : '';

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[META_PEOPLE_PANEL].get}
        modify={panelActions[META_PEOPLE_PANEL].modify}
        duplicate={panelActions[META_PEOPLE_PANEL].duplicate}
        del={panelActions[META_PEOPLE_PANEL].delete}
        lang={{
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {({ modifyDocument, duplicateDocument, deleteDocument }) => !!data && (
          <Fragment>
            <LeftColumn>
              <GenericCover placeholder={artistplaceholder} />
              <DocumentName>
                {data.full_name}
              </DocumentName>
              <DocumentType>
                {t(`common:entities.${entity}`)}
              </DocumentType>
              <DocumentCreatedAt>
                {t(`pages:meta.${entity}s.panel.created_at`)}
                :&nbsp;
                {formatDateString(data.created_at, localPreferences.dateFormat, true)}
              </DocumentCreatedAt>
              <CopyLink
                link={shareLink}
                content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
              />
            </LeftColumn>
            <RightColumn initTab="general_information">
              <DocumentStatus
                isModifying={isModifying}
                isDuplicating={isDuplicating}
                isDeleting={isDeleting}
                localPreferences={localPreferences}
                documentRights={{ isOwner: true }}
                updatedAt={data.updated_at}
                duplicateDocument={duplicateDocument}
                deleteDocument={deleteDocument}
              />
              {this.renderPanelAnchors()}
              <DocumentBody>
                <Form
                  onSubmit={() => {}}
                  initialValues={this.pickFormData(data)}
                  subscription={{}}
                  keepDirtyOnReinitialize
                >
                  {() => (
                    <Fragment>
                      <Autosave
                        debounce={5000}
                        save={modifyDocument}
                        nullValuesTransformer={this.pickFormData}
                        autosavePanelId={`${META_PEOPLE_PANEL}_${data.id}`}
                      />
                      <TabElement name="general_information">
                        <SectionTitle>{t(`pages:meta.${entity}s.panel.general_information`)}</SectionTitle>
                        <SectionNotice>
                          {t(`pages:meta.${entity}s.panel.general_information_notice`)}
                        </SectionNotice>
                        <SectionBox>
                          <InputWrapper>
                            <Field
                              name="full_name"
                              component={MewoInput}
                              use={Input}
                              type="text"
                              label={t(`pages:meta.${entity}s.panel.full_name`)}
                              fullWidth
                              validate={validator.full_name}
                              context="autosave"
                            />
                            <Field
                              name="email"
                              component={MewoInput}
                              use={Input}
                              type="text"
                              label={t(`pages:meta.${entity}s.panel.email`)}
                              fullWidth
                              validate={validator.email}
                              context="autosave"
                            />
                            <Field
                              name="address"
                              component={MewoInput}
                              use={GooglePlacesInput}
                              type="text"
                              label={t(`pages:meta.${entity}s.panel.address`)}
                              fullWidth
                              context="autosave"
                            />
                            <InputDouble>
                              <Field
                                name="company_name"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.company_name`)}
                                context="autosave"
                              />
                              <Field
                                name="job"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.job`)}
                                context="autosave"
                              />
                            </InputDouble>
                            <InputDouble>
                              <Field
                                name="phone_number"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.phone_number`)}
                                context="autosave"
                              />
                              <Field
                                name="secondary_phone_number"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.secondary_phone_number`)}
                                context="autosave"
                              />
                            </InputDouble>
                          </InputWrapper>
                        </SectionBox>
                      </TabElement>
                    </Fragment>
                  )}
                </Form>
              </DocumentBody>
            </RightColumn>
          </Fragment>
        )}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core }) {
  return {
    localPreferences: core.localPreferences,
  };
}

export default compose(
  withTranslation(['common', 'pages', 'components', 'errors']),
  connect(mapStateToProps),
)(MetaPeople);
