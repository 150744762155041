// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import _get from 'lodash/get';

import * as pth from '../../../helpers/proptypes';

import { Wrapper, PrevZone, PrevIcon, SubWrapper } from './styles';

// =============================
// Component
// =============================

class SidePanel extends Component {
  static propTypes = {
    panels: PropTypes.arrayOf(pth.sidePanel).isRequired,
    isOpened: PropTypes.bool.isRequired,
    playerIsOpened: PropTypes.bool.isRequired,
    CurrentPanel: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    openPanel: PropTypes.func.isRequired,
    removePanel: PropTypes.func.isRequired,
    closePanel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    CurrentPanel: null,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { isOpened, closePanel } = this.props;

    // If escape button & is opened
    if (isOpened && e.keyCode === 27) {
      closePanel();
    }
  };

  // Used by react-onclickoutside
  handleClickOutside = () => {
    const { isOpened, closePanel } = this.props;
    if (isOpened) closePanel();
  };

  handlePrevClick = () => {
    const { isOpened, panels, removePanel } = this.props;
    if (isOpened) removePanel(_get(panels, '0.uuid', null));
  };

  render() {
    const { panels, isOpened, playerIsOpened, CurrentPanel, openPanel } = this.props;

    return (
      <Wrapper isOpened={isOpened} playerIsOpened={playerIsOpened}>
        <PrevZone onClick={this.handlePrevClick}>
          <PrevIcon />
        </PrevZone>
        <SubWrapper>
          {CurrentPanel && <CurrentPanel panel={panels[0]} openPanel={openPanel} />}
        </SubWrapper>
      </Wrapper>
    );
  }
}

export default onClickOutside(SidePanel);
