// =============================
// Imports
// =============================

// External Dependencies
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Router from 'next/router';
import { Flex, Icons } from '@mewo/components';
import albumplaceholder from '@mewo/components/assets/images/placeholders/album_500x500.png?webp';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

// Config
import { withTranslation } from '../../../../config/i18n';
import entityConfig from '../../meta/albums/config';

// Actions
import { panelActions, triggerAutosaveCheck } from '../../../../store/actions/SidePanelActions';
import { createMetaPitch as createMetaPitchBase } from '../../../../store/actions/MetaActions';

// Constants
import * as spts from '../../../../store/constants/SidePanelTypes';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import DocumentStatus from '../documentStatus';
import Autosave from '../../../other/autosave';
import MewoInput from '../../../presentationals/mewoInput';
import ApiSelectInput from '../../../containers/inputs/apiSelectInput';
import MetaDisplayArtist from '../inputs/metaDisplayArtist';
import Input from '../../../presentationals/inputs/input';
import InputWithLanguage from '../../../containers/inputWithLanguage';
import SelectInput from '../../../presentationals/inputs/selectInput';
import DatePickerInput from '../../../presentationals/inputs/datePickerInput';
import TextAreaInput from '../../../presentationals/inputs/textAreaInput';
import MetaRightsInformations from '../metaRightsInformations';
import Uploader from '../../../presentationals/uploader';
import MetaCustomFields from '../metaCustomFields';
import MetaDownloadDropdown from '../../../other/metaDownloadDropdown';
import WithMetaSearchRoute from '../../../faac/withMetaSearchRoute';
import CopyLink from '../copyLink';
import MetaTags from '../../../other/metaTags';
import DocumentTracks from '../documentTracks';
import Autotag from '../autotag';

import validator from '../../../../validators/meta/album';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getSiteOrigin } from '../../../../helpers/misc';
import { getApiSelectInputData, getDocumentRights, getGenericDocCoverUrl } from '../../../../helpers/meta-common';
import { formatDateString } from '../../../../helpers/dates';
import { getDocName, getNameWithFallback, getSearchName } from '../../../../helpers/doc-names';

// Styles
import {
  AddItemBtn,
  GenericAction,
  DocumentBody,
  DocumentCreatedAt,
  DocumentName,
  DocumentType,
  DownloadActionIcon,
  DownloadActionWrapper,
  FileHelper,
  GenericCover,
  GenericOwnership,
  GenericOwnershipCount,
  GenericSearchIcon,
  GenericSearchLink,
  InputItemBox,
  LeftColumn,
  LineInnerLoader,
  LineLoader,
  LineLoaderWrapper,
  MiscHelper,
  RelationJobTooltip,
  RelationsWrapper,
  RelationTitleWrapper,
  RightColumn,
  SaveItemButton,
  SectionBox,
  SectionNotice,
  SectionTitle,
  TabElement,
  TabLink,
  TabLinksWrapper,
} from '../common.styles';
import { InputDouble, InputWrapper } from '../../global.styles';

// =============================
// Component
// =============================

const entity = 'album';

class MetaAlbum extends Component {
  static propTypes = {
    tenantId: PropTypes.string.isRequired,
    panel: PropTypes.shape({
      data: pth.album,
      additionalData: PropTypes.shape({
        tracks: PropTypes.shape({
          data: PropTypes.arrayOf(pth.smallTrack),
        }),
        tags: PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.shape({
            status: PropTypes.number,
            tag: pth.tag,
          })),
        }),
        shares: PropTypes.shape({
          data: PropTypes.shape({
            public: PropTypes.number,
            agents: PropTypes.objectOf(PropTypes.number),
            showcase: PropTypes.objectOf(PropTypes.number),
          }),
        }),
        autotagging: PropTypes.shape({
          data: PropTypes.shape({
            nb_tracks: PropTypes.number,
            nb_tracks_not_autotagged: PropTypes.number,
          }),
        }),
      }),
      isLoading: PropTypes.bool.isRequired,
      'isLoading-tags': PropTypes.bool,
      'isLoading-tracks': PropTypes.bool,
      isModifying: PropTypes.bool.isRequired,
      'isModifying-shares': PropTypes.bool,
      'isModifying-ingest': PropTypes.bool,
      'isModifying-tags': PropTypes.bool,
      'isModifying-tracks': PropTypes.bool,
      'isModifying-attachment': PropTypes.bool,
      isAutotagging: PropTypes.bool.isRequired,
      isDuplicating: PropTypes.bool.isRequired,
      isUploading: PropTypes.bool.isRequired,
      isUploadingAttachment: PropTypes.bool.isRequired,
      isDeletingUpload: PropTypes.bool.isRequired,
      isDeletingAttachment: PropTypes.arrayOf(PropTypes.string).isRequired,
      isDeleting: PropTypes.bool.isRequired,
      uploadProgress: PropTypes.number.isRequired,
      uploadAttachmentProgress: PropTypes.number.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    relationsNotifications: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      progress: PropTypes.number,
      isAudioIngestion: PropTypes.bool,
    })),
    customFields: pth.customFieldsConfig.isRequired,
    albumTypes: PropTypes.arrayOf(pth.albumType).isRequired,
    providers: PropTypes.arrayOf(pth.provider).isRequired,
    modoConfigsCount: PropTypes.number.isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    createMetaPitch: PropTypes.func.isRequired,
    isCreatingPitch: PropTypes.bool.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    relationsNotifications: [],
  };

  componentDidUpdate(prevProps) {
    const { relationsNotifications: prevRelationsNotifications } = prevProps;
    const { relationsNotifications, panel: { data } } = this.props;

    if (
      data
      && typeof this.refreshPanel === 'function'
      && prevRelationsNotifications.length
      && !relationsNotifications.length
    ) {
      this.refreshPanel(true);
    }
  }

  pickFormData = (data, documentRights, init = false) => {
    if (!documentRights.canWrite && !init) {
      return {
        showcase: _get(data, 'showcase', []),
      };
    }

    const keys = {
      title: _get(data, 'title', ''),
      album_ref: _get(data, 'album_ref', ''),
      album_type: init
        ? _get(data, 'album_type.id', null)
        : _get(data, 'album_type', null),
      catalog: getApiSelectInputData(data.catalog, 'catalog', init),
      display_artists: _get(data, 'display_artists', [])
        .filter(({ artist }) => init || !!getApiSelectInputData(artist, 'artist', init))
        .map(({ artist, alias }) => ({
          artist: getApiSelectInputData(artist, 'artist', init),
          alias: alias || '',
        })),
      release_date: _get(data, 'release_date', null),
      upc: _get(data, 'upc', ''),
      ean: _get(data, 'ean', ''),
      showcase: init
        ? _get(data, 'showcase', []).map(({ id }) => id)
        : _get(data, 'showcase', []),
      descriptions: _get(data, 'descriptions', [])
        .map(({ locale, value }) => ({ locale, value })),
    };

    if (documentRights.canAccessNotes) {
      keys.notes = _get(data, 'notes', '');
    }

    if (documentRights.isOwner) {
      keys.public = _get(data, 'public', false);
      keys.agents = _get(data, 'agents', []);

      keys.custom = _get(data, 'custom', {});
    }

    return keys;
  };

  renderPanelAnchors = (data) => {
    const { customFields, providers, t } = this.props;

    const documentRights = getDocumentRights(
      data.owned_by_tenant,
      data.tenant.id,
      providers,
    );

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    return (
      <TabLinksWrapper>
        <TabLink name="tracks">
          {t(`pages:meta.${entity}s.panel.tracks_anchor`)}
        </TabLink>
        <TabLink name="tags">
          {t(`pages:meta.${entity}s.panel.tags_anchor`)}
        </TabLink>
        <TabLink name="general_information">
          {t(`pages:meta.${entity}s.panel.general_information_anchor`)}
        </TabLink>
        {(documentRights.isOwner && hasCustomFields) && (
          <TabLink name="custom_fields_information">
            {t(`pages:meta.${entity}s.panel.custom_fields_information_anchor`)}
          </TabLink>
        )}
        {documentRights.isOwner && (
          <TabLink name="agents_information">
            {t(`pages:meta.${entity}s.panel.agents_information_anchor`)}
          </TabLink>
        )}
        {!documentRights.isOwner && (
          <TabLink name="providers_information">
            {t(`pages:meta.${entity}s.panel.providers_information_anchor`)}
          </TabLink>
        )}
        <TabLink name="modo_information">
          {t(`pages:meta.${entity}s.panel.modo_information_anchor`)}
        </TabLink>
        <TabLink name="uploads_information">
          {t(`pages:meta.${entity}s.panel.uploads_information_anchor`)}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  render() {
    const {
      tenantId,
      panel,
      localPreferences,
      relationsNotifications,
      customFields,
      albumTypes,
      providers,
      modoConfigsCount,
      triggerPanelAutosaveCheck,
      createMetaPitch,
      isCreatingPitch,
      i18n: { language },
      t,
    } = this.props;

    const {
      data,
      additionalData,
      isModifying,
      isAutotagging,
      isDuplicating,
      isUploading,
      isUploadingAttachment,
      isDeletingUpload,
      isDeletingAttachment,
      isDeleting,
      uploadProgress,
      uploadAttachmentProgress,
    } = panel;

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/${entity}s?panel=${data.id}`
      : '';

    const albumTypesOptions = _sortBy(
      albumTypes.map(({ id, names }) => ({
        label: getNameWithFallback(names, language), value: id,
      })),
      'label',
    );

    let inputDateFormat = 'DD/MM/YYYY';
    if (localPreferences.dateFormat === 'MMDDYYYY') inputDateFormat = 'MM/DD/YYYY';

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[spts.META_ALBUM_PANEL].get}
        getAdditionalOptions={{
          fnc: panelActions[spts.META_ALBUM_PANEL].getAdditional,
          list: [
            ['count/shares', 'shares'],
            ['tracks', 'tracks'],
            ['tracks/tags', 'tags'],
            [
              `/meta/autotagging/${entity}/${panel.id}/data`,
              'autotagging',
              '',
              panelData => panelData?.owned_by_tenant,
            ],
          ],
        }}
        requestGridRefresh={panelActions[spts.META_ALBUM_PANEL].requestGridRefresh}
        modify={panelActions[spts.META_ALBUM_PANEL].modify}
        modifyAdditional={panelActions[spts.META_ALBUM_PANEL].modifyAdditional}
        autotagOptions={{
          fnc: panelActions[spts.META_ALBUM_PANEL].autotag,
          getAdditionalList: [
            ['tracks/tags', 'tags'],
            [
              `/meta/autotagging/${entity}/${panel.id}/data`,
              'autotagging',
            ],
          ],
        }}
        duplicate={panelActions[spts.META_ALBUM_PANEL].duplicate}
        uploadFile={panelActions[spts.META_ALBUM_PANEL].uploadFile}
        deleteFile={panelActions[spts.META_ALBUM_PANEL].deleteFile}
        del={panelActions[spts.META_ALBUM_PANEL].delete}
        lang={{
          autotagTitle: t(`pages:meta.${entity}s.autotag_documents`, { name: data?.title }),
          autotagDesc: t(`pages:meta.${entity}s.autotag_documents_notice`),
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {({
          refreshPanel,
          modifyDocument,
          modifyAdditional,
          autotagDocument,
          duplicateDocument,
          uploadDocumentFile,
          deleteDocumentFile,
          deleteDocument,
        }) => {
          if (!data) return null;

          this.refreshPanel = refreshPanel;

          const relationsNotification = relationsNotifications
            .find(notification => notification.id === data.id);

          const documentRights = getDocumentRights(
            data.owned_by_tenant,
            data.tenant.id,
            providers,
          );

          const image = _get(data, 'image.original.url')
            ? {
              name: _get(data, 'image.original.file_name'),
              url: _get(data, 'image.original.url'),
            }
            : null;

          let imageUploadStatus = data.has_file ? 2 : 0;
          let attachmentUploadStatus = 0;
          // This needs to be last as it overwrites everything
          if (isUploading || isDeletingUpload) imageUploadStatus = 1;
          if (isUploadingAttachment || !!isDeletingAttachment.length) attachmentUploadStatus = 1;

          const isIngestingImage = (
            _get(data, 'image.conversion_id') === 'ingestion-conversion'
            && !_get(data, 'image.error_key')
          );

          const attachmentUploadDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isModifying
            || _get(panel, 'isModifying-attachment', false);

          const formDisabled = !documentRights.canWrite
            || !!relationsNotification
            || panel['isModifying-shares']
            || isUploading
            || isDeletingUpload;
          const writableForAgentDisabled = !!relationsNotification
            || isUploading
            || isDeletingUpload;
          const uploadDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isModifying
            || isIngestingImage;

          const getASIAction = (key, ASIEntity) => {
            if (!_get(data, key)) return null;

            return () => triggerPanelAutosaveCheck(
              'open',
              [spts.metaMapping[ASIEntity], _get(data, `${key}.id`)],
            );
          };

          return (
            <Fragment>
              <LeftColumn>
                <GenericCover
                  placeholder={albumplaceholder}
                  cover={getGenericDocCoverUrl(data.image)}
                />
                <DocumentName>
                  {data.title}
                </DocumentName>
                <DocumentType>
                  {t(`common:entities.${entity}`)}
                </DocumentType>
                <Autotag.Status
                  autotaggingData={_get(additionalData, 'autotagging.data')}
                  documentRights={documentRights}
                  entity={entity}
                  t={t}
                />
                <DocumentCreatedAt>
                  {t(`pages:meta.${entity}s.panel.created_at`)}
                  :&nbsp;
                  {formatDateString(data.created_at, localPreferences.dateFormat, true)}
                </DocumentCreatedAt>
                <CopyLink
                  link={shareLink}
                  content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                  tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
                />

                {documentRights.isOwner && (
                  <Fragment>
                    {!!_get(data, 'pitches', []).length && (
                      <GenericAction
                        onClick={() => triggerPanelAutosaveCheck(
                          'open',
                          [spts.META_PITCH_PANEL, data.pitches[0]],
                        )}
                        disabled={isCreatingPitch}
                      >
                        {t(`pages:meta.${entity}s.open_pitch`)}
                      </GenericAction>
                    )}

                    {(modoConfigsCount > 1 || !_get(data, 'pitches', []).length) && (
                      <GenericAction
                        onClick={() => createMetaPitch({ type: 'album', entity: data.id })}
                        disabled={isCreatingPitch}
                      >
                        {t(`pages:meta.${entity}s.create_pitch`)}
                      </GenericAction>
                    )}
                  </Fragment>
                )}

                {!!_get(additionalData, 'tracks.data.length') && (
                  <GenericOwnership>
                    <GenericOwnershipCount>
                      {t('common:entities.number_tracks', {
                        count: _get(additionalData, 'tracks.data.length'),
                      })}
                    </GenericOwnershipCount>
                  </GenericOwnership>
                )}

                <Autotag.ColumnAction
                  autotagDocument={autotagDocument}
                  autotaggingData={_get(additionalData, 'autotagging.data')}
                  documentRights={documentRights}
                  entity={entity}
                  t={t}
                />

                <MetaDownloadDropdown
                  entity={entity}
                  documentId={data.id}
                  documentName={data.title}
                  canDownload={!!_get(additionalData, 'tracks.data.length')}
                  togglerElement={(
                    <DownloadActionWrapper>
                      <DownloadActionIcon />
                      {t(`pages:meta.${entity}s.downloads.toggler_title`)}
                    </DownloadActionWrapper>
                  )}
                />

                {!!_get(additionalData, 'tracks.data.length') && (
                  <WithMetaSearchRoute
                    entity="track"
                    filters={{ albums: [[data.id, data.title]] }}
                    resetQuery
                  >
                    {routeQuery => (
                      <GenericSearchLink onClick={() => Router.push(`/meta/tracks${routeQuery}`)}>
                        <GenericSearchIcon />
                        {t(`pages:meta.${entity}s.panel.search_tracks`)}
                      </GenericSearchLink>
                    )}
                  </WithMetaSearchRoute>
                )}

                {!!relationsNotification && (
                  <RelationsWrapper>
                    <RelationTitleWrapper>
                      {t(`pages:meta.${entity}s.panel.ongoing_relations_job`)}
                      <RelationJobTooltip
                        content={t(`pages:meta.${entity}s.panel.ongoing_relations_job_tooltip`)}
                      >
                        <Icons.Informations />
                      </RelationJobTooltip>
                    </RelationTitleWrapper>
                    <LineLoaderWrapper>
                      <LineLoader>
                        <LineInnerLoader progress={relationsNotification.progress} />
                      </LineLoader>
                    </LineLoaderWrapper>
                  </RelationsWrapper>
                )}
              </LeftColumn>
              <RightColumn initTab="tracks">
                <DocumentStatus
                  isModifying={(
                    isModifying
                    || _get(panel, 'isModifying-tracks', false)
                    || _get(panel, 'isModifying-tags', false)
                    || _get(panel, 'isModifying-ingest', false)
                  )}
                  isAutotagging={isAutotagging}
                  isDuplicating={isDuplicating}
                  isDeleting={isDeleting}
                  localPreferences={localPreferences}
                  documentRights={documentRights}
                  updatedAt={data.updated_at}
                  duplicateDocument={duplicateDocument}
                  deleteDocument={deleteDocument}
                />
                {this.renderPanelAnchors(data)}
                <DocumentBody>
                  <TabElement name="tracks">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.tracks_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.tracks_information_notice`)}
                    </SectionNotice>
                    <DocumentTracks
                      isLoading={_get(panel, 'isLoading-tracks', false)}
                      isModifying={_get(panel, 'isModifying-tracks', false)
                        || _get(panel, 'isModifying-ingest', false)}
                      relationsNotification={relationsNotification}
                      tenantId={tenantId}
                      entity={entity}
                      parentDocument={data}
                      data={_get(additionalData, 'tracks.data', [])}
                      documentRights={documentRights}
                      modifyAdditional={modifyAdditional}
                    />
                  </TabElement>

                  <TabElement name="tags">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.tags_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.tags_information_notice`)}
                    </SectionNotice>
                    <Autotag.PanelSection
                      autotagDocument={autotagDocument}
                      autotaggingData={_get(additionalData, 'autotagging.data')}
                      documentRights={documentRights}
                      entity={entity}
                      t={t}
                    />
                    <SectionBox>
                      {!_get(panel, 'isLoading-tags', false) && (
                        <MetaTags
                          currentTagsTitle={t(`pages:meta.${entity}s.panel.current_tags`)}
                          statusData={_get(additionalData, 'tags.data', [])}
                          disabled={(
                            !documentRights.canWrite
                            || isAutotagging
                            || _get(panel, 'isModifying-tags', false)
                            || !_get(additionalData, 'tracks.data.length')
                          )}
                          onAdd={tagId => modifyAdditional(
                            'tracks/tags',
                            'post',
                            { added: [tagId] },
                            'tags',
                          )}
                          onRemove={tagId => modifyAdditional(
                            'tracks/tags',
                            'post',
                            { removed: [tagId] },
                            'tags',
                          )}
                        />
                      )}
                    </SectionBox>
                  </TabElement>

                  <Form
                    onSubmit={() => {}}
                    initialValues={this.pickFormData(data, documentRights, true)}
                    subscription={{ values: true }}
                    keepDirtyOnReinitialize
                    mutators={{ ...arrayMutators }}
                  >
                    {({ form, values }) => (
                      <Fragment>
                        <Autosave
                          debounce={5000}
                          save={modifyDocument}
                          nullValuesTransformer={formValues => this.pickFormData(
                            formValues, documentRights, false,
                          )}
                          autosavePanelId={`${spts.META_ALBUM_PANEL}_${data.id}`}
                        />
                        <TabElement name="general_information">
                          <SectionTitle>{t(`pages:meta.${entity}s.panel.general_information`)}</SectionTitle>
                          <SectionNotice>
                            {t(`pages:meta.${entity}s.panel.general_information_notice`)}
                          </SectionNotice>
                          <SectionBox>
                            <InputWrapper>
                              <Field
                                name="title"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.title`)}
                                fullWidth
                                validate={validator.title}
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <InputDouble>
                                <Field
                                  name="album_ref"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.album_ref`)}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <Field
                                  name="album_type"
                                  component={MewoInput}
                                  use={SelectInput}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.album_type`)}
                                  options={albumTypesOptions}
                                  renderOptionsVariant="classic"
                                  renderValuesVariant="text"
                                  searchable
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                              </InputDouble>
                              <Field
                                name="catalog"
                                component={MewoInput}
                                use={ApiSelectInput}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.catalog`)}
                                entity="meta/catalogs"
                                getEntityLabel={getDocName('catalog')}
                                getSearchEntityLabel={getSearchName('catalog')}
                                onTheFlyEnabled={documentRights.isOwner}
                                onTheFlyField="name"
                                listAdditionalQuery={documentRights.providerId
                                  ? `&provider_id=${documentRights.providerId}`
                                  : ''}
                                itemAction={getASIAction('catalog', 'catalog')}
                                resetBtnTriggersOnChange
                                fullWidth
                                tooltipMessage={t(`pages:meta.${entity}s.panel.catalog_tooltip`)}
                                context="autosave"
                                disabled={formDisabled}
                              />
                            </InputWrapper>
                          </SectionBox>
                          {(documentRights.canWrite || !!_get(data, 'display_artists', []).length) && (
                            <SectionBox>
                              <Fragment>
                                <FieldArray name="display_artists">
                                  {({ fields }) => fields.map((fieldName, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <InputItemBox key={index}>
                                      <MetaDisplayArtist
                                        documentRights={documentRights}
                                        entity={entity}
                                        fieldName={fieldName}
                                        fields={fields}
                                        formDisabled={formDisabled}
                                        formValues={values}
                                        getASIAction={getASIAction}
                                        index={index}
                                        name="display_artists"
                                        panelData={data}
                                      />
                                    </InputItemBox>
                                  ))}
                                </FieldArray>
                                {/** Maximum for this item is always 100 items */}
                                {(documentRights.canWrite && _get(values, 'display_artists', []).length < 100) && (
                                  <AddItemBtn
                                    onClick={() => form.mutators.push('display_artists', {
                                      artist: {
                                        value: null,
                                        label: '',
                                        search: '',
                                      },
                                      alias: '',
                                    })}
                                    disabled={formDisabled}
                                  >
                                    {t(`pages:meta.${entity}s.panel.display_artists.add`)}
                                  </AddItemBtn>
                                )}
                              </Fragment>
                            </SectionBox>
                          )}
                          <SectionBox>
                            <Field
                              name="release_date"
                              component={MewoInput}
                              use={DatePickerInput}
                              allowNull={false}
                              type="text"
                              label={t(`pages:meta.${entity}s.panel.release_date`)}
                              fullWidth
                              context="autosave"
                              dateFormat={inputDateFormat}
                              withCalendar
                              showResetBtn={false}
                              disabled={formDisabled}
                            />
                          </SectionBox>
                          <SectionBox>
                            <InputWrapper>
                              <Field
                                name="upc"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.upc`)}
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <Field
                                name="ean"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.ean`)}
                                context="autosave"
                                disabled={formDisabled}
                              />
                            </InputWrapper>
                          </SectionBox>
                          <SectionBox>
                            <Field
                              name="descriptions"
                              component={MewoInput}
                              use={InputWithLanguage}
                              defaultLanguage={language}
                              label={t(`pages:meta.${entity}s.panel.descriptions`)}
                              fullWidth
                              isTranslatable
                              inputType="textarea"
                              context="autosave"
                              disabled={formDisabled}
                              height="12rem"
                            />
                          </SectionBox>
                          {documentRights.canAccessNotes && (
                            <SectionBox>
                              <Field
                                name="notes"
                                component={MewoInput}
                                use={TextAreaInput}
                                label={t(`pages:meta.${entity}s.panel.notes`)}
                                fullWidth
                                context="autosave"
                                disabled={formDisabled}
                                height="12rem"
                              />
                            </SectionBox>
                          )}
                        </TabElement>

                        {documentRights.isOwner && hasCustomFields && (
                          <TabElement name="custom_fields_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.custom_fields_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.custom_fields_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <MetaCustomFields entity={entity} formDisabled={formDisabled} />
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}

                        <MetaRightsInformations
                          entity={entity}
                          sharesData={{
                            ..._get(additionalData, 'shares.data', {
                              public: 0,
                              agents: {},
                              showcase: {},
                            }),
                            total: _get(additionalData, 'tracks.data.length', 0),
                          }}
                          docAgents={data.agents}
                          docModos={data.showcase}
                          docPublic={data.public}
                          documentRights={documentRights}
                          formDisabled={formDisabled}
                          modifyAdditional={modifyAdditional}
                          resyncable
                          writableForAgentDisabled={writableForAgentDisabled}
                        />
                      </Fragment>
                    )}
                  </Form>

                  <TabElement name="uploads_information">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.uploads_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.uploads_information_notice`)}
                    </SectionNotice>
                    <SectionBox>
                      <Uploader
                        name="image"
                        value={image}
                        status={imageUploadStatus}
                        disabled={uploadDisabled}
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        onChange={(nextValue) => {
                          if (!nextValue) return deleteDocumentFile('image');
                          return uploadDocumentFile(nextValue, 'image');
                        }}
                        maxSize={entityConfig.panelUploads.image.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.image`)}
                      </FileHelper>
                      {isIngestingImage && (
                        <MiscHelper mt="1.2rem">
                          {t(`pages:meta.${entity}s.panel.image_ingestion_ongoing`)}
                        </MiscHelper>
                      )}
                      {_get(data, 'image.error_key') && (
                        <MiscHelper mt="1.2rem" isError>
                          {t(`errors:conversion.${_get(data, 'image.error_key')}_desc`)}
                        </MiscHelper>
                      )}
                    </SectionBox>

                    <SectionTitle>{t(`pages:meta.${entity}s.panel.attachment_uploads_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.attachment_uploads_information_notice`)}
                    </SectionNotice>
                    <SectionBox>
                      <Uploader
                        name="attachment"
                        accept=""
                        status={attachmentUploadStatus}
                        disabled={attachmentUploadDisabled}
                        isUploading={isUploadingAttachment}
                        uploadProgress={uploadAttachmentProgress}
                        onChange={nextValue => uploadDocumentFile(
                          nextValue,
                          'attachment',
                          `${t(`pages:meta.${entity}s.panel.attachment_default_name`)} ${data.attachments.length + 1}`,
                        )}
                        maxSize={entityConfig.panelUploads.attachment.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.attachment`)}
                      </FileHelper>
                    </SectionBox>
                    {[...data.attachments].reverse().map(attachment => (
                      <SectionBox key={attachment.id}>
                        <Form
                          onSubmit={a => modifyAdditional(
                            `/meta/uploads/album/${data.id}/attachment/${attachment.id}`,
                            'put',
                            { name: a.attachment_name },
                            'attachment',
                            true,
                          )}
                          initialValues={{ attachment_name: attachment.name }}
                        >
                          {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                              <Flex align="center" mb="1rem">
                                <Field
                                  name="attachment_name"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.attachment_name`)}
                                  disabled={attachmentUploadDisabled}
                                  validate={validator.attachment_name}
                                />
                                <SaveItemButton
                                  type="submit"
                                  disabled={attachmentUploadDisabled}
                                >
                                  {t('common:form.save')}
                                </SaveItemButton>
                              </Flex>
                            </form>
                          )}
                        </Form>
                        <Uploader
                          name="attachment_url"
                          accept=""
                          status={isDeletingAttachment.includes(attachment.id) ? 1 : 2}
                          disabled={attachmentUploadDisabled}
                          onChange={() => deleteDocumentFile('attachment', attachment.id)}
                          value={{
                            name: attachment.file_name,
                            url: attachment.url,
                          }}
                        />
                      </SectionBox>
                    ))}
                  </TabElement>
                </DocumentBody>
              </RightColumn>
            </Fragment>
          );
        }}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core, options, user, meta, modo }) {
  return {
    tenantId: user.data.tenant_id || user.data.id,
    localPreferences: core.localPreferences,
    relationsNotifications: user.relationsNotifications.albums,
    customFields: meta.customFields.data,
    albumTypes: options.data.albumtypes,
    providers: meta.providers,
    isCreatingPitch: meta.pitchs.isCreating,
    modoConfigsCount: modo.configs.data.length,
  };
}

export default compose(
  connect(mapStateToProps, {
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
    createMetaPitch: createMetaPitchBase,
  }),
  withTranslation(['common', 'pages', 'components', 'errors']),
)(MetaAlbum);
