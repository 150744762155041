/* Meta */
export const UPLOAD_META_PUBLISHER_FILE = 'UPLOAD_META_PUBLISHER_FILE';
export const UPLOAD_META_LABEL_FILE = 'UPLOAD_META_LABEL_FILE';
export const UPLOAD_META_ARTIST_FILE = 'UPLOAD_META_ARTIST_FILE';
export const UPLOAD_META_CATALOG_FILE = 'UPLOAD_META_CATALOG_FILE';
export const UPLOAD_META_ALBUM_FILE = 'UPLOAD_META_ALBUM_FILE';
export const UPLOAD_META_ALBUM_ATTACHMENT_FILE = 'UPLOAD_META_ALBUM_ATTACHMENT_FILE';
export const UPLOAD_META_PLAYLIST_FILE = 'UPLOAD_META_PLAYLIST_FILE';
export const UPLOAD_META_BRIEF_FILE = 'UPLOAD_META_BRIEF_FILE';
export const UPLOAD_META_TRACK_FILE = 'UPLOAD_META_TRACK_FILE';
export const UPLOAD_META_TRACK_STEMS_FILE = 'UPLOAD_META_TRACK_STEMS_FILE';
export const UPLOAD_META_TRACK_ATTACHMENT_FILE = 'UPLOAD_META_TRACK_ATTACHMENT_FILE';
export const UPLOAD_META_INGESTION_FILE = 'UPLOAD_META_INGESTION_FILE';

export const LAUNCH_SIMILARITY_SEARCH = 'LAUNCH_SIMILARITY_SEARCH';

/* Player */
export const GET_PLAYER_TRACKS_THROUGH_REQUEST = 'GET_PLAYER_TRACKS_THROUGH_REQUEST';

/* Playlist adder */
export const GET_REFS_FOR_PLAYLIST_ADDER = 'GET_REFS_FOR_PLAYLIST_ADDER';

/* Modo */
export const MODIFY_MODO_CONFIG_MAIN_SETTINGS = 'MODIFY_MODO_CONFIG_MAIN_SETTINGS';
export const MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS = 'MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS';
export const MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS = 'MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS';
export const MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS = 'MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS';
export const MODIFY_MODO_CONFIG_USERS_SETTINGS = 'MODIFY_MODO_CONFIG_USERS_SETTINGS';
export const MODIFY_MODO_CONFIG_URL_SETTINGS = 'MODIFY_MODO_CONFIG_URL_SETTINGS';
export const MODIFY_MODO_CONFIG_ACTIVE_SETTINGS = 'MODIFY_MODO_CONFIG_ACTIVE_SETTINGS';
export const UPLOAD_MODO_CONFIG_IMAGE = 'UPLOAD_MODO_CONFIG_IMAGE';

export const MODIFY_MODO_CONFIG_MENUS = 'MODIFY_MODO_CONFIG_MENUS';

export const MODIFY_MODO_CONFIG_LEGAL = 'MODIFY_MODO_CONFIG_LEGAL';

export const MODIFY_MODO_CONFIG_PAGE = 'MODIFY_MODO_CONFIG_PAGE';
export const UPLOAD_MODO_CONFIG_PAGE_IMAGE = 'UPLOAD_MODO_CONFIG_PAGE_IMAGE';
export const MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_MODULES_ORDER = 'MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_MODULES_ORDER';

export const MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS = 'MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS';
export const MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS = 'MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS';

export const MODIFY_MODO_USER = 'MODIFY_MODO_USER';

export const CREATE_MODO_USER_IMPORT = 'CREATE_USER_USER_IMPORT';
export const UPLOAD_MODO_USER_IMPORT = 'UPLOAD_MODO_USER_IMPORT';
export const MODIFY_MODO_USER_IMPORT = 'MODIFY_MODO_USER_IMPORT';

export const MODIFY_USER = 'MODIFY_USER';
export const MODIFY_USER_PASSWORD = 'MODIFY_USER_PASSWORD';
