// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { DropdownContext } from '@mewo/components';

import { FilterButton, OptionSelected, Option, DropdownIndicator, StyledDropdown } from './styles';

// =============================
// Component
// =============================

/**
 * NOTE: Uncontrolled component
 */

class FilterDropdown extends Component {
  static propTypes = {
    /** Default option name selected */
    defaultName: PropTypes.string,
    /** Translation strings */
    lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Array of options */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  static defaultProps = {
    defaultName: null,
  };

  renderOption = (option) => {
    const { defaultName, options } = this.props;
    const selectedOptionName = defaultName || _get(options, '[0].name', '');

    return <Option selected={option.name === selectedOptionName}>{option.label}</Option>;
  };

  renderTogglerElement = () => {
    const { defaultName, lang, options } = this.props;
    const selectedOptionLabel = defaultName
      ? _find(options, o => o.name === defaultName).label
      : _get(options, '[0].label', '');

    return (
      <FilterButton>
        {lang.sortBy}
        <OptionSelected>{selectedOptionLabel}</OptionSelected>
        <DropdownContext.Consumer>
          {({ opened }) => <DropdownIndicator opened={opened} />}
        </DropdownContext.Consumer>
      </FilterButton>
    );
  };

  render() {
    const { options } = this.props;

    return (
      <StyledDropdown
        options={options}
        renderOption={this.renderOption}
        togglerElement={this.renderTogglerElement()}
      />
    );
  }
}

export default FilterDropdown;
