// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

// Components
import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import SelectInput from '../../../presentationals/inputs/selectInput';
import CheckboxInput from '../../../presentationals/inputs/checkboxInput';
import metaEntities from '../../../../config/meta-entities';

// Helpers
import * as pth from '../../../../helpers/proptypes';

// =============================
// Component
// =============================

class MetaCustomFields extends Component {
  static propTypes = {
    entity: PropTypes.oneOf(metaEntities).isRequired,
    formDisabled: PropTypes.bool.isRequired,
    customFields: pth.customFieldsConfig.isRequired,
  };

  renderCustomFieldText = (customField) => {
    const { formDisabled } = this.props;

    return (
      <Field
        key={customField.key}
        name={`custom.${customField.key}`}
        component={MewoInput}
        use={Input}
        type="text"
        label={customField.name}
        fullWidth
        context="autosave"
        disabled={formDisabled}
      />
    );
  };

  renderCustomFieldBoolean = (customField) => {
    const { formDisabled } = this.props;

    return (
      <Field
        key={customField.key}
        name={`custom.${customField.key}`}
        component={MewoInput}
        use={CheckboxInput}
        type="checkbox"
        label={customField.name}
        context="autosave"
        disabled={formDisabled}
      />
    );
  };

  renderCustomFieldList = (customField, multiple = false) => {
    const { formDisabled } = this.props;

    return (
      <Field
        key={customField.key}
        name={`custom.${customField.key}`}
        component={MewoInput}
        use={SelectInput}
        type="text"
        label={customField.name}
        options={customField.data.list_options.map(option => ({
          label: option,
          value: option,
        }))}
        context="autosave"
        searchable
        multiple={multiple}
        selectAllHelper={multiple}
        renderOptionsVariant="check"
        renderValuesVariant="chip"
        disabled={formDisabled}
      />
    );
  }

  renderCustomField = (customField) => {
    switch (customField.type) {
      case 'text':
        return this.renderCustomFieldText(customField);

      case 'boolean':
        return this.renderCustomFieldBoolean(customField);

      case 'list':
        return this.renderCustomFieldList(customField, true);

      case 'single_value_list':
        return this.renderCustomFieldList(customField);

      default:
        return null;
    }
  };

  render() {
    const { entity, customFields } = this.props;

    const fields = customFields.fields
      .filter(customField => customField.collections.includes(entity));

    return (
      <Fragment>
        {fields.map(customField => this.renderCustomField(customField))}
      </Fragment>
    );
  }
}

function mapStateToProps({ meta }) {
  return {
    customFields: meta.customFields.data,
  };
}

export default connect(mapStateToProps)(MetaCustomFields);
