// =============================
// Imports
// =============================

import { required } from '../index';

// =============================
// Validator
// =============================

const validator = {
  catalog: value => required(value.value),
};

export default validator;
