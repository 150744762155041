// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mewo/components';

import * as pth from '../../../../helpers/proptypes';

import { StyledDropdown, Option, OptionLink } from './styles';

// =============================
// Component
// =============================

export default class HeaderDropdown extends Component {
  static propTypes = {
    /** Active page */
    activePage: PropTypes.string.isRequired,
    /** Array of links. */
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.func,
        name: PropTypes.string.isRequired,
      }),
    ),
    /** User object */
    user: pth.user,
  };

  static defaultProps = {
    links: [],
    user: null,
  };

  renderOptions = (option) => {
    const { activePage } = this.props;

    if (option.action || option.name === activePage) {
      return <Option selected={option.name === activePage}>{option.label}</Option>;
    }

    return (
      <OptionLink selected={option.name === activePage} href={option.name} noHover>
        {option.label}
      </OptionLink>
    );
  };

  render() {
    const { activePage, links, user } = this.props;

    return (
      <StyledDropdown
        defaultName={activePage}
        options={links}
        renderOption={option => this.renderOptions(option)}
        togglerElement={<Avatar user={user} />}
      />
    );
  }
}
