// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Button, Div } from '@mewo/components';

// =============================
// Styles
// =============================

export const ResyncableWrapper = styled(Div)`
  display: flex;
  align-items: center;
`;

export const ResyncableValue = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ResyncableCount = styled.div`
  margin-left: 1rem;
`;

export const ResyncableActions = styled.div`
  margin-left: auto;
  display: flex;
`;

export const ResyncableAction = styled(Button).attrs({
  size: 'small',
})`
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;
