// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { DragDrop, StatusBar } from '@uppy/react';
import { alpha } from '@material-ui/core';

// =============================
// Styles
// =============================

export const StyledDragDrop = styled(DragDrop)`
  ${({ disabled }) => disabled && css`
    && .uppy-DragDrop-container {
      pointer-events: none;
      cursor: default;
      filter: grayscale(1);
    }
  `};

  && .uppy-DragDrop-arrow {
    width: 3rem;
    height: 3rem;
    fill: #999999;
    margin-bottom: 0.5rem;
  }

  && .uppy-DragDrop-label {
    display: block;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  && .uppy-DragDrop--isDragDropSupported {
    border: 0.1rem dashed #999999;

    &:hover {
      background-color: ${({ theme }) => alpha(theme.colors.primary, 0.1)};
      border: 0.1rem dashed ${({ theme }) => theme.colors.primary}
    };
  }

  && .uppy-DragDrop--isDraggingOver {
    background-color: ${({ theme }) => alpha(theme.colors.type.success, 0.1)};
    border: 0.1rem dashed ${({ theme }) => theme.colors.type.success};
  }
`;

export const StyledStatusBar = styled(StatusBar)`
  && .uppy-StatusBar {
    display: flex;
    position: relative;
    height: 6rem;
    line-height: 6rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #fff;
    background-color: #fff;
    z-index: 1001;
    transition: height 0.2s;
    text-align: center;
  }

  && .uppy-StatusBar-progress {
    background-color: ${({ theme }) => theme.colors.primary};
    bottom: 0;
    height: 0.4rem;
    position: absolute;
    z-index: 1001;
    transition: background-color, width 0.3s ease-out;
  }

  && .uppy-StatusBar.is-complete .uppy-StatusBar-progress {
    background-color: ${({ theme }) => theme.colors.type.success};
  }

  && .uppy-StatusBar.is-error .uppy-StatusBar-progress {
    background-color: ${({ theme }) => theme.colors.type.error};
  }

  && .uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
    color: ${({ theme }) => theme.colors.type.success};
  }

  && .uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
    color: ${({ theme }) => theme.colors.type.error};
  }

  && .uppy-StatusBar-content {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1002;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333;
    height: 100%;
    margin: 0 auto;
  }

  && [dir="ltr"] .uppy-StatusBar-content {
    padding-left: 0;
  }

  && [dir="rtl"] .uppy-StatusBar-content {
    padding-right: 0;
  }

  && .uppy-StatusBar-spinner {
    display: none;
  }
`;
