// =============================
// Imports
// =============================

// External dependencies
import { v4 as uuid } from 'uuid';
import axios, { isCancel } from 'axios';
import _get from 'lodash/get';
import _some from 'lodash/some';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';
import * as spts from '../constants/SidePanelTypes';
import * as rqs from '../constants/RequestTypes';

// Helpers
import { getApiUrl, getXPreferredLanguage } from '../../helpers/misc';
import determineError from '../../helpers/errors';
import { cancelableRequest, cancelRequest } from '../helpers/axios';

// =============================
// Global Actions
// =============================

/**
 *  As you can see here, we have isUploading & isUploadingStems & isUploadingAttachment
 *  values in panels to handle states for Meta.
 *  The upload action is within MetaAction though as it could
 *  be used for grids as well as panels.
 */

// Open side panel
export const open = (panelType, panelId) => (dispatch, getState) => {
  const { panels } = getState().sidepanel;

  const hasPanels = !!panels.length;
  // NOTE: Do not reopen same panel twice in a row
  const nextPanelIsPrev = hasPanels && panels[0].type === panelType && panels[0].id === panelId;
  // NOTE: Do not open next panel if current is modifying, uploading or deleting + meta stats
  const prevIsModifyingAdditional = _some(
    Object.keys(_get(panels, '0', {}))
      .filter(k => k.indexOf('isModifying-') !== -1)
      .map(k => _get(panels, `0.${k}`, false)),
    e => e,
  );

  const prevIsMutating = hasPanels && (
    _get(panels, '0.isModifying', false)
    || prevIsModifyingAdditional
    || _get(panels, '0.isAutotagging', false)
    || _get(panels, '0.isUploading', false)
    || _get(panels, '0.isUploadingStems', false)
    || _get(panels, '0.isDeletingUpload', false)
    || _get(panels, '0.isDeletingStems', false)
    || _get(panels, '0.isDuplicating', false)
    || _get(panels, '0.isDeleting', false)
  );

  if (!nextPanelIsPrev && !prevIsMutating) {
    return dispatch({
      type: acts.OPEN_SIDE_PANEL,
      payload: {
        uuid: uuid(), // Generate a unique id for the panel
        type: panelType, // Panel type
        id: panelId, // Id of the document in db
        isLoading: false, // Initial loading state
        data: null, // Panel data
        additionalData: null, // Additional panel data
        getErrorMsg: null, // Error message
        getErrorReqId: null, // Request id for error
        isUploading: false, // Meta only: Is uploading file for entity
        isUploadingStems: false, // Meta tracks only: Is uploading stems for entity
        // eslint-disable-next-line max-len
        isUploadingAttachment: false, // Meta tracks & albums only: Is uploading attachment for entity
        uploadProgress: 0, // Meta only: Is uploading file for entity
        uploadStemsProgress: 0, // Meta tracks only: Is uploading stems for entity
        // eslint-disable-next-line max-len
        uploadAttachmentProgress: 0, // Meta tracks & albums only: Is uploading attachment for entity
        isDeletingUpload: false, // Meta only: Is deleting file for entity
        isDeletingStems: false, // Meta tracks only: Is deleting stems
        isDeletingAttachment: [], // Meta tracks & albums only: Is deleting attachment
        isDuplicating: false, // Meta only: Is duplicating entity
        isAutotagging: false, // Meta only: Is autotagging entity
        isModifying: false, // Is modifying entity
        isDeleting: false, // Is deleting entity
        hasAutosave: false, // Has an autosave form within panel
      },
    });
  }

  return null;
};

// Remove side panel
export const remove = panelUUID => (dispatch, getState) => {
  const { panels } = getState().sidepanel;

  const panel = panels.find(p => p.uuid === panelUUID);
  // NOTE: Do not remove if modification or deletion is happening
  //  In meta we need to handle other states as well.
  //  It will be always false for other panels
  const isModifyingAdditional = _some(
    Object.keys(panel || {})
      .filter(k => k.indexOf('isModifying-') !== -1)
      .map(k => _get(panel, k, false)),
    e => e,
  );

  const isMutating = _get(panel, 'isModifying', false)
    || isModifyingAdditional
    || _get(panel, 'isAutotagging', false)
    || _get(panel, 'isUploading', false)
    || _get(panel, 'isUploadingStems', false)
    || _get(panel, 'isUploadingAttachment', false)
    || _get(panel, 'isDeletingUpload', false)
    || _get(panel, 'isDeletingStems', false)
    || !!_get(panel, 'isDeletingAttachment', []).length
    || _get(panel, 'isDuplicating', false)
    || _get(panel, 'isDeleting', false);

  if (!isMutating) {
    return dispatch({
      type: acts.REMOVE_SIDE_PANEL,
      payload: panelUUID,
    });
  }

  return null;
};

// Set data for side panel
export const setData = (data = {}) => ({
  type: acts.SET_PANEL_DATA,
  payload: data,
});

// Set additional data for side panel
export const setAdditionalData = (data = {}) => ({
  type: acts.SET_PANEL_ADDITIONAL_DATA,
  payload: data,
});

// Close side panel
export const close = () => (dispatch, getState) => {
  const { panels } = getState().sidepanel;

  // NOTE: Do not close if modification or deletion is happening
  //  In meta we need to handle other states as well.
  //  It will be always false for other panels
  const isModifyingAdditional = _some(
    Object.keys(_get(panels, '0', {}))
      .filter(k => k.indexOf('isModifying-') !== -1)
      .map(k => _get(panels, `0.${k}`, false)),
    e => e,
  );

  const isMutating = _get(panels, '0.isModifying', false)
    || isModifyingAdditional
    || _get(panels, '0.isAutotagging', false)
    || _get(panels, '0.isUploading', false)
    || _get(panels, '0.isUploadingStems', false)
    || _get(panels, '0.isUploadingAttachment', false)
    || _get(panels, '0.isDeletingUpload', false)
    || _get(panels, '0.isDeletingStems', false)
    || !!_get(panels, '0.isDeletingAttachment', []).length
    || _get(panels, '0.isDuplicating', false)
    || _get(panels, '0.isDeleting', false);

  if (!isMutating) {
    return dispatch({
      type: acts.CLOSE_SIDE_PANEL,
    });
  }

  return dispatch({
    type: acts.REQUEST_CLOSE_SIDE_PANEL,
  });
};

export const triggerAutosaveCheck = (actionName, actionArgs = []) => (dispatch, getState) => {
  const actions = { open, remove, close };
  const { panels } = getState().sidepanel;

  if (!panels.length || (panels.length && !panels[0].hasAutosave)) {
    return dispatch(actions[actionName](...actionArgs));
  }

  // This allows errored panels to be closed
  const isLoadingAdditional = _some(
    Object.keys(panels[0])
      .filter(key => key.indexOf('isLoading-') !== -1)
      .map(key => _get(panels, `0.${key}`)),
    e => e,
  );

  if (
    panels.length
    && (panels[0].getErrorMsg || panels[0].isLoading || isLoadingAdditional)
  ) {
    return dispatch(actions[actionName](...actionArgs));
  }

  return dispatch({
    type: acts.TRIGGER_PANEL_AUTOSAVE_CHECK,
    payload: {
      actionName,
      actionArgs,
    },
  });
};

export const setHasAutosave = data => ({
  type: acts.SET_PANEL_HAS_AUTOSAVE,
  payload: data,
});

export const resolveAutosaveCheck = () => (dispatch, getState) => {
  const actions = { open, remove, close };
  const {
    postAutosaveAction: { actionName, actionArgs },
  } = getState().sidepanel;

  dispatch(actions[actionName](...actionArgs));

  return dispatch({
    type: acts.RESOLVE_PANEL_AUTOSAVE_CHECK,
  });
};

// =============================
// API Actions
// =============================

// Get action for panel
export const getPanel = (panelType, panelUUID, getEndpoint) => (dispatch, getState) => {
  dispatch({
    type: acts.GET_SIDE_PANEL_DATA_LOADING,
    payload: { uuid: panelUUID, type: panelType },
  });

  return axios({
    method: 'get',
    url: getApiUrl(getEndpoint(getState)),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.token,
      'x-preferred-language': getXPreferredLanguage(),
    },
  })
    .then((response) => {
      dispatch(
        setData({
          uuid: panelUUID,
          data: response.data,
        }),
      );

      return dispatch({
        type: acts.GET_SIDE_PANEL_DATA_SUCCESS,
        payload: { uuid: panelUUID, type: panelType },
      });
    })
    .catch((err) => {
      let errorMsg;

      switch (true) {
        case err.response && err.response.status === 404:
          errorMsg = i18n.t('errors:sidepanel.not_found');
          break;

        default:
          errorMsg = determineError(err);
          break;
      }

      return dispatch({
        type: acts.GET_SIDE_PANEL_DATA_FAILURE,
        payload: {
          uuid: panelUUID,
          message: errorMsg,
          reqId: _get(err, 'response.data.reqId'),
          type: panelType,
        },
      });
    });
};

// Get additional panel data
export const getPanelAdditional = (
  panelType,
  panelUUID,
  getEndpoint,
  dataKey,
  dataRest = {},
  method = 'get',
) => (dispatch, getState) => {
  dispatch({
    type: acts.GET_SIDE_PANEL_ADDITIONAL_DATA_LOADING,
    payload: { uuid: panelUUID, type: panelType, additionalDataKey: dataKey },
  });

  return axios({
    method,
    url: getApiUrl(getEndpoint(getState)),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.token,
      'x-preferred-language': getXPreferredLanguage(),
    },
  })
    .then((response) => {
      dispatch(
        setAdditionalData({
          uuid: panelUUID,
          data: {
            ...dataRest,
            data: response.data,
          },
          additionalDataKey: dataKey,
        }),
      );

      return dispatch({
        type: acts.GET_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS,
        payload: { uuid: panelUUID, type: panelType, additionalDataKey: dataKey },
      });
    })
    .catch(err => dispatch({
      type: acts.GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE,
      payload: {
        uuid: panelUUID,
        type: panelType,
        additionalDataKey: dataKey,
        message: determineError(err),
        reqId: _get(err, 'response.data.reqId'),
      },
    }));
};

export const requestGridRefreshFromPanel = (panelType, panelUUID) => dispatch => dispatch({
  type: acts.REQUEST_GRID_REFRESH_FROM_SIDE_PANEL,
  payload: { uuid: panelUUID, type: panelType },
});

// Modify action for panel
export const modifyPanel = (panelType, panelUUID, getEndpoint, nextData, getErrorMsg) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: acts.MODIFY_SIDE_PANEL_DATA_LOADING,
    payload: { uuid: panelUUID, type: panelType },
  });

  try {
    const response = await axios({
      method: 'put',
      url: getApiUrl(getEndpoint(getState)),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: nextData,
    });

    dispatch(
      setData({
        uuid: panelUUID,
        data: response.data,
      }),
    );

    return dispatch({
      type: acts.MODIFY_SIDE_PANEL_DATA_SUCCESS,
      payload: { uuid: panelUUID, type: panelType },
    });
  } catch (error) {
    let errorMsg;

    switch (true) {
      case typeof getErrorMsg === 'function' && !!getErrorMsg(error):
        errorMsg = getErrorMsg(error);
        break;

      case error.response && error.response.status === 404:
        errorMsg = i18n.t('errors:sidepanel.not_found');
        break;

      default:
        errorMsg = determineError(error);
        break;
    }

    return dispatch({
      type: acts.MODIFY_SIDE_PANEL_DATA_FAILURE,
      payload: {
        uuid: panelUUID,
        message: errorMsg,
        reqId: _get(error, 'response.data.reqId'),
        type: panelType,
      },
    });
  }
};

// Modify additional action for panel
export const modifyPanelAdditional = (
  panelType,
  panelUUID,
  getEndpoint,
  endpointMethod,
  nextData,
  dataKey,
  asMainEndpoint = false,
  getChainEndpoint = null,
  chainDataRest = {},
  getErrorMsg = null,
) => async (dispatch, getState) => {
  dispatch({
    type: acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_LOADING,
    payload: { uuid: panelUUID, type: panelType, additionalDataKey: dataKey },
  });

  try {
    const response = await axios({
      method: endpointMethod,
      url: getApiUrl(getEndpoint(getState)),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: nextData,
    });

    if (asMainEndpoint && response.data) {
      dispatch(setData({
        uuid: panelUUID,
        data: response.data,
      }));
    } else if (getChainEndpoint) {
      dispatch(getPanelAdditional(
        panelType,
        panelUUID,
        getChainEndpoint,
        dataKey,
        chainDataRest,
      ));
    } else if (response.data) {
      dispatch(
        setAdditionalData({
          uuid: panelUUID,
          data: {
            data: response.data,
          },
          additionalDataKey: dataKey,
        }),
      );
    }

    return dispatch({
      type: acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS,
      payload: { uuid: panelUUID, type: panelType, additionalDataKey: dataKey },
    });
  } catch (error) {
    let errorMsg;

    switch (true) {
      case typeof getErrorMsg === 'function' && !!getErrorMsg(error):
        errorMsg = getErrorMsg(error);
        break;

      case error.response && error.response.status === 404:
        errorMsg = i18n.t('errors:sidepanel.not_found');
        break;

      default:
        errorMsg = determineError(error);
        break;
    }

    return dispatch({
      type: acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE,
      payload: {
        uuid: panelUUID,
        type: panelType,
        additionalDataKey: dataKey,
        message: errorMsg,
        reqId: _get(error, 'response.data.reqId'),
      },
    });
  }
};

// Autotag action for panel
export const autotagPanel = (
  panelType,
  panelUUID,
  getEndpoint,
  data,
  getRefreshPanelEndpoint = null,
) => async (dispatch, getState) => {
  dispatch({
    type: acts.AUTOTAG_SIDE_PANEL_LOADING,
    payload: { uuid: panelUUID, type: panelType },
  });

  try {
    const autotagResponse = await axios({
      method: 'post',
      url: getApiUrl(getEndpoint(getState)),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    });

    if (autotagResponse.status === 200 && getRefreshPanelEndpoint) {
      const refreshResponse = await axios({
        method: 'get',
        url: getApiUrl(getRefreshPanelEndpoint(getState)),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch(setData({
        uuid: panelUUID,
        data: refreshResponse.data,
      }));
    }

    return dispatch({
      type: acts.AUTOTAG_SIDE_PANEL_SUCCESS,
      payload: {
        uuid: panelUUID,
        type: panelType,
        deferred: autotagResponse.status === 202,
        message: autotagResponse.status === 202
          ? i18n.t('components:autotag.job_launched')
          : i18n.t('components:autotag.autotagging_success'),
      },
    });
  } catch (error) {
    let errorMsg;

    switch (true) {
      case error.response && error.response.status === 404:
        errorMsg = i18n.t('errors:autotag.not_found');
        break;

      case error.response
        && error.response.status === 406
        && error.response.data.message === 'ingestion_ongoing':
        errorMsg = i18n.t('errors:autotag.ingestion_ongoing');
        break;

      case error.response
        && error.response.status === 406
        && error.response.data.message === 'tracks_export_ongoing':
        errorMsg = i18n.t('errors:autotag.tracks_export_ongoing');
        break;

      case error.response
        && error.response.status === 406
        && error.response.data.message === 'tracks_autotagging_ongoing':
        errorMsg = i18n.t('errors:autotag.tracks_autotagging_ongoing');
        break;

      default:
        errorMsg = determineError(error);
        break;
    }

    return dispatch({
      type: acts.AUTOTAG_SIDE_PANEL_FAILURE,
      payload: {
        uuid: panelUUID,
        type: panelType,
        message: errorMsg,
        reqId: _get(error, 'response.data.reqId'),
      },
    });
  }
};

// Duplicate action for panel
export const duplicatePanel = (panelType, panelUUID, getEndpoint) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: acts.DUPLICATE_SIDE_PANEL_DATA_LOADING,
    payload: { uuid: panelUUID, type: panelType },
  });

  return axios({
    method: 'post',
    url: getApiUrl(getEndpoint(getState)),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.token,
      'x-preferred-language': getXPreferredLanguage(),
    },
    data: { suffix: i18n.t('common:entities.copy_suffix') },
  })
    .then(response => dispatch({
      type: acts.DUPLICATE_SIDE_PANEL_DATA_SUCCESS,
      payload: {
        duplicatedId: response.data.id,
        uuid: panelUUID,
        type: panelType,
        refreshInterval: 700,
      },
    }))
    .catch(err => dispatch({
      type: acts.DUPLICATE_SIDE_PANEL_DATA_FAILURE,
      payload: {
        uuid: panelUUID,
        message: determineError(err),
        reqId: _get(err, 'response.data.reqId'),
        type: panelType,
      },
    }));
};

// Upload file action for panel
export const uploadPanelFile = (
  panelType,
  panelUUID,
  getEndpoint,
  file,
  fileType = 'image',
  requestName,
  getErrorMsg,
) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: acts.UPLOAD_SIDE_PANEL_FILE_LOADING,
    payload: { uuid: panelUUID, type: panelType, fileType },
  });

  const form = new FormData();
  form.append('file', file);

  return cancelableRequest(
    // The cancelable request must be unique so that
    // each document can be modified concurrently from the others
    // We use the panel UUID here because ids can be used on the grid
    `${requestName}_${panelUUID}`,
    {
      method: 'post',
      url: getApiUrl(getEndpoint(getState)),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: form,
      // Track the progress of upload
      onUploadProgress: (event) => {
        const progress = event.total ? event.loaded / event.total : 0;

        // Update progress state
        dispatch({
          type: acts.UPLOAD_SIDE_PANEL_FILE_PROGRESS,
          payload: {
            uuid: panelUUID,
            type: panelType,
            fileType,
            progress: parseInt(progress * 100, 10),
          },
        });
      },
    },
  )
    .then((response) => {
      dispatch(
        setData({
          uuid: panelUUID,
          data: response.data,
        }),
      );

      return dispatch({
        type: acts.UPLOAD_SIDE_PANEL_FILE_SUCCESS,
        payload: { uuid: panelUUID, type: panelType, fileType },
      });
    })
    .catch((error) => {
      if (isCancel(error)) return null;

      let errorMsg;

      switch (true) {
        case typeof getErrorMsg === 'function' && !!getErrorMsg(error):
          errorMsg = getErrorMsg(error);
          break;

        case error.response && error.response.status === 403:
          errorMsg = i18n.t(`errors:meta.${spts.entityMapping[panelType]}s.file_converting`);
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      return dispatch({
        type: acts.UPLOAD_SIDE_PANEL_FILE_FAILURE,
        payload: {
          uuid: panelUUID,
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
          type: panelType,
          fileType,
        },
      });
    });
};

export const cancelUploadPanelFile = (panelType, panelUUID, fileType, requestName) => (
  dispatch,
) => {
  cancelRequest(`${requestName}_${panelUUID}`);

  return dispatch({
    type: acts.CANCEL_UPLOAD_SIDE_PANEL_FILE,
    payload: {
      uuid: panelUUID,
      type: panelType,
      fileType,
    },
  });
};

// Delete file action for panel
export const deletePanelFile = (panelType, panelUUID, getEndpoint, fileType, fileId) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: acts.DELETE_SIDE_PANEL_FILE_LOADING,
    payload: { uuid: panelUUID, type: panelType, fileType, fileId },
  });

  return axios({
    method: 'delete',
    url: getApiUrl(getEndpoint(getState)),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.token,
      'x-preferred-language': getXPreferredLanguage(),
    },
  })
    .then((response) => {
      dispatch(
        setData({
          uuid: panelUUID,
          data: response.data,
        }),
      );

      return dispatch({
        type: acts.DELETE_SIDE_PANEL_FILE_SUCCESS,
        payload: { uuid: panelUUID, type: panelType, fileType, fileId },
      });
    })
    .catch((error) => {
      let errorMsg;
      switch (true) {
        case error.response && error.response.status === 403:
          errorMsg = i18n.t(`errors:meta.${spts.entityMapping[panelType]}s.file_converting`);
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      return dispatch({
        type: acts.DELETE_SIDE_PANEL_FILE_FAILURE,
        payload: {
          uuid: panelUUID,
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
          type: panelType,
          fileType,
          fileId,
        },
      });
    });
};

// Delete action for panel
export const deletePanel = (panelType, panelUUID, getEndpoint, endpointData = {}) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: acts.DELETE_SIDE_PANEL_LOADING,
    payload: { uuid: panelUUID, type: panelType },
  });

  return axios({
    method: 'delete',
    url: getApiUrl(getEndpoint(getState)),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.token,
      'x-preferred-language': getXPreferredLanguage(),
    },
    data: endpointData,
  })
    .then(() => dispatch({
      type: acts.DELETE_SIDE_PANEL_SUCCESS,
      payload: { uuid: panelUUID, type: panelType, refreshInterval: 700 },
    }),
    )
    .catch((err) => {
      let errorMsg;

      switch (true) {
        default:
          errorMsg = determineError(err);
          break;
      }

      return dispatch({
        type: acts.DELETE_SIDE_PANEL_FAILURE,
        payload: {
          uuid: panelUUID,
          message: errorMsg,
          reqId: _get(err, 'response.data.reqId'),
          type: panelType,
        },
      });
    });
};

// =============================
// Meta Entities
// =============================

export const getMetaEntityEndpoint = (entity, docId = '') => {
  let endpoint = `meta/${entity}s`;
  if (docId) endpoint += `/${docId}`;

  return endpoint;
};

// =============================
// Modo Users
// =============================

export const getModoUserEndpoint = (getState, userId) => {
  const configId = getState().modo.config.id;
  return `modo/${configId}/users/${userId}`;
};

// =============================
// Exports
// =============================

export const panelActions = {
  // =============================
  // Meta
  // =============================
  // People
  [spts.META_PEOPLE_PANEL]: {
    get: (panelUUID, peopleId) => getPanel(
      spts.META_PEOPLE_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('people', peopleId),
    ),
    modify: (panelUUID, peopleId, nextData) => modifyPanel(
      spts.META_PEOPLE_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('people', peopleId),
      nextData,
    ),
    duplicate: (panelUUID, peopleId) => duplicatePanel(
      spts.META_PEOPLE_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('people', peopleId)}/duplicate`,
    ),
    delete: (panelUUID, peopleId) => deletePanel(
      spts.META_PEOPLE_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('people'),
      { ids: [peopleId] },
    ),
  },
  // Publisher
  [spts.META_PUBLISHER_PANEL]: {
    get: (panelUUID, publisherId) => getPanel(
      spts.META_PUBLISHER_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('publisher', publisherId),
    ),
    getAdditional: (panelUUID, publisherId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_PUBLISHER_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('publisher', publisherId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    modify: (panelUUID, publisherId, nextData) => modifyPanel(
      spts.META_PUBLISHER_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('publisher', publisherId),
      nextData,
    ),
    duplicate: (panelUUID, publisherId) => duplicatePanel(
      spts.META_PUBLISHER_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('publisher', publisherId)}/duplicate`,
    ),
    uploadFile: (panelUUID, publisherId, file) => uploadPanelFile(
      spts.META_PUBLISHER_PANEL,
      panelUUID,
      () => `meta/uploads/publisher/${publisherId}/image`,
      file,
      'image',
      rqs.UPLOAD_META_PUBLISHER_FILE,
    ),
    deleteFile: (panelUUID, publisherId) => deletePanelFile(
      spts.META_PUBLISHER_PANEL,
      panelUUID,
      () => `meta/uploads/publisher/${publisherId}/image`,
    ),
    delete: (panelUUID, publisherId) => deletePanel(
      spts.META_PUBLISHER_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('publisher'),
      { ids: [publisherId] },
    ),
  },
  // Label
  [spts.META_LABEL_PANEL]: {
    get: (panelUUID, labelId) => getPanel(
      spts.META_LABEL_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('label', labelId),
    ),
    getAdditional: (panelUUID, labelId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_LABEL_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('label', labelId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    modify: (panelUUID, labelId, nextData) => modifyPanel(
      spts.META_LABEL_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('label', labelId),
      nextData,
    ),
    duplicate: (panelUUID, labelId) => duplicatePanel(
      spts.META_LABEL_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('label', labelId)}/duplicate`,
    ),
    uploadFile: (panelUUID, labelId, file) => uploadPanelFile(
      spts.META_LABEL_PANEL,
      panelUUID,
      () => `meta/uploads/label/${labelId}/image`,
      file,
      'image',
      rqs.UPLOAD_META_LABEL_FILE,
    ),
    deleteFile: (panelUUID, labelId) => deletePanelFile(
      spts.META_LABEL_PANEL,
      panelUUID,
      () => `meta/uploads/label/${labelId}/image`,
    ),
    delete: (panelUUID, labelId) => deletePanel(
      spts.META_LABEL_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('label'),
      { ids: [labelId] },
    ),
  },
  // Artist
  [spts.META_ARTIST_PANEL]: {
    get: (panelUUID, artistId) => getPanel(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('artist', artistId),
    ),
    getAdditional: (panelUUID, artistId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('artist', artistId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    modify: (panelUUID, artistId, nextData) => modifyPanel(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('artist', artistId),
      nextData,
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.artists.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.artists.marked_for_deletion');
        }

        return null;
      },
    ),
    modifyAdditional: (
      panelUUID,
      artistId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
    ) => modifyPanelAdditional(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('artist', artistId)}/${endpointSuffix}`,
      endpointMethod,
      nextData,
      dataKey,
    ),
    duplicate: (panelUUID, artistId) => duplicatePanel(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('artist', artistId)}/duplicate`,
    ),
    uploadFile: (panelUUID, artistId, file) => uploadPanelFile(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => `meta/uploads/artist/${artistId}/image`,
      file,
      'image',
      rqs.UPLOAD_META_ARTIST_FILE,
    ),
    deleteFile: (panelUUID, artistId) => deletePanelFile(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => `meta/uploads/artist/${artistId}/image`,
    ),
    delete: (panelUUID, artistId) => deletePanel(
      spts.META_ARTIST_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('artist'),
      { ids: [artistId] },
    ),
  },
  // Catalog
  [spts.META_CATALOG_PANEL]: {
    get: (panelUUID, catalogId) => getPanel(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('catalog', catalogId),
    ),
    getAdditional: (panelUUID, catalogId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('catalog', catalogId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    modify: (panelUUID, catalogId, nextData) => modifyPanel(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('catalog', catalogId),
      nextData,
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.catalogs.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.catalogs.marked_for_deletion');
        }

        return null;
      },
    ),
    modifyAdditional: (
      panelUUID,
      catalogId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
      asMainEndpoint,
    ) => modifyPanelAdditional(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('catalog', catalogId)}/${endpointSuffix}`,
      endpointMethod,
      nextData,
      dataKey,
      asMainEndpoint,
    ),
    autotag: (panelUUID, catalogId, data) => autotagPanel(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => `meta/autotagging/catalog/${catalogId}/launch`,
      data,
    ),
    duplicate: (panelUUID, catalogId) => duplicatePanel(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('catalog', catalogId)}/duplicate`,
    ),
    uploadFile: (panelUUID, catalogId, file) => uploadPanelFile(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => `meta/uploads/catalog/${catalogId}/image`,
      file,
      'image',
      rqs.UPLOAD_META_CATALOG_FILE,
    ),
    deleteFile: (panelUUID, catalogId) => deletePanelFile(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => `meta/uploads/catalog/${catalogId}/image`,
    ),
    delete: (panelUUID, catalogId) => deletePanel(
      spts.META_CATALOG_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('catalog'),
      { ids: [catalogId] },
    ),
  },
  // Playlist
  [spts.META_PLAYLIST_PANEL]: {
    get: (panelUUID, playlistId) => getPanel(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('playlist', playlistId),
    ),
    getAdditional: (panelUUID, playlistId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('playlist', playlistId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    requestGridRefresh: panelUUID => requestGridRefreshFromPanel(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
    ),
    modify: (panelUUID, playlistId, nextData) => modifyPanel(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('playlist', playlistId),
      nextData,
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.playlists.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.playlists.marked_for_deletion');
        }

        return null;
      },
    ),
    modifyAdditional: (
      panelUUID,
      playlistId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
    ) => modifyPanelAdditional(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('playlist', playlistId)}/${endpointSuffix}`,
      endpointMethod,
      nextData,
      dataKey,
      false,
      null,
      {},
      (error) => {
        if (
          endpointSuffix === 'tracks/ingest'
          && error.response
          && error.response.status === 406
          && error.response.data.key === 'not_acceptable'
        ) {
          return i18n.t('errors:meta.playlists.no_audiofiles_to_ingest');
        }

        // This can happen when launching ingest tracks audiofiles.
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.playlists.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.key === 'tracks_autotagging_ongoing'
        ) {
          return i18n.t('errors:meta.playlists.tracks_autotagging_ongoing');
        }

        return null;
      },
    ),
    autotag: (panelUUID, playlistId, data) => autotagPanel(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => `meta/autotagging/playlist/${playlistId}/launch`,
      data,
    ),
    duplicate: (panelUUID, playlistId) => duplicatePanel(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('playlist', playlistId)}/duplicate`,
    ),
    uploadFile: (panelUUID, playlistId, file) => uploadPanelFile(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => `meta/uploads/playlist/${playlistId}/image`,
      file,
      'image',
      rqs.UPLOAD_META_PLAYLIST_FILE,
    ),
    deleteFile: (panelUUID, playlistId) => deletePanelFile(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => `meta/uploads/playlist/${playlistId}/image`,
    ),
    delete: (panelUUID, playlistId) => deletePanel(
      spts.META_PLAYLIST_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('playlist'),
      { ids: [playlistId] },
    ),
  },
  // Brief
  [spts.META_BRIEF_PANEL]: {
    get: (panelUUID, briefId) => getPanel(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('brief', briefId),
    ),
    getAdditional: (panelUUID, briefId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('brief', briefId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    requestGridRefresh: panelUUID => requestGridRefreshFromPanel(
      spts.META_BRIEF_PANEL,
      panelUUID,
    ),
    modify: (panelUUID, briefId, nextData) => modifyPanel(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('brief', briefId),
      nextData,
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.briefs.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.briefs.marked_for_deletion');
        }

        return null;
      },
    ),
    modifyAdditional: (
      panelUUID,
      briefId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
    ) => modifyPanelAdditional(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('brief', briefId)}/${endpointSuffix}`,
      endpointMethod,
      nextData,
      dataKey,
      false,
      null,
      {},
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.briefs.relation_in_progress');
        }

        // This can happen when launching ingest tracks audiofiles.
        if (
          endpointSuffix === 'tracks/ingest'
          && error.response
          && error.response.status === 406
          && error.response.data.key === 'not_acceptable'
        ) {
          return i18n.t('errors:meta.briefs.no_audiofiles_to_ingest');
        }

        // This can happen for brief tracks when trying to add tracks
        // to a brief but the user who sent it to you is not your agent.
        if (
          error.response
          && error.response.status === 400
          && error.response.data.message.includes('not_agent')
        ) {
          return i18n.t('errors:meta.briefs.owner_not_agent');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.key === 'tracks_autotagging_ongoing'
        ) {
          return i18n.t('errors:meta.briefs.tracks_autotagging_ongoing');
        }

        return null;
      },
    ),
    autotag: (panelUUID, briefId, data) => autotagPanel(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => `meta/autotagging/brief/${briefId}/launch`,
      data,
    ),
    uploadFile: (panelUUID, briefId, file) => uploadPanelFile(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => `meta/uploads/brief/${briefId}/image`,
      file,
      'image',
      rqs.UPLOAD_META_BRIEF_FILE,
    ),
    deleteFile: (panelUUID, briefId) => deletePanelFile(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => `meta/uploads/brief/${briefId}/image`,
    ),
    delete: (panelUUID, briefId) => deletePanel(
      spts.META_BRIEF_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('brief'),
      { ids: [briefId] },
    ),
  },
  // Pitch
  [spts.META_PITCH_PANEL]: {
    get: (panelUUID, pitchId) => getPanel(
      spts.META_PITCH_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('pitch', pitchId),
    ),
    getAdditional: (panelUUID, briefId, endpointSuffix, dataKey, method) => getPanelAdditional(
      spts.META_PITCH_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('pitch', briefId)}/${endpointSuffix}`;
      },
      dataKey,
      {},
      method,
    ),
    modifyAdditional: (
      panelUUID,
      pitchId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
      chainEndpointSuffix,
    ) => modifyPanelAdditional(
      spts.META_PITCH_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('pitch', pitchId)}/${endpointSuffix}`,
      endpointMethod,
      nextData,
      dataKey,
      false,
      chainEndpointSuffix
        ? () => `${getMetaEntityEndpoint('pitch', pitchId)}/${chainEndpointSuffix}`
        : null,
      {},
    ),
    requestGridRefresh: panelUUID => requestGridRefreshFromPanel(
      spts.META_PITCH_PANEL,
      panelUUID,
    ),
    modify: (panelUUID, pitchId, nextData) => modifyPanel(
      spts.META_PITCH_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('pitch', pitchId),
      nextData,
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.pitchs.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.pitchs.marked_for_deletion');
        }

        return null;
      },
    ),
    delete: (panelUUID, pitchId) => deletePanel(
      spts.META_PITCH_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('pitch'),
      { ids: [pitchId] },
    ),
  },
  // Album
  [spts.META_ALBUM_PANEL]: {
    get: (panelUUID, albumId) => getPanel(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('album', albumId),
    ),
    getAdditional: (panelUUID, albumId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('album', albumId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    requestGridRefresh: panelUUID => requestGridRefreshFromPanel(
      spts.META_ALBUM_PANEL,
      panelUUID,
    ),
    modify: (panelUUID, albumId, nextData) => modifyPanel(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('album', albumId),
      nextData,
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.albums.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.albums.marked_for_deletion');
        }

        return null;
      },
    ),
    modifyAdditional: (
      panelUUID,
      albumId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
      asMainEndpoint,
      chainEndpointSuffix,
      chainDataRest,
    ) => modifyPanelAdditional(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('album', albumId)}/${endpointSuffix}`;
      },
      endpointMethod,
      nextData,
      dataKey,
      asMainEndpoint,
      chainEndpointSuffix
        ? () => `${getMetaEntityEndpoint('album', albumId)}/${chainEndpointSuffix}`
        : null,
      chainDataRest,
      (error) => {
        if (
          endpointSuffix === 'tracks/ingest'
          && error.response
          && error.response.status === 406
          && error.response.data.key === 'not_acceptable'
        ) {
          return i18n.t('errors:meta.albums.no_audiofiles_to_ingest');
        }

        // This can happen for album tracks when (un)setting the album value
        // which triggers a relationship. We handle it here.
        // This can also happen when launching ingest tracks audiofiles.
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.albums.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.albums.marked_for_deletion');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.key === 'tracks_autotagging_ongoing'
        ) {
          return i18n.t('errors:meta.albums.tracks_autotagging_ongoing');
        }

        return null;
      },
    ),
    autotag: (panelUUID, albumId, data) => autotagPanel(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => `meta/autotagging/album/${albumId}/launch`,
      data,
    ),
    duplicate: (panelUUID, albumId) => duplicatePanel(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('album', albumId)}/duplicate`,
    ),
    uploadFile: (panelUUID, albumId, file, fileType, name) => uploadPanelFile(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => {
        const base = `meta/uploads/album/${albumId}/${fileType}`;
        if (fileType === 'attachment' && name) return `${base}/${name}`;
        return base;
      },
      file,
      fileType,
      {
        image: rqs.UPLOAD_META_ALBUM_FILE,
        attachment: rqs.UPLOAD_META_ALBUM_ATTACHMENT_FILE,
      }[fileType],
    ),
    deleteFile: (panelUUID, albumId, fileType, fileId) => deletePanelFile(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => {
        const base = `meta/uploads/album/${albumId}/${fileType}`;
        if (fileType === 'attachment' && fileId) return `${base}/${fileId}`;
        return base;
      },
      fileType,
      fileId,
    ),
    delete: (panelUUID, albumId) => deletePanel(
      spts.META_ALBUM_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('album'),
      { ids: [albumId] },
    ),
  },
  // Track
  [spts.META_TRACK_PANEL]: {
    get: (panelUUID, trackId) => getPanel(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('track', trackId),
    ),
    getAdditional: (panelUUID, trackId, endpointSuffix, dataKey) => getPanelAdditional(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('track', trackId)}/${endpointSuffix}`;
      },
      dataKey,
    ),
    modify: (panelUUID, trackId, nextData) => modifyPanel(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('track', trackId),
      nextData,
      (error) => {
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.tracks.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.tracks.marked_for_deletion');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.key === 'tracks_autotagging_ongoing'
        ) {
          return i18n.t('errors:meta.tracks.tracks_autotagging_ongoing');
        }

        if (
          error.response
          && error.response.status === 400
          && error.response.data.key === 'validation_error'
          && error.response.data.message.includes('too_many_tracks')
        ) {
          return i18n.t('errors:meta.tracks.too_many_tracks_in_album');
        }

        return null;
      },
    ),
    modifyAdditional: (
      panelUUID,
      trackId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
      asMainEndpoint,
      chainEndpointSuffix,
      chainDataRest,
    ) => modifyPanelAdditional(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => {
        if (endpointSuffix.indexOf('/') === 0) return endpointSuffix.substr(1, endpointSuffix.length);
        return `${getMetaEntityEndpoint('track', trackId)}/${endpointSuffix}`;
      },
      endpointMethod,
      nextData,
      dataKey,
      asMainEndpoint,
      chainEndpointSuffix
        ? () => `${getMetaEntityEndpoint('track', trackId)}/${chainEndpointSuffix}`
        : null,
      chainDataRest,
      (error) => {
        // This can happen for track versions. We handle it here
        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.tracks.relation_in_progress');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion'
        ) {
          return i18n.t('errors:meta.tracks.marked_for_deletion');
        }

        if (
          error.response
          && error.response.status === 400
          && error.response.data.message.includes('original_track_version_is_not_original')
        ) {
          return i18n.t('errors:meta.tracks.original_track_version_is_not_original');
        }

        return null;
      },
    ),
    autotag: (panelUUID, trackId, data) => autotagPanel(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => `meta/autotagging/track/${trackId}/launch`,
      data,
      () => getMetaEntityEndpoint('track', trackId),
    ),
    duplicate: (panelUUID, trackId) => duplicatePanel(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('track', trackId)}/duplicate`,
    ),
    uploadFile: (panelUUID, trackId, file, fileType, name) => uploadPanelFile(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => {
        const base = `meta/uploads/track/${trackId}/${fileType}`;
        if (fileType === 'attachment' && name) return `${base}/${name}`;
        return base;
      },
      file,
      fileType,
      {
        audiofile: rqs.UPLOAD_META_TRACK_FILE,
        stems: rqs.UPLOAD_META_TRACK_STEMS_FILE,
        attachment: rqs.UPLOAD_META_TRACK_ATTACHMENT_FILE,
      }[fileType],
    ),
    deleteFile: (panelUUID, trackId, fileType, fileId) => deletePanelFile(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => {
        const base = `meta/uploads/track/${trackId}/${fileType}`;
        if (fileType === 'attachment' && fileId) return `${base}/${fileId}`;
        return base;
      },
      fileType,
      fileId,
    ),
    delete: (panelUUID, trackId) => deletePanel(
      spts.META_TRACK_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('track'),
      { ids: [trackId] },
    ),
  },
  // Ingestion
  [spts.META_INGESTION_PANEL]: {
    get: (panelUUID, ingestionId) => getPanel(
      spts.META_INGESTION_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('ingestion', ingestionId),
    ),
    modify: (panelUUID, ingestionId, nextData) => modifyPanel(
      spts.META_INGESTION_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('ingestion', ingestionId),
      nextData,
    ),
    modifyAdditional: (
      panelUUID,
      ingestionId,
      endpointSuffix,
      endpointMethod,
      nextData,
      dataKey,
    ) => modifyPanelAdditional(
      spts.META_INGESTION_PANEL,
      panelUUID,
      () => `${getMetaEntityEndpoint('ingestion', ingestionId)}/${endpointSuffix}`,
      endpointMethod,
      nextData,
      dataKey,
      true,
      null,
      {},
      (error) => {
        if (
          endpointSuffix === 'launch'
          && error.response
          && error.response.status === 403
        ) {
          return i18n.t('errors:meta.ingestions.invalid_ingestion_launch');
        }

        if (
          endpointSuffix === 'launch'
          && error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress'
        ) {
          return i18n.t('errors:meta.ingestions.relation_in_progress');
        }

        if (
          endpointSuffix === 'launch'
          && error.response
          && error.response.status === 406
          && error.response.data.key === 'not_acceptable'
        ) {
          return i18n.t('errors:meta.ingestions.no_audiofiles_to_ingest');
        }

        return null;
      },
    ),
    uploadFile: (panelUUID, ingestionId, file) => uploadPanelFile(
      spts.META_INGESTION_PANEL,
      panelUUID,
      () => `meta/uploads/ingestion/${ingestionId}/csv`,
      file,
      'csv',
      rqs.UPLOAD_META_INGESTION_FILE,
      (error) => {
        if (
          error.response
          && error.response.status === 500
          && error.response.data.key === 'reading_row_error'
        ) {
          return i18n.t('errors:meta.ingestions.reading_row_error');
        }

        if (
          error.response
          && error.response.status === 404
          && error.response.data.key === 'not_found'
        ) {
          return i18n.t('errors:meta.ingestions.not_found');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.key === 'invalid_file_type'
        ) {
          return i18n.t('errors:meta.ingestions.invalid_file_type');
        }

        if (
          error.response
          && error.response.status === 406
          && error.response.data.key === 'mandatory_headers_missing'
        ) {
          return i18n.t('errors:meta.ingestions.mandatory_headers_missing');
        }

        if (
          error.response
          && error.response.status === 400
          && error.response.data.key === 'file_already_uploaded'
        ) {
          return i18n.t('errors:meta.ingestions.already_uploaded');
        }

        return null;
      },
    ),
    cancelUploadFile: panelUUID => cancelUploadPanelFile(
      spts.META_INGESTION_PANEL,
      panelUUID,
      'csv',
      rqs.UPLOAD_META_INGESTION_FILE,
    ),
    delete: (panelUUID, ingestionId) => deletePanel(
      spts.META_INGESTION_PANEL,
      panelUUID,
      () => getMetaEntityEndpoint('ingestion'),
      { ids: [ingestionId] },
    ),
  },
  // =============================
  // Modo Users
  // =============================
  [spts.MODO_USER_PANEL]: {
    get: (panelUUID, userId) => getPanel(
      spts.MODO_USER_PANEL,
      panelUUID,
      getState => getModoUserEndpoint(getState, userId),
    ),
    getAdditional: (
      panelUUID,
      userId,
      endpointSuffix,
      dataKey,
      timeframe = null,
    ) => getPanelAdditional(
      spts.MODO_USER_PANEL,
      panelUUID,
      (getState) => {
        let apiUrl = `${getModoUserEndpoint(getState, userId)}/${endpointSuffix}`;
        if (timeframe) apiUrl += `?timeframe=${timeframe}`;
        return apiUrl;
      },
      dataKey,
      { timeframe },
    ),
    modify: (panelUUID, userId, nextData) => modifyPanel(
      spts.MODO_USER_PANEL,
      panelUUID,
      getState => getModoUserEndpoint(getState, userId),
      nextData,
    ),
    delete: (panelUUID, userId) => deletePanel(
      spts.MODO_USER_PANEL,
      panelUUID,
      getState => getModoUserEndpoint(getState, userId),
    ),
  },
};
