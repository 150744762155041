// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  configs: {
    isFetching: false,
    isFetchedOnce: false,
    isModifying: false,
    data: [],
  },
  config: {
    isFetching: false,
    isModifyingPlan: false,
    isModifying: false,
    urlIsModifying: false,
    activeIsModifying: false,
    customisationsIsModifying: false,
    otherCustomisationsIsModifying: false,
    integrationsIsModifying: false,
    usersIsModifying: false,
    images: {
      logo: {
        value: undefined,
        status: 0,
      },
      logo_variant: {
        value: undefined,
        status: 0,
      },
      logo_modal: {
        value: undefined,
        status: 0,
      },
      logo_email: {
        value: undefined,
        status: 0,
      },
      logo_filter_bar: {
        value: undefined,
        status: 0,
      },
      favicon: {
        value: undefined,
        status: 0,
      },
    },
    id: null,
    data: {},
    urlData: {},
    activeData: {},
    otherCustomisationsData: {},
    customisationsData: {},
    integrationsData: {},
    usersData: {},
    fullData: {},
  },
  configMenus: {
    isFetching: false,
    isModifying: false,
    data: {},
  },
  configMenusOptions: {
    isFetching: false,
    data: [],
  },
  configLegal: {
    isFetching: false,
    isModifying: false,
    data: [],
  },
  configPages: {
    isFetching: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      sort: undefined,
    },
    total: null,
  },
  configPage: {
    isFetching: false,
    isCreating: false,
    isDuplicating: false,
    isModifying: false,
    isDeleting: false,
    id: null,
    data: {},
    images: {
      seo_preview: {
        value: undefined,
        status: 0,
      },
    },
  },
  configPageModules: {
    isFetching: false,
    isCreating: false,
    isModifying: [],
    isUploading: [],
    isDeletingUpload: [],
    items: {
      isUploading: [],
      isDeletingUpload: [],
    },
    isReordering: false,
    isDeleting: false,
    dirtyModules: [],
    data: [],
  },
  configSuggestedPlaylists: {
    isFetching: false,
    isModifying: false,
    data: [],
  },
  configSuggestedAlbums: {
    isFetching: false,
    isModifying: false,
    data: [],
  },
  users: {
    isFetching: false,
    isModifying: false,
    isDeleting: false,
    isInviting: false,
    isExporting: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      search: undefined,
      sort: undefined,
      sort_direction: undefined,
    },
    total: null,
  },
  usersImport: {
    id: null,
    isCreating: false,
    isUploading: false,
    isModifying: false,
    isLaunching: false,
    uploadProgress: 0,
    data: {},
  },
  stats: {
    globalUser: {
      isFetching: false,
      data: {},
    },
    globalPlays: {
      isFetching: false,
      data: {},
      timeframe: null,
    },
    globalDownloads: {
      isFetching: false,
      data: {},
      timeframe: null,
    },
    globalSearch: {
      isFetching: false,
      data: {},
    },
    globalSearchStrings: {
      isFetching: false,
      data: {},
      timeframe: null,
    },
    globalSearchMaia: {
      isFetching: false,
      data: {},
      timeframe: null,
    },
    globalSearchTags: {
      isFetching: false,
      data: {},
      timeframe: null,
    },
  },
};

// =============================
// Reducer
// =============================

export default function modo(state = initialState, action) {
  switch (action.type) {
    /* Configs listing */
    case acts.GET_MODO_CONFIGS_LOADING:
      return {
        ...state,
        configs: {
          ...state.configs,
          isFetching: true,
        },
      };

    case acts.SET_MODO_CONFIGS:
      return {
        ...state,
        configs: {
          ...state.configs,
          data: action.payload,
        },
      };

    case acts.GET_MODO_CONFIGS_SUCCESS:
      return {
        ...state,
        configs: {
          ...state.configs,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_MODO_CONFIGS_FAILURE:
      return {
        ...state,
        configs: {
          ...state.configs,
          isFetching: false,
        },
      };

    // Reset initialization when user logs out
    case acts.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        configs: {
          ...state.configs,
          isFetchedOnce: false,
        },
      };

    case acts.CREATE_MODO_CONFIG_LOADING:
      return {
        ...state,
        configs: {
          ...state.configs,
          isCreating: true,
        },
      };

    case acts.PUSH_MODO_CONFIG:
      return {
        ...state,
        configs: {
          ...state.configs,
          data: [...state.configs.data, action.payload],
        },
      };

    case acts.CREATE_MODO_CONFIG_SUCCESS:
    case acts.CREATE_MODO_CONFIG_FAILURE:
      return {
        ...state,
        configs: {
          ...state.configs,
          isCreating: false,
        },
      };

    /* Config initial */
    case acts.GET_MODO_CONFIG_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          isFetching: true,
        },
      };

    case acts.SET_MODO_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload,
        },
      };

    case acts.SET_MODO_CONFIG_IMAGE:
      return {
        ...state,
        config: {
          ...state.config,
          images: { ...state.config.images, ...action.payload },
        },
      };

    case acts.GET_MODO_CONFIG_SUCCESS:
    case acts.GET_MODO_CONFIG_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          isFetching: false,
        },
      };

    /* Config plan */
    case acts.MODIFY_MODO_CONFIG_PLAN_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          isModifyingPlan: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_PLAN_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_PLAN_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          isModifyingPlan: false,
        },
      };

    /* Config main settings */
    case acts.MODIFY_MODO_CONFIG_MAIN_SETTINGS_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_MAIN_SETTINGS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          isModifying: false,
        },
      };

    /* Config customisations settings */
    case acts.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          customisationsIsModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          customisationsIsModifying: false,
        },
      };

    /* Config other customisations settings */
    case acts.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          otherCustomisationsIsModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          otherCustomisationsIsModifying: false,
        },
      };

    /* Config integrations settings */
    case acts.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          integrationsIsModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          integrationsIsModifying: false,
        },
      };

    /* Config users settings */
    case acts.MODIFY_MODO_CONFIG_USERS_SETTINGS_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          usersIsModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_USERS_SETTINGS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          usersIsModifying: false,
        },
      };

    /* Config url settings */
    case acts.MODIFY_MODO_CONFIG_URL_SETTINGS_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          urlIsModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_URL_SETTINGS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          urlIsModifying: false,
        },
      };

    /* Config active settings */
    case acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_LOADING:
      return {
        ...state,
        config: {
          ...state.config,
          activeIsModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          activeIsModifying: false,
        },
      };

    /* Config Menus */
    case acts.SET_MODO_CONFIG_MENUS:
      return {
        ...state,
        configMenus: {
          ...state.configMenus,
          data: action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_MENUS_LOADING:
      return {
        ...state,
        configMenus: {
          ...state.configMenus,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_MENUS_SUCCESS:
    case acts.GET_MODO_CONFIG_MENUS_FAILURE:
      return {
        ...state,
        configMenus: {
          ...state.configMenus,
          isFetching: false,
        },
      };

    case acts.MODIFY_MODO_CONFIG_MENUS_LOADING:
      return {
        ...state,
        configMenus: {
          ...state.configMenus,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_MENUS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_MENUS_FAILURE:
      return {
        ...state,
        configMenus: {
          ...state.configMenus,
          isModifying: false,
        },
      };

    /* Config Menus Options */
    case acts.SET_MODO_CONFIG_MENUS_OPTIONS:
      return {
        ...state,
        configMenusOptions: {
          ...state.configMenusOptions,
          data: action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_MENUS_OPTIONS_LOADING:
      return {
        ...state,
        configMenusOptions: {
          ...state.configMenusOptions,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_MENUS_OPTIONS_SUCCESS:
    case acts.GET_MODO_CONFIG_MENUS_OPTIONS_FAILURE:
      return {
        ...state,
        configMenusOptions: {
          ...state.configMenusOptions,
          isFetching: false,
        },
      };

    /* Config Legal */
    case acts.SET_MODO_CONFIG_LEGAL:
      return {
        ...state,
        configLegal: {
          ...state.configLegal,
          data: action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_LEGAL_LOADING:
      return {
        ...state,
        configLegal: {
          ...state.configLegal,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_LEGAL_SUCCESS:
    case acts.GET_MODO_CONFIG_LEGAL_FAILURE:
      return {
        ...state,
        configLegal: {
          ...state.configLegal,
          isFetching: false,
        },
      };

    case acts.MODIFY_MODO_CONFIG_LEGAL_LOADING:
      return {
        ...state,
        configLegal: {
          ...state.configLegal,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_LEGAL_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_LEGAL_FAILURE:
      return {
        ...state,
        configLegal: {
          ...state.configLegal,
          isModifying: false,
        },
      };

    /* Config Pages */
    case acts.SET_MODO_CONFIG_PAGES:
      return {
        ...state,
        configPages: {
          ...state.configPages,
          ...action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_PAGES_LOADING:
      return {
        ...state,
        configPages: {
          ...state.configPages,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_PAGES_SUCCESS:
    case acts.GET_MODO_CONFIG_PAGES_FAILURE:
      return {
        ...state,
        configPages: {
          ...state.configPages,
          isFetching: false,
        },
      };

    /* Config Page */
    case acts.SET_MODO_CONFIG_PAGE:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          ...action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_PAGE_LOADING:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_PAGE_SUCCESS:
    case acts.GET_MODO_CONFIG_PAGE_FAILURE:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isFetching: false,
        },
      };

    case acts.CREATE_MODO_CONFIG_PAGE_LOADING:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isCreating: true,
        },
      };

    case acts.CREATE_MODO_CONFIG_PAGE_SUCCESS:
    case acts.CREATE_MODO_CONFIG_PAGE_FAILURE:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isCreating: false,
        },
      };

    case acts.DUPLICATE_MODO_CONFIG_PAGE_LOADING:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isDuplicating: true,
        },
      };

    case acts.DUPLICATE_MODO_CONFIG_PAGE_SUCCESS:
    case acts.DUPLICATE_MODO_CONFIG_PAGE_FAILURE:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isDuplicating: false,
        },
      };

    case acts.MODIFY_MODO_CONFIG_PAGE_LOADING:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_PAGE_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_PAGE_FAILURE:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isModifying: false,
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_LOADING:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isDeleting: true,
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_SUCCESS:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isDeleting: false,
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_FAILURE:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          isDeleting: false,
        },
      };

    case acts.SET_MODO_CONFIG_PAGE_IMAGE:
      return {
        ...state,
        configPage: {
          ...state.configPage,
          images: { ...state.configPage.images, ...action.payload },
        },
      };

    /* Config Page Modules */
    case acts.SET_MODO_CONFIG_PAGE_MODULES:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          data: action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_PAGE_MODULES_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_PAGE_MODULES_SUCCESS:
    case acts.GET_MODO_CONFIG_PAGE_MODULES_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isFetching: false,
        },
      };

    case acts.ADD_MODO_CONFIG_PAGE_MODULE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          data: [
            ...state.configPageModules.data,
            action.payload,
          ],
        },
      };

    case acts.CREATE_MODO_CONFIG_PAGE_MODULE_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isCreating: true,
        },
      };

    case acts.CREATE_MODO_CONFIG_PAGE_MODULE_SUCCESS:
    case acts.CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isCreating: false,
        },
      };

    case acts.UPDATE_MODO_CONFIG_PAGE_MODULE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          data: state.configPageModules.data.map((m) => {
            if (m.id !== action.payload.id) return m;
            return { ...action.payload.data };
          }),
        },
      };

    case acts.SET_DIRTY_MODO_CONFIG_PAGE_MODULES:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          dirtyModules: action.payload,
        },
      };

    case acts.MODIFY_MODO_CONFIG_PAGE_MODULE_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isModifying: [...state.configPageModules.isModifying, action.payload.id],
        },
      };

    case acts.MODIFY_MODO_CONFIG_PAGE_MODULE_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isModifying: [
            ...state.configPageModules.isModifying.filter(m => m !== action.payload.id),
          ],
        },
      };

    case acts.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isReordering: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isReordering: false,
        },
      };

    case acts.REMOVE_MODO_CONFIG_PAGE_MODULE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          data: [...state.configPageModules.data.filter(m => m.id !== action.payload)],
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isDeleting: true,
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS:
    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isDeleting: false,
        },
      };

    case acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isUploading: [
            ...state.configPageModules.isUploading,
            { id: action.payload.id, name: action.payload.name, isMobile: action.payload.isMobile },
          ],
        },
      };

    case acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS:
    case acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isUploading: [
            ...state.configPageModules.isUploading
              .filter(m => !(m.id === action.payload.id && m.isMobile === action.payload.isMobile)),
          ],
        },
      };

    case acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          items: {
            ...state.configPageModules.items,
            isUploading: [
              ...state.configPageModules.items.isUploading,
              { id: action.payload.id, name: action.payload.name, itemId: action.payload.itemId },
            ],
          },
        },
      };

    case acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS:
    case acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          items: {
            ...state.configPageModules.items,
            isUploading: [
              ...state.configPageModules.items.isUploading
                .filter(m => !(m.id === action.payload.id && m.itemId === action.payload.itemId)),
            ],
          },
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isDeletingUpload: [
            ...state.configPageModules.isDeletingUpload,
            { id: action.payload.id, isMobile: action.payload.isMobile },
          ],
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS:
    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          isDeletingUpload: [
            ...state.configPageModules.isDeletingUpload
              .filter(m => !(m.id === action.payload.id && m.isMobile === action.payload.isMobile)),
          ],
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          items: {
            ...state.configPageModules.items,
            isDeletingUpload: [
              ...state.configPageModules.items.isDeletingUpload,
              { id: action.payload.id, itemId: action.payload.itemId },
            ],
          },
        },
      };

    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS:
    case acts.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE:
      return {
        ...state,
        configPageModules: {
          ...state.configPageModules,
          items: {
            ...state.configPageModules.items,
            isDeletingUpload: [
              ...state.configPageModules.items.isDeletingUpload
                .filter(m => !(m.id === action.payload.id && m.itemId === action.payload.itemId)),
            ],
          },
        },
      };

    /* Config Suggested Playlists */
    case acts.SET_MODO_CONFIG_SUGGESTED_PLAYLISTS:
      return {
        ...state,
        configSuggestedPlaylists: {
          ...state.configSuggestedPlaylists,
          data: action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING:
      return {
        ...state,
        configSuggestedPlaylists: {
          ...state.configSuggestedPlaylists,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS:
    case acts.GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE:
      return {
        ...state,
        configSuggestedPlaylists: {
          ...state.configSuggestedPlaylists,
          isFetching: false,
        },
      };

    case acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING:
      return {
        ...state,
        configSuggestedPlaylists: {
          ...state.configSuggestedPlaylists,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE:
      return {
        ...state,
        configSuggestedPlaylists: {
          ...state.configSuggestedPlaylists,
          isModifying: false,
        },
      };

    /* Config Suggested Albums */
    case acts.SET_MODO_CONFIG_SUGGESTED_ALBUMS:
      return {
        ...state,
        configSuggestedAlbums: {
          ...state.configSuggestedAlbums,
          data: action.payload,
        },
      };

    case acts.GET_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING:
      return {
        ...state,
        configSuggestedAlbums: {
          ...state.configSuggestedAlbums,
          isFetching: true,
        },
      };

    case acts.GET_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS:
    case acts.GET_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE:
      return {
        ...state,
        configSuggestedAlbums: {
          ...state.configSuggestedAlbums,
          isFetching: false,
        },
      };

    case acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING:
      return {
        ...state,
        configSuggestedAlbums: {
          ...state.configSuggestedAlbums,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS:
    case acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE:
      return {
        ...state,
        configSuggestedAlbums: {
          ...state.configSuggestedAlbums,
          isModifying: false,
        },
      };

    /* Modo Users */
    case acts.SET_MODO_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
        },
      };

    case acts.GET_MODO_USERS_LOADING:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: true,
        },
      };

    case acts.GET_MODO_USERS_SUCCESS:
    case acts.GET_MODO_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: false,
        },
      };

    case acts.DELETE_MODO_USER_LOADING:
      return {
        ...state,
        users: {
          ...state.users,
          isDeleting: true,
        },
      };

    case acts.DELETE_MODO_USER_SUCCESS:
    case acts.DELETE_MODO_USER_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isDeleting: false,
        },
      };

    case acts.MODIFY_MODO_USER_LOADING:
      return {
        ...state,
        users: {
          ...state.users,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_USER_SUCCESS:
    case acts.MODIFY_MODO_USER_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isModifying: false,
        },
      };

    case acts.INVITE_MODO_USER_LOADING:
      return {
        ...state,
        users: {
          ...state.users,
          isInviting: true,
        },
      };

    case acts.INVITE_MODO_USER_SUCCESS:
    case acts.INVITE_MODO_USER_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isInviting: false,
        },
      };

    /* Modo Users Export */
    case acts.EXPORT_MODO_USERS_LOADING:
      return {
        ...state,
        users: {
          ...state.users,
          isExporting: true,
        },
      };

    case acts.EXPORT_MODO_USERS_SUCCESS:
    case acts.EXPORT_MODO_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isExporting: false,
        },
      };

    /* Modo Users Import */
    case acts.SET_MODO_USER_IMPORT_ID:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          id: action.payload,
        },
      };

    case acts.CREATE_MODO_USER_IMPORT_LOADING:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isCreating: true,
        },
      };

    case acts.CREATE_MODO_USER_IMPORT_SUCCESS:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isCreating: false,
        },
      };

    case acts.UPLOAD_MODO_USER_IMPORT_LOADING:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isUploading: true,
        },
      };

    case acts.UPLOAD_MODO_USER_IMPORT_PROGRESS:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          uploadProgress: action.payload,
        },
      };

    case acts.SET_MODO_USER_IMPORT_DATA:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          data: action.payload,
        },
      };

    case acts.UPLOAD_MODO_USER_IMPORT_SUCCESS:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isUploading: false,
          isUploaded: true,
        },
      };

    case acts.MODIFY_MODO_USER_IMPORT_LOADING:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isModifying: true,
        },
      };

    case acts.MODIFY_MODO_USER_IMPORT_SUCCESS:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isModifying: false,
        },
      };

    case acts.LAUNCH_MODO_USER_IMPORT_LOADING:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isLauching: true,
        },
      };

    case acts.LAUNCH_MODO_USER_IMPORT_SUCCESS:
      return {
        ...state,
        usersImport: {
          ...state.usersImport,
          isLauching: false,
        },
      };

    case acts.RESET_MODO_USER_IMPORT:
    case acts.CREATE_MODO_USER_IMPORT_FAILURE:
    case acts.UPLOAD_MODO_USER_IMPORT_FAILURE:
    case acts.MODIFY_MODO_USER_IMPORT_FAILURE:
    case acts.LAUNCH_MODO_USER_IMPORT_FAILURE:
      return {
        ...state,
        usersImport: {
          id: null,
          isCreating: false,
          isUploading: false,
          isModifying: false,
          isLaunching: false,
          uploadProgress: 0,
          isUploaded: false,
          data: {},
        },
      };

    /* Modo Users Stats */
    case acts.GET_MODO_GLOBAL_USER_STATS_LOADING:
    case acts.GET_MODO_GLOBAL_PLAYS_STATS_LOADING:
    case acts.GET_MODO_GLOBAL_DOWNLOADS_STATS_LOADING:
    case acts.GET_MODO_GLOBAL_SEARCH_STATS_LOADING:
    case acts.GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_LOADING:
    case acts.GET_MODO_GLOBAL_SEARCH_MAIA_STATS_LOADING:
    case acts.GET_MODO_GLOBAL_SEARCH_TAGS_STATS_LOADING:
      return {
        ...state,
        stats: {
          ...state.stats,
          [action.payload.key]: {
            ...state.stats[action.payload.key],
            isFetching: true,
          },
        },
      };

    case acts.GET_MODO_GLOBAL_USER_STATS_SUCCESS:
    case acts.GET_MODO_GLOBAL_PLAYS_STATS_SUCCESS:
    case acts.GET_MODO_GLOBAL_DOWNLOADS_STATS_SUCCESS:
    case acts.GET_MODO_GLOBAL_SEARCH_STATS_SUCCESS:
    case acts.GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_SUCCESS:
    case acts.GET_MODO_GLOBAL_SEARCH_MAIA_STATS_SUCCESS:
    case acts.GET_MODO_GLOBAL_SEARCH_TAGS_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          [action.payload.key]: {
            ...state.stats[action.payload.key],
            isFetching: false,
          },
        },
      };

    case acts.GET_MODO_GLOBAL_USER_STATS_FAILURE:
    case acts.GET_MODO_GLOBAL_PLAYS_STATS_FAILURE:
    case acts.GET_MODO_GLOBAL_DOWNLOADS_STATS_FAILURE:
    case acts.GET_MODO_GLOBAL_SEARCH_STATS_FAILURE:
    case acts.GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_FAILURE:
    case acts.GET_MODO_GLOBAL_SEARCH_MAIA_STATS_FAILURE:
    case acts.GET_MODO_GLOBAL_SEARCH_TAGS_STATS_FAILURE:
      return {
        ...state,
        stats: {
          ...state.stats,
          [action.payload.key]: {
            ...state.stats[action.payload.key],
            isFetching: false,
          },
        },
      };

    case acts.SET_MODO_GLOBAL_USER_STATS:
    case acts.SET_MODO_GLOBAL_PLAYS_STATS:
    case acts.SET_MODO_GLOBAL_DOWNLOADS_STATS:
    case acts.SET_MODO_GLOBAL_SEARCH_STATS:
    case acts.SET_MODO_GLOBAL_SEARCH_STRINGS_STATS:
    case acts.SET_MODO_GLOBAL_SEARCH_MAIA_STATS:
    case acts.SET_MODO_GLOBAL_SEARCH_TAGS_STATS: {
      if (action.payload.timeframe) {
        return {
          ...state,
          stats: {
            ...state.stats,
            [action.payload.key]: {
              ...state.stats[action.payload.key],
              data: action.payload.data,
              timeframe: action.payload.timeframe,
            },
          },
        };
      }

      return {
        ...state,
        stats: {
          ...state.stats,
          [action.payload.key]: {
            ...state.stats[action.payload.key],
            data: action.payload.data,
          },
        },
      };
    }

    default:
      return state;
  }
}
