// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

// Components
import { withTranslation } from '../../../../../config/i18n';
import UserPlayStats from './playStats';
import UserDownloadStats from './downloadStats';
import UserLastPlayedStats from './lastPlayedStats';
import UserLastDownloadedStats from './lastDownloadedStats';
import UserSearchStats from './searchStats';
import UserSearchStringsStats from './searchStringsStats';
import UserSearchTagsStats from './searchTagsStats';
import UserSearchMaiaStats from './searchMaiaStats';

// Proptypes
import * as pth from '../proptypes';
import * as globalPth from '../../../../../helpers/proptypes';

// =============================
// Component
// =============================

class ModoUserStats extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      'isLoading-stats-downloads': PropTypes.bool,
      'isLoading-stats-plays': PropTypes.bool,
      'isLoading-stats-search': PropTypes.bool,
      'isLoading-stats-search-maia': PropTypes.bool,
      'isLoading-stats-search-strings': PropTypes.bool,
      'isLoading-stats-search-tags': PropTypes.bool,
    }).isRequired,
    data: PropTypes.shape({
      'stats-downloads': pth.statsDownload,
      'stats-plays': pth.statsPlays,
      'stats-search': pth.statsSearch,
      'stats-search-maia': pth.statsSearchMaia,
      'stats-search-strings': pth.statsSearchStrings,
      'stats-search-tags': pth.statsSearchTags,
    }),
    getAdditional: PropTypes.func,
    tags: PropTypes.arrayOf(globalPth.tagCategory).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: null,
    getAdditional: null,
  };

  render() {
    const { panel, data, getAdditional, tags, i18n: { language }, t } = this.props;

    return (
      <Fragment>
        <UserPlayStats
          playsData={_get(data, 'stats-plays.data', {})}
          isFetching={_get(panel, 'isLoading-stats-plays', false)}
          currentTimeframe={_get(data, 'stats-plays.timeframe', 'all')}
          getStats={timeframe => getAdditional('stats/plays', 'stats-plays', timeframe)}
          locale={language}
          t={t}
        />
        <UserDownloadStats
          downloadsData={_get(data, 'stats-downloads.data', {})}
          isFetching={_get(panel, 'isLoading-stats-downloads', false)}
          currentTimeframe={_get(data, 'stats-downloads.timeframe', 'all')}
          getStats={timeframe => getAdditional('stats/downloads', 'stats-downloads', timeframe)}
          locale={language}
          t={t}
        />
        <UserLastPlayedStats
          playsData={_get(data, 'stats-last-played.data', [])}
          locale={language}
          t={t}
        />
        <UserLastDownloadedStats
          downloadsData={_get(data, 'stats-last-downloaded.data', [])}
          locale={language}
          t={t}
        />
        <UserSearchStats searchData={_get(data, 'stats-search.data', {})} t={t} />
        <UserSearchStringsStats
          searchStringsData={_get(data, 'stats-search-strings.data', {})}
          isFetching={_get(panel, 'isLoading-stats-search-strings', false)}
          currentTimeframe={_get(data, 'stats-search-strings.timeframe', 'all')}
          getStats={timeframe => getAdditional('stats/search/strings', 'stats-search-strings', timeframe)}
          t={t}
        />
        <UserSearchTagsStats
          searchTagsData={_get(data, 'stats-search-tags.data', {})}
          isFetching={_get(panel, 'isLoading-stats-search-tags', false)}
          currentTimeframe={_get(data, 'stats-search-tags.timeframe', 'all')}
          getStats={timeframe => getAdditional('stats/search/tags', 'stats-search-tags', timeframe)}
          tags={tags}
          locale={language}
          t={t}
        />
        <UserSearchMaiaStats
          searchMaiaData={_get(data, 'stats-search-maia.data', {})}
          isFetching={_get(panel, 'isLoading-stats-search-maia', false)}
          currentTimeframe={_get(data, 'stats-search-maia.timeframe', 'all')}
          getStats={timeframe => getAdditional('stats/search/maia', 'stats-search-maia', timeframe)}
          locale={language}
          t={t}
        />
      </Fragment>
    );
  }
}

function mapStateToProps({ options }) {
  return {
    tags: options.data.tags,
  };
}

export default compose(
  withTranslation(['common', 'components', 'pages', 'errors']),
  connect(mapStateToProps),
)(ModoUserStats);
