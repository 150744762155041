// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const footerHeight = '5rem';

export const Wrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border-top: 0.1rem solid #e5e5e5;
  display: flex;
  margin-top: auto;
  margin-left: 2rem;
  width: calc(100% - 4rem);
  justify-content: space-between;

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-left: 3rem;
    width: calc(100% - 6rem);
  `};
`;

export const Content = styled.div`
  align-items: center;
  color: #b2b2b2;
  display: flex;
  font-size: 1.2rem;
  height: ${footerHeight};
`;
