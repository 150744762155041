// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const PgnButton = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 3rem;
  justify-content: center;
  margin: 0 0.5rem;
  width: 3rem;

  &:hover {
    ${({ isAccessible, theme }) => isAccessible
      && css`
        color: ${theme.colors.primary};
        fill: ${theme.colors.primary};
      `};
  }

  ${({ active, theme }) => active
    && css`
      color: ${theme.colors.primary};
      cursor: default;
    `};

  ${({ isAccessible }) => !isAccessible
    && css`
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    `};
`;

PgnButton.propTypes = {
  active: PropTypes.bool,
  isAccessible: PropTypes.bool,
};

PgnButton.defaultProps = {
  active: false,
  isAccessible: true,
};

export const StyledArrowDouble = styled(Icons.ArrowDouble).attrs(() => ({
  fill: 'inherit',
  width: '0.7rem',
}))``;

export const StyledArrowSingle = styled(Icons.ArrowSingle).attrs(() => ({
  fill: 'inherit',
  width: '0.7rem',
}))``;

export const GoToPageButton = styled.button`
  display: none;
`;
