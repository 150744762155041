// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { closeRouterModal } from '../../../store/actions/CoreActions';

// Components
import RouterModal from '../../presentationals/routerModal';

// =============================
// Component
// =============================

function mapStateToProps({ core }) {
  const { routerModal: { isOpened, nextHref, nextAsPath } } = core;

  return {
    isOpened,
    nextHref,
    nextAsPath,
  };
}

export default connect(mapStateToProps, {
  closeRouterModal,
})(RouterModal);
