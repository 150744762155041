// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';
import _pick from 'lodash/pick';

// =============================
// Internal
// =============================

export const sidePanel = PropTypes.shape({
  uuid: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  additionalData: PropTypes.any,
  getErrorMsg: PropTypes.string,
  getErrorReqId: PropTypes.string,
  isModifying: PropTypes.bool,
  isAutotagging: PropTypes.bool,
  isDuplicating: PropTypes.bool,
  isUploading: PropTypes.bool,
  isUploadingStems: PropTypes.bool,
  isUploadingAttachment: PropTypes.bool,
  uploadProgress: PropTypes.number,
  uploadStemsProgress: PropTypes.number,
  uploadAttachmentProgress: PropTypes.number,
  isDeletingUpload: PropTypes.bool,
  isDeletingStems: PropTypes.bool,
  isDeletingAttachment: PropTypes.arrayOf(PropTypes.string),
  isDeleting: PropTypes.bool,
  hasAutosave: PropTypes.bool,
});

export const notification = PropTypes.shape({
  notificationKey: PropTypes.string,
  duration: PropTypes.number,
  type: PropTypes.string,
  message: PropTypes.string,
  actions: PropTypes.func,
  reqId: PropTypes.string,
});

export const metaPageFetcher = (data = PropTypes.array) => PropTypes.shape({
  isFetching: PropTypes.bool,
  isCreating: PropTypes.bool,
  isModifying: PropTypes.arrayOf(PropTypes.string),
  isDuplicating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isChecking: PropTypes.bool,
  page: PropTypes.number,
  nbPages: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object,
  total: PropTypes.number,
  data,
});

export const pageFetcher = (data = PropTypes.array) => PropTypes.shape({
  isFetching: PropTypes.bool,
  isCreating: PropTypes.bool,
  isModifying: PropTypes.bool,
  isDuplicating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isChecking: PropTypes.bool,
  page: PropTypes.number,
  nbPages: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object,
  total: PropTypes.number,
  data,
});

export const metaEntityColumn = PropTypes.shape({
  key: PropTypes.string.isRequired,
  dataKey: PropTypes.string,
  columnKey: PropTypes.string,
  renderComponent: PropTypes.shape({
    type: PropTypes.string.isRequired,
    editVariant: PropTypes.string,
    displayVariant: PropTypes.string,
    color: PropTypes.string,
    transformToDisplay: PropTypes.func,
    getInputKey: PropTypes.func,
    validator: PropTypes.func,
    isPanelOpener: PropTypes.bool,
    withTime: PropTypes.bool,
    ownershipEntity: PropTypes.oneOf(['label', 'publisher', 'artist']),
    ownershipRightsType: PropTypes.string,
    onTheFlyField: PropTypes.string,
    getSearchProps: PropTypes.func,
  }).isRequired,
  readableWidth: PropTypes.number.isRequired,
  writableWidth: PropTypes.number,
  editable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  immutableColumn: PropTypes.bool,
  withoutColumnHeader: PropTypes.bool,
  editableColumn: PropTypes.bool,
  sortableColumn: PropTypes.bool,
});

export const metaEntityConfig = PropTypes.shape({
  columns: PropTypes.arrayOf(metaEntityColumn).isRequired,
  gridModifyPick: PropTypes.func.isRequired,
  gridUpload: PropTypes.shape({
    key: PropTypes.string,
    maxSize: PropTypes.number,
  }),
  panelUploads: PropTypes.objectOf(PropTypes.shape({
    maxSize: PropTypes.number,
  })),
});

export const metaSearchSettings = PropTypes.shape({
  searchInDescriptions: PropTypes.bool.isRequired,
});

export const localPreferences = PropTypes.shape({
  language: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    gridsLanguage: PropTypes.string.isRequired,
    showTracksTags: PropTypes.string.isRequired,
  }).isRequired,
});

// =============================
// Global
// =============================

export const docTenant = PropTypes.shape({
  id: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  company_name: PropTypes.string,
});

export const descriptions = PropTypes.arrayOf(
  PropTypes.shape({
    locale: PropTypes.string,
    value: PropTypes.string,
  }),
);

export const image = PropTypes.shape({
  original: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
  small: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
  large: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
  is_converting: PropTypes.bool,
  is_converted: PropTypes.bool,
  conversion_error: PropTypes.bool,
  error_key: PropTypes.string,
});

export const audiofile = PropTypes.shape({
  original: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
  sd_mp3: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
  hd_mp3: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
  is_converting: PropTypes.bool.isRequired,
  is_converted: PropTypes.bool.isRequired,
  conversion_error: PropTypes.bool.isRequired,
  error_key: PropTypes.string,
});

export const waveform = PropTypes.shape({
  small: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
  large: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
});

export const stems = PropTypes.shape({
  original: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
});

export const attachment = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  file_name: PropTypes.string,
  url: PropTypes.string,
});

export const ingestionFile = PropTypes.shape({
  key: PropTypes.string,
  file_name: PropTypes.string,
  url: PropTypes.string,
});

export const albumType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
});

export const rightsType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
});

export const rightsSociety = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const tag = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  synonyms: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
});

export const tagWithCat = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  synonyms: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  search_names: descriptions.isRequired,
  search_synonyms: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  category_id: PropTypes.string.isRequired,
  category_key: PropTypes.string.isRequired,
  category_names: descriptions.isRequired,
  sub_category_id: PropTypes.string,
  sub_category_key: PropTypes.string,
  sub_category_names: descriptions,
});

export const tagSubCategory = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  search_names: descriptions.isRequired,
  category_id: PropTypes.string.isRequired,
  category_key: PropTypes.string.isRequired,
  category_names: descriptions.isRequired,
  tags: PropTypes.arrayOf(tagWithCat).isRequired,
});

export const tagCategory = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  search_names: descriptions.isRequired,
  color: PropTypes.string.isRequired,
  sub_categories: PropTypes.arrayOf(tagSubCategory).isRequired,
  tags: PropTypes.arrayOf(tagWithCat).isRequired,
});

export const userIndustry = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
});

export const trackVersion = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  search_names: descriptions.isRequired,
});

export const trackVersionBase = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
});

export const tempo = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  range_start: PropTypes.number.isRequired,
  range_end: PropTypes.number.isRequired,
  names: descriptions.isRequired,
});

export const tonalityKey = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  tonality: PropTypes.string.isRequired,
});

export const territory = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const language = PropTypes.shape({
  nativeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const displayArtist = PropTypes.shape({
  artist: PropTypes.shape({
    id: PropTypes.string,
    tenant: docTenant,
    full_name: PropTypes.string,
    aliases: PropTypes.arrayOf(PropTypes.string),
  }),
  alias: PropTypes.string,
});

export const artistMasterOwnership = PropTypes.shape({
  artist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    full_name: PropTypes.string.isRequired,
    aliases: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  alias: PropTypes.string,
  rights_type: rightsType,
  ownership_percentage: PropTypes.number,
  sync_percentage: PropTypes.number,
  chain_of_title: PropTypes.string,
  territories: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const artistPublishingOwnership = PropTypes.shape({
  artist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    full_name: PropTypes.string.isRequired,
    aliases: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  alias: PropTypes.string,
  rights_type: rightsType,
  performing_percentage: PropTypes.number,
  mechanical_percentage: PropTypes.number,
  sync_percentage: PropTypes.number,
  chain_of_title: PropTypes.string,
  territories: PropTypes.arrayOf(PropTypes.string),
});

export const masterOwnership = PropTypes.shape({
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    label_name: PropTypes.string.isRequired,
  }),
  rights_type: rightsType,
  ownership_percentage: PropTypes.number,
  sync_percentage: PropTypes.number,
  chain_of_title: PropTypes.string,
  territories: PropTypes.arrayOf(PropTypes.string),
});

export const publishingOwnership = PropTypes.shape({
  publisher: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    publisher_name: PropTypes.string.isRequired,
  }),
  rights_type: rightsType,
  performing_percentage: PropTypes.number,
  mechanical_percentage: PropTypes.number,
  sync_percentage: PropTypes.number,
  chain_of_title: PropTypes.string,
  territories: PropTypes.arrayOf(PropTypes.string),
});

// =============================
// User
// =============================

export const user = PropTypes.shape({
  id: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  account_type: PropTypes.string,
  avatar_color: PropTypes.string,
  company_name: PropTypes.string,
  phone_number: PropTypes.string,
  job: PropTypes.string,
  country: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const userSubscriptions = PropTypes.shape({
  meta: PropTypes.shape({ active: PropTypes.bool }),
  modo: PropTypes.shape({ active: PropTypes.bool }),
  maia: PropTypes.shape({ active: PropTypes.bool }),
});

const organizationSettingsMusicTypes = PropTypes.shape({
  wants: PropTypes.arrayOf(PropTypes.string),
  default: PropTypes.string,
});

export const organizationSettings = PropTypes.shape({
  id: PropTypes.string,
  tenant: PropTypes.string,
  meta: PropTypes.shape({
    audiofiles: PropTypes.shape({
      name_template: PropTypes.string,
      comment: PropTypes.string,
    }),
    provider_types: PropTypes.arrayOf(PropTypes.string),
    music_types: organizationSettingsMusicTypes,
  }),
});

export const userNotification = PropTypes.shape({
  id: PropTypes.string,
  date: PropTypes.string,
  message: PropTypes.node,
  key: PropTypes.string,
  seen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  created_at: PropTypes.string,
});

export const agent = PropTypes.shape({
  id: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  company_name: PropTypes.string,
  read_only_agent: PropTypes.bool,
  agent_can_access_contact_infos: PropTypes.bool,
  agent_can_access_tracks_splits: PropTypes.bool,
  agent_can_access_notes: PropTypes.bool,
  organization_settings: {
    meta: {
      provider_types: PropTypes.arrayOf(PropTypes.string),
      music_types: organizationSettingsMusicTypes,
    },
  },
});

export const provider = PropTypes.shape({
  id: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  company_name: PropTypes.string,
  read_only_provider: PropTypes.bool,
  provider_can_access_contact_infos: PropTypes.bool,
  provider_can_access_notes: PropTypes.bool,
  provider_can_access_tracks_splits: PropTypes.bool,
  organization_settings: {
    meta: {
      provider_types: PropTypes.arrayOf(PropTypes.string),
      music_types: organizationSettingsMusicTypes,
    },
  },
});

export const tracksFacets = PropTypes.shape({
  tags: PropTypes.objectOf(PropTypes.number),
  versions: PropTypes.objectOf(PropTypes.number),
  duration: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
    doc_count: PropTypes.number,
  })),
  bpm: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
    doc_count: PropTypes.number,
  })),
  year: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
    doc_count: PropTypes.number,
  })),
});

// =============================
// Meta
// =============================

export const customFieldsConfig = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      list_options: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    collections: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  })).isRequired,
});

export const bulkData = PropTypes.shape({
  tenant_documents: PropTypes.number,
  agent_documents: PropTypes.number,
  agents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.number,
  })),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.number,
  })),
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.number,
  })),
});

export const contact = PropTypes.shape({
  id: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired,
  email: PropTypes.string,
  address: PropTypes.string,
  phone_number: PropTypes.string,
  secondary_phone_number: PropTypes.string,
  role: PropTypes.string,
});

export const people = PropTypes.shape({
  id: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired,
  role: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  phone_number: PropTypes.string,
  secondary_phone_number: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const publisher = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  publisher_name: PropTypes.string.isRequired,
  publisher_code: PropTypes.string,
  descriptions,
  territories: PropTypes.arrayOf(PropTypes.string).isRequired,
  performing_rights_society: rightsSociety,
  mechanical_rights_society: rightsSociety,
  ipi: PropTypes.string,
  contacts: PropTypes.arrayOf(contact),
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  image,
  owned_by_tenant: PropTypes.bool.isRequired,
  agents: PropTypes.arrayOf(PropTypes.string),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  })).isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const label = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  label_name: PropTypes.string.isRequired,
  label_code: PropTypes.string,
  descriptions,
  territories: PropTypes.arrayOf(PropTypes.string).isRequired,
  neighboring_rights_society: rightsSociety,
  ipn: PropTypes.string,
  contacts: PropTypes.arrayOf(contact),
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  image,
  owned_by_tenant: PropTypes.bool.isRequired,
  agents: PropTypes.arrayOf(PropTypes.string),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  })).isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const artist = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  full_name: PropTypes.string.isRequired,
  aliases: PropTypes.arrayOf(PropTypes.string),
  descriptions,
  legal_first_name: PropTypes.string,
  legal_middle_name: PropTypes.string,
  legal_last_name: PropTypes.string,
  artist_type: PropTypes.string,
  artist_ref: PropTypes.string,
  ipi: PropTypes.string,
  ipn: PropTypes.string,
  artist_email: PropTypes.string,
  artist_phone: PropTypes.string,
  artist_address: PropTypes.string,
  contacts: PropTypes.arrayOf(contact),
  neighboring_rights_society: rightsSociety,
  performing_rights_society: rightsSociety,
  mechanical_rights_society: rightsSociety,
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  image,
  owned_by_tenant: PropTypes.bool.isRequired,
  agents: PropTypes.arrayOf(PropTypes.string),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  })).isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const catalog = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  name: PropTypes.string.isRequired,
  descriptions,
  default_label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label_name: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
  }),
  default_publisher: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label_name: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
  }),
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  contacts: PropTypes.arrayOf(contact),
  image,
  owned_by_tenant: PropTypes.bool.isRequired,
  public: PropTypes.bool.isRerquired,
  agents: PropTypes.arrayOf(PropTypes.string),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  })).isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const playlist = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  created_by: docTenant.isRequired,
  title: PropTypes.string.isRequired,
  descriptions,
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  image,
  owned_by_tenant: PropTypes.bool.isRequired,
  public: PropTypes.bool.isRerquired,
  agents: PropTypes.arrayOf(PropTypes.string),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  })).isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const brief = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  created_by: docTenant.isRequired,
  title: PropTypes.string.isRequired,
  descriptions,
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  budget: PropTypes.number,
  currency: PropTypes.string.isRequired,
  deadline: PropTypes.string,
  image,
  owned_by_tenant: PropTypes.bool.isRequired,
  public: PropTypes.bool.isRequired,
  agents: PropTypes.arrayOf(PropTypes.string),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const pitch = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  created_by: docTenant.isRequired,
  title: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  descriptions,
  has_file: PropTypes.bool.isRequired,
  image,
  showcase: PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  }),
  global_token: PropTypes.string.isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const album = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  title: PropTypes.string.isRequired,
  descriptions,
  album_ref: PropTypes.string,
  release_date: PropTypes.string,
  upc: PropTypes.string,
  ean: PropTypes.string,
  album_type: albumType,
  catalog: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    name: PropTypes.string.isRequired,
  }),
  display_artists: PropTypes.arrayOf(displayArtist),
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  image,
  attachments: PropTypes.arrayOf(attachment),
  owned_by_tenant: PropTypes.bool.isRequired,
  public: PropTypes.bool.isRerquired,
  agents: PropTypes.arrayOf(PropTypes.string),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  })).isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
});

export const smallTrack = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  descriptions,
  display_title: PropTypes.string,
  duration: PropTypes.number,
  track_number: PropTypes.number,
  version: trackVersionBase,
  album: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    album_ref: PropTypes.string,
    image,
  }),
  catalog: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  display_artists: PropTypes.arrayOf(displayArtist),
  audiofile,
  waveform,
});

export const track = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: docTenant.isRequired,
  title: PropTypes.string.isRequired,
  music_type: PropTypes.string.isRequired,
  descriptions,
  track_ref: PropTypes.string,
  display_title: PropTypes.string,
  track_number: PropTypes.number,
  isrc: PropTypes.string,
  iswc: PropTypes.string,
  cocv: PropTypes.string,
  prs_tunecode: PropTypes.string,
  duration: PropTypes.number,
  bpm: PropTypes.number,
  similarity: PropTypes.number,
  release_date: PropTypes.string,
  territories: PropTypes.arrayOf(PropTypes.string).isRequired,
  lyrics: PropTypes.string,
  version: trackVersionBase,
  tempo,
  tonality_key: tonalityKey,
  original_prs: rightsSociety,
  original_mrs: rightsSociety,
  original_nrs: rightsSociety,
  tags: PropTypes.arrayOf(tag),
  rating: PropTypes.number,
  approved: PropTypes.bool,
  album: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    title: PropTypes.string.isRequired,
    album_ref: PropTypes.string,
    image,
  }),
  catalog: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    name: PropTypes.string.isRequired,
  }),
  original_track: smallTrack,
  ref_id: PropTypes.string.isRequired,
  display_artists: PropTypes.arrayOf(displayArtist),
  artists_master_ownerships: PropTypes.arrayOf(PropTypes.shape({
    artist: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tenant: docTenant.isRequired,
      full_name: PropTypes.string.isRequired,
      aliases: PropTypes.arrayOf(PropTypes.string),
    }),
    alias: PropTypes.string,
    rights_type: rightsType,
    ownership_percentage: PropTypes.number,
    sync_percentage: PropTypes.number,
    chain_of_title: PropTypes.string,
    territories: PropTypes.arrayOf(PropTypes.string),
  })),
  artists_publishing_ownerships: PropTypes.arrayOf(PropTypes.shape({
    artist: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tenant: docTenant.isRequired,
      full_name: PropTypes.string.isRequired,
      aliases: PropTypes.arrayOf(PropTypes.string),
    }),
    alias: PropTypes.string,
    rights_type: rightsType,
    performing_percentage: PropTypes.number,
    mechanical_percentage: PropTypes.number,
    sync_percentage: PropTypes.number,
    chain_of_title: PropTypes.string,
    territories: PropTypes.arrayOf(PropTypes.string),
  })),
  master_ownerships: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tenant: docTenant.isRequired,
      label_name: PropTypes.string.isRequired,
    }),
    rights_type: rightsType,
    ownership_percentage: PropTypes.number,
    sync_percentage: PropTypes.number,
    chain_of_title: PropTypes.string,
    territories: PropTypes.arrayOf(PropTypes.string),
  })),
  publishing_ownerships: PropTypes.arrayOf(PropTypes.shape({
    publisher: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tenant: docTenant.isRequired,
      publisher_name: PropTypes.string.isRequired,
    }),
    rights_type: rightsType,
    performing_percentage: PropTypes.number,
    mechanical_percentage: PropTypes.number,
    sync_percentage: PropTypes.number,
    chain_of_title: PropTypes.string,
    territories: PropTypes.arrayOf(PropTypes.string),
  })),
  audiofile,
  waveform,
  stems,
  attachments: PropTypes.arrayOf(attachment),
  complete_doc: PropTypes.bool.isRequired,
  has_file: PropTypes.bool.isRequired,
  has_stems: PropTypes.bool.isRequired,
  from_ingestion: PropTypes.bool.isRequired,
  owned_by_tenant: PropTypes.bool.isRequired,
  public: PropTypes.bool.isRerquired,
  agents: PropTypes.arrayOf(PropTypes.string),
  showcase: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    website_title: PropTypes.string.isRequired,
  })),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  notes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.object,
  grouping: PropTypes.arrayOf(PropTypes.string),
});

export const ingestion = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ingestion_type: PropTypes.string,
  catalog: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenant: docTenant.isRequired,
    name: PropTypes.string.isRequired,
  }),
  preferred_language: PropTypes.string,
  date_format: PropTypes.string,
  normalize_artists_names: PropTypes.bool,
  binaries_uploaded: PropTypes.bool,
  unmatched_processed: PropTypes.bool,
  lines: PropTypes.number,
  status: PropTypes.number,
  list_errors: PropTypes.arrayOf(PropTypes.string),
  unmatched_tags: PropTypes.objectOf(PropTypes.number),
  unmatched_versions: PropTypes.objectOf(PropTypes.number),
  tags_dictionary: PropTypes.arrayOf(PropTypes.shape({
    original: PropTypes.string,
    match: PropTypes.arrayOf(PropTypes.string),
  })),
  versions_dictionary: PropTypes.arrayOf(PropTypes.shape({
    original: PropTypes.string,
    match: PropTypes.string,
  })),
  ingestion_file: ingestionFile,
  ingestion_file_type: PropTypes.string,
  has_file: PropTypes.bool,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const playerTrack = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  display_title: PropTypes.string,
  duration: PropTypes.number,
  album: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    album_ref: PropTypes.string,
    image,
  }),
  display_artists: PropTypes.arrayOf(displayArtist),
  audiofile,
  waveform,
});

export const playlistOption = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  tenant: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    company_name: PropTypes.string,
  }),
  descriptions,
  image,
  covers: PropTypes.arrayOf(image),
});

export const albumOption = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  album_ref: PropTypes.string,
  release_date: PropTypes.string,
  descriptions,
  catalog: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  display_artists: PropTypes.arrayOf(displayArtist),
  image,
});

// =============================
// Modo
// =============================

export const modoConfigListing = PropTypes.shape({
  id: PropTypes.string,
  tenant: PropTypes.string,
  website_title: PropTypes.string,
  default_language: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
  url_config: PropTypes.shape({
    website_url: PropTypes.string,
    heroku_cname: PropTypes.string,
    heroku_id: PropTypes.string,
  }),
  active: PropTypes.bool,
  colors: PropTypes.shape({
    variant_settings: PropTypes.shape({
      default: PropTypes.oneOf(['dark', 'light']),
      other: PropTypes.oneOf(['dark', 'light']),
      player: PropTypes.oneOf(['dark', 'light']),
    }),
    light: PropTypes.shape({
      background: PropTypes.string,
      text: PropTypes.string,
      primary: PropTypes.string,
      primary_text: PropTypes.string,
      box_shadow: PropTypes.oneOf(['dark', 'light']),
    }),
    dark: PropTypes.shape({
      background: PropTypes.string,
      text: PropTypes.string,
      primary: PropTypes.string,
      primary_text: PropTypes.string,
      box_shadow: PropTypes.oneOf(['dark', 'light']),
    }),
    header: PropTypes.shape({
      background: PropTypes.string,
      text: PropTypes.string,
      primary: PropTypes.string,
      primary_text: PropTypes.string,
      box_shadow: PropTypes.oneOf(['dark', 'light']),
    }),
    search_bar: PropTypes.shape({
      background: PropTypes.string,
      text: PropTypes.string,
      primary: PropTypes.string,
      primary_text: PropTypes.string,
    }),
    chip: PropTypes.shape({
      background: PropTypes.string,
      text: PropTypes.string,
      outline_background: PropTypes.string,
      outline_text: PropTypes.string,
    }),
    filter_bar: PropTypes.shape({
      header_follows_filter_bar: PropTypes.bool,
      background: PropTypes.string,
      text: PropTypes.string,
      primary: PropTypes.string,
      primary_text: PropTypes.string,
    }),
    playlist_wide: PropTypes.shape({
      background: PropTypes.string,
      text: PropTypes.string,
      primary: PropTypes.string,
      primary_text: PropTypes.string,
    }),
    modal: PropTypes.shape({
      primary: PropTypes.string,
      primary_text: PropTypes.string,
    }),
    type: PropTypes.shape({
      cancel: PropTypes.string,
      error: PropTypes.string,
      success: PropTypes.string,
      warning: PropTypes.string,
    }),
  }),
  logo: image,
  logo_variant: image,
  logo_modal: image,
  logo_email: image,
  logo_filter_bar: image,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

const fullModoConfig = {
  id: PropTypes.string,
  website_title: PropTypes.string,
  blog_url: PropTypes.string,
  default_language: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
  global_contact: PropTypes.shape({
    address: PropTypes.string,
    address_complement: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  url_config: PropTypes.shape({
    heroku_cname: PropTypes.string,
    heroku_id: PropTypes.string,
    website_url: PropTypes.string,
  }),
  integrations: PropTypes.shape({
    google: PropTypes.shape({
      analytics_code: PropTypes.string,
    }),
    hotjar: PropTypes.shape({
      tracking_id: PropTypes.string,
    }),
    mailchimp: PropTypes.shape({
      api_key: PropTypes.string,
      audience_id: PropTypes.string,
    }),
    intercom: PropTypes.shape({
      app_id: PropTypes.string,
    }),
  }),
  default_user_rights: PropTypes.shape({
    can_download: PropTypes.bool,
    can_download_stems: PropTypes.bool,
    can_access: PropTypes.bool,
  }),
  user_rights_whitelists: PropTypes.arrayOf(PropTypes.shape({
    can_download: PropTypes.bool,
    can_download_stems: PropTypes.bool,
    can_access: PropTypes.bool,
    domain: PropTypes.string,
  })),
  permissions: PropTypes.shape({
    PAGES_CREATION: PropTypes.shape({
      active: PropTypes.bool,
      max: PropTypes.number,
    }),
    USER_STATS: PropTypes.shape({
      active: PropTypes.bool,
    }),
    PUBLIC_PLAYLIST: PropTypes.shape({
      active: PropTypes.bool,
      max: PropTypes.number,
    }),
  }),
  private_access: PropTypes.bool,
  active: PropTypes.bool,
  tenant: PropTypes.string,
  customisations: PropTypes.shape({
    logo: image,
    logo_variant: image,
    logo_modal: image,
    logo_email: image,
    logo_filter_bar: image,
    favicon: image,
    font_family: PropTypes.string,
    colors: PropTypes.shape({
      variant_settings: PropTypes.shape({
        default: PropTypes.oneOf(['dark', 'light']),
        other: PropTypes.oneOf(['dark', 'light']),
        player: PropTypes.oneOf(['dark', 'light']),
      }),
      light: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
        box_shadow: PropTypes.oneOf(['dark', 'light']),
      }),
      dark: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
        box_shadow: PropTypes.oneOf(['dark', 'light']),
      }),
      header: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
        box_shadow: PropTypes.oneOf(['dark', 'light']),
      }),
      search_bar: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      chip: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        outline_background: PropTypes.string,
        outline_text: PropTypes.string,
      }),
      filter_bar: PropTypes.shape({
        header_follows_filter_bar: PropTypes.bool,
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      playlist_wide: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      modal: PropTypes.shape({
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      type: PropTypes.shape({
        cancel: PropTypes.string,
        error: PropTypes.string,
        success: PropTypes.string,
        warning: PropTypes.string,
      }),
    }),
    homepage: PropTypes.shape({
      disable_access: PropTypes.bool,
      url: PropTypes.string,
    }),
    pre_search: PropTypes.shape({
      fullpage: PropTypes.bool,
    }),
    guided_search: PropTypes.shape({
      active: PropTypes.bool,
    }),
    player: PropTypes.shape({
      size: PropTypes.string,
    }),
    playlists: PropTypes.shape({
      use_header_variant: PropTypes.bool,
    }),
    audiofiles: PropTypes.shape({
      name_template: PropTypes.string,
      comment: PropTypes.string,
    }),
    filter_bar: PropTypes.shape({
      show_unavailable_tags: PropTypes.bool,
    }),
    global: PropTypes.shape({
      edges: PropTypes.string,
    }),
    covers: PropTypes.shape({
      filter: PropTypes.shape({
        enable: PropTypes.bool,
      }),
    }),
  }),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
};

export const modoConfig = PropTypes.shape(fullModoConfig);

export const modoConfigMinimal = PropTypes.shape({
  ..._pick(fullModoConfig, [
    'website_title',
    'blog_url',
    'default_language',
    'languages',
    'global_contact',
    'private_access',
  ]),
});

export const modoConfigActive = PropTypes.shape({
  active: PropTypes.bool,
});

export const modoConfigUrl = PropTypes.shape({
  website_url: PropTypes.string,
});

export const modoConfigCustomisations = PropTypes.shape({
  customisations: PropTypes.shape({
    font_family: PropTypes.string,
    colors: PropTypes.shape({
      variant_settings: PropTypes.shape({
        default: PropTypes.oneOf(['dark', 'light']),
        other: PropTypes.oneOf(['dark', 'light']),
        player: PropTypes.oneOf(['dark', 'light']),
      }),
      light: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
        box_shadow: PropTypes.oneOf(['dark', 'light']),
      }),
      dark: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
        box_shadow: PropTypes.oneOf(['dark', 'light']),
      }),
      header: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
        box_shadow: PropTypes.oneOf(['dark', 'light']),
      }),
      search_bar: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      chip: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        outline_background: PropTypes.string,
        outline_text: PropTypes.string,
      }),
      filter_bar: PropTypes.shape({
        header_follows_filter_bar: PropTypes.bool,
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      playlist_wide: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      modal: PropTypes.shape({
        primary: PropTypes.string,
        primary_text: PropTypes.string,
      }),
      type: PropTypes.shape({
        cancel: PropTypes.string,
        error: PropTypes.string,
        success: PropTypes.string,
        warning: PropTypes.string,
      }),
    }),
  }),
});

export const modoConfigOtherCustomisations = PropTypes.shape({
  customisations: PropTypes.shape({
    homepage: PropTypes.shape({
      disable_access: PropTypes.bool,
      url: PropTypes.string,
    }),
    pre_search: PropTypes.shape({
      fullpage: PropTypes.bool,
    }),
    guided_search: PropTypes.shape({
      active: PropTypes.bool,
    }),
    player: PropTypes.shape({
      size: PropTypes.string,
    }),
    playlists: PropTypes.shape({
      use_header_variant: PropTypes.bool,
    }),
    audiofiles: PropTypes.shape({
      name_template: PropTypes.string,
      comment: PropTypes.string,
    }),
    filter_bar: PropTypes.shape({
      show_unavailable_tags: PropTypes.bool,
    }),
    global: PropTypes.shape({
      edges: PropTypes.string,
    }),
    covers: PropTypes.shape({
      filter: PropTypes.shape({
        enable: PropTypes.bool,
      }),
    }),
  }),
});

export const modoConfigIntegrations = PropTypes.shape({
  ..._pick(fullModoConfig, 'integrations'),
});

export const modoUserRights = PropTypes.shape({
  ..._pick(fullModoConfig, ['default_user_rights', 'user_rights_whitelists']),
});

export const modoConfigLegal = descriptions;

const modoCustomLink = PropTypes.shape({
  slug: PropTypes.string,
  names: descriptions,
  page: PropTypes.string,
});

export const modoConfigMenus = PropTypes.shape({
  main_menu: PropTypes.shape({
    custom_links: PropTypes.shape({
      main: PropTypes.arrayOf(modoCustomLink),
      footer: PropTypes.arrayOf(modoCustomLink),
    }),
  }),
  header: PropTypes.shape({
    custom_links: PropTypes.shape({
      left: PropTypes.arrayOf(modoCustomLink),
      right: PropTypes.arrayOf(modoCustomLink),
    }),
  }),
});

const baseModoUser = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  industry: userIndustry,
  company_name: PropTypes.string,
  country: PropTypes.string,
  phone_number: PropTypes.string,
  can_download: PropTypes.bool,
  can_download_stems: PropTypes.bool,
  can_access: PropTypes.bool,
};

export const modoUser = PropTypes.shape({
  ...baseModoUser,
  id: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const modoUserImportPreview = PropTypes.shape({
  ...baseModoUser,
  line_number: PropTypes.number,
});

export const modoUserImport = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  lines: PropTypes.number,
  sample_data: PropTypes.arrayOf(modoUserImportPreview),
  list_errors: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.number,
  send_welcome_email: PropTypes.bool,
  ingestion_file: PropTypes.shape({
    key: PropTypes.string,
    file_name: PropTypes.string,
    url: PropTypes.string,
  }),
});

export const modoCustomPage = PropTypes.shape({
  default_title: PropTypes.string,
  meta_titles: descriptions,
  meta_descriptions: descriptions,
  active: PropTypes.bool,
  is_home: PropTypes.bool,
  enable_seo: PropTypes.bool,
  slug: PropTypes.string,
  draft_token: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  id: PropTypes.string,
  seo_preview: image,
});

const baseModoModule = {
  id: PropTypes.string,
  tenant: PropTypes.string,
  type: PropTypes.string,
  visibility: PropTypes.string,
  industries: PropTypes.arrayOf(userIndustry),
  colors: PropTypes.shape({
    use_custom_color: PropTypes.bool,
    background: PropTypes.string,
    text: PropTypes.string,
    primary: PropTypes.string,
    primary_text: PropTypes.string,
    box_shadow: PropTypes.string,
  }),
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
  padding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
  data: PropTypes.any,
  open_on_init: PropTypes.bool,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
};

export const modoModule = (type = null) => {
  switch (type) {
    case 'addresses':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.shape({
          contacts: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            phone: PropTypes.string,
            address: PropTypes.string,
            address_complement: PropTypes.string,
          })),
          google_place_id: PropTypes.string,
          contact_image: image,
        }),
      });

    case 'benefits':
    case 'benefits_alt':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string,
          title: descriptions,
          descriptions,
          link_url: PropTypes.string,
          link_text: descriptions,
          image,
        })),
      });

    case 'call_to_action':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string,
          title: descriptions,
          link_url: PropTypes.string,
          link_text: descriptions,
          outer_background_top: PropTypes.string,
          outer_background_bottom: PropTypes.string,
          image,
          benefits: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            descriptions,
            image,
          })),
        })),
      });

    case 'entities':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.string,
          descriptions,
        })),
      });

    case 'masonry':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.shape({
          use_rectangle_variant: PropTypes.bool,
          items: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.shape({
              type: PropTypes.string,
              id: PropTypes.string,
              descriptions,
            }),
            PropTypes.shape({
              type: PropTypes.string,
              title: descriptions,
              claim: descriptions,
              descriptions,
              link_url: PropTypes.string,
              cover_image: image,
            }),
          ])),
        }),
      });

    case 'masonry_video':
    case 'slider_video':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          title: descriptions,
          sub_title: descriptions,
          descriptions,
          type: descriptions,
          company_name: PropTypes.string,
          track: PropTypes.string,
          thumbnail: image,
          video_link: PropTypes.string,
        })),
      });

    case 'partners':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string,
          image,
          link_url: PropTypes.string,
          link_text: descriptions,
        })),
      });

    case 'post':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.shape({
          title: descriptions,
          claim: descriptions,
          descriptions,
          link_url: PropTypes.string,
          cover_image: image,
        }),
      });

    case 'text':
      return PropTypes.shape({
        ...baseModoModule,
        data: descriptions,
      });

    case 'tracks':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string,
        })),
      });

    case 'trending_with_tracks':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.string,
        })),
      });

    case 'social':
      return PropTypes.shape({
        ...baseModoModule,
        data: PropTypes.arrayOf(PropTypes.shape({
          title: descriptions,
        })),
      });

    default:
      return PropTypes.shape(baseModoModule);
  }
};

export const modoPublicPlaylist = PropTypes.shape({
  id: PropTypes.string,
  playlist: playlistOption,
  tokens: PropTypes.arrayOf(
    PropTypes.shape({
      owner: PropTypes.string,
      value: PropTypes.string,
      expiry_date: PropTypes.string,
      can_download: PropTypes.bool,
    }),
  ),
});
