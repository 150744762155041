// =============================
// Imports
// =============================

// External Dependencies
import PubNub from 'pubnub';

// Helpers
import { isHttps } from './misc';

// =============================
// Business
// =============================

const pubNub = { client: null };

export default function getClient(userId, authKey) {
  if (!pubNub.client) {
    pubNub.client = new PubNub({
      subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
      ssl: isHttps(),
      uuid: userId,
      authKey,
    });
  }

  return pubNub.client;
}
