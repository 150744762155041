/* Meta */
export const META_PEOPLE_PANEL = 'META_PEOPLE_PANEL';
export const META_PUBLISHER_PANEL = 'META_PUBLISHER_PANEL';
export const META_LABEL_PANEL = 'META_LABEL_PANEL';
export const META_ARTIST_PANEL = 'META_ARTIST_PANEL';
export const META_CATALOG_PANEL = 'META_CATALOG_PANEL';
export const META_PLAYLIST_PANEL = 'META_PLAYLIST_PANEL';
export const META_PITCH_PANEL = 'META_PITCH_PANEL';
export const META_BRIEF_PANEL = 'META_BRIEF_PANEL';
export const META_ALBUM_PANEL = 'META_ALBUM_PANEL';
export const META_TRACK_PANEL = 'META_TRACK_PANEL';
export const META_INGESTION_PANEL = 'META_INGESTION_PANEL';

export const metaMapping = {
  people: META_PEOPLE_PANEL,
  publisher: META_PUBLISHER_PANEL,
  label: META_LABEL_PANEL,
  artist: META_ARTIST_PANEL,
  catalog: META_CATALOG_PANEL,
  playlist: META_PLAYLIST_PANEL,
  pitch: META_PITCH_PANEL,
  brief: META_BRIEF_PANEL,
  album: META_ALBUM_PANEL,
  track: META_TRACK_PANEL,
  ingestion: META_INGESTION_PANEL,
};

export const entityMapping = {
  [META_PEOPLE_PANEL]: 'people',
  [META_PUBLISHER_PANEL]: 'publisher',
  [META_LABEL_PANEL]: 'label',
  [META_ARTIST_PANEL]: 'artist',
  [META_CATALOG_PANEL]: 'catalog',
  [META_PLAYLIST_PANEL]: 'playlist',
  [META_PITCH_PANEL]: 'pitch',
  [META_BRIEF_PANEL]: 'brief',
  [META_ALBUM_PANEL]: 'album',
  [META_TRACK_PANEL]: 'track',
  [META_INGESTION_PANEL]: 'ingestion',
};

/* Modo */
export const MODO_USER_PANEL = 'MODO_USER_PANEL';
