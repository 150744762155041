// =============================
// Imports
// =============================

// External Dependencies
import _pick from 'lodash/pick';

// =============================
// Helpers
// =============================

/**
 * Pick track data values for player
 * @param {Object} document - Track document
 * @return {Object} - Player track data
 */
export const pickPlayerTrackData = document => _pick(document, [
  'id',
  'title',
  'display_title',
  'duration',
  'album',
  'display_artists',
  'audiofile',
  'waveform',
  'stems',
]);

/**
 * Get next seek value
 * @param {number} duration - Duration of track
 * @param {number} seekValue - Seek value in percentage
 * @return {number} - Next seek value in seconds
 */
export const getNextSeekValue = (duration, seekValue) => (duration / 100) * seekValue;
