// =============================
// Imports
// =============================

// External Dependencies
import { memo } from 'react';
import PropTypes from 'prop-types';

// Styles
import {
  DocumentAutotagStatus,
  AutotagActionButton,
  AutotagActionIcon,
  AutotagActionWrapper,
} from './styles';

import {
  MiscHelper,
  SectionBox,
} from '../common.styles';

// =============================
// Helpers
// =============================

const sharedPropTypes = {
  autotaggingData: PropTypes.shape({
    nb_tracks: PropTypes.number,
    nb_versions: PropTypes.number,
    nb_tracks_not_autotagged: PropTypes.number,
    nb_versions_not_autotagged: PropTypes.number,
  }),
  documentRights: PropTypes.shape({
    isOwner: PropTypes.bool.isRequired,
  }).isRequired,
  entity: PropTypes.oneOf([
    'catalog',
    'playlist',
    'brief',
    'album',
    'track',
  ]).isRequired,
  t: PropTypes.func.isRequired,
};

const getAutotaggingStatus = (autotaggingData) => {
  let autotaggingStatus = 0;

  if (
    autotaggingData.nb_tracks_not_autotagged
    && autotaggingData.nb_tracks !== autotaggingData.nb_tracks_not_autotagged
  ) {
    autotaggingStatus = 1;
  }

  if (!autotaggingData.nb_tracks_not_autotagged) {
    autotaggingStatus = 2;
  }

  return autotaggingStatus;
};

const canAutotagInContext = (autotaggingData, documentRights, entity) => {
  if (entity !== 'track') return !!autotaggingData.nb_tracks && documentRights.isOwner;
  return (!!autotaggingData.nb_tracks || !!autotaggingData.nb_versions) && documentRights.isOwner;
};

// =============================
// Components
// =============================

const ColumnAction = memo(({
  autotagDocument,
  autotaggingData,
  documentRights,
  entity,
  t,
}) => {
  if (!autotaggingData) return null;

  if (canAutotagInContext(autotaggingData, documentRights, entity)) {
    return (
      <AutotagActionWrapper
        onClick={() => autotagDocument(true, {
          nbTracks: autotaggingData.nb_tracks,
          nbBillableTracks: autotaggingData.nb_tracks_not_autotagged,
          ...(entity === 'track' ? {
            nbTrackVersions: autotaggingData.nb_versions,
            nbBillableTrackVersions: autotaggingData.nb_versions_not_autotagged,
          } : {}),
        })}
      >
        <AutotagActionIcon />
        {t(`pages:meta.${entity}s.panel.autotag`)}
      </AutotagActionWrapper>
    );
  }

  return null;
});

ColumnAction.displayName = 'AutotagColumnAction';
ColumnAction.propTypes = sharedPropTypes;
ColumnAction.propTypes = { ...ColumnAction.propTypes, autotagDocument: PropTypes.func.isRequired };
ColumnAction.defaultProps = { autotaggingData: null };

const PanelSection = memo(({
  autotagDocument,
  autotaggingData,
  documentRights,
  entity,
  t,
}) => {
  if (!autotaggingData) return null;

  if (canAutotagInContext(autotaggingData, documentRights, entity)) {
    const autotaggingStatus = getAutotaggingStatus(autotaggingData);

    return (
      <SectionBox>
        <AutotagActionButton
          onClick={() => autotagDocument(true, {
            nbTracks: autotaggingData.nb_tracks,
            nbBillableTracks: autotaggingData.nb_tracks_not_autotagged,
            ...(entity === 'track' ? {
              nbTrackVersions: autotaggingData.nb_versions,
              nbBillableTrackVersions: autotaggingData.nb_versions_not_autotagged,
            } : {}),
          })}
        >
          {t(`pages:meta.${entity}s.panel.autotag`)}
        </AutotagActionButton>
        <MiscHelper mt="2rem">
          {autotaggingStatus === 0 && t(`pages:meta.${entity}s.panel.is_not_autotagged_desc`)}
          {autotaggingStatus === 1 && t(`pages:meta.${entity}s.panel.is_partially_autotagged_desc`)}
          {autotaggingStatus === 2 && t(`pages:meta.${entity}s.panel.is_autotagged_desc`)}
        </MiscHelper>
      </SectionBox>
    );
  }

  return null;
});

PanelSection.displayName = 'AutotagPanelSection';
PanelSection.propTypes = sharedPropTypes;
PanelSection.propTypes = { ...PanelSection.propTypes, autotagDocument: PropTypes.func.isRequired };
PanelSection.defaultProps = { autotaggingData: null };

const Status = memo(({
  autotaggingData,
  documentRights,
  entity,
  t,
}) => {
  if (!autotaggingData) return null;

  if (canAutotagInContext(autotaggingData, documentRights, entity)) {
    const autotaggingStatus = getAutotaggingStatus(autotaggingData);

    return (
      <DocumentAutotagStatus>
        {autotaggingStatus === 0 && t(`pages:meta.${entity}s.panel.is_not_autotagged`)}
        {autotaggingStatus === 1 && t(`pages:meta.${entity}s.panel.is_partially_autotagged`)}
        {autotaggingStatus === 2 && t(`pages:meta.${entity}s.panel.is_autotagged`)}
      </DocumentAutotagStatus>
    );
  }

  return null;
});

Status.displayName = 'AutotagStatus';
Status.propTypes = sharedPropTypes;
Status.defaultProps = { autotaggingData: null };

// =============================
// Exports
// =============================

const Autotag = { ColumnAction, PanelSection, Status };

export default Autotag;
