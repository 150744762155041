// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _get from 'lodash/get';

import { withTranslation } from '../config/i18n';

import {
  clearRelationsNotification as clearRelationsNotificationBase,
  pushLatestNotification as pushLatestNotificationBase,
  pushRelationsNotification as pushRelationsNotificationBase,
} from '../store/actions/UserActions';
import { setNotification as setNotificationBase } from '../store/actions/NotificationActions';
import {
  getMetaTracks as getMetaTracksBase,
  updateMetaIngestionPostJob as updateMetaIngestionPostJobBase,
} from '../store/actions/MetaActions';

import * as ntfs from '../store/constants/NotificationTypes';

import getClient from '../helpers/realtime';
import download from '../helpers/download';
import * as pth from '../helpers/proptypes';
import * as searchHelpers from '../helpers/meta-search';

// =============================
// Page
// =============================

class Realtime extends Component {
  listener = {
    message: (msg) => {
      const {
        originalRealtimeChannels,
        pushLatestNotification,
        pushRelationsNotification,
        clearRelationsNotification,
        setNotification,
        getMetaTracks,
        updateMetaIngestionPostJob,
        t,
      } = this.props;

      // =============================
      // Notifications
      // =============================

      if (msg.channel === originalRealtimeChannels.notifications) {
        switch (_get(msg, 'message.type')) {
          case 'MODO_USERS_EXPORT':
          case 'META_ARCHIVE':
          case 'META_TRACKS_EXPORT':
          case 'META_ENTITY_EXPORT': {
            if (_get(msg, 'message.status') === 'COMPLETED' && _get(msg, 'message.data.file')) {
              download(_get(msg, 'message.data.file'));
            }

            pushLatestNotification(_get(msg, 'message', {}));
            break;
          }

          case 'META_SEARCH_SIMILARITY': {
            if (_get(msg, 'message.status') === 'COMPLETED') {
              setNotification({
                notificationKey: ntfs.SIMILARITY_SEARCH_SUCCESS_INFO,
                message: t('pages:meta.tracks.similarity_search_success'),
                type: 'success',
                duration: 12000,
              });

              const query = searchHelpers.reduxObjectToQueryString('track', 0, {
                filters: {
                  search_id: [_get(msg, 'message.data.id'), _get(msg, 'message.data.value')],
                },
              });

              if (
                typeof window !== 'undefined'
                && window.location.pathname === '/meta/tracks'
                && window.location.search === query
              ) {
                getMetaTracks(0, {
                  filters: {
                    search_id: [_get(msg, 'message.data.id'), _get(msg, 'message.data.value')],
                  },
                });
              }
            } else {
              setNotification({
                notificationKey: ntfs.SIMILARITY_SEARCH_FAILURE_INFO,
                message: t('errors:meta.tracks.similarity_search_error'),
                type: 'error',
                duration: 12000,
              });
            }

            pushLatestNotification(_get(msg, 'message', {}));
            break;
          }

          case 'META_GENERATE_INGESTION_DICTIONARIES': {
            if (_get(msg, 'message.status') === 'COMPLETED') {
              updateMetaIngestionPostJob(_get(msg, 'message.data.id'));
            }

            pushLatestNotification(_get(msg, 'message', {}));
            break;
          }

          case 'META_INGESTION': {
            if (_get(msg, 'message.status') === 'COMPLETED') {
              updateMetaIngestionPostJob(_get(msg, 'message.data.id'));
            }

            pushLatestNotification(_get(msg, 'message', {}));
            break;
          }

          case 'META_INGEST_ALBUM_TRACKS': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('album', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('album', {
                notificationId: _get(msg, 'message.id'),
                docIds: [_get(msg, 'message.data.id')],
                progress: _get(msg, 'message.data.progress'),
                data: { isAudioIngestion: true },
              });
            } else {
              pushRelationsNotification('album', {
                notificationId: _get(msg, 'message.id'),
                docIds: [_get(msg, 'message.data.id')],
                progress: _get(msg, 'message.data.progress'),
                data: { isAudioIngestion: true },
              });
            }

            break;
          }

          case 'META_INGEST_PLAYLIST_TRACKS': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('playlist', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('playlist', {
                notificationId: _get(msg, 'message.id'),
                docIds: [_get(msg, 'message.data.id')],
                progress: _get(msg, 'message.data.progress'),
                data: { isAudioIngestion: true },
              });
            } else {
              pushRelationsNotification('playlist', {
                notificationId: _get(msg, 'message.id'),
                docIds: [_get(msg, 'message.data.id')],
                progress: _get(msg, 'message.data.progress'),
                data: { isAudioIngestion: true },
              });
            }

            break;
          }

          case 'META_INGEST_BRIEF_TRACKS': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('brief', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('brief', {
                notificationId: _get(msg, 'message.id'),
                docIds: [_get(msg, 'message.data.id')],
                progress: _get(msg, 'message.data.progress'),
                data: { isAudioIngestion: true },
              });
            } else {
              pushRelationsNotification('brief', {
                notificationId: _get(msg, 'message.id'),
                docIds: [_get(msg, 'message.data.id')],
                progress: _get(msg, 'message.data.progress'),
                data: { isAudioIngestion: true },
              });
            }

            break;
          }

          case 'META_RELATION_ARTIST_MODIFY': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('artist', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('artist', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            } else {
              pushRelationsNotification('artist', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            }

            break;
          }

          case 'META_RELATION_CATALOG_MODIFY': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('catalog', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('catalog', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            } else {
              pushRelationsNotification('catalog', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            }

            break;
          }

          case 'META_RELATION_ALBUM_MODIFY': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('album', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('album', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            } else {
              pushRelationsNotification('album', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            }

            break;
          }

          case 'META_RELATION_BRIEF_MODIFY': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('brief', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('brief', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            } else {
              pushRelationsNotification('brief', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            }

            break;
          }

          case 'META_RELATION_PLAYLIST_MODIFY': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('playlist', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('playlist', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            } else {
              pushRelationsNotification('playlist', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            }

            break;
          }

          case 'META_RELATION_TRACK_MODIFY': {
            if (
              _get(msg, 'message.status') === 'COMPLETED'
              || _get(msg, 'message.status') === 'FAILED'
            ) {
              clearRelationsNotification('track', {
                notificationId: _get(msg, 'message.id'),
              });
            } else if (_get(msg, 'message.status') === 'PROCESSING') {
              pushRelationsNotification('track', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            } else {
              pushRelationsNotification('track', {
                notificationId: _get(msg, 'message.id'),
                docIds: _get(msg, 'message.data.ids', []),
                progress: _get(msg, 'message.data.progress'),
              });
            }

            break;
          }

          default:
            pushLatestNotification(_get(msg, 'message', {}));
            break;
        }
      }
    },
  };

  static propTypes = {
    userData: pth.user,
    originalRealtimeChannels: PropTypes.objectOf(PropTypes.string),
    realtimeChannels: PropTypes.arrayOf(PropTypes.string),
    realtimeAuthKey: PropTypes.string,
    pushLatestNotification: PropTypes.func.isRequired,
    pushRelationsNotification: PropTypes.func.isRequired,
    clearRelationsNotification: PropTypes.func.isRequired,
    setNotification: PropTypes.func.isRequired,
    getMetaTracks: PropTypes.func.isRequired,
    updateMetaIngestionPostJob: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    userData: {},
    originalRealtimeChannels: {},
    realtimeChannels: [],
    realtimeAuthKey: null,
  };

  componentDidMount() {
    const { realtimeAuthKey, realtimeChannels } = this.props;

    if (realtimeAuthKey && realtimeChannels.length) {
      this.subscribe();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      realtimeAuthKey: oldRealtimeAuthKey,
      realtimeChannels: oldRealtimeChannels,
    } = prevProps;

    const { realtimeAuthKey, realtimeChannels } = this.props;

    const wasValid = !!(oldRealtimeAuthKey && oldRealtimeChannels.length);
    const isValid = !!(realtimeAuthKey && realtimeChannels.length);

    if (!wasValid && isValid) {
      this.subscribe();
    }

    if (wasValid && !isValid) {
      this.unsubscribe();
    }
  }

  componentWillUnmount() {
    const { realtimeAuthKey, realtimeChannels } = this.props;

    if (realtimeAuthKey && realtimeChannels.length) {
      this.unsubscribe();
    }
  }

  subscribe = () => {
    const { userData, realtimeAuthKey, realtimeChannels } = this.props;

    const client = getClient(userData.id, realtimeAuthKey);

    client.addListener(this.listener);

    client.subscribe({
      channels: realtimeChannels,
    });
  };

  unsubscribe = () => {
    const { realtimeChannels } = this.props;

    const client = getClient();

    client.removeListener(this.listener);

    client.unsubscribe({
      channels: realtimeChannels,
    });
  };

  render() {
    const { children } = this.props;

    return children;
  }
}

function mapStateToProps({ user }) {
  return {
    userData: user.data,
    realtimeChannels: Object.values(user.realtimeChannels),
    originalRealtimeChannels: user.realtimeChannels,
    realtimeAuthKey: user.realtimeAuthKey,
  };
}

export default compose(
  withTranslation(['pages', 'errors']),
  connect(mapStateToProps, {
    pushLatestNotification: pushLatestNotificationBase,
    pushRelationsNotification: pushRelationsNotificationBase,
    clearRelationsNotification: clearRelationsNotificationBase,
    setNotification: setNotificationBase,
    getMetaTracks: getMetaTracksBase,
    updateMetaIngestionPostJob: updateMetaIngestionPostJobBase,
  }),
)(Realtime);
