// =============================
// Imports
// =============================

import { Icons } from '@mewo/components';
import styled, { keyframes } from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { alpha } from '@material-ui/core';

import Fade from '../fade';

import { StyledButton } from '../../layouts/global.styles';

// =============================
// Styles
// =============================

const slideUp = keyframes`
  from {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 4rem, 0);
  }

  to {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }
`;

export const StyledModal = styled(props => <Modal {...props} classes={{ root: 'root' }} />)``;

export const StyledBackdrop = styled(props => <Backdrop {...props} classes={{ root: 'root' }} />)`
  && {
    &.root {
      background-color: ${alpha('#191919', 0.9)};
      /** Need !important to override inline style */
      transition: opacity 500ms !important;
    }
  }
`;

export const StyledFade = styled(Fade).attrs(({ theme }) => ({
  timeout: parseInt(theme.transition.medium, 10),
}))`
  && {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    outline: none;
    position: relative;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: block;
  outline: none;
  position: relative;
`;

export const CloseWHitBox = styled.div`
  cursor: pointer;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 0;
`;

export const CloseModal = styled(({ onClick, ...rest }) => (
  <Icons.Close onClick={onClick} {...rest} />
))``;

export const Content = styled.div`
  align-items: center;
  animation: ${slideUp} 600ms ease-in-out 1;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  background-color: #ffffff;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  opacity: 0;
  padding: 2rem;
  position: relative;
  width: 55rem;
`;

export const Title = styled.div`
  color: #191919;
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 3.8rem;
  margin-bottom: 2rem;
  text-align: center;
`;

export const Message = styled.div`
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 3.2rem;
  text-align: center;
`;

export const Image = styled.img`
  height: 15rem;
  margin: 0 auto 2rem;
  width: auto;
`;

export const Button = styled(StyledButton).attrs({
  centered: true,
})`
  margin-bottom: 1.2rem;
`;
