// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import _sortBy from 'lodash/sortBy';
import _mapValues from 'lodash/mapValues';
import _keyBy from 'lodash/keyBy';

import { withTranslation } from '../../../../../config/i18n';

import MewoInput from '../../../../presentationals/mewoInput';
import SelectInput from '../../../../presentationals/inputs/selectInput';
import Autosave from '../../../../other/autosave';

import { getNameWithFallback } from '../../../../../helpers/doc-names';
import * as pth from '../../../../../helpers/proptypes';

import {
  Wrapper,
  Header,
  HeaderTitle,
  Line,
  Occurrence,
  OriginalValue,
  StyledPagination,
} from './styles';
import { GenericText } from '../styles';

// =============================
// Component
// =============================

const entity = 'ingestion';
const MAX_PER_PAGE = 20;

class VersionsMatching extends Component {
  static propTypes = {
    /** Props used by styled component to override styles. */
    className: PropTypes.string,
    /** Versions dictionary */
    dictionary: PropTypes.arrayOf(PropTypes.shape({
      original: PropTypes.string,
      match: PropTypes.string,
    })).isRequired,
    /** Disable form */
    disabled: PropTypes.bool,
    /** Function to call when changing dictionary */
    handleChange: PropTypes.func,
    /** Current i18n settings */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Ingestion is launched */
    ingestionLaunched: PropTypes.bool.isRequired,
    /** Unmatched being processed */
    processing: PropTypes.bool.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
    /** Track versions */
    trackVersions: PropTypes.arrayOf(pth.trackVersion).isRequired,
    /** Unmatched versions */
    unmatched: PropTypes.objectOf(PropTypes.number).isRequired,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    handleChange: null,
  };

  state = {
    currentPage: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentPage: prevPage } = prevState;
    const { currentPage: nextPage } = this.state;

    if (
      prevPage !== nextPage
      && typeof document !== 'undefined'
      && document.getElementById('panel-body')
    ) {
      document.getElementById('panel-body').scrollTo(0, 0);
    }
  }

  renderForm = () => {
    const {
      dictionary,
      disabled,
      handleChange,
      i18n: { language },
      ingestionLaunched,
      t,
      trackVersions,
      unmatched,
    } = this.props;
    const { currentPage } = this.state;

    const trackVersionsOptions = _sortBy(
      trackVersions.map(trackVersion => ({
        label: getNameWithFallback(trackVersion.names, language),
        searchName: getNameWithFallback(trackVersion.search_names, language),
        value: trackVersion.id,
      })),
      'label',
    );

    const numberPages = Math.ceil(Object.keys(unmatched).length / MAX_PER_PAGE);

    const dictionaryHash = _mapValues(
      _keyBy(dictionary, 'original'),
      'match',
    );

    const sortedDictionary = _sortBy(
      Object.keys(unmatched),
      value => -unmatched[value],
    )
      .slice(currentPage * MAX_PER_PAGE, (currentPage + 1) * MAX_PER_PAGE)
      .map(original => ({
        original,
        match: dictionaryHash[original] || '',
      }));

    return (
      <Fragment>
        <Header>
          <HeaderTitle>
            {t(`pages:meta.${entity}s.panel.version_occurrences`)}
          </HeaderTitle>
          <HeaderTitle>
            {t(`pages:meta.${entity}s.panel.version_original`)}
          </HeaderTitle>
          <HeaderTitle>
            {t(`pages:meta.${entity}s.panel.version_match`)}
          </HeaderTitle>
        </Header>
        <Form
          onSubmit={() => {}}
          initialValues={{ dictionary: sortedDictionary }}
          mutators={{ ...arrayMutators }}
          key={`versions_matching_${currentPage}`}
        >
          {() => (
            <Fragment>
              <Autosave
                save={handleChange}
                nullValuesTransformer={values => ({
                  dictionary: values.dictionary.map(({ original, match }) => ({
                    original,
                    match: match || null,
                  })),
                })}
              />
              <FieldArray name="dictionary">
                {({ fields }) => fields.map((fieldName, index) => {
                  const originalValue = fields.value[index].original;

                  return (
                    <Line key={originalValue}>
                      <Occurrence>
                        {unmatched[originalValue]}
                      </Occurrence>
                      <OriginalValue>
                        {originalValue}
                      </OriginalValue>
                      <Field
                        name={`${fieldName}.match`}
                        component={MewoInput}
                        use={SelectInput}
                        type="text"
                        options={trackVersionsOptions}
                        renderOptionsVariant="classic"
                        renderValuesVariant="text"
                        searchable
                        showResetBtn={!ingestionLaunched}
                        disabled={disabled}
                      />
                    </Line>
                  );
                })}
              </FieldArray>
            </Fragment>
          )}
        </Form>

        <StyledPagination
          currentPage={currentPage}
          pageCount={numberPages}
          changeSearchPage={nextPage => this.setState({ currentPage: nextPage })}
        />
      </Fragment>
    );
  };

  render() {
    const { processing, unmatched, className, t } = this.props;

    const hasUnmatched = !!Object.keys(unmatched).length;

    return (
      <Wrapper className={className}>
        {processing && (
          <GenericText>
            {t(`pages:meta.${entity}s.panel.unmatched_processing`)}
          </GenericText>
        )}
        {!processing && hasUnmatched && this.renderForm()}
        {!processing && !hasUnmatched && (
          <GenericText>
            {t(`pages:meta.${entity}s.panel.no_unmatched_tags`)}
          </GenericText>
        )}
      </Wrapper>
    );
  }
}

function mapStateToProps({ options }) {
  return {
    trackVersions: options.data.trackversions,
  };
}

export default compose(
  withTranslation(['pages']),
  connect(mapStateToProps),
)(VersionsMatching);
