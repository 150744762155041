// =============================
// Imports
// =============================

import styled, { keyframes } from 'styled-components';

import NotificationManager from '../notificationManager';

// =============================
// Styles
// =============================

export const headerHeight = '7rem';

const slideDown = keyframes`
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0 , 0, 0);
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  animation: ${slideDown} ${({ theme }) => theme.transition.medium} linear 1;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 1.5rem 0 rgba(19, 93, 125, 0.07);
  display: flex;
  height: ${headerHeight};
  left: 0;
  padding: 0 2.4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.header};
`;

export const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  height: ${headerHeight};
  margin-left: auto;
`;

export const StyledNotificationManager = styled(NotificationManager)`
  margin-right: 1.2rem;
`;

export const FaqContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${headerHeight};
  justify-content: center;
  margin-right: 1.2rem;
`;
