// =============================
// Imports
// =============================

import { required, mustBeNumber } from '../index';

// =============================
// Validator
// =============================

const validator = {
  title: required,
  budget: mustBeNumber,
  currency: required,
};

export default validator;
