// =============================
// Imports
// =============================

import * as renderersType from '../entities/renderers/types';

// =============================
// Config
// =============================

const config = {
  columns: [
    {
      key: 'name',
      renderComponent: {
        type: renderersType.TEXT,
        isPanelOpener: true,
      },
      readableWidth: 20,
      immutableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'ingestion_type',
      renderComponent: {
        type: renderersType.INGESTION_TYPE,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'has_file',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'lines',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'unmatched_processed',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'status',
      renderComponent: {
        type: renderersType.INGESTION_STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'list_errors',
      renderComponent: {
        type: renderersType.INGESTION_ERRORS,
      },
      readableWidth: 15,
      editableColumn: true,
    },
    {
      key: 'created_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'updated_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
  ],
  gridModifyPick: () => ({}),
  panelUploads: {
    csv: {
      maxSize: 500000000,
    },
  },
};

export default config;
