// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Typo } from '@mewo/components';

import { withTranslation } from '../../../config/i18n';

import {
  Wrapper,
  LeftSectionWrapper,
  FileName,
  DropzoneInnerContainer,
  UploadStatus,
  CircleProgressbar,
  CircularContainer,
  DeleteButton,
  Flex,
  Container,
  Label,
  UploadIcon,
  PictureIcon,
} from './styles';

// =============================
// Component
// =============================

class Uploader extends PureComponent {
  static propTypes = {
    /** MimeType of files accepted */
    accept: PropTypes.string,
    /** Props used by styled component to override styles. */
    className: PropTypes.string,
    /** Download link. */
    downloadLink: PropTypes.string,
    /** If True, Uploader is disabled. */
    disabled: PropTypes.bool,
    /** If True, uploading is happening. */
    isUploading: PropTypes.bool,
    /** Uploader label. */
    label: PropTypes.string,
    /** Max size */
    maxSize: PropTypes.number,
    /** Input name */
    name: PropTypes.string.isRequired,
    /** onChange handler */
    onChange: PropTypes.func,
    /** Status of upload (0 = error, 1 = running, 2 = success) */
    status: PropTypes.oneOf([0, 1, 2]),
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Upload progress in percentage. */
    uploadProgress: PropTypes.number,
    /** File uploaded */
    value: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  };

  static defaultProps = {
    accept: 'image/*',
    className: '',
    disabled: false,
    downloadLink: '',
    isUploading: false,
    label: '',
    maxSize: undefined,
    onChange: null,
    status: 1,
    uploadProgress: 0,
    value: null,
  };

  state = {
    error: false,
  };

  handleDrop = () => {
    this.setState({
      error: null,
    });
  };

  handleRejectedDrop = () => {
    this.setState({
      error: true,
    });
  };

  handleChange = (file) => {
    const { onChange } = this.props;

    // React dropzone return an array even if we specify "multiple files upload" to false
    // That's why we use file[0]
    if (onChange) {
      onChange(file ? file[0] : null);
    }
  };

  getStatus = (status) => {
    switch (status) {
      case 0:
        return 'error';
      case 1:
        return 'warning';
      case 2:
        return 'success';
      default:
        return 'error';
    }
  };

  render() {
    const {
      accept,
      className,
      downloadLink,
      value: file,
      disabled,
      isUploading,
      label,
      name,
      maxSize,
      status,
      uploadProgress,
      t,
    } = this.props;

    const { error } = this.state;

    return (
      <Wrapper className={className} disabled={disabled}>
        {label && <Label error={error}>{label}</Label>}
        {file ? (
          <Container>
            <LeftSectionWrapper>
              <PictureIcon />
            </LeftSectionWrapper>
            <FileName href={downloadLink || file.url} download>
              {file.name}
            </FileName>
            <UploadStatus status={this.getStatus(status)} />
            {!disabled && <DeleteButton onClick={() => this.handleChange(null)}>{t('common:form.delete')}</DeleteButton>}
          </Container>
        ) : (
          <Flex>
            <Dropzone
              maxSize={maxSize}
              accept={accept}
              disabled={disabled}
              multiple={false}
              onDrop={this.handleDrop}
              onDropAccepted={this.handleChange}
              onDropRejected={this.handleRejectedDrop}
              name={name}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                <DropzoneInnerContainer
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                >
                  <input {...getInputProps()} />
                  <LeftSectionWrapper>
                    {!isUploading && <UploadIcon />}
                    {isUploading && (
                      <CircularContainer>
                        <CircleProgressbar strokeWidth={10} value={uploadProgress} />
                      </CircularContainer>
                    )}
                  </LeftSectionWrapper>
                  <Flex dir="column">
                    <Typo size={1.2}>
                      {t('components:uploader.drop_your_file_or')}
                      <Typo inline color="primary" weight="semibold">
                        &nbsp;
                        {t('components:uploader.browse')}
                        &nbsp;
                      </Typo>
                      {t('components:uploader.on_your_computer')}
                    </Typo>
                    {error && (
                      <Typo color="type.error" size={1}>
                        {t('components:uploader.file_not_accepted')}
                      </Typo>
                    )}
                  </Flex>
                </DropzoneInnerContainer>
              )}
            </Dropzone>
          </Flex>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation(['components'])(Uploader);
