// =============================
// Imports
// =============================

import { i18n } from '../../../../config/i18n';

import * as renderersType from '../entities/renderers/types';

// =============================
// Config
// =============================

const config = {
  columns: [
    {
      key: 'image',
      renderComponent: {
        type: renderersType.IMAGE,
      },
      readableWidth: 5,
      immutableColumn: true,
      withoutColumnHeader: true,
    },
    {
      key: 'created_by',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: user => `${user.first_name} ${user.last_name}`,
        getSearchProps: (document) => {
          const { created_by: user } = document;

          return {
            entity: 'pitch',
            filters: {
              created_by: [[
                user.id,
                `${user.first_name} ${user.last_name}`,
              ]],
            },
            resetQuery: true,
          };
        },
      },
      readableWidth: 12,
      immutableColumn: true,
    },
    {
      key: 'title',
      renderComponent: {
        type: renderersType.TEXT,
        isPanelOpener: true,
        getSearchProps: document => ({
          entity: 'track',
          filters: {
            [`${document.type === 'playlist' ? 'internal_ref' : document.type}s`]: [
              [
                document.entity,
                document.type === 'playlist'
                  ? `${i18n.t('common:entities.pitch')}: ${document.title}`
                  : document.title,
              ],
            ],
          },
          resetQuery: true,
        }),
      },
      readableWidth: 20,
      immutableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'type',
      renderComponent: {
        type: renderersType.PITCH_ENTITY_TYPE,
      },
      readableWidth: 15,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'showcase',
      renderComponent: {
        type: renderersType.SHOWCASE,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'created_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'updated_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
  ],
  gridModifyPick: () => ({}),
};

export default config;
