// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import dayjs from 'dayjs';

// Config
import { i18n } from '../config/i18n';

// Helpers
import { buildTextNode, buildDownloadTextNode } from './locales';
import * as searchHelpers from './meta-search';

// =============================
// Helpers
// =============================

function getMessageData(key, data, status) {
  switch (key) {
    case 'ADDED_AS_AGENT':
    case 'AGENT_ACCEPTED_INVITATION':
    case 'AGENT_REMOVED_PROVIDER':
      return data.company_name
        ? { author: `${data.company_name} (${data.email})` }
        : { author: `${data.first_name} ${data.last_name} (${data.email})` };

    case 'REMOVED_AS_AGENT': {
      const base = {
        author: data.company_name
          ? `${data.company_name} (${data.email})`
          : `${data.first_name} ${data.last_name} (${data.email})`,
      };

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      return base;
    }

    case 'META_SEARCH_SIMILARITY':
      return { id: data.id, value: data.value };

    case 'META_ARCHIVE': {
      const base = {
        context: i18n.t(`common:entities.${data.context}`),
        name: data.name,
      };

      if (status === 'CREATED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      if (status === 'COMPLETED') {
        return { ...base, file: data.file };
      }

      return { ...base, error_key: data.error_key };
    }

    case 'META_TRACKS_EXPORT': {
      const remaining = data.names ? data.names.length - 2 : 0;

      const base = {
        context: data.context === 'all'
          ? i18n.t('common:misc.all_your_tracks_prefix')
          : i18n.t(`common:entities.${data.context}`, { count: data.names.length }).toLowerCase(),
        name: data.context === 'all'
          ? i18n.t('common:misc.all_your_tracks_suffix')
          : data.names.slice(0, 2).map(name => `"${name}"`)
            .join(remaining ? ', ' : ` ${i18n.t('common:misc.and')} `),
      };

      if (data.names && data.names.length > 2) {
        base.name += ` ${i18n.t('common:misc.and_others', { count: remaining })}`;
      }

      if (status === 'CREATED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      if (status === 'COMPLETED') {
        return { ...base, file: data.file };
      }

      return { ...base, error_key: data.error_key };
    }

    case 'META_ENTITY_EXPORT': {
      const base = {
        context: i18n.t(`common:entities.${data.context}`, { count: 2 }).toLowerCase(),
      };

      if (status === 'CREATED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      if (status === 'COMPLETED') {
        return { ...base, file: data.file };
      }

      return { ...base, error_key: data.error_key };
    }

    case 'META_AUTOTAG_TRACKS': {
      const base = {
        context: i18n.t(`common:entities.${data.context}`),
        name: data.name,
        id: data.id,
      };

      if (status === 'CREATED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      if (status === 'COMPLETED') {
        return base;
      }

      return { ...base, error_key: data.error_key };
    }

    case 'META_BRIEF_INVITE_AGENT': {
      const base = {
        id: data.id,
        brief: data.name,
      };

      return data.tenant.company_name
        ? { author: `${data.tenant.company_name} (${data.tenant.email})`, ...base }
        : { author: `${data.tenant.first_name} ${data.tenant.last_name} (${data.tenant.email})`, ...base };
    }

    case 'META_BRIEF_AGENT_ADD_TRACK': {
      const base = {
        id: data.id,
        brief: data.name,
      };

      return data.agent.company_name
        ? { author: `${data.agent.company_name} (${data.agent.email})`, ...base }
        : { author: `${data.agent.first_name} ${data.agent.last_name} (${data.agent.email})`, ...base };
    }

    case 'META_GENERATE_INGESTION_DICTIONARIES': {
      const base = {
        id: data.id,
        ingestion: data.name,
      };

      if (status === 'CREATED' || status === 'COMPLETED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      return { ...base, error_key: data.error_key };
    }

    case 'META_INGESTION': {
      const base = {
        id: data.id,
        ingestion: data.name,
      };

      if (status === 'CREATED' || status === 'COMPLETED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      return { ...base, error_key: data.error_key };
    }

    case 'MODO_NEW_USER':
      return {
        website_title: data.website_title,
        email: data.email,
        full_name: `${data.first_name} ${data.last_name}`,
      };

    case 'MODO_USER_INGESTION': {
      const base = {
        website_title: data.website_title,
        file_key: data.file.split('/').reverse()[0],
      };

      if (status === 'CREATED' || status === 'COMPLETED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      return { ...base, error_key: data.error_key };
    }

    case 'MODO_USERS_EXPORT': {
      const base = {
        website_title: data.website_title,
      };

      if (status === 'CREATED') {
        return base;
      }

      if (status === 'PROCESSING') {
        return { ...base, progress: data.progress };
      }

      if (status === 'COMPLETED') {
        return { ...base, file: data.file };
      }

      return { ...base, error_key: data.error_key };
    }

    default:
      return null;
  }
}

function getMessage(key, data, lastUpdatedAt, status) {
  const finalKey = status ? `${key}_${status}` : key;
  const defaultText = i18n.t(`common:notifications.${finalKey}`, getMessageData(key, data, status));

  switch (key) {
    case 'ADDED_AS_AGENT':
      return buildTextNode(defaultText, '/dashboard/providers');

    case 'AGENT_ACCEPTED_INVITATION':
      return buildTextNode(defaultText, '/dashboard/agents');

    case 'META_SEARCH_SIMILARITY': {
      const messageData = getMessageData(key, data, status);

      if (status === 'COMPLETED') {
        // Similarity searched are only stored for 7 days, so if the search was created
        // more than 7 days, the link is expired
        if (dayjs(lastUpdatedAt).isBefore(dayjs().subtract(7, 'day'))) {
          return i18n.t(`common:notifications.${key}_EXPIRED`, messageData);
        }

        const query = searchHelpers.reduxObjectToQueryString('track', 0, {
          filters: {
            search_id: [messageData.id, messageData.value],
          },
        });

        return buildTextNode(defaultText, `/meta/tracks${query}`);
      }

      return defaultText;
    }

    case 'META_ARCHIVE':
    case 'META_TRACKS_EXPORT':
    case 'META_ENTITY_EXPORT': {
      if (status === 'CREATED' || status === 'PROCESSING') {
        return defaultText;
      }

      const messageData = getMessageData(key, data, status);

      if (status === 'COMPLETED') {
        // Archives are only stored for 5 days, so if the archive was created
        // more than 5 days, the link is expired
        if (dayjs(lastUpdatedAt).isBefore(dayjs().subtract(5, 'day'))) {
          return i18n.t(`common:notifications.${key}_EXPIRED`, messageData);
        }

        return buildDownloadTextNode(defaultText, messageData.file);
      }

      return i18n.t(`common:notifications.${finalKey}.${messageData.error_key}`, messageData);
    }

    case 'META_AUTOTAG_TRACKS': {
      if (status === 'CREATED' || status === 'PROCESSING') {
        return defaultText;
      }

      const messageData = getMessageData(key, data, status);

      if (status === 'COMPLETED') {
        const filters = {};

        // Use data to not have translated context in messageData
        switch (data.context) {
          case 'catalog':
            filters.catalogs = [[messageData.id, messageData.name]];
            break;

          case 'album':
            filters.albums = [[messageData.id, messageData.name]];
            break;

          case 'playlist':
            filters.playlists = [[messageData.id, messageData.name]];
            break;

          case 'track':
          default:
            filters.ref_id = [messageData.id, messageData.name];
            break;
        }

        const query = searchHelpers.reduxObjectToQueryString('track', 0, { filters });

        return buildTextNode(defaultText, `/meta/tracks${query}`);
      }

      return i18n.t(`common:notifications.${finalKey}.${messageData.error_key}`, messageData);
    }

    case 'META_BRIEF_INVITE_AGENT':
    case 'META_BRIEF_AGENT_ADD_TRACK': {
      const messageData = getMessageData(key, data, status);

      // NOTE: This is a random token used to refresh panel
      const refreshToken = Math.floor(100000 + Math.random() * 900000);

      return buildTextNode(
        defaultText,
        `/meta/briefs?panel=${messageData.id}&r=${refreshToken}`,
      );
    }

    case 'META_GENERATE_INGESTION_DICTIONARIES': {
      const messageData = getMessageData(key, data, status);

      if (status === 'CREATED' || status === 'PROCESSING') {
        return defaultText;
      }

      if (status === 'COMPLETED') {
        // NOTE: This is a random token used to refresh panel
        const refreshToken = Math.floor(100000 + Math.random() * 900000);

        return buildTextNode(
          defaultText,
          `/meta/ingestions?panel=${messageData.id}&r=${refreshToken}`,
        );
      }

      return i18n.t(`common:notifications.${finalKey}.${messageData.error_key}`, messageData);
    }

    case 'META_INGESTION': {
      if (status === 'CREATED' || status === 'PROCESSING') {
        return defaultText;
      }

      const messageData = getMessageData(key, data, status);

      if (status === 'COMPLETED') {
        // NOTE: This is a random token used to refresh panel
        const refreshToken = Math.floor(100000 + Math.random() * 900000);

        return buildTextNode(defaultText, `/meta/tracks?r=${refreshToken}`);
      }

      return i18n.t(`common:notifications.${finalKey}.${messageData.error_key}`, messageData);
    }

    case 'MODO_NEW_USER':
      return buildTextNode(defaultText, '/modo/[modoId]/users', `/modo/${data.id}/users`);

    case 'MODO_USER_INGESTION': {
      if (status === 'CREATED' || status === 'PROCESSING') {
        return defaultText;
      }

      if (status === 'COMPLETED') {
        // NOTE: This is a random token used to refresh page
        const refreshToken = Math.floor(100000 + Math.random() * 900000);

        return buildTextNode(
          defaultText,
          `/modo/[modoId]/users?r=${refreshToken}`,
          `/modo/${data.id}/users?r=${refreshToken}`,
        );
      }

      const messageData = getMessageData(key, data, status);
      return i18n.t(`common:notifications.${finalKey}.${messageData.error_key}`, messageData);
    }

    case 'MODO_USERS_EXPORT': {
      if (status === 'CREATED' || status === 'PROCESSING') {
        return defaultText;
      }

      const messageData = getMessageData(key, data, status);

      if (status === 'COMPLETED') {
        // Archives are only stored for 5 days, so if the archive was created
        // more than 5 days, the link is expired
        if (dayjs(lastUpdatedAt).isBefore(dayjs().subtract(5, 'day'))) {
          return i18n.t(`common:notifications.${key}_EXPIRED`, messageData);
        }

        return buildDownloadTextNode(defaultText, messageData.file);
      }

      return i18n.t(`common:notifications.${finalKey}.${messageData.error_key}`, messageData);
    }

    default:
      return defaultText;
  }
}

function transformNotifications(notifications = []) {
  return notifications
    .map((notification) => {
      const date = _get(notification, 'updated_at', new Date().toISOString());

      return {
        date,
        message: getMessage(notification.type, notification.data, date, notification.status),
        seen: _get(notification, 'seen', false),
        id: _get(notification, 'id', ''),
        ...notification,
      };
    });
}

export default transformNotifications;
