// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import _get from 'lodash/get';

// Components
import { withTranslation } from '../../../../../config/i18n';

// Components
import MetaDisplayArtist from '../metaDisplayArtist';
import MewoInput from '../../../../presentationals/mewoInput';
import Input from '../../../../presentationals/inputs/input';
import SelectInput from '../../../../presentationals/inputs/selectInput';
import ApiSelectInput from '../../../../containers/inputs/apiSelectInput';

import {
  composeValidators,
  maxLength,
  maxNumber,
  minNumber,
  mustBeNumber,
  required,
} from '../../../../../validators';

// Helpers
import * as pth from '../../../../../helpers/proptypes';
import { getDocName, getNameWithFallback, getSearchName } from '../../../../../helpers/doc-names';

// Styles
import {
  AddItemBtn,
  InputItemBox,
  SectionBox,
  StyledDropdown,
  OwnershipEntityInputs,
  DeleteItemButton,
} from '../../common.styles';
import { InputQuadruple, InputTriple, InputWrapper } from '../../../global.styles';

// =============================
// Component
// =============================

const entity = 'track';

class MetaTrackOwnerships extends Component {
  static propTypes = {
    documentRights: PropTypes.shape({
      canWrite: PropTypes.bool.isRequired,
      canAccessTracksSplits: PropTypes.bool.isRequired,
      isOwner: PropTypes.bool.isRequired,
      providerId: PropTypes.string,
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    form: PropTypes.object.isRequired,
    formDisabled: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formValues: PropTypes.object.isRequired,
    getASIAction: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    panelData: PropTypes.oneOfType([pth.track, pth.album]).isRequired,
    rightsTypes: PropTypes.arrayOf(pth.rightsType).isRequired,
    t: PropTypes.func.isRequired,
    territories: PropTypes.arrayOf(pth.territory).isRequired,
  };

  isMaster = () => {
    const { name } = this.props;

    return name === 'artists_master_ownerships' || name === 'master_ownerships';
  };

  isPublishing = () => {
    const { name } = this.props;

    return name === 'artists_publishing_ownerships' || name === 'publishing_ownerships';
  };

  getOwnershipEntity = () => {
    const { name } = this.props;

    switch (name) {
      case 'master_ownerships':
        return 'label';

      case 'publishing_ownerships':
        return 'publisher';

      case 'artists_master_ownerships':
      case 'artists_publishing_ownerships':
      default:
        return 'artist';
    }
  };

  getTypeOfRights = () => {
    const { name } = this.props;

    switch (name) {
      case 'artists_master_ownerships':
        return 'master-artist';

      case 'artists_publishing_ownerships':
        return 'publishing-artist';

      case 'master_ownerships':
        return 'master-label';

      case 'publishing_ownerships':
      default:
        return 'publishing-publisher';
    }
  };

  renderEntityInputs = (fields, fieldName, index) => {
    const {
      documentRights,
      formDisabled,
      formValues,
      getASIAction,
      i18n: { language },
      name,
      panelData,
      rightsTypes,
      t,
    } = this.props;

    const ownershipEntity = this.getOwnershipEntity();

    const rightsTypesNames = {};

    rightsTypes.forEach((rightsType) => {
      rightsTypesNames[rightsType.id] = getNameWithFallback(rightsType.names, language);
    });

    if (ownershipEntity !== 'artist') {
      return (
        <Fragment>
          <OwnershipEntityInputs withActions={documentRights.canWrite}>
            <Field
              name={`${fieldName}.${ownershipEntity}`}
              component={MewoInput}
              use={ApiSelectInput}
              type="text"
              label={rightsTypesNames[_get(formValues, `${fieldName}.rights_type`)]}
              entity={`meta/${ownershipEntity}s`}
              getEntityLabel={getDocName(ownershipEntity)}
              getSearchEntityLabel={getSearchName(ownershipEntity)}
              onTheFlyEnabled={documentRights.isOwner}
              onTheFlyField={`${ownershipEntity}_name`}
              listAdditionalQuery={documentRights.providerId
                ? `&provider_id=${documentRights.providerId}`
                : ''}
              itemAction={getASIAction(`${fieldName}.${ownershipEntity}`, ownershipEntity)}
              fullWidth
              tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.${ownershipEntity}_tooltip`)}
              validate={required}
              context="autosave"
              disabled={formDisabled}
            />
          </OwnershipEntityInputs>
          {documentRights.canWrite && (
            <DeleteItemButton
              onClick={() => fields.remove(index)}
              disabled={formDisabled}
            >
              {t('common:form.remove')}
            </DeleteItemButton>
          )}
        </Fragment>
      );
    }

    return (
      <MetaDisplayArtist
        artistLabel={rightsTypesNames[_get(formValues, `${fieldName}.rights_type`)]}
        documentRights={documentRights}
        entity={entity}
        fieldName={fieldName}
        fields={fields}
        formDisabled={formDisabled}
        formValues={formValues}
        getASIAction={getASIAction}
        index={index}
        name={name}
        panelData={panelData}
      />
    );
  };

  renderSplits = (fieldName) => {
    const { documentRights, name, formDisabled, t } = this.props;

    if (!documentRights.canAccessTracksSplits) {
      return (
        <Field
          name={`${fieldName}.chain_of_title`}
          component={MewoInput}
          use={Input}
          type="text"
          label={t(`pages:meta.${entity}s.panel.${name}.chain_of_title`)}
          tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.chain_of_title_tooltip`)}
          validate={maxLength(1)}
          context="autosave"
          disabled={formDisabled}
        />
      );
    }

    if (this.isPublishing()) {
      return (
        <InputQuadruple>
          <Field
            name={`${fieldName}.chain_of_title`}
            component={MewoInput}
            use={Input}
            type="text"
            label={t(`pages:meta.${entity}s.panel.${name}.chain_of_title`)}
            tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.chain_of_title_tooltip`)}
            validate={maxLength(1)}
            context="autosave"
            disabled={formDisabled}
          />
          <Field
            name={`${fieldName}.mechanical_percentage`}
            component={MewoInput}
            use={Input}
            type="number"
            label={t(`pages:meta.${entity}s.panel.${name}.mechanical_percentage`)}
            tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.mechanical_percentage_tooltip`)}
            validate={composeValidators(mustBeNumber, minNumber(0), maxNumber(100))}
            context="autosave"
            disabled={formDisabled}
          />
          <Field
            name={`${fieldName}.performing_percentage`}
            component={MewoInput}
            use={Input}
            type="number"
            label={t(`pages:meta.${entity}s.panel.${name}.performing_percentage`)}
            tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.performing_percentage_tooltip`)}
            validate={composeValidators(mustBeNumber, minNumber(0), maxNumber(100))}
            context="autosave"
            disabled={formDisabled}
          />
          <Field
            name={`${fieldName}.sync_percentage`}
            component={MewoInput}
            use={Input}
            type="number"
            label={t(`pages:meta.${entity}s.panel.${name}.sync_percentage`)}
            tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.sync_percentage_tooltip`)}
            validate={composeValidators(mustBeNumber, minNumber(0), maxNumber(100))}
            context="autosave"
            disabled={formDisabled}
          />
        </InputQuadruple>
      );
    }

    return (
      <InputTriple>
        <Field
          name={`${fieldName}.chain_of_title`}
          component={MewoInput}
          use={Input}
          type="text"
          label={t(`pages:meta.${entity}s.panel.${name}.chain_of_title`)}
          tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.chain_of_title_tooltip`)}
          validate={maxLength(1)}
          context="autosave"
          disabled={formDisabled}
        />
        <Field
          name={`${fieldName}.ownership_percentage`}
          component={MewoInput}
          use={Input}
          type="number"
          label={t(`pages:meta.${entity}s.panel.${name}.ownership_percentage`)}
          tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.ownership_percentage_tooltip`)}
          validate={composeValidators(mustBeNumber, minNumber(0), maxNumber(100))}
          context="autosave"
          disabled={formDisabled}
        />
        <Field
          name={`${fieldName}.sync_percentage`}
          component={MewoInput}
          use={Input}
          type="number"
          label={t(`pages:meta.${entity}s.panel.${name}.sync_percentage`)}
          tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.sync_percentage_tooltip`)}
          validate={composeValidators(mustBeNumber, minNumber(0), maxNumber(100))}
          context="autosave"
          disabled={formDisabled}
        />
      </InputTriple>
    );
  };

  renderOwnershipAdder = () => {
    const { form, formDisabled, i18n: { language }, name, rightsTypes, t } = this.props;

    const ownershipEntity = this.getOwnershipEntity();
    const typeOfRights = this.getTypeOfRights();

    return (
      <StyledDropdown
        options={rightsTypes
          .filter(rightsType => rightsType.type === typeOfRights)
          .map(rightsType => ({
            name: rightsType.id,
            label: getNameWithFallback(rightsType.names, language),
            action: val => form.mutators.push(name, {
              [ownershipEntity]: {
                value: null,
                label: '',
                search: '',
              },
              ...(ownershipEntity === 'artist' ? {
                alias: '',
              } : {}),
              rights_type: val,
              ...(this.isPublishing() ? {
                mechanical_percentage: 100,
                performing_percentage: 100,
              } : {}),
              ...(this.isMaster() ? {
                ownership_percentage: 100,
              } : {}),
              sync_percentage: 100,
              chain_of_title: '',
              territories: ['WW'],
            }),
          }))}
        togglerElement={(
          <AddItemBtn disabled={formDisabled}>
            {t(`pages:meta.${entity}s.panel.${name}.add`)}
          </AddItemBtn>
        )}
      />
    );
  };

  render() {
    const { documentRights, formDisabled, formValues, name, t, territories } = this.props;

    const ownershipEntity = this.getOwnershipEntity();

    const countryOptions = territories
      .filter(({ code }) => code !== 'WW')
      .map(({ code, name: territoryName }) => ({ label: territoryName, value: code }));

    return (
      <Fragment>
        <FieldArray name={name}>
          {({ fields }) => fields.map((fieldName, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SectionBox key={index}>
              <InputItemBox>
                {this.renderEntityInputs(fields, fieldName, index)}
              </InputItemBox>
              {!!_get(formValues, `${fieldName}.${ownershipEntity}.value`) && (
                <InputWrapper>
                  {this.renderSplits(fieldName)}
                  <Field
                    name={`${fieldName}.territories`}
                    component={MewoInput}
                    use={SelectInput}
                    type="text"
                    label={t(`pages:meta.${entity}s.panel.${name}.territories`)}
                    tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.territories_tooltip`)}
                    options={countryOptions}
                    searchable
                    multiple
                    selectAllHelper
                    selectAllLabel={t('components:meta_territories_select.worldwide')}
                    renderOptionsVariant="check"
                    renderValuesVariant="count"
                    countName={t('components:meta_territories_select.territory')}
                    countNamePlural={t('components:meta_territories_select.territories')}
                    countAll={t('components:meta_territories_select.worldwide')}
                    selectAllValue="WW"
                    context="autosave"
                    disabled={formDisabled}
                  />
                </InputWrapper>
              )}
            </SectionBox>
          ))}
        </FieldArray>
        {/** Maximum for ownerships is always 100 items */}
        {(documentRights.canWrite
          && _get(formValues, name, []).length < 100)
          && this.renderOwnershipAdder()}
      </Fragment>
    );
  }
}

export default withTranslation('pages')(MetaTrackOwnerships);
