// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

// Components
import MonthlyLine from '../../../../../other/charts/monthlyLine';

// Helpers
import * as pth from '../../proptypes';

// Styles
import {
  Inline,
  UserSingleStatBox,
  SearchLineStatBox,
  StatTitle,
  StatNumberValue,
  TitleAsSeparator,
} from '../../../../modo/stats/styles';

// =============================
// Component
// =============================

class SearchStats extends Component {
  static propTypes = {
    searchData: pth.statsSearch.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { searchData, t } = this.props;

    const searchEvolutionData = _get(searchData, 'by_month', [])
      .filter(o => !!(o.year && o.month))
      .slice(0, 6) // 6 months max
      .map(o => ({
        x: `${o.year}-${o.month}-01`,
        y: o.count,
      }));

    return (
      <Fragment>
        <TitleAsSeparator mt="3rem" mb="2rem">
          {t('pages:modo.stats.search')}
        </TitleAsSeparator>
        <Inline>
          <UserSingleStatBox>
            <StatTitle mb="1.5rem">{t('pages:modo.stats.search_total')}</StatTitle>
            <StatNumberValue>{_get(searchData, 'total', 0)}</StatNumberValue>
          </UserSingleStatBox>
        </Inline>
        <Inline ml="4rem">
          <UserSingleStatBox>
            <StatTitle mb="1.5rem">{t('pages:modo.stats.search_average_per_day')}</StatTitle>
            <StatNumberValue>{_get(searchData, 'average_per_day', 0)}</StatNumberValue>
          </UserSingleStatBox>
        </Inline>
        <SearchLineStatBox width="72.4rem" mt="2rem">
          <StatTitle mb="1.5rem">{t('pages:modo.stats.search_evolution')}</StatTitle>
          <MonthlyLine
            charts={[
              {
                id: 'user-search-evolution',
                data: searchEvolutionData,
              },
            ]}
            maxValue={Math.max(..._get(searchData, 'by_month', []).map(o => o.count), 0)}
          />
        </SearchLineStatBox>
      </Fragment>
    );
  }
}

export default SearchStats;
