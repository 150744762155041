// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons } from '@mewo/components';

import { svgHoverCirclePath } from '../../../../helpers/svg';

// =============================
// Styles
// =============================

export const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity ${({ theme }) => theme.transition.medium};

  &:hover {
    opacity: 1;
  }

  ${({ isCurrent }) => isCurrent && css`
    opacity: 1;
  `};
`;

export const PlayIcon = styled(Icons.PlayOutline).attrs({
  width: '6rem',
})`
  ${svgHoverCirclePath('#fff', '#7f7f7f')};
`;

export const PauseIcon = styled(Icons.PauseOutline).attrs({
  width: '6rem',
})`
  ${svgHoverCirclePath('#fff', '#7f7f7f')};
`;
