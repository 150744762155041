// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import _get from 'lodash/get';

import { withTranslation } from '../../../config/i18n';

import MewoInput from '../mewoInput';
import ApiSelectInput from '../../containers/inputs/apiSelectInput';
import Input from '../inputs/input';
import AddressFetcher from '../../faac/withAddress';

import { META_PEOPLE_PANEL } from '../../../store/constants/SidePanelTypes';

import * as pth from '../../../helpers/proptypes';

import { InputWrapper } from '../../layouts/global.styles';
import {
  ContactBox,
  ContactInnerBox,
  AddContactBtn,
  DeleteButton,
  NameRole,
  Info,
  ContactDisplay, EmailIcon, LocationIcon, PhoneIcon,
} from './styles';

// =============================
// Component
// =============================

class ContactsInput extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(pth.people).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    form: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formValues: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    documentRights: PropTypes.shape({
      canWrite: PropTypes.bool.isRequired,
      canAccessContactInfos: PropTypes.bool.isRequired,
      canAccessNotes: PropTypes.bool.isRequired,
      canAccessTracksSplits: PropTypes.bool.isRequired,
      isOwner: PropTypes.bool.isRequired,
      providerName: PropTypes.string,
    }).isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      data,
      form,
      formValues,
      name,
      disabled,
      documentRights,
      triggerPanelAutosaveCheck,
      t,
    } = this.props;

    if (!documentRights.isOwner) {
      return (
        <div>
          {data.map(contact => (
            <ContactDisplay key={contact.id}>
              <NameRole>
                {contact.full_name}
                &nbsp;-&nbsp;
                {contact.role}
              </NameRole>
              {contact.email && (
                <Info>
                  <EmailIcon />
                  {contact.email}
                </Info>
              )}
              {contact.address
                && (
                <AddressFetcher placeId={contact.address}>
                  {address => !!address && (
                  <Info>
                    <LocationIcon />
                    {address}
                  </Info>
                  )}
                </AddressFetcher>
                )}
              {contact.phone_number && (
                <Info>
                  <PhoneIcon />
                  {contact.phone_number}
                </Info>
              )}
              {contact.secondary_phone_number && (
                <Info>
                  <PhoneIcon />
                  {contact.secondary_phone_number}
                </Info>
              )}
            </ContactDisplay>
          ))}
        </div>
      );
    }

    const getPeopleASIAction = (index) => {
      if (!_get(data, `${index}.id`)) return null;

      return () => triggerPanelAutosaveCheck(
        'open',
        [META_PEOPLE_PANEL, _get(data, `${index}.id`)],
      );
    };

    return (
      <InputWrapper>
        <FieldArray name={name}>
          {({ fields }) => fields.map((fieldName, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ContactBox key={index}>
              <ContactInnerBox>
                <Field
                  name={`${fieldName}.people`}
                  component={MewoInput}
                  use={ApiSelectInput}
                  label={t('components:contacts_input.contact')}
                  entity="meta/peoples"
                  type="text"
                  getEntityLabel={doc => doc.full_name}
                  getSearchEntityLabel={doc => doc.full_name}
                  itemAction={getPeopleASIAction(index)}
                  onTheFlyEnabled
                  onTheFlyField="full_name"
                  disabled={disabled}
                  context="autosave"
                />
                <DeleteButton
                  onClick={() => fields.remove(index)}
                  disabled={disabled}
                >
                  {t('common:form.remove')}
                </DeleteButton>
              </ContactInnerBox>
              {_get(formValues, `${name}.${index}.people`) && (
                <Field
                  name={`${fieldName}.role`}
                  component={MewoInput}
                  use={Input}
                  type="text"
                  label={t('components:contacts_input.role')}
                  disabled={disabled}
                  context="autosave"
                  optional
                />
              )}
            </ContactBox>
          ))}
        </FieldArray>
        {/** Maximum for this item is always 100 items */}
        {_get(formValues, name, []).length < 100 && (
          <AddContactBtn
            onClick={() => form.mutators.push(name, {
              people: {
                value: null,
                label: '',
                search: '',
              },
            })}
            type="button"
            disabled={disabled}
          >
            {t('components:contacts_input.add_contact')}
          </AddContactBtn>
        )}
      </InputWrapper>
    );
  }
}

export default withTranslation(['common', 'components'])(ContactsInput);
