// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';

// Hoc
import { withTranslation } from '../../../../config/i18n';

// Components
import Link from '../../link';

// Styles
import {
  Wrapper,
  Header,
  Title,
  Switchers,
  Switcher,
  LineHeader,
  Line,
  Column,
  NoData,
  SeeMore,
} from './styles';

// =============================
// Components
// =============================

class ListChart extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    header: PropTypes.shape({
      value: PropTypes.string,
      count: PropTypes.string,
    }).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        count: PropTypes.number,
        is_link: PropTypes.bool,
      }),
    ).isRequired,
    width: PropTypes.number,
    maxItemsFolded: PropTypes.number.isRequired,
    disablePeriods: PropTypes.bool,
    currentPeriod: PropTypes.string,
    isLoadingPeriod: PropTypes.bool,
    getByPeriod: PropTypes.func,
    t: PropTypes.func.isRequired,
    transformDate: PropTypes.func,
  };

  static defaultProps = {
    width: 46,
    disablePeriods: false,
    currentPeriod: 'all',
    isLoadingPeriod: false,
    getByPeriod: null,
    transformDate: null,
  };

  state = {
    seeMore: false,
  };

  toggleMore = () => {
    const { seeMore } = this.state;
    this.setState({ seeMore: !seeMore });
  };

  loadPeriod = (period) => {
    const { disablePeriods, isLoadingPeriod, currentPeriod, getByPeriod } = this.props;

    if (!disablePeriods && !isLoadingPeriod && period !== currentPeriod) {
      getByPeriod(period);
    }
  };

  render() {
    const {
      title,
      data,
      header,
      width,
      maxItemsFolded,
      disablePeriods,
      currentPeriod,
      t,
      transformDate,
    } = this.props;
    const { seeMore } = this.state;

    const selectedData = seeMore ? data : data.slice(0, maxItemsFolded);

    return (
      <Wrapper width={`${width}rem`}>
        <Header>
          <Title>{title}</Title>
          {!disablePeriods && (
            <Switchers>
              <Switcher isCurrent={currentPeriod === 'all'} onClick={() => this.loadPeriod('all')}>
                {t('components:stats.all')}
              </Switcher>
              <Switcher
                isCurrent={currentPeriod === 'quarter'}
                onClick={() => this.loadPeriod('quarter')}
              >
                {t('components:stats.quarter')}
              </Switcher>
              <Switcher
                isCurrent={currentPeriod === 'month'}
                onClick={() => this.loadPeriod('month')}
              >
                {t('components:stats.month')}
              </Switcher>
              <Switcher isCurrent={currentPeriod === 'day'} onClick={() => this.loadPeriod('day')}>
                {t('components:stats.day')}
              </Switcher>
            </Switchers>
          )}
        </Header>
        <LineHeader>
          <Column width={header.date ? '40%' : '70%'}>
            {header.value}
          </Column>
          <Column width="30%">
            {header.count}
          </Column>
          {header.date && (
            <Column width="30%">
              {header.date}
            </Column>
          )}
        </LineHeader>
        {selectedData.map((l, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Line key={i} bgColor={(i + 1) % 2 === 0 ? '#f2f8fe' : '#fff'}>
            <Column width={l.date ? '40%' : '70%'}>
              {l.is_link ? (
                <Link href={l.value} target="_blank" rel="noopener noreferrer">
                  {l.value}
                </Link>
              ) : (
                l.value
              )}
            </Column>
            <Column width="30%">
              {l.count}
            </Column>
            {l.date && (
              <Column width="30%">
                {transformDate ? transformDate(l.date) : l.date}
              </Column>
            )}
          </Line>
        ))}
        {!data.length && <NoData>{t('components:stats.no_data')}</NoData>}
        {data.length > maxItemsFolded && (
          <SeeMore onClick={this.toggleMore}>
            {seeMore ? t('components:stats.see_less') : t('components:stats.see_more')}
          </SeeMore>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation(['components'])(ListChart);
