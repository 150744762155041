// =============================
// Imports
// =============================

import { composeValidators, maxNumber, minNumber, mustBeNumber, required, maxLength } from '../index';

// =============================
// Validator
// =============================

const validator = {
  title: required,
  version: required,
  music_type: required,
  track_number: composeValidators(mustBeNumber, minNumber(1), maxNumber(500)),
  attachment_name: required,
  grouping: composeValidators(maxLength(40)),
};

export default validator;
