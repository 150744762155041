// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConditionalWrapper } from '@mewo/components';
import Clipboard from 'react-clipboard.js';

// Config
import { withTranslation } from '../../../../config/i18n';

// Components
import Tooltip from '../../../presentationals/tooltip';

// Actions
import { setNotification as setNotificationBase } from '../../../../store/actions/NotificationActions';

// Constants
import * as ntfs from '../../../../store/constants/NotificationTypes';

// Styles
import { ShareLink } from '../common.styles';

// =============================
// Component
// =============================

const CopyLink = ({
  disableMargin,
  link,
  content,
  tooltipContent,
  notificationContent,
  setNotification,
  Wrapper,
  t,
}) => {
  const MainWrapper = Wrapper || ShareLink;

  return (
    <ConditionalWrapper
      Wrapper={(
        <Tooltip
          interactive
          placement="top-start"
          content={tooltipContent}
        />
      )}
      condition={!!tooltipContent}
    >
      <MainWrapper
        disableMargin={disableMargin}
        onClick={() => setNotification({
          notificationKey: ntfs.COPY_LINK_SUCCESS,
          message: notificationContent || t('components:copy_link.copy_success'),
          type: 'success',
          duration: 12000,
        })}
      >
        <Clipboard component="div" data-clipboard-text={link}>
          {content}
        </Clipboard>
      </MainWrapper>
    </ConditionalWrapper>
  );
};

CopyLink.propTypes = {
  Wrapper: PropTypes.node,
  disableMargin: PropTypes.bool,
  link: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  tooltipContent: PropTypes.string,
  notificationContent: PropTypes.string,
  setNotification: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CopyLink.defaultProps = {
  Wrapper: null,
  disableMargin: false,
  tooltipContent: null,
  notificationContent: null,
};

export default compose(
  withTranslation(['components']),
  connect(() => ({}), {
    setNotification: setNotificationBase,
  }),
)(CopyLink);
