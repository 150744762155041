// =============================
// Imports
// =============================

// External Dependencies
import _findIndex from 'lodash/findIndex';

// Actions
import {
  open as openPlayer,
  fulfillRequest as fulfillPlayerRequest,
  seek,
  setCurrentTrack,
} from '../actions/PlayerActions';

// Constants
import * as acts from '../constants/ActionTypes';
import * as requests from '../constants/PlayerRequests';

// =============================
// Middleware
// =============================

const playerMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.PLAYER_REQUEST_SET_SEEKING_TIME:
    case action.type === acts.PLAYER_REQUEST_PLAY:
    case action.type === acts.SET_PLAYER_CURRENT_TRACK: {
      const { isOpened } = store.getState().player;

      if (!isOpened) {
        store.dispatch(openPlayer());
      }

      return next(action);
    }

    case action.type === acts.PLAYER_REQUEST_GO_TO_PREV_TRACK: {
      const { trackListing, contextName, contextId, contextPosition } = store.getState().player;

      const reversedTrackListing = trackListing.slice(0, contextPosition).reverse();

      const prevTrackIndex = _findIndex(
        reversedTrackListing,
        track => track && track.audiofile.original,
      );

      if (prevTrackIndex !== -1) {
        store.dispatch(setCurrentTrack(
          reversedTrackListing[prevTrackIndex],
          contextName,
          contextId,
          contextPosition - prevTrackIndex - 1,
        ));
      } else {
        store.dispatch(seek(0));
      }

      store.dispatch(fulfillPlayerRequest(requests.PREV_TRACK));

      return next(action);
    }

    case action.type === acts.PLAYER_REQUEST_GO_TO_NEXT_TRACK: {
      const { trackListing, contextName, contextId, contextPosition } = store.getState().player;

      const nextTrackIndex = _findIndex(
        trackListing,
        track => track && track.audiofile.original,
        contextPosition + 1,
      );

      if (nextTrackIndex !== -1) {
        store.dispatch(setCurrentTrack(
          trackListing[nextTrackIndex],
          contextName,
          contextId,
          nextTrackIndex,
        ));
      }

      store.dispatch(fulfillPlayerRequest(requests.NEXT_TRACK));

      return next(action);
    }

    default:
      return next(action);
  }
};

export default playerMiddleware;
