/* eslint-disable implicit-arrow-linebreak */
// =============================
// Imports
// =============================

import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _countBy from 'lodash/countBy';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';

import { i18n } from '../config/i18n';

// =============================
// Validators
// =============================

export const required = value =>
  (_isNumber(value) || (value && !_isEmpty(value)) ? undefined : i18n.t('validation.required'));

export const mustBeLowercase = value =>
  (!value || (value && value === value.toLowerCase()) ? undefined : i18n.t('validation.must_be_lowercase'));

export const minLength = min => value =>
  (!value || value.length >= min ? undefined : i18n.t('validation.min_length', { min }));

export const maxLength = max => value =>
  (!value || value.length <= max ? undefined : i18n.t('validation.max_length', { max }));

export const mustBeNumber = value =>
  (value && typeof +value !== 'number' ? i18n.t('validation.must_be_number') : undefined);

export const minNumber = min => value =>
  (value && (typeof +value !== 'number' || value < min) ? i18n.t('validation.must_be_min_number', { min }) : undefined);

export const maxNumber = max => value =>
  (value && (typeof +value !== 'number' || value > max) ? i18n.t('validation.must_be_max_number', { max }) : undefined);

export const mustBeEmail = value =>
  (!value || (value && isEmail(value)) ? undefined : i18n.t('validation.must_be_email'));

export const mustBeComplexPassword = value =>
  (!value || (value && !!value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) ? undefined : i18n.t('validation.must_be_complex_password'));

export const mustBeSlug = (value) => {
  if (!value) return undefined;
  const validSlug = !!value.match(/^[a-zA-Z0-9_?&=-][a-zA-Z0-9_/?&=-]*$/);
  return validSlug ? undefined : i18n.t('validation.must_be_slug');
};

export const mustBeSlugOrUrl = (value) => {
  if (!value) return undefined;

  const validUrl = isURL(value, {
    require_protocol: true,
    require_valid_protocol: true,
    protocols: ['http', 'https'],
  });

  const validSlug = !!value.match(/^[a-zA-Z0-9_?&=-][a-zA-Z0-9_/?&=-]*$/);

  return validUrl || validSlug ? undefined : i18n.t('validation.must_be_slug_or_url');
};

export const mustBeUrlWithProtocol = (value) => {
  if (!value) return undefined;

  const validUrl = isURL(value, {
    require_protocol: true,
    require_valid_protocol: true,
    protocols: ['http', 'https'],
  });

  return validUrl ? undefined : i18n.t('validation.must_be_url_with_protocol');
};

export const mustBeUrlWithoutProtocol = value =>
  (!value || (value && isURL(value) && value.indexOf('://') === -1)
    ? undefined
    : i18n.t('validation.must_be_url_without_protocol'));

export const mustBeVideoUrl = (value) => {
  if (!value) return undefined;

  const validLink = !!value.match(/vimeo.com|youtube.com|youtu.be/);

  return validLink ? undefined : i18n.t('validation.must_be_video_url');
};

export const mustBeDomainName = value =>
  (!value || (isURL(value) && value.indexOf('://') === -1 && !value.match(/^www./))
    ? undefined
    : i18n.t('validation.must_be_domain_name'));

export const mustExcludeAtSign = value =>
  (!value || (value && value.indexOf('@') === -1)
    ? undefined
    : i18n.t('validation.must_exclude_at_sign'));

export const mustBeEqualTo = (compareVal, fieldName) => value =>
  (value === compareVal ? undefined : i18n.t('validation.must_be_equal_to', { fieldName }));

export const mustBeUniqueInList = compareValues => value =>
  ((_countBy(compareValues)[value] || 0) < 1 ? undefined : i18n.t('validation.must_be_unique'));

export const validateMewoTranslationsValues = validator => values =>
  (values || []).reduce((error, { value }) => error || validator(value), undefined);

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
