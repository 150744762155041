// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import { Button } from '@mewo/components';

// =============================
// Styled Components
// =============================

export const Wrapper = styled.div`
  bottom: 8rem;
  left: 3rem;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.notifications};
`;

export const ClearNotificationsButton = styled(Button).attrs({
  color: 'secondary',
  size: 'small',
})`
  box-shadow: 0 0.2rem 2rem 0 rgba(0, 0, 0, 0.1);
  margin-top: 1.2rem;
`;
