// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { StyledApiSelectInput } from './styles';

// =============================
// Component
// =============================

class ApiSelectInput extends Component {
  static propTypes = {
    /** User token used for api calls */
    userToken: PropTypes.string.isRequired,
    /** On the fly field */
    onTheFlyField: PropTypes.string,
    /** Form onChange function */
    onChange: PropTypes.func,
    /** Translation function */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onTheFlyField: '',
    onChange: null,
  };

  render() {
    const {
      onTheFlyField,
      userToken,
      t,
      ...rest
    } = this.props;

    return (
      <StyledApiSelectInput
        {...rest}
        dropdownClassName="ignore-react-onclickoutside"
        lang={{
          Label: {
            optional: t('components:mewo_input.Label.optional'),
          },
        }}
        onTheFlyField={onTheFlyField}
        sessionToken={userToken}
      />
    );
  }
}

export default ApiSelectInput;
