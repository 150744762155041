// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import Icon from './icons';
import Customisations from './icons/Customisations';
import GeneralSettings from './icons/GeneralSettings';
import Integrations from './icons/Integrations';
import Legal from './icons/Legal';
import OtherCustomisations from './icons/OtherCustomisations';
import Users from './icons/Users';
import UsersRights from './icons/UsersRights';
import Analytics from './icons/Analytics';
import Menus from './icons/Menus';
import Pages from './icons/Pages';
import Suggestions from './icons/Suggestions';

import { Wrapper, CategoryWrapper, Category, StyledLink } from './styles';

// =============================
// Component
// =============================

class ModoVerticalMenu extends Component {
  static propTypes = {
    /** Current modo id. */
    modoId: PropTypes.string.isRequired,
    /** Currently active page. */
    activePage: PropTypes.string.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
  };

  checkActive = (path) => {
    const { activePage } = this.props;

    const withoutQuery = activePage.split('?')[0];

    return withoutQuery === path;
  };

  render() {
    const { modoId, t } = this.props;

    return (
      <Wrapper>
        <CategoryWrapper>
          <Category>{t('components:modo_menu.platform_category')}</Category>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/general`)}
            href="/modo/[modoId]/general"
            nextAs={`/modo/${modoId}/general`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/general`)} IconComponent={GeneralSettings} />
            {t('components:modo_menu.general_settings')}
          </StyledLink>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/integrations`)}
            href="/modo/[modoId]/integrations"
            nextAs={`/modo/${modoId}/integrations`}
          >
            <Icon
              active={this.checkActive(`/modo/${modoId}/integrations`)}
              IconComponent={Integrations}
            />
            {t('components:modo_menu.integrations')}
          </StyledLink>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/legal`)}
            href="/modo/[modoId]/legal"
            nextAs={`/modo/${modoId}/legal`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/legal`)} IconComponent={Legal} />
            {t('components:modo_menu.legal')}
          </StyledLink>
        </CategoryWrapper>

        <CategoryWrapper>
          <Category>{t('components:modo_menu.customisations_category')}</Category>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/customisations`)}
            href="/modo/[modoId]/customisations"
            nextAs={`/modo/${modoId}/customisations`}
          >
            <Icon
              active={this.checkActive(`/modo/${modoId}/customisations`)}
              IconComponent={Customisations}
            />
            {t('components:modo_menu.customisations')}
          </StyledLink>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/othercustomisations`)}
            href="/modo/[modoId]/othercustomisations"
            nextAs={`/modo/${modoId}/othercustomisations`}
          >
            <Icon
              active={this.checkActive(`/modo/${modoId}/othercustomisations`)}
              IconComponent={OtherCustomisations}
            />
            {t('components:modo_menu.other_customisations')}
          </StyledLink>
        </CategoryWrapper>

        <CategoryWrapper>
          <Category>{t('components:modo_menu.users_category')}</Category>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/users`)}
            href="/modo/[modoId]/users"
            nextAs={`/modo/${modoId}/users`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/users`)} IconComponent={Users} />
            {t('components:modo_menu.users')}
          </StyledLink>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/usersrights`)}
            href="/modo/[modoId]/usersrights"
            nextAs={`/modo/${modoId}/usersrights`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/usersrights`)} IconComponent={UsersRights} />
            {t('components:modo_menu.users_rights')}
          </StyledLink>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/stats`)}
            href="/modo/[modoId]/stats"
            nextAs={`/modo/${modoId}/stats`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/stats`)} IconComponent={Analytics} />
            {t('components:modo_menu.stats')}
          </StyledLink>
        </CategoryWrapper>

        <CategoryWrapper>
          <Category>{t('components:modo_menu.pages_category')}</Category>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/pages`)}
            href="/modo/[modoId]/pages"
            nextAs={`/modo/${modoId}/pages`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/pages`)} IconComponent={Pages} />
            {t('components:modo_menu.pages')}
          </StyledLink>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/menus`)}
            href="/modo/[modoId]/menus"
            nextAs={`/modo/${modoId}/menus`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/menus`)} IconComponent={Menus} />
            {t('components:modo_menu.menus')}
          </StyledLink>
          <StyledLink
            active={this.checkActive(`/modo/${modoId}/suggestions`)}
            href="/modo/[modoId]/suggestions"
            nextAs={`/modo/${modoId}/suggestions`}
          >
            <Icon active={this.checkActive(`/modo/${modoId}/suggestions`)} IconComponent={Suggestions} />
            {t('components:modo_menu.suggestions')}
          </StyledLink>
        </CategoryWrapper>
      </Wrapper>
    );
  }
}

export default withTranslation(['components'])(ModoVerticalMenu);
