// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { Wrapper, Content } from './styles';

// =============================
// Component
// =============================

function Footer({ children, isAdmin }) {
  return (
    <Wrapper>
      <Content>{children}</Content>
      {isAdmin && <Content>Admin mode</Content>}
    </Wrapper>
  );
}

Footer.propTypes = {
  /** Footer content */
  children: PropTypes.node.isRequired,
  /** Admin mode activate */
  isAdmin: PropTypes.bool.isRequired,
};

export default Footer;
