// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Legal = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 100 100"
    {...rest}
  >
    <path d="M33.8,12.3c-5.2,0-9.5,4.3-9.5,9.5v46.8c0,5.2,4.3,9.5,9.5,9.5h3.8l-0.2,7.7c0,0.8,0.4,1.4,1.1,1.7   c0.2,0.1,0.5,0.1,0.7,0.1c0.5,0,0.9-0.2,1.2-0.5l4.6-3.9l4.5,3.9c0.5,0.5,1.3,0.6,2,0.4c0.7-0.3,1.1-1,1.1-1.8l-0.2-7.7h13.8   c5.2,0,9.5-4.3,9.5-9.5V32.1h0c0-0.4-0.2-0.8-0.5-1.1L55.9,12.7c-0.3-0.3-0.7-0.4-1-0.4v0H33.8z M45,70.1c-4.2,0-7.7-3.5-7.7-7.7   s3.5-7.7,7.7-7.7s7.7,3.5,7.7,7.7S49.2,70.1,45,70.1z M46.3,80.4c-0.7-0.7-1.8-0.7-2.5-0.1l-3.3,2.9l0.3-11   c1.3,0.6,2.7,0.9,4.2,0.9c1.5,0,2.9-0.3,4.2-0.9l0.3,11L46.3,80.4z M56.3,17.3l14.1,13.3H56.3V17.3z M72.7,33.6v35   c0,3.6-2.9,6.5-6.5,6.5H52.3l-0.1-4.8c2.2-2,3.5-4.8,3.5-7.9c0-5.9-4.8-10.7-10.7-10.7s-10.7,4.8-10.7,10.7c0,3.1,1.4,6,3.5,7.9   l-0.1,4.8h-3.9c-3.6,0-6.5-2.9-6.5-6.5V21.8c0-3.6,2.9-6.5,6.5-6.5h19.5v16.8c0,0.8,0.7,1.5,1.5,1.5H72.7z" />
    <path d="M36.3,35.6h7.5c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5h-7.5c-0.8,0-1.5,0.7-1.5,1.5S35.5,35.6,36.3,35.6z" />
    <path d="M34.8,44.2c0,0.8,0.7,1.5,1.5,1.5h20.5c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H36.3C35.5,42.7,34.8,43.4,34.8,44.2z" />
  </Svg>
));

Legal.displayName = 'Legal';

Legal.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Legal.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '2rem',
};

export default withTheme(Legal);
