// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Suggestions = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 16.933333 16.933333"
    {...rest}
  >
    <path transform="translate(0,-280.06669)" d="m 6.9300479,281.99546 -1.2211491,3.37446 -3.5901924,0.11805 c -0.7360888,0.0244 -1.30554896,0.52879 -1.49998398,1.12753 -0.19461812,0.5993 -0.0290423,1.34094 0.55358978,1.79306 l 2.833077,2.20622 -0.9972757,3.45179 c -0.2044424,0.70789 0.10104,1.40275 0.6105769,1.77271 0.509537,0.36996 1.2646602,0.4441 1.8744712,0.0305 l 2.9735097,-2.01286 2.9735097,2.01286 c 0.609811,0.41357 1.364935,0.33943 1.874472,-0.0305 0.509537,-0.36996 0.815018,-1.06482 0.610576,-1.77271 l -0.997275,-3.45179 2.833077,-2.20622 c 0.582631,-0.45212 0.748207,-1.19376 0.553589,-1.79306 -0.194435,-0.59874 -0.763895,-1.1031 -1.499983,-1.12753 l -3.590157,-0.11805 -1.221189,-3.37446 c -0.228017,-0.63225 -0.8260201,-1.06269 -1.5121241,-1.07914 -0.686104,-0.0164 -1.3234877,0.42021 -1.561119,1.07914 z m 2.0759615,0.35821 1.3453096,3.71231 c 0.07301,0.20339 0.262339,0.34197 0.478285,0.35006 l 3.950398,0.13026 c 0.29577,0.009 0.455309,0.17571 0.527131,0.39687 0.07182,0.22117 0.04044,0.44951 -0.193349,0.63094 l -3.120014,2.42602 c -0.17045,0.13289 -0.242964,0.35607 -0.183172,0.56377 l 1.097003,3.79575 c 0.08201,0.28395 -0.02569,0.48832 -0.213701,0.62483 -0.188014,0.1365 -0.414812,0.17606 -0.659424,0.0102 l -3.2706574,-2.21436 c -0.1793982,-0.12173 -0.4148967,-0.12173 -0.5942949,0 l -3.270657,2.21436 c -0.244612,0.16589 -0.4714102,0.12633 -0.6594232,-0.0102 -0.188013,-0.13651 -0.2957094,-0.34088 -0.2137019,-0.62483 l 1.0969985,-3.79575 c 0.059792,-0.2077 -0.012723,-0.43088 -0.1831731,-0.56377 l -3.1200528,-2.42602 c -0.2337884,-0.18143 -0.2651718,-0.40977 -0.1933493,-0.63094 0.071822,-0.22116 0.2313611,-0.38742 0.5271314,-0.39687 l 3.9504376,-0.13026 c 0.2159463,-0.008 0.40528,-0.14667 0.4782852,-0.35006 l 1.3453092,-3.71231 c 0.084494,-0.2343 0.3045954,-0.38457 0.5483625,-0.37857 0.2437672,0.006 0.4493692,0.15411 0.5303186,0.37857 z" />
  </Svg>
));

Suggestions.displayName = 'Suggestions';

Suggestions.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Suggestions.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '1.6rem',
};

export default withTheme(Suggestions);
