// =============================
// Imports
// =============================

// External dependencies
import styled, { css } from 'styled-components';
import { Div } from '@mewo/components';

// Components
import Input from '../../../../presentationals/inputs/input';
import SelectInput from '../../../../presentationals/inputs/selectInput';

// =============================
// Styles
// =============================

export const InputsWrapper = styled.div`
  text-align: center;
`;

export const InlineText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: inline-block;
`;

export const StyledSelectInput = styled(SelectInput)`
  display: inline-block;
  margin: 0.5rem 1rem;

  ${({ width }) => width
    && css`
      width: ${width};
    `};
`;

export const AutotaggingTitle = styled(Div)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 2rem;
  text-align: center;
`;

export const AutotaggingDesc = styled(Div)`
  ${({ theme, bold }) => bold
    && css`
      font-weight: ${theme.fontWeight.bold};
    `};
`;

export const MaiaCapacitiesDesc = styled.div`
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 3rem;
  line-height: 1.4;
  text-align: center;
`;

export const PlaylistAgentTracksNotice = styled.div`
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 2rem;
  line-height: 1.4;
  text-align: center;
`;

export const ConfirmInput = styled(Input)`
  width: 45rem;
  margin: 0 auto;
  margin-bottom: 4.8rem;
`;
