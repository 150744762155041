// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Analytics = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 24 24"
    {...rest}
  >
    <path d="M23.6,6.09v5.72a.6.6,0,0,1-1.2,0V7.54L14.91,15a1.82,1.82,0,0,1-2.58,0L9,11.67a.62.62,0,0,0-.44-.18h0a.62.62,0,0,0-.44.18L1.42,18.33a.6.6,0,0,1-.85-.85l6.66-6.66a1.8,1.8,0,0,1,1.29-.53h0a1.81,1.81,0,0,1,1.29.53l3.36,3.36a.62.62,0,0,0,.88,0l7.5-7.5H17.28a.6.6,0,0,1,0-1.2H23a.6.6,0,0,1,.6.6Z" />
  </Svg>
));

Analytics.displayName = 'Analytics';

Analytics.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Analytics.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '1.7rem',
};

export default withTheme(Analytics);
