// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons, Waveform } from '@mewo/components';

import { svgHoverCirclePath } from '../../../helpers/svg';

import { musicPlayerHeight } from './variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 8px 30px 0 ${({ theme }) => theme.colors.boxShadow};
  border-radius: 1rem 1rem 0 0;
  display: flex;
  padding: 1rem 3rem;
  position: fixed;
  width: calc(100% - 4rem);
  height: ${musicPlayerHeight};
  left: 2rem;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.sidePanel + 1};
`;

export const Cover = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 5rem;
  width: 5rem;
  margin-right: 2rem;

  ${({ placeholder, cover }) => placeholder
    && !cover
    && css`
      background-image: url(${placeholder});
    `};

  ${({ cover }) => cover
    && css`
      background-image: url(${cover});
      cursor: pointer;
    `};
`;

export const TitleArtistsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5rem;
  width: 25rem;
`;

export const TrackTitle = styled.div`
  color: #191919;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 0.4rem;
  line-height: 1.3;
  max-height: 3rem;
  overflow: auto;
`;

export const DisplayArtists = styled.span`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: #7f7f7f;
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const DisplayArtist = styled.div`
  cursor: pointer;
  display: inline;
  flex-shrink: 0;
  line-height: 1.2;

  &:hover {
    text-decoration: underline;
  }
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 12rem;
`;

export const PrevNext = styled.div`
  padding: 0.8rem;

  svg path {
    fill: ${({ theme }) => theme.colors.text};
  }

  ${({ disabled }) => (disabled
    ? css`
      pointer-events: none;

      svg path {
        opacity: 0.7;
      }
    `
    : css`
      &:hover {
        opacity: 0.7;
      }
    `)};
`;

export const PrevIcon = styled(PrevNext).attrs({
  as: Icons.Previous,
  width: '0.8rem',
})``;

export const NextIcon = styled(PrevNext).attrs({
  as: Icons.Next,
  width: '0.8rem',
})``;

export const PlayIcon = styled(Icons.PlayOutline).attrs({
  width: '3.5rem',
})`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const PauseIcon = styled(Icons.PauseOutline).attrs({
  width: '3.5rem',
})`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const WaveformContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 8rem;
  flex: 1 1 auto;
  width: 48rem;
`;

export const StyledWaveform = styled(Waveform).attrs(({ theme }) => ({
  color: theme.colors.primary,
  width: '100%',
}))``;

export const Timeline = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-right: 4rem;
`;

export const CurrentTime = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-right: 0.8rem;
`;

export const TrackTime = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Interactions = styled.div`
  align-items: center;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

export const SimilaritySearchIcon = styled(Icons.MagicWand).attrs({
  fill: '#191919',
  width: '1.6rem',
})`
  &:hover {
    svg path {
      fill-opacity: 0.7;
    }
  }
`;

export const AddToRefIcon = styled(Icons.Plus).attrs({
  fill: '#191919',
  width: '1.7rem',
})`
  &:hover {
    svg path {
      fill-opacity: 0.7;
    }
  }
`;

export const DownloadsIcon = styled(Icons.Download).attrs({
  fill: '#191919',
  width: '1.7rem',
})`
  &:hover {
    svg path {
      fill-opacity: 0.7;
    }
  }
`;

export const ClosePlayerIcon = styled(Icons.ClosePlayer).attrs({
  fill: '#191919',
  width: '1.1rem',
})`
  position: relative;
  top: -0.1rem;

  &:hover {
    svg path {
      fill-opacity: 0.7;
    }
  }
`;
