// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import Link from '../../other/link';

import { withTranslation } from '../../../config/i18n';

import { Wrapper, Title, Logo, Url, ActiveWrapper, ActiveIndicator } from './styles';

// =============================
// Helpers
// =============================

const formatUrl = (url, t) => {
  if (!url) return <Url>{t('components:modo_site_preview.unavailable_url')}</Url>;

  return <Url>{url}</Url>;
};

// =============================
// Component
// =============================

const ModoSitePreview = ({ active, className, logo, modoId, t, title, websiteUrl }) => (
  <Link href="/modo/[modoId]/general" nextAs={`/modo/${modoId}/general`}>
    <Wrapper className={className}>
      {logo && <Logo src={logo} />}
      <Title>{title}</Title>
      {formatUrl(websiteUrl, t)}
      <ActiveWrapper active={active}>
        <ActiveIndicator active={active} />
        {active ? t('common:misc.active') : t('common:misc.inactive')}
      </ActiveWrapper>
    </Wrapper>
  </Link>
);

ModoSitePreview.propTypes = {
  /** If True, site is active. */
  active: PropTypes.bool.isRequired,
  /** Props used by styled component to override styles */
  className: PropTypes.string,
  /** URL to Modo Site Logo. */
  logo: PropTypes.string,
  /* Modo configuration URL */
  modoId: PropTypes.string.isRequired,
  /** i18n func. */
  t: PropTypes.func.isRequired,
  /** Modo title. */
  title: PropTypes.string.isRequired,
  /** Modo Site URL. */
  websiteUrl: PropTypes.string,
};

ModoSitePreview.defaultProps = {
  className: '',
  logo: '',
  websiteUrl: '',
};

export default withTranslation(['components', 'common'])(ModoSitePreview);
