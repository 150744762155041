// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { DropdownList, Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const Option = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.2rem;
  padding: 0.75rem 3rem;
  user-select: none;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${({ selected, theme }) => selected
    && css`
      color: ${theme.colors.primary};
    `};
`;

export const FilterButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;
`;

export const OptionSelected = styled.div`
  color: #191919;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

export const DropdownIndicator = styled(Icons.ArrowSingle).attrs(({ opened }) => ({
  dir: opened ? 'north' : 'south',
}))``;

export const StyledDropdown = styled(DropdownList).attrs(({ theme }) => ({
  dropdownStyles: css`
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 1rem 3rem 0 ${theme.colors.boxShadow};
    font-size: 1.2rem;
    padding: 2rem 0;
    width: 16rem;
  `,
}))`
  font-size: 1.2rem;
`;
