// =============================
// Imports
// =============================

// Externals Dependencies
import Cookies from 'js-cookie';

// Constants
import * as acts from '../constants/ActionTypes';

// Actions
import {
  initializeApp,
  setLocalPreferences,
} from '../actions/CoreActions';
import {
  getUserSubscriptions,
  getLatestNotifications,
  getRealtimeChannels,
} from '../actions/UserActions';

// Helpers
import { getCookieConfig } from '../../helpers/misc';

// =============================
// Middleware
// =============================

const userMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.SET_USER_SESSION_TOKEN:
      Cookies.set('xAuth', action.payload, getCookieConfig(3));
      return next(action);

    case action.type === acts.GET_USER_DATA_SUCCESS:
      if (!store.getState().core.ready) {
        store.dispatch(initializeApp());
      }

      return next(action);

    case action.type === acts.GET_USER_DATA_FAILURE:
      Cookies.remove('xAuth', getCookieConfig(null));

      if (!store.getState().core.ready) {
        store.dispatch(initializeApp());
      }

      return next(action);

    // This action should mean that user will be logged
    case action.type === acts.SET_USER_INFO:
      store.dispatch(getUserSubscriptions());
      store.dispatch(getLatestNotifications());
      store.dispatch(getRealtimeChannels());
      // When user is logging in after app init, we might need to refresh language
      store.dispatch(setLocalPreferences({ language: action.payload.language }));
      return next(action);

    case action.type === acts.LOGOUT_USER_SUCCESS:
      Cookies.remove('xAuth', getCookieConfig(null));
      return next(action);

    default:
      return next(action);
  }
};

export default userMiddleware;
