// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { getSiteOrigin } from '../../../helpers/misc';
import NoLang from './base';
// =============================
// Component
// =============================

const PremiumModal = (props) => {
  const { t, sendUserRequest, closePremiumModal, show, message, context, description } = props;

  return (
    <NoLang
      lang={{
        title: t('components:premium_modal.title'),
        message,
        button: t('components:premium_modal.button'),
        chooseLater: t('components:premium_modal.choose_later'),
      }}
      gotoPremium={() => {
        sendUserRequest({ context, description });
        closePremiumModal();
      }}
      open={show}
      onClose={() => {
        closePremiumModal();
      }}
      premiumNopeSrc={`${getSiteOrigin()}/static/premium-nope.gif`}
    />
  );
};

PremiumModal.propTypes = {
  t: PropTypes.func.isRequired,
  sendUserRequest: PropTypes.func.isRequired,
  closePremiumModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.string,
  context: PropTypes.string,
  description: PropTypes.string,
};

PremiumModal.defaultProps = {
  message: null,
  context: null,
  description: null,
};

export default withTranslation(['components'])(PremiumModal);
