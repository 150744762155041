// Global renderers
export const TEXT = 'TEXT';
export const NUMBER = 'NUMBER';
export const ADDRESS = 'ADDRESS';
export const DATE = 'DATE';
export const IMAGE = 'IMAGE';
export const STATUS = 'STATUS';
export const META_ENTITY = 'META_ENTITY';
export const DISPLAY_ARTISTS = 'DISPLAY_ARTISTS';
export const GENERIC_CHIP = 'GENERIC_CHIP';
export const NOTES = 'NOTES';
export const SHOWCASE = 'SHOWCASE';
export const AGENTS = 'AGENTS';

// Artist specific renderers
export const ARTIST_TYPE = 'ARTIST_TYPE';

// Album specific renderers
export const ALBUM_TYPE = 'ALBUM_TYPE';

// Pitch specific renderers
export const PITCH_ENTITY_TYPE = 'PITCH_ENTITY_TYPE';

// Track specific renderers
export const MUSIC_TYPE = 'MUSIC_TYPE';
export const OWNERSHIPS = 'OWNERSHIPS';
export const TRACK_VERSION = 'TRACK_VERSION';
export const SIMILARITY_SEARCH = 'SIMILARITY_SEARCH';
export const SIMILARITY = 'SIMILARITY';
export const WAVEFORM = 'WAVEFORM';
export const RATING = 'RATING';
export const GROUPING = 'GROUPING';

// Ingestion specific renderers
export const INGESTION_TYPE = 'INGESTION_TYPE';
export const INGESTION_STATUS = 'INGESTION_STATUS';
export const INGESTION_ERRORS = 'INGESTION_ERRORS';
