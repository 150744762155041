// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

import Link from '../../other/link';

import { StyledButton } from '../../layouts/global.styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: #dae2e5;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  width: 100%;

  ${({ theme }) => theme.mediaAbove.sxga`
    padding: 4rem 3.2rem;
  `};
`;

export const BackLink = styled(Link)`
  align-items: center;
  color: #999999;
  display: flex;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  justify-content: flex-start;
  margin-bottom: 1.6rem;
`;

export const BackIcon = styled(Icons.ArrowDirection).attrs(({ theme }) => ({
  dir: 'west',
  fill: theme.colors.primary,
  width: '1rem',
}))`
  margin-right: 0.8rem;
`;

export const FlexWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const RightSection = styled.div`
  align-items: center;
  display: flex;

  > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const SiteTitle = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: #191919;
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-bottom: 0.4rem;
  max-width: 55rem;

  ${({ theme }) => theme.mediaBelow.sxga`
    max-width: 30rem;
  `};
`;

export const LastTimeModified = styled.div`
  color: #999999;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const ChangePlanButton = styled(StyledButton).attrs({
  color: 'primary',
})``;

export const GoToSiteButton = styled(StyledButton).attrs({
  color: 'secondary',
})``;

export const GoToIcon = styled(Icons.ExternalLink).attrs(({ theme }) => ({
  fill: theme.colors.primary,
}))`
  margin-right: 0.8rem;
`;
