// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _some from 'lodash/some';
import _startsWith from 'lodash/startsWith';

import { openRouterModal as openRouterModalBase } from '../../../store/actions/CoreActions';

import { Wrapper } from './styles';

// =============================
// Component
// =============================

class Link extends Component {
  static propTypes = {
    /** Link content. */
    children: PropTypes.node.isRequired,
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    /** If True, User confirms he want to navigate even if he have some unsaved changes. */
    disableBlockNavigation: PropTypes.bool,
    /** The path inside pages directory. This is the only required prop. */
    href: PropTypes.string.isRequired,
    /** If True, wrapper will use inlined styles. */
    inline: PropTypes.bool,
    /**
     * The path that will be rendered in the browser URL bar. Used for dynamic routes.
     * NextJs & Styled Components both us the "as" props, so we need to use nextAs to fix
     * prop not being passed correctly. */
    nextAs: PropTypes.string,
    /** If True, hover styles won't be applied. */
    noHover: PropTypes.bool,
    /** onClick handler */
    onClick: PropTypes.func,
    /**
     * Function use to open the confirmation modal
     * (relative to shouldBlockNavigation & disableBlockNavigation). */
    openRouterModal: PropTypes.func.isRequired,
    /**
     * If True, on click, will block navigation and ask for confirmation.
     * Usualy because some modification are not saved on the page. */
    shouldBlockNavigation: PropTypes.bool.isRequired,
    /** If True, will add text ellipsis styles to link.s */
    textEllipsis: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    disableBlockNavigation: false,
    inline: false,
    nextAs: '',
    noHover: false,
    onClick: null,
    textEllipsis: false,
  };

  handleOnClick = () => {
    const {
      disableBlockNavigation,
      href,
      nextAs,
      onClick,
      openRouterModal,
      shouldBlockNavigation,
    } = this.props;

    // Block navigation & open confirmation modal.
    if (shouldBlockNavigation && !disableBlockNavigation) {
      return (e) => {
        e.preventDefault();
        openRouterModal(href, nextAs);
      };
    }

    return onClick;
  };

  render() {
    const {
      children,
      className,
      disableBlockNavigation,
      href,
      nextAs,
      inline,
      noHover,
      onClick,
      openRouterModal,
      shouldBlockNavigation,
      textEllipsis,
      ...rest
    } = this.props;

    // External Link
    if (!_startsWith(href, '/')) {
      return (
        <Wrapper
          className={className}
          href={href}
          inline={inline}
          noHover={noHover}
          onClick={this.handleOnClick()}
          textEllipsis={textEllipsis}
          {...rest}
        >
          {children}
        </Wrapper>
      );
    }

    // Internal Link
    return (
      <NextLink as={nextAs} href={href} passHref>
        <Wrapper
          className={className}
          inline={inline}
          noHover={noHover}
          onClick={this.handleOnClick()}
          textEllipsis={textEllipsis}
          {...rest}
        >
          {children}
        </Wrapper>
      </NextLink>
    );
  }
}

function mapStateToProps({ modo }) {
  return {
    shouldBlockNavigation: _some(
      [_get(modo, 'configPageModules.dirtyModules', []).length > 0],
      e => e,
    ),
  };
}

export default connect(mapStateToProps, {
  openRouterModal: openRouterModalBase,
})(Link);
