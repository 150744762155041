// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Components
import InputWithLanguage from '../../presentationals/inputWithLanguage';

// =============================
// Component
// =============================

function mapStateToProps({ options }) {
  return {
    languages: options.data.languages,
  };
}

export default connect(mapStateToProps)(InputWithLanguage);
