// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

// =============================
// Page
// =============================

class Business extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    router: PropTypes.shape({
      asPath: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidUpdate(prevProps) {
    const { router: prevRouter } = prevProps;
    const { router } = this.props;

    if (
      typeof window !== 'undefined'
      && window.gtag
      && process.env.GA_CODE
      && prevRouter.asPath !== router.asPath
    ) {
      window.gtag('config', process.env.GA_CODE, {
        page_path: router.asPath,
      });
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
      </div>
    );
  }
}

export default withRouter(Business);
