// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Pages = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 32 32"
    {...rest}
  >
    <path d="M23.941,5.5H8.059A2.561,2.561,0,0,0,5.5,8.059V23.941A2.561,2.561,0,0,0,8.059,26.5H23.941A2.561,2.561,0,0,0,26.5,23.941V8.059A2.561,2.561,0,0,0,23.941,5.5ZM18.974,18.734l2.063-2.064L25.5,20.241v3.513ZM8.059,6.5H23.941A1.56,1.56,0,0,1,25.5,8.059v10.9l-4.187-3.35a.5.5,0,0,0-.666.037l-2.472,2.472L12.3,13.6a.5.5,0,0,0-.625.012L6.5,17.932V8.059A1.56,1.56,0,0,1,8.059,6.5Zm15.883,19H8.059A1.56,1.56,0,0,1,6.5,23.941V19.235l5.513-4.594,13.221,10.17A1.558,1.558,0,0,1,23.941,25.5Z" />
    <path d="M17,13.5A2.5,2.5,0,1,0,14.5,11,2.5,2.5,0,0,0,17,13.5Zm0-4A1.5,1.5,0,1,1,15.5,11,1.5,1.5,0,0,1,17,9.5Z" />
  </Svg>
));

Pages.displayName = 'Pages';

Pages.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Pages.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '1.9rem',
};

export default withTheme(Pages);
