// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

// Helpers
import { getDocName, getNameWithFallback, getSearchName } from './doc-names';

// =============================
// Helpers
// =============================

/**
 * Get rights for a document
 * @param {boolean} docOwnedByTenant
 * @param {string} docTenantId
 * @param {object[]} providers
 * @return {object} - Documents rights
 */
export const getDocumentRights = (
  docOwnedByTenant,
  docTenantId = null,
  providers = [],
) => {
  if (!docOwnedByTenant) {
    const provider = providers.find(p => p.id === docTenantId);

    return {
      canWrite: !_get(provider, 'read_only_provider', true),
      canAccessContactInfos: _get(provider, 'provider_can_access_contact_infos', true),
      canAccessNotes: _get(provider, 'provider_can_access_notes', true),
      canAccessTracksSplits: _get(provider, 'provider_can_access_tracks_splits', true),
      isOwner: false,
      providerId: provider.id,
      providerName: provider.company_name || `${provider.first_name} ${provider.last_name}`,
    };
  }

  return {
    canWrite: true,
    canAccessContactInfos: true,
    canAccessNotes: true,
    canAccessTracksSplits: true,
    isOwner: true,
  };
};

/**
 * Check if renderer is editable
 * @param {string|function} editable
 * @param {object} data
 * @return {boolean}
 */
export const isEditableRenderer = (editable, data) => {
  if (typeof editable === 'boolean') return editable;

  if (typeof editable === 'function') return editable(data);

  return false;
};

/**
 * Get image url for a document
 * @param {object} image - Image object
 * @param {string} size - Image size, can be "large", "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getGenericDocCoverUrl = (image, size = 'large') => {
  if (_get(image, 'is_converting', false)) {
    return _get(image, 'original.url');
  }

  switch (size) {
    case 'xsmall':
      return (
        _get(image, `[${size}].url`)
        || _get(image, 'small.url')
        || _get(image, 'large.url')
        || _get(image, 'original.url')
      );

    case 'small':
      return (
        _get(image, `[${size}].url`)
        || _get(image, 'large.url')
        || _get(image, 'original.url')
      );

    case 'large':
      return _get(image, `[${size}].url`) || _get(image, 'original.url');

    default:
      return _get(image, 'original.url');
  }
};

/**
 * Transform ApiSelectInput data for target
 * @param {object} data - Can be input data or api data
 * @param {string} collection - Meta collection name
 * @param {boolean} targetIsForm - Set to true to transform from api to form, or
 * false for the other way
 * @param {string} language - Language for helper functions, use for tracks
 * @return {object|string} - Transformed data
 */
export const getApiSelectInputData = (
  data,
  collection,
  targetIsForm,
  language,
) => {
  // From Api to Form
  if (targetIsForm) {
    return _get(data, 'id', null) ? {
      value: data.id,
      label: collection === 'people' ? data.full_name : getDocName(collection)(data, language),
      search: collection === 'people' ? data.full_name : getSearchName(collection)(data),
    } : {
      value: null,
      label: '',
      search: '',
    };
  }

  // From Form to Api
  return _get(data, 'value', null);
};

/**
 * Get formatted duration
 * @param {number} duration - Duration in seconds
 * @return {string} - Formatted duration
 */
export function presentDuration(duration) {
  let sec = Math.floor(duration % 60);
  let min = Math.floor(duration / 60);

  if (sec < 10) sec = `0${sec}`;
  if (min < 10) min = `0${min}`;

  return `${min}:${sec}`;
}

const tagCategoriesOrder = [
  // genres
  '5b532dd7450f084ddcd8354c',
  // area
  '5b532dd7450f084ddcd83546',
  // periods
  '5b532dd7450f084ddcd83549',
  // structures
  '5b532dd7450f084ddcd83555',
  // mood
  '5b532dd7450f084ddcd83552',
  // style
  '5b532dd7450f084ddcd83558',
  // usage
  '5b532dd7450f084ddcd8355b',
  // instruments
  '5b532dd7450f084ddcd8354f',
  // sound
  '5f22b65c388a470017da62a3',
  // gestures
  '5d35f5dcb42d7b56380dfa63',
  // technics
  '5d356e044ee859003222b01e',
  // nuance
  '5d35908c9fd3b20032f574bf',
  // pal
  '5b59c72cb8986ea19dc40781',
];

/**
 * Get tags by order
 * @param {Object[]} tagsWithCategory
 * @param {string} language - Language alpha sorting
 * @return {Object[]} tagsWithCategory sorted
 */
export function presentTags(tagsWithCategory, language) {
  return _sortBy(
    tagsWithCategory,
    tag => `${tagCategoriesOrder.indexOf(tag.category_id)}-${getNameWithFallback(tag.names, language)}`,
  );
}
