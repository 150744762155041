// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { StyledIcon, IconWrapper } from './styles';

// =============================
// Component
// =============================

const Icon = ({ IconComponent }) => <IconWrapper><StyledIcon as={IconComponent} /></IconWrapper>;

Icon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  IconComponent: PropTypes.object.isRequired,
};

export default Icon;
