// =============================
// Imports
// =============================

// External Dependencies
import axios, { isCancel } from 'axios';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

// Helpers
import determineError from '../../helpers/errors';
import { getApiUrl, getXPreferredLanguage } from '../../helpers/misc';
import { cancelableRequest } from '../helpers/axios';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// =============================
// Actions
// =============================

export function getUserSubscriptions() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_SUBSCRIPTIONS_LOADING,
    });

    return axios({
      method: 'get',
      url: getApiUrl('dashboard/users/subscriptions'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_USER_SUBSCRIPTIONS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_USER_SUBSCRIPTIONS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_USER_SUBSCRIPTIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

function modifyUserBase(data, options) {
  const nextDiff = _pick(data, [
    'first_name',
    'last_name',
    'email',
    'job',
    'phone_number',
    'company_name',
    'country',
  ]);

  return (dispatch, getState) => {
    const oldState = getState().user.data;

    dispatch({
      type: options.loadingType,
    });

    // We set it here so that in case of error (email)
    // we can reset the form later
    dispatch({
      type: acts.SET_USER_INFO_ONLY,
      payload: { ...oldState, ...nextDiff },
    });

    return cancelableRequest(options.requestType, {
      method: 'put',
      url: getApiUrl('dashboard/user'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_USER_INFO_ONLY,
          payload: response.data,
        });

        return dispatch({
          type: options.successType,
          payload: {
            message: i18n.t('pages:account.account_modified'),
          },
        });
      })
      .catch((err) => {
        if (isCancel(err)) throw err;
        let errorMsg;

        dispatch({
          type: acts.SET_USER_INFO_ONLY,
          payload: oldState,
        });

        switch (true) {
          case err.response && err.response.data.key === 'email_already_exists':
            errorMsg = i18n.t('errors:dashboard.email_already_taken');
            break;

          case err.response && err.response.data.key === 'wrong_credentials':
            errorMsg = i18n.t('errors:dashboard.bad_password');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: options.failureType,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function modifyUserInfo(data) {
  return modifyUserBase(data, {
    requestType: rqs.MODIFY_USER,
    loadingType: acts.MODIFY_USER_LOADING,
    successType: acts.MODIFY_USER_SUCCESS,
    failureType: acts.MODIFY_USER_FAILURE,
  });
}

export function modifyUserPassword(data) {
  return modifyUserBase(data, {
    requestType: rqs.MODIFY_USER_PASSWORD,
    loadingType: acts.MODIFY_USER_PASSWORD_LOADING,
    successType: acts.MODIFY_USER_PASSWORD_SUCCESS,
    failureType: acts.MODIFY_USER_PASSWORD_FAILURE,
  });
}

export function sendUserRequest({ context, description }) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.USER_REQUEST_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/users/request'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { context, description },
    })
      .then(() => dispatch({
        type: acts.USER_REQUEST_SUCCESS,
        payload: { message: i18n.t('common:request_sent') },
      }),
      )
      .catch(err => dispatch({
        type: acts.USER_REQUEST_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function getLatestNotifications() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_LATEST_NOTIFICATIONS_LOADING,
    });

    return axios({
      method: 'get',
      url: `${getApiUrl('dashboard/users/notifications')}?page=0&max=4`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_LATEST_NOTIFICATIONS,
          payload: response.data.data,
        });

        return dispatch({
          type: acts.GET_LATEST_NOTIFICATIONS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_LATEST_NOTIFICATIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function pushLatestNotification(notification) {
  return (dispatch, getState) => {
    const {
      user: { notifications },
    } = getState();

    let updatedNotifications = [];

    if (notifications.find(notif => notif.id === notification.id)) {
      // Replace current notification with new one
      updatedNotifications = notifications.map((notif) => {
        if (notif.id === notification.id) return notification;
        return notif;
      });
    } else {
      // Remove last notifications
      updatedNotifications = notifications.slice(0, -1);

      // Add new one
      updatedNotifications.unshift(notification);
    }

    // Update store
    return dispatch({
      type: acts.SET_LATEST_NOTIFICATIONS,
      payload: updatedNotifications,
    });
  };
}

export function pushRelationsNotification(entity, notification) {
  return {
    type: acts.SET_RELATIONS_NOTIFICATIONS,
    payload: {
      type: `${entity}s`,
      notificationId: notification.notificationId,
      progress: notification.progress,
      docIds: notification.docIds,
      data: notification.data,
    },
  };
}

export function clearRelationsNotification(entity, notification) {
  return {
    type: acts.CLEAR_RELATIONS_NOTIFICATIONS,
    payload: {
      type: `${entity}s`,
      notificationId: notification.notificationId,
    },
  };
}

export function getRealtimeChannels() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_REALTIME_CHANNELS_LOADING,
    });

    return axios({
      method: 'get',
      url: getApiUrl('dashboard/realtime/channels'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_REALTIME_AUTH_KEY,
          payload: response.headers['x-realtime-auth-key'],
        });

        dispatch({
          type: acts.SET_REALTIME_CHANNELS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_REALTIME_CHANNELS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_REALTIME_CHANNELS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}
