// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { StyledInput } from './styles';

// =============================
// Component
// =============================

const Input = ({ t, withBackground, ...rest }) => (
  <StyledInput
    lang={{
      Label: {
        optional: t('components:mewo_input.Label.optional'),
      },
    }}
    withBackground={withBackground}
    {...rest}
  />
);

Input.propTypes = {
  /** If True, will render Input with a white background. */
  withBackground: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

Input.defaultProps = {
  withBackground: false,
};

export default withTranslation(['components'])(Input);
