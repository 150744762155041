// List of meta entites that exists on Admin-v2
const metaEntities = [
  'track',
  'album',
  'playlist',
  'pitch',
  'brief',
  'catalog',
  'artist',
  'label',
  'publisher',
  'people',
  'ingestion',
];

export default metaEntities;
