// =============================
// Imports
// =============================

// Config
import { i18n } from '../../../config/i18n';

// =============================
// Config
// =============================

const dashboardMenu = (logoutFnc, noSubscription = false) => {
  if (noSubscription) {
    return [
      {
        name: '/dashboard/account',
        label: i18n.t('components:dashboard_menu.account'),
      },
      {
        name: '/logout',
        action: () => {
          logoutFnc();
        },
        label: i18n.t('components:dashboard_menu.log_out'),
      },
    ];
  }

  return [
    {
      name: '/dashboard/account',
      label: i18n.t('components:dashboard_menu.account'),
    },
    {
      name: '/dashboard/organizationsettings',
      label: i18n.t('components:dashboard_menu.organization_settings'),
    },
    {
      name: '/dashboard/collaborators',
      label: i18n.t('components:dashboard_menu.collaborators'),
    },
    {
      name: '/dashboard/agents',
      label: i18n.t('components:dashboard_menu.agents'),
    },
    {
      name: '/dashboard/providers',
      label: i18n.t('components:dashboard_menu.providers'),
    },
    {
      name: '/logout',
      action: () => {
        logoutFnc();
      },
      label: i18n.t('components:dashboard_menu.log_out'),
    },
  ];
};

export default dashboardMenu;
