// =============================
// Imports
// =============================

import _get from 'lodash/get';

import * as renderersType from '../entities/renderers/types';

import { required } from '../../../../validators';

import { getApiSelectInputData } from '../../../../helpers/meta-common';

// =============================
// Config
// =============================

const config = {
  columns: [
    {
      key: 'image',
      renderComponent: {
        type: renderersType.IMAGE,
      },
      readableWidth: 5,
      immutableColumn: true,
      withoutColumnHeader: true,
    },
    {
      key: 'tenant',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (tenant) => {
          if (tenant.company_name) return tenant.company_name;
          return `${tenant.first_name} ${tenant.last_name}`;
        },
        getSearchProps: (document) => {
          const { tenant } = document;

          return {
            entity: 'album',
            filters: {
              tenants: [[
                tenant.id,
                tenant.company_name || `${tenant.first_name} ${tenant.last_name}`,
              ]],
            },
            resetQuery: true,
          };
        },
      },
      readableWidth: 12,
      immutableColumn: true,
    },
    {
      key: 'title',
      renderComponent: {
        type: renderersType.TEXT,
        validator: required,
        isPanelOpener: true,
        getSearchProps: document => ({
          entity: 'track',
          filters: { albums: [[document.id, document.title]] },
          resetQuery: true,
        }),
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      immutableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'catalog',
      renderComponent: {
        type: renderersType.META_ENTITY,
        entity: 'catalog',
        isPanelOpener: true,
        transformToDisplay: catalog => catalog.name,
        onTheFlyField: 'name',
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'album_type',
      renderComponent: {
        type: renderersType.ALBUM_TYPE,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'album_ref',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'release_date',
      renderComponent: {
        type: renderersType.DATE,
        validator: required,
        withTime: false,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'display_artists',
      renderComponent: {
        type: renderersType.DISPLAY_ARTISTS,
      },
      readableWidth: 20,
      editableColumn: true,
    },
    {
      key: 'upc',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'ean',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'descriptions',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (descriptions, { gridsLanguage }) => {
          const descriptionInLanguage = descriptions.find(desc => desc.locale === gridsLanguage);

          return descriptionInLanguage ? descriptionInLanguage.value : '';
        },
        editVariant: 'descriptions',
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'notes',
      renderComponent: {
        type: renderersType.NOTES,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'agents',
      renderComponent: {
        type: renderersType.AGENTS,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'showcase',
      renderComponent: {
        type: renderersType.SHOWCASE,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'has_file',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'attachments',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: attachments => attachments.map(a => a.name).join(', '),
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: false,
    },
    {
      key: 'has_conversion_error',
      dataKey: 'image.error_key',
      columnKey: 'conversion_error',
      renderComponent: {
        type: renderersType.TEXT,
        displayVariant: 'error',
        transformToDisplay: (errorKey, { t }) => t(`errors:conversion.${errorKey}`),
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'created_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'updated_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
  ],
  gridModifyPick: (data, init = false) => ({
    title: _get(data, 'title', ''),
    catalog: getApiSelectInputData(data.catalog, 'catalog', init),
    album_type: init
      ? _get(data, 'album_type.id', null)
      : _get(data, 'album_type', null),
    album_ref: _get(data, 'album_ref', ''),
    release_date: _get(data, 'release_date', null),
    upc: _get(data, 'upc', ''),
    ean: _get(data, 'ean', ''),
    descriptions: _get(data, 'descriptions', [])
      .map(({ locale, value }) => ({ locale, value })),
    notes: _get(data, 'notes', ''),
  }),
  gridUpload: {
    key: 'image',
    maxSize: 20000000,
  },
  panelUploads: {
    image: {
      maxSize: 20000000,
    },
    attachment: {
      maxSize: 1000000000,
    },
  },
};

export default config;
