// =============================
// Imports
// =============================

// External Dependencies
import { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Flex, Icons } from '@mewo/components';
import Router from 'next/router';
import trackplaceholder from '@mewo/components/assets/images/placeholders/track_500x500.png?webp';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _compact from 'lodash/compact';

// Config
import { withTranslation } from '../../../../config/i18n';
import entityConfig from '../../meta/tracks/config';

// Actions
import { panelActions, triggerAutosaveCheck } from '../../../../store/actions/SidePanelActions';
import {
  pause as pauseBase,
  play as playBase,
  setCurrentTrack as setCurrentTrackBase,
} from '../../../../store/actions/PlayerActions';

// Constants
import * as spts from '../../../../store/constants/SidePanelTypes';
import * as playerCtx from '../../../../store/constants/PlayerContexts';
import * as playerStates from '../../../../store/constants/PlayerStates';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import DocumentStatus from '../documentStatus';
import Autosave from '../../../other/autosave';
import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import InputWithLanguage from '../../../containers/inputWithLanguage';
import TextAreaInput from '../../../presentationals/inputs/textAreaInput';
import MetaDisplayArtist from '../inputs/metaDisplayArtist';
import MetaTrackOwnerships from '../inputs/metaTrackOwnerships';
import MetaRightsInformations from '../metaRightsInformations';
import MetaRefAdder from '../../../other/metaRefAdder';
import MetaDownloadDropdown from '../../../other/metaDownloadDropdown';
import SelectInput from '../../../presentationals/inputs/selectInput';
import ApiSelectInput from '../../../containers/inputs/apiSelectInput';
import SwitchInput from '../../../presentationals/inputs/switchInput';
import WithMetaSearchRoute from '../../../faac/withMetaSearchRoute';
import TrackVersions from './versions';
import DatePickerInput from '../../../presentationals/inputs/datePickerInput';
import Uploader from '../../../presentationals/uploader';
import MetaTags from '../../../other/metaTags';
import MetaCustomFields from '../metaCustomFields';
import CopyLink from '../copyLink';
import Autotag from '../autotag';

import validator from '../../../../validators/meta/track';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getSiteOrigin } from '../../../../helpers/misc';
import { formatDateString } from '../../../../helpers/dates';
import { getDocName, getNameWithFallback, getSearchName } from '../../../../helpers/doc-names';
import { getApiSelectInputData, getDocumentRights, getGenericDocCoverUrl } from '../../../../helpers/meta-common';

// Styles
import {
  AddItemBtn,
  AddToRefActionIcon,
  AddToRefActionWrapper,
  DeleteItemButton,
  DocumentBody,
  DocumentCreatedAt,
  DocumentName,
  DocumentType,
  DownloadActionIcon,
  DownloadActionWrapper,
  FileHelper,
  GenericCover,
  GenericSearchIcon,
  GenericSearchLink,
  InputItemBox,
  LeftColumn,
  LineInnerLoader,
  LineLoader,
  LineLoaderWrapper,
  MiscHelper,
  RelationJobTooltip,
  RelationsWrapper,
  RelationTitleWrapper,
  RightColumn,
  SaveItemButton,
  SectionBox,
  SectionNotice,
  SectionSubTitle,
  SectionTitle,
  TabElement,
  TabLink,
  TabLinksWrapper,
} from '../common.styles';
import { InputDouble, InputTriple, InputWrapper } from '../../global.styles';
import { Controls, PauseIcon, PlayIcon } from './styles';

// =============================
// Component
// =============================

const entity = 'track';

export class MetaTrack extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      data: pth.track,
      additionalData: PropTypes.shape({
        versions: PropTypes.shape({
          data: PropTypes.arrayOf(pth.smallTrack),
        }),
        autotagging: PropTypes.shape({
          data: PropTypes.shape({
            nb_tracks: PropTypes.number,
            nb_versions: PropTypes.number,
            nb_tracks_not_autotagged: PropTypes.number,
            nb_versions_not_autotagged: PropTypes.number,
          }),
        }),
      }),
      isLoading: PropTypes.bool.isRequired,
      'isLoading-versions': PropTypes.bool,
      isModifying: PropTypes.bool.isRequired,
      'isModifying-versions': PropTypes.bool,
      'isModifying-attachment': PropTypes.bool,
      isAutotagging: PropTypes.bool.isRequired,
      isDuplicating: PropTypes.bool.isRequired,
      isUploading: PropTypes.bool.isRequired,
      isUploadingStems: PropTypes.bool.isRequired,
      isUploadingAttachment: PropTypes.bool.isRequired,
      isDeletingUpload: PropTypes.bool.isRequired,
      isDeletingStems: PropTypes.bool.isRequired,
      isDeletingAttachment: PropTypes.arrayOf(PropTypes.string).isRequired,
      isDeleting: PropTypes.bool.isRequired,
      uploadProgress: PropTypes.number.isRequired,
      uploadStemsProgress: PropTypes.number.isRequired,
      uploadAttachmentProgress: PropTypes.number.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    relationsNotifications: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      progress: PropTypes.number,
    })),
    customFields: pth.customFieldsConfig.isRequired,
    languages: PropTypes.objectOf(pth.language).isRequired,
    providers: PropTypes.arrayOf(pth.provider).isRequired,
    trackVersions: PropTypes.arrayOf(pth.trackVersion).isRequired,
    rightsTypes: PropTypes.arrayOf(pth.rightsType).isRequired,
    territories: PropTypes.arrayOf(pth.territory).isRequired,
    prs: PropTypes.arrayOf(pth.rightsSociety).isRequired,
    nrs: PropTypes.arrayOf(pth.rightsSociety).isRequired,
    mrs: PropTypes.arrayOf(pth.rightsSociety).isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    contextId: PropTypes.string,
    contextName: PropTypes.string,
    isPlaying: PropTypes.bool.isRequired,
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    setCurrentTrack: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
    grouping: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    contextId: null,
    contextName: null,
    relationsNotifications: [],
    grouping: [],
  };

  constructor(props) {
    super(props);

    this.formRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { panel: { isAutotagging: wasAutotagging } } = prevProps;
    const { panel: { isAutotagging, data } } = this.props;

    if (wasAutotagging && !isAutotagging && this.form) {
      this.form.change('tags', _get(data, 'tags', []).map(({ id }) => id));
    }
  }

  pickFormData = (data, documentRights, init = false) => {
    if (!documentRights.canWrite && !init) {
      return {
        approved: _get(data, 'approved', false),
        rating: _get(data, 'rating', null),
        showcase: _get(data, 'showcase', []),
      };
    }

    const keys = {
      title: _get(data, 'title', ''),
      music_type: _get(data, 'music_type', 'core'),
      display_title: _get(data, 'display_title', ''),
      approved: _get(data, 'approved', false),
      track_number: _get(data, 'track_number', null),
      rating: _get(data, 'rating', null),
      track_ref: _get(data, 'track_ref', ''),
      tags: init
        ? _get(data, 'tags', []).map(({ id }) => id)
        : _get(data, 'tags', []),
      isrc: _get(data, 'isrc', ''),
      iswc: _get(data, 'iswc', ''),
      cocv: _get(data, 'cocv', ''),
      prs_tunecode: _get(data, 'prs_tunecode', ''),
      territories: _get(data, 'territories', []),
      lyrics: _get(data, 'lyrics', ''),
      original_prs: init
        ? _get(data, 'original_prs.id', null)
        : _get(data, 'original_prs', null),
      original_mrs: init
        ? _get(data, 'original_mrs.id', null)
        : _get(data, 'original_mrs', null),
      original_nrs: init
        ? _get(data, 'original_nrs.id', null)
        : _get(data, 'original_nrs', null),
      version: init
        ? _get(data, 'version.id', null)
        : _get(data, 'version', null),
      album: getApiSelectInputData(data.album, 'album', init),
      display_artists: _get(data, 'display_artists', [])
        .filter(({ artist }) => init || !!getApiSelectInputData(artist, 'artist', init))
        .map(({ artist, alias }) => ({
          artist: getApiSelectInputData(artist, 'artist', init),
          alias: alias || '',
        })),
      artists_publishing_ownerships: _get(data, 'artists_publishing_ownerships', [])
        .filter(({ artist }) => init || !!getApiSelectInputData(artist, 'artist', init))
        .map(ownership => ({
          artist: getApiSelectInputData(ownership.artist, 'artist', init),
          alias: _get(ownership, 'alias', ''),
          rights_type: init
            ? _get(ownership, 'rights_type.id', null)
            : _get(ownership, 'rights_type', null),
          ...(documentRights.canAccessTracksSplits ? {
            mechanical_percentage: _get(ownership, 'mechanical_percentage', null),
            performing_percentage: _get(ownership, 'performing_percentage', null),
            sync_percentage: _get(ownership, 'sync_percentage', null),
          } : {}),
          chain_of_title: _get(ownership, 'chain_of_title', ''),
          territories: _get(ownership, 'territories', []),
        })),
      publishing_ownerships: _get(data, 'publishing_ownerships', [])
        .filter(({ publisher }) => init || !!getApiSelectInputData(publisher, 'publisher', init))
        .map(ownership => ({
          publisher: getApiSelectInputData(ownership.publisher, 'publisher', init),
          rights_type: init
            ? _get(ownership, 'rights_type.id', null)
            : _get(ownership, 'rights_type', null),
          ...(documentRights.canAccessTracksSplits ? {
            mechanical_percentage: _get(ownership, 'mechanical_percentage', null),
            performing_percentage: _get(ownership, 'performing_percentage', null),
            sync_percentage: _get(ownership, 'sync_percentage', null),
          } : {}),
          chain_of_title: _get(ownership, 'chain_of_title', ''),
          territories: _get(ownership, 'territories', []),
        })),
      artists_master_ownerships: _get(data, 'artists_master_ownerships', [])
        .filter(({ artist }) => init || !!getApiSelectInputData(artist, 'artist', init))
        .map(ownership => ({
          artist: getApiSelectInputData(ownership.artist, 'artist', init),
          alias: _get(ownership, 'alias', ''),
          rights_type: init
            ? _get(ownership, 'rights_type.id', null)
            : _get(ownership, 'rights_type', null),
          ...(documentRights.canAccessTracksSplits ? {
            ownership_percentage: _get(ownership, 'ownership_percentage', null),
            sync_percentage: _get(ownership, 'sync_percentage', null),
          } : {}),
          chain_of_title: _get(ownership, 'chain_of_title', ''),
          territories: _get(ownership, 'territories', []),
        })),
      master_ownerships: _get(data, 'master_ownerships', [])
        .filter(({ label }) => init || !!getApiSelectInputData(label, 'label', init))
        .map(ownership => ({
          label: getApiSelectInputData(ownership.label, 'label', init),
          rights_type: init
            ? _get(ownership, 'rights_type.id', null)
            : _get(ownership, 'rights_type', null),
          ...(documentRights.canAccessTracksSplits ? {
            ownership_percentage: _get(ownership, 'ownership_percentage', null),
            sync_percentage: _get(ownership, 'sync_percentage', null),
          } : {}),
          chain_of_title: _get(ownership, 'chain_of_title', ''),
          territories: _get(ownership, 'territories', []),
        })),
      release_date: _get(data, 'release_date', null),
      showcase: init
        ? _get(data, 'showcase', []).map(({ id }) => id)
        : _get(data, 'showcase', []),
      descriptions: _get(data, 'descriptions', [])
        .map(({ locale, value }) => ({ locale, value })),
    };

    if (documentRights.canAccessNotes) {
      keys.notes = _get(data, 'notes', '');
    }

    if (documentRights.isOwner) {
      keys.public = _get(data, 'public', false);
      keys.agents = _get(data, 'agents', []);
      keys.custom = _get(data, 'custom', {});
      keys.grouping = _compact(_get(data, 'grouping', []));
    }

    return keys;
  };

  renderPanelAnchors = (data) => {
    const { customFields, providers, t } = this.props;

    const documentRights = getDocumentRights(
      data.owned_by_tenant,
      data.tenant.id,
      providers,
    );

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    return (
      <TabLinksWrapper>
        <TabLink name="general_information">
          {t(`pages:meta.${entity}s.panel.general_information_anchor`)}
        </TabLink>
        <TabLink name="versions">
          {t(`pages:meta.${entity}s.panel.versions_anchor`)}
        </TabLink>
        <TabLink name="publishing_information">
          {t(`pages:meta.${entity}s.panel.publishing_information_anchor`)}
        </TabLink>
        <TabLink name="master_information">
          {t(`pages:meta.${entity}s.panel.master_information_anchor`)}
        </TabLink>
        <TabLink name="tags">
          {t(`pages:meta.${entity}s.panel.tags_anchor`)}
        </TabLink>
        {(documentRights.isOwner && hasCustomFields) && (
          <TabLink name="custom_fields_information">
            {t(`pages:meta.${entity}s.panel.custom_fields_information_anchor`)}
          </TabLink>
        )}
        {documentRights.isOwner && (
          <TabLink name="agents_information">
            {t(`pages:meta.${entity}s.panel.agents_information_anchor`)}
          </TabLink>
        )}
        {!documentRights.isOwner && (
          <TabLink name="providers_information">
            {t(`pages:meta.${entity}s.panel.providers_information_anchor`)}
          </TabLink>
        )}
        <TabLink name="modo_information">
          {t(`pages:meta.${entity}s.panel.modo_information_anchor`)}
        </TabLink>
        <TabLink name="uploads_information">
          {t(`pages:meta.${entity}s.panel.uploads_information_anchor`)}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  getPlayerIsCurrent = () => {
    const { contextId, contextName, panel: { data } } = this.props;

    return contextId === data.id
      && contextName === playerCtx.TRACK;
  };

  handlePlayClick = () => {
    const { panel: { data }, play, setCurrentTrack } = this.props;

    if (this.getPlayerIsCurrent()) {
      return play();
    }

    return setCurrentTrack(
      data,
      playerCtx.TRACK,
      data.id,
      0,
      [data],
    );
  };

  renderPlayerControls = () => {
    const { isPlaying, panel: { data }, pause } = this.props;

    if (!data || !data.audiofile.original) return null;

    const isCurrent = this.getPlayerIsCurrent();

    return (
      <Controls isCurrent={isCurrent}>
        {(isPlaying && isCurrent)
          ? <PauseIcon onClick={pause} />
          : <PlayIcon onClick={() => this.handlePlayClick()} />}
      </Controls>
    );
  };

  render() {
    const {
      panel,
      localPreferences,
      relationsNotifications,
      customFields,
      providers,
      nrs,
      prs,
      mrs,
      trackVersions,
      territories,
      rightsTypes,
      triggerPanelAutosaveCheck,
      i18n: { language },
      t,
    } = this.props;

    const {
      data,
      additionalData,
      isModifying,
      isAutotagging,
      isDuplicating,
      isUploading,
      isUploadingStems,
      isUploadingAttachment,
      isDeletingUpload,
      isDeletingStems,
      isDeletingAttachment,
      isDeleting,
      uploadProgress,
      uploadStemsProgress,
      uploadAttachmentProgress,
    } = panel;

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/${entity}s?panel=${data.id}`
      : '';

    const trackVersionsOptions = _sortBy(
      trackVersions.filter(({ key }) => key !== 'original').map(({ id, names }) => ({
        label: getNameWithFallback(names, language), value: id,
      })),
      'label',
    );

    const originalTrackVersion = trackVersions.find(({ key }) => key === 'original');

    if (originalTrackVersion) {
      trackVersionsOptions.unshift({
        label: getNameWithFallback(originalTrackVersion.names, language),
        value: originalTrackVersion.id,
      });
    }

    const nrsOptions = _sortBy(
      nrs.map(({ id, name }) => ({ label: name, value: id })),
      'label',
    );
    const prsOptions = _sortBy(
      prs.map(({ id, name }) => ({ label: name, value: id })),
      'label',
    );
    const mrsOptions = _sortBy(
      mrs.map(({ id, name }) => ({ label: name, value: id })),
      'label',
    );

    let inputDateFormat = 'DD/MM/YYYY';
    if (localPreferences.dateFormat === 'MMDDYYYY') inputDateFormat = 'MM/DD/YYYY';

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[spts.META_TRACK_PANEL].get}
        getAdditionalOptions={{
          fnc: panelActions[spts.META_TRACK_PANEL].getAdditional,
          list: [
            ['versions', 'versions'],
            [
              `/meta/autotagging/${entity}/${panel.id}/data`,
              'autotagging',
              '',
              panelData => panelData?.owned_by_tenant,
            ],
          ],
        }}
        modify={panelActions[spts.META_TRACK_PANEL].modify}
        modifyAdditional={panelActions[spts.META_TRACK_PANEL].modifyAdditional}
        autotagOptions={{
          fnc: panelActions[spts.META_TRACK_PANEL].autotag,
          getAdditionalList: [
            [
              `/meta/autotagging/${entity}/${panel.id}/data`,
              'autotagging',
            ],
          ],
        }}
        duplicate={panelActions[spts.META_TRACK_PANEL].duplicate}
        uploadFile={panelActions[spts.META_TRACK_PANEL].uploadFile}
        deleteFile={panelActions[spts.META_TRACK_PANEL].deleteFile}
        del={panelActions[spts.META_TRACK_PANEL].delete}
        lang={{
          autotagTitle: t(`pages:meta.${entity}s.autotag_documents`, { name: data?.title }),
          autotagDesc: t(`pages:meta.${entity}s.autotag_documents_notice`),
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {({
          getAdditional,
          modifyDocument,
          modifyAdditional,
          autotagDocument,
          duplicateDocument,
          uploadDocumentFile,
          deleteDocumentFile,
          deleteDocument,
        }) => {
          if (!data) return null;

          const relationsNotification = relationsNotifications
            .find(notification => notification.id === data.id);

          const documentRights = getDocumentRights(
            data.owned_by_tenant,
            data.tenant.id,
            providers,
          );

          const audiofile = _get(data, 'audiofile.original.url')
            ? {
              name: _get(data, 'audiofile.original.file_name'),
              url: _get(data, 'audiofile.original.url'),
            }
            : null;

          const stems = _get(data, 'stems.original.url')
            ? {
              name: _get(data, 'stems.original.file_name'),
              url: _get(data, 'stems.original.url'),
            }
            : null;

          const audiofileIsConverted = _get(data, 'audiofile.is_converted');

          let audiofileUploadStatus = data.has_file ? 2 : 0;
          let stemsUploadStatus = data.has_stems ? 2 : 0;
          let attachmentUploadStatus = 0;
          // This needs to be last as it overwrites everything
          if (isUploading || isDeletingUpload) audiofileUploadStatus = 1;
          if (isUploadingStems || isDeletingStems) stemsUploadStatus = 1;
          if (isUploadingAttachment || !!isDeletingAttachment.length) attachmentUploadStatus = 1;

          const isIngestingAudiofile = (
            _get(data, 'audiofile.conversion_id') === 'ingestion-conversion'
            && !_get(data, 'audiofile.error_key')
          );
          const isIngestingStems = _get(data, 'stems.conversion_id') === 'ingestion-conversion';

          const formDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isAutotagging
            || isUploading
            || isUploadingStems
            || isDeletingUpload
            || isDeletingStems;
          const writableForAgentDisabled = !!relationsNotification
            || isAutotagging
            || isUploading
            || isUploadingStems
            || isDeletingUpload
            || isDeletingStems;
          const audiofileUploadDisabled = !documentRights.canWrite
            || !!relationsNotification;
          const stemsUploadDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isModifying
            || isIngestingStems;
          const attachmentUploadDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isModifying
            || _get(panel, 'isModifying-attachment', false);

          const getASIAction = (key, ASIEntity) => {
            if (!_get(data, key)) return null;

            return () => triggerPanelAutosaveCheck(
              'open',
              [spts.metaMapping[ASIEntity], _get(data, `${key}.id`)],
            );
          };

          return (
            <Fragment>
              <LeftColumn>
                <GenericCover
                  placeholder={trackplaceholder}
                  cover={getGenericDocCoverUrl(data.album?.image)}
                >
                  {this.renderPlayerControls()}
                </GenericCover>
                <DocumentName>
                  {data.title}
                </DocumentName>
                <DocumentType>
                  {`${t(`common:entities.${entity}`)} - ${getNameWithFallback(data.version.names, language)}`}
                </DocumentType>
                {audiofileIsConverted && (
                  <Autotag.Status
                    autotaggingData={_get(additionalData, 'autotagging.data')}
                    documentRights={documentRights}
                    entity={entity}
                    t={t}
                  />
                )}
                <DocumentCreatedAt>
                  {t(`pages:meta.${entity}s.panel.created_at`)}
                  :&nbsp;
                  {formatDateString(data.created_at, localPreferences.dateFormat, true)}
                </DocumentCreatedAt>
                <CopyLink
                  link={shareLink}
                  content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                  tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
                />

                {audiofileIsConverted && (
                  <Autotag.ColumnAction
                    autotagDocument={autotagDocument}
                    autotaggingData={_get(additionalData, 'autotagging.data')}
                    documentRights={documentRights}
                    entity={entity}
                    t={t}
                  />
                )}

                <MetaDownloadDropdown
                  entity={entity}
                  documentId={data.id}
                  documentName={data.title}
                  originalAudioLink={data.audiofile.original?.url}
                  hdMp3AudioLink={data.audiofile.hd_mp3?.url}
                  stemsLink={data.stems.original?.url}
                  canDownload
                  togglerElement={(
                    <DownloadActionWrapper>
                      <DownloadActionIcon />
                      {t(`pages:meta.${entity}s.downloads.toggler_title`)}
                    </DownloadActionWrapper>
                  )}
                />

                <MetaRefAdder
                  placement="bottom-start"
                  tracks={[data.id]}
                  togglerElement={(
                    <AddToRefActionWrapper>
                      <AddToRefActionIcon />
                      {t(`pages:meta.${entity}s.panel.add_to_ref`)}
                    </AddToRefActionWrapper>
                  )}
                />

                <WithMetaSearchRoute
                  entity="track"
                  filters={{ ref_id: [data.ref_id, data.title] }}
                  resetQuery
                  resetAllFilters
                >
                  {routeQuery => (
                    <GenericSearchLink onClick={() => Router.push(`/meta/tracks${routeQuery}`)}>
                      <GenericSearchIcon />
                      {t(`pages:meta.${entity}s.panel.search_tracks`)}
                    </GenericSearchLink>
                  )}
                </WithMetaSearchRoute>

                {!!relationsNotification && (
                  <RelationsWrapper>
                    <RelationTitleWrapper>
                      {t(`pages:meta.${entity}s.panel.ongoing_relations_job`)}
                      <RelationJobTooltip
                        content={t(`pages:meta.${entity}s.panel.ongoing_relations_job_tooltip`)}
                      >
                        <Icons.Informations />
                      </RelationJobTooltip>
                    </RelationTitleWrapper>
                    <LineLoaderWrapper>
                      <LineLoader>
                        <LineInnerLoader progress={relationsNotification.progress} />
                      </LineLoader>
                    </LineLoaderWrapper>
                  </RelationsWrapper>
                )}
              </LeftColumn>
              <RightColumn initTab="general_information">
                <DocumentStatus
                  isModifying={(
                    isModifying
                    || _get(panel, 'isModifying-versions', false)
                  )}
                  isAutotagging={isAutotagging}
                  isDuplicating={isDuplicating}
                  isDeleting={isDeleting}
                  localPreferences={localPreferences}
                  documentRights={documentRights}
                  updatedAt={data.updated_at}
                  duplicateDocument={duplicateDocument}
                  deleteDocument={deleteDocument}
                />
                {this.renderPanelAnchors(data)}
                <DocumentBody>
                  <Form
                    onSubmit={() => {}}
                    initialValues={this.pickFormData(data, documentRights, true)}
                    subscription={{ values: true }}
                    keepDirtyOnReinitialize
                    mutators={{ ...arrayMutators }}
                  >
                    {({ form, values }) => {
                      this.form = form;

                      return (
                        <Fragment>
                          <Autosave
                            debounce={5000}
                            save={(formValues) => {
                              if (formValues.version !== data.version?.id) {
                                return modifyDocument(formValues, [['versions', 'versions']]);
                              }

                              return modifyDocument(formValues);
                            }}
                            nullValuesTransformer={formValues => this.pickFormData(
                              formValues, documentRights, false,
                            )}
                            autosavePanelId={`${spts.META_TRACK_PANEL}_${data.id}`}
                          />
                          <TabElement name="general_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.general_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.general_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <Field
                                  name="title"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.title`)}
                                  fullWidth
                                  validate={validator.title}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <Field
                                  name="display_title"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.display_title`)}
                                  fullWidth
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <InputDouble>
                                  <Field
                                    name="track_ref"
                                    component={MewoInput}
                                    use={Input}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.track_ref`)}
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                  <Field
                                    name="version"
                                    component={MewoInput}
                                    use={SelectInput}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.version`)}
                                    options={trackVersionsOptions}
                                    renderOptionsVariant="classic"
                                    renderValuesVariant="text"
                                    validate={validator.version}
                                    searchable
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                </InputDouble>
                                <Field
                                  name="music_type"
                                  component={MewoInput}
                                  use={SelectInput}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.music_type`)}
                                  options={[
                                    {
                                      label: t(`pages:meta.${entity}s.data.music_type.core`),
                                      value: 'core',
                                    },
                                    {
                                      label: t(`pages:meta.${entity}s.data.music_type.production`),
                                      value: 'production',
                                    },
                                    {
                                      label: t(`pages:meta.${entity}s.data.music_type.other`),
                                      value: 'other',
                                    },
                                  ]}
                                  renderOptionsVariant="classic"
                                  renderValuesVariant="text"
                                  validate={validator.music_type}
                                  searchable
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <Field
                                  name="approved"
                                  component={MewoInput}
                                  use={SwitchInput}
                                  type="boolean"
                                  textOn={t(`pages:meta.${entity}s.panel.approved_on`)}
                                  textOff={t(`pages:meta.${entity}s.panel.approved_off`)}
                                  context="autosave"
                                  disabled={writableForAgentDisabled}
                                />
                              </InputWrapper>
                            </SectionBox>
                            {(documentRights.canWrite || !!_get(data, 'display_artists', []).length) && (
                              <SectionBox>
                                <Fragment>
                                  <FieldArray name="display_artists">
                                    {({ fields }) => fields.map((fieldName, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <InputItemBox key={index}>
                                        <MetaDisplayArtist
                                          documentRights={documentRights}
                                          entity={entity}
                                          fieldName={fieldName}
                                          fields={fields}
                                          formDisabled={formDisabled}
                                          formValues={values}
                                          getASIAction={getASIAction}
                                          index={index}
                                          name="display_artists"
                                          panelData={data}
                                        />
                                      </InputItemBox>
                                    ))}
                                  </FieldArray>
                                  {/** Maximum for this item is always 100 items */}
                                  {(documentRights.canWrite && _get(values, 'display_artists', []).length < 100) && (
                                    <AddItemBtn
                                      onClick={() => form.mutators.push('display_artists', {
                                        artist: {
                                          value: null,
                                          label: '',
                                          search: '',
                                        },
                                        alias: '',
                                      })}
                                      disabled={formDisabled}
                                    >
                                      {t(`pages:meta.${entity}s.panel.display_artists.add`)}
                                    </AddItemBtn>
                                  )}
                                </Fragment>
                              </SectionBox>
                            )}
                            <SectionBox>
                              <InputWrapper>
                                <Field
                                  name="release_date"
                                  component={MewoInput}
                                  use={DatePickerInput}
                                  allowNull={false}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.release_date`)}
                                  fullWidth
                                  context="autosave"
                                  dateFormat={inputDateFormat}
                                  withCalendar
                                  showResetBtn={false}
                                  disabled={formDisabled}
                                />
                                <Field
                                  name="territories"
                                  component={MewoInput}
                                  use={SelectInput}
                                  type="text"
                                  label={t('components:meta_territories_select.label')}
                                  options={territories
                                    .filter(({ code }) => code !== 'WW')
                                    .map(({ code, name }) => ({ label: name, value: code }))}
                                  searchable
                                  multiple
                                  selectAllHelper
                                  selectAllLabel={t('components:meta_territories_select.worldwide')}
                                  renderOptionsVariant="check"
                                  renderValuesVariant="count"
                                  countName={t('components:meta_territories_select.territory')}
                                  countNamePlural={t('components:meta_territories_select.territories')}
                                  countAll={t('components:meta_territories_select.worldwide')}
                                  selectAllValue="WW"
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                              </InputWrapper>
                            </SectionBox>
                            <SectionBox>
                              <InputWrapper>
                                <Field
                                  name="album"
                                  component={MewoInput}
                                  use={ApiSelectInput}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.album`)}
                                  entity="meta/albums"
                                  getEntityLabel={getDocName('album')}
                                  getSearchEntityLabel={getSearchName('album')}
                                  onTheFlyEnabled={documentRights.isOwner}
                                  onTheFlyField="title"
                                  listAdditionalQuery={documentRights.providerId
                                    ? `&provider_id=${documentRights.providerId}`
                                    : ''}
                                  itemAction={getASIAction('album', 'album')}
                                  resetBtnTriggersOnChange
                                  fullWidth
                                  tooltipMessage={t(`pages:meta.${entity}s.panel.album_tooltip`)}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <Field
                                  name="track_number"
                                  component={MewoInput}
                                  use={Input}
                                  type="number"
                                  label={t(`pages:meta.${entity}s.panel.track_number`)}
                                  validate={validator.track_number}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                              </InputWrapper>
                            </SectionBox>
                            <SectionBox>
                              <InputWrapper>
                                <Field
                                  name="lyrics"
                                  component={MewoInput}
                                  use={TextAreaInput}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.lyrics`)}
                                  fullWidth
                                  context="autosave"
                                  disabled={formDisabled}
                                  height="12rem"
                                />
                              </InputWrapper>
                            </SectionBox>
                            <SectionBox>
                              <Field
                                name="descriptions"
                                component={MewoInput}
                                use={InputWithLanguage}
                                defaultLanguage={language}
                                label={t(`pages:meta.${entity}s.panel.descriptions`)}
                                fullWidth
                                isTranslatable
                                inputType="textarea"
                                context="autosave"
                                disabled={formDisabled}
                                height="12rem"
                              />
                            </SectionBox>
                            {documentRights.canAccessNotes && (
                              <SectionBox>
                                <Field
                                  name="notes"
                                  component={MewoInput}
                                  use={TextAreaInput}
                                  label={t(`pages:meta.${entity}s.panel.notes`)}
                                  fullWidth
                                  context="autosave"
                                  disabled={formDisabled}
                                  height="12rem"
                                />
                              </SectionBox>
                            )}

                            {documentRights.isOwner && (
                              <SectionBox>
                                <Fragment>
                                  <FieldArray name="grouping">
                                    {({ fields }) => fields.map((fieldName, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <InputItemBox key={index}>
                                        <Field
                                          name={fieldName}
                                          component={MewoInput}
                                          use={Input}
                                          type="text"
                                          label={t(`pages:meta.${entity}s.panel.grouping.label`)}
                                          fullWidth
                                          validate={validator.grouping(
                                            values.grouping.filter((a, i) => i !== index),
                                          )}
                                          context="autosave"
                                          disabled={formDisabled}
                                        />
                                        <DeleteItemButton
                                          onClick={() => fields.remove(index)}
                                          disabled={formDisabled}
                                        >
                                          {t('common:form.remove')}
                                        </DeleteItemButton>
                                      </InputItemBox>
                                    ))}
                                  </FieldArray>
                                  {/** Maximum for this item is always 50 items */}
                                  {_get(values, 'grouping', []).length < 50 && (
                                  <AddItemBtn
                                    onClick={() => form.mutators.push('grouping', null)}
                                    disabled={formDisabled}
                                  >
                                    {t(`pages:meta.${entity}s.panel.grouping.add`)}
                                  </AddItemBtn>
                                  )}
                                </Fragment>
                              </SectionBox>
                            )}

                          </TabElement>

                          <TabElement name="versions">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.versions_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.versions_information_notice`)}
                            </SectionNotice>
                            <TrackVersions
                              data={_get(additionalData, 'versions.data', [])}
                              documentRights={documentRights}
                              isLoading={_get(panel, 'isLoading-versions', false)}
                              isModifying={_get(panel, 'isModifying-versions', false)}
                              getAdditional={getAdditional}
                              modifyAdditional={modifyAdditional}
                              parentDocument={data}
                            />
                          </TabElement>

                          <TabElement name="publishing_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.publishing_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.publishing_information_notice`)}
                            </SectionNotice>
                            <SectionBox mb="0">
                              <InputWrapper>
                                <InputDouble>
                                  <Field
                                    name="original_prs"
                                    component={MewoInput}
                                    use={SelectInput}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.original_prs`)}
                                    options={prsOptions}
                                    searchable
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                  <Field
                                    name="original_mrs"
                                    component={MewoInput}
                                    use={SelectInput}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.original_mrs`)}
                                    options={mrsOptions}
                                    searchable
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                </InputDouble>
                                <InputTriple>
                                  <Field
                                    name="iswc"
                                    component={MewoInput}
                                    use={Input}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.iswc`)}
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                  <Field
                                    name="cocv"
                                    component={MewoInput}
                                    use={Input}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.cocv`)}
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                  <Field
                                    name="prs_tunecode"
                                    component={MewoInput}
                                    use={Input}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.prs_tunecode`)}
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                </InputTriple>
                              </InputWrapper>
                            </SectionBox>

                            {(documentRights.canWrite || !!_get(data, 'artists_publishing_ownerships', []).length) && (
                              <Fragment>
                                <SectionSubTitle>
                                  {t(`pages:meta.${entity}s.panel.artists_publishing_ownerships.title`)}
                                </SectionSubTitle>
                                <MetaTrackOwnerships
                                  documentRights={documentRights}
                                  form={form}
                                  formDisabled={formDisabled}
                                  formValues={values}
                                  getASIAction={getASIAction}
                                  name="artists_publishing_ownerships"
                                  panelData={data}
                                  rightsTypes={rightsTypes}
                                  territories={territories}
                                />
                              </Fragment>
                            )}

                            {(documentRights.canWrite || !!_get(data, 'publishing_ownerships', []).length) && (
                              <Fragment>
                                <SectionSubTitle>
                                  {t(`pages:meta.${entity}s.panel.publishing_ownerships.title`)}
                                </SectionSubTitle>
                                <MetaTrackOwnerships
                                  documentRights={documentRights}
                                  form={form}
                                  formDisabled={formDisabled}
                                  formValues={values}
                                  getASIAction={getASIAction}
                                  name="publishing_ownerships"
                                  panelData={data}
                                  rightsTypes={rightsTypes}
                                  territories={territories}
                                />
                              </Fragment>
                            )}
                          </TabElement>

                          <TabElement name="master_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.master_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.master_information_notice`)}
                            </SectionNotice>
                            <SectionBox mb="0">
                              <InputDouble>
                                <Field
                                  name="original_nrs"
                                  component={MewoInput}
                                  use={SelectInput}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.original_nrs`)}
                                  options={nrsOptions}
                                  searchable
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <Field
                                  name="isrc"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.isrc`)}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                              </InputDouble>
                            </SectionBox>

                            {(documentRights.canWrite || !!_get(data, 'artists_master_ownerships', []).length) && (
                              <Fragment>
                                <SectionSubTitle>
                                  {t(`pages:meta.${entity}s.panel.artists_master_ownerships.title`)}
                                </SectionSubTitle>
                                <MetaTrackOwnerships
                                  documentRights={documentRights}
                                  form={form}
                                  formDisabled={formDisabled}
                                  formValues={values}
                                  getASIAction={getASIAction}
                                  name="artists_master_ownerships"
                                  panelData={data}
                                  rightsTypes={rightsTypes}
                                  territories={territories}
                                />
                              </Fragment>
                            )}

                            {(documentRights.canWrite || !!_get(data, 'master_ownerships', []).length) && (
                              <Fragment>
                                <SectionSubTitle>
                                  {t(`pages:meta.${entity}s.panel.master_ownerships.title`)}
                                </SectionSubTitle>
                                <MetaTrackOwnerships
                                  documentRights={documentRights}
                                  form={form}
                                  formDisabled={formDisabled}
                                  formValues={values}
                                  getASIAction={getASIAction}
                                  name="master_ownerships"
                                  panelData={data}
                                  rightsTypes={rightsTypes}
                                  territories={territories}
                                />
                              </Fragment>
                            )}
                          </TabElement>

                          <TabElement name="tags">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.tags_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.tags_information_notice`)}
                            </SectionNotice>
                            {audiofileIsConverted && (
                              <Autotag.PanelSection
                                autotagDocument={autotagDocument}
                                autotaggingData={_get(additionalData, 'autotagging.data')}
                                documentRights={documentRights}
                                entity={entity}
                                t={t}
                              />
                            )}
                            <SectionBox>
                              <Field name="tags">
                                {({ input }) => (
                                  <MetaTags
                                    currentTagsTitle={t(`pages:meta.${entity}s.panel.current_tags`)}
                                    statusData={_get(input, 'value', []).map(tagId => ({
                                      id: tagId,
                                      status: 2,
                                    }))}
                                    disabled={formDisabled}
                                    onAdd={tagId => input.onChange([
                                      ..._get(input, 'value', []),
                                      tagId,
                                    ])}
                                    onRemove={tagId => input.onChange([
                                      ..._get(input, 'value', [])
                                        .filter(id => id !== tagId),
                                    ])}
                                  />
                                )}
                              </Field>
                            </SectionBox>
                          </TabElement>

                          {documentRights.isOwner && hasCustomFields && (
                            <TabElement name="custom_fields_information">
                              <SectionTitle>{t(`pages:meta.${entity}s.panel.custom_fields_information`)}</SectionTitle>
                              <SectionNotice>
                                {t(`pages:meta.${entity}s.panel.custom_fields_information_notice`)}
                              </SectionNotice>
                              <SectionBox>
                                <InputWrapper>
                                  <MetaCustomFields entity={entity} formDisabled={formDisabled} />
                                </InputWrapper>
                              </SectionBox>
                            </TabElement>
                          )}

                          <MetaRightsInformations
                            entity={entity}
                            docAgents={data.agents}
                            docModos={data.showcase}
                            docPublic={data.public}
                            documentRights={documentRights}
                            formDisabled={formDisabled}
                            modifyAdditional={modifyAdditional}
                            writableForAgentDisabled={writableForAgentDisabled}
                          />
                        </Fragment>
                      );
                    }}
                  </Form>

                  <TabElement name="uploads_information">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.uploads_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.uploads_information_notice`)}
                    </SectionNotice>
                    <SectionBox>
                      <Uploader
                        name="audiofile"
                        accept="audio/*"
                        value={audiofile}
                        status={audiofileUploadStatus}
                        disabled={audiofileUploadDisabled}
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        onChange={(nextValue) => {
                          if (!nextValue) return deleteDocumentFile('audiofile');
                          return uploadDocumentFile(nextValue, 'audiofile');
                        }}
                        maxSize={entityConfig.panelUploads.audiofile.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.audiofile`)}
                      </FileHelper>
                      {isIngestingAudiofile && (
                        <MiscHelper mt="1.2rem">
                          {t(`pages:meta.${entity}s.panel.audiofile_ingestion_ongoing`)}
                        </MiscHelper>
                      )}
                      {_get(data, 'audiofile.error_key') && (
                        <MiscHelper mt="1.2rem" isError>
                          {t(`errors:conversion.${_get(data, 'audiofile.error_key')}_desc`)}
                        </MiscHelper>
                      )}
                    </SectionBox>
                    <SectionBox>
                      <Uploader
                        name="stems"
                        accept=".zip"
                        value={stems}
                        status={stemsUploadStatus}
                        disabled={stemsUploadDisabled}
                        isUploading={isUploadingStems}
                        uploadProgress={uploadStemsProgress}
                        onChange={(nextValue) => {
                          if (!nextValue) return deleteDocumentFile('stems');
                          return uploadDocumentFile(nextValue, 'stems');
                        }}
                        maxSize={entityConfig.panelUploads.stems.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.stems`)}
                      </FileHelper>
                      {isIngestingStems && (
                        <MiscHelper mt="1.2rem">
                          {t(`pages:meta.${entity}s.panel.stems_ingestion_ongoing`)}
                        </MiscHelper>
                      )}
                    </SectionBox>

                    <SectionTitle>{t(`pages:meta.${entity}s.panel.attachment_uploads_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.attachment_uploads_information_notice`)}
                    </SectionNotice>
                    <SectionBox>
                      <Uploader
                        name="attachment"
                        accept=""
                        status={attachmentUploadStatus}
                        disabled={attachmentUploadDisabled}
                        isUploading={isUploadingAttachment}
                        uploadProgress={uploadAttachmentProgress}
                        onChange={nextValue => uploadDocumentFile(
                          nextValue,
                          'attachment',
                          `${t(`pages:meta.${entity}s.panel.attachment_default_name`)} ${data.attachments.length + 1}`,
                        )}
                        maxSize={entityConfig.panelUploads.attachment.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.attachment`)}
                      </FileHelper>
                    </SectionBox>
                    {[...data.attachments].reverse().map(attachment => (
                      <SectionBox key={attachment.id}>
                        <Form
                          onSubmit={a => modifyAdditional(
                            `/meta/uploads/track/${data.id}/attachment/${attachment.id}`,
                            'put',
                            { name: a.attachment_name },
                            'attachment',
                            true,
                          )}
                          initialValues={{ attachment_name: attachment.name }}
                        >
                          {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                              <Flex align="center" mb="1rem">
                                <Field
                                  name="attachment_name"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.attachment_name`)}
                                  disabled={attachmentUploadDisabled}
                                  validate={validator.attachment_name}
                                />
                                <SaveItemButton
                                  type="submit"
                                  disabled={attachmentUploadDisabled}
                                >
                                  {t('common:form.save')}
                                </SaveItemButton>
                              </Flex>
                            </form>
                          )}
                        </Form>
                        <Uploader
                          name="attachment_url"
                          accept=""
                          status={isDeletingAttachment.includes(attachment.id) ? 1 : 2}
                          disabled={attachmentUploadDisabled}
                          onChange={() => deleteDocumentFile('attachment', attachment.id)}
                          value={{
                            name: attachment.file_name,
                            url: attachment.url,
                          }}
                        />
                      </SectionBox>
                    ))}
                  </TabElement>
                </DocumentBody>
              </RightColumn>
            </Fragment>
          );
        }}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ player, core, user, meta, options }) {
  return {
    contextId: player.contextId,
    contextName: player.contextName,
    isPlaying: player.playerState === playerStates.PLAYING,
    localPreferences: core.localPreferences,
    relationsNotifications: user.relationsNotifications.tracks,
    customFields: meta.customFields.data,
    trackVersions: options.data.trackversions,
    languages: options.data.languages,
    prs: options.data.performingrightssocieties,
    mrs: options.data.mechanicalrightssocieties,
    nrs: options.data.neighboringrightssocieties,
    territories: options.data.territories,
    rightsTypes: options.data.rightstypes,
    providers: meta.providers,
  };
}

export default compose(
  connect(mapStateToProps, {
    pause: pauseBase,
    play: playBase,
    setCurrentTrack: setCurrentTrackBase,
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
  }),
  withTranslation(['common', 'pages', 'components', 'errors']),
)(MetaTrack);
