// =============================
// Imports
// =============================

import styled from 'styled-components';

import Pagination from '../../../../presentationals/pagination';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 1rem;

  > *:first-child {
    width: 8%;
  }

  > *:nth-child(2) {
    margin: 0 4%;
    width: 32%;
  }

  > *:last-child {
    width: 52%;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Line = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  padding: 1rem 2rem;
  position: relative;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  > *:first-child {
    width: 8%;
  }

  > *:nth-child(2) {
    margin: 0 4%;
    width: 32%;
  }

  > *:last-child {
    width: 52%;
  }
`;

export const Occurrence = styled.div`
  line-height: 1.2;
`;

export const OriginalValue = styled.div`
  color: ${({ theme }) => theme.colors.input.text};
  line-height: 1.2;
`;

export const StyledPagination = styled(Pagination)`
  margin-top: 4rem;
`;
