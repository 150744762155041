// =============================
// Imports
// =============================

import styled, { keyframes } from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';

// =============================
// Styles
// =============================

const slideUp = keyframes`
  from {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 4rem, 0);
  }

  to {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }
`;

export const StyledModal = styled(props => <Modal {...props} classes={{ root: 'root ignore-react-onclickoutside' }} />)`
  && {
    &.root {
      padding: 6rem;
    }
  }
`;

export const StyledBackdrop = styled(props => <Backdrop {...props} classes={{ root: 'root ignore-react-onclickoutside' }} />)`
  && {
    &.root {
      background-color: rgba(255, 255, 255, 0.95);
      /** Need !important to override inline style */
      transition: opacity 300ms !important;
    }
  }
`;

export const InnerFade = styled.div.attrs(() => ({
  className: 'ignore-react-onclickoutside',
}))`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  outline: none;
  position: relative;
  width: 100%;
`;

export const CloseWHitBox = styled.div`
  cursor: pointer;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Content = styled.div`
  align-items: center;
  animation: ${slideUp} 400ms ease-in-out 1;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
  max-width: 100%;
  opacity: 0;
  padding: 2rem;
`;
