// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import Link from '../../other/link';

import { headerHeight } from '../header/styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: #ffffff;
  color: #999999;
  flex-shrink: 0;
  height: calc(100vh - ${headerHeight});
  left: 0;
  overflow-y: auto;
  overflow-x: auto;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: fixed;
  top: ${headerHeight};
  width: 22rem;
  ${({ theme }) => theme.helpers.hideScrollbar};

  ${({ theme }) => theme.mediaAbove.sxga`
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    width: 24rem;
  `};
`;

export const CategoryWrapper = styled.div`
  margin-bottom: 3.2rem;
`;

export const Category = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

export const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 1.6rem;
  text-decoration: none;
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.75;
  }

  ${({ active }) => active
    && css`
      color: ${({ theme }) => theme.colors.primary};
    `};
`;
