// =============================
// Imports
// =============================

// External dependencies
import styled from 'styled-components';
import { Button, Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const ContactBox = styled.div`
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

export const ContactInnerBox = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
`;

export const DeleteButton = styled(Button).attrs({
  color: 'error',
  fillVariant: 'ghost',
  size: 'small',
})`
  margin-left: 2rem;
  position: relative;
  top: 1rem;
`;

export const AddContactBtn = styled(Button).attrs(() => ({
  color: 'primary',
  size: 'small',
}))``;

export const ContactDisplay = styled.div`
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

export const NameRole = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 1.2rem;
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.normal};

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
`;

export const EmailIcon = styled(Icons.Email).attrs({
  fill: '#999999',
  width: '1.6rem',
})`
  margin-left: 0.2rem;
  margin-right: 1.2rem;
`;

export const LocationIcon = styled(Icons.Location).attrs({
  fill: '#b2b2b2',
  width: '1.8rem',
})`
  margin-left: 0.1rem;
  margin-right: 1.1rem;
`;

export const PhoneIcon = styled(Icons.Phone).attrs({
  fill: '#b2b2b2',
  width: '1.8rem',
})`
  margin-left: 0.1rem;
  margin-right: 1.1rem;
`;
