// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isOpened: false,
  requestedClose: false,
  panels: [],
  postAutosaveAction: {
    actionName: null,
    actionArgs: [],
  },
};

// =============================
// Reducer
// =============================

export default function sidepanel(state = initialState, action) {
  switch (action.type) {
    // Base actions
    case acts.OPEN_SIDE_PANEL: {
      return {
        ...state,
        isOpened: true,
        panels: [action.payload, ...state.panels],
      };
    }

    case acts.REMOVE_SIDE_PANEL: {
      if (state.panels.length <= 1) return initialState;

      return {
        ...state,
        panels: [...state.panels.filter(p => p.uuid !== action.payload)],
      };
    }

    case acts.SET_PANEL_DATA: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, ...action.payload };
        }),
      };
    }

    case acts.REQUEST_CLOSE_SIDE_PANEL: {
      return {
        ...state,
        requestedClose: true,
      };
    }

    case acts.CLOSE_SIDE_PANEL: {
      return initialState;
    }

    // Autosave actions
    case acts.SET_PANEL_HAS_AUTOSAVE: {
      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== action.payload.uuid) return p;
          return { ...p, hasAutosave: action.payload.value };
        }),
      };
    }

    case acts.TRIGGER_PANEL_AUTOSAVE_CHECK: {
      return {
        ...state,
        postAutosaveAction: {
          ...state.postAutosaveAction,
          actionName: action.payload.actionName,
          actionArgs: action.payload.actionArgs,
        },
      };
    }

    case acts.RESOLVE_PANEL_AUTOSAVE_CHECK: {
      return {
        ...state,
        postAutosaveAction: initialState.postAutosaveAction,
      };
    }

    // Get
    case acts.GET_SIDE_PANEL_DATA_LOADING: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isLoading: true };
        }),
      };
    }

    case acts.GET_SIDE_PANEL_DATA_SUCCESS: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isLoading: false };
        }),
      };
    }

    case acts.GET_SIDE_PANEL_DATA_FAILURE: {
      const { uuid, message, reqId } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isLoading: false, getErrorMsg: message, getErrorReqId: reqId };
        }),
      };
    }

    // Get & set additional
    case acts.GET_SIDE_PANEL_ADDITIONAL_DATA_LOADING: {
      const { uuid, additionalDataKey } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [`isLoading-${additionalDataKey}`]: true };
        }),
      };
    }

    case acts.SET_PANEL_ADDITIONAL_DATA: {
      const { uuid, additionalDataKey } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return {
            ...p,
            additionalData: {
              ...p.additionalData,
              [additionalDataKey]: action.payload.data,
            },
          };
        }),
      };
    }

    case acts.GET_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS:
    case acts.GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE: {
      const { uuid, additionalDataKey } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [`isLoading-${additionalDataKey}`]: false };
        }),
      };
    }

    // Modify
    case acts.MODIFY_SIDE_PANEL_DATA_LOADING: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isModifying: true };
        }),
      };
    }

    case acts.MODIFY_SIDE_PANEL_DATA_SUCCESS:
    case acts.MODIFY_SIDE_PANEL_DATA_FAILURE: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isModifying: false };
        }),
      };
    }

    // Modify additional
    case acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_LOADING: {
      const { uuid, additionalDataKey } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [`isModifying-${additionalDataKey}`]: true };
        }),
      };
    }

    case acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS:
    case acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE: {
      const { uuid, additionalDataKey } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [`isModifying-${additionalDataKey}`]: false };
        }),
      };
    }

    // Autotag
    case acts.AUTOTAG_SIDE_PANEL_LOADING: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isAutotagging: true };
        }),
      };
    }

    case acts.AUTOTAG_SIDE_PANEL_SUCCESS:
    case acts.AUTOTAG_SIDE_PANEL_FAILURE: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isAutotagging: false };
        }),
      };
    }

    // Duplicate
    case acts.DUPLICATE_SIDE_PANEL_DATA_LOADING: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isDuplicating: true };
        }),
      };
    }

    case acts.DUPLICATE_SIDE_PANEL_DATA_SUCCESS:
    case acts.DUPLICATE_SIDE_PANEL_DATA_FAILURE: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isDuplicating: false };
        }),
      };
    }

    // Upload
    case acts.UPLOAD_SIDE_PANEL_FILE_LOADING: {
      const { uuid, fileType } = action.payload;

      const loadingKey = {
        image: 'isUploading',
        audiofile: 'isUploading',
        stems: 'isUploadingStems',
        attachment: 'isUploadingAttachment',
      }[fileType];

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [loadingKey]: true };
        }),
      };
    }

    case acts.UPLOAD_SIDE_PANEL_FILE_PROGRESS: {
      const { uuid, fileType } = action.payload;

      const progressKey = {
        image: 'uploadProgress',
        audiofile: 'uploadProgress',
        stems: 'uploadStemsProgress',
        attachment: 'uploadAttachmentProgress',
      }[fileType];

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [progressKey]: action.payload.progress };
        }),
      };
    }

    case acts.UPLOAD_SIDE_PANEL_FILE_SUCCESS:
    case acts.UPLOAD_SIDE_PANEL_FILE_FAILURE:
    case acts.CANCEL_UPLOAD_SIDE_PANEL_FILE: {
      const { uuid, fileType } = action.payload;

      const loadingKey = {
        image: 'isUploading',
        audiofile: 'isUploading',
        stems: 'isUploadingStems',
        attachment: 'isUploadingAttachment',
      }[fileType];

      const progressKey = {
        image: 'uploadProgress',
        audiofile: 'uploadProgress',
        stems: 'uploadStemsProgress',
        attachment: 'uploadAttachmentProgress',
      }[fileType];

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [loadingKey]: false, [progressKey]: 0 };
        }),
      };
    }

    // Delete upload
    case acts.DELETE_SIDE_PANEL_FILE_LOADING: {
      const { uuid, fileType, fileId } = action.payload;

      if (fileType === 'attachment' && fileId) {
        return {
          ...state,
          panels: state.panels.map((p) => {
            if (p.uuid !== uuid) return p;

            return {
              ...p,
              isDeletingAttachment: [
                ...p.isDeletingAttachment,
                fileId,
              ],
            };
          }),
        };
      }

      const loadingKey = {
        image: 'isDeletingUpload',
        audiofile: 'isDeletingUpload',
        stems: 'isDeletingStems',
      }[fileType];

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [loadingKey]: true };
        }),
      };
    }

    case acts.DELETE_SIDE_PANEL_FILE_SUCCESS:
    case acts.DELETE_SIDE_PANEL_FILE_FAILURE: {
      const { uuid, fileType, fileId } = action.payload;

      if (fileType === 'attachment' && fileId) {
        return {
          ...state,
          panels: state.panels.map((p) => {
            if (p.uuid !== uuid) return p;

            return {
              ...p,
              isDeletingAttachment: [
                ...p.isDeletingAttachment.filter(a => a !== fileId),
              ],
            };
          }),
        };
      }

      const loadingKey = {
        image: 'isDeletingUpload',
        audiofile: 'isDeletingUpload',
        stems: 'isDeletingStems',
      }[fileType];

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, [loadingKey]: false };
        }),
      };
    }

    // Delete
    case acts.DELETE_SIDE_PANEL_LOADING: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isDeleting: true };
        }),
      };
    }

    case acts.DELETE_SIDE_PANEL_SUCCESS:
    case acts.DELETE_SIDE_PANEL_FAILURE: {
      const { uuid } = action.payload;

      return {
        ...state,
        panels: state.panels.map((p) => {
          if (p.uuid !== uuid) return p;
          return { ...p, isDeleting: false };
        }),
      };
    }

    default:
      return state;
  }
}
