// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _mapValues from 'lodash/mapValues';
import _mapKeys from 'lodash/mapKeys';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import _trim from 'lodash/trim';

// Config
import { i18n } from '../config/i18n';
import locales from '../config/locales';

// =============================
// Helpers
// =============================

export const isServer = () => typeof window === 'undefined';

export const getApiUrl = (suffix) => {
  let apiUrl = process.env.API_URL;

  if (
    process.env.APP_ENV !== 'prod'
    && !isServer()
    && window.localStorage.getItem('devApiUrl')
  ) {
    apiUrl = window.localStorage.getItem('devApiUrl');
  }

  if (suffix) apiUrl += `/${suffix}`;

  return apiUrl;
};

export const getXPreferredLanguage = () => i18n.language || locales.defaultLanguage;

export const getSiteOrigin = (forceIsServer = false) => {
  if (isServer() || forceIsServer) return global.LOCATION_ORIGIN;
  return window.location.origin;
};

export const isHttps = (forceIsServer = false) => (isServer() || forceIsServer
  ? global.LOCATION_PROTOCOL === 'https:'
  : window.location.protocol === 'https:');

export const getCookieConfig = (expiryDays = null) => {
  const opts = {};

  if (isHttps()) opts.secure = true;
  if (expiryDays) opts.expires = expiryDays;

  return opts;
};

export const getDefaultTitle = () => process.env.WEBSITE_TITLE;

export const getWebsiteTitle = prefix => _compact([prefix, getDefaultTitle()]).join(' - ');

export function getLanguagesForInputWithLanguageSwitcher(languages, values = []) {
  return Object.entries(languages).map(([k, v]) => ({
    label: v.nativeName,
    value: k,
    filled: !!values.find(translation => translation.locale === k)?.value,
  }));
}

export function getLanguagesForTextEditorSwitcher(languages, values = []) {
  const translationLocales = Object.keys(values);

  return Object.entries(languages).map(([k, v]) => ({
    label: v.nativeName,
    value: k,
    filled: !!(values[k] && translationLocales.includes(k)),
  }));
}

export function getLanguagesForSelectInput(languages) {
  return Object.entries(languages).map(([k, v]) => ({
    label: v.nativeName,
    value: k,
  }));
}

// NOTE: finds first appearing locale, if there are no locales returns english
export function getLocale(languages, translationLocales) {
  if (!translationLocales.length || _isEmpty(languages)) return locales.defaultLanguage;
  return Object.keys(languages).find(l => translationLocales.includes(l));
}

// adapts from request files to uploader files
export function apiAdapterFile(file) {
  if (!_get(file, 'original.file_name', undefined)) return null;

  return {
    name: _get(file, 'original.file_name', undefined),
    url: _get(file, 'original.url', undefined),
    original: file,
  };
}

// determines state for requests
export function apiFileState(nextFile, prevFile) {
  if (!nextFile && prevFile) {
    return {
      type: 'deleted',
    };
  }

  if (!nextFile && !prevFile) {
    return {
      type: 'unchanged',
    };
  }

  if (nextFile.original) {
    // If nextFile has .original it means that it was already uploaded
    return {
      type: 'unchanged',
    };
  }

  // Cases left:
  // !prevFile && nextFile
  // prevFile && nextFile -> Delete old one no matter what
  const data = new FormData();
  data.append('file', nextFile);

  return {
    type: 'changed',
    data,
  };
}

// sleep promise
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// change all keys of object or array of objects
export const changeKeysDeep = cb => (obj) => {
  if (Array.isArray(obj)) return obj.map(changeKeysDeep(cb));

  if (_isObject(obj)) {
    return _mapValues(
      _mapKeys(obj, (_v, k) => cb(k)),
      changeKeysDeep(cb),
    );
  }

  return obj;
};

export const camelCaseKeysDeep = changeKeysDeep(_camelCase);
export const snakeCaseKeysDeep = changeKeysDeep(_snakeCase);

// Encode string and remove percent char
// This prevent React Link of throwing URI malformed error as % is invalid in RFC 3986
export const removePercentChar = value => _trim(value.replace('%', ''));
