/* eslint-disable max-len */
// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Flex } from '@mewo/components';
import Clipboard from 'react-clipboard.js';
import _get from 'lodash/get';

// Hoc
import { withTranslation } from '../../../../config/i18n';

// Components
import Autotag from './autotag';

// Helpers
import * as pth from '../../../../helpers/proptypes';

// Styles
import {
  ButtonWrapper,
  CenteredWrapper,
  InheritSize,
  ModalContent,
  ModalDesc,
  ModalTitle,
  StyledButton,
} from '../../../layouts/global.styles';

import { ClipboardIcon, ConfirmationModal, ErrorRequestText, State } from './styles';

// =============================
// Component
// =============================

export class Base extends Component {
  static propTypes = {
    autotag: PropTypes.func,
    autotagOptions: PropTypes.shape({
      fnc: PropTypes.func,
      getAdditionalList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    }),
    cancelUploadFile: PropTypes.func,
    children: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    deleteFile: PropTypes.func,
    duplicate: PropTypes.func,
    get: PropTypes.func.isRequired,
    getAdditional: PropTypes.func,
    getAdditionalOptions: PropTypes.shape({
      fnc: PropTypes.func,
      list: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        ),
      ),
    }),
    lang: PropTypes.shape({
      autotagTitle: PropTypes.string,
      autotagDesc: PropTypes.string,
      deleteConfirmTitle: PropTypes.string,
      deleteConfirmDesc: PropTypes.string,
    }),
    modify: PropTypes.func.isRequired,
    modifyAdditional: PropTypes.func,
    panel: pth.sidePanel.isRequired,
    t: PropTypes.func.isRequired,
    uploadFile: PropTypes.func,
  };

  static defaultProps = {
    autotag: null,
    autotagOptions: {},
    cancelUploadFile: null,
    deleteFile: null,
    duplicate: null,
    getAdditionalOptions: {},
    getAdditional: null,
    modifyAdditional: null,
    lang: {},
    uploadFile: null,
  };

  state = {
    autotagModal: {
      opened: false,
      nbTracks: 0,
      nbBillableTracks: 0,
      nbTrackVersions: 0,
      nbBillableTrackVersions: 0,
    },
    deleteConfirmationOpened: false,
  };

  async componentDidMount() {
    const { get, getAdditional, getAdditionalOptions } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    let { panel } = this.props;

    await get(panel.uuid, panel.id);

    // NOTE: Update variable after get to have data within object
    // eslint-disable-next-line react/destructuring-assignment
    panel = this.props.panel;

    if (getAdditional && _get(getAdditionalOptions, 'list', []).length) {
      await Promise.all(
        _get(getAdditionalOptions, 'list', [])
          .filter((args) => {
            const [,,, condition] = args;
            return !!(!condition || (typeof condition === 'function' && condition(panel.data)));
          })
          .map((args) => {
            const [endpoint, dataKey, option] = args;
            return getAdditional(panel.uuid, panel.id, endpoint, dataKey, option);
          }),
      );
    }
  }

  getAdditionalWithPanel = async (...args) => {
    const { getAdditional, panel } = this.props;

    if (getAdditional) {
      await getAdditional(panel.uuid, panel.id, ...args);
    }
  };

  refreshPanel = async (refreshGrid = false) => {
    const { get, getAdditional, getAdditionalOptions, panel, requestGridRefresh } = this.props;

    await get(panel.uuid, panel.id);

    if (getAdditional && _get(getAdditionalOptions, 'list', []).length) {
      await Promise.all(
        _get(getAdditionalOptions, 'list', [])
          .filter((args) => {
            const [,,, condition] = args;
            return !!(!condition || (typeof condition === 'function' && condition(panel.data)));
          })
          .map((args) => {
            const [endpoint, dataKey, option] = args;
            return getAdditional(panel.uuid, panel.id, endpoint, dataKey, option);
          }),
      );
    }

    if (refreshGrid && requestGridRefresh) {
      requestGridRefresh();
    }
  };

  refreshGrid = () => {
    const { requestGridRefresh } = this.props;

    if (requestGridRefresh) {
      requestGridRefresh();
    }
  };

  modifyDocument = async (nextData, getAdditionalList = []) => {
    const { getAdditional, modify, panel } = this.props;

    await modify(panel.uuid, panel.id, nextData);

    if (getAdditional && getAdditionalList.length) {
      await Promise.all(
        getAdditionalList.map(args => getAdditional(panel.uuid, panel.id, ...args)),
      );
    }
  };

  modifyAdditionalWithPanel = async (...args) => {
    const { modifyAdditional, panel } = this.props;

    if (modifyAdditional) {
      await modifyAdditional(panel.uuid, panel.id, ...args);
    }
  };

  autotagDocument = async (data) => {
    const { autotag, autotagOptions, getAdditional, panel } = this.props;

    if (autotag) {
      const res = await autotag(panel.uuid, panel.id, data);

      if (!res.payload.deferred && getAdditional && _get(autotagOptions, 'getAdditionalList', []).length) {
        await Promise.all(
          autotagOptions.getAdditionalList.map(args => getAdditional(panel.uuid, panel.id, ...args)),
        );
      }
    }
  };

  duplicateDocument = async () => {
    const { duplicate, panel } = this.props;

    if (duplicate) {
      await duplicate(panel.uuid, panel.id);
    }
  };

  uploadDocumentFile = async (...args) => {
    const { panel, uploadFile } = this.props;

    if (uploadFile) {
      await uploadFile(panel.uuid, panel.id, ...args);
    }
  };

  cancelUploadDocumentFile = (...args) => {
    const { cancelUploadFile, panel } = this.props;

    if (cancelUploadFile) {
      cancelUploadFile(panel.uuid, panel.id, ...args);
    }
  };

  deleteDocumentFile = async (...args) => {
    const { deleteFile, panel } = this.props;

    if (deleteFile) {
      await deleteFile(panel.uuid, panel.id, ...args);
    }
  };

  deleteDocument = async () => {
    const { del, panel } = this.props;

    await del(panel.uuid, panel.id);
  };

  toggleAutotagModal = (opened = true, options) => {
    this.setState({
      autotagModal: {
        opened,
        ...(opened
          ? options
          : {
            nbTracks: 0,
            nbBillableTracks: 0,
            nbTrackVersions: 0,
            nbBillableTrackVersions: 0,
          }),
      },
    });
  };

  toggleDeleteConfirmationBox = (nextValue = true) => {
    this.setState({ deleteConfirmationOpened: nextValue });
  };

  render() {
    const { children, lang, panel, t } = this.props;
    const { autotagModal, deleteConfirmationOpened } = this.state;

    // If panel already has data, we are performing a refresh
    if (!panel.data && panel.isLoading) {
      return (
        <CenteredWrapper>
          <State>{t('common:misc.loading')}</State>
        </CenteredWrapper>
      );
    }

    if (panel.getErrorMsg) {
      return (
        <CenteredWrapper>
          <Flex direction="column" align="center">
            <State>{panel.getErrorMsg}</State>
            {panel.getErrorReqId && (
              <Clipboard component="div" data-clipboard-text={panel.getErrorReqId}>
                <ErrorRequestText>
                  <span>{t('common:misc.req_id')}</span>
                  :&nbsp;
                  {panel.getErrorReqId}
                  <ClipboardIcon />
                </ErrorRequestText>
              </Clipboard>
            )}
          </Flex>
        </CenteredWrapper>
      );
    }

    return (
      <InheritSize>
        {deleteConfirmationOpened && (
          <ConfirmationModal>
            <ModalContent width="35">
              <ModalTitle>
                {lang.deleteConfirmTitle || t('components:side_panel.delete_confirm_title')}
              </ModalTitle>
              <ModalDesc>
                {lang.deleteConfirmDesc || t('components:side_panel.delete_confirm_desc')}
              </ModalDesc>
              <ButtonWrapper>
                <StyledButton
                  onClick={() => this.toggleDeleteConfirmationBox(false)}
                  color="cancel"
                  fillVariant="outline"
                  type="button"
                >
                  {t('common:form.cancel')}
                </StyledButton>

                <StyledButton onClick={this.deleteDocument} color="error" type="button">
                  {t('common:form.delete')}
                </StyledButton>
              </ButtonWrapper>
            </ModalContent>
          </ConfirmationModal>
        )}

        <Autotag
          autotag={this.autotagDocument}
          autotagModal={autotagModal}
          lang={lang}
          panel={panel}
          t={t}
          toggleAutotagModal={this.toggleAutotagModal}
        />

        {children({
          getAdditional: this.getAdditionalWithPanel,
          refreshPanel: this.refreshPanel,
          refreshGrid: this.refreshGrid,
          modifyDocument: this.modifyDocument,
          modifyAdditional: this.modifyAdditionalWithPanel,
          autotagDocument: this.toggleAutotagModal,
          duplicateDocument: this.duplicateDocument,
          uploadDocumentFile: this.uploadDocumentFile,
          cancelUploadDocumentFile: this.cancelUploadDocumentFile,
          deleteDocumentFile: this.deleteDocumentFile,
          deleteDocument: this.toggleDeleteConfirmationBox,
        })}
      </InheritSize>
    );
  }
}

export default compose(
  withTranslation(['components', 'common']),
  connect(
    () => ({}),
    (dispatch, props) => ({
      get: (...args) => dispatch(props.get(...args)),
      modify: (...args) => dispatch(props.modify(...args)),
      del: (...args) => dispatch(props.del(...args)),
      getAdditional: typeof _get(props, 'getAdditionalOptions.fnc') === 'function'
        ? (...args) => dispatch(props.getAdditionalOptions.fnc(...args)) : null,
      requestGridRefresh: typeof props.requestGridRefresh === 'function'
        ? (...args) => dispatch(props.requestGridRefresh(...args)) : null,
      autotag: typeof _get(props, 'autotagOptions.fnc') === 'function'
        ? (...args) => dispatch(props.autotagOptions.fnc(...args)) : null,
      modifyAdditional: typeof props.modifyAdditional === 'function'
        ? (...args) => dispatch(props.modifyAdditional(...args)) : null,
      duplicate: typeof props.duplicate === 'function'
        ? (...args) => dispatch(props.duplicate(...args)) : null,
      uploadFile: typeof props.uploadFile === 'function'
        ? (...args) => dispatch(props.uploadFile(...args)) : null,
      cancelUploadFile: typeof props.cancelUploadFile === 'function'
        ? (...args) => dispatch(props.cancelUploadFile(...args)) : null,
      deleteFile: typeof props.deleteFile === 'function'
        ? (...args) => dispatch(props.deleteFile(...args)) : null,
    }),
  ),
)(Base);
