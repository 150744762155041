// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.a`
  cursor: pointer;
  display: block;
  text-decoration: none;

  ${({ inline }) => inline
    && css`
      display: inline-block;
    `};

  ${({ noHover }) => !noHover
    && css`
      transition: opacity ${({ theme }) => theme.transition.fast};

      &:hover {
        opacity: 0.75;
      }

      * {
        transition: opacity ${({ theme }) => theme.transition.fast};

        &:hover {
          opacity: 0.75;
        }
      }
    `};

   ${({ textEllipsis, theme }) => textEllipsis && theme.helpers.textEllipsis}
`;
