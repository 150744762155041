// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Chip } from '@mewo/components';

import { chipColors } from '../../../variables';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const StyledChip = styled(Chip).attrs(({ type }) => ({
  color: chipColors.music_type[type],
  size: 'small',
}))``;
