// =============================
// Imports
// =============================

// External Dependencies
import { v4 as uuid } from 'uuid';
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Flex } from '@mewo/components';
import playlistplaceholder from '@mewo/components/assets/images/placeholders/playlist_500x500.png?webp';
import _get from 'lodash/get';

// Config
import { withTranslation } from '../../../../config/i18n';

// Actions
import { panelActions, triggerAutosaveCheck } from '../../../../store/actions/SidePanelActions';

// Constants
import * as spts from '../../../../store/constants/SidePanelTypes';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import DocumentStatus from '../documentStatus';
import Autosave from '../../../other/autosave';
import MewoInput from '../../../presentationals/mewoInput';
import MetaCustomFields from '../metaCustomFields';
import SelectInput from '../../../presentationals/inputs/selectInput';
import CheckboxInput from '../../../presentationals/inputs/checkboxInput';
import CopyLink from '../copyLink';
import List from '../../../other/charts/list';
import ApiSelectInput from '../../../containers/inputs/apiSelectInput';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getSiteOrigin } from '../../../../helpers/misc';
import { getDocumentRights, getGenericDocCoverUrl } from '../../../../helpers/meta-common';
import { formatDateString } from '../../../../helpers/dates';

// Styles
import {
  DocumentBody,
  DocumentCreatedAt,
  DocumentName,
  DocumentType,
  GenericAction,
  GenericCover,
  GenericText,
  LeftColumn,
  RightColumn,
  SectionBox,
  SectionNotice,
  SectionTitle,
  TabElement,
  TabLink,
  TabLinksWrapper,
} from '../common.styles';

import {
  SingleStatBox,
  StatNumberValue,
  StatTitle,
  UserChip,
  UserChipsWrapper,
  InvitationWrapper,
  InvitationInnerWrapper,
  InvitationUser,
  InvitationRights,
  InvitationCanDownloadIcon,
  InvitationDate,
  InvitationActions,
  CopyInvitationLinkButton,
  EditInvitationButton,
  DeleteInvitationButton,
  InvitationsTitle,
  UserModal,
  UserForm,
  UserInputWrapper,
  InvitationLinkWrapper,
  GlobalLinkWrapper,
} from './styles';

import {
  ButtonWrapper,
  InputWrapper,
  ModalContent,
  ModalDesc,
  ModalTitle,
  StyledButton,
} from '../../global.styles';

// =============================
// Component
// =============================

const entity = 'pitch';

class MetaPitch extends Component {
  static propTypes = {
    tenantId: PropTypes.string.isRequired,
    panel: PropTypes.shape({
      data: pth.pitch,
      additionalData: PropTypes.shape({
        users: PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.shape({
            users: PropTypes.arrayOf(PropTypes.shape({
              id: PropTypes.string,
              email: PropTypes.string,
            })),
            rights: PropTypes.shape({
              download: PropTypes.bool,
            }),
            invitation_date: PropTypes.string,
          })),
        }),
        stats: PropTypes.shape({
          data: PropTypes.shape({
            total: PropTypes.shape({
              plays: PropTypes.number,
              downloads: PropTypes.shape({
                audiofile: PropTypes.number,
                archive: PropTypes.number,
              }),
            }),
            top: PropTypes.shape({
              plays: PropTypes.arrayOf(PropTypes.shape({
                count: PropTypes.number,
                last_event_date: PropTypes.string,
                track: PropTypes.shape({
                  id: PropTypes.string,
                  title: PropTypes.string,
                }),
              })),
              downloads: PropTypes.arrayOf(PropTypes.shape({
                count: PropTypes.number,
                last_event_date: PropTypes.string,
                track: PropTypes.shape({
                  id: PropTypes.string,
                  title: PropTypes.string,
                }),
              })),
            }),
            events: PropTypes.arrayOf(PropTypes.shape({
              action: PropTypes.string,
              date: PropTypes.string,
              track: PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
              }),
              user: PropTypes.shape({
                id: PropTypes.string,
                email: PropTypes.string,
              }),
            })),
          }),
        }),
      }),
      isLoading: PropTypes.bool.isRequired,
      'isLoading-users': PropTypes.bool,
      'isLoading-stats': PropTypes.bool,
      isModifying: PropTypes.bool,
      'isModifying-users': PropTypes.bool,
      isDeleting: PropTypes.bool.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    customFields: pth.customFieldsConfig.isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    modoConfigs: PropTypes.arrayOf(pth.modoConfigListing).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    selectedUsers: [],
    modals: {
      addEditUsers: { active: false },
      deleteUsers: { active: false },
    },
  };

  // This entity is never shared to agents
  pickFormData = (data, init = false, showcaseDefined) => {
    if (!init) {
      if (showcaseDefined) return { custom: _get(data, 'custom', {}) };
      return { showcase: _get(data, 'showcase', '') };
    }

    return {
      showcase: _get(data, 'showcase.id', ''),
      custom: _get(data, 'custom', {}),
    };
  };

  renderPanelAnchors = () => {
    const { customFields, t } = this.props;

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    return (
      <TabLinksWrapper>
        <TabLink name="general_information">
          {t(`pages:meta.${entity}s.panel.general_information_anchor`)}
        </TabLink>
        {hasCustomFields && (
          <TabLink name="custom_fields_information">
            {t(`pages:meta.${entity}s.panel.custom_fields_information_anchor`)}
          </TabLink>
        )}
        <TabLink name="analytics_information">
          {t(`pages:meta.${entity}s.panel.analytics_information_anchor`)}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  render() {
    const {
      panel,
      localPreferences,
      modoConfigs,
      customFields,
      triggerPanelAutosaveCheck,
      t,
    } = this.props;

    const {
      data,
      additionalData,
      isModifying,
      isDeleting,
    } = panel;

    const {
      selectedUsers,
      modals: { addEditUsers, deleteUsers },
    } = this.state;

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/drops?panel=${data.id}`
      : '';

    const modoConfigsOptions = modoConfigs.map(modoConfig => ({
      value: modoConfig.id,
      label: modoConfig.website_title,
    }));

    const usersData = _get(additionalData, 'users.data', []);
    const statsData = _get(additionalData, 'stats.data', {});

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[spts.META_PITCH_PANEL].get}
        getAdditionalOptions={{
          fnc: panelActions[spts.META_PITCH_PANEL].getAdditional,
          list: [
            ['tracks', 'tracks'],
            ['users', 'users'],
            ['users/stats', 'stats', 'post'],
          ],
        }}
        requestGridRefresh={panelActions[spts.META_PITCH_PANEL].requestGridRefresh}
        modify={panelActions[spts.META_PITCH_PANEL].modify}
        modifyAdditional={panelActions[spts.META_PITCH_PANEL].modifyAdditional}
        del={panelActions[spts.META_PITCH_PANEL].delete}
        lang={{
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {({
          refreshPanel,
          modifyDocument,
          modifyAdditional,
          deleteDocument,
        }) => {
          if (!data) return null;

          this.refreshPanel = refreshPanel;

          const documentRights = getDocumentRights(
            true,
            data.tenant.id,
          );

          let showcaseUrl = null;

          if (_get(data, 'showcase.id')) {
            const modoConfig = modoConfigs.find(config => config.id === data.showcase.id);

            if (modoConfig && modoConfig.active) {
              showcaseUrl = modoConfig.url_config.website_url;
            }
          }

          return (
            <Fragment>
              <LeftColumn>
                <GenericCover
                  placeholder={playlistplaceholder}
                  cover={getGenericDocCoverUrl(data.image)}
                />
                <DocumentName>
                  {data.title}
                </DocumentName>
                <DocumentType>
                  {t(`common:entities.${entity}`)}
                </DocumentType>
                <DocumentCreatedAt>
                  {t(`pages:meta.${entity}s.panel.created_at`)}
                  :&nbsp;
                  {formatDateString(data.created_at, localPreferences.dateFormat, true)}
                </DocumentCreatedAt>

                <CopyLink
                  link={shareLink}
                  content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                  tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
                />

                <CopyLink
                  link={_get(additionalData, 'tracks.data', []).map((track) => {
                    const artists = track.display_artists.map(da => da.alias || da.artist.full_name);

                    return `${track.display_title || track.title}${artists.length ? ` - ${artists.join('-')}` : ''}`;
                  }).join('\n')}
                  content={t(`pages:meta.${entity}s.panel.copy_track_listing`)}
                />

                <GenericAction
                  onClick={() => triggerPanelAutosaveCheck(
                    'open',
                    [spts.metaMapping[data.type], data.entity],
                  )}
                >
                  {t(`pages:meta.${entity}s.panel.see_original_${data.type}`)}
                </GenericAction>
              </LeftColumn>

              <RightColumn initTab="general_information">
                <DocumentStatus
                  isModifying={isModifying}
                  isDeleting={isDeleting}
                  localPreferences={localPreferences}
                  documentRights={documentRights}
                  updatedAt={data.updated_at}
                  deleteDocument={deleteDocument}
                />
                {this.renderPanelAnchors(data)}
                <DocumentBody>
                  <Form
                    onSubmit={() => {}}
                    initialValues={this.pickFormData(data, true)}
                    subscription={{ values: true }}
                    keepDirtyOnReinitialize
                    mutators={{ ...arrayMutators }}
                  >
                    {() => (
                      <Fragment>
                        <Autosave
                          debounce={!data.showcase ? 200 : 5000}
                          save={modifyDocument}
                          nullValuesTransformer={formValues => this.pickFormData(formValues, false, !!data.showcase)}
                          autosavePanelId={`${spts.META_PITCH_PANEL}_${data.id}`}
                        />
                        <TabElement name="general_information">
                          <SectionTitle>{t(`pages:meta.${entity}s.panel.general_information`)}</SectionTitle>
                          <SectionNotice>{t(`pages:meta.${entity}s.panel.general_information_notice`)}</SectionNotice>

                          {/* Showcase */}
                          <SectionBox>
                            {(!data.showcase && modoConfigs.length) && (
                              <InputWrapper>
                                <Field
                                  name="showcase"
                                  component={MewoInput}
                                  use={SelectInput}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.modos_label`)}
                                  options={modoConfigsOptions}
                                  searchable
                                  renderOptionsVariant="classic"
                                  renderValuesVariant="text"
                                />
                              </InputWrapper>
                            )}

                            {(data.showcase) && (
                              <>
                                <GenericText>{t(`pages:meta.${entity}s.panel.modos_label`)}</GenericText>
                                <GenericText>{modoConfigs.find(config => config.id === data.showcase.id).website_title}</GenericText>
                              </>
                            )}

                            {(!modoConfigs.length) && (<GenericText>{t(`pages:meta.${entity}s.panel.no_active_modos`)}</GenericText>)}
                          </SectionBox>

                          {/* Global link */}
                          <SectionBox>
                            <GenericText>{t(`pages:meta.${entity}s.panel.global_share_link`)}</GenericText>
                            {(data.showcase && !showcaseUrl) && (<GenericText>{t(`pages:meta.${entity}s.panel.showcase_no_url`)}</GenericText>)}
                            {(data.showcase && showcaseUrl) && (
                              <GlobalLinkWrapper>{`https://${showcaseUrl}/drop/${data.id}?token=${data.global_token}`}</GlobalLinkWrapper>
                            )}
                          </SectionBox>

                          {/* Invitations */}
                          {!!showcaseUrl && (
                            <Fragment>
                              <Flex mb="2rem" align="center">
                                {!!usersData.length && (
                                  <CheckboxInput
                                    checked={usersData.length === selectedUsers.length}
                                    onChange={checked => this.setState({
                                      selectedUsers: checked ? usersData : [],
                                    })}
                                  />
                                )}
                                <Flex
                                  align="center"
                                  justify="space-between"
                                  width="100%"
                                  ml="2rem"
                                >
                                  <InvitationsTitle>
                                    {t(`pages:meta.${entity}s.panel.invitations_title`)}
                                  </InvitationsTitle>
                                  <Flex align="center">
                                    {selectedUsers.length ? (
                                      <Fragment>
                                        <StyledButton
                                          size="small"
                                          disabled={_get(panel, 'isModifying-users', false)}
                                          onClick={() => this.setState(prevState => ({
                                            modals: {
                                              ...prevState.modals,
                                              addEditUsers: { active: true },
                                            },
                                          }))}
                                        >
                                          {t(`pages:meta.${entity}s.panel.modify_users`)}
                                        </StyledButton>
                                        <StyledButton
                                          size="small"
                                          color="error"
                                          ml="2rem"
                                          disabled={_get(panel, 'isModifying-users', false)}
                                          onClick={() => this.setState(prevState => ({
                                            modals: {
                                              ...prevState.modals,
                                              deleteUsers: { active: true },
                                            },
                                          }))}
                                        >
                                          {t(`pages:meta.${entity}s.panel.delete_users`)}
                                        </StyledButton>
                                      </Fragment>
                                    ) : (
                                      <StyledButton
                                        size="small"
                                        onClick={() => this.setState(prevState => ({
                                          modals: {
                                            ...prevState.modals,
                                            addEditUsers: { active: true },
                                          },
                                        }))}
                                      >
                                        {t(`pages:meta.${entity}s.panel.invite_user`)}
                                      </StyledButton>
                                    )}
                                  </Flex>
                                </Flex>
                              </Flex>

                              {!usersData.length ? (
                                <SectionBox>
                                  <GenericText>
                                    {t(`pages:meta.${entity}s.panel.no_active_users`)}
                                  </GenericText>
                                </SectionBox>
                              ) : usersData.map(user => (
                                <InvitationWrapper key={user.id}>
                                  <CheckboxInput
                                    checked={selectedUsers.includes(user)}
                                    onChange={checked => this.setState({
                                      selectedUsers: checked
                                        ? [...selectedUsers, user]
                                        : selectedUsers.filter(u => u.id !== user.id),
                                    })}
                                  />
                                  <InvitationInnerWrapper>
                                    <InvitationUser>{user.email}</InvitationUser>
                                    <InvitationRights>
                                      {user.rights.download && (
                                        <InvitationCanDownloadIcon title={t(`pages:meta.${entity}s.panel.user_rights_download`)} />
                                      )}
                                    </InvitationRights>
                                    <InvitationDate>
                                      {formatDateString(
                                        user.invitation_date,
                                        localPreferences.dateFormat,
                                        true,
                                      )}
                                    </InvitationDate>
                                    <InvitationActions>
                                      <CopyLink
                                        Wrapper={InvitationLinkWrapper}
                                        link={`https://${showcaseUrl}/drop/${data.id}?token=${user.token}`}
                                        content={<CopyInvitationLinkButton />}
                                        notificationContent={t(`pages:meta.${entity}s.panel.copied_user_link`)}
                                      />
                                      {user.email !== 'global' && (
                                        <>
                                          <EditInvitationButton
                                            onClick={() => this.setState(prevState => ({
                                              selectedUsers: [user],
                                              modals: {
                                                ...prevState.modals,
                                                addEditUsers: { active: true },
                                              },
                                            }))}
                                            disabled={_get(panel, 'isModifying-users', false)}
                                          />
                                          <DeleteInvitationButton
                                            onClick={() => this.setState(prevState => ({
                                              selectedUsers: [user],
                                              modals: {
                                                ...prevState.modals,
                                                deleteUsers: { active: true },
                                              },
                                            }))}
                                            disabled={_get(panel, 'isModifying-users', false)}
                                          />
                                        </>
                                      )}
                                    </InvitationActions>
                                  </InvitationInnerWrapper>
                                </InvitationWrapper>
                              ))}
                            </Fragment>
                          )}
                        </TabElement>

                        {hasCustomFields && data.showcase && (
                          <TabElement name="custom_fields_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.custom_fields_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.custom_fields_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <MetaCustomFields
                                  entity={entity}
                                  formDisabled={!data.showcase}
                                />
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}
                      </Fragment>
                    )}
                  </Form>

                  {data.showcase && (
                    <TabElement name="analytics_information">
                      <SectionTitle>
                        {t(`pages:meta.${entity}s.panel.analytics_information`)}
                      </SectionTitle>
                      <SectionNotice>
                        {t(`pages:meta.${entity}s.panel.analytics_information_notice`)}
                      </SectionNotice>
                      <SectionBox>
                        <Form
                          onSubmit={values => modifyAdditional(
                            'users/stats',
                            'post',
                            {
                              user_blacklist: usersData
                                .filter(user => !values.whitelist.includes(user))
                                .map(user => user.id),
                            },
                            'stats',
                          )}
                          initialValues={{ whitelist: usersData }}
                          subscription={{ values: true }}
                          keepDirtyOnReinitialize
                          mutators={{ ...arrayMutators }}
                        >
                          {({ form, values }) => (
                            <Fragment>
                              <Field name="whitelist">
                                {({ input, meta }) => (
                                  <MewoInput
                                    meta={meta}
                                    input={{
                                      ...input,
                                      onChange: (v) => {
                                        input.onChange(v || []);
                                        form.submit();
                                      },
                                    }}
                                    use={SelectInput}
                                    name="whitelist"
                                    label={t(`pages:meta.${entity}s.panel.analytics_user_filter`)}
                                    options={usersData.map((user) => {
                                      let suffix = '';

                                      if (user.last_interaction_date) {
                                        suffix = ` (${t(`pages:meta.${entity}s.panel.last_interaction`)}: ${formatDateString(user.last_interaction_date, localPreferences.dateFormat, false)})`;
                                      }

                                      return {
                                        label: `${user.email}${suffix}`,
                                        value: user,
                                      };
                                    })}
                                    renderOptionsVariant="check"
                                    renderValuesVariant="count"
                                    multiple
                                    searchable
                                    selectAllHelper
                                    selectAllLabel={t(`pages:meta.${entity}s.panel.all_analytics_users`)}
                                    countName={t(`pages:meta.${entity}s.panel.analytics_user`)}
                                    countNamePlural={t(`pages:meta.${entity}s.panel.analytics_users`)}
                                    countAll={t(`pages:meta.${entity}s.panel.all_analytics_users`)}
                                    error={false}
                                    disabled={_get(panel, 'isLoading-stats', false)}
                                  />
                                )}
                              </Field>

                              {values.whitelist.length > 0 && (
                                <UserChipsWrapper>
                                  {values.whitelist
                                    .map(user => (
                                      <UserChip
                                        key={user.id}
                                        name={user.email}
                                        onDelete={() => {
                                          form.change('whitelist', values.whitelist.filter(u => u !== user));
                                          form.submit();
                                        }}
                                        disabled={_get(panel, 'isLoading-stats', false)}
                                      />
                                    ))}
                                </UserChipsWrapper>
                              )}
                            </Fragment>
                          )}
                        </Form>
                      </SectionBox>
                      <Flex justify="space-between">
                        <SingleStatBox>
                          <StatTitle mb="1.5rem">
                            {t(`pages:meta.${entity}s.panel.plays_total`)}
                          </StatTitle>
                          <StatNumberValue>
                            {statsData.total?.plays || 0}
                          </StatNumberValue>
                        </SingleStatBox>
                        <SingleStatBox>
                          <StatTitle mb="1.5rem">
                            {t(`pages:meta.${entity}s.panel.tracks_downloads_total`)}
                          </StatTitle>
                          <StatNumberValue>
                            {statsData.total?.downloads.audiofile || 0}
                          </StatNumberValue>
                        </SingleStatBox>
                        <SingleStatBox>
                          <StatTitle mb="1.5rem">
                            {t(`pages:meta.${entity}s.panel.archives_downloads_total`)}
                          </StatTitle>
                          <StatNumberValue>
                            {statsData.total?.downloads.archive || 0}
                          </StatNumberValue>
                        </SingleStatBox>
                      </Flex>
                      <Flex mt="2rem">
                        <List
                          title={t(`pages:meta.${entity}s.panel.top_plays`)}
                          header={{
                            value: t(`pages:meta.${entity}s.panel.track_title`),
                            count: t(`pages:meta.${entity}s.panel.number_of_plays`),
                            date: t(`pages:meta.${entity}s.panel.latest_event_date`),
                          }}
                          width={72}
                          data={(statsData.top?.plays || [])
                            .map(topPlay => ({
                              value: topPlay.track.title,
                              count: topPlay.count,
                              date: topPlay.last_event_date,
                            }))}
                          disablePeriods
                          maxItemsFolded={8}
                          transformDate={date => formatDateString(
                            date, localPreferences.dateFormat, true,
                          )}
                        />
                      </Flex>
                      <Flex mt="2rem">
                        <List
                          title={t(`pages:meta.${entity}s.panel.top_downloads`)}
                          header={{
                            value: t(`pages:meta.${entity}s.panel.track_title`),
                            count: t(`pages:meta.${entity}s.panel.number_of_downloads`),
                            date: t(`pages:meta.${entity}s.panel.latest_event_date`),
                          }}
                          width={72}
                          data={(statsData.top?.downloads || [])
                            .map(topDownload => ({
                              value: topDownload.track.title,
                              count: topDownload.count,
                              date: topDownload.last_event_date,
                            }))}
                          disablePeriods
                          maxItemsFolded={8}
                          transformDate={date => formatDateString(
                            date, localPreferences.dateFormat, true,
                          )}
                        />
                      </Flex>
                      <Flex mt="2rem">
                        <List
                          title={t(`pages:meta.${entity}s.panel.events`)}
                          header={{
                            value: t(`pages:meta.${entity}s.panel.action`),
                            count: t(`pages:meta.${entity}s.panel.user`),
                            date: t(`pages:meta.${entity}s.panel.event_date`),
                          }}
                          width={72}
                          data={(statsData.events || [])
                            .map(lastEvent => ({
                              value: `${t(`pages:meta.${entity}s.panel.event_action.${lastEvent.action}`)}${lastEvent.track?.title ? ` - ${lastEvent.track?.title}` : ''}`,
                              count: lastEvent.user?.email || '',
                              date: lastEvent.date,
                            }))}
                          disablePeriods
                          maxItemsFolded={8}
                          transformDate={date => formatDateString(
                            date, localPreferences.dateFormat, true,
                          )}
                        />
                      </Flex>
                    </TabElement>
                  )}
                </DocumentBody>
              </RightColumn>

              {/* Invite/Edit Modal */}
              {addEditUsers.active && (
                <UserModal>
                  <ModalContent width="70rem">
                    <ModalTitle>
                      {selectedUsers.length
                        ? t(`pages:meta.${entity}s.panel.modify_users`)
                        : t(`pages:meta.${entity}s.panel.add_users`)}
                    </ModalTitle>
                    <ModalDesc>
                      {selectedUsers.length
                        ? t(`pages:meta.${entity}s.panel.modify_users_desc`)
                        : t(`pages:meta.${entity}s.panel.add_users_desc`)}
                    </ModalDesc>

                    <Form
                      onSubmit={async (values) => {
                        await modifyAdditional(
                          'users',
                          selectedUsers.length ? 'put' : 'post',
                          {
                            ids: values.users.map(user => user.id),
                            rights: values.rights,
                            ...(!selectedUsers.length ? { send_email: values.send_email } : {}),
                          },
                          'users',
                          'users',
                        );

                        this.setState(prevState => ({
                          selectedUsers: [],
                          modals: {
                            ...prevState.modals,
                            addEditUsers: { active: false },
                          },
                        }));
                      }}
                      initialValues={{
                        users: selectedUsers,
                        rights: {
                          download: false,
                        },
                        send_email: true,
                      }}
                      subscription={{ values: true }}
                      mutators={{ ...arrayMutators }}
                    >
                      {({ handleSubmit, submitting, values, form }) => (
                        <UserForm onSubmit={handleSubmit}>
                          <UserInputWrapper>
                            {!selectedUsers.length && values.users.length <= 500 && (
                              <ApiSelectInput
                                name="user_selector"
                                label={t(`pages:meta.${entity}s.panel.users`)}
                                entity={`modo/${data.showcase.id}/users`}
                                type="text"
                                getEntityLabel={(doc) => {
                                  const res = [];
                                  if (doc.first_name !== '-') res.push(doc.first_name);
                                  if (doc.last_name !== '-') res.push(doc.last_name);
                                  if (doc.company_name) res.push(`(${doc.company_name})`);
                                  if (res.length) res.push('-');
                                  res.push(doc.email);

                                  return res.join(' ');
                                }}
                                getSearchEntityLabel={doc => doc}
                                showResetBtn={false}
                                onChange={(value) => {
                                  if (
                                    !values.users.find(user => user.id === value.search.id)
                                    && !usersData.find(user => user.id === value.search.id)
                                  ) {
                                    form.change('users', [...values.users, value.search]);
                                  }
                                }}
                                disabled={submitting}
                                onTheFlyEnabled
                                onTheFlyField="email"
                                onTheFlyAdditionalData={{
                                  first_name: '-',
                                  last_name: '-',
                                  phone_number: '-',
                                  password: `${uuid()}-aA&1`,
                                  agree_to_tos: true,
                                }}
                              />
                            )}

                            {values.users.length > 0 && (
                            <UserChipsWrapper>
                              {values.users.map(user => (
                                <UserChip
                                  key={user.id}
                                  name={user.email}
                                  onDelete={selectedUsers.length
                                    ? null : () => {
                                      form.change('users', values.users.filter(v => v.id !== user.id));
                                    }}
                                  disabled={submitting}
                                />
                              ))}
                            </UserChipsWrapper>
                            )}
                            <Field
                              name="rights.download"
                              component={MewoInput}
                              use={CheckboxInput}
                              type="checkbox"
                              label={t(`pages:meta.${entity}s.panel.can_download`)}
                              disabled={submitting}
                            />
                            {!selectedUsers.length && (
                              <Field
                                name="send_email"
                                component={MewoInput}
                                use={CheckboxInput}
                                type="checkbox"
                                label={t(`pages:meta.${entity}s.panel.send_email`)}
                                disabled={submitting}
                              />
                            )}
                          </UserInputWrapper>

                          <ButtonWrapper mt="2rem">
                            <StyledButton
                              onClick={() => this.setState(prevState => ({
                                selectedUsers: [],
                                modals: {
                                  ...prevState.modals,
                                  addEditUsers: { active: false },
                                },
                              }))}
                              color="cancel"
                              fillVariant="outline"
                              type="button"
                            >
                              {t('common:form.cancel')}
                            </StyledButton>

                            <StyledButton
                              type="submit"
                              disabled={submitting
                                || (!selectedUsers.length && !values.users.length)}
                            >
                              {selectedUsers.length
                                ? t('common:form.modify')
                                : t('common:form.create')}
                            </StyledButton>
                          </ButtonWrapper>
                        </UserForm>
                      )}
                    </Form>
                  </ModalContent>
                </UserModal>
              )}

              {deleteUsers.active && (
                <UserModal>
                  <ModalContent width="70rem">
                    <ModalTitle>{t(`pages:meta.${entity}s.panel.delete_users_title`)}</ModalTitle>
                    <ModalDesc>{t(`pages:meta.${entity}s.panel.delete_users_desc`)}</ModalDesc>
                    <ButtonWrapper mt="2rem">
                      <StyledButton
                        onClick={() => this.setState(prevState => ({
                          selectedUsers: [],
                          modals: {
                            ...prevState.modals,
                            deleteUsers: { active: false },
                          },
                        }))}
                        color="cancel"
                        fillVariant="outline"
                        type="button"
                      >
                        {t('common:form.cancel')}
                      </StyledButton>

                      <StyledButton
                        color="error"
                        disabled={_get(panel, 'isModifying-users', false)}
                        onClick={async () => {
                          await modifyAdditional(
                            'users',
                            'delete',
                            { ids: selectedUsers.map(v => v.id) },
                            'users',
                            'users',
                          );

                          this.setState(prevState => ({
                            selectedUsers: [],
                            modals: {
                              ...prevState.modals,
                              deleteUsers: { active: false },
                            },
                          }));
                        }}
                      >
                        {t('common:form.delete')}
                      </StyledButton>
                    </ButtonWrapper>

                  </ModalContent>
                </UserModal>
              )}
            </Fragment>
          );
        }}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core, user, meta, modo }) {
  return {
    tenantId: user.data.tenant_id || user.data.id,
    localPreferences: core.localPreferences,
    customFields: meta.customFields.data,
    modoConfigs: modo.configs.data,
  };
}

export default compose(
  connect(mapStateToProps, {
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
  }),
  withTranslation(['common', 'pages', 'components', 'errors']),
)(MetaPitch);
