// =============================
// Imports
// =============================

import { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Brief = memo(
  forwardRef(({ fill, onClick, theme, title, ...rest }, ref) => (
    <Svg
      fill={fill || theme.colors.text}
      onClick={onClick}
      ref={ref}
      title={title}
      viewBox="0 0 42 42"
      {...rest}
    >
      <path d="M34,7c-1.4,0-2.6,1-2.9,2.2L19.8,13H11c-3.3,0-6,2.7-6,6v2c0,3.3,2.7,6,6,6h0.6l1.2,5.9  c0.3,1.2,1.3,2.1,2.6,2.1c1.5,0,2.6-1.2,2.6-2.7V27h1.8l11.3,3.8C31.4,32,32.6,33,34,33c1.7,0,3-1.3,3-3V10C37,8.3,35.7,7,34,7z   M21,14.7l10-3.3v17.2l-10-3.3V14.7z M7,21v-2c0-2.2,1.8-4,4-4h8v10h-8C8.8,25,7,23.2,7,21z M16,32.3c0,0.4-0.3,0.7-0.6,0.7  c-0.3,0-0.6-0.2-0.6-0.5L13.6,27H16V32.3z M35,30c0,0.6-0.4,1-1,1s-1-0.4-1-1V10c0-0.6,0.4-1,1-1s1,0.4,1,1V30z" />
    </Svg>
  )),
);

Brief.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Brief.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '2rem',
};

export default withTheme(Brief);
