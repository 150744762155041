// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import _get from 'lodash/get';

// Helpers
import { getApiUrl, getSiteOrigin, getXPreferredLanguage } from '../../helpers/misc';
import determineError from '../../helpers/errors';
import transformNotifications from '../../helpers/notifications';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Organization Settings Actions
// =============================

export function getOrganizationSettings() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_ORGANIZATION_SETTINGS_LOADING,
    });

    return axios({
      method: 'get',
      url: getApiUrl('dashboard/organization-settings'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_ORGANIZATION_SETTINGS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_ORGANIZATION_SETTINGS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_ORGANIZATION_SETTINGS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function modifyOrganizationSettings(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_ORGANIZATION_SETTINGS_LOADING,
    });

    return axios({
      method: 'put',
      url: getApiUrl('dashboard/organization-settings'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_ORGANIZATION_SETTINGS,
          payload: response.data,
        });

        return dispatch({
          type: acts.MODIFY_ORGANIZATION_SETTINGS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.MODIFY_ORGANIZATION_SETTINGS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

// =============================
// Collaborator Actions
// =============================

// NOTE: Page is not used for this anymore but still necessary
// so that we can use the pageFetcher system
export function getUserCollaborators(page, { sort = undefined }) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_COLLABORATORS_LOADING,
    });

    let url = getApiUrl('dashboard/users/collaborators');
    if (sort) url += `?sort=${sort}`;

    return axios({
      method: 'get',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_USER_COLLABORATORS,
          payload: {
            data: response.data.data,
            total: response.data.total,
            query: _omitBy({ sort }, _isUndefined),
            // Fake values
            page: 0,
            nbPages: 1,
          },
        });

        return dispatch({
          type: acts.GET_USER_COLLABORATORS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_USER_COLLABORATORS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function createUserCollaborator(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.CREATE_USER_COLLABORATOR_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/users/collaborators'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        ...data,
        site_url: `${getSiteOrigin()}/setpassword`,
      },
    })
      .then(() => dispatch({
        type: acts.CREATE_USER_COLLABORATOR_SUCCESS,
        payload: {
          message: i18n.t('pages:collaborators.creation_success'),
        },
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 402:
            switch (err.response.data.key) {
              case 'quota_exceeded':
                errorMsg = i18n.t('errors:dashboard.collaborators.quota_exceeded');
                break;

              default:
                errorMsg = i18n.t('errors:dashboard.collaborators.not_in_plan');
            }

            dispatch({
              type: acts.CREATE_USER_COLLABORATOR_FAILURE,
            });

            return dispatch({
              type: acts.OPEN_PREMIUM_MODAL,
              payload: {
                message: errorMsg,
                context: 'collaborators',
                description: errorMsg,
              },
            });

          case err.response && err.response.data.key === 'email_already_exists':
            errorMsg = i18n.t('errors:dashboard.email_already_taken');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.CREATE_USER_COLLABORATOR_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function modifyUserCollaborator(id, data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_USER_COLLABORATOR_LOADING,
    });

    return axios({
      method: 'put',
      url: getApiUrl(`dashboard/users/collaborators/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then(() => dispatch({
        type: acts.MODIFY_USER_COLLABORATOR_SUCCESS,
        payload: {
          message: i18n.t('pages:collaborators.modification_success'),
        },
      }),
      )
      .catch((err) => {
        let errorMsg;

        switch (true) {
          case err.response
            && err.response.status === 400
            && err.response.data.key !== 'invalid_role':
            errorMsg = i18n.t('errors:dashboard.collaborators.no_changes_in_modification');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_USER_COLLABORATOR_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function deleteUserCollaborator(id) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.DELETE_USER_COLLABORATOR_LOADING,
    });

    return axios({
      method: 'delete',
      url: getApiUrl(`dashboard/users/collaborators/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(() => dispatch({
        type: acts.DELETE_USER_COLLABORATOR_SUCCESS,
        payload: {
          message: i18n.t('pages:collaborators.deletion_success'),
        },
      }),
      )
      .catch((err) => {
        let errorMsg;

        switch (true) {
          case err.response && err.response.status === 406:
            errorMsg = i18n.t('errors:dashboard.collaborators.cannot_delete_tenant');
            break;

          case err.response && err.response.status === 404:
            errorMsg = i18n.t('errors:dashboard.collaborators.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.DELETE_USER_COLLABORATOR_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Agents Actions
// =============================

export function getUserAgents(page = 0, { sort = undefined }) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_AGENTS_LOADING,
    });

    let url = getApiUrl(`dashboard/users/agents?page=${page}&max=12`);
    if (sort) url += `&sort=${sort}`;

    return axios({
      method: 'get',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_USER_AGENTS,
          payload: {
            data: response.data.data,
            page: response.data.page,
            nbPages: response.data.nb_pages,
            total: response.data.total,
            query: _omitBy({ sort }, _isUndefined),
          },
        });

        return dispatch({
          type: acts.GET_USER_AGENTS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_USER_AGENTS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function createUserAgent(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.CREATE_USER_AGENT_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/users/agents'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        ...data,
        site_url: `${getSiteOrigin()}/dashboard/providers`,
        no_notification: getState().user.isAdmin,
      },
    })
      .then(response => dispatch({
        type: acts.CREATE_USER_AGENT_SUCCESS,
        payload: {
          message: i18n.t('pages:agents.creation_success'),
          agent: response.data,
        },
      }),
      )
      .catch((err) => {
        let errorMsg;

        switch (true) {
          case err.response
          && err.response.status === 406
          && err.response.data.key === 'too_many_agents':
            errorMsg = i18n.t('errors:dashboard.agents.too_many_agents');
            break;

          case err.response
            && err.response.status === 406
            && err.response.data.key === 'job_in_progress':
            errorMsg = i18n.t('errors:dashboard.agents.job_in_progress');
            break;

          case err.response
            && err.response.status === 406
            && err.response.data.key !== 'too_many_agents'
            && err.response.data.key !== 'job_in_progress':
            errorMsg = i18n.t('errors:dashboard.agents.create_as_self');
            break;

          case err.response && err.response.status === 404:
            errorMsg = i18n.t('errors:dashboard.agents.not_found');
            break;

          case err.response
            && err.response.status === 400
            && err.response.data.key !== 'invalid_role':
            errorMsg = i18n.t('errors:dashboard.agents.already_an_agent');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.CREATE_USER_AGENT_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function modifyUserAgent(id, data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_USER_AGENT_LOADING,
    });

    return axios({
      method: 'put',
      url: getApiUrl(`dashboard/users/agents/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then(() => dispatch({
        type: acts.MODIFY_USER_AGENT_SUCCESS,
        payload: {
          message: i18n.t('pages:agents.modification_success'),
        },
      }),
      )
      .catch((err) => {
        let errorMsg;

        switch (true) {
          case err.response
            && err.response.status === 400
            && err.response.data.key !== 'invalid_role':
            errorMsg = i18n.t('errors:dashboard.agents.no_changes_in_modification');
            break;

          case err.response && err.response.status === 404:
            errorMsg = i18n.t('errors:dashboard.agents.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_USER_AGENT_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function deleteUserAgent(id) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.DELETE_USER_AGENT_LOADING,
    });

    return axios({
      method: 'delete',
      url: getApiUrl(`dashboard/users/agents/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(() => dispatch({
        type: acts.DELETE_USER_AGENT_SUCCESS,
        payload: {
          message: i18n.t('pages:agents.deletion_success'),
          agentId: id,
        },
      }),
      )
      .catch((err) => {
        let errorMsg;

        switch (true) {
          case err.response
            && err.response.status === 406
            && err.response.data.key === 'operation_on_agent_in_progress':
            errorMsg = i18n.t('errors:dashboard.agents.operation_on_agent_in_progress');
            break;

          case err.response && err.response.status === 404:
            errorMsg = i18n.t('errors:dashboard.agents.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.DELETE_USER_AGENT_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Providers Actions
// =============================

export function getUserProviders(page = 0, { sort = undefined }) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_PROVIDERS_LOADING,
    });

    let url = getApiUrl(`dashboard/users/providers?page=${page}&max=12`);
    if (sort) url += `&sort=${sort}`;

    return axios({
      method: 'get',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_USER_PROVIDERS,
          payload: {
            data: response.data.data,
            page: response.data.page,
            nbPages: response.data.nb_pages,
            total: response.data.total,
            query: _omitBy({ sort }, _isUndefined),
          },
        });

        return dispatch({
          type: acts.GET_USER_PROVIDERS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_USER_PROVIDERS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function validateUserProvider(id) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.VALIDATE_USER_PROVIDER_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl(`dashboard/users/providers/${id}/activate`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        no_notification: getState().user.isAdmin,
      },
    })
      .then(() => dispatch({
        type: acts.VALIDATE_USER_PROVIDER_SUCCESS,
        payload: { message: i18n.t('pages:providers.validate_success') },
      }),
      )
      .catch((err) => {
        let errorMsg;

        switch (true) {
          case err.response && err.response.status === 404:
            errorMsg = i18n.t('errors:dashboard.providers.not_found');
            break;

          case err.response
            && err.response.status === 400
            && err.response.data.key !== 'invalid_role':
            errorMsg = i18n.t('errors:dashboard.providers.already_a_provider');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.VALIDATE_USER_PROVIDER_FAILURE,
          payload: {
            messag: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function deleteUserProvider(id) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.DELETE_USER_PROVIDER_LOADING,
    });

    return axios({
      method: 'delete',
      url: getApiUrl(`dashboard/users/providers/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(() => dispatch({
        type: acts.DELETE_USER_PROVIDER_SUCCESS,
        payload: {
          message: i18n.t('pages:providers.deletion_success'),
          providerId: id,
        },
      }),
      )
      .catch((err) => {
        let errorMsg;

        switch (true) {
          case err.response && err.response.status === 404:
            errorMsg = i18n.t('errors:dashboard.providers.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.DELETE_USER_PROVIDER_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function getUserNotifications(page = 0) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_NOTIFICATIONS_LOADING,
    });

    return axios({
      method: 'get',
      url: getApiUrl(`dashboard/users/notifications?page=${page}&max=10`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_USER_NOTIFICATIONS,
          payload: {
            data: transformNotifications(response.data.data),
            page: response.data.page,
            nbPages: response.data.nb_pages,
            total: response.data.total,
          },
        });

        return dispatch({
          type: acts.GET_USER_NOTIFICATIONS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_USER_NOTIFICATIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function checkUserNotifications() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.CHECK_USER_NOTIFICATIONS_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/users/notifications/check'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(() => dispatch({
        type: acts.CHECK_USER_NOTIFICATIONS_SUCCESS,
      }),
      )
      .catch(err => dispatch({
        type: acts.CHECK_USER_NOTIFICATIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}
