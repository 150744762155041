// =============================
// Imports
// =============================

import { required, mustBeEmail } from '../index';

// =============================
// Validator
// =============================

const validator = {
  full_name: required,
  email: mustBeEmail,
};

export default validator;
