/* eslint-disable implicit-arrow-linebreak */
// =============================
// Imports
// =============================

import removeAccents from 'remove-accents';

import { getNameWithFallback } from '../../../helpers/doc-names';

// =============================
// Helpers
// =============================

export const matches = (text, search) => {
  const searchValues = removeAccents(search).toLowerCase().split(/\s+/);

  const textValues = text.split(/\s+/);

  return searchValues.every(searchValue => textValues
    .find(textValue => textValue.indexOf(searchValue) === 0));
};

export const matchedSynonym = (searchSynonyms, synonyms, search, locale) => {
  const synonym = synonyms.find(e => e.locale === locale);
  const searchSynonym = searchSynonyms.find(e => e.locale === locale);
  if (!synonym || !searchSynonym) return null;

  const searchValues = removeAccents(search).toLowerCase().split(/\s+/);

  const matchedSynonymIndex = searchSynonym.values.findIndex((s) => {
    const synonymValues = s.split(/\s+/);

    return searchValues.every(searchValue => synonymValues
      .find(synonymValue => synonymValue.indexOf(searchValue) === 0));
  });

  if (matchedSynonymIndex === -1) return null;

  return synonym.values[matchedSynonymIndex];
};

export const synonymMatches = (synonyms, search, locale) => {
  const synonym = synonyms.find(e => e.locale === locale);
  if (!synonym) return false;
  return synonym.values.some(s => matches(s, search));
};

export const tagMatches = (tag, search, locale) =>
  matches(getNameWithFallback(tag.search_names, locale), search)
  || synonymMatches(tag.search_synonyms, search, locale);

export const subCategoryMatches = (subCategory, search, locale) =>
  matches(getNameWithFallback(subCategory.search_names, locale), search)
  || subCategory.tags.some(tag => tagMatches(tag, search, locale));

export const categoryMatches = (category, search, locale) =>
  category.tags.some(tag => tagMatches(tag, search, locale))
  || category.sub_categories.some(subCategory => subCategoryMatches(subCategory, search, locale));
