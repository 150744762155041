// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { Flex, InfoTooltip, Typo } from '@mewo/components';

import { Wrapper, Label, IconWrapper } from './styles';

// =============================
// Component
// =============================

const ModoStatus = ({ lang, status, activationTooltip }) => {
  if (typeof status === 'undefined') return false;
  return (
    <Wrapper>
      <Label>
        {lang.status}
        {': '}
      </Label>
      {status ? (
        <Typo color="type.success">{lang.active}</Typo>
      ) : (
        <Flex align="center">
          <Typo color="type.error">{lang.inactive}</Typo>
          <IconWrapper>
            {!status && (
              <InfoTooltip content={activationTooltip} interactive placement="bottom-start" />
            )}
          </IconWrapper>
        </Flex>
      )}
    </Wrapper>
  );
};

ModoStatus.propTypes = {
  /** Translation strings */
  lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  status: PropTypes.bool,
  activationTooltip: PropTypes.node.isRequired,
};

ModoStatus.defaultProps = {
  status: undefined,
};

export default ModoStatus;
