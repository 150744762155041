// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import { DropdownContext } from '@mewo/components';
import trackplaceholder from '@mewo/components/assets/images/placeholders/track_150x150.png';

// Config
import { withTranslation } from '../../../../../config/i18n';

// Components
import ApiSelectInput from '../../../../containers/inputs/apiSelectInput';

// Actions
import { triggerAutosaveCheck } from '../../../../../store/actions/SidePanelActions';
import {
  pause as pauseBase,
  play as playBase,
  setCurrentTrack as setCurrentTrackBase,
} from '../../../../../store/actions/PlayerActions';
import { openBulkModal as openBulkModalBase } from '../../../../../store/actions/MetaActions';

// Helpers
import * as pth from '../../../../../helpers/proptypes';
import { getApiUrl } from '../../../../../helpers/misc';
import { getDocName, getNameWithFallback, getSearchName } from '../../../../../helpers/doc-names';
import { getGenericDocCoverUrl, presentDuration } from '../../../../../helpers/meta-common';

// Constants
import * as playerStates from '../../../../../store/constants/PlayerStates';
import * as spts from '../../../../../store/constants/SidePanelTypes';
import * as playerCtx from '../../../../../store/constants/PlayerContexts';

// Styles
import {
  Wrapper,
  Actions,
  Cover,
  CoverWrapper,
  DeleteButton,
  DisplayArtist,
  DisplayArtists,
  Duration,
  GridWrapper,
  InnerWrapper,
  OuterGridWrapper,
  ToggleBulkButton,
  Tenant,
  Title,
  VersionSelectWrapper,
  Version,
  VersionName,
  Controls,
  PauseIcon,
  PlayIcon,
  DropdownIndicator,
} from './styles';
import { GenericText, SectionBox, StyledDropdown } from '../../common.styles';

// =============================
// Component
// =============================

const entity = 'track';

class TrackVersions extends Component {
  static propTypes = {
    contextId: PropTypes.string,
    contextName: PropTypes.string,
    contextPosition: PropTypes.number,
    data: PropTypes.arrayOf(pth.smallTrack).isRequired,
    documentRights: PropTypes.shape({
      canWrite: PropTypes.bool.isRequired,
      isOwner: PropTypes.bool.isRequired,
      providerId: PropTypes.string,
    }).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isModifying: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    getAdditional: PropTypes.func.isRequired,
    modifyAdditional: PropTypes.func.isRequired,
    openBulkModal: PropTypes.func.isRequired,
    parentDocument: pth.track.isRequired,
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    setCurrentTrack: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    trackVersions: PropTypes.arrayOf(pth.trackVersion).isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contextId: null,
    contextName: null,
    contextPosition: null,
  };

  openDocumentPanel = (panelEntity, doc) => {
    const { triggerPanelAutosaveCheck } = this.props;

    triggerPanelAutosaveCheck('open', [spts.metaMapping[panelEntity], doc.id]);
  };

  getPlayerIsCurrent = (trackIndex) => {
    const {
      contextId,
      contextName,
      contextPosition,
      parentDocument,
    } = this.props;

    return contextId === parentDocument.id
      && contextName === playerCtx.VERSIONS
      && contextPosition === trackIndex;
  };

  handlePlayClick = (track, trackIndex) => {
    const {
      data,
      parentDocument,
      play,
      setCurrentTrack,
    } = this.props;

    if (this.getPlayerIsCurrent(trackIndex)) {
      return play();
    }

    return setCurrentTrack(
      track,
      playerCtx.VERSIONS,
      parentDocument.id,
      trackIndex,
      data,
    );
  };

  renderPlayerControls = (track, trackIndex) => {
    const { pause, isPlaying } = this.props;

    if (!track.audiofile.original) return null;

    const isCurrent = this.getPlayerIsCurrent(trackIndex);

    return (
      <Controls>
        {(isPlaying && isCurrent)
          ? <PauseIcon onClick={pause} />
          : <PlayIcon onClick={() => this.handlePlayClick(track, trackIndex)} />}
      </Controls>
    );
  };

  renderTrackLine = (track, trackIndex) => {
    const {
      documentRights,
      i18n: { language },
      isLoading,
      isModifying,
      modifyAdditional,
      parentDocument,
      t,
      trackVersions,
    } = this.props;

    const tenantName = track.tenant.company_name
      || `${track.tenant.first_name} ${track.tenant.last_name}`;

    const versionName = track.version
      ? getNameWithFallback(track.version.names, language)
      : null;

    const isOriginal = track.version && track.version.key === 'original';

    return (
      <InnerWrapper>
        <CoverWrapper>
          {this.renderPlayerControls(track, trackIndex)}
          <Cover
            placeholder={trackplaceholder}
            cover={getGenericDocCoverUrl(track.album?.image, 'small')}
          />
        </CoverWrapper>
        <OuterGridWrapper>
          <GridWrapper>
            <Title
              title={track.display_title || track.title}
              onClick={() => this.openDocumentPanel('track', track)}
            >
              {track.display_title || track.title}
            </Title>
            {(!isOriginal && documentRights.canWrite) ? (
              <VersionSelectWrapper>
                <StyledDropdown
                  options={_sortBy(
                    trackVersions
                      .filter(trackVersion => trackVersion.key !== 'original')
                      .map(trackVersion => ({
                        name: trackVersion.id,
                        label: getNameWithFallback(trackVersion.names, language),
                        action: trackVersionId => modifyAdditional(
                          `/meta/tracks/${track.id}`,
                          'put',
                          { version: trackVersionId },
                          'versions',
                          false,
                          'versions',
                        ),
                      })),
                    'label',
                  )}
                  togglerElement={(
                    <Version
                      title={versionName || ''}
                      disabled={isLoading || isModifying}
                    >
                      <VersionName>{versionName}</VersionName>
                      <DropdownContext.Consumer>
                        {({ opened }) => (
                          <DropdownIndicator opened={opened} />
                        )}
                      </DropdownContext.Consumer>
                    </Version>
                  )}
                />
              </VersionSelectWrapper>
            ) : (
              <Version title={versionName || ''}>
                {versionName}
              </Version>
            )}
            <Duration>
              {track.duration ? presentDuration(track.duration) : null}
            </Duration>
            <Tenant title={tenantName}>{tenantName}</Tenant>
          </GridWrapper>
          {!!track.display_artists.length && (
            <DisplayArtists>
              {track.display_artists.map((displayArtist, i) => (
                <DisplayArtist
                  key={`${displayArtist.artist.id}-${displayArtist.alias || displayArtist.artist.full_name}`}
                  title={displayArtist.alias || displayArtist.artist.full_name}
                  onClick={() => this.openDocumentPanel('artist', displayArtist.artist)}
                >
                  {i > 0 && (
                    <Fragment>,&nbsp;</Fragment>
                  )}
                  {displayArtist.alias || displayArtist.artist.full_name}
                </DisplayArtist>
              ))}
            </DisplayArtists>
          )}
        </OuterGridWrapper>
        <Actions>
          {documentRights.canWrite && (
            <DeleteButton
              type="button"
              onClick={() => modifyAdditional(
                `/meta/tracks/${isOriginal ? parentDocument.id : track.id}`,
                'put',
                { original_track: null },
                'versions',
                false,
                'versions',
              )}
              disabled={isLoading || isModifying}
            >
              {t('common:form.remove')}
            </DeleteButton>
          )}
        </Actions>
      </InnerWrapper>
    );
  };

  render() {
    const {
      data,
      documentRights,
      i18n: { language },
      isLoading,
      isModifying,
      getAdditional,
      modifyAdditional,
      openBulkModal,
      parentDocument,
      t,
      trackVersions,
    } = this.props;

    // Show no versions when
    // - User cannot write
    // - AND Tracks are not being loaded
    // - AND There are no tracks
    if (!documentRights.canWrite && !isLoading && !data.length) {
      return (
        <SectionBox>
          <GenericText>
            {t(`pages:meta.${entity}s.panel.no_versions`)}
          </GenericText>
        </SectionBox>
      );
    }

    const isOriginal = parentDocument.version && parentDocument.version.key === 'original';

    // These should never fail
    const originalVersionId = trackVersions.find(trackVersion => trackVersion.key === 'original').id;
    const alternativeVersionId = trackVersions.find(trackVersion => trackVersion.key === 'alternative').id;

    // Sort versions
    const sortedVersions = _sortBy(
      data.filter(track => track.version.key !== 'original'),
      track => getNameWithFallback(track.version.names, language),
    );

    const originalVersionTrack = data.find(track => track.version.key === 'original');

    if (originalVersionTrack) {
      sortedVersions.unshift(originalVersionTrack);
    }

    const originalTrackId = isOriginal ? parentDocument.id : originalVersionTrack?.id;

    return (
      <Fragment>
        {documentRights.canWrite && !!data.length && (
          <ToggleBulkButton
            disabled={isLoading || isModifying}
            onClick={() => openBulkModal(entity, [parentDocument.id, ...data.map(({ id }) => id)])}
          >
            {t(`pages:meta.${entity}s.panel.toggle_bulk`)}
          </ToggleBulkButton>
        )}
        {documentRights.canWrite && !isOriginal && !data.length && (
          <SectionBox>
            <ApiSelectInput
              name="original_track_setter"
              label={t(`pages:meta.${entity}s.panel.set_original_track`)}
              entity="meta/tracks"
              type="text"
              getEntityLabel={doc => getDocName('track')(doc, language)}
              getSearchEntityLabel={getSearchName('track')}
              listAdditionalQuery={documentRights.providerId
                ? `&original=1&provider_id=${documentRights.providerId}`
                : '&original=1'}
              showResetBtn={false}
              onChange={({ value: trackId }) => modifyAdditional(
                `/meta/tracks/${parentDocument.id}`,
                'put',
                { original_track: trackId },
                'versions',
                false,
                'versions',
              )}
              disabled={isLoading || isModifying}
              onTheFlyEnabled={documentRights.isOwner}
              onTheFlyField="display_title"
              onTheFlyEndpoint={getApiUrl(`meta/tracks/${parentDocument.id}/duplicate`)}
              onTheFlyAdditionalData={{
                suffix: t('common:entities.copy_suffix'),
                title: parentDocument.title,
                version: originalVersionId,
              }}
            />
          </SectionBox>
        )}
        {documentRights.canWrite && originalTrackId && (
          <SectionBox>
            {/* Use get additional to refresh versions after on the fly */}
            <ApiSelectInput
              name="version_adder"
              label={t(`pages:meta.${entity}s.panel.add_version`)}
              entity="meta/tracks"
              type="text"
              getEntityLabel={doc => getDocName('track')(doc, language)}
              getSearchEntityLabel={getSearchName('track')}
              listAdditionalQuery={documentRights.providerId
                ? `&original=-1&provider_id=${documentRights.providerId}`
                : '&original=-1'}
              showResetBtn={false}
              onChange={({ value: trackId }, onTheFly) => {
                if (!onTheFly) {
                  return modifyAdditional(
                    `/meta/tracks/${trackId}`,
                    'put',
                    {
                      original_track: originalTrackId,
                      version: alternativeVersionId,
                    },
                    'versions',
                    false,
                    'versions',
                  );
                }

                return getAdditional('versions', 'versions');
              }}
              disabled={isLoading || isModifying}
              onTheFlyEnabled={documentRights.isOwner}
              onTheFlyField="display_title"
              onTheFlyEndpoint={getApiUrl(`meta/tracks/${parentDocument.id}/duplicate`)}
              onTheFlyAdditionalData={{
                suffix: t('common:entities.copy_suffix'),
                title: parentDocument.title,
                original_track: originalTrackId,
                version: alternativeVersionId,
              }}
            />
          </SectionBox>
        )}
        {sortedVersions.map((track, index) => (
          <Wrapper key={track.id}>
            {this.renderTrackLine(track, index)}
          </Wrapper>
        ))}
      </Fragment>
    );
  }
}

function mapStateToProps({ options, player }) {
  return {
    contextId: player.contextId,
    contextName: player.contextName,
    contextPosition: player.contextPosition,
    isPlaying: player.playerState === playerStates.PLAYING,
    trackVersions: options.data.trackversions,
  };
}

export default compose(
  connect(mapStateToProps, {
    openBulkModal: openBulkModalBase,
    pause: pauseBase,
    play: playBase,
    setCurrentTrack: setCurrentTrackBase,
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
  }),
  withTranslation(['pages', 'common']),
)(TrackVersions);
