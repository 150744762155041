// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// =============================
// Helpers
// =============================

// Get name for locale with fallback
export const getNameWithFallback = (names = [], locale) => {
  const name = names.find(n => n.locale === locale);
  if (name) return name.value;

  const englishName = names.find(n => n.locale === 'en');
  if (englishName) return englishName.value;

  if (names.length) return names[0].value;
  return '';
};

// Michael Jackson, Quincy Jones
const getDisplayArtists = (artists = []) => {
  if (!artists.length) return null;

  const list = artists.map((da, i) => {
    const name = _get(da, 'alias') || _get(da, 'artist.full_name');
    if (i === 0) return name;
    return `, ${name}`;
  });

  return list.join('');
};

// PlaylistTitle - (TenantName, *TenantCompanyName)
export const getPlaylistName = (doc) => {
  const title = _get(doc, 'title');
  const tenantName = `${_get(doc, 'tenant.first_name')} ${_get(doc, 'tenant.last_name')}`;
  const companyName = _get(doc, 'tenant.company_name');

  if (!companyName) return `${title} (${tenantName})`;
  return `${title} (${tenantName}, ${companyName})`;
};

// AlbumTitle - *AlbumRef (*AlbumDisplayArtists, *Catalog)
export const getAlbumName = (doc) => {
  const title = _get(doc, 'title');
  const albumRef = _get(doc, 'album_ref');
  const catalogName = _get(doc, 'catalog.name');
  const displayArtistsNames = getDisplayArtists(_get(doc, 'display_artists', []));

  const firstPart = albumRef ? `${title} - ${albumRef}` : title;

  let secondPart = '';

  if (displayArtistsNames && catalogName) {
    secondPart = ` (${displayArtistsNames} - ${catalogName})`;
  } else if (!displayArtistsNames && catalogName) {
    secondPart = ` (${catalogName})`;
  } else if (displayArtistsNames && !catalogName) {
    secondPart = ` (${displayArtistsNames})`;
  }

  return `${firstPart}${secondPart}`;
};

// TrackTitle - *TrackDisplayArtists (*AlbumRef - *AlbumName, *Catalog)
export const getTrackName = (doc, locale) => {
  const title = _get(doc, 'display_title') || _get(doc, 'title');
  const version = _get(doc, 'version.names', []).find(n => n.locale === locale);
  const albumRef = _get(doc, 'album.album_ref');
  const albumName = _get(doc, 'album.title');
  const catalogName = _get(doc, 'catalog.name');
  const displayArtistsNames = getDisplayArtists(_get(doc, 'display_artists', []));

  const fullTitle = version ? `${title} (${version.value})` : title;
  const firstPart = displayArtistsNames ? `${fullTitle} - ${displayArtistsNames}` : fullTitle;

  let secondPart = '';

  if (albumRef && albumName) {
    secondPart = `${albumRef} - ${albumName}`;
  } else if (!albumRef && albumName) {
    secondPart = albumName;
  }

  if (secondPart && catalogName) {
    secondPart += `, ${catalogName}`;
  } else if (!secondPart && catalogName) {
    secondPart = catalogName;
  }

  return secondPart ? `${firstPart} (${secondPart})` : firstPart;
};

// CatalogName - (TenantName, *TenantCompanyName)
export const getCatalogName = (doc) => {
  const title = _get(doc, 'name');
  const tenantName = `${_get(doc, 'tenant.first_name')} ${_get(doc, 'tenant.last_name')}`;
  const companyName = _get(doc, 'tenant.company_name');

  if (!companyName) return `${title} (${tenantName})`;
  return `${title} (${tenantName}, ${companyName})`;
};

// ArtistName - (*TenantCompanyName)
export const getArtistName = (doc) => {
  const title = _get(doc, 'full_name');
  const companyName = _get(doc, 'tenant.company_name');

  if (!companyName) return title;
  return `${title} (${companyName})`;
};

// LabelName - (*TenantCompanyName)
export const getLabelName = (doc) => {
  const title = _get(doc, 'label_name');
  const companyName = _get(doc, 'tenant.company_name');

  if (!companyName) return title;
  return `${title} (${companyName})`;
};

// PublisherName - (*TenantCompanyName)
export const getPublisherName = (doc) => {
  const title = _get(doc, 'publisher_name');
  const companyName = _get(doc, 'tenant.company_name');

  if (!companyName) return title;
  return `${title} (${companyName})`;
};

export const getDocName = type => ({
  playlist: getPlaylistName,
  album: getAlbumName,
  track: getTrackName,
  catalog: getCatalogName,
  artist: getArtistName,
  label: getLabelName,
  publisher: getPublisherName,
}[type]);

export const getSearchName = type => ({
  playlist: e => e.title,
  album: e => e.title,
  track: e => e.title,
  catalog: e => e.name,
  artist: e => e.full_name,
  label: e => e.label_name,
  publisher: e => e.publisher_name,
}[type]);

export const getInnerHtml = (html) => {
  if (typeof window !== 'undefined') {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return _get(doc, 'body.textContent', '');
  }

  return '';
};
