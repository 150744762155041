// =============================
// Imports
// =============================

import { useState } from 'react';
import PropTypes from 'prop-types';

import TabsContext from './context';

// =============================
// Component
// =============================

const Tabs = (props) => {
  const { className, children, initTab } = props;

  const [currentTab, setCurrentTab] = useState(initTab);

  const tabsContext = { currentTab, setCurrentTab };

  return (
    <TabsContext.Provider value={tabsContext}>
      <div className={className}>
        {children}
      </div>
    </TabsContext.Provider>
  );
};

Tabs.propTypes = {
  /** Props used by styled component to override styles. */
  className: PropTypes.string,
  /** Children */
  children: PropTypes.node.isRequired,
  /** Tab to be init, must be equal to a tab & tab panel name */
  initTab: PropTypes.string.isRequired,
};

Tabs.defaultProps = {
  className: '',
};

export default Tabs;
