// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  organizationSettings: {
    isFetching: false,
    isModifying: false,
    data: {},
  },
  agents: {
    isFetching: false,
    isCreating: false,
    isModifying: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      sort: undefined,
    },
    total: null,
  },
  collaborators: {
    isFetching: false,
    isCreating: false,
    isModifying: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      sort: undefined,
    },
    total: null,
  },
  providers: {
    isFetching: false,
    isValidating: false,
    isDeleting: false,
    page: null,
    nbPages: null,
    data: [],
    query: {
      sort: undefined,
    },
    total: null,
  },
  notifications: {
    isFetching: false,
    isChecking: false,
    page: null,
    nbPages: null,
    data: [],
    query: {},
    total: null,
  },
};

// =============================
// Reducer
// =============================

export default function user(state = initialState, action) {
  switch (action.type) {
    /* User Organization Settings */
    case acts.GET_ORGANIZATION_SETTINGS_LOADING:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          isFetching: true,
        },
      };

    case acts.GET_ORGANIZATION_SETTINGS_SUCCESS:
    case acts.GET_ORGANIZATION_SETTINGS_FAILURE:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          isFetching: false,
        },
      };

    case acts.SET_ORGANIZATION_SETTINGS:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          data: action.payload,
        },
      };

    case acts.MODIFY_ORGANIZATION_SETTINGS_LOADING:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          isModifying: true,
        },
      };

    case acts.MODIFY_ORGANIZATION_SETTINGS_SUCCESS:
    case acts.MODIFY_ORGANIZATION_SETTINGS_FAILURE:
      return {
        ...state,
        organizationSettings: {
          ...state.organizationSettings,
          isModifying: false,
        },
      };

    /* User collaborators */
    case acts.GET_USER_COLLABORATORS_LOADING:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isFetching: true,
        },
      };

    case acts.GET_USER_COLLABORATORS_SUCCESS:
    case acts.GET_USER_COLLABORATORS_FAILURE:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isFetching: false,
        },
      };

    case acts.SET_USER_COLLABORATORS:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          ...action.payload,
        },
      };

    case acts.CREATE_USER_COLLABORATOR_LOADING:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isCreating: true,
        },
      };

    case acts.CREATE_USER_COLLABORATOR_SUCCESS:
    case acts.CREATE_USER_COLLABORATOR_FAILURE:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isCreating: false,
        },
      };

    case acts.MODIFY_USER_COLLABORATOR_LOADING:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isModifying: true,
        },
      };

    case acts.MODIFY_USER_COLLABORATOR_SUCCESS:
    case acts.MODIFY_USER_COLLABORATOR_FAILURE:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isModifying: false,
        },
      };

    case acts.DELETE_USER_COLLABORATOR_LOADING:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isDeleting: true,
        },
      };

    case acts.DELETE_USER_COLLABORATOR_SUCCESS:
    case acts.DELETE_USER_COLLABORATOR_FAILURE:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          isDeleting: false,
        },
      };

    /* User agents */
    case acts.GET_USER_AGENTS_LOADING:
      return {
        ...state,
        agents: {
          ...state.agents,
          isFetching: true,
        },
      };

    case acts.GET_USER_AGENTS_SUCCESS:
    case acts.GET_USER_AGENTS_FAILURE:
      return {
        ...state,
        agents: {
          ...state.agents,
          isFetching: false,
        },
      };

    case acts.SET_USER_AGENTS:
      return {
        ...state,
        agents: {
          ...state.agents,
          ...action.payload,
        },
      };

    case acts.CREATE_USER_AGENT_LOADING:
      return {
        ...state,
        agents: {
          ...state.agents,
          isCreating: true,
        },
      };

    case acts.CREATE_USER_AGENT_SUCCESS:
    case acts.CREATE_USER_AGENT_FAILURE:
      return {
        ...state,
        agents: {
          ...state.agents,
          isCreating: false,
        },
      };

    case acts.MODIFY_USER_AGENT_LOADING:
      return {
        ...state,
        agents: {
          ...state.agents,
          isModifying: true,
        },
      };

    case acts.MODIFY_USER_AGENT_SUCCESS:
    case acts.MODIFY_USER_AGENT_FAILURE:
      return {
        ...state,
        agents: {
          ...state.agents,
          isModifying: false,
        },
      };

    case acts.DELETE_USER_AGENT_LOADING:
      return {
        ...state,
        agents: {
          ...state.agents,
          isDeleting: true,
        },
      };

    case acts.DELETE_USER_AGENT_SUCCESS:
    case acts.DELETE_USER_AGENT_FAILURE:
      return {
        ...state,
        agents: {
          ...state.agents,
          isDeleting: false,
        },
      };

    /* User providers */
    case acts.GET_USER_PROVIDERS_LOADING:
      return {
        ...state,
        providers: {
          ...state.providers,
          isFetching: true,
        },
      };

    case acts.GET_USER_PROVIDERS_SUCCESS:
    case acts.GET_USER_PROVIDERS_FAILURE:
      return {
        ...state,
        providers: {
          ...state.providers,
          isFetching: false,
        },
      };

    case acts.SET_USER_PROVIDERS:
      return {
        ...state,
        providers: {
          ...state.providers,
          ...action.payload,
        },
      };

    case acts.VALIDATE_USER_PROVIDER_LOADING:
      return {
        ...state,
        providers: {
          ...state.providers,
          isValidating: true,
        },
      };

    case acts.VALIDATE_USER_PROVIDER_SUCCESS:
    case acts.VALIDATE_USER_PROVIDER_FAILURE:
      return {
        ...state,
        providers: {
          ...state.providers,
          isValidating: false,
        },
      };

    case acts.DELETE_USER_PROVIDER_LOADING:
      return {
        ...state,
        providers: {
          ...state.providers,
          isDeleting: true,
        },
      };

    case acts.DELETE_USER_PROVIDER_SUCCESS:
    case acts.DELETE_USER_PROVIDER_FAILURE:
      return {
        ...state,
        providers: {
          ...state.providers,
          isDeleting: false,
        },
      };

    /* User notifications */
    case acts.GET_USER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isFetching: true,
        },
      };

    case acts.GET_USER_NOTIFICATIONS_SUCCESS:
    case acts.GET_USER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isFetching: false,
        },
      };

    case acts.SET_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.payload,
        },
      };

    case acts.CHECK_USER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isChecking: true,
        },
      };

    case acts.CHECK_USER_NOTIFICATIONS_SUCCESS:
    case acts.CHECK_USER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isChecking: false,
        },
      };

    case acts.LOGOUT_USER_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
