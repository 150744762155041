// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import { CircularProgressbar } from 'react-circular-progressbar';

import { Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  width: 100%;

  ${({ disabled }) => disabled
    && css`
      opacity: 0.5;
    `};
`;

export const LeftSectionWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 2rem;
`;

export const FileName = styled.a`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.input.text};
  flex-shrink: 1;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-right: 4rem;
  text-decoration: none;
  transition: opacity ${({ theme }) => theme.transition.medium};

  ${({ href }) => href
    && css`
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    `};
`;

export const UploadStatus = styled.div`
  background-color: ${({ status, theme }) => status && theme.colors.type[status]};
  border-radius: 50%;
  flex-shrink: 0;
  height: 1rem;
  margin-right: 1.2rem;
  width: 1rem;
`;

export const CircleProgressbar = styled(CircularProgressbar)`
  && {
    width: 3rem;

    .CircularProgressbar-path {
      stroke: ${({ theme }) => theme.colors.primary};
      stroke-linecap: round;
      transition: stroke-dashoffset 0.5s ease 0s;
    }

    .CircularProgressbar-trail {
      stroke: ${({ theme }) => alpha(theme.colors.text, 0.4)};
    }
  }
`;

export const CircularContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const DeleteButton = styled.div`
  color: ${({ theme }) => theme.colors.type.error};
  cursor: pointer;
  flex-shrink: 0;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-left: auto;
`;

export const DropzoneInnerContainer = styled.div`
  align-items: center;
  border: 0.1rem dashed transparent;
  border-radius: 0.8rem;
  cursor: pointer;
  display: flex;
  outline: 0;
  padding: 1rem;

  ${({ isDragActive }) => !isDragActive && css`
    &:hover {
      background-color: ${({ theme }) => alpha(theme.colors.primary, 0.1)};
      border: 0.1rem dashed ${({ theme }) => theme.colors.primary}
    }
  `};

  ${({ isDragAccept }) => isDragAccept && css`
    background-color: ${({ theme }) => alpha(theme.colors.type.success, 0.1)};
    border: 0.1rem dashed ${({ theme }) => theme.colors.type.success};
  `};


  ${({ isDragReject }) => isDragReject && css`
    background-color: ${({ theme }) => alpha(theme.colors.type.error, 0.1)};
    border: 0.1rem dashed ${({ theme }) => theme.colors.type.error};
  `};
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  ${({ dir }) => dir
    && css`
      align-items: flex-start;
      flex-direction: ${dir};
    `};
`;

/** We use this container to keep the same height when file is present or not */
export const Container = styled.div`
  align-items: center;
  border: 0.1rem dashed transparent;
  display: flex;
  padding: 1rem;
  width: 100%;
`;

export const Label = styled.div`
  font-size: inherit;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 0.8rem;

  ${({ error, theme }) => error
    && css`
      color: ${theme.colors.type.error};
    `};
`;

export const UploadIcon = styled(Icons.Upload)``;

export const PictureIcon = styled(Icons.Picture)``;
