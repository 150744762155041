// =============================
// Imports
// =============================

import styled from 'styled-components';
import { LangSwitcher } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledLangSwitcher = styled(LangSwitcher)`
  position: absolute;
  top: 0;
  right: 0;
`;
