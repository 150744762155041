/* eslint-disable global-require */
// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from '../../../config/i18n';

import { Option, OptionLink, StyledDropdown } from './styles';

import {
  archiveMetaAlbumTracks,
  archiveMetaCatalogTracks,
  archiveMetaPlaylistTracks,
  archiveMetaBriefTracks,
  archiveMetaTrack,
  downloadMetaAlbumCopyright,
  downloadMetaPlaylistCopyright,
  downloadMetaBriefCopyright,
  downloadMetaTrackAudiofile,
  downloadMetaTrackCopyright,
  exportMetaAlbumTracks,
  exportMetaArtistTracks,
  exportMetaCatalogTracks,
  exportMetaLabelTracks,
  exportMetaPlaylistTracks,
  exportMetaBriefTracks,
  exportMetaPublisherTracks,
  exportMetaTrack,
} from '../../../store/actions/MetaActions';

// =============================
// Component
// =============================

const actions = {
  track: {
    archive: archiveMetaTrack,
    export: exportMetaTrack,
    downloadAudiofile: downloadMetaTrackAudiofile,
    copyright: downloadMetaTrackCopyright,
  },
  album: {
    archive: archiveMetaAlbumTracks,
    export: exportMetaAlbumTracks,
    downloadAudiofile: () => {},
    copyright: downloadMetaAlbumCopyright,
  },
  playlist: {
    archive: archiveMetaPlaylistTracks,
    export: exportMetaPlaylistTracks,
    downloadAudiofile: () => {},
    copyright: downloadMetaPlaylistCopyright,
  },
  brief: {
    archive: archiveMetaBriefTracks,
    export: exportMetaBriefTracks,
    downloadAudiofile: () => {},
    copyright: downloadMetaBriefCopyright,
  },
  catalog: {
    archive: archiveMetaCatalogTracks,
    export: exportMetaCatalogTracks,
    downloadAudiofile: () => {},
    copyright: () => {},
  },
  artist: {
    archive: () => {},
    export: exportMetaArtistTracks,
    downloadAudiofile: () => {},
    copyright: () => {},
  },
  publisher: {
    archive: () => {},
    export: exportMetaPublisherTracks,
    downloadAudiofile: () => {},
    copyright: () => {},
  },
  label: {
    archive: () => {},
    export: exportMetaLabelTracks,
    downloadAudiofile: () => {},
    copyright: () => {},
  },
};

class MetaDownloadDropdown extends Component {
  static propTypes = {
    placement: PropTypes.string,
    entity: PropTypes.oneOf([
      'catalog',
      'playlist',
      'brief',
      'album',
      'track',
    ]).isRequired,
    documentId: PropTypes.string.isRequired,
    documentName: PropTypes.string.isRequired,
    originalAudioLink: PropTypes.string,
    hdMp3AudioLink: PropTypes.string,
    stemsLink: PropTypes.string,
    canDownload: PropTypes.bool.isRequired,
    togglerElement: PropTypes.node.isRequired,
    isExportingTracks: PropTypes.bool.isRequired,
    isArchivingTracks: PropTypes.bool.isRequired,
    isDownloadingAudiofile: PropTypes.bool,
    isDownloadingCopyright: PropTypes.bool,
    archiveFnc: PropTypes.func.isRequired,
    exportFnc: PropTypes.func.isRequired,
    downloadAudiofileFnc: PropTypes.func.isRequired,
    copyrightFnc: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placement: 'bottom-start',
    originalAudioLink: null,
    hdMp3AudioLink: null,
    stemsLink: null,
    isDownloadingAudiofile: false,
    isDownloadingCopyright: false,
  };

  renderNoOption = () => {
    const { entity, canDownload, t } = this.props;

    if (entity !== 'track' && !canDownload) {
      return <Option disabled>{t(`pages:meta.${entity}s.downloads.no_downloads_available`)}</Option>;
    }

    return null;
  };

  getOptions = () => {
    const {
      entity,
      documentId,
      documentName,
      originalAudioLink,
      hdMp3AudioLink,
      stemsLink,
      canDownload,
      isExportingTracks,
      isArchivingTracks,
      isDownloadingAudiofile,
      isDownloadingCopyright,
      archiveFnc,
      exportFnc,
      downloadAudiofileFnc,
      copyrightFnc,
      t,
    } = this.props;

    if (!canDownload) return [];

    const options = [
      {
        name: 'csv_export',
        disabled: isExportingTracks,
        label: t(`pages:meta.${entity}s.downloads.export_tracks`),
        action: () => exportFnc([documentId]),
      },
    ];

    if (entity === 'track') {
      if (hdMp3AudioLink) {
        options.push({
          name: 'mp3',
          disabled: isDownloadingAudiofile,
          label: t(`pages:meta.${entity}s.downloads.low_quality`),
          action: () => downloadAudiofileFnc(documentId, 'hd_mp3'),
        });
      }

      if (originalAudioLink) {
        options.push({
          name: 'original',
          disabled: isDownloadingAudiofile,
          label: t(`pages:meta.${entity}s.downloads.high_quality`),
          action: () => downloadAudiofileFnc(documentId, 'original'),
        });
      }
    }

    if (['album', 'playlist', 'brief', 'catalog'].includes(entity)) {
      options.push(
        {
          name: 'mp3_archive',
          disabled: isArchivingTracks,
          label: t(`pages:meta.${entity}s.downloads.low_quality`),
          action: () => archiveFnc(documentId, 'hd_mp3', false),
        },
        {
          name: 'original_archive',
          disabled: isArchivingTracks,
          label: t(`pages:meta.${entity}s.downloads.high_quality`),
          action: () => archiveFnc(documentId, 'original', false),
        },
      );
    }

    if (['track', 'album', 'playlist', 'brief', 'catalog'].includes(entity)) {
      options.push(
        {
          name: 'mp3_archive_with_versions',
          disabled: isArchivingTracks,
          label: t(`pages:meta.${entity}s.downloads.low_quality_with_versions`),
          action: () => archiveFnc(documentId, 'hd_mp3', true),
        },
        {
          name: 'original_archive_with_versions',
          disabled: isArchivingTracks,
          label: t(`pages:meta.${entity}s.downloads.high_quality_with_versions`),
          action: () => archiveFnc(documentId, 'original', false),
        },
      );
    }

    if (entity === 'track' && stemsLink) {
      options.push({
        link: (
          <OptionLink href={stemsLink} download>
            {t(`pages:meta.${entity}s.downloads.stems`)}
          </OptionLink>
        ),
        name: '',
        label: '',
      });
    }

    if (['track', 'album', 'playlist', 'brief'].includes(entity)) {
      options.push({
        name: 'copyright',
        disabled: isDownloadingCopyright,
        label: t(`pages:meta.${entity}s.downloads.copyright`),
        action: () => copyrightFnc(documentId, documentName),
      });
    }

    return options;
  };

  renderOption = (option) => {
    if (option.link) return option.link;

    return (
      <Option
        onClick={option.action}
        key={option.name}
        disabled={option.disabled}
      >
        {option.label}
      </Option>
    );
  };

  render() {
    const { placement, togglerElement } = this.props;

    return (
      <StyledDropdown
        placement={placement}
        togglerElement={togglerElement}
        noOption={this.renderNoOption()}
        options={this.getOptions()}
        renderOption={this.renderOption}
      />
    );
  }
}

function mapStateToProps({ meta }, { entity }) {
  return {
    isExportingTracks: meta[`${entity}s`].isExportingTracks,
    isArchivingTracks: meta[`${entity}s`].isArchivingTracks,
    isDownloadingAudiofile: meta[`${entity}s`].isDownloadingAudiofile,
    isDownloadingCopyright: meta[`${entity}s`].isDownloadingCopyright,
  };
}

export default compose(
  connect(mapStateToProps, (dispatch, { entity }) => ({
    archiveFnc: (...args) => dispatch(actions[entity].archive(...args)),
    exportFnc: (...args) => dispatch(actions[entity].export(...args)),
    downloadAudiofileFnc: (...args) => dispatch(actions[entity].downloadAudiofile(...args)),
    copyrightFnc: (...args) => dispatch(actions[entity].copyright(...args)),
  })),
  withTranslation(['pages']),
)(MetaDownloadDropdown);
