// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { withTranslation } from '../../../../config/i18n';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { formatDateString } from '../../../../helpers/dates';

// Styles
import {
  DocumentStatus,
  DocumentStatusAction,
  DocumentStatusInnerWrapper,
  DuplicateIcon,
  TrashIcon,
} from '../common.styles';

// =============================
// Component
// =============================

class DocStatus extends Component {
  static propTypes = {
    isModifying: PropTypes.bool.isRequired,
    isAutotagging: PropTypes.bool,
    isDuplicating: PropTypes.bool,
    isDeleting: PropTypes.bool.isRequired,
    localPreferences: pth.localPreferences.isRequired,
    documentRights: PropTypes.shape({
      isOwner: PropTypes.bool.isRequired,
    }).isRequired,
    updatedAt: PropTypes.string,
    duplicateDocument: PropTypes.func,
    deleteDocument: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAutotagging: false,
    isDuplicating: false,
    updatedAt: null,
    duplicateDocument: null,
  };

  render() {
    const {
      isModifying,
      isAutotagging,
      isDuplicating,
      isDeleting,
      localPreferences,
      documentRights,
      updatedAt,
      duplicateDocument,
      deleteDocument,
      t,
    } = this.props;

    const { dateFormat } = localPreferences;

    const isMutating = isModifying || isAutotagging || isDuplicating || isDeleting;

    return (
      <DocumentStatus>
        <DocumentStatusInnerWrapper>
          {(!isMutating && !!duplicateDocument) && (
            <DocumentStatusAction onClick={duplicateDocument}>
              <DuplicateIcon />
              {t('common:form.duplicate')}
            </DocumentStatusAction>
          )}
          {isModifying && t('common:form.updating')}
          {isAutotagging && t('common:form.autotagging')}
          {isDuplicating && t('common:form.duplicating')}
          {isDeleting && t('common:form.deleting')}
        </DocumentStatusInnerWrapper>
        <DocumentStatusInnerWrapper textAlign="center">
          {updatedAt && (
            <Fragment>
              {t('common:form.last_update')}
              :&nbsp;
              {formatDateString(updatedAt, dateFormat, true)}
            </Fragment>
          )}
        </DocumentStatusInnerWrapper>
        <DocumentStatusInnerWrapper textAlign="right">
          {(documentRights.isOwner && !isMutating) && (
            <DocumentStatusAction onClick={deleteDocument}>
              {t('common:form.delete')}
              <TrashIcon />
            </DocumentStatusAction>
          )}
        </DocumentStatusInnerWrapper>
      </DocumentStatus>
    );
  }
}

export default withTranslation(['common'])(DocStatus);
