// =============================
// Imports
// =============================

// External Dependencies
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Icons } from '@mewo/components';
import Router from 'next/router';
import artistplaceholder from '@mewo/components/assets/images/placeholders/artist_500x500.png?webp';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _compact from 'lodash/compact';

// Config
import { withTranslation } from '../../../../config/i18n';
import entityConfig from '../../meta/artists/config';

// Actions
import { panelActions, triggerAutosaveCheck } from '../../../../store/actions/SidePanelActions';

// Constants
import { META_ARTIST_PANEL } from '../../../../store/constants/SidePanelTypes';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import DocumentStatus from '../documentStatus';
import Autosave from '../../../other/autosave';
import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import InputWithLanguage from '../../../containers/inputWithLanguage';
import TextAreaInput from '../../../presentationals/inputs/textAreaInput';
import SelectInput from '../../../presentationals/inputs/selectInput';
import GooglePlacesInput from '../../../presentationals/inputs/googlePlacesInput';
import MetaRightsInformations from '../metaRightsInformations';
import Uploader from '../../../presentationals/uploader';
import ApiSelectInput from '../../../containers/inputs/apiSelectInput';
import ContactsInput from '../../../containers/contactsInput';
import MetaCustomFields from '../metaCustomFields';
import WithMetaSearchRoute from '../../../faac/withMetaSearchRoute';
import MetaDownloadDropdown from '../../../other/metaDownloadDropdown';
import CopyLink from '../copyLink';

import validator from '../../../../validators/meta/artist';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getSiteOrigin } from '../../../../helpers/misc';
import { getApiSelectInputData, getDocumentRights, getGenericDocCoverUrl } from '../../../../helpers/meta-common';
import { getDocName, getSearchName } from '../../../../helpers/doc-names';
import { formatDateString } from '../../../../helpers/dates';

// Styles
import {
  AddItemBtn,
  DeleteItemButton,
  DocumentBody,
  DocumentCreatedAt,
  DocumentName,
  DocumentType,
  DownloadActionIcon,
  DownloadActionWrapper,
  FileHelper,
  GenericCover,
  GenericOwnership,
  GenericOwnershipCount,
  GenericSearchIcon,
  GenericSearchLink,
  InputItemBox,
  ItemAsLink,
  LeftColumn,
  LineInnerLoader,
  LineLoader,
  LineLoaderWrapper,
  MiscHelper,
  RelationJobTooltip,
  RelationsWrapper,
  RelationTitleWrapper,
  RightColumn,
  SectionBox,
  SectionNotice,
  SectionTitle,
  TabElement,
  TabLink,
  TabLinksWrapper,
} from '../common.styles';
import { InputDouble, InputTriple, InputWrapper } from '../../global.styles';

// =============================
// Component
// =============================

const entity = 'artist';

class MetaArtist extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      data: pth.artist,
      additionalData: PropTypes.shape({
        'band-members': PropTypes.shape({
          data: PropTypes.arrayOf(pth.artist),
        }),
        ownerships: PropTypes.shape({
          data: PropTypes.shape({
            master: PropTypes.number,
            publishing: PropTypes.number,
          }),
        }),
      }),
      isLoading: PropTypes.bool.isRequired,
      isModifying: PropTypes.bool.isRequired,
      'isModifying-band-members': PropTypes.bool,
      isDuplicating: PropTypes.bool.isRequired,
      isUploading: PropTypes.bool.isRequired,
      isDeletingUpload: PropTypes.bool.isRequired,
      isDeleting: PropTypes.bool.isRequired,
      uploadProgress: PropTypes.number.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    relationsNotifications: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      progress: PropTypes.number,
    })),
    customFields: pth.customFieldsConfig.isRequired,
    nrs: PropTypes.arrayOf(pth.rightsSociety).isRequired,
    prs: PropTypes.arrayOf(pth.rightsSociety).isRequired,
    mrs: PropTypes.arrayOf(pth.rightsSociety).isRequired,
    providers: PropTypes.arrayOf(pth.provider).isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    relationsNotifications: [],
  };

  pickFormData = (data, documentRights, init = false) => {
    const keys = {
      full_name: _get(data, 'full_name', ''),
      descriptions: _get(data, 'descriptions', [])
        .map(({ locale, value }) => ({ locale, value })),
      artist_ref: _get(data, 'artist_ref', ''),
      artist_type: _get(data, 'artist_type', 'individual'),
      ipi: _get(data, 'ipi', ''),
      ipn: _get(data, 'ipn', ''),
      neighboring_rights_society: init
        ? _get(data, 'neighboring_rights_society.id', null)
        : _get(data, 'neighboring_rights_society', null),
      performing_rights_society: init
        ? _get(data, 'performing_rights_society.id', null)
        : _get(data, 'performing_rights_society', null),
      mechanical_rights_society: init
        ? _get(data, 'mechanical_rights_society.id', null)
        : _get(data, 'mechanical_rights_society', null),
    };

    if (keys.artist_type === 'individual') {
      keys.legal_first_name = _get(data, 'legal_first_name', '');
      keys.legal_middle_name = _get(data, 'legal_middle_name', '');
      keys.legal_last_name = _get(data, 'legal_last_name', '');
      keys.aliases = _compact(data.aliases);
    } else {
      keys.legal_first_name = '';
      keys.legal_middle_name = '';
      keys.legal_last_name = '';
      keys.aliases = [];
    }

    if (documentRights.canAccessContactInfos) {
      keys.artist_email = _get(data, 'artist_email', '');
      keys.artist_phone = _get(data, 'artist_phone', '');
      keys.artist_address = _get(data, 'artist_address', '');
    }

    if (documentRights.canAccessNotes) {
      keys.notes = _get(data, 'notes', '');
    }

    if (documentRights.isOwner) {
      keys.contacts = _get(data, 'contacts', [])
        .filter(people => (init ? true : !!people.people.value))
        .map((people) => {
          if (init) {
            return {
              people: getApiSelectInputData(people, 'people', init),
              role: people.role,
            };
          }

          return {
            people: getApiSelectInputData(people.people, 'people', init),
            role: people.role,
          };
        });

      keys.custom = _get(data, 'custom', {});
    }

    return keys;
  };

  renderPanelAnchors = (data, additionalData) => {
    const { customFields, providers, t } = this.props;

    const documentRights = getDocumentRights(
      data.owned_by_tenant,
      data.tenant.id,
      providers,
    );

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    return (
      <TabLinksWrapper>
        <TabLink name="general_information">
          {t(`pages:meta.${entity}s.panel.general_information_anchor`)}
        </TabLink>
        {(
          documentRights.canWrite
          || !!_get(additionalData, 'band-members.data', []).length
        ) && data.artist_type === 'band' && (
          <TabLink name="band_members_information">
            {t(`pages:meta.${entity}s.panel.band_members_information_anchor`)}
          </TabLink>
        )}
        {(documentRights.isOwner || !!data.contacts?.length) && (
          <TabLink name="contacts_information">
            {t(`pages:meta.${entity}s.panel.contacts_information_anchor`)}
          </TabLink>
        )}
        {(documentRights.isOwner && hasCustomFields) && (
          <TabLink name="custom_fields_information">
            {t(`pages:meta.${entity}s.panel.custom_fields_information_anchor`)}
          </TabLink>
        )}
        {documentRights.isOwner && (
          <TabLink name="agents_information">
            {t(`pages:meta.${entity}s.panel.agents_information_anchor`)}
          </TabLink>
        )}
        {!documentRights.isOwner && (
          <TabLink name="providers_information">
            {t(`pages:meta.${entity}s.panel.providers_information_anchor`)}
          </TabLink>
        )}
        <TabLink name="modo_information">
          {t(`pages:meta.${entity}s.panel.modo_information_anchor`)}
        </TabLink>
        <TabLink name="uploads_information">
          {t(`pages:meta.${entity}s.panel.uploads_information_anchor`)}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  render() {
    const {
      panel,
      localPreferences,
      relationsNotifications,
      customFields,
      nrs,
      prs,
      mrs,
      providers,
      triggerPanelAutosaveCheck,
      i18n: { language },
      t,
    } = this.props;

    const {
      data,
      additionalData,
      isModifying,
      isDuplicating,
      isUploading,
      isDeletingUpload,
      isDeleting,
      uploadProgress,
    } = panel;

    const isModifyingBandMembers = panel['isModifying-band-members'];

    const hasCustomFields = !!customFields.fields
      .filter(customField => customField.collections.includes(entity))
      .length;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/${entity}s?panel=${data.id}`
      : '';

    const nrsOptions = _sortBy(
      nrs.map(({ id, name }) => ({ label: name, value: id })),
      'label',
    );
    const prsOptions = _sortBy(
      prs.map(({ id, name }) => ({ label: name, value: id })),
      'label',
    );
    const mrsOptions = _sortBy(
      mrs.map(({ id, name }) => ({ label: name, value: id })),
      'label',
    );

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[META_ARTIST_PANEL].get}
        getAdditionalOptions={{
          fnc: panelActions[META_ARTIST_PANEL].getAdditional,
          list: [
            ['count/ownerships', 'ownerships'],
            ['bandmembers', 'band-members'],
          ],
        }}
        modify={panelActions[META_ARTIST_PANEL].modify}
        modifyAdditional={panelActions[META_ARTIST_PANEL].modifyAdditional}
        duplicate={panelActions[META_ARTIST_PANEL].duplicate}
        uploadFile={panelActions[META_ARTIST_PANEL].uploadFile}
        deleteFile={panelActions[META_ARTIST_PANEL].deleteFile}
        del={panelActions[META_ARTIST_PANEL].delete}
        lang={{
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {({
          modifyDocument,
          modifyAdditional,
          duplicateDocument,
          uploadDocumentFile,
          deleteDocumentFile,
          deleteDocument,
        }) => {
          if (!data) return null;

          const relationsNotification = relationsNotifications
            .find(notification => notification.id === data.id);

          const documentRights = getDocumentRights(
            data.owned_by_tenant,
            data.tenant.id,
            providers,
          );

          const image = _get(data, 'image.original.url')
            ? {
              name: _get(data, 'image.original.file_name'),
              url: _get(data, 'image.original.url'),
            }
            : null;

          let uploadStatus = data.has_file ? 2 : 0;
          // This needs to be last as it overwrites everything
          if (isUploading || isDeletingUpload) uploadStatus = 1;

          const formDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isUploading
            || isDeletingUpload;
          const uploadDisabled = !documentRights.canWrite
            || !!relationsNotification
            || isModifying;

          const getBandMemberASIAction = (bandMember) => {
            if (bandMember.preview_only) return null;

            return () => triggerPanelAutosaveCheck(
              'open',
              [META_ARTIST_PANEL, bandMember.id],
            );
          };

          let bandMembersASIQuery = '&artist_type=individual';
          if (documentRights.providerId) {
            bandMembersASIQuery += `&provider_id=${documentRights.providerId}`;
          }

          return (
            <Fragment>
              <LeftColumn>
                <GenericCover
                  placeholder={artistplaceholder}
                  cover={getGenericDocCoverUrl(data.image)}
                />
                <DocumentName>
                  {data.full_name}
                </DocumentName>
                <DocumentType>
                  {t(`common:entities.${entity}`)}
                </DocumentType>
                <DocumentCreatedAt>
                  {t(`pages:meta.${entity}s.panel.created_at`)}
                  :&nbsp;
                  {formatDateString(data.created_at, localPreferences.dateFormat, true)}
                </DocumentCreatedAt>
                <CopyLink
                  link={shareLink}
                  content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                  tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
                />
                {!!_get(additionalData, 'ownerships.data.publishing') && (
                  <GenericOwnership>
                    <GenericOwnershipCount>
                      {_get(additionalData, 'ownerships.data.publishing')}
                      &nbsp;
                      {t(`pages:meta.${entity}s.panel.publishing_ownerships`, {
                        count: _get(additionalData, 'ownerships.data.publishing'),
                      })}
                    </GenericOwnershipCount>
                  </GenericOwnership>
                )}
                {!!_get(additionalData, 'ownerships.data.master') && (
                  <GenericOwnership>
                    <GenericOwnershipCount>
                      {_get(additionalData, 'ownerships.data.master')}
                      &nbsp;
                      {t(`pages:meta.${entity}s.panel.master_ownerships`, {
                        count: _get(additionalData, 'ownerships.data.master'),
                      })}
                    </GenericOwnershipCount>
                  </GenericOwnership>
                )}

                <MetaDownloadDropdown
                  entity={entity}
                  documentId={data.id}
                  documentName={data.full_name}
                  canDownload={
                    !!_get(additionalData, 'ownerships.data.publishing')
                    || !!_get(additionalData, 'ownerships.data.master')
                  }
                  togglerElement={(
                    <DownloadActionWrapper>
                      <DownloadActionIcon />
                      {t(`pages:meta.${entity}s.downloads.toggler_title`)}
                    </DownloadActionWrapper>
                  )}
                />

                <WithMetaSearchRoute
                  entity="track"
                  filters={{ artists: [[data.id, data.full_name]] }}
                  resetQuery
                >
                  {routeQuery => (
                    <GenericSearchLink onClick={() => Router.push(`/meta/tracks${routeQuery}`)}>
                      <GenericSearchIcon />
                      {t(`pages:meta.${entity}s.panel.search_tracks`)}
                    </GenericSearchLink>
                  )}
                </WithMetaSearchRoute>
                <WithMetaSearchRoute
                  entity="album"
                  filters={{ artists: [[data.id, data.full_name]] }}
                  resetQuery
                >
                  {routeQuery => (
                    <GenericSearchLink onClick={() => Router.push(`/meta/albums${routeQuery}`)}>
                      <GenericSearchIcon />
                      {t(`pages:meta.${entity}s.panel.search_albums`)}
                    </GenericSearchLink>
                  )}
                </WithMetaSearchRoute>

                {!!relationsNotification && (
                  <RelationsWrapper>
                    <RelationTitleWrapper>
                      {t(`pages:meta.${entity}s.panel.ongoing_relations_job`)}
                      <RelationJobTooltip
                        content={t(`pages:meta.${entity}s.panel.ongoing_relations_job_tooltip`)}
                      >
                        <Icons.Informations />
                      </RelationJobTooltip>
                    </RelationTitleWrapper>
                    <LineLoaderWrapper>
                      <LineLoader>
                        <LineInnerLoader progress={relationsNotification.progress} />
                      </LineLoader>
                    </LineLoaderWrapper>
                  </RelationsWrapper>
                )}
              </LeftColumn>
              <RightColumn initTab="general_information">
                <DocumentStatus
                  isModifying={(
                    isModifying
                    || _get(panel, 'isModifying-band-members', false)
                  )}
                  isDuplicating={isDuplicating}
                  isDeleting={isDeleting}
                  localPreferences={localPreferences}
                  documentRights={documentRights}
                  updatedAt={data.updated_at}
                  duplicateDocument={duplicateDocument}
                  deleteDocument={deleteDocument}
                />
                {this.renderPanelAnchors(data, additionalData)}
                <DocumentBody>
                  <Form
                    onSubmit={() => {}}
                    initialValues={this.pickFormData(data, documentRights, true)}
                    subscription={{ values: true }}
                    keepDirtyOnReinitialize
                    mutators={{ ...arrayMutators }}
                  >
                    {({ form, values }) => (
                      <Fragment>
                        <Autosave
                          debounce={5000}
                          save={modifyDocument}
                          nullValuesTransformer={formValues => this.pickFormData(
                            formValues, documentRights, false,
                          )}
                          autosavePanelId={`${META_ARTIST_PANEL}_${data.id}`}
                        />
                        <TabElement name="general_information">
                          <SectionTitle>{t(`pages:meta.${entity}s.panel.general_information`)}</SectionTitle>
                          <SectionNotice>
                            {t(`pages:meta.${entity}s.panel.general_information_notice`)}
                          </SectionNotice>
                          <SectionBox>
                            <InputWrapper>
                              <InputDouble>
                                <Field
                                  name="full_name"
                                  component={MewoInput}
                                  use={Input}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.full_name`)}
                                  fullWidth
                                  validate={validator.full_name}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                                <Field name="artist_type">
                                  {({ input, meta }) => (
                                    <MewoInput
                                      meta={meta}
                                      input={{
                                        ...input,
                                        onChange: (v) => {
                                          if (v) input.onChange(v);
                                        },
                                      }}
                                      use={SelectInput}
                                      options={[
                                        {
                                          label: t('pages:meta.artists.data.artist_type.individual'),
                                          value: 'individual',
                                        },
                                        {
                                          label: t('pages:meta.artists.data.artist_type.band'),
                                          value: 'band',
                                        },
                                      ]}
                                      label={t(`pages:meta.${entity}s.panel.artist_type`)}
                                      renderOptionsVariant="classic"
                                      renderValuesVariant="text"
                                      fullWidth
                                      context="autosave"
                                      disabled={formDisabled}
                                    />
                                  )}
                                </Field>
                              </InputDouble>
                              {data.artist_type === 'individual' && (
                                <Fragment>
                                  <InputTriple>
                                    <Field
                                      name="legal_first_name"
                                      component={MewoInput}
                                      use={Input}
                                      type="text"
                                      label={t(`pages:meta.${entity}s.panel.legal_first_name`)}
                                      context="autosave"
                                      disabled={formDisabled}
                                    />
                                    <Field
                                      name="legal_middle_name"
                                      component={MewoInput}
                                      use={Input}
                                      type="text"
                                      label={t(`pages:meta.${entity}s.panel.legal_middle_name`)}
                                      context="autosave"
                                      disabled={formDisabled}
                                    />
                                    <Field
                                      name="legal_last_name"
                                      component={MewoInput}
                                      use={Input}
                                      type="text"
                                      label={t(`pages:meta.${entity}s.panel.legal_last_name`)}
                                      context="autosave"
                                      disabled={formDisabled}
                                    />
                                  </InputTriple>
                                  <Fragment>
                                    <FieldArray name="aliases">
                                      {({ fields }) => fields.map((fieldName, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <InputItemBox key={index}>
                                          <Field
                                            name={fieldName}
                                            component={MewoInput}
                                            use={Input}
                                            type="text"
                                            label={t(`pages:meta.${entity}s.panel.alias`)}
                                            fullWidth
                                            validate={validator.aliases(
                                              values.aliases.filter((a, i) => i !== index),
                                            )}
                                            context="autosave"
                                            disabled={formDisabled}
                                          />
                                          {documentRights.canWrite && (
                                            <DeleteItemButton
                                              onClick={() => fields.remove(index)}
                                              disabled={formDisabled}
                                            >
                                              {t('common:form.remove')}
                                            </DeleteItemButton>
                                          )}
                                        </InputItemBox>
                                      ))}
                                    </FieldArray>
                                    {/** Maximum for this item is always 100 items */}
                                    {(documentRights.canWrite && _get(values, 'aliases', []).length < 100) && (
                                      <AddItemBtn
                                        onClick={() => form.mutators.push('aliases', null)}
                                        disabled={formDisabled}
                                      >
                                        {t(`pages:meta.${entity}s.panel.add_alias`)}
                                      </AddItemBtn>
                                    )}
                                  </Fragment>
                                </Fragment>
                              )}
                              <Field
                                name="artist_ref"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.artist_ref`)}
                                fullWidth
                                context="autosave"
                                disabled={formDisabled}
                              />
                            </InputWrapper>
                          </SectionBox>
                          {documentRights.canAccessContactInfos && (
                            <SectionBox>
                              <InputWrapper>
                                <InputDouble>
                                  <Field
                                    name="artist_phone"
                                    component={MewoInput}
                                    use={Input}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.artist_phone`)}
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                  <Field
                                    name="artist_email"
                                    component={MewoInput}
                                    use={Input}
                                    type="text"
                                    label={t(`pages:meta.${entity}s.panel.artist_email`)}
                                    fullWidth
                                    validate={validator.artist_email}
                                    context="autosave"
                                    disabled={formDisabled}
                                  />
                                </InputDouble>
                                <Field
                                  name="artist_address"
                                  component={MewoInput}
                                  use={GooglePlacesInput}
                                  type="text"
                                  label={t(`pages:meta.${entity}s.panel.artist_address`)}
                                  context="autosave"
                                  disabled={formDisabled}
                                />
                              </InputWrapper>
                            </SectionBox>
                          )}
                          <SectionBox>
                            <InputWrapper>
                              <Field
                                name="ipn"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.ipn`)}
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <Field
                                name="neighboring_rights_society"
                                component={MewoInput}
                                use={SelectInput}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.neighboring_rights_society`)}
                                options={nrsOptions}
                                searchable
                                context="autosave"
                                disabled={formDisabled}
                              />
                            </InputWrapper>
                          </SectionBox>
                          <SectionBox>
                            <InputWrapper>
                              <Field
                                name="ipi"
                                component={MewoInput}
                                use={Input}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.ipi`)}
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <Field
                                name="performing_rights_society"
                                component={MewoInput}
                                use={SelectInput}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.performing_rights_society`)}
                                options={prsOptions}
                                searchable
                                context="autosave"
                                disabled={formDisabled}
                              />
                              <Field
                                name="mechanical_rights_society"
                                component={MewoInput}
                                use={SelectInput}
                                type="text"
                                label={t(`pages:meta.${entity}s.panel.mechanical_rights_society`)}
                                options={mrsOptions}
                                searchable
                                context="autosave"
                                disabled={formDisabled}
                              />
                            </InputWrapper>
                          </SectionBox>
                          <SectionBox>
                            <Field
                              name="descriptions"
                              component={MewoInput}
                              use={InputWithLanguage}
                              defaultLanguage={language}
                              label={t(`pages:meta.${entity}s.panel.descriptions`)}
                              fullWidth
                              isTranslatable
                              inputType="textarea"
                              context="autosave"
                              disabled={formDisabled}
                              height="12rem"
                            />
                          </SectionBox>
                          {documentRights.canAccessNotes && (
                            <SectionBox>
                              <Field
                                name="notes"
                                component={MewoInput}
                                use={TextAreaInput}
                                label={t(`pages:meta.${entity}s.panel.notes`)}
                                fullWidth
                                context="autosave"
                                disabled={formDisabled}
                                height="12rem"
                              />
                            </SectionBox>
                          )}
                        </TabElement>

                        {(
                          documentRights.canWrite
                          || !!_get(additionalData, 'band-members.data', []).length
                        ) && data.artist_type === 'band' && (
                          <TabElement name="band_members_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.band_members_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.band_members_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                {_get(additionalData, 'band-members.data', []).map((bandMember, i) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <InputItemBox key={`${bandMember.id}-${i}`}>
                                    <ItemAsLink
                                      title={getDocName(entity)(bandMember)}
                                      onClick={getBandMemberASIAction(bandMember)}
                                    >
                                      {getDocName(entity)(bandMember)}
                                    </ItemAsLink>
                                    {(documentRights.canWrite && !bandMember.preview_only) && (
                                      <DeleteItemButton
                                        onClick={() => modifyAdditional(
                                          'bandmembers',
                                          'delete',
                                          { ids: [bandMember.id] },
                                          'band-members',
                                        )}
                                        disabled={isModifyingBandMembers}
                                        withoutTopSpacing
                                      >
                                        {t('common:form.remove')}
                                      </DeleteItemButton>
                                    )}
                                  </InputItemBox>
                                ))}
                                {/** Maximum for this item is always 100 items */}
                                {(documentRights.canWrite && _get(additionalData, 'band-members.data', []).length < 100) && (
                                  <ApiSelectInput
                                    name="band_members"
                                    label={t(`pages:meta.${entity}s.panel.add_band_member`)}
                                    entity="meta/artists"
                                    type="text"
                                    getEntityLabel={getDocName(entity)}
                                    getSearchEntityLabel={getSearchName(entity)}
                                    listAdditionalQuery={bandMembersASIQuery}
                                    onTheFlyEnabled
                                    onTheFlyField="full_name"
                                    showResetBtn={false}
                                    disabled={isModifyingBandMembers}
                                    onChange={({ value: id }) => modifyAdditional(
                                      'bandmembers',
                                      'post',
                                      { ids: [id] },
                                      'band-members',
                                    )}
                                  />
                                )}
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}

                        {(documentRights.isOwner || !!data.contacts?.length) && (
                          <TabElement name="contacts_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.contacts_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.contacts_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <ContactsInput
                                  data={data.contacts}
                                  form={form}
                                  formValues={values}
                                  name="contacts"
                                  disabled={formDisabled}
                                  documentRights={documentRights}
                                />
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}

                        {documentRights.isOwner && hasCustomFields && (
                          <TabElement name="custom_fields_information">
                            <SectionTitle>{t(`pages:meta.${entity}s.panel.custom_fields_information`)}</SectionTitle>
                            <SectionNotice>
                              {t(`pages:meta.${entity}s.panel.custom_fields_information_notice`)}
                            </SectionNotice>
                            <SectionBox>
                              <InputWrapper>
                                <MetaCustomFields entity={entity} formDisabled={formDisabled} />
                              </InputWrapper>
                            </SectionBox>
                          </TabElement>
                        )}

                        <MetaRightsInformations
                          entity={entity}
                          docAgents={data.agents}
                          docModos={data.showcase}
                          documentRights={documentRights}
                          formDisabled={formDisabled}
                          writableForAgentDisabled
                        />
                      </Fragment>
                    )}
                  </Form>

                  <TabElement name="uploads_information">
                    <SectionTitle>{t(`pages:meta.${entity}s.panel.uploads_information`)}</SectionTitle>
                    <SectionNotice>
                      {t(`pages:meta.${entity}s.panel.uploads_information_notice`)}
                    </SectionNotice>
                    <SectionBox>
                      <Uploader
                        name="image"
                        value={image}
                        status={uploadStatus}
                        disabled={uploadDisabled}
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        onChange={(nextValue) => {
                          if (!nextValue) return deleteDocumentFile();
                          return uploadDocumentFile(nextValue);
                        }}
                        maxSize={entityConfig.panelUploads.image.maxSize}
                      />
                      <FileHelper>
                        {t(`pages:meta.${entity}s.panel.image`)}
                      </FileHelper>
                      {_get(data, 'image.error_key') && (
                        <MiscHelper mt="1.2rem" isError>
                          {t(`errors:conversion.${_get(data, 'image.error_key')}_desc`)}
                        </MiscHelper>
                      )}
                    </SectionBox>
                  </TabElement>
                </DocumentBody>
              </RightColumn>
            </Fragment>
          );
        }}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core, options, user, meta }) {
  return {
    localPreferences: core.localPreferences,
    relationsNotifications: user.relationsNotifications.artists,
    customFields: meta.customFields.data,
    nrs: options.data.neighboringrightssocieties,
    prs: options.data.performingrightssocieties,
    mrs: options.data.mechanicalrightssocieties,
    providers: meta.providers,
  };
}

export default compose(
  connect(mapStateToProps, {
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
  }),
  withTranslation(['common', 'pages', 'components', 'errors']),
)(MetaArtist);
