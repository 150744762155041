// =============================
// Imports
// =============================

// External dependencies
import styled from 'styled-components';
import { Icons } from '@mewo/components';

// Components
import CheckboxInput from '../../../presentationals/inputs/checkboxInput';
import Input from '../../../presentationals/inputs/input';

// =============================
// Styles
// =============================

export const State = styled.div`
  font-size: 1.5rem;
`;

export const ErrorRequestText = styled.div`
  align-items: center;
  color: #999999;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  line-height: 2rem;
  margin-top: 1rem;

  span {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export const ClipboardIcon = styled(Icons.Clipboard).attrs(() => ({
  fill: '#999999',
  width: '2.25rem',
}))``;

export const ConfirmationModal = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const AutotagWarning = styled.span`
  color: ${({ theme }) => theme.colors.type.error};
`;

export const AutotagChoiceSection = styled.div`
  width: 50%;
  padding: 0 2rem;
`;

export const AutotagVerticalSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  height: 18rem;
  width: 0.1rem;
`;

export const AutotagWithVersionsCheckbox = styled(CheckboxInput)`
  margin-bottom: 2rem;
`;

export const AutotagConfirmInput = styled(Input)`
  width: 30rem;
  margin: 0 auto;
  margin-bottom: 4.8rem;
`;
