// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { lighten } from '@material-ui/core';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: ${({ theme }) => lighten(theme.colors.primary, 0.95)};
  min-height: 6rem;
  padding: 1.2rem 2rem;
  width: 33rem;

  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
    `};

  ${({ onClick }) => onClick
    && css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => lighten(theme.colors.primary, 0.975)};
      }
    `};

  ${({ seen }) => seen
    && css`
      background-color: #fff;
    `};

  &:not(:first-child) {
    border-top: 0.1rem solid #f2f2f2;
  }
`;

export const Message = styled.div`
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 0.6rem;

  b {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export const Date = styled.div`
  font-size: 1rem;
`;
