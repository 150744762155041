// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import Modal from '../modal';
import Link from '../../other/link';

import { withTranslation } from '../../../config/i18n';

import {
  ButtonWrapper,
  ModalContent,
  ModalDesc,
  ModalTitle,
  StyledButton,
} from '../../layouts/global.styles';

// =============================
// Component
// =============================

const RouterModal = ({ isOpened, nextHref, nextAsPath, closeRouterModal, t }) => (
  <Modal
    open={isOpened}
    onClose={() => closeRouterModal()}
  >
    <ModalContent width="35">
      <ModalTitle>{t('components:router_modal.confirm_navigation')}</ModalTitle>
      <ModalDesc>{t('components:router_modal.confirm_navigation_notice')}</ModalDesc>
      <ButtonWrapper>
        <StyledButton
          onClick={() => closeRouterModal()}
          color="cancel"
          fillVariant="outline"
          type="button"
        >
          {t('common:form.cancel')}
        </StyledButton>

        <Link
          href={nextHref || '/'}
          nextAs={nextAsPath || nextHref || '/'}
          disableBlockNavigation
          noHover
        >
          <StyledButton
            onClick={() => closeRouterModal()}
            color="error"
            type="button"
          >
            {t('common:form.quit_page')}
          </StyledButton>
        </Link>
      </ButtonWrapper>
    </ModalContent>
  </Modal>
);

RouterModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  nextHref: PropTypes.string,
  nextAsPath: PropTypes.string,
  closeRouterModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

RouterModal.defaultProps = {
  nextHref: null,
  nextAsPath: null,
};

export default withTranslation(['components', 'common'])(RouterModal);
