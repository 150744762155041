// eslint-disable-next-line import/prefer-default-export
export function formatDateString(_date, dateFormat, withTime = true) {
  const date = new Date(_date);

  const dateData = {
    day: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
    month: (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
    year: date.getFullYear(),
    hours: date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
    minutes: date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
  };

  let displayDate;

  switch (dateFormat) {
    case 'MMDDYYYY':
      displayDate = `${dateData.month}/${dateData.day}/${dateData.year}`;
      break;

    case 'DDMMYYYY':
    default:
      displayDate = `${dateData.day}/${dateData.month}/${dateData.year}`;
      break;
  }

  if (!withTime) {
    return displayDate;
  }

  const displayHours = `${dateData.hours}:${dateData.minutes}`;

  return `${displayHours} - ${displayDate}`;
}
