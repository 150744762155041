// =============================
// Imports
// =============================

import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DropdownContext } from '@mewo/components';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios, { isCancel } from 'axios';
import _flatMap from 'lodash/flatMap';

import { withTranslation } from '../../../config/i18n';

import { triggerAutosaveCheck } from '../../../store/actions/SidePanelActions';
import {
  addMetaTracksToRef as addMetaTracksToRefBase,
  removeMetaTracksFromRef as removeMetaTracksFromRefBase,
} from '../../../store/actions/MetaActions';

import * as acts from '../../../store/constants/ActionTypes';
import * as spts from '../../../store/constants/SidePanelTypes';
import * as rqs from '../../../store/constants/RequestTypes';

import { cancelableRequest } from '../../../store/helpers/axios';
import { getApiUrl, getXPreferredLanguage, sleep } from '../../../helpers/misc';

import {
  StyledDropdown,
  Option,
  OptionButton,
  OptionAdded,
  GoToRef,
  Header,
  FilterInputContainer,
  FilterInput,
  MiscMessage,
  PlusIcon,
  NewPlaylistInput,
  AddToPlaylistBtn,
  CreatePlaylistToggler,
  CreatePlaylistForm,
  AddToPlaylistBtnPlusIcon,
  OptionBriefIcon,
} from './styles';

// =============================
// Component
// =============================

class MetaRefAdder extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    placement: PropTypes.string,
    tracks: PropTypes.arrayOf(PropTypes.string).isRequired,
    togglerElement: PropTypes.node.isRequired,
    isHandlingTracks: PropTypes.arrayOf(PropTypes.string).isRequired,
    userToken: PropTypes.string.isRequired,
    addMetaTracksToRef: PropTypes.func.isRequired,
    removeMetaTracksFromRef: PropTypes.func.isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    placement: 'bottom-start',
  };

  state = {
    filter: '',
    refs: [],
    tracksRefs: [],
    hasMoreRefs: true,
    creatingNewPlaylist: false,
    newPlaylistName: '',
  };

  constructor(props) {
    super(props);

    this.dropdownRef = createRef();
  }

  closeNewPlaylistCreation = () => this.setState({ creatingNewPlaylist: false });

  openNewPlaylistCreation = () => this.setState({ creatingNewPlaylist: true });

  fetchTracksRefs = async () => {
    const { tracks, userToken } = this.props;

    try {
      const response = await Promise.all([
        axios({
          method: 'post',
          url: getApiUrl('meta/tracks/playlists/data'),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Auth': userToken,
            'x-preferred-language': getXPreferredLanguage(),
          },
          data: { ids: tracks },
        }),
        axios({
          method: 'post',
          url: getApiUrl('meta/tracks/briefs/data'),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Auth': userToken,
            'x-preferred-language': getXPreferredLanguage(),
          },
          data: { ids: tracks },
        }),
      ]);

      this.setState({
        tracksRefs: [..._flatMap(response, r => r.data)],
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  // page is 0:
  // - When loading first batch of refs
  // - When filter change
  // - After adding to new playlist
  // - After adding to existing ref
  fetchRefs = async (page) => {
    const { t, userToken } = this.props;
    const { refs, filter } = this.state;

    const limit = 10;
    const offset = page * 10;

    const nextQueryParams = new URLSearchParams();
    nextQueryParams.append('limit', limit);
    if (offset) nextQueryParams.append('offset', offset);
    if (filter) nextQueryParams.append('search', filter);

    const stringQuery = nextQueryParams.toString();

    try {
      const response = await cancelableRequest(rqs.GET_REFS_FOR_PLAYLIST_ADDER, {
        method: 'get',
        url: getApiUrl(`meta/internal-refs/list?${stringQuery}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': userToken,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      if (page === 0) {
        if (this.dropdownRef?.current.scrollComponent?.parentElement) {
          const scrollElement = this.dropdownRef?.current.scrollComponent?.parentElement;
          scrollElement.scrollTop = 0;
        }
      }

      this.setState({
        refs: [...(page ? refs : []), ...response.data.results],
        ...(!page && !filter ? {
          newPlaylistName: `${t('common:entities.new_playlist')} ${response.data.total + 1}`,
        } : {}),
        hasMoreRefs: response.data.total > limit + offset,
      });
    } catch (error) {
      if (!isCancel(error)) {
        this.setState({
          hasMoreRefs: false,
        });
      }
    }
  };

  handleClick = () => {
    this.fetchTracksRefs();
  };

  handleAddToRef = async (id, type) => {
    const { tracks, isHandlingTracks, addMetaTracksToRef } = this.props;

    const res = await addMetaTracksToRef(tracks, type, id);

    if (res.type !== acts.ADD_META_TRACKS_TO_REF_FAILURE) {
      if (!isHandlingTracks.length) {
        await sleep(500); // Wait for ES indexation
        await this.fetchRefs(0);
      }

      this.setState(state => ({
        tracksRefs: [...state.tracksRefs, id],
      }));
    }
  };

  handleAddToNewPlaylist = async () => {
    const { tracks, isHandlingTracks, addMetaTracksToRef } = this.props;
    const { newPlaylistName } = this.state;

    this.closeNewPlaylistCreation();

    const {
      payload: { id },
    } = await addMetaTracksToRef(tracks, 'playlist', null, newPlaylistName);

    if (!isHandlingTracks.length) {
      await sleep(500); // Wait for ES indexation
      await this.fetchRefs(0);
    }

    this.setState(state => ({
      tracksRefs: [...state.tracksRefs, id],
    }));
  };

  handleRemoveFromRef = async (id, type) => {
    const { tracks, isHandlingTracks, removeMetaTracksFromRef } = this.props;

    await removeMetaTracksFromRef(tracks, type, id);

    if (!isHandlingTracks.length) {
      await sleep(500); // Wait for ES indexation
      await this.fetchRefs(0);
    }

    this.setState(state => ({
      tracksRefs: [...state.tracksRefs.filter(refId => refId !== id)],
    }));
  };

  handleFilterChange = (e) => {
    const { value } = e.target;

    this.setState({ filter: value }, () => {
      this.fetchRefs(0);
    });
  };

  openRefPanel = (id, type) => {
    const { triggerPanelAutosaveCheck } = this.props;

    triggerPanelAutosaveCheck('open', [spts.metaMapping[type], id]);
  };

  renderFilterInput = () => {
    const { t } = this.props;
    const { filter } = this.state;

    return (
      <FilterInputContainer>
        <FilterInput
          innerRef={(node) => {
            this.inputRef = node;
          }}
          value={filter}
          onChange={this.handleFilterChange}
          name="filterInput"
          placeholder={t('components:meta_ref_adder.search')}
        />
      </FilterInputContainer>
    );
  };

  renderHeaderAndFilterInput = () => (
    <Header>
      {this.renderCreateNewPlaylist()}
      {this.renderFilterInput()}
    </Header>
  );

  renderOption = (option) => {
    const { isHandlingTracks, t } = this.props;
    const { tracksRefs } = this.state;

    const disabled = isHandlingTracks.includes(option.id);
    const added = tracksRefs.includes(option.id);

    return (
      <Option key={option.id}>
        <OptionButton
          disabled={disabled}
          onClick={async () => {
            if (added) return this.handleRemoveFromRef(option.id, option._type);
            return this.handleAddToRef(option.id, option._type);
          }}
        >
          {added && <OptionAdded />}
          {option._type === 'brief' && (
            <OptionBriefIcon title={t('common:entities.brief')} />
          )}
          {option.title}
        </OptionButton>
        <DropdownContext.Consumer>
          {({ closeDropdown }) => (
            <GoToRef
              onClick={() => {
                this.openRefPanel(option.id, option._type);
                closeDropdown();
              }}
            />
          )}
        </DropdownContext.Consumer>
      </Option>
    );
  };

  handleNewPlaylistNameChange = val => this.setState({ newPlaylistName: val });

  handleNewPlaylistKeyDown = (e) => {
    const { key } = e;

    if (key === 'Enter') {
      this.handleAddToNewPlaylist();
    }
  };

  // No brief creation on RefAdder
  renderCreateNewPlaylist = () => {
    const { isHandlingTracks, t } = this.props;
    const { creatingNewPlaylist, newPlaylistName } = this.state;

    if (creatingNewPlaylist) {
      return (
        <CreatePlaylistForm>
          <NewPlaylistInput
            label={t('components:meta_ref_adder.new_playlist_name')}
            name="new_playlist"
            onChange={this.handleNewPlaylistNameChange}
            onKeyDown={this.handleNewPlaylistKeyDown}
            value={newPlaylistName}
          />
          <AddToPlaylistBtn onClick={this.handleAddToNewPlaylist}>
            <AddToPlaylistBtnPlusIcon />
          </AddToPlaylistBtn>
        </CreatePlaylistForm>
      );
    }

    return (
      <CreatePlaylistToggler
        onClick={this.openNewPlaylistCreation}
        disabled={isHandlingTracks.length}
      >
        <PlusIcon />
        {t('components:meta_ref_adder.create_new_playlist')}
      </CreatePlaylistToggler>
    );
  };

  render() {
    const { className, placement, togglerElement, t } = this.props;
    const { filter, refs, hasMoreRefs } = this.state;

    return (
      <StyledDropdown
        className={className}
        header={this.renderHeaderAndFilterInput()}
        placement={placement}
        togglerElement={togglerElement}
        handleClick={this.handleClick}
      >
        <DropdownContext.Consumer>
          {(context) => {
            // If popperjs is not already initialized, wait to display playlist
            // So we can update popperjs depending on dropdown real
            // height (after all playlists are loaded).
            if (!context.updatePopper) return null;
            setTimeout(context.updatePopper, 0);

            return (
              <InfiniteScroll
                key={filter}
                hasMore={hasMoreRefs}
                loadMore={async (nextPage) => {
                  /* https://github.com/CassetteRocks/react-infinite-scroller/pull/193 */
                  if (hasMoreRefs) {
                    // Set temp to avoid fetching many times the page
                    this.setState({ hasMoreRefs: false }, () => {
                      this.fetchRefs(nextPage, context.updatePopper);
                    });
                  }
                }}
                threshold={50}
                useWindow={false}
                pageStart={-1}
              >
                <div ref={this.dropdownRef}>
                  {!hasMoreRefs && !refs.length && (
                    <MiscMessage>
                      {!!filter && t('components:meta_ref_adder.no_results_for_search')}
                      {!filter && t('components:meta_ref_adder.no_refs')}
                    </MiscMessage>
                  )}

                  {refs.map(this.renderOption)}
                </div>
              </InfiniteScroll>
            );
          }}
        </DropdownContext.Consumer>
      </StyledDropdown>
    );
  }
}

function mapStateToProps({ user, meta }) {
  return {
    userToken: user.token,
    isHandlingTracks: meta.refs.isHandlingTracks,
  };
}

export default compose(
  connect(mapStateToProps, {
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
    addMetaTracksToRef: addMetaTracksToRefBase,
    removeMetaTracksFromRef: removeMetaTracksFromRefBase,
  }),
  withTranslation(['components', 'common']),
)(MetaRefAdder);
