// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Menus = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 100 100"
    {...rest}
  >
    <path d="M 14 16 C 9.6054056 16 6 19.6054 6 24 C 6 24.46839 6.046855 24.92825 6.125 25.375 A 2.0001999 2.0001999 0 1 0 10.0625 24.6875 C 10.023449 24.46425 10 24.23381 10 24 C 10 21.76716 11.76717 20 14 20 C 16.232786 20 18 21.76717 18 24 C 18 26.23281 16.232786 28 14 28 C 13.768028 28 13.537155 27.9765 13.3125 27.9375 A 2.0001999 2.0001999 0 1 0 12.625 31.875 C 13.068333 31.952 13.531872 32 14 32 C 18.394572 32 22 28.39459 22 24 C 22 19.60539 18.394572 16 14 16 z M 31.8125 22 A 2.0021961 2.0021961 0 1 0 32 26 L 92 26 A 2.0001999 2.0001999 0 1 0 92 22 L 32 22 A 2.0001999 2.0001999 0 0 0 31.8125 22 z M 14 42 C 9.6054056 42 6 45.6054 6 50 C 6 50.4684 6.046855 50.9283 6.125 51.375 A 2.0001999 2.0001999 0 1 0 10.0625 50.6875 C 10.023449 50.4642 10 50.2338 10 50 C 10 47.7672 11.76717 46 14 46 C 16.232786 46 18 47.7672 18 50 C 18 52.2328 16.232786 54 14 54 C 13.768028 54 13.537155 53.9755 13.3125 53.9375 A 2.0001999 2.0001999 0 1 0 12.625 57.875 C 13.068333 57.952 13.531872 58 14 58 C 18.394572 58 22 54.3946 22 50 C 22 45.60539 18.394572 42 14 42 z M 31.8125 48 A 2.0021961 2.0021961 0 1 0 32 52 L 92 52 A 2.0001999 2.0001999 0 1 0 92 48 L 32 48 A 2.0001999 2.0001999 0 0 0 31.8125 48 z M 14 68 C 9.6054056 68 6 71.6054 6 76 C 6 76.4684 6.046854 76.9282 6.125 77.375 A 2.0001999 2.0001999 0 1 0 10.0625 76.6875 C 10.02345 76.4642 10 76.2339 10 76 C 10 73.7672 11.76717 72 14 72 C 16.232786 72 18 73.7672 18 76 C 18 78.2328 16.232786 80 14 80 C 13.766855 80 13.535066 79.9755 13.3125 79.9375 A 2.0001999 2.0001999 0 1 0 12.625 83.875 C 13.070422 83.953 13.533045 84 14 84 C 18.394572 84 22 80.3946 22 76 C 22 71.6054 18.394572 68 14 68 z M 31.8125 74 A 2.0021961 2.0021961 0 1 0 32 78 L 92 78 A 2.0001999 2.0001999 0 1 0 92 74 L 32 74 A 2.0001999 2.0001999 0 0 0 31.8125 74 z " />
  </Svg>
));

Menus.displayName = 'Menus';

Menus.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Menus.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '1.5rem',
};

export default withTheme(Menus);
