// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { FilterBar as FilterBarBase } from '@mewo/components';

import { headerHeight } from '../header/styles';
import { musicPlayerHeight } from '../player/variables';
import { filterBarWidth } from './variables';

// =============================
// Styles
// =============================

const filterbarHeaderHeight = '18.5rem';
const filterbarContentPaddingBottom = '4rem';

// eslint-disable-next-line import/prefer-default-export
export const FilterBar = styled(FilterBarBase).attrs(() => ({
  classNames: {
    content: 'contentClassName',
    header: 'headerClassName',
    textIndication: 'textIndication',
  },
  onCloseIcon: 'close',
}))`
  bottom: 0;
  left: 0;
  position: fixed;
  top: ${headerHeight};
  transition: all ${({ theme }) => theme.transition.medium};
  transform: translate3d(-${filterBarWidth}, 0, 0);
  width: ${filterBarWidth};
  z-index: ${({ theme }) => theme.zIndex.header - 1};

  ${({ opened }) => opened
    && css`
      transform: translate3d(0, 0, 0);
    `};

  & .headerClassName {
    height: ${filterbarHeaderHeight};
    padding: 2.5rem 3rem 0;

    ${({ noSearchBar }) => noSearchBar && css`
      height: 11.5rem;
    `};
  }

  & .contentClassName {
    padding: ${filterbarHeaderHeight} 3rem ${filterbarContentPaddingBottom};

    ${({ noSearchBar }) => noSearchBar && css`
      padding: 11.5rem 3rem ${filterbarContentPaddingBottom};
    `};
    }


  & .textIndication {
    height: calc(100vh - (${filterbarHeaderHeight} + ${filterbarContentPaddingBottom} + ${headerHeight}));
  }

  ${({ isMusicPlayerOpened }) => isMusicPlayerOpened
    && css`
      & .contentClassName {
        height: calc(100vh - ${headerHeight} - ${musicPlayerHeight});
        max-height: calc(100vh - ${headerHeight} - ${musicPlayerHeight});
      }
    `};
`;
