// =============================
// Imports
// =============================

import { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Audiofile = memo(
  forwardRef(({ fill, onClick, theme, title, ...rest }, ref) => (
    <Svg
      fill={fill || theme.colors.text}
      onClick={onClick}
      ref={ref}
      title={title}
      viewBox="-24 -4 260 260"
      {...rest}
    >
      <path d="M192.509,67.108l-50.922-49.527C134.333,10.526,120.725,5,110.605,5H27.5C15.093,5,5,15.093,5,27.5v197.203  c0,12.406,10.093,22.5,22.5,22.5h155.533c12.406,0,22.5-10.094,22.5-22.5V97.943C205.533,87.586,199.934,74.33,192.509,67.108z   M182.05,77.861c1.803,1.753,3.535,4.378,4.963,7.286h-56.329c-4.136,0-7.5-3.364-7.5-7.5V23.249  c3.154,1.44,6.032,3.226,7.944,5.086L182.05,77.861z M183.033,232.203H27.5c-4.136,0-7.5-3.364-7.5-7.5V27.5  c0-4.136,3.364-7.5,7.5-7.5h80.684v57.647c0,12.407,10.094,22.5,22.5,22.5h59.85v124.556  C190.533,228.839,187.169,232.203,183.033,232.203z" />
      <path d="M140.025,108.096c-4.16,1.048-9.168,2.285-12.555,3.098l-13.219,3.176c-4.459,1.072-9.122,3.106-10.362,4.522   c-1.24,1.415-2.254,6.326-2.254,10.913v32.323c0,4.586,0,8.338,0,8.339c0.001,0-3.749-0.113-8.333-0.252   c0,0-8.869-0.271-16.044,7.243c-8.697,9.103-10.006,21.966-2.926,28.728c7.08,6.765,19.869,4.867,28.565-4.234   c4.678-4.896,5.504-8.318,5.504-8.318c1.076-4.455,1.961-8.105,1.97-8.105c0.007,0,0.011-3.75,0.011-8.336v-27.893   c0-4.586,3.635-9.273,8.075-10.418l21.567-5.556V108.096z M105.93,173.322c0,0,0,0-0.108-0.102c-0.22-0.213-0.22-0.213-0.22-0.213   c-0.252-0.219-0.236-0.262,0.036-0.095c0.272,0.168,0.496,0.376,0.496,0.463C106.133,173.465,106.041,173.439,105.93,173.322z" />
    </Svg>
  )),
);

Audiofile.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Audiofile.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '2rem',
};

export default withTheme(Audiofile);
