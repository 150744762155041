// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

// Components
import Pie from '../../../../../other/charts/pie/index';
import List from '../../../../../other/charts/list/index';

// Helpers
import { getTrackName } from '../../../../../../helpers/doc-names';
import * as pth from '../../proptypes';

// Styles
import { Inline, UserPieStatBox, TitleAsSeparator } from '../../../../modo/stats/styles';

// =============================
// Component
// =============================

class SearchMaiaStats extends Component {
  static propTypes = {
    searchMaiaData: pth.statsSearchMaia.isRequired,
    isFetching: PropTypes.bool.isRequired,
    currentTimeframe: PropTypes.string.isRequired,
    getStats: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { searchMaiaData, isFetching, currentTimeframe, getStats, locale, t } = this.props;

    const topSearches = _get(searchMaiaData, 'top_searches', [])
      .slice(0, 20) // Top 20 only
      .map((d) => {
        if (d.type === 'link') return { value: d.value, count: d.count, is_link: true };
        if (d.type === 'upload') return { value: d.value, count: d.count };
        return { value: getTrackName(d.value, locale), count: d.count };
      });

    const topTypes = _get(searchMaiaData, 'by_type', [])
      .slice(0, 6) // Top 6 only
      .map(c => ({ id: t(`pages:modo.stats.${c.value}`), value: c.count }));

    return (
      <Fragment>
        <TitleAsSeparator mt="3rem" mb="2rem">
          {t('pages:modo.stats.search_maia')}
        </TitleAsSeparator>
        <Inline>
          <List
            title={t('pages:modo.stats.top_searches')}
            header={{
              value: t('pages:modo.stats.source'),
              count: t('pages:modo.stats.occurrence'),
            }}
            width={48.9}
            data={topSearches}
            isLoadingPeriod={isFetching}
            currentPeriod={currentTimeframe}
            getByPeriod={period => getStats(period)}
            maxItemsFolded={6}
          />
        </Inline>
        <Inline ml="2rem">
          <UserPieStatBox>
            <Pie charts={topTypes} />
          </UserPieStatBox>
        </Inline>
      </Fragment>
    );
  }
}

export default SearchMaiaStats;
