// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

// Components
import List from '../../../../../other/charts/list/index';

// Helpers
import { getTrackName } from '../../../../../../helpers/doc-names';
import * as pth from '../../proptypes';

// Styles
import {
  Inline,
  TitleAsSeparator,
} from '../../../../modo/stats/styles';

// =============================
// Component
// =============================

class LastPlayedStats extends Component {
  static propTypes = {
    playsData: pth.statsLastPlayed.isRequired,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { playsData, locale, t } = this.props;

    const lastPlayedTracks = (playsData || [])
      .slice(0, 20) // Top 20 only
      .map(d => ({ value: getTrackName(d, locale), count: d.total }));

    return (
      <Fragment>
        <TitleAsSeparator mt="3rem" mb="2rem">
          {t('pages:modo.stats.last_plays')}
        </TitleAsSeparator>
        <Inline>
          <List
            title={t('pages:modo.stats.last_played_tracks')}
            header={{
              value: t('pages:modo.stats.track_title'),
              count: t('pages:modo.stats.number_of_plays'),
            }}
            width={72.4}
            data={lastPlayedTracks}
            disablePeriods
            maxItemsFolded={8}
          />
        </Inline>
      </Fragment>
    );
  }
}

export default LastPlayedStats;
