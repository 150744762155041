// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import _sortBy from 'lodash/sortBy';
import _mapValues from 'lodash/mapValues';
import _keyBy from 'lodash/keyBy';
import _compact from 'lodash/compact';

import { withTranslation } from '../../../../../config/i18n';

import MewoInput from '../../../../presentationals/mewoInput';
import SelectInput from '../../../../presentationals/inputs/selectInput';
import Autosave from '../../../../other/autosave';

import { getNameWithFallback } from '../../../../../helpers/doc-names';
import * as pth from '../../../../../helpers/proptypes';

import {
  Wrapper,
  Header,
  HeaderTitle,
  Line,
  Occurrence,
  OriginalValue,
  MatchesWrapper,
  MatchWrapper,
  AddMatch,
  DeleteMatch,
  StyledPagination,
} from './styles';
import { GenericText } from '../styles';

// =============================
// Component
// =============================

const entity = 'ingestion';
const MAX_PER_PAGE = 20;

class TagsMatching extends Component {
  static propTypes = {
    /** Props used by styled component to override styles. */
    className: PropTypes.string,
    /** Versions dictionary */
    dictionary: PropTypes.arrayOf(PropTypes.shape({
      original: PropTypes.string,
      match: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
    /** Disable form */
    disabled: PropTypes.bool,
    /** Function to call when changing dictionary */
    handleChange: PropTypes.func,
    /** Current i18n settings */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Ingestion is launched */
    ingestionLaunched: PropTypes.bool.isRequired,
    /** Unmatched being processed */
    processing: PropTypes.bool.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
    /** Tags with cat */
    tags: PropTypes.arrayOf(pth.tagWithCat).isRequired,
    /** Unmatched versions */
    unmatched: PropTypes.objectOf(PropTypes.number).isRequired,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    handleChange: null,
  };

  state = {
    currentPage: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentPage: prevPage } = prevState;
    const { currentPage: nextPage } = this.state;

    if (
      prevPage !== nextPage
      && typeof document !== 'undefined'
      && document.getElementById('panel-body')
    ) {
      document.getElementById('panel-body').scrollTo(0, 0);
    }
  }

  renderForm = () => {
    const {
      dictionary,
      disabled,
      handleChange,
      i18n: { language },
      ingestionLaunched,
      t,
      tags,
      unmatched,
    } = this.props;
    const { currentPage } = this.state;

    const tagsOptions = _sortBy(
      tags.filter(tag => tag.type === 0).map(tag => ({
        label: getNameWithFallback(tag.names, language),
        searchName: getNameWithFallback(tag.search_names, language),
        synonyms: (tag.synonyms.find(e => e.locale === language) || {}).values,
        searchSynonyms: (tag.search_synonyms.find(e => e.locale === language) || {}).values,
        value: tag.id,
      })),
      'label',
    );

    const numberPages = Math.ceil(Object.keys(unmatched).length / MAX_PER_PAGE);

    const dictionaryHash = _mapValues(
      _keyBy(dictionary, 'original'),
      'match',
    );

    const sortedDictionary = _sortBy(
      Object.keys(unmatched),
      value => -unmatched[value],
    )
      .slice(currentPage * MAX_PER_PAGE, (currentPage + 1) * MAX_PER_PAGE)
      .map((original) => {
        const next = {
          original,
          match: dictionaryHash[original],
        };

        // If there are no matches, add at least one for the form
        if (!next.match || !next.match.length) {
          next.match = [''];
        }

        return next;
      });

    return (
      <Fragment>
        <Header>
          <HeaderTitle>
            {t(`pages:meta.${entity}s.panel.tag_occurrences`)}
          </HeaderTitle>
          <HeaderTitle>
            {t(`pages:meta.${entity}s.panel.tag_original`)}
          </HeaderTitle>
          <HeaderTitle>
            {t(`pages:meta.${entity}s.panel.tag_match`)}
          </HeaderTitle>
        </Header>
        <Form
          onSubmit={() => {}}
          initialValues={{ dictionary: sortedDictionary }}
          mutators={{ ...arrayMutators }}
          key={`tags_matching_${currentPage}`}
        >
          {({ form }) => (
            <Fragment>
              <Autosave
                save={handleChange}
                nullValuesTransformer={values => ({
                  dictionary: values.dictionary.map(({ original, match }) => ({
                    original,
                    match: _compact(match),
                  })),
                })}
              />
              <FieldArray name="dictionary">
                {({ fields }) => fields.map((fieldName, index) => {
                  const originalValue = fields.value[index].original;

                  return (
                    <Line key={originalValue}>
                      <Occurrence>
                        {unmatched[originalValue]}
                      </Occurrence>
                      <OriginalValue>
                        {originalValue}
                      </OriginalValue>
                      <FieldArray name={`${fieldName}.match`}>
                        {({ fields: matchFields }) => (
                          <MatchesWrapper>
                            {matchFields.map((matchFieldName, matchIndex) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <MatchWrapper key={matchIndex}>
                                <Field
                                  name={matchFieldName}
                                  component={MewoInput}
                                  use={SelectInput}
                                  type="text"
                                  options={tagsOptions}
                                  renderOptionsVariant="classic"
                                  renderValuesVariant="text"
                                  searchable
                                  showResetBtn={!ingestionLaunched && matchFields.value.length <= 1}
                                  disabled={disabled}
                                />
                                {!ingestionLaunched && matchFields.value.length > 1 && (
                                  <DeleteMatch
                                    onClick={() => matchFields.remove(matchIndex)}
                                    disabled={disabled}
                                  >
                                    {t('common:form.remove')}
                                  </DeleteMatch>
                                )}
                              </MatchWrapper>
                            ))}
                            {/** Maximum for this item is always 10 items */}
                            {!ingestionLaunched && matchFields.value.length < 10 && (
                              <AddMatch
                                onClick={() => form.mutators.push(`${fieldName}.match`, '')}
                                type="button"
                                disabled={disabled}
                              >
                                {t(`pages:meta.${entity}s.panel.add_tag`)}
                              </AddMatch>
                            )}
                          </MatchesWrapper>
                        )}
                      </FieldArray>
                    </Line>
                  );
                })}
              </FieldArray>
            </Fragment>
          )}
        </Form>

        <StyledPagination
          currentPage={currentPage}
          pageCount={numberPages}
          changeSearchPage={nextPage => this.setState({ currentPage: nextPage })}
        />
      </Fragment>
    );
  };

  render() {
    const { processing, unmatched, className, t } = this.props;

    const hasUnmatched = !!Object.keys(unmatched).length;

    return (
      <Wrapper className={className}>
        {processing && (
          <GenericText>
            {t(`pages:meta.${entity}s.panel.unmatched_processing`)}
          </GenericText>
        )}
        {!processing && hasUnmatched && this.renderForm()}
        {!processing && !hasUnmatched && (
          <GenericText>
            {t(`pages:meta.${entity}s.panel.no_unmatched_tags`)}
          </GenericText>
        )}
      </Wrapper>
    );
  }
}

function mapStateToProps({ options }) {
  return {
    tags: options.data.tags_only,
  };
}

export default compose(
  withTranslation(['pages']),
  connect(mapStateToProps),
)(TagsMatching);
