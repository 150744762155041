// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import TabsContext from './context';

// =============================
// Component
// =============================

const TabPanel = (props) => {
  const { className, children, name } = props;

  return (
    <TabsContext.Consumer>
      {({ currentTab }) => currentTab === name && (
        <div className={className}>
          {children}
        </div>
      )}
    </TabsContext.Consumer>
  );
};

TabPanel.propTypes = {
  /** Props used by styled component to override styles. */
  className: PropTypes.string,
  /** Tab panel name, must be equal to associated tab name */
  name: PropTypes.string.isRequired,
  /** Children */
  children: PropTypes.node.isRequired,
};

TabPanel.defaultProps = {
  className: '',
};

export default TabPanel;
