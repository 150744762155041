// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const UsersRights = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 32 32"
    {...rest}
  >
    <path d="M24,17.5c-0.016,0-0.027,0.002-0.042,0.002c-1.183-1.715-2.815-3.119-4.78-3.996c1.127-1.169,1.825-2.756,1.825-4.505  c0-3.584-2.915-6.501-6.499-6.501S8.003,5.417,8.003,9.001c0,1.749,0.698,3.336,1.826,4.505C5.867,15.274,3.205,19.16,3.014,23.5  L3,24c0,1.93,1.57,3.5,3.5,3.5h14.348c0.895,0.628,1.979,1,3.152,1c3.033,0,5.5-2.467,5.5-5.5S27.033,17.5,24,17.5z M9.003,9.001  c0-3.033,2.468-5.501,5.501-5.501c3.032,0,5.499,2.468,5.499,5.501c0,3.032-2.467,5.499-5.499,5.499  C11.471,14.5,9.003,12.033,9.003,9.001z M6.5,26.5c-1.338,0-2.434-1.057-2.497-2.378V24c0-4.301,2.673-8.187,6.671-9.759  c1.076,0.788,2.397,1.259,3.83,1.259c1.432,0,2.754-0.472,3.829-1.26c1.81,0.712,3.323,1.914,4.465,3.396  C20.342,18.188,18.5,20.381,18.5,23c0,1.329,0.475,2.548,1.262,3.5H6.5z M24,27.5c-2.48,0-4.5-2.02-4.5-4.5s2.02-4.5,4.5-4.5  s4.5,2.02,4.5,4.5S26.48,27.5,24,27.5z" />
    <path d="M26.146,21.146L23.5,23.793l-1.646-1.646c-0.194-0.194-0.512-0.194-0.707,0c-0.194,0.195-0.194,0.513,0,0.707l2,2  C23.244,24.951,23.372,25,23.5,25s0.256-0.049,0.354-0.146l3-3c0.195-0.194,0.195-0.512,0-0.707  C26.659,20.952,26.342,20.952,26.146,21.146z" />
  </Svg>
));

UsersRights.displayName = 'UsersRights';

UsersRights.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

UsersRights.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '1.8rem',
};

export default withTheme(UsersRights);
