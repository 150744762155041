// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
`;

export const ActualLang = styled.div`
  display: flex;
  margin-bottom: 1.2rem;
`;

export const LangList = styled.ul`
  display: flex;
  list-style: none;
`;
