// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { triggerAutosaveCheck } from '../../../store/actions/SidePanelActions';

// Constants
import * as panelTypes from '../../../store/constants/SidePanelTypes';

// Components
import SidePanel from '../../presentationals/sidePanel';

// Panels
import MetaPeoplePanel from '../../layouts/panels/metaPeople';
import MetaPublisherPanel from '../../layouts/panels/metaPublisher';
import MetaLabelPanel from '../../layouts/panels/metaLabel';
import MetaArtistPanel from '../../layouts/panels/metaArtist';
import MetaCatalogPanel from '../../layouts/panels/metaCatalog';
import MetaPlaylistPanel from '../../layouts/panels/metaPlaylist';
import MetaBriefPanel from '../../layouts/panels/metaBrief';
import MetaPitchPanel from '../../layouts/panels/metaPitch';
import MetaAlbumPanel from '../../layouts/panels/metaAlbum';
import MetaTrackPanel from '../../layouts/panels/metaTrack';
import MetaIngestionPanel from '../../layouts/panels/metaIngestion';
import ModoUserPanel from '../../layouts/panels/modoUser';

// Helpers
import * as pth from '../../../helpers/proptypes';

// =============================
// Component
// =============================

export function getPanel(type) {
  switch (type) {
    case panelTypes.META_PEOPLE_PANEL:
      return MetaPeoplePanel;

    case panelTypes.META_PUBLISHER_PANEL:
      return MetaPublisherPanel;

    case panelTypes.META_LABEL_PANEL:
      return MetaLabelPanel;

    case panelTypes.META_ARTIST_PANEL:
      return MetaArtistPanel;

    case panelTypes.META_CATALOG_PANEL:
      return MetaCatalogPanel;

    case panelTypes.META_PLAYLIST_PANEL:
      return MetaPlaylistPanel;

    case panelTypes.META_BRIEF_PANEL:
      return MetaBriefPanel;

    case panelTypes.META_PITCH_PANEL:
      return MetaPitchPanel;

    case panelTypes.META_ALBUM_PANEL:
      return MetaAlbumPanel;

    case panelTypes.META_TRACK_PANEL:
      return MetaTrackPanel;

    case panelTypes.META_INGESTION_PANEL:
      return MetaIngestionPanel;

    case panelTypes.MODO_USER_PANEL:
      return ModoUserPanel;

    default:
      return null;
  }
}

export class SidePanelContainer extends Component {
  static propTypes = {
    panels: PropTypes.arrayOf(pth.sidePanel).isRequired,
  };

  constructor(props) {
    super(props);

    // If there is a panel on init
    const currentPanel = props.panels.length ? getPanel(props.panels[0].type) : null;
    this.state = { currentPanel };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { panels } = this.props;
    const { panels: nextPanels } = nextProps;

    if (panels.length !== nextPanels.length && nextPanels.length) {
      // We need to reset currentPanel first in case if the next panel is the
      // same one as the current
      // Eg: Transition from Track Panel to another Track Panel (through track version link)
      this.setState({ currentPanel: null }, () => {
        this.setState({ currentPanel: getPanel(nextPanels[0].type) });
      });
    } else if (panels.length !== nextPanels.length && !nextPanels.length) {
      this.setState({ currentPanel: null });
    }
  }

  render() {
    const { currentPanel } = this.state;

    return <SidePanel {...this.props} CurrentPanel={currentPanel} />;
  }
}

function mapStateToProps({ sidepanel, player }) {
  const { isOpened, panels } = sidepanel;

  return {
    playerIsOpened: player.isOpened,
    isOpened,
    panels,
  };
}

export default connect(mapStateToProps, {
  openPanel: (...args) => triggerAutosaveCheck('open', args),
  removePanel: (...args) => triggerAutosaveCheck('remove', args),
  closePanel: (...args) => triggerAutosaveCheck('close', args),
})(SidePanelContainer);
