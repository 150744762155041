// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

// Components
import List from '../../../../../other/charts/list/index';

// Proptypes
import * as pth from '../../proptypes';
import * as globalPth from '../../../../../../helpers/proptypes';

// Styles
import { TitleAsSeparator } from '../../../../modo/stats/styles';

// =============================
// Component
// =============================

class SearchTagsStats extends Component {
  static propTypes = {
    searchTagsData: pth.statsSearchTags.isRequired,
    isFetching: PropTypes.bool.isRequired,
    currentTimeframe: PropTypes.string.isRequired,
    getStats: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(globalPth.tagCategory).isRequired,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { tags, locale } = props;

    this.flattenedTags = [];
    this.flattenedTagSubCategories = [];

    tags.forEach((tagCategory) => {
      tagCategory.tags.forEach((tag) => {
        this.flattenedTags.push({
          id: tag.id,
          name: (_get(tag, 'names', []).find(n => n.locale === locale) || {}).value,
          rootCategoryName: (_get(tagCategory, 'names', []).find(n => n.locale === locale) || {})
            .value,
        });
      });

      tagCategory.sub_categories.forEach((tagSubCategory) => {
        this.flattenedTagSubCategories.push({
          id: tagSubCategory.id,
          name: (_get(tagSubCategory, 'names', []).find(n => n.locale === locale) || {}).value,
          rootCategoryName: (_get(tagCategory, 'names', []).find(n => n.locale === locale) || {})
            .value,
        });

        tagSubCategory.tags.forEach((tag) => {
          this.flattenedTags.push({
            id: tag.id,
            name: (_get(tag, 'names', []).find(n => n.locale === locale) || {}).value,
            subCategoryName: (
              _get(tagSubCategory, 'names', []).find(n => n.locale === locale) || {}
            ).value,
            rootCategoryName: (
              _get(tagCategory, 'names', []).find(n => n.locale === locale) || {}
            ).value,
          });
        });
      });
    });
  }

  render() {
    const { searchTagsData, isFetching, currentTimeframe, getStats, t } = this.props;

    const topTags = _get(searchTagsData, 'top_tags', [])
      .slice(0, 20) // Top 20 only
      .map((tag) => {
        if (!tag.category) {
          const flattenedTag = this.flattenedTags.find(v => v.id === tag.id);
          const value = flattenedTag.subCategoryName
            ? `${flattenedTag.name} (${flattenedTag.rootCategoryName} > ${flattenedTag.subCategoryName})`
            : `${flattenedTag.name} (${flattenedTag.rootCategoryName})`;

          return { value, count: tag.count };
        }

        const flattenedTag = this.flattenedTagSubCategories.find(v => v.id === tag.id);
        const value = `${flattenedTag.name} (${flattenedTag.rootCategoryName})`;

        return { value, count: tag.count };
      });

    return (
      <Fragment>
        <TitleAsSeparator mt="3rem" mb="2rem">
          {t('pages:modo.stats.search_tags')}
        </TitleAsSeparator>
        <List
          title={t('pages:modo.stats.top_searches')}
          header={{
            value: t('pages:modo.stats.tag'),
            count: t('pages:modo.stats.occurrence'),
          }}
          width={72.4}
          data={topTags}
          isLoadingPeriod={isFetching}
          currentPeriod={currentTimeframe}
          getByPeriod={period => getStats(period)}
          maxItemsFolded={6}
        />
      </Fragment>
    );
  }
}

export default SearchTagsStats;
