// =============================
// Imports
// =============================

// External Dependencies
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'react-final-form';
import { Div } from '@mewo/components';
import _sortBy from 'lodash/sortBy';

// Components
import Link from '../../../other/link';
import MewoInput from '../../../presentationals/mewoInput';
import SelectInput from '../../../presentationals/inputs/selectInput';
import SwitchInput from '../../../presentationals/inputs/switchInput';
import RatingInput from '../../../presentationals/inputs/ratingInput';

// Config
import { withTranslation } from '../../../../config/i18n';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import metaEntities from '../../../../config/meta-entities';

// Styles
import { ResyncableAction, ResyncableActions, ResyncableCount, ResyncableValue, ResyncableWrapper } from './styles';
import {
  GenericSmallTitle,
  GenericText,
  GenericUnderlinedItem,
  SectionBox,
  SectionNotice,
  SectionTitle,
  TabElement,
} from '../common.styles';
import { MiscHelper } from '../../modo/common.styles';
import { InputWrapper } from '../../global.styles';
import { StyledChip } from '../../meta/entities/renderers/musicType/styles';

// =============================
// Component
// =============================

class MetaRightsInformations extends Component {
  static propTypes = {
    agents: PropTypes.arrayOf(pth.agent).isRequired,
    docAgents: PropTypes.arrayOf(PropTypes.string),
    docModos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      website_title: PropTypes.string.isRequired,
    })),
    docPublic: PropTypes.bool,
    documentRights: PropTypes.shape({
      canWrite: PropTypes.bool.isRequired,
      canAccessContactInfos: PropTypes.bool.isRequired,
      canAccessNotes: PropTypes.bool.isRequired,
      canAccessTracksSplits: PropTypes.bool.isRequired,
      isOwner: PropTypes.bool.isRequired,
      providerName: PropTypes.string,
    }).isRequired,
    entity: PropTypes.oneOf(metaEntities).isRequired,
    formDisabled: PropTypes.bool.isRequired,
    modifyAdditional: PropTypes.func,
    modoConfigs: PropTypes.arrayOf(pth.modoConfigListing).isRequired,
    resyncable: PropTypes.bool,
    sharesData: PropTypes.shape({
      public: PropTypes.number,
      agents: PropTypes.objectOf(PropTypes.number),
      showcase: PropTypes.objectOf(PropTypes.number),
      total: PropTypes.number,
    }),
    writableForAgentDisabled: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // Value does not exist for labels, publishers & artists
    docPublic: false,
    // As agent, you do not get agents from provider doc
    // In pitches, there are no agents
    docAgents: [],
    // In briefs, there are no modos
    docModos: [],
    modifyAdditional: () => {},
    resyncable: false,
    sharesData: null,
  };

  renderAgents = () => {
    const {
      entity,
      docAgents,
      documentRights,
      agents,
      formDisabled,
      t,
    } = this.props;

    if (!documentRights.isOwner || entity === 'pitch') return null;

    if (['catalog', 'album', 'track', 'playlist', 'brief'].includes(entity)) {
      const agentsOptions = _sortBy(
        agents
          .filter(agent => agent.active_as_agent)
          .map((agent) => {
            const musicType = agent.organization_settings?.meta.music_types.wants.map(
              type => t(`pages:meta.tracks.data.music_type.${type}`),
            ).join(', ');

            return {
              value: agent.id,
              label: `${agent.company_name || `${agent.first_name} ${agent.last_name}`}${musicType ? ` - (${musicType})` : ''}`,
            };
          }),
        'label',
      );

      return (
        <TabElement name="agents_information">
          <SectionTitle>{t(`pages:meta.${entity}s.panel.agents_information`)}</SectionTitle>
          <SectionNotice>
            {t(`pages:meta.${entity}s.panel.agents_information_notice`)}
          </SectionNotice>
          <SectionBox>
            {!agents.length ? (
              <GenericText>
                {t(`pages:meta.${entity}s.panel.no_active_agents`)}
              </GenericText>
            ) : (
              <InputWrapper>
                <Field
                  name="public"
                  component={MewoInput}
                  use={SwitchInput}
                  type="boolean"
                  textOn={t(`pages:meta.${entity}s.panel.public_on`)}
                  textOff={t(`pages:meta.${entity}s.panel.public_off`)}
                  context="autosave"
                  disabled={formDisabled}
                />
                <Field
                  name="agents"
                  component={MewoInput}
                  use={SelectInput}
                  type="text"
                  label={t(`pages:meta.${entity}s.panel.agents_label`)}
                  options={agentsOptions}
                  multiple
                  searchable
                  selectAllHelper
                  renderOptionsVariant="check"
                  renderValuesVariant="count"
                  countName={t(`pages:meta.${entity}s.panel.agent`)}
                  countNamePlural={t(`pages:meta.${entity}s.panel.agents`)}
                  countAll={t(`pages:meta.${entity}s.panel.all_agents`)}
                  disabled={formDisabled}
                />
              </InputWrapper>
            )}
            <MiscHelper mt="2rem">
              {t(`pages:meta.${entity}s.panel.how_you_share_to_agents`)}
            </MiscHelper>
          </SectionBox>
        </TabElement>
      );
    }

    const docAgentsWithData = _sortBy(
      docAgents.map(docAgent => agents.find(agent => agent.id === docAgent)),
      docAgent => docAgent.company_name || docAgent.first_name,
    );

    return (
      <TabElement name="agents_information">
        <SectionTitle>{t(`pages:meta.${entity}s.panel.agents_information`)}</SectionTitle>
        <SectionNotice>
          {t(`pages:meta.${entity}s.panel.agents_information_notice`)}
        </SectionNotice>
        <SectionBox>
          {!docAgents.length && (
            <GenericText>
              {t(`pages:meta.${entity}s.panel.not_shared_to_agents`)}
            </GenericText>
          )}
          {!!docAgents.length && (
            <Fragment>
              <GenericSmallTitle>
                {t(`pages:meta.${entity}s.panel.shared_to_agents`)}
                :
              </GenericSmallTitle>
              <GenericText>
                {docAgentsWithData.map((agent, i) => (
                  <Fragment key={agent.id}>
                    {i > 0 && (
                      <Fragment>,&nbsp;</Fragment>
                    )}
                    <GenericUnderlinedItem>
                      {agent.company_name || `${agent.first_name} ${agent.last_name}`}
                    </GenericUnderlinedItem>
                  </Fragment>
                ))}
              </GenericText>
            </Fragment>
          )}
          <MiscHelper mt="2rem">
            {t(`pages:meta.${entity}s.panel.how_you_share_to_agents`)}
          </MiscHelper>
        </SectionBox>
      </TabElement>
    );
  };

  renderResyncableAgents = () => {
    const {
      agents,
      docAgents,
      docPublic,
      documentRights,
      entity,
      formDisabled,
      modifyAdditional,
      sharesData,
      t,
    } = this.props;

    if (!documentRights.isOwner) return null;

    const agentsOptions = _sortBy(
      agents
        .filter(agent => agent.active_as_agent)
        .map(agent => ({
          value: agent.id,
          label: `${agent.company_name || `${agent.first_name} ${agent.last_name}`}`,
          musicTypes: agent.organization_settings?.meta.music_types.wants,
        })),
      'label',
    );

    return (
      <TabElement name="agents_information">
        <SectionTitle>{t(`pages:meta.${entity}s.panel.agents_information`)}</SectionTitle>
        <SectionNotice>
          {t(`pages:meta.${entity}s.panel.agents_information_notice`)}
        </SectionNotice>
        <SectionBox>
          {!agents.length ? (
            <GenericText>
              {t(`pages:meta.${entity}s.panel.no_active_agents`)}
            </GenericText>
          ) : (
            <InputWrapper>
              <ResyncableWrapper>
                <ResyncableValue>
                  {docPublic
                    ? t(`pages:meta.${entity}s.panel.public_on`)
                    : t(`pages:meta.${entity}s.panel.public_off`)}
                </ResyncableValue>
                <ResyncableCount>
                  {`${sharesData.public} / ${sharesData.total}`}
                </ResyncableCount>
                <ResyncableActions>
                  {!docPublic && (
                    <ResyncableAction
                      disabled={formDisabled}
                      onClick={() => modifyAdditional(
                        'shares',
                        'put',
                        { type: 'public', strategy: 'add' },
                        'shares',
                        true,
                      )}
                    >
                      {t('common:form.share')}
                    </ResyncableAction>
                  )}
                  {docPublic && (
                    <Fragment>
                      {sharesData.public < sharesData.total && (
                        <ResyncableAction
                          disabled={formDisabled}
                          onClick={() => modifyAdditional(
                            'shares',
                            'put',
                            { type: 'public', strategy: 'sync' },
                            'shares',
                            true,
                          )}
                        >
                          {t('common:form.resynchronize')}
                        </ResyncableAction>
                      )}
                      <ResyncableAction
                        disabled={formDisabled}
                        onClick={() => modifyAdditional(
                          'shares',
                          'put',
                          { type: 'public', strategy: 'remove' },
                          'shares',
                          true,
                        )}
                      >
                        {t('common:form.unshare')}
                      </ResyncableAction>
                    </Fragment>
                  )}
                </ResyncableActions>
              </ResyncableWrapper>
              {agentsOptions.map((agent, index) => (
                <ResyncableWrapper key={agent.value} mt={index === 0 ? '5rem' : 0}>
                  <ResyncableValue>{agent.label}</ResyncableValue>
                  <div style={{ marginLeft: '1rem', scale: '0.8', transformOrigin: 'left' }}>
                    {agent.musicTypes.map(type => (
                      <StyledChip
                        key={type}
                        name={t(`pages:meta.tracks.data.music_type.${type}`)}
                        type={type}
                      />
                    ))}
                  </div>
                  <ResyncableCount>
                    {`${sharesData.agents[agent.value] || 0} / ${sharesData.total}`}
                  </ResyncableCount>
                  <ResyncableActions>
                    {!docAgents.includes(agent.value) && (
                      <ResyncableAction
                        disabled={formDisabled}
                        onClick={() => modifyAdditional(
                          'shares',
                          'put',
                          { type: 'agents', strategy: 'add', id: agent.value },
                          'shares',
                          true,
                        )}
                      >
                        {t('common:form.share')}
                      </ResyncableAction>
                    )}
                    {!!docAgents.includes(agent.value) && (
                      <Fragment>
                        {sharesData.agents[agent.value] < sharesData.total && (
                          <ResyncableAction
                            disabled={formDisabled}
                            onClick={() => modifyAdditional(
                              'shares',
                              'put',
                              { type: 'agents', strategy: 'sync', id: agent.value },
                              'shares',
                              true,
                            )}
                          >
                            {t('common:form.resynchronize')}
                          </ResyncableAction>
                        )}
                        <ResyncableAction
                          disabled={formDisabled}
                          onClick={() => modifyAdditional(
                            'shares',
                            'put',
                            { type: 'agents', strategy: 'remove', id: agent.value },
                            'shares',
                            true,
                          )}
                        >
                          {t('common:form.unshare')}
                        </ResyncableAction>
                      </Fragment>
                    )}
                  </ResyncableActions>
                </ResyncableWrapper>
              ))}
            </InputWrapper>
          )}
          <MiscHelper mt="2rem">
            {t(`pages:meta.${entity}s.panel.how_you_share_to_agents`)}
          </MiscHelper>
        </SectionBox>
      </TabElement>
    );
  };

  renderModo = () => {
    const {
      entity,
      docModos,
      modoConfigs,
      writableForAgentDisabled,
      t,
    } = this.props;

    if (entity === 'brief') return null;

    const modoConfigsOptions = modoConfigs.map(modoConfig => ({
      value: modoConfig.id,
      label: modoConfig.website_title,
    }));

    if (['catalog', 'album', 'track', 'playlist', 'pitch'].includes(entity)) {
      return (
        <TabElement name="modo_information">
          <SectionTitle>{t(`pages:meta.${entity}s.panel.modo_information`)}</SectionTitle>
          <SectionNotice>
            {t(`pages:meta.${entity}s.panel.modo_information_notice`)}
          </SectionNotice>
          {entity === 'track' && (
            <SectionBox>
              <Div width="18.5rem">
                <Field
                  name="rating"
                  component={MewoInput}
                  use={RatingInput}
                  type="number"
                  label={t(`pages:meta.${entity}s.panel.rating`)}
                  context="autosave"
                  disabled={writableForAgentDisabled}
                  showResetBtn
                />
              </Div>
              <MiscHelper mt="2rem">
                {t(`pages:meta.${entity}s.panel.how_does_rating_work`)}
              </MiscHelper>
            </SectionBox>
          )}
          <SectionBox>
            {!modoConfigs.length ? (
              <GenericText>
                {t(`pages:meta.${entity}s.panel.no_active_modos`)}
              </GenericText>
            ) : (
              <InputWrapper>
                <Field
                  name="showcase"
                  component={MewoInput}
                  use={SelectInput}
                  type="text"
                  label={t(`pages:meta.${entity}s.panel.modos_label`)}
                  options={modoConfigsOptions}
                  multiple
                  searchable
                  selectAllHelper
                  renderOptionsVariant="check"
                  renderValuesVariant="count"
                  countName={t(`pages:meta.${entity}s.panel.modo`)}
                  countNamePlural={t(`pages:meta.${entity}s.panel.modos`)}
                  countAll={t(`pages:meta.${entity}s.panel.all_modos`)}
                  disabled={writableForAgentDisabled}
                />
              </InputWrapper>
            )}
            <MiscHelper mt="2rem">
              {t(`pages:meta.${entity}s.panel.how_to_make_modo_active`)}
            </MiscHelper>
          </SectionBox>
        </TabElement>
      );
    }

    const modoConfigsIds = modoConfigs.map(config => config.id);

    const filteredDocModos = _sortBy(
      docModos.filter(modo => modoConfigsIds.includes(modo.id)),
      'website_title',
    );

    return (
      <TabElement name="modo_information">
        <SectionTitle>{t(`pages:meta.${entity}s.panel.modo_information`)}</SectionTitle>
        <SectionNotice>
          {t(`pages:meta.${entity}s.panel.modo_information_notice`)}
        </SectionNotice>
        <SectionBox>
          {!filteredDocModos.length && (
            <GenericText>
              {t(`pages:meta.${entity}s.panel.not_active_on_any_modo`)}
            </GenericText>
          )}
          {!!filteredDocModos.length && (
            <Fragment>
              <GenericSmallTitle>
                {t(`pages:meta.${entity}s.panel.active_on_modo`)}
                :
              </GenericSmallTitle>
              {filteredDocModos.map((modo, i) => (
                <Fragment key={modo.id}>
                  {i > 0 && (
                    <Fragment>,&nbsp;</Fragment>
                  )}
                  <Link
                    href={`${window.location.origin}/modo/${modo.id}/general`}
                    target="_blank"
                    rel="noopener noreferrer"
                    inline
                  >
                    <GenericText isLink>{modo.website_title}</GenericText>
                  </Link>
                </Fragment>
              ))}
            </Fragment>
          )}
          <MiscHelper mt="2rem">
            {t(`pages:meta.${entity}s.panel.how_to_make_modo_active`)}
          </MiscHelper>
        </SectionBox>
      </TabElement>
    );
  };

  renderResyncableModo = () => {
    const {
      docModos,
      entity,
      // formDisabled,
      modifyAdditional,
      modoConfigs,
      sharesData,
      t,
    } = this.props;

    const docModoIds = docModos.map(({ id }) => id);

    const modoConfigsOptions = modoConfigs.map(modoConfig => ({
      value: modoConfig.id,
      label: modoConfig.website_title,
    }));

    return (
      <TabElement name="modo_information">
        <SectionTitle>{t(`pages:meta.${entity}s.panel.modo_information`)}</SectionTitle>
        <SectionNotice>
          {t(`pages:meta.${entity}s.panel.modo_information_notice`)}
        </SectionNotice>
        <SectionBox>
          {!modoConfigs.length ? (
            <GenericText>
              {t(`pages:meta.${entity}s.panel.no_active_modos`)}
            </GenericText>
          ) : (
            <InputWrapper>
              {modoConfigsOptions.map(modo => (
                <ResyncableWrapper key={modo.value}>
                  <ResyncableValue>{modo.label}</ResyncableValue>
                  <ResyncableCount>
                    {`${sharesData.showcase[modo.value] || 0} / ${sharesData.total}`}
                  </ResyncableCount>
                  <ResyncableActions>
                    {!docModoIds.includes(modo.value) && (
                      <ResyncableAction
                        // disabled={formDisabled}
                        onClick={() => modifyAdditional(
                          'shares',
                          'put',
                          { type: 'showcase', strategy: 'add', id: modo.value },
                          'shares',
                          true,
                        )}
                      >
                        {t('common:form.share')}
                      </ResyncableAction>
                    )}
                    {!!docModoIds.includes(modo.value) && (
                      <Fragment>
                        {sharesData.showcase[modo.value] < sharesData.total && (
                          <ResyncableAction
                            // disabled={formDisabled}
                            onClick={() => modifyAdditional(
                              'shares',
                              'put',
                              { type: 'showcase', strategy: 'sync', id: modo.value },
                              'shares',
                              true,
                            )}
                          >
                            {t('common:form.resynchronize')}
                          </ResyncableAction>
                        )}
                        <ResyncableAction
                          // disabled={formDisabled}
                          onClick={() => modifyAdditional(
                            'shares',
                            'put',
                            { type: 'showcase', strategy: 'remove', id: modo.value },
                            'shares',
                            true,
                          )}
                        >
                          {t('common:form.unshare')}
                        </ResyncableAction>
                      </Fragment>
                    )}
                  </ResyncableActions>
                </ResyncableWrapper>
              ))}
            </InputWrapper>
          )}
          <MiscHelper mt="2rem">
            {t(`pages:meta.${entity}s.panel.how_to_make_modo_active`)}
          </MiscHelper>
        </SectionBox>
      </TabElement>
    );
  };

  render() {
    const { entity, documentRights, resyncable, t } = this.props;

    return (
      <Fragment>
        {/* Agents */}
        {resyncable ? this.renderResyncableAgents() : this.renderAgents()}

        {/* Provider */}
        {!documentRights.isOwner && (
          <TabElement name="providers_information">
            <SectionTitle>{t(`pages:meta.${entity}s.panel.providers_information`)}</SectionTitle>
            <SectionNotice>
              {t(`pages:meta.${entity}s.panel.providers_information_notice`)}
            </SectionNotice>
            <SectionBox>
              <GenericSmallTitle>
                {t(`pages:meta.${entity}s.panel.owned_by_provider`, {
                  name: documentRights.providerName,
                })}
              </GenericSmallTitle>
              {documentRights.canWrite && (
                <GenericText>
                  {t(`pages:meta.${entity}s.panel.can_write_for_provider`)}
                </GenericText>
              )}
              {documentRights.canAccessNotes && (
                <GenericText>
                  {t(`pages:meta.${entity}s.panel.can_access_provider_notes`)}
                </GenericText>
              )}
              {(
                ['label', 'publisher', 'artist'].includes(entity)
                && documentRights.canAccessContactInfos
              ) && (
                <GenericText>
                  {t(`pages:meta.${entity}s.panel.can_access_contact_infos`)}
                </GenericText>
              )}
              <MiscHelper mt="2rem">
                {t(`pages:meta.${entity}s.panel.how_providers_share_to_you`)}
              </MiscHelper>
            </SectionBox>
          </TabElement>
        )}

        {/* Modo */}
        {resyncable ? this.renderResyncableModo() : this.renderModo()}
      </Fragment>
    );
  }
}

function mapStateToProps({ meta, modo }) {
  return {
    agents: meta.agents,
    modoConfigs: modo.configs.data,
  };
}

export default compose(
  connect(mapStateToProps),
  withTranslation(['common', 'pages', 'components', 'errors']),
)(MetaRightsInformations);
