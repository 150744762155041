/* eslint-disable react-hooks/rules-of-hooks */
// =============================
// Imports
// =============================

import _pick from 'lodash/pick';

import { create } from 'zustand';
import { useEffect, useRef, useCallback } from 'react';
import LanguageSwitcher from '../languageSwitcher';
import Input from '../inputs/input';

import { getSiteOrigin, isHttps } from '../../../helpers/misc';

import { Wrapper, EnvViewer, Toggle, Content } from './styles';

// =============================
// Component
// =============================

const getApiUrl = () => {
  const url = window.localStorage.getItem('devApiUrl');

  if (url && !url.includes('http')) return process.env.API_URL;

  return url || process.env.API_URL;
};

const useDevToolsStore = create(set => ({
  isOpen: false,
  url: '',
  setUrl: (url) => {
    window.localStorage.setItem('devApiUrl', url);

    return set({ url });
  },
  toggle: () => set(state => ({ isOpen: !state.isOpen })),
}));

const MewoDevTools = process.env.APP_ENV === 'prod' ? () => <></> : () => {
  const { isOpen, toggle, url, setUrl } = useDevToolsStore();
  const wrapper = useRef();

  const checkBody = useCallback((event) => {
    const path = event.composedPath ? event.composedPath() : event.path;

    if (!path.includes(wrapper.current)) {
      toggle();
    }
  }, [toggle]);

  useEffect(() => {
    setUrl(getApiUrl());
  }, [setUrl]);

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', checkBody, true);
    } else {
      document.body.removeEventListener('click', checkBody, true);
    }
  }, [isOpen, toggle, checkBody]);

  const configs = _pick(process.env, [
    'APP_ENV',
    'PORT',
    'WEBSITE_TITLE',
    'API_URL',
    'SUPPORT_MAILER',
    'GOOGLE_API_KEY',
    'SENTRY_DSN',
    'GA_CODE',
    'PUBNUB_SUBSCRIBE_KEY',
    'MODO_BRONZE_PRICE',
    'MODO_SILVER_PRICE',
    'MODO_GOLD_PRICE',
    'AUTOTAG_PRICE',
    'NODE_ENV',
    'OPENREPLAY_PROJECT_KEY',
  ]);

  const others = {
    siteOriginClient: getSiteOrigin(),
    isHttpsClient: isHttps(),
  };

  return (
    <Wrapper ref={wrapper} style={isOpen ? { height: '30rem', width: '50rem' } : {}}>
      <Toggle onClick={toggle} />

      <Content>
        <LanguageSwitcher />
        <EnvViewer>{JSON.stringify(configs, 0, 2)}</EnvViewer>
        <EnvViewer>{JSON.stringify(others, 0, 2)}</EnvViewer>

        <div style={{ display: 'flex' }}>
          <button type="button" style={{ margin: '5px' }} onClick={() => setUrl('http://localhost:4040')}>Local</button>
          <button type="button" style={{ margin: '5px' }} onClick={() => setUrl('https://preprod-api-v2.mewo.io')}>Preprod</button>
          <button type="button" style={{ margin: '5px' }} onClick={() => setUrl('https://api-v2.mewo.io')}>Prod</button>
        </div>

        <Input
          name="apiUrl"
          value={url}
          onChange={setUrl}
        />
      </Content>
    </Wrapper>
  );
};

export default MewoDevTools;
