// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

// Components
import List from '../../../../../other/charts/list/index';

// Helpers
import * as pth from '../../proptypes';

// Styles
import { TitleAsSeparator } from '../../../../modo/stats/styles';

// =============================
// Component
// =============================

class SearchStringsStats extends Component {
  static propTypes = {
    searchStringsData: pth.statsSearchStrings.isRequired,
    isFetching: PropTypes.bool.isRequired,
    currentTimeframe: PropTypes.string.isRequired,
    getStats: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { searchStringsData, isFetching, currentTimeframe, getStats, t } = this.props;

    const topSearches = _get(searchStringsData, 'top_searches', [])
      .slice(0, 20) // Top 20 only
      .map(d => ({ value: d.value, count: d.count }));

    return (
      <Fragment>
        <TitleAsSeparator mt="3rem" mb="2rem">
          {t('pages:modo.stats.search_strings')}
        </TitleAsSeparator>
        <List
          title={t('pages:modo.stats.top_searches')}
          header={{
            value: t('pages:modo.stats.string'),
            count: t('pages:modo.stats.occurrence'),
          }}
          width={72.4}
          data={topSearches}
          isLoadingPeriod={isFetching}
          currentPeriod={currentTimeframe}
          getByPeriod={period => getStats(period)}
          maxItemsFolded={6}
        />
      </Fragment>
    );
  }
}

export default SearchStringsStats;
