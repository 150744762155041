// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

// =============================
// Prop types
// =============================

export const statsDownload = PropTypes.shape({
  timeframe: PropTypes.string,
  data: PropTypes.shape({
    total: PropTypes.number,
    top_catalogs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        download_count: PropTypes.number,
      }),
    ),
    top_tracks: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        download_count: PropTypes.number,
      }),
    ),
  }),
});

export const statsPlays = PropTypes.shape({
  timeframe: PropTypes.string,
  data: PropTypes.shape({
    total: PropTypes.number,
    top_catalogs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        play_count: PropTypes.number,
      }),
    ),
    top_tracks: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        play_count: PropTypes.number,
      }),
    ),
  }),
});

export const statsLastDownloaded = PropTypes.arrayOf(PropTypes.shape({
  title: PropTypes.string,
  total: PropTypes.number,
}));

export const statsLastPlayed = PropTypes.arrayOf(PropTypes.shape({
  title: PropTypes.string,
  total: PropTypes.number,
}));

export const statsSearch = PropTypes.shape({
  timeframe: PropTypes.string,
  data: PropTypes.shape({
    total: PropTypes.number,
    average_per_user: PropTypes.number,
    average_per_day: PropTypes.number,
    by_month: PropTypes.arrayOf(
      PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        count: PropTypes.number,
      }),
    ),
  }),
});

export const statsSearchStrings = PropTypes.shape({
  timeframe: PropTypes.string,
  data: PropTypes.shape({
    top_searches: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        count: PropTypes.number,
      }),
    ),
  }),
});

export const statsSearchTags = PropTypes.shape({
  timeframe: PropTypes.string,
  data: PropTypes.shape({
    top_tags: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        count: PropTypes.number,
      }),
    ),
  }),
});

export const statsSearchMaia = PropTypes.shape({
  timeframe: PropTypes.string,
  data: PropTypes.shape({
    top_searches: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        count: PropTypes.number,
        type: PropTypes.string,
      }),
    ),
    by_type: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        count: PropTypes.number,
      }),
    ),
  }),
});
