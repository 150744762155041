// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import Cookies from 'js-cookie';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEqual from 'lodash/isEqual';
import _difference from 'lodash/difference';
import _uniq from 'lodash/uniq';

// Config
import { i18n } from '../../config/i18n';
import locales from '../../config/locales';
import dateFormats from '../../config/date-formats';
import metaLocales from '../../config/meta-locales';

// Meta entities configs
import tracksConfig from '../../components/layouts/meta/tracks/config';
import albumsConfig from '../../components/layouts/meta/albums/config';
import playlistsConfig from '../../components/layouts/meta/playlists/config';
import catalogsConfig from '../../components/layouts/meta/catalogs/config';
import artistsConfig from '../../components/layouts/meta/artists/config';
import publishersConfig from '../../components/layouts/meta/publishers/config';
import labelsConfig from '../../components/layouts/meta/labels/config';
import peoplesConfig from '../../components/layouts/meta/peoples/config';
import ingestionsConfig from '../../components/layouts/meta/ingestions/config';
import pitchsConfig from '../../components/layouts/meta/pitchs/config';
import briefsConfig from '../../components/layouts/meta/briefs/config';

// Helpers
import { getApiUrl, getCookieConfig, getXPreferredLanguage } from '../../helpers/misc';
import determineError from '../../helpers/errors';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Actions
// =============================

export const optionsKeys = [
  'languages',
  'territories',
  'albumtypes',
  'tonalitykeys',
  'trackversions',
  'tempos',
  'rightstypes',
  'tags',
  'mechanicalrightssocieties',
  'neighboringrightssocieties',
  'performingrightssocieties',
  'userindustries',
];

/**
 * Get all options from api
 */
export function getOptions(dispatch) {
  dispatch({
    type: acts.GET_OPTIONS_LOADING,
  });

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'x-preferred-language': getXPreferredLanguage(),
    },
  };

  const url = getApiUrl('public/options');

  return Promise.all(optionsKeys.map(key => axios.get(`${url}/${key}`, config)))
    .then((res) => {
      const data = { initial_facets: {} };

      res.forEach((v, i) => {
        data[optionsKeys[i]] = v.data;

        // NOTE: for trackversions, we create facets for further use
        if (optionsKeys[i] === 'trackversions') {
          data.initial_facets.versions = {};

          v.data.forEach((trackVersion) => {
            data.initial_facets.versions[trackVersion.id] = 1;
          });
        }

        // NOTE: for tags, we create facets for further use
        if (optionsKeys[i] === 'tags') {
          const tagSubcategories = v.data.reduce(
            (acc, e) => [
              ...acc,
              ...e.sub_categories,
            ],
            [],
          );

          const tagsFromCat = v.data.reduce(
            (acc, e) => [
              ...acc,
              ...e.tags,
            ],
            [],
          );

          const tagsFromSc = tagSubcategories.reduce(
            (acc, e) => [
              ...acc,
              ...e.tags,
            ],
            [],
          );

          data.tags_only = [...tagsFromCat, ...tagsFromSc];

          data.initial_facets.tags = {};

          data.tags_only.forEach((tag) => {
            data.initial_facets.tags[tag.id] = 1;
          });
        }
      });

      dispatch({
        type: acts.SET_OPTIONS,
        payload: data,
      });

      return dispatch({
        type: acts.GET_OPTIONS_SUCCESS,
      });
    })
    .catch(err => dispatch({
      type: acts.GET_OPTIONS_FAILURE,
      payload: {
        message: determineError(err),
        reqId: _get(err, 'response.data.reqId'),
      },
    }),
    );
}

/**
 * Initialize app
 */
export function initializeApp() {
  return dispatch => getOptions(dispatch).then(() => dispatch({
    type: acts.INITIALIZE_APP,
  }),
  );
}

/**
 * Router modal actions
 */
export function openRouterModal(nextHref, nextAsPath = null) {
  return {
    type: acts.OPEN_ROUTER_MODAL,
    payload: {
      nextHref,
      nextAsPath,
    },
  };
}

export function closeRouterModal() {
  return {
    type: acts.CLOSE_ROUTER_MODAL,
  };
}

/**
 * Local actions
 */
export function setLocalMetaColumnsPreferences(preferences) {
  if (typeof window === 'undefined') {
    return null;
  }

  const getEditableMetaColumns = columns => columns
    .filter(column => column.editableColumn)
    .map(column => column.key);

  const getLocaleStorageMetaColumn = (localStorageKey, defaultValues) => {
    let value = window.localStorage.getItem(localStorageKey);

    if (value) {
      value = value.split(';');
    } else {
      window.localStorage.setItem(
        localStorageKey,
        defaultValues.join(';'),
      );

      value = defaultValues;
    }

    return value;
  };

  const preferenceMappings = {
    'meta.columns.track': {
      argsKey: 'track',
      localStorageKey: 'meta-columns-track',
      validValues: getEditableMetaColumns(tracksConfig.columns),
      defaultValue: getEditableMetaColumns(tracksConfig.columns),
    },
    'meta.columns.album': {
      argsKey: 'album',
      localStorageKey: 'meta-columns-album',
      validValues: getEditableMetaColumns(albumsConfig.columns),
      defaultValue: getEditableMetaColumns(albumsConfig.columns),
    },
    'meta.columns.playlist': {
      argsKey: 'playlist',
      localStorageKey: 'meta-columns-playlist',
      validValues: getEditableMetaColumns(playlistsConfig.columns),
      defaultValue: getEditableMetaColumns(playlistsConfig.columns),
    },
    'meta.columns.pitch': {
      argsKey: 'pitch',
      localStorageKey: 'meta-columns-pitch',
      validValues: getEditableMetaColumns(pitchsConfig.columns),
      defaultValue: getEditableMetaColumns(pitchsConfig.columns),
    },
    'meta.columns.brief': {
      argsKey: 'brief',
      localStorageKey: 'meta-columns-brief',
      validValues: getEditableMetaColumns(briefsConfig.columns),
      defaultValue: getEditableMetaColumns(briefsConfig.columns),
    },
    'meta.columns.catalog': {
      argsKey: 'catalog',
      localStorageKey: 'meta-columns-catalog',
      validValues: getEditableMetaColumns(catalogsConfig.columns),
      defaultValue: getEditableMetaColumns(catalogsConfig.columns),
    },
    'meta.columns.artist': {
      argsKey: 'artist',
      localStorageKey: 'meta-columns-artist',
      validValues: getEditableMetaColumns(artistsConfig.columns),
      defaultValue: getEditableMetaColumns(artistsConfig.columns),
    },
    'meta.columns.publisher': {
      argsKey: 'publisher',
      localStorageKey: 'meta-columns-publisher',
      validValues: getEditableMetaColumns(publishersConfig.columns),
      defaultValue: getEditableMetaColumns(publishersConfig.columns),
    },
    'meta.columns.label': {
      argsKey: 'label',
      localStorageKey: 'meta-columns-label',
      validValues: getEditableMetaColumns(labelsConfig.columns),
      defaultValue: getEditableMetaColumns(labelsConfig.columns),
    },
    'meta.columns.people': {
      argsKey: 'people',
      localStorageKey: 'meta-columns-people',
      validValues: getEditableMetaColumns(peoplesConfig.columns),
      defaultValue: getEditableMetaColumns(peoplesConfig.columns),
    },
    'meta.columns.ingestion': {
      argsKey: 'ingestion',
      localStorageKey: 'meta-columns-ingestion',
      validValues: getEditableMetaColumns(ingestionsConfig.columns),
      defaultValue: getEditableMetaColumns(ingestionsConfig.columns),
    },
  };

  const columnPreferences = {};

  Object.keys(preferenceMappings).forEach((mappingKey) => {
    const preferenceMapping = preferenceMappings[mappingKey];

    _set(
      columnPreferences,
      mappingKey,
      _uniq(
        _get(preferences, preferenceMapping.argsKey)
        || getLocaleStorageMetaColumn(
          preferenceMapping.localStorageKey,
          preferenceMapping.defaultValue,
        ),
      ),
    );

    let currentValue = _get(columnPreferences, mappingKey);

    if (_difference(currentValue, preferenceMapping.validValues).length) {
      _set(
        columnPreferences,
        mappingKey,
        preferenceMapping.defaultValue,
      );

      currentValue = preferenceMapping.defaultValue;
    }

    const updateLocalStorage = !_isEqual(
      currentValue,
      window.localStorage.getItem(preferenceMapping.localStorageKey),
    );

    if (updateLocalStorage) {
      window.localStorage.setItem(
        preferenceMapping.localStorageKey,
        currentValue.join(';'),
      );
    }
  });

  return {
    type: acts.SET_LOCAL_META_COLUMNS_PREFERENCES,
    payload: columnPreferences,
  };
}

export function setLocalPreferences(preferences) {
  const preferenceMappings = {
    language: {
      cookieKey: 'next-i18next',
      validValues: locales.languagesList,
      defaultValue: locales.defaultLanguage,
    },
    dateFormat: {
      cookieKey: 'date-format',
      validValues: dateFormats.dateFormatsList,
      defaultValue: dateFormats.defaultDateFormat,
    },
    'meta.gridsLanguage': {
      cookieKey: 'meta-grids-language',
      validValues: metaLocales.languagesList,
      defaultValue: metaLocales.defaultLanguage,
    },
    'meta.showTracksTags': {
      cookieKey: 'meta-show-tracks-tags',
      validValues: ['true', 'false'],
      defaultValue: 'true',
    },
  };

  const localPreferences = {};

  Object.keys(preferenceMappings).forEach((mappingKey) => {
    const preferenceMapping = preferenceMappings[mappingKey];

    _set(
      localPreferences,
      mappingKey,
      _get(preferences, mappingKey) || Cookies.get(preferenceMapping.cookieKey),
    );

    let currentValue = _get(localPreferences, mappingKey);

    if (!preferenceMapping.validValues.includes(currentValue)) {
      _set(
        localPreferences,
        mappingKey,
        preferenceMapping.defaultValue,
      );

      currentValue = preferenceMapping.defaultValue;
    }

    if (currentValue !== Cookies.get(preferenceMapping.cookieKey)) {
      if (mappingKey === 'language') {
        i18n.changeLanguage(currentValue);
      }

      Cookies.set(
        preferenceMapping.cookieKey,
        currentValue,
        getCookieConfig(3),
      );
    }
  });

  return {
    type: acts.SET_LOCAL_PREFERENCES,
    payload: localPreferences,
  };
}
