// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Flex, Typo, Loader } from '@mewo/components';

import {
  Wrapper,
  Button,
  DropzoneInnerContainer,
  UploadIcon,
} from './styles';

// =============================
// Component
// =============================

class IngestionImporter extends Component {
  static propTypes = {
    /** Props used by styled component to override styles. */
    className: PropTypes.string,
    /** Disable upload */
    disabled: PropTypes.bool,
    /** Translation strings */
    lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** File max size */
    maxSize: PropTypes.number,
    /** onChange handler */
    onChange: PropTypes.func,
    /** Callback function used to cancel upload */
    onCancelUpload: PropTypes.func,
    /** If True, User is uploading a file */
    uploading: PropTypes.bool,
    /** Uploading Progress */
    uploadingProgress: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    maxSize: 500000000,
    onChange: null,
    onCancelUpload: null,
    uploading: false,
    uploadingProgress: 0,
  };

  state = {
    error: false,
  };

  handleDrop = () => {
    this.setState({
      error: null,
    });
  };

  handleRejectedDrop = () => {
    this.setState({
      error: true,
    });
  };

  handleAcceptedDrop = (file) => {
    const { onChange } = this.props;

    if (onChange) onChange(file[0]);
  };

  render() {
    const {
      className,
      disabled,
      lang,
      maxSize,
      onCancelUpload,
      uploading,
      uploadingProgress,
    } = this.props;

    const { error } = this.state;

    return (
      <Wrapper className={className}>
        <Dropzone
          accept={[
            'text/plain',
            'text/csv',
            'text/x-csv',
            'text/x-comma-separated-values',
            'text/comma-separated-values',
            'application/csv',
            'application/x-csv',
            'application/vnd.ms-excel',
            // NOTE: https://stackoverflow.com/questions/51724649/mime-type-of-file-returning-empty-in-javascript-on-some-machines
            '',
          ]}
          disabled={disabled}
          maxSize={maxSize}
          multiple={false}
          onDrop={this.handleDrop}
          onDropAccepted={this.handleAcceptedDrop}
          onDropRejected={this.handleRejectedDrop}
          name="ingestion"
        >
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
            <DropzoneInnerContainer
              {...getRootProps()}
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
            >
              <input {...getInputProps()} />
              {uploading ? (
                <div>
                  <Loader active type="circular" percentage={uploadingProgress} />
                  {onCancelUpload && <Button onClick={onCancelUpload}>{lang.cancelUpload}</Button>}
                </div>
              ) : (
                <Flex align="center" direction="column">
                  <UploadIcon />
                  <Typo mb="1rem" size={1.2} weight="bold">
                    {lang.dragAndDropOr}
                    <Typo inline color="primary">
                      &nbsp;
                      {lang.browse}
                    </Typo>
                  </Typo>
                  <Typo mb={error ? '1rem' : 0} size={1} weight="semibold">
                    {lang.fileAccepted}
                  </Typo>
                  {error && (
                    <Typo color="type.error" size={1}>
                      {lang.fileNotAccepted}
                    </Typo>
                  )}
                </Flex>
              )}
            </DropzoneInnerContainer>
          )}
        </Dropzone>
      </Wrapper>
    );
  }
}

export default IngestionImporter;
