// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { StyledRatingInput } from './styles';

// =============================
// Component
// =============================

class RatingInput extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <StyledRatingInput
        lang={{
          Label: {
            optional: t('components:mewo_input.Label.optional'),
          },
        }}
        {...rest}
      />
    );
  }
}

export default withTranslation(['components'])(RatingInput);
