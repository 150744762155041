// =============================
// Users
// =============================

// External Dependencies
import axios, { isCancel } from 'axios';
import FileSaver from 'file-saver';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

// Config
import { i18n } from '../../config/i18n';
import metaEntities from '../../config/meta-entities';

// Actions
import { setData as setSidePanelData } from './SidePanelActions';

// Helpers
import { getApiUrl, getXPreferredLanguage, sleep } from '../../helpers/misc';
import determineError from '../../helpers/errors';
import { cancelableRequest, cancelRequest } from '../helpers/axios';
import * as searchHelpers from '../../helpers/meta-search';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';
import * as spts from '../constants/SidePanelTypes';
import downloadLink from '../../helpers/download';

// =============================
// Global
// =============================

export function initializeMeta() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.INITIALIZE_META_LOADING,
    });

    try {
      const agentsRes = await axios({
        method: 'get',
        url: getApiUrl('dashboard/users/agents/all'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      const providersRes = await axios({
        method: 'get',
        url: getApiUrl('dashboard/users/providers/all'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      const entitiesRes = await Promise.all(metaEntities.map(entity => axios({
        method: 'post',
        url: getApiUrl(`meta/${entity}s/search`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: {
          query: '',
          page: 0,
          size: 1,
        },
      })));

      const data = {
        agents: agentsRes.data,
        providers: providersRes.data,
      };

      entitiesRes.forEach((v, i) => {
        data[`${metaEntities[i]}s`] = _pick(v.data, ['facets', 'total']);

        if (metaEntities[i] === 'track') {
          dispatch({
            type: acts.SET_OPTIONS,
            payload: {
              initial_facets: _pick(v.data.facets, ['duration', 'bpm', 'year']),
            },
          });
        }
      });

      dispatch({
        type: acts.SET_META_INITIALIZATION_DATA,
        payload: data,
      });

      return dispatch({
        type: acts.INITIALIZE_META_SUCCESS,
      });
    } catch (error) {
      return dispatch({
        type: acts.INITIALIZE_META_FAILURE,
        payload: {
          message: determineError(error),
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}

export function getMetaCustomFields() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_META_CUSTOM_FIELDS_LOADING,
    });

    try {
      const response = await axios({
        method: 'get',
        url: getApiUrl('meta/custom-fields-config'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_META_CUSTOM_FIELDS,
        payload: response.data,
      });

      return dispatch({
        type: acts.GET_META_CUSTOM_FIELDS_SUCCESS,
      });
    } catch (error) {
      return dispatch({
        type: acts.GET_META_CUSTOM_FIELDS_FAILURE,
        payload: {
          message: determineError(error),
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}

export function openBulkModal(entity, ids) {
  return {
    type: acts.OPEN_META_BULK_MODAL,
    payload: { entity, ids },
  };
}

export function getBulkData(entity, ids) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_META_BULK_MODAL_DATA_LOADING,
    });

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl(`meta/${entity}s/bulk/data`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: { ids },
      });

      dispatch({
        type: acts.SET_META_BULK_MODAL_DATA,
        payload: response.data,
      });

      return dispatch({
        type: acts.GET_META_BULK_MODAL_DATA_SUCCESS,
      });
    } catch (error) {
      let errorMsg;
      switch (true) {
        case error.response && error.response.status === 404:
          errorMsg = i18n.t(`errors:meta.${entity}s.bulk_not_found`);
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      return dispatch({
        type: acts.GET_META_BULK_MODAL_DATA_FAILURE,
        payload: {
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}

export function updateBulk(entity, data) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.UPDATE_META_BULK_LOADING,
    });

    try {
      await axios({
        method: 'put',
        url: getApiUrl(`meta/${entity}s/bulk`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data,
      });

      return dispatch({
        type: acts.UPDATE_META_BULK_SUCCESS,
        payload: { entity },
      });
    } catch (error) {
      let errorMsg;
      switch (true) {
        case error.response && error.response.status === 404:
          errorMsg = i18n.t(`errors:meta.${entity}s.bulk_not_found`);
          break;

        case error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress':
          errorMsg = i18n.t(`errors:meta.${entity}s.relation_in_progress`, {
            count: data.ids.length,
          });
          break;

        case error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion':
          errorMsg = i18n.t(`errors:meta.${entity}s.marked_for_deletion`, {
            count: data.ids.length,
          });
          break;

        case entity === 'track'
          && error.response
          && error.response.status === 406
          && error.response.data.key === 'tracks_autotagging_ongoing':
          errorMsg = i18n.t(`errors:meta.${entity}s.tracks_autotagging_ongoing`, {
            count: data.ids.length,
          });
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      return dispatch({
        type: acts.UPDATE_META_BULK_FAILURE,
        payload: {
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}

export function closeBulkModal() {
  return {
    type: acts.CLOSE_META_BULK_MODAL,
  };
}

export function setMetaSearchSettings({
  searchInDescriptions = false,
}) {
  return {
    type: acts.SET_META_SEARCH_SETTINGS,
    payload: { searchInDescriptions },
  };
}

export function getMetaEntities(entityConfig, page, query, refreshInterval = 0) {
  return async (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    if (refreshInterval) {
      await sleep(refreshInterval);
    }

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl(`meta/${entityConfig.entity}s/search`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: {
          ...searchHelpers.reduxObjectToApiObject({
            entity: entityConfig.entity,
            page,
            query,
            tagCategories: getState().options.data.tags
              .flatMap(v => v.sub_categories.map(s => s.id)),
          }),
          ...(!['people', 'ingestion'].includes(entityConfig.entity) ? {
            search_descriptions: getState().meta.searchSettings.searchInDescriptions,
          } : {}),
        },
      });

      dispatch({
        type: entityConfig.set,
        payload: {
          data: response.data.hits,
          page: response.data.page,
          nbPages: response.data.nb_pages,
          total: response.data.total,
          query: searchHelpers.cleanReduxQueryObjectForUpdate(
            entityConfig.entity,
            query,
          ),
          ...(response.data.facets ? { facets: response.data.facets } : {}),
        },
      });

      return dispatch({
        type: entityConfig.success,
      });
    } catch (error) {
      return dispatch({
        type: entityConfig.failure,
        payload: {
          message: determineError(error),
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}

export function toggleFilterBar(status) {
  return {
    type: acts.TOGGLE_META_FILTER_BAR,
    payload: status,
  };
}

export function launchSimilaritySearch(type, data) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.LAUNCH_SIMILARITY_SEARCH_LOADING,
    });

    try {
      let reqUrl;
      let nextData;
      let displayValue;

      switch (type) {
        case 'upload':
          reqUrl = getApiUrl('meta/tracks/search/upload');

          nextData = new FormData();
          nextData.append('file', data);

          displayValue = data.name;
          break;

        case 'url':
        default:
          reqUrl = getApiUrl('meta/tracks/search/url');
          nextData = { link: data };
          displayValue = data;
          break;
      }

      const response = await cancelableRequest(rqs.LAUNCH_SIMILARITY_SEARCH, {
        method: 'post',
        url: reqUrl,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: nextData,
      });

      return dispatch({
        type: acts.LAUNCH_SIMILARITY_SEARCH_SUCCESS,
        payload: {
          message: i18n.t('pages:meta.tracks.launch_similarity_search_success'),
          value: response.data,
          displayValue,
        },
      });
    } catch (error) {
      return dispatch({
        type: acts.LAUNCH_SIMILARITY_SEARCH_FAILURE,
        payload: {
          message: determineError(error),
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}

export function refreshMetaEntities(entityConfig, ids) {
  return (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    return axios({
      method: 'post',
      url: getApiUrl(`meta/${entityConfig.entity}s/search-by-ids`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { ids },
    })
      .then((response) => {
        dispatch({
          type: entityConfig.set,
          payload: {
            data: response.data,
          },
        });

        return dispatch({
          type: entityConfig.success,
        });
      })
      .catch(err => dispatch({
        type: entityConfig.failure,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function createMetaEntity(entityConfig, data) {
  return async (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl(`meta/${entityConfig.entity}s`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data,
      });

      dispatch({
        type: entityConfig.success,
        payload: {
          entity: entityConfig.entity,
          id: response.data.id,
        },
      });
    } catch (err) {
      dispatch({
        type: entityConfig.failure,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function modifyMetaEntity(entityConfig, id, diff, retainKeys) {
  return async (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
      payload: { id },
    });

    try {
      const response = await axios({
        method: 'put',
        url: getApiUrl(`meta/${entityConfig.entity}s/${id}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: diff,
      });

      // Update data
      const data = getState().meta[`${entityConfig.entity}s`].data.reduce((acc, e) => {
        if (e.id === response.data.id) {
          return [...acc, {
            ..._pick(e, retainKeys),
            ..._omit(response.data, retainKeys),
          }];
        }

        return [...acc, e];
      }, []);

      dispatch({
        type: entityConfig.set,
        payload: { data },
      });

      return dispatch({
        type: entityConfig.success,
        payload: { id },
      });
    } catch (error) {
      let errorMsg;
      switch (true) {
        case typeof entityConfig.getErrorMsg === 'function' && !!entityConfig.getErrorMsg(error):
          errorMsg = entityConfig.getErrorMsg(error);
          break;

        case error.response && error.response.status === 404:
          errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.not_found`);
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      return dispatch({
        type: entityConfig.failure,
        payload: {
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
          id,
        },
      });
    }
  };
}

export function uploadMetaEntityFile(entityConfig, id, fileKey, file) {
  return (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
      payload: { id },
    });

    const form = new FormData();
    form.append('file', file);

    return cancelableRequest(
      // The cancelable request must be unique so that
      // each document can be modified concurrently from the others
      `${entityConfig.rqs}_${id}`,
      {
        method: 'post',
        url: getApiUrl(`meta/uploads/${entityConfig.entity}/${id}/${fileKey}`),
        data: form,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        // Track the progress of upload
        onUploadProgress: (event) => {
          const progress = event.total ? event.loaded / event.total : 0;

          // Update progress state
          dispatch({
            type: entityConfig.setUpdateProgress,
            payload: {
              id,
              progress: parseInt(progress * 100, 10),
            },
          });
        },
      },
    )
      .then((response) => {
        // Update again to make sure everything is alright
        const data = getState().meta[`${entityConfig.entity}s`].data.reduce((acc, e) => {
          if (e.id === response.data.id) {
            return [...acc, {
              ...e,
              ..._pick(response.data, [fileKey, 'has_file']),
            }];
          }

          return [...acc, e];
        }, []);

        dispatch({
          type: entityConfig.set,
          payload: { data },
        });

        return dispatch({
          type: entityConfig.success,
          payload: { id },
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 404:
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.not_found`);
            break;

          case err.response && err.response.status === 403:
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.file_converting`);
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: entityConfig.failure,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
            id,
          },
        });
      });
  };
}

export function cancelMetaEntityFileUpload(entityConfig, id) {
  cancelRequest(`${entityConfig.rqs}_${id}`);

  return {
    type: entityConfig.reset,
    payload: { id },
  };
}

export function deleteMetaEntities(entityConfig, ids) {
  return (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    return axios({
      method: 'delete',
      url: getApiUrl(`meta/${entityConfig.entity}s`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { ids },
    })
      .then(() => dispatch({
        type: entityConfig.success,
        payload: {
          showNotification: ids.length > 1,
          message: i18n.t(
            `pages:meta.${entityConfig.entity}s.delete_success`,
            { count: ids.length },
          ),
        },
      }),
      )
      .catch(err => dispatch({
        type: entityConfig.failure,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }));
  };
}

export function exportMetaTracks(entityConfig, ids, all) {
  return (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    const { dateFormat } = getState().core.localPreferences;

    let baseUrl = 'meta/export/tracks';
    if (all) baseUrl += '/all';

    return axios({
      method: 'post',
      url: getApiUrl(baseUrl),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        ...(!all ? {
          ids,
          context: entityConfig.entity,
        } : {}),
        date_format: dateFormat === 'DDMMYYYY'
          ? 'DD/MM/YYYY'
          : 'MM/DD/YYYY',
      },
    })
      .then(() => dispatch({
        type: entityConfig.success,
        payload: {
          message: i18n.t(`pages:meta.${entityConfig.entity}s.export_tracks_success`),
        },
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 404:
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.export_tracks_not_found`);
            break;

          case err.response
            && err.response.data.key === 'job_creation_failed'
            && err.response.data.message === 'export_ongoing':
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.export_ongoing`);
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: entityConfig.failure,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
            ids,
          },
        });
      });
  };
}

export function exportMetaDocuments(entityConfig) {
  return (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    const { dateFormat } = getState().core.localPreferences;

    return axios({
      method: 'post',
      url: getApiUrl('meta/export'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        context: entityConfig.entity,
        date_format: dateFormat === 'DDMMYYYY'
          ? 'DD/MM/YYYY'
          : 'MM/DD/YYYY',
      },
    })
      .then(() => dispatch({
        type: entityConfig.success,
        payload: {
          message: i18n.t(`pages:meta.${entityConfig.entity}s.export_success`),
        },
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 404:
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.export_not_found`);
            break;

          case err.response
            && err.response.data.key === 'job_creation_failed'
            && err.response.data.message === 'export_ongoing':
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.export_ongoing`);
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: entityConfig.failure,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function archiveMetaTracks(
  entityConfig,
  id,
  quality = 'original',
  withVersions = false,
) {
  return (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    return axios({
      method: 'post',
      url: getApiUrl('meta/archive'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        id,
        context: entityConfig.entity,
        quality,
        with_versions: withVersions,
      },
    })
      .then(() => dispatch({
        type: entityConfig.success,
        payload: {
          message: i18n.t(`pages:meta.${entityConfig.entity}s.archive_tracks_success`),
        },
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 404:
          case err.response
            && err.response.status === 400
            && err.response.data.key === 'no_tracks':
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.archive_tracks_not_found`);
            break;

          case err.response
            && err.response.status === 400
            && err.response.data.key === 'too_many_tracks':
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.archive_tracks_limit_exceeded`);
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: entityConfig.failure,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
            id,
          },
        });
      });
  };
}

export function downloadMetaTrack(entityConfig, id, quality) {
  return async (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    try {
      const response = await fetch(getApiUrl(`meta/tracks/${id}/download?format=${quality}`), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      if (!response.ok) {
        const error = new Error(response.statusText);
        // Reconstruct error stream style
        error.response = {
          status: response.status,
          data: { key: response.statusText },
        };
        throw error;
      }

      downloadLink(await response.json());

      dispatch({
        type: entityConfig.success,
      });
    } catch (error) {
      let errorMsg;
      switch (true) {
        case error.response
          && error.response.status === 404
          && error.response.data.key === 'not_found':
          errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.download_audiofile_not_found`);
          break;

        case error.response
          && error.response.status === 404
          && error.response.data.key === 'aws_s3_get_audiofile_not_found':
          errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.download_audiofile_s3_not_found`);
          break;

        case error.response
          && error.response.status === 500
          && error.response.data.key === 'aws_s3_get_audiofile_error':
          errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.download_audiofile_s3_error`);
          break;

        case error.response
          && error.response.status === 404
          && error.response.data.key === 'aws_s3_get_image_not_found':
          errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.download_audiofile_image_s3_not_found`);
          break;

        case error.response
          && error.response.status === 500
          && error.response.data.key === 'aws_s3_get_image_error':
          errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.download_audiofile_image_s3_error`);
          break;

        case error.response
          && error.response.status === 500
          && error.response.data.key === 'node_id3_error':
          errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.download_audiofile_node_id3_error`);
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      dispatch({
        type: entityConfig.failure,
        payload: {
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
          id,
        },
      });
    }
  };
}

export function downloadMetaCopyright(entityConfig, id, name) {
  return (dispatch, getState) => {
    dispatch({
      type: entityConfig.loading,
    });

    return axios({
      method: 'get',
      url: getApiUrl(`meta/copyright/${entityConfig.entity}/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      responseType: 'blob',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, `${name} - Copyright.txt`);

        dispatch({
          type: entityConfig.success,
          payload: {
            message: i18n.t(`pages:meta.${entityConfig.entity}s.download_copyright_success`),
          },
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 404:
            errorMsg = i18n.t(`errors:meta.${entityConfig.entity}s.download_copyright_not_found`);
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: entityConfig.failure,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
            id,
          },
        });
      });
  };
}

// =============================
// Peoples
// =============================

export function getMetaPeoples(...args) {
  return getMetaEntities({
    entity: 'people',
    loading: acts.GET_META_PEOPLES_LOADING,
    set: acts.SET_META_PEOPLES,
    success: acts.GET_META_PEOPLES_SUCCESS,
    failure: acts.GET_META_PEOPLES_FAILURE,
  }, ...args);
}

export function refreshMetaPeoples(...args) {
  return refreshMetaEntities({
    entity: 'people',
    loading: acts.REFRESH_META_PEOPLES_LOADING,
    set: acts.SET_META_PEOPLES,
    success: acts.REFRESH_META_PEOPLES_SUCCESS,
    failure: acts.REFRESH_META_PEOPLES_FAILURE,
  }, ...args);
}

export function createMetaPeople(...args) {
  return createMetaEntity({
    entity: 'people',
    loading: acts.CREATE_META_PEOPLE_LOADING,
    success: acts.CREATE_META_PEOPLE_SUCCESS,
    failure: acts.CREATE_META_PEOPLE_FAILURE,
  }, ...args);
}

export function modifyMetaPeople(...args) {
  return modifyMetaEntity({
    entity: 'people',
    loading: acts.MODIFY_META_PEOPLE_LOADING,
    set: acts.SET_META_PEOPLES,
    success: acts.MODIFY_META_PEOPLE_SUCCESS,
    failure: acts.MODIFY_META_PEOPLE_FAILURE,
  }, ...args);
}

export function deleteMetaPeoples(...args) {
  return deleteMetaEntities({
    entity: 'people',
    loading: acts.DELETE_META_PEOPLES_LOADING,
    success: acts.DELETE_META_PEOPLES_SUCCESS,
    failure: acts.DELETE_META_PEOPLES_FAILURE,
  }, ...args);
}

export function exportMetaPeoples(...args) {
  return exportMetaDocuments({
    entity: 'people',
    loading: acts.EXPORT_META_PEOPLES_LOADING,
    success: acts.EXPORT_META_PEOPLES_SUCCESS,
    failure: acts.EXPORT_META_PEOPLES_FAILURE,
  }, ...args);
}

// =============================
// Publishers
// =============================

export function getMetaPublishers(...args) {
  return getMetaEntities({
    entity: 'publisher',
    loading: acts.GET_META_PUBLISHERS_LOADING,
    set: acts.SET_META_PUBLISHERS,
    success: acts.GET_META_PUBLISHERS_SUCCESS,
    failure: acts.GET_META_PUBLISHERS_FAILURE,
  }, ...args);
}

export function refreshMetaPublishers(...args) {
  return refreshMetaEntities({
    entity: 'publisher',
    loading: acts.REFRESH_META_PUBLISHERS_LOADING,
    set: acts.SET_META_PUBLISHERS,
    success: acts.REFRESH_META_PUBLISHERS_SUCCESS,
    failure: acts.REFRESH_META_PUBLISHERS_FAILURE,
  }, ...args);
}

export function createMetaPublisher(...args) {
  return createMetaEntity({
    entity: 'publisher',
    loading: acts.CREATE_META_PUBLISHER_LOADING,
    success: acts.CREATE_META_PUBLISHER_SUCCESS,
    failure: acts.CREATE_META_PUBLISHER_FAILURE,
  }, ...args);
}

export function modifyMetaPublisher(...args) {
  return modifyMetaEntity({
    entity: 'publisher',
    loading: acts.MODIFY_META_PUBLISHER_LOADING,
    set: acts.SET_META_PUBLISHERS,
    success: acts.MODIFY_META_PUBLISHER_SUCCESS,
    failure: acts.MODIFY_META_PUBLISHER_FAILURE,
  }, ...args);
}

export function uploadMetaPublisherFile(...args) {
  return uploadMetaEntityFile({
    entity: 'publisher',
    rqs: rqs.UPLOAD_META_PUBLISHER_FILE,
    loading: acts.UPLOAD_META_PUBLISHER_FILE_LOADING,
    set: acts.SET_META_PUBLISHERS,
    setUpdateProgress: acts.SET_UPLOAD_META_PUBLISHER_FILE_PROGRESS,
    success: acts.UPLOAD_META_PUBLISHER_FILE_SUCCESS,
    failure: acts.UPLOAD_META_PUBLISHER_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaPublisherFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_PUBLISHER_FILE,
    reset: acts.CANCEL_UPLOAD_META_PUBLISHER_FILE,
  }, ...args);
}

export function deleteMetaPublishers(...args) {
  return deleteMetaEntities({
    entity: 'publisher',
    loading: acts.DELETE_META_PUBLISHERS_LOADING,
    success: acts.DELETE_META_PUBLISHERS_SUCCESS,
    failure: acts.DELETE_META_PUBLISHERS_FAILURE,
  }, ...args);
}

export function exportMetaPublisherTracks(...args) {
  return exportMetaTracks({
    entity: 'publisher',
    loading: acts.EXPORT_META_PUBLISHER_TRACKS_LOADING,
    success: acts.EXPORT_META_PUBLISHER_TRACKS_SUCCESS,
    failure: acts.EXPORT_META_PUBLISHER_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaPublishers(...args) {
  return exportMetaDocuments({
    entity: 'publisher',
    loading: acts.EXPORT_META_PUBLISHERS_LOADING,
    success: acts.EXPORT_META_PUBLISHERS_SUCCESS,
    failure: acts.EXPORT_META_PUBLISHERS_FAILURE,
  }, ...args);
}

// =============================
// Labels
// =============================

export function getMetaLabels(...args) {
  return getMetaEntities({
    entity: 'label',
    loading: acts.GET_META_LABELS_LOADING,
    set: acts.SET_META_LABELS,
    success: acts.GET_META_LABELS_SUCCESS,
    failure: acts.GET_META_LABELS_FAILURE,
  }, ...args);
}

export function refreshMetaLabels(...args) {
  return refreshMetaEntities({
    entity: 'label',
    loading: acts.REFRESH_META_LABELS_LOADING,
    set: acts.SET_META_LABELS,
    success: acts.REFRESH_META_LABELS_SUCCESS,
    failure: acts.REFRESH_META_LABELS_FAILURE,
  }, ...args);
}

export function createMetaLabel(...args) {
  return createMetaEntity({
    entity: 'label',
    loading: acts.CREATE_META_LABEL_LOADING,
    success: acts.CREATE_META_LABEL_SUCCESS,
    failure: acts.CREATE_META_LABEL_FAILURE,
  }, ...args);
}

export function modifyMetaLabel(...args) {
  return modifyMetaEntity({
    entity: 'label',
    loading: acts.MODIFY_META_LABEL_LOADING,
    set: acts.SET_META_LABELS,
    success: acts.MODIFY_META_LABEL_SUCCESS,
    failure: acts.MODIFY_META_LABEL_FAILURE,
  }, ...args);
}

export function uploadMetaLabelFile(...args) {
  return uploadMetaEntityFile({
    entity: 'label',
    rqs: rqs.UPLOAD_META_LABEL_FILE,
    loading: acts.UPLOAD_META_LABEL_FILE_LOADING,
    set: acts.SET_META_LABELS,
    setUpdateProgress: acts.SET_UPLOAD_META_LABEL_FILE_PROGRESS,
    success: acts.UPLOAD_META_LABEL_FILE_SUCCESS,
    failure: acts.UPLOAD_META_LABEL_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaLabelFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_LABEL_FILE,
    reset: acts.CANCEL_UPLOAD_META_LABEL_FILE,
  }, ...args);
}

export function deleteMetaLabels(...args) {
  return deleteMetaEntities({
    entity: 'label',
    loading: acts.DELETE_META_LABELS_LOADING,
    success: acts.DELETE_META_LABELS_SUCCESS,
    failure: acts.DELETE_META_LABELS_FAILURE,
  }, ...args);
}

export function exportMetaLabelTracks(...args) {
  return exportMetaTracks({
    entity: 'label',
    loading: acts.EXPORT_META_LABEL_TRACKS_LOADING,
    success: acts.EXPORT_META_LABEL_TRACKS_SUCCESS,
    failure: acts.EXPORT_META_LABEL_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaLabels(...args) {
  return exportMetaDocuments({
    entity: 'label',
    loading: acts.EXPORT_META_LABELS_LOADING,
    success: acts.EXPORT_META_LABELS_SUCCESS,
    failure: acts.EXPORT_META_LABELS_FAILURE,
  }, ...args);
}

// =============================
// Artists
// =============================

export function getMetaArtists(...args) {
  return getMetaEntities({
    entity: 'artist',
    loading: acts.GET_META_ARTISTS_LOADING,
    set: acts.SET_META_ARTISTS,
    success: acts.GET_META_ARTISTS_SUCCESS,
    failure: acts.GET_META_ARTISTS_FAILURE,
  }, ...args);
}

export function refreshMetaArtists(...args) {
  return refreshMetaEntities({
    entity: 'artist',
    loading: acts.REFRESH_META_ARTISTS_LOADING,
    set: acts.SET_META_ARTISTS,
    success: acts.REFRESH_META_ARTISTS_SUCCESS,
    failure: acts.REFRESH_META_ARTISTS_FAILURE,
  }, ...args);
}

export function createMetaArtist(...args) {
  return createMetaEntity({
    entity: 'artist',
    loading: acts.CREATE_META_ARTIST_LOADING,
    success: acts.CREATE_META_ARTIST_SUCCESS,
    failure: acts.CREATE_META_ARTIST_FAILURE,
  }, ...args);
}

export function modifyMetaArtist(...args) {
  return modifyMetaEntity({
    entity: 'artist',
    loading: acts.MODIFY_META_ARTIST_LOADING,
    set: acts.SET_META_ARTISTS,
    success: acts.MODIFY_META_ARTIST_SUCCESS,
    failure: acts.MODIFY_META_ARTIST_FAILURE,
    getErrorMsg: ({ response }) => {
      if (
        response
        && response.status === 406
        && response.data.message === 'relation_in_progress'
      ) {
        return i18n.t('errors:meta.artists.relation_in_progress');
      }

      if (
        response
        && response.status === 406
        && response.data.message === 'marked_for_deletion'
      ) {
        return i18n.t('errors:meta.artists.marked_for_deletion');
      }

      return null;
    },
  }, ...args);
}

export function uploadMetaArtistFile(...args) {
  return uploadMetaEntityFile({
    entity: 'artist',
    rqs: rqs.UPLOAD_META_ARTIST_FILE,
    loading: acts.UPLOAD_META_ARTIST_FILE_LOADING,
    set: acts.SET_META_ARTISTS,
    setUpdateProgress: acts.SET_UPLOAD_META_ARTIST_FILE_PROGRESS,
    success: acts.UPLOAD_META_ARTIST_FILE_SUCCESS,
    failure: acts.UPLOAD_META_ARTIST_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaArtistFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_ARTIST_FILE,
    reset: acts.CANCEL_UPLOAD_META_ARTIST_FILE,
  }, ...args);
}

export function deleteMetaArtists(...args) {
  return deleteMetaEntities({
    entity: 'artist',
    loading: acts.DELETE_META_ARTISTS_LOADING,
    success: acts.DELETE_META_ARTISTS_SUCCESS,
    failure: acts.DELETE_META_ARTISTS_FAILURE,
  }, ...args);
}

export function exportMetaArtistTracks(...args) {
  return exportMetaTracks({
    entity: 'artist',
    loading: acts.EXPORT_META_ARTIST_TRACKS_LOADING,
    success: acts.EXPORT_META_ARTIST_TRACKS_SUCCESS,
    failure: acts.EXPORT_META_ARTIST_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaArtists(...args) {
  return exportMetaDocuments({
    entity: 'artist',
    loading: acts.EXPORT_META_ARTISTS_LOADING,
    success: acts.EXPORT_META_ARTISTS_SUCCESS,
    failure: acts.EXPORT_META_ARTISTS_FAILURE,
  }, ...args);
}

// =============================
// Catalogs
// =============================

export function getMetaCatalogs(...args) {
  return getMetaEntities({
    entity: 'catalog',
    loading: acts.GET_META_CATALOGS_LOADING,
    set: acts.SET_META_CATALOGS,
    success: acts.GET_META_CATALOGS_SUCCESS,
    failure: acts.GET_META_CATALOGS_FAILURE,
  }, ...args);
}

export function refreshMetaCatalogs(...args) {
  return refreshMetaEntities({
    entity: 'catalog',
    loading: acts.REFRESH_META_CATALOGS_LOADING,
    set: acts.SET_META_CATALOGS,
    success: acts.REFRESH_META_CATALOGS_SUCCESS,
    failure: acts.REFRESH_META_CATALOGS_FAILURE,
  }, ...args);
}

export function createMetaCatalog(...args) {
  return createMetaEntity({
    entity: 'catalog',
    loading: acts.CREATE_META_CATALOG_LOADING,
    success: acts.CREATE_META_CATALOG_SUCCESS,
    failure: acts.CREATE_META_CATALOG_FAILURE,
  }, ...args);
}

export function modifyMetaCatalog(...args) {
  return modifyMetaEntity({
    entity: 'catalog',
    loading: acts.MODIFY_META_CATALOG_LOADING,
    set: acts.SET_META_CATALOGS,
    success: acts.MODIFY_META_CATALOG_SUCCESS,
    failure: acts.MODIFY_META_CATALOG_FAILURE,
    getErrorMsg: ({ response }) => {
      if (
        response
        && response.status === 406
        && response.data.message === 'relation_in_progress'
      ) {
        return i18n.t('errors:meta.catalogs.relation_in_progress');
      }

      if (
        response
        && response.status === 406
        && response.data.message === 'marked_for_deletion'
      ) {
        return i18n.t('errors:meta.catalogs.marked_for_deletion');
      }

      return null;
    },
  }, ...args);
}

export function uploadMetaCatalogFile(...args) {
  return uploadMetaEntityFile({
    entity: 'catalog',
    rqs: rqs.UPLOAD_META_CATALOG_FILE,
    loading: acts.UPLOAD_META_CATALOG_FILE_LOADING,
    set: acts.SET_META_CATALOGS,
    setUpdateProgress: acts.SET_UPLOAD_META_CATALOG_FILE_PROGRESS,
    success: acts.UPLOAD_META_CATALOG_FILE_SUCCESS,
    failure: acts.UPLOAD_META_CATALOG_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaCatalogFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_CATALOG_FILE,
    reset: acts.CANCEL_UPLOAD_META_CATALOG_FILE,
  }, ...args);
}

export function deleteMetaCatalogs(...args) {
  return deleteMetaEntities({
    entity: 'catalog',
    loading: acts.DELETE_META_CATALOGS_LOADING,
    success: acts.DELETE_META_CATALOGS_SUCCESS,
    failure: acts.DELETE_META_CATALOGS_FAILURE,
  }, ...args);
}

export function exportMetaCatalogTracks(...args) {
  return exportMetaTracks({
    entity: 'catalog',
    loading: acts.EXPORT_META_CATALOG_TRACKS_LOADING,
    success: acts.EXPORT_META_CATALOG_TRACKS_SUCCESS,
    failure: acts.EXPORT_META_CATALOG_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaCatalogs(...args) {
  return exportMetaDocuments({
    entity: 'catalog',
    loading: acts.EXPORT_META_CATALOGS_LOADING,
    success: acts.EXPORT_META_CATALOGS_SUCCESS,
    failure: acts.EXPORT_META_CATALOGS_FAILURE,
  }, ...args);
}

export function archiveMetaCatalogTracks(...args) {
  return archiveMetaTracks({
    entity: 'catalog',
    loading: acts.ARCHIVE_META_CATALOG_TRACKS_LOADING,
    success: acts.ARCHIVE_META_CATALOG_TRACKS_SUCCESS,
    failure: acts.ARCHIVE_META_CATALOG_TRACKS_FAILURE,
  }, ...args);
}

// =============================
// Albums
// =============================

export function getMetaAlbums(...args) {
  return getMetaEntities({
    entity: 'album',
    loading: acts.GET_META_ALBUMS_LOADING,
    set: acts.SET_META_ALBUMS,
    success: acts.GET_META_ALBUMS_SUCCESS,
    failure: acts.GET_META_ALBUMS_FAILURE,
  }, ...args);
}

export function refreshMetaAlbums(...args) {
  return refreshMetaEntities({
    entity: 'album',
    loading: acts.REFRESH_META_ALBUMS_LOADING,
    set: acts.SET_META_ALBUMS,
    success: acts.REFRESH_META_ALBUMS_SUCCESS,
    failure: acts.REFRESH_META_ALBUMS_FAILURE,
  }, ...args);
}

export function createMetaAlbum(...args) {
  return createMetaEntity({
    entity: 'album',
    loading: acts.CREATE_META_ALBUM_LOADING,
    success: acts.CREATE_META_ALBUM_SUCCESS,
    failure: acts.CREATE_META_ALBUM_FAILURE,
  }, ...args);
}

export function modifyMetaAlbum(...args) {
  return modifyMetaEntity({
    entity: 'album',
    loading: acts.MODIFY_META_ALBUM_LOADING,
    set: acts.SET_META_ALBUMS,
    success: acts.MODIFY_META_ALBUM_SUCCESS,
    failure: acts.MODIFY_META_ALBUM_FAILURE,
    getErrorMsg: ({ response }) => {
      if (
        response
        && response.status === 406
        && response.data.message === 'relation_in_progress'
      ) {
        return i18n.t('errors:meta.albums.relation_in_progress');
      }

      if (
        response
        && response.status === 406
        && response.data.message === 'marked_for_deletion'
      ) {
        return i18n.t('errors:meta.albums.marked_for_deletion');
      }

      return null;
    },
  }, ...args);
}

export function uploadMetaAlbumFile(...args) {
  return uploadMetaEntityFile({
    entity: 'album',
    rqs: rqs.UPLOAD_META_ALBUM_FILE,
    loading: acts.UPLOAD_META_ALBUM_FILE_LOADING,
    set: acts.SET_META_ALBUMS,
    setUpdateProgress: acts.SET_UPLOAD_META_ALBUM_FILE_PROGRESS,
    success: acts.UPLOAD_META_ALBUM_FILE_SUCCESS,
    failure: acts.UPLOAD_META_ALBUM_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaAlbumFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_ALBUM_FILE,
    reset: acts.CANCEL_UPLOAD_META_ALBUM_FILE,
  }, ...args);
}

export function deleteMetaAlbums(...args) {
  return deleteMetaEntities({
    entity: 'album',
    loading: acts.DELETE_META_ALBUMS_LOADING,
    success: acts.DELETE_META_ALBUMS_SUCCESS,
    failure: acts.DELETE_META_ALBUMS_FAILURE,
  }, ...args);
}

export function exportMetaAlbumTracks(...args) {
  return exportMetaTracks({
    entity: 'album',
    loading: acts.EXPORT_META_ALBUM_TRACKS_LOADING,
    success: acts.EXPORT_META_ALBUM_TRACKS_SUCCESS,
    failure: acts.EXPORT_META_ALBUM_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaAlbums(...args) {
  return exportMetaDocuments({
    entity: 'album',
    loading: acts.EXPORT_META_ALBUMS_LOADING,
    success: acts.EXPORT_META_ALBUMS_SUCCESS,
    failure: acts.EXPORT_META_ALBUMS_FAILURE,
  }, ...args);
}

export function archiveMetaAlbumTracks(...args) {
  return archiveMetaTracks({
    entity: 'album',
    loading: acts.ARCHIVE_META_ALBUM_TRACKS_LOADING,
    success: acts.ARCHIVE_META_ALBUM_TRACKS_SUCCESS,
    failure: acts.ARCHIVE_META_ALBUM_TRACKS_FAILURE,
  }, ...args);
}

export function downloadMetaAlbumCopyright(...args) {
  return downloadMetaCopyright({
    entity: 'album',
    loading: acts.DOWNLOAD_META_ALBUM_COPYRIGHT_LOADING,
    success: acts.DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS,
    failure: acts.DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE,
  }, ...args);
}

// =============================
// Playlists
// =============================

export function getMetaPlaylists(...args) {
  return getMetaEntities(
    {
      entity: 'playlist',
      loading: acts.GET_META_PLAYLISTS_LOADING,
      set: acts.SET_META_PLAYLISTS,
      success: acts.GET_META_PLAYLISTS_SUCCESS,
      failure: acts.GET_META_PLAYLISTS_FAILURE,
    },
    ...args,
  );
}

export function refreshMetaPlaylists(...args) {
  return refreshMetaEntities({
    entity: 'playlist',
    loading: acts.REFRESH_META_PLAYLISTS_LOADING,
    set: acts.SET_META_PLAYLISTS,
    success: acts.REFRESH_META_PLAYLISTS_SUCCESS,
    failure: acts.REFRESH_META_PLAYLISTS_FAILURE,
  }, ...args);
}

export function createMetaPlaylist(...args) {
  return createMetaEntity({
    entity: 'playlist',
    loading: acts.CREATE_META_PLAYLIST_LOADING,
    success: acts.CREATE_META_PLAYLIST_SUCCESS,
    failure: acts.CREATE_META_PLAYLIST_FAILURE,
  }, ...args);
}

export function modifyMetaPlaylist(...args) {
  return modifyMetaEntity({
    entity: 'playlist',
    loading: acts.MODIFY_META_PLAYLIST_LOADING,
    set: acts.SET_META_PLAYLISTS,
    success: acts.MODIFY_META_PLAYLIST_SUCCESS,
    failure: acts.MODIFY_META_PLAYLIST_FAILURE,
    getErrorMsg: ({ response }) => {
      if (
        response
        && response.status === 406
        && response.data.message === 'relation_in_progress'
      ) {
        return i18n.t('errors:meta.playlists.relation_in_progress');
      }

      if (
        response
        && response.status === 406
        && response.data.message === 'marked_for_deletion'
      ) {
        return i18n.t('errors:meta.playlists.marked_for_deletion');
      }

      return null;
    },
  }, ...args);
}

export function uploadMetaPlaylistFile(...args) {
  return uploadMetaEntityFile({
    entity: 'playlist',
    rqs: rqs.UPLOAD_META_PLAYLIST_FILE,
    loading: acts.UPLOAD_META_PLAYLIST_FILE_LOADING,
    set: acts.SET_META_PLAYLISTS,
    setUpdateProgress: acts.SET_UPLOAD_META_PLAYLIST_FILE_PROGRESS,
    success: acts.UPLOAD_META_PLAYLIST_FILE_SUCCESS,
    failure: acts.UPLOAD_META_PLAYLIST_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaPlaylistFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_PLAYLIST_FILE,
    reset: acts.CANCEL_UPLOAD_META_PLAYLIST_FILE,
  }, ...args);
}

export function deleteMetaPlaylists(...args) {
  return deleteMetaEntities({
    entity: 'playlist',
    loading: acts.DELETE_META_PLAYLISTS_LOADING,
    success: acts.DELETE_META_PLAYLISTS_SUCCESS,
    failure: acts.DELETE_META_PLAYLISTS_FAILURE,
  }, ...args);
}

export function exportMetaPlaylistTracks(...args) {
  return exportMetaTracks({
    entity: 'playlist',
    loading: acts.EXPORT_META_PLAYLIST_TRACKS_LOADING,
    success: acts.EXPORT_META_PLAYLIST_TRACKS_SUCCESS,
    failure: acts.EXPORT_META_PLAYLIST_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaPlaylists(...args) {
  return exportMetaDocuments({
    entity: 'playlist',
    loading: acts.EXPORT_META_PLAYLISTS_LOADING,
    success: acts.EXPORT_META_PLAYLISTS_SUCCESS,
    failure: acts.EXPORT_META_PLAYLISTS_FAILURE,
  }, ...args);
}

export function archiveMetaPlaylistTracks(...args) {
  return archiveMetaTracks({
    entity: 'playlist',
    loading: acts.ARCHIVE_META_PLAYLIST_TRACKS_LOADING,
    success: acts.ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS,
    failure: acts.ARCHIVE_META_PLAYLIST_TRACKS_FAILURE,
  }, ...args);
}

export function downloadMetaPlaylistCopyright(...args) {
  return downloadMetaCopyright({
    entity: 'playlist',
    loading: acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_LOADING,
    success: acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS,
    failure: acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE,
  }, ...args);
}

// =============================
// Refs Tracks
// =============================

export function addMetaTracksToRef(tracksIds, type, id = null, title = '') {
  return async (dispatch, getState) => {
    let targetRefId = id;

    try {
      if (!targetRefId) {
        const response = await axios({
          method: 'post',
          url: getApiUrl(`meta/${type}s`),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
          },
          data: {
            title: title || i18n.t(`common:entities.new_${type}`),
          },
        });

        targetRefId = response.data.id;
      }

      dispatch({
        type: acts.ADD_META_TRACKS_TO_REF_LOADING,
        payload: {
          id: targetRefId,
        },
      });

      await axios({
        method: 'post',
        url: getApiUrl(`meta/${type}s/${targetRefId}/tracks`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: { ids: tracksIds },
      });

      return dispatch({
        type: acts.ADD_META_TRACKS_TO_REF_SUCCESS,
        payload: {
          id: targetRefId,
        },
      });
    } catch (error) {
      let errorMsg;

      switch (true) {
        case !!id
          && error.response
          && error.response.status === 404:
          errorMsg = i18n.t(`errors:meta.${type}s.not_found`);
          break;

        case error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress':
          errorMsg = i18n.t(`errors:meta.${type}s.relation_in_progress`);
          break;

        case error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion':
          errorMsg = i18n.t(`errors:meta.${type}s.marked_for_deletion`);
          break;

        case error.response
          && error.response.status === 400
          && error.response.data.key === 'validation_error'
          && error.response.data.message.includes('tracks exceeds the limit'):
          errorMsg = i18n.t(`errors:meta.${type}s.too_many_tracks_in_${type}`);
          break;

        // Handle brief specific case here
        case type === 'brief'
          && error.response
          && error.response.status === 400
          && error.response.data.message.includes('not_agent'):
          errorMsg = i18n.t('errors:meta.briefs.owner_not_agent');
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      return dispatch({
        type: acts.ADD_META_TRACKS_TO_REF_FAILURE,
        payload: {
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
          id: targetRefId,
        },
      });
    }
  };
}

export function removeMetaTracksFromRef(tracksIds, type, id = null) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: acts.REMOVE_META_TRACKS_FROM_REF_LOADING,
        payload: { id },
      });

      await axios({
        method: 'delete',
        url: getApiUrl(`meta/${type}s/${id}/tracks`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: { ids: tracksIds },
      });

      return dispatch({
        type: acts.REMOVE_META_TRACKS_FROM_REF_SUCCESS,
        payload: { id },
      });
    } catch (error) {
      let errorMsg;

      switch (true) {
        case !!id
          && error.response
          && error.response.status === 404:
          errorMsg = i18n.t(`errors:meta.${type}s.not_found`);
          break;

        case error.response
          && error.response.status === 406
          && error.response.data.message === 'relation_in_progress':
          errorMsg = i18n.t(`errors:meta.${type}s.relation_in_progress`);
          break;

        case error.response
          && error.response.status === 406
          && error.response.data.message === 'marked_for_deletion':
          errorMsg = i18n.t(`errors:meta.${type}s.marked_for_deletion`);
          break;

        default:
          errorMsg = determineError(error);
          break;
      }

      return dispatch({
        type: acts.REMOVE_META_TRACKS_FROM_REF_FAILURE,
        payload: {
          message: errorMsg,
          reqId: _get(error, 'response.data.reqId'),
          id,
        },
      });
    }
  };
}

// =============================
// Pitchs
// =============================

export function getMetaPitchs(...args) {
  return getMetaEntities(
    {
      entity: 'pitch',
      loading: acts.GET_META_PITCHS_LOADING,
      set: acts.SET_META_PITCHS,
      success: acts.GET_META_PITCHS_SUCCESS,
      failure: acts.GET_META_PITCHS_FAILURE,
    },
    ...args,
  );
}

export function refreshMetaPitchs(...args) {
  return refreshMetaEntities({
    entity: 'pitch',
    loading: acts.REFRESH_META_PITCHS_LOADING,
    set: acts.SET_META_PITCHS,
    success: acts.REFRESH_META_PITCHS_SUCCESS,
    failure: acts.REFRESH_META_PITCHS_FAILURE,
  }, ...args);
}

export function createMetaPitch(...args) {
  return createMetaEntity(
    {
      entity: 'pitch',
      loading: acts.CREATE_META_PITCH_LOADING,
      success: acts.CREATE_META_PITCH_SUCCESS,
      failure: acts.CREATE_META_PITCH_FAILURE,
    },
    ...args,
  );
}

export function modifyMetaPitch(...args) {
  return modifyMetaEntity({
    entity: 'pitch',
    loading: acts.MODIFY_META_PITCH_LOADING,
    set: acts.SET_META_PITCHS,
    success: acts.MODIFY_META_PITCH_SUCCESS,
    failure: acts.MODIFY_META_PITCH_FAILURE,
    getErrorMsg: ({ response }) => {
      if (
        response
        && response.status === 406
        && response.data.message === 'relation_in_progress'
      ) {
        return i18n.t('errors:meta.pitchs.relation_in_progress');
      }

      if (
        response
        && response.status === 406
        && response.data.message === 'marked_for_deletion'
      ) {
        return i18n.t('errors:meta.pitchs.marked_for_deletion');
      }

      return null;
    },
  }, ...args);
}

export function deleteMetaPitchs(...args) {
  return deleteMetaEntities({
    entity: 'pitch',
    loading: acts.DELETE_META_PITCHS_LOADING,
    success: acts.DELETE_META_PITCHS_SUCCESS,
    failure: acts.DELETE_META_PITCHS_FAILURE,
  }, ...args);
}

export function exportMetaPitchs(...args) {
  return exportMetaDocuments({
    entity: 'pitch',
    loading: acts.EXPORT_META_PITCHS_LOADING,
    success: acts.EXPORT_META_PITCHS_SUCCESS,
    failure: acts.EXPORT_META_PITCHS_FAILURE,
  }, ...args);
}

// =============================
// Projects
// =============================

export function getMetaBriefs(...args) {
  return getMetaEntities(
    {
      entity: 'brief',
      loading: acts.GET_META_BRIEFS_LOADING,
      set: acts.SET_META_BRIEFS,
      success: acts.GET_META_BRIEFS_SUCCESS,
      failure: acts.GET_META_BRIEFS_FAILURE,
    },
    ...args,
  );
}

export function refreshMetaBriefs(...args) {
  return refreshMetaEntities({
    entity: 'brief',
    loading: acts.REFRESH_META_BRIEFS_LOADING,
    set: acts.SET_META_BRIEFS,
    success: acts.REFRESH_META_BRIEFS_SUCCESS,
    failure: acts.REFRESH_META_BRIEFS_FAILURE,
  }, ...args);
}

export function createMetaBrief(...args) {
  return createMetaEntity(
    {
      entity: 'brief',
      loading: acts.CREATE_META_BRIEF_LOADING,
      success: acts.CREATE_META_BRIEF_SUCCESS,
      failure: acts.CREATE_META_BRIEF_FAILURE,
    },
    ...args,
  );
}

export function modifyMetaBrief(...args) {
  return modifyMetaEntity({
    entity: 'brief',
    loading: acts.MODIFY_META_BRIEF_LOADING,
    set: acts.SET_META_BRIEFS,
    success: acts.MODIFY_META_BRIEF_SUCCESS,
    failure: acts.MODIFY_META_BRIEF_FAILURE,
    getErrorMsg: ({ response }) => {
      if (
        response
        && response.status === 406
        && response.data.message === 'relation_in_progress'
      ) {
        return i18n.t('errors:meta.briefs.relation_in_progress');
      }

      if (
        response
        && response.status === 406
        && response.data.message === 'marked_for_deletion'
      ) {
        return i18n.t('errors:meta.briefs.marked_for_deletion');
      }

      return null;
    },
  }, ...args);
}

export function uploadMetaBriefFile(...args) {
  return uploadMetaEntityFile({
    entity: 'brief',
    rqs: rqs.UPLOAD_META_BRIEF_FILE,
    loading: acts.UPLOAD_META_BRIEF_FILE_LOADING,
    set: acts.SET_META_BRIEFS,
    setUpdateProgress: acts.SET_UPLOAD_META_BRIEF_FILE_PROGRESS,
    success: acts.UPLOAD_META_BRIEF_FILE_SUCCESS,
    failure: acts.UPLOAD_META_BRIEF_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaBriefFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_BRIEF_FILE,
    reset: acts.CANCEL_UPLOAD_META_BRIEF_FILE,
  }, ...args);
}

export function deleteMetaBriefs(...args) {
  return deleteMetaEntities({
    entity: 'brief',
    loading: acts.DELETE_META_BRIEFS_LOADING,
    success: acts.DELETE_META_BRIEFS_SUCCESS,
    failure: acts.DELETE_META_BRIEFS_FAILURE,
  }, ...args);
}

export function exportMetaBriefTracks(...args) {
  return exportMetaTracks({
    entity: 'brief',
    loading: acts.EXPORT_META_BRIEF_TRACKS_LOADING,
    success: acts.EXPORT_META_BRIEF_TRACKS_SUCCESS,
    failure: acts.EXPORT_META_BRIEF_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaBriefs(...args) {
  return exportMetaDocuments({
    entity: 'brief',
    loading: acts.EXPORT_META_BRIEFS_LOADING,
    success: acts.EXPORT_META_BRIEFS_SUCCESS,
    failure: acts.EXPORT_META_BRIEFS_FAILURE,
  }, ...args);
}

export function archiveMetaBriefTracks(...args) {
  return archiveMetaTracks({
    entity: 'brief',
    loading: acts.ARCHIVE_META_BRIEF_TRACKS_LOADING,
    success: acts.ARCHIVE_META_BRIEF_TRACKS_SUCCESS,
    failure: acts.ARCHIVE_META_BRIEF_TRACKS_FAILURE,
  }, ...args);
}

export function downloadMetaBriefCopyright(...args) {
  return downloadMetaCopyright({
    entity: 'brief',
    loading: acts.DOWNLOAD_META_BRIEF_COPYRIGHT_LOADING,
    success: acts.DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS,
    failure: acts.DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE,
  }, ...args);
}

// =============================
// Tracks
// =============================

export function getMetaTracks(...args) {
  return getMetaEntities({
    entity: 'track',
    loading: acts.GET_META_TRACKS_LOADING,
    set: acts.SET_META_TRACKS,
    success: acts.GET_META_TRACKS_SUCCESS,
    failure: acts.GET_META_TRACKS_FAILURE,
  }, ...args);
}

export function refreshMetaTracks(...args) {
  return refreshMetaEntities({
    entity: 'track',
    loading: acts.REFRESH_META_TRACKS_LOADING,
    set: acts.REFRESH_META_TRACKS,
    success: acts.REFRESH_META_TRACKS_SUCCESS,
    failure: acts.REFRESH_META_TRACKS_FAILURE,
  }, ...args);
}

export function createMetaTrack(...args) {
  return createMetaEntity({
    entity: 'track',
    loading: acts.CREATE_META_TRACK_LOADING,
    success: acts.CREATE_META_TRACK_SUCCESS,
    failure: acts.CREATE_META_TRACK_FAILURE,
  }, ...args);
}

export function modifyMetaTrack(...args) {
  return modifyMetaEntity({
    entity: 'track',
    loading: acts.MODIFY_META_TRACK_LOADING,
    set: acts.SET_META_TRACKS,
    success: acts.MODIFY_META_TRACK_SUCCESS,
    failure: acts.MODIFY_META_TRACK_FAILURE,
    getErrorMsg: ({ response }) => {
      if (
        response
        && response.status === 406
        && response.data.message === 'relation_in_progress'
      ) {
        return i18n.t('errors:meta.tracks.relation_in_progress');
      }

      if (
        response
        && response.status === 406
        && response.data.message === 'marked_for_deletion'
      ) {
        return i18n.t('errors:meta.tracks.marked_for_deletion');
      }

      if (
        response
        && response.status === 400
        && response.data.key === 'validation_error'
        && response.data.message.includes('too_many_tracks')
      ) {
        return i18n.t('errors:meta.tracks.too_many_tracks_in_album');
      }

      return null;
    },
  }, ...args);
}

export function uploadMetaTrackFile(...args) {
  return uploadMetaEntityFile({
    entity: 'track',
    rqs: rqs.UPLOAD_META_TRACK_FILE,
    loading: acts.UPLOAD_META_TRACK_FILE_LOADING,
    set: acts.SET_META_TRACKS,
    setUpdateProgress: acts.SET_UPLOAD_META_TRACK_FILE_PROGRESS,
    success: acts.UPLOAD_META_TRACK_FILE_SUCCESS,
    failure: acts.UPLOAD_META_TRACK_FILE_FAILURE,
  }, ...args);
}

export function cancelMetaTrackFileUpload(...args) {
  return cancelMetaEntityFileUpload({
    rqs: rqs.UPLOAD_META_TRACK_FILE,
    reset: acts.CANCEL_UPLOAD_META_TRACK_FILE,
  }, ...args);
}

export function deleteMetaTracks(...args) {
  return deleteMetaEntities({
    entity: 'track',
    loading: acts.DELETE_META_TRACKS_LOADING,
    success: acts.DELETE_META_TRACKS_SUCCESS,
    failure: acts.DELETE_META_TRACKS_FAILURE,
  }, ...args);
}

export function exportMetaTrack(...args) {
  return exportMetaTracks({
    entity: 'track',
    loading: acts.EXPORT_META_TRACK_LOADING,
    success: acts.EXPORT_META_TRACK_SUCCESS,
    failure: acts.EXPORT_META_TRACK_FAILURE,
  }, ...args);
}

export function archiveMetaTrack(...args) {
  return archiveMetaTracks({
    entity: 'track',
    loading: acts.ARCHIVE_META_TRACK_LOADING,
    success: acts.ARCHIVE_META_TRACK_SUCCESS,
    failure: acts.ARCHIVE_META_TRACK_FAILURE,
  }, ...args);
}

export function downloadMetaTrackAudiofile(...args) {
  return downloadMetaTrack({
    entity: 'track',
    loading: acts.DOWNLOAD_META_TRACK_AUDIOFILE_LOADING,
    success: acts.DOWNLOAD_META_TRACK_AUDIOFILE_SUCCESS,
    failure: acts.DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE,
  }, ...args);
}

export function downloadMetaTrackCopyright(...args) {
  return downloadMetaCopyright({
    entity: 'track',
    loading: acts.DOWNLOAD_META_TRACK_COPYRIGHT_LOADING,
    success: acts.DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS,
    failure: acts.DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE,
  }, ...args);
}

// =============================
// Ingestions
// =============================

export function getMetaIngestions(...args) {
  return getMetaEntities({
    entity: 'ingestion',
    loading: acts.GET_META_INGESTIONS_LOADING,
    set: acts.SET_META_INGESTIONS,
    success: acts.GET_META_INGESTIONS_SUCCESS,
    failure: acts.GET_META_INGESTIONS_FAILURE,
  }, ...args);
}

export function refreshMetaIngestions(...args) {
  return refreshMetaEntities({
    entity: 'ingestion',
    loading: acts.REFRESH_META_INGESTIONS_LOADING,
    set: acts.SET_META_INGESTIONS,
    success: acts.REFRESH_META_INGESTIONS_SUCCESS,
    failure: acts.REFRESH_META_INGESTIONS_FAILURE,
  }, ...args);
}

export function createMetaIngestion(...args) {
  return createMetaEntity({
    entity: 'ingestion',
    loading: acts.CREATE_META_INGESTION_LOADING,
    success: acts.CREATE_META_INGESTION_SUCCESS,
    failure: acts.CREATE_META_INGESTION_FAILURE,
  }, ...args);
}

export function deleteMetaIngestions(...args) {
  return deleteMetaEntities({
    entity: 'ingestion',
    loading: acts.DELETE_META_INGESTIONS_LOADING,
    success: acts.DELETE_META_INGESTIONS_SUCCESS,
    failure: acts.DELETE_META_INGESTIONS_FAILURE,
  }, ...args);
}

export function updateMetaIngestionPostJob(ingestionId) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.UPDATE_META_INGESTION_POST_JOB_LOADING,
    });

    try {
      const res = await axios({
        method: 'get',
        url: getApiUrl(`meta/ingestions/${ingestionId}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.UPDATE_META_INGESTION_POST_JOB,
        payload: res.data,
      });

      // Check side panel
      const { panels } = getState().sidepanel;

      if (
        panels.length
        && panels[0].id === res.data.id
        && panels[0].type === spts.META_INGESTION_PANEL
      ) {
        dispatch(setSidePanelData({
          uuid: panels[0].uuid,
          data: res.data,
        }));
      }

      return dispatch({
        type: acts.UPDATE_META_INGESTION_POST_JOB_SUCCESS,
      });
    } catch (error) {
      return dispatch({
        type: acts.UPDATE_META_INGESTION_POST_JOB_FAILURE,
        payload: {
          message: determineError(error),
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}
