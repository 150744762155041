// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  isLoading: false,
  register: {
    isLoading: false,
  },
  recover: {
    isLoading: false,
  },
  resetpassword: {
    isLoading: false,
  },
};

// =============================
// Reducer
// =============================

export default function auth(state = initialState, action) {
  switch (action.type) {
    /* User session */
    case acts.GET_USER_SESSION_TOKEN_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case acts.GET_USER_SESSION_TOKEN_SUCCESS:
    case acts.GET_USER_SESSION_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    /* Register */
    case acts.REGISTER_USER_LOADING:
      return {
        ...state,
        register: {
          ...state.register,
          isLoading: true,
        },
      };

    case acts.REGISTER_USER_SUCCESS:
    case acts.REGISTER_USER_FAILURE:
      return {
        ...state,
        register: {
          ...state.register,
          isLoading: false,
        },
      };

    /* Recover password */
    case acts.RECOVER_PASSWORD_LOADING:
      return {
        ...state,
        recover: {
          ...state.recover,
          isLoading: true,
        },
      };

    case acts.RECOVER_PASSWORD_SUCCESS:
    case acts.RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        recover: {
          ...state.recover,
          isLoading: false,
        },
      };

    /* Reset password */
    case acts.RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetpassword: {
          ...state.resetpassword,
          isLoading: true,
        },
      };

    case acts.RESET_PASSWORD_SUCCESS:
    case acts.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetpassword: {
          ...state.resetpassword,
          isLoading: false,
        },
      };

    default:
      return state;
  }
}
