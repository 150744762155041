// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const StyledIcon = styled.div.attrs(({ active, as, theme }) => ({
  as,
  fill: active ? theme.colors.primary : '#999999',
}))``;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 1.6rem;
  width: 2.5rem;
`;
