// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { StyledDatePicker } from './styles';

// =============================
// Component
// =============================

const DatePickerInput = ({ t, ...rest }) => (
  <StyledDatePicker
    dropdownClassName="ignore-react-onclickoutside"
    lang={{
      Label: {
        optional: t('components:mewo_input.Label.optional'),
      },
    }}
    {...rest}
  />
);

DatePickerInput.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['components'])(DatePickerInput);
