// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _some from 'lodash/some';
import _sortBy from 'lodash/sortBy';

import { withTranslation } from '../../../config/i18n';

import Input from '../../presentationals/inputs/input';

import * as pth from '../../../helpers/proptypes';
import { getNameWithFallback } from '../../../helpers/doc-names';
import { presentTags } from '../../../helpers/meta-common';
import * as helpers from './helpers';

import {
  PreviewTagsWrapper,
  PreviewTagsTitle,
  TagCategoryWrapper,
  TagCategoryArrow,
  TagCategoryChildrenWrapper,
  TagSubCategoryWrapper,
  TagSubCategoryArrow,
  TagSubCategoryChildrenWrapper,
  TitleWrapper,
  CountWrapper,
  StyledChip,
  TagCategoryHeader,
  TagList,
} from './styles';

// =============================
// Component
// =============================

class MetaTags extends Component {
  static propTypes = {
    statusData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.number,
      }),
    ).isRequired,
    currentTagsTitle: PropTypes.string,
    tags: PropTypes.arrayOf(pth.tagWithCat).isRequired,
    tagCategories: PropTypes.arrayOf(pth.tagCategory).isRequired,
    disabled: PropTypes.bool.isRequired,
    withPreview: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentTagsTitle: null,
    withPreview: true,
  };

  constructor(props) {
    super(props);
    const { statusData, tags, tagCategories } = this.props;

    const opened = {
      tagCategories: {},
      tagSubCategories: {},
    };

    tagCategories.forEach((tagCategory) => {
      opened.tagCategories[tagCategory.id] = false;

      tagCategory.sub_categories.forEach((tagSubCategory) => {
        opened.tagSubCategories[tagSubCategory.id] = false;
      });
    });

    statusData
      .filter(({ status }) => status > 0)
      .forEach(({ id }) => {
        const tag = tags.find(t => t.id === id);

        opened.tagCategories[tag.category_id] = true;
        if (tag.sub_category_id) opened.tagSubCategories[tag.sub_category_id] = true;
      });

    this.state = { opened, search: '' };
  }

  handleSearch = (value) => {
    const {
      tagCategories,
      i18n: { language },
    } = this.props;

    const opened = {
      tagCategories: {},
      tagSubCategories: {},
    };

    tagCategories.forEach((tagCategory) => {
      opened.tagCategories[tagCategory.id] = !value
        ? false
        : helpers.categoryMatches(tagCategory, value, language);

      tagCategory.sub_categories.forEach((tagSubCategory) => {
        opened.tagSubCategories[tagSubCategory.id] = !value
          ? false
          : helpers.subCategoryMatches(tagSubCategory, value, language);
      });
    });

    return this.setState({ search: value, opened });
  };

  handleTagClick = (tag, status) => {
    const { onAdd, onRemove, disabled } = this.props;

    if (disabled) return null;
    if (status === 2) return onRemove(tag.id);
    return onAdd(tag.id);
  };

  renderTag = (tag, color, withSynonyms = true) => {
    const {
      statusData,
      disabled,
      i18n: { language },
    } = this.props;
    const { search } = this.state;

    let name = getNameWithFallback(tag.names, language);

    if (withSynonyms && search && tag.synonyms.length) {
      const matchedSynonym = helpers.matchedSynonym(
        tag.search_synonyms,
        tag.synonyms,
        search,
        language,
      );
      if (matchedSynonym) name = `${name} (${matchedSynonym})`;
    }

    const { status } = statusData.find(({ id }) => id === tag.id) || { status: 0 };

    return (
      <StyledChip
        key={tag.id}
        name={name}
        color={color}
        onClick={() => this.handleTagClick(tag, status)}
        status={status}
        noHover={disabled}
      />
    );
  };

  renderPreviewTags = () => {
    const {
      statusData,
      currentTagsTitle,
      tags,
      withPreview,
      i18n: { language },
    } = this.props;

    if (!withPreview) return null;

    const previewTags = presentTags(
      statusData.filter(({ status }) => status === 2)
        .map(({ id }) => tags.find(tag => tag.id === id))
        .filter(tag => tag.type === 0),
      language,
    );

    if (!previewTags.length) return null;

    return (
      <div>
        {currentTagsTitle && (
          <PreviewTagsTitle>
            {currentTagsTitle}
          </PreviewTagsTitle>
        )}
        <PreviewTagsWrapper>
          {previewTags.map(tag => this.renderTag(tag, tag.color, false))}
        </PreviewTagsWrapper>
      </div>
    );
  };

  getFilteredAndSortedTags = (tags) => {
    const {
      i18n: { language },
    } = this.props;
    const { search } = this.state;

    return _sortBy(tags, tag => getNameWithFallback(tag.names, language))
      .filter(tag => helpers.tagMatches(tag, search, language));
  };

  toggleTagCategory = tagCategoryId => this.setState(state => ({
    ...state,
    opened: {
      ...state.opened,
      tagCategories: {
        ...state.opened.tagCategories,
        [tagCategoryId]: !state.opened.tagCategories[tagCategoryId],
      },
    },
  }));

  toggleTagSubCategory = tagSubCategoryId => this.setState(state => ({
    ...state,
    opened: {
      ...state.opened,
      tagSubCategories: {
        ...state.opened.tagSubCategories,
        [tagSubCategoryId]: !state.opened.tagSubCategories[tagSubCategoryId],
      },
    },
  }));

  renderTagSubCategory = (tagSubCategory, tagCategory) => {
    const {
      statusData,
      tags,
      i18n: { language },
    } = this.props;
    const {
      opened: { tagSubCategories: openedTagSubCategories },
    } = this.state;

    const name = getNameWithFallback(tagSubCategory.names, language);
    const opened = openedTagSubCategories[tagSubCategory.id];

    const count = statusData.filter(({ id, status }) => {
      if (status === 0) return false;
      const tag = tags.find(t => t.id === id);

      return tag.sub_category_id === tagSubCategory.id;
    }).length;

    if (!this.getFilteredAndSortedTags(tagSubCategory.tags).length) return null;

    return (
      <Fragment key={tagSubCategory.id}>
        <TagSubCategoryWrapper
          opened={opened}
          onClick={() => this.toggleTagSubCategory(tagSubCategory.id)}
        >
          <TitleWrapper>
            {name}
            {!!count && <CountWrapper>{count}</CountWrapper>}
          </TitleWrapper>
          <TagSubCategoryArrow opened={opened} />
        </TagSubCategoryWrapper>
        <TagSubCategoryChildrenWrapper opened={opened}>
          {opened && this.getFilteredAndSortedTags(tagSubCategory.tags)
            .map(tag => this.renderTag(tag, tagCategory.color))}
        </TagSubCategoryChildrenWrapper>
      </Fragment>
    );
  };

  renderTagCategory = (tagCategory) => {
    const {
      statusData,
      tags,
      i18n: { language },
    } = this.props;
    const {
      opened: { tagCategories: openedTagCategories },
    } = this.state;

    const name = getNameWithFallback(tagCategory.names, language);
    const opened = openedTagCategories[tagCategory.id];

    const count = statusData.filter(({ id, status }) => {
      if (status === 0) return false;
      const tag = tags.find(t => t.id === id);

      return tag.category_id === tagCategory.id;
    }).length;

    const categoryHasTags = !tagCategory.sub_categories.length
      && this.getFilteredAndSortedTags(tagCategory.tags).length;

    const subCategoriesHasTags = tagCategory.sub_categories.length
      && _some(
        tagCategory.sub_categories.map(
          subCategory => !!this.getFilteredAndSortedTags(subCategory.tags).length,
        ),
      );

    if (!categoryHasTags && !subCategoriesHasTags) return null;

    return (
      <Fragment key={tagCategory.id}>
        <TagCategoryWrapper>
          <TagCategoryHeader onClick={() => this.toggleTagCategory(tagCategory.id)} opened={opened}>
            <TitleWrapper isRoot>
              {name}
              {!!count && <CountWrapper>{count}</CountWrapper>}
            </TitleWrapper>
            <TagCategoryArrow opened={opened} />
          </TagCategoryHeader>
          <TagList opened={opened}>
            {opened && this.getFilteredAndSortedTags(tagCategory.tags)
              .map(tag => this.renderTag(tag, tagCategory.color))}
          </TagList>
          <TagCategoryChildrenWrapper opened={opened}>
            {opened && tagCategory.sub_categories.map(
              tagSubCategory => this.renderTagSubCategory(tagSubCategory, tagCategory),
            )}
          </TagCategoryChildrenWrapper>
        </TagCategoryWrapper>
      </Fragment>
    );
  };

  render() {
    const { tagCategories, t } = this.props;
    const { search } = this.state;

    return (
      <Fragment>
        {this.renderPreviewTags()}
        <Input
          placeholder={t('components:meta_tags.search_placeholder')}
          name="tags_search"
          type="text"
          value={search}
          onChange={this.handleSearch}
        />
        {tagCategories.filter(tag => tag.type === 0).map(tagCategory => this.renderTagCategory(tagCategory))}
      </Fragment>
    );
  }
}

function mapStateToProps({ options }) {
  return {
    tags: options.data.tags_only,
    tagCategories: options.data.tags,
  };
}

export default compose(withTranslation(['components']), connect(mapStateToProps))(MetaTags);
