// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Button, Dropdown, Icons, InputBase } from '@mewo/components';
import { alpha } from '@material-ui/core';

import Input from '../../presentationals/inputs/input';
import Brief from '../../layouts/meta/entities/renderers/icons/Brief';

// =============================
// Styles
// =============================

export const StyledDropdown = styled(Dropdown).attrs(({ placement, theme }) => ({
  contentStyles: css`
    max-height: 20rem;
  `,
  dropdownStyles: css`
    background: #fff;
    box-shadow: 0 0.4rem 1.5rem 0 ${theme.colors.boxShadow};
    color: #191919;
    font-size: 1.2rem;
    font-weight: ${theme.fontWeight.semibold};
    width: 50rem;
  `,
  dropdownClassName: 'ignore-react-onclickoutside',
  closeOnClick: false,
  placement,
  withoutStyles: true,
}))``;

export const Header = styled.div`
  width: 100%;
`;

export const Option = styled.div`
  align-items: center;
  display: flex;
  height: 2.8rem;
  justify-content: space-between;
  padding: 0 2.4rem;
  width: 100%;
`;

export const OptionButton = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: #191919;
  cursor: pointer;
  transition: opacity ${({ theme }) => theme.transition.medium};
  width: 42rem;

  &:hover {
    opacity: 0.7;
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.7;
  `};
`;

export const OptionBriefIcon = styled(Brief).attrs({
  width: '1.5rem',
})`
  margin-right: 0.8rem;
  position: relative;
  top: 0.3rem;
`;

export const OptionAdded = styled(Icons.Check).attrs(({ theme }) => ({
  fill: theme.colors.type.success,
  width: '1rem',
}))`
  margin-right: 1rem;
`;

export const GoToRef = styled(Icons.ExternalLink).attrs({
  fill: '#191919',
  width: '1.3rem',
})``;

export const FilterInputContainer = styled.div`
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
  margin: 0.8rem 0;
  padding: 0.4rem 0.8rem;
  width: 100%;
`;

export const FilterInput = styled(InputBase).attrs({
  showPlaceholder: true,
})`
  background-color: ${alpha('#191919', 0.1)};
  color: #191919;
  padding: 1.2rem 1.6rem;
`;

export const MiscMessage = styled.div`
  color: #7f7f7f;
  padding: 0 2.4rem;
  margin: 1rem 0;
  line-height: 1.3;
  text-align: center;
`;

export const PlusIcon = styled(Icons.PlusOutline).attrs({
  width: '1.4rem',
  fill: '#191919',
})`
  margin-right: 0.8rem;
`;

export const CreatePlaylistWrapper = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => alpha(theme.colors.text, 0.2)};
  display: flex;
  padding: 1.2rem 2.4rem;
  width: 100%;
`;

export const CreatePlaylistToggler = styled(CreatePlaylistWrapper)`
  align-items: center;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `};

  ${({ disabled }) => disabled
    && css`
      opacity: 0.7;
      pointer-events: none;
    `};
`;

export const CreatePlaylistForm = styled(CreatePlaylistWrapper)`
  align-items: flex-end;
`;

export const NewPlaylistInput = styled(Input).attrs({
  containerClassName: 'containerClassName',
  variant: 'background',
})`
  margin-right: 0.8rem;

  & .containerClassName {
    min-height: 4rem;
  }
`;

export const AddToPlaylistBtn = styled(Button).attrs(() => ({
  color: 'primary',
  fillVariant: 'plain',
  size: 'small',
}))`
  align-items: center;
  height: 3rem;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin-left: auto;
  padding: 0 0.8rem;
  width: 3rem;
`;

export const AddToPlaylistBtnPlusIcon = styled(Icons.Plus).attrs(({ theme }) => ({
  fill: theme.colors.primaryText,
}))``;
