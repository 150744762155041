// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { closePremiumModal } from '../../../store/actions/PremiumActions';
import { sendUserRequest } from '../../../store/actions/UserActions';

// Components
import PremiumModal from '../../presentationals/premiumModal';

// =============================
// Component
// =============================

function mapStateToProps({ premium }) {
  return {
    show: premium.show,
    message: premium.message,
    context: premium.context,
    description: premium.description,
  };
}

export default connect(mapStateToProps, { closePremiumModal, sendUserRequest })(PremiumModal);
