// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { triggerAutosaveCheck } from '../../../store/actions/SidePanelActions';

// Components
import ContactsInput from '../../presentationals/contactsInput';

// =============================
// Component
// =============================

export default connect(null, {
  triggerPanelAutosaveCheck: triggerAutosaveCheck,
})(ContactsInput);
