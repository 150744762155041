// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import { Button, Div, DropdownList, Icons, Tooltip } from '@mewo/components';

import { Tab, TabPanel, Tabs } from '../../other/tabs';

// =============================
// Styles
// =============================

export const LeftColumn = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: top;
  padding: 2rem 2rem 0;
  width: 20rem;
  position: relative;
`;

export const RightColumn = styled(Tabs)`
  background-color: ${({ theme }) => theme.colors.background};
  display: inline-block;
  height: 100%;
  vertical-align: top;
  position: relative;
  width: 78.4rem;
`;

export const DocumentBody = styled.div.attrs({
  id: 'panel-body',
})`
  height: calc(100% - 8.5rem);
  overflow: auto;
  padding: 0 3.2rem 3.2rem;
  width: 100%;
  ${({ theme }) => theme.helpers.hideScrollbar};
`;

export const DocumentStatus = styled.div`
  display: flex;
  font-size: 1.2rem;
  font-style: italic;
  height: 2.5rem;
  justify-content: space-between;
  line-height: 2.5rem;
  margin-top: 1rem;
  padding: 0 3.2rem;
`;

export const DocumentStatusInnerWrapper = styled(Div)`
  width: calc(100% / 3);

  ${({ textAlign }) => textAlign
    && css`
      text-align: ${textAlign};
    `};
`;

export const DocumentStatusAction = styled.div`
  cursor: pointer;
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }
`;

export const DuplicateIcon = styled(Icons.Duplicate).attrs(() => ({
  width: '1.4rem',
}))`
  margin-right: 1rem;
  position: relative;
  top: 0.3rem;
`;

export const TrashIcon = styled(Icons.TrashCan).attrs(() => ({
  width: '1.4rem',
}))`
  margin-left: 1rem;
  position: relative;
  top: 0.3rem;
`;

export const TabLinksWrapper = styled.div`
  align-items: center;
  border-bottom: 0.1rem solid #e5e5e5;
  display: flex;
  height: 5rem;
  margin: 0 3.2rem;
`;

export const TabLink = styled(Tab).attrs({
  selectedClassName: 'activeTabLink',
})`
  align-items: center;
  border-bottom: 0.2rem solid transparent;
  /** Used to keep link vertically aligned. */
  border-top: 0.2rem solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0 1rem;
  height: 100%;
  transition: ${({ theme }) => theme.transition.fast};

  &.activeTabLink {
    border-bottom-color: ${({ theme }) => theme.colors.primary};
    color: #191919;
    font-size: 1.4rem;
  }
`;

export const TabElement = styled(TabPanel)`
  position: relative;
`;

export const SectionTitle = styled.div`
  color: #191919;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 1.8rem;
  max-width: 60rem;
  padding-top: 3.2rem;
  line-height: 1.4;
`;

export const SectionSubTitle = styled.div`
  color: #191919;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 3.2rem;
  max-width: 60rem;
  padding-top: 3.2rem;
  line-height: 1.4;
`;

export const SectionNotice = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.5rem;
  margin-bottom: 3.2rem;
`;

export const SectionBox = styled(Div)`
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 3.2rem;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }
`;

export const GenericCover = styled.div`
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.8rem;
  color: #fff;
  display: inline-flex;
  height: 16rem;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-size: 6rem;
  width: 16rem;

  ${({ color }) => color
    && css`
      background-color: ${color};
    `};

  ${({ placeholder, cover }) => placeholder
    && !cover
    && css`
      background-image: url(${placeholder});
    `};

  ${({ cover }) => cover
    && css`
      background-image: url(${cover});
    `};
`;

export const DocumentName = styled.div`
  color: #191919;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: 1.5rem;
  line-height: 1.2;
`;

export const DocumentType = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  line-height: 1.2;
`;

export const DocumentCreatedAt = styled.div`
  font-size: 1.2rem;
  margin-bottom: 2.4rem;
  margin-top: 2rem;
  line-height: 1.2;
`;

export const ShareLink = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }

  ${({ disableMargin }) => !disableMargin && css`
    margin-bottom: 2.4rem;
  `};
`;

export const GenericSmallTitle = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

export const GenericText = styled.div`
  color: ${({ theme, isLink }) => (isLink ? theme.colors.primary : '#191919')};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.normal};

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

export const GenericUnderlinedItem = styled.div`
  display: inline-block;
  text-decoration: underline;
`;

export const InputLabelHelper = styled(Div)`
  color: ${({ theme }) => alpha(theme.colors.text, 0.8)};
  font-size: inherit;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 0.6rem;
  transform: scale(0.8);
  transform-origin: left;

  ${({ isError, theme }) => isError
    && css`
      color: ${theme.colors.type.error};
    `};
`;

export const MiscHelper = styled(Div)`
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.4;

  ${({ isError, theme }) => isError
    && css`
      color: ${theme.colors.type.error};
    `};
`;

export const InnerMiscHelper = styled(Div)`
  margin-bottom: 1rem;
`;

export const FileHelper = styled(Div)`
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.4;
`;

export const GenericAction = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }

  ${({ disableMargin }) => !disableMargin && css`
    margin-bottom: 2.4rem;
  `};

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.7;
  `};
`;

export const GenericOwnership = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 2.4rem;
  }
`;

export const GenericOwnershipCount = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 1rem;
  color: #fff;
  display: inline-block;
  margin-right: 1rem;
  padding: 1rem;
`;

export const GenericSearchLink = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 1.2rem;
`;

export const GenericSearchIcon = styled(Icons.Search).attrs({
  width: '1.5rem',
})`
  margin-right: 1rem;
`;

export const RelationsWrapper = styled.div`
  width: 100%;
  left: 0;
  bottom: 3.2rem;
  padding: 0 2rem;
  position: absolute;
`;

export const RelationTitleWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const RelationJobTooltip = styled(Tooltip).attrs(() => ({
  interactive: true,
  placement: 'top-start',
}))`
  flex-shrink: 0;
  margin-left: 1.2rem;
`;

export const GenericLoaderWrapper = styled(Div)`
  text-align: center;
`;

export const LineLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
`;

export const LineLoader = styled.div`
  background-color: ${({ theme }) => alpha(theme.colors.text, 0.4)};
  height: 0.4rem;
  width: 100%;
  position: relative;
`;

export const LineInnerLoader = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  width: ${({ progress }) => `${progress || 0}%`};
  left: 0;
  top: 0;
  height: 100%;
`;

export const InputItemBox = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const DisplayArtistInputs = styled.div`
  display: flex;
  width: 85%;

  ${({ withActions }) => !withActions && css`
    width: 100%;
  `};

  ${({ withAliases }) => withAliases && css`
    > *:first-child {
      margin-right: 1.2rem;
      width: calc(60% - 1.2rem);
    }

    > *:last-child {
      margin-left: 1.2rem;
      width: calc(40% - 1.2rem);
    }
  `};
`;

export const OwnershipEntityInputs = styled.div`
  display: flex;
  width: 85%;

  ${({ withActions }) => !withActions && css`
    width: 100%;
  `};
`;

export const SaveItemButton = styled(Button).attrs({
  size: 'small',
})`
  margin-left: 2rem;
  position: relative;

  ${({ withoutTopSpacing }) => !withoutTopSpacing && css`
    top: 1rem;
  `};
`;

export const DeleteItemButton = styled(Button).attrs({
  color: 'error',
  fillVariant: 'ghost',
  size: 'small',
})`
  margin-left: 2rem;
  position: relative;

  ${({ withoutTopSpacing }) => !withoutTopSpacing && css`
    top: 1rem;
  `};
`;

export const ItemAsLink = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  width: 100%;

  ${({ onClick }) => onClick
    && css`
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        opacity: 0.7;
      }
    `};
`;

export const DeleteItemIcon = styled(Icons.TrashCan).attrs(({ theme }) => ({
  fill: theme.colors.type.error,
  width: '2rem',
}))`
  position: relative;
  top: 1.2rem;
`;

export const AddItemBtn = styled(Button).attrs(() => ({
  color: 'primary',
  size: 'small',
}))``;

export const ExportActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 1.2rem;

  ${({ disabled }) => disabled
    && css`
      opacity: 0.7;
      pointer-events: none;
    `};
`;

export const ExportActionIcon = styled(Icons.Download).attrs({
  width: '1.8rem',
})`
  margin-left: 1rem;
`;

export const DownloadActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 1.2rem;
`;

export const DownloadActionIcon = styled(Icons.Download).attrs({
  width: '1.8rem',
})`
  margin-right: 1rem;
`;

export const AddToRefActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 1.2rem;
`;

export const AddToRefActionIcon = styled(Icons.Plus).attrs({
  width: '1.6rem',
})`
  margin-right: 1rem;
`;

export const StyledDropdown = styled(DropdownList).attrs(() => ({
  placement: 'bottom-start',
  contentStyles: css`
    width: 40rem;
  `,
  optionsContainerStyles: css`
    max-height: 20rem;
  `,
  dropdownClassName: 'ignore-react-onclickoutside',
  withoutStyles: true,
}))``;
