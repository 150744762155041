// =============================
// Helpers
// =============================

// Put links to external documentation here so that we can change platform easily if necessary.
const docs = {
  website: 'https://docs.mewo.io',
  agent_provider_system: 'https://docs.mewo.io/category/12-distribution',
  collaborator_system: 'https://docs.mewo.io/category/14-collaborative-work',
  meta: 'https://docs.mewo.io/category/13-mewos-catalog-manager-meta',
  meta_ingestion: 'https://docs.mewo.io/article/6-bulk-import',
  modo_legal_content_system: 'https://docs.mewo.io/article/38-how-to-fill-my-legal-content',
  modo_suggested_albums_system:
    'https://docs.mewo.io/article/39-how-to-suggest-albums-to-my-customers',
  modo_suggested_playlists_system:
    'https://docs.mewo.io/article/40-how-to-suggest-playlists-to-my-customers',
  modo_website_url_system: 'https://docs.mewo.io/article/42-how-do-i-setup-my-own-domain-name',
  modo_users_import_system: 'https://docs.mewo.io/article/41-how-do-i-import-multiple-customers',
};

export default docs;
