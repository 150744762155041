// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Chip, Div, Flex, Icons } from '@mewo/components';

import ModuleLinks from '../../../other/modoModule/modules/icons/ModuleLinks';

import { InputWrapper } from '../../global.styles';

// =============================
// Styles
// =============================

export const Inline = styled(Div)`
  display: inline-block;
  vertical-align: top;
`;

export const CommonBox = styled(Div)`
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
`;

export const SingleStatBox = styled(CommonBox)`
  height: 11rem;
  width: 20rem;
`;

export const StatTitle = styled(Div)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const StatNumberValue = styled(Div)`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  width: 100%;
`;

export const UserChipsWrapper = styled(Div)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: 0.5rem 0 1rem;
  padding: 1rem;
  max-height: 11.6rem;
  overflow: auto;
`;

export const UserChip = styled(Chip).attrs({
  color: '#000',
  size: 'small',
})``;

export const GlobalLinkWrapper = styled.div`
  display: inline-flex;
  border: 1px dashed darkgrey;
  border-radius: 5px;
  padding: 10px;
  line-height: 1.8rem;
`;

export const InvitationWrapper = styled(Flex).attrs({
  align: 'center',
})`
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

export const InvitationInnerWrapper = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  margin-left: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 7.3rem;
  grid-gap: 1rem;
  width: 100%;
`;

export const InvitationsTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 1.5rem;
  color: #000;
`;

export const InvitationUser = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const InvitationCanDownloadIcon = styled(Icons.Download).attrs(({ theme }) => ({
  width: '1.7rem',
  fill: theme.colors.type.success,
}))``;

export const InvitationRights = styled.div``;

export const InvitationDate = styled.p`
  font-style: italic;
`;

export const InvitationActions = styled.div`
  position: relative;
`;

export const InvitationLink = styled.div`
  border: 0.1rem dashed #b2b2b2;
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 1rem;
  margin-top: 1rem;
`;

export const InvitationLinkWrapper = styled.div`
  display: inline-flex;
`;

export const CopyInvitationLinkButton = styled(ModuleLinks).attrs({
  width: '1.9rem',
  fill: '#000',
})`
  cursor: pointer;

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const EditInvitationButton = styled(Icons.Edit).attrs({
  width: '1.7rem',
  fill: '#000',
})`
  margin-left: 1rem;

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.7;
  `};

`;

export const DeleteInvitationButton = styled(Icons.TrashCan).attrs({
  width: '1.7rem',
  fill: '#000',
})`
  margin-left: 1rem;

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.type.error};
    }
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.7;
  `};
`;

export const UserModal = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`;

export const UserForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
`;

export const UserInputWrapper = styled(InputWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
