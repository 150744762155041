// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import _get from 'lodash/get';

// Components
import { withTranslation } from '../../../../../config/i18n';

// Components
import MewoInput from '../../../../presentationals/mewoInput';
import ApiSelectInput from '../../../../containers/inputs/apiSelectInput';
import SelectInput from '../../../../presentationals/inputs/selectInput';

import { required } from '../../../../../validators';

// Helpers
import * as pth from '../../../../../helpers/proptypes';
import { getDocName, getSearchName } from '../../../../../helpers/doc-names';

// Styles
import { DeleteItemButton, DisplayArtistInputs } from '../../common.styles';

// =============================
// Component
// =============================

class MetaDisplayArtists extends Component {
  static propTypes = {
    artistLabel: PropTypes.string,
    documentRights: PropTypes.shape({
      canWrite: PropTypes.bool.isRequired,
      isOwner: PropTypes.bool.isRequired,
      providerId: PropTypes.string,
    }).isRequired,
    entity: PropTypes.oneOf(['track', 'album']).isRequired,
    fieldName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.object.isRequired,
    formDisabled: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formValues: PropTypes.object.isRequired,
    getASIAction: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    panelData: PropTypes.oneOfType([pth.track, pth.album]).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    artistLabel: '',
  };

  render() {
    const {
      artistLabel,
      documentRights,
      entity,
      fieldName,
      fields,
      formDisabled,
      formValues,
      getASIAction,
      index,
      name,
      panelData,
      t,
    } = this.props;

    return (
      <Fragment>
        <DisplayArtistInputs
          withAliases={
            !!_get(formValues, `${fieldName}.artist.value`)
            && _get(formValues, `${fieldName}.artist.value`) === _get(panelData, `${fieldName}.artist.id`)
            && !!_get(panelData, `${fieldName}.artist.aliases`, []).length
          }
          withActions={documentRights.canWrite}
        >
          <Field
            name={`${fieldName}.artist`}
            component={MewoInput}
            use={ApiSelectInput}
            type="text"
            label={artistLabel || t(`pages:meta.${entity}s.panel.${name}.artist`)}
            entity="meta/artists"
            getEntityLabel={getDocName('artist')}
            getSearchEntityLabel={getSearchName('artist')}
            onTheFlyEnabled={documentRights.isOwner}
            onTheFlyField="full_name"
            listAdditionalQuery={documentRights.providerId
              ? `&provider_id=${documentRights.providerId}`
              : ''}
            itemAction={getASIAction(`${fieldName}.artist`, 'artist')}
            fullWidth
            tooltipMessage={t(`pages:meta.${entity}s.panel.${name}.artist_tooltip`)}
            validate={required}
            context="autosave"
            disabled={formDisabled}
          />
          {!!_get(formValues, `${fieldName}.artist.value`)
          && _get(formValues, `${fieldName}.artist.value`) === _get(panelData, `${fieldName}.artist.id`)
          && !!_get(panelData, `${fieldName}.artist.aliases`, []).length && (
            <Field
              name={`${fieldName}.alias`}
              component={MewoInput}
              use={SelectInput}
              label={t(`pages:meta.${entity}s.panel.${name}.alias`)}
              options={_get(panelData, `${fieldName}.artist.aliases`, []).map(alias => ({
                value: alias,
                label: alias,
              }))}
              renderOptionsVariant="classic"
              renderValuesVariant="text"
              context="autosave"
              disabled={formDisabled}
            />
          )}
        </DisplayArtistInputs>
        {documentRights.canWrite && (
          <DeleteItemButton
            onClick={() => fields.remove(index)}
            disabled={formDisabled}
          >
            {t('common:form.remove')}
          </DeleteItemButton>
        )}
      </Fragment>
    );
  }
}

export default withTranslation(['common', 'pages'])(MetaDisplayArtists);
