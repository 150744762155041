// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { Icons } from '@mewo/components';
import Fade from '@material-ui/core/Fade';

import { StyledModal, StyledBackdrop, InnerFade, CloseWHitBox, Content } from './styles';

// =============================
// Component
// =============================

const Modal = ({ children, open, onClose, ...mui }) => (
  <StyledModal BackdropComponent={StyledBackdrop} onClose={onClose} open={open} {...mui}>
    <Fade in={open} timeout={200}>
      <InnerFade>
        <CloseWHitBox onClick={onClose}>
          <Icons.Close onClick={onClose} />
        </CloseWHitBox>
        {open && <Content>{children}</Content>}
      </InnerFade>
    </Fade>
  </StyledModal>
);

Modal.propTypes = {
  /** Content */
  children: PropTypes.node,
  /** modal is open */
  open: PropTypes.bool.isRequired,
  /** onClose handler */
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  children: null,
};

export default Modal;
