// eslint-disable-next-line import/prefer-default-export
export const chipColors = {
  tenant: '#8fbda7',
  artist: '#e2703a',
  catalog: '#aaaaaa',
  publisher: '#564a4a',
  label: '#21094e',
  playlist: '#ca596f',
  album: '#845460',
  // Album
  album_type: '#38ada9',
  // Artist
  artist_type: {
    band: '#f1b279',
    individual: '#6a89cc',
  },
  // Pitch
  pitch_entity_type: {
    album: '#10ac84',
    playlist: '#8395a7',
  },
  // Track
  music_type: {
    core: '#f1c40f',
    production: '#8e44ad',
    other: '#1ab7ea',
  },
  grouping: '#f0932b',
  lyrics: '#9b59b6',
  stems: '#34495e',
  bpm: '#6d214f',
  year: '#f39c12',
  duration: '#1abc9c',
  tempo: '#82589f',
  tonality_key: '#58b19f',
  version: '#2c3a47',
  ref_id: '#afb9c8',
  tags_not: '#7f7f7f',
  // Ingestion
  ingestion_type: {
    file: '#f1b279',
    audio: '#6a89cc',
  },
  // AI
  track_id: '#8e9775',
  search_id: '#4a503d',
};
