/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import { Fade } from '@material-ui/core';
import styled from 'styled-components';

// =============================
// Styled
// =============================

export const StyledFade = styled(Fade)``;
