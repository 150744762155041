// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Customisations = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 0.43 0.723"
    {...rest}
  >
    <path
      d="M0.068 0.491l0.294 -0.485c0.003,-0.006 0.01,-0.008 0.015,-0.004 0.015,0.009 0.032,0.018 0.047,0.027 0.006,0.003 0.008,0.01 0.005,0.015l-0.261 0.474c0.013,0.015 0.021,0.034 0.021,0.058 0,0.026 -0.012,0.046 -0.024,0.065 -0.011,0.018 -0.023,0.037 -0.017,0.059l0.226 0c0.007,0 0.012,0.005 0.012,0.011 0,0.006 -0.005,0.012 -0.012,0.012l-0.234 0 0 0 0 0 -0.001 0c-0.065,-0.001 -0.108,-0.038 -0.128,-0.083 -0.006,-0.016 -0.01,-0.032 -0.011,-0.048 -0.001,-0.017 0.002,-0.033 0.007,-0.047 0.01,-0.026 0.031,-0.047 0.061,-0.054zm0.082 0.012l0.253 -0.46 -0.028 -0.016 -0.278 0.461c0.018,0 0.037,0.005 0.053,0.015zm-0.054 0.007c-0.036,0 -0.058,0.019 -0.068,0.043 -0.004,0.012 -0.006,0.024 -0.005,0.038 0,0.013 0.004,0.027 0.009,0.04 0.015,0.034 0.046,0.063 0.092,0.068 -0.005,-0.028 0.008,-0.049 0.022,-0.07 0.01,-0.016 0.02,-0.033 0.02,-0.053 0,-0.021 -0.008,-0.038 -0.021,-0.049 -0.014,-0.011 -0.032,-0.017 -0.049,-0.017z"
      fillRule="nonzero"
    />
  </Svg>
));

Customisations.displayName = 'Customisations';

Customisations.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Customisations.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '1rem',
};

export default withTheme(Customisations);
