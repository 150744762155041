// =============================
// Imports
// =============================

import { DropdownList } from '@mewo/components';
import styled, { css } from 'styled-components';

import Link from '../link';

// =============================
// Styles
// =============================

export const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  margin: 0 2.4rem;
`;

export const MetaLinksWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const RightBlock = styled.div`
  display: flex;
  height: 100%;
  margin-left: auto;
`;

export const StyledDropdown = styled(DropdownList).attrs({
  contentStyles: css`
    max-height: none;
  `,
  dropdownStyles: css`
    background: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 0.4rem 1.5rem 0 rgba(19, 93, 125, 0.07);
    padding: 0.8rem 0;
    width: 20rem;
  `,
  withoutStyles: true,
})`
  height: 100%;
  align-items: center;
  border-bottom: 0.2rem solid transparent;
  /** Used to keep link vertically aligned. */
  border-top: 0.2rem solid transparent;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 0 1rem;
  user-select: none;
  height: 100%;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ theme, active }) => active
    && css`
      border-bottom-color: ${theme.colors.primary};
      color: ${theme.colors.primary};
      font-size: 1.3rem;
    `};
`;

export const RightsHoldersToggler = styled.div`
  align-items: center;
  display: flex;
`;

export const HeaderLink = styled(Link)`
  align-items: center;
  border-bottom: 0.2rem solid transparent;
  /** Used to keep link vertically aligned. */
  border-top: 0.2rem solid transparent;
  display: flex;
  height: 100%;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  flex-shrink: 0;
  justify-content: center;
  margin: 0 1rem;
  user-select: none;

  ${({ theme, active }) => active
    && css`
      border-bottom-color: ${theme.colors.primary};
      color: ${theme.colors.primary};
      font-size: 1.3rem;
    `};
`;

export const RightsHoldersLink = styled(Link)`
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  flex-shrink: 0;
  user-select: none;
  padding: 1rem;

  ${({ theme, active }) => active
    && css`
      color: ${theme.colors.primary};
    `};
`;

export const LinkCounter = styled.div`
  align-items: center;
  border-radius: 2rem;
  background-color: #b2b2b2;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  margin-left: 0.5rem;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 2rem;
  justify-content: center;
  padding: 0 0.8rem;

  ${({ theme, active }) => active
    && css`
      background-color: ${theme.colors.primary};
    `};
`;
