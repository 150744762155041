// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Users = memo(({ fill, onClick, theme, title, ...rest }) => (
  <Svg
    fill={fill || '#999999'}
    onClick={onClick}
    title={title}
    viewBox="0 0 32 32"
    {...rest}
  >
    <path d="M15.649,12.261C16.172,11.649,16.5,10.866,16.5,10c0-1.93-1.57-3.5-3.5-3.5S9.5,8.07,9.5,10  c0,0.866,0.328,1.649,0.852,2.261C8.904,13.166,8,14.761,8,16.497v0.284c0,0.179,0.094,0.345,0.249,0.435  C9.698,18.057,11.341,18.5,13,18.5s3.302-0.443,4.75-1.285c0.154-0.089,0.249-0.253,0.249-0.431L18,16.499  C18,14.761,17.098,13.166,15.649,12.261z M13.001,7.5c1.378,0,2.5,1.122,2.5,2.5c0,1.377-1.121,2.498-2.5,2.498  s-2.5-1.121-2.5-2.498C10.501,8.622,11.623,7.5,13.001,7.5z M9.001,16.49c0.003-1.491,0.845-2.842,2.158-3.528  c0.537,0.334,1.164,0.536,1.841,0.536s1.305-0.202,1.841-0.536c1.313,0.685,2.154,2.036,2.158,3.527  C14.532,17.813,11.469,17.813,9.001,16.49z" />
    <path d="M26,25.293l-5.949-5.949C21.568,17.66,22.5,15.439,22.5,13c0-5.238-4.262-9.5-9.5-9.5c-5.238,0-9.5,4.262-9.5,9.5  c0,5.238,4.262,9.5,9.5,9.5c2.439,0,4.66-0.932,6.344-2.449L25.293,26c0.195,0.195,0.512,0.195,0.707,0S26.195,25.488,26,25.293z   M13,21.5c-4.687,0-8.5-3.813-8.5-8.5c0-4.687,3.813-8.5,8.5-8.5c4.688,0,8.5,3.813,8.5,8.5C21.5,17.688,17.688,21.5,13,21.5z" />
  </Svg>
));

Users.displayName = 'Users';

Users.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Users.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '2rem',
};

export default withTheme(Users);
