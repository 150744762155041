/* eslint-disable react/no-did-update-set-state */
// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// Config
import { withTranslation } from '../../../../config/i18n';
import { languagesNames } from '../../../../config/locales';
import entityConfig from '../../meta/ingestions/config';

// Actions
import { panelActions, triggerAutosaveCheck } from '../../../../store/actions/SidePanelActions';

// Constants
import { META_INGESTION_PANEL } from '../../../../store/constants/SidePanelTypes';
import * as spts from '../../../../store/constants/SidePanelTypes';

// Components
import BaseSidePanel from '../../../faac/panels/base';
import DocumentStatus from '../documentStatus';
import MewoInput from '../../../presentationals/mewoInput';
import SwitchInput from '../../../presentationals/inputs/switchInput';
import SelectInput from '../../../presentationals/inputs/selectInput';
import ApiSelectInput from '../../../containers/inputs/apiSelectInput';
import MetaUploader from '../../../containers/metaUploader';
import TagsMatching from './tagsMatching';
import VersionsMatching from './versionsMatching';
import IngestionImporter from './ingestionImporter';
import CopyLink from '../copyLink';

import validator from '../../../../validators/meta/ingestion';

// Helpers
import * as pth from '../../../../helpers/proptypes';
import { getApiUrl, getSiteOrigin } from '../../../../helpers/misc';
import { getDocName, getSearchName } from '../../../../helpers/doc-names';
import { getApiSelectInputData } from '../../../../helpers/meta-common';
import { formatDateString } from '../../../../helpers/dates';
import { buildExternalLinkTextNode } from '../../../../helpers/locales';
import docs from '../../../../helpers/docs';

// Styles
import {
  SectionBox,
  MiscHelper,
} from '../common.styles';
import {
  Wrapper,
  DocumentHeader,
  DocumentInnerHeader,
  DocumentName,
  DocumentCreatedAt,
  DocumentInformations,
  StepsHeader,
  StepTitle,
  DocumentBody,
  StepWrapper,
  StepNotice,
  GenericText,
  StyledForm,
  StepButtonWrapper,
  PrevStepButton,
  NextStepButton,
  BackToBeginningButton,
  FileName,
  FileNameContainer,
  UploadStatus,
  FilePathWrapper,
  FilePathPreview,
  TypeSelectionWrapper,
  TypeChoiceWrapper,
  TypeChoiceText,
  AudiofileIcon,
  MetadataIcon,
} from './styles';
import { InputWrapper } from '../../global.styles';

// =============================
// Component
// =============================

const entity = 'ingestion';

class MetaIngestion extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      data: pth.ingestion,
      isModifying: PropTypes.bool.isRequired,
      'isModifying-launch': PropTypes.bool,
      'isModifying-tags': PropTypes.bool,
      'isModifying-versions': PropTypes.bool,
      isUploading: PropTypes.bool.isRequired,
      uploadProgress: PropTypes.number.isRequired,
      isDeleting: PropTypes.bool.isRequired,
    }).isRequired,
    localPreferences: pth.localPreferences.isRequired,
    t: PropTypes.func.isRequired,
    triggerPanelAutosaveCheck: PropTypes.func.isRequired,
  };

  state = {
    // Steps can be:
    // - set_type
    // Then for ingestion_type "audio":
    // - upload_audiofiles_settings
    // - upload_audiofiles
    // - pre_success
    // - success
    // Then for ingestion_type "file":
    // - upload_settings
    // - upload
    // - tags_matching
    // - versions_matching
    // - pre_success
    // - success
    currentStep: null,
    filePathExample: null,
    fileWithSubPathExample: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { panel: { data: prevData } } = prevProps;
    const { panel: { data: nextData } } = this.props;

    const { currentStep: prevStep } = prevState;
    const {
      currentStep: nextStep,
      filePathExample,
      fileWithSubPathExample,
    } = this.state;

    // When panel data is fetched
    if (!prevData && nextData) {
      if (!nextData.ingestion_type) {
        this.setState({ currentStep: 'set_type' });
      } else if (nextData.ingestion_type === 'audio') {
        switch (true) {
          case nextData.status === 0 && nextData.catalog:
            this.setState({ currentStep: 'upload_audiofiles' });
            break;

          default:
            this.setState({ currentStep: 'upload_audiofiles_settings' });
            break;
        }
      } else {
        switch (true) {
          case nextData.status === 0 && !nextData.has_file:
            this.setState({ currentStep: 'upload_settings' });
            break;

          case nextData.status === 0 && nextData.has_file:
            this.setState({ currentStep: 'tags_matching' });
            break;

          default:
            this.setState({ currentStep: 'upload_settings' });
            break;
        }

        if (
          prevStep === 'upload'
          && prevStep !== nextStep
          && (filePathExample || fileWithSubPathExample)
        ) {
          this.setState({
            filePathExample: null,
            fileWithSubPathExample: null,
          });
        }
      }
    }
  }

  renderIngestionStepsHeader = () => {
    const { panel: { data }, t } = this.props;
    const { currentStep } = this.state;

    if (!data || !data.ingestion_type) return null;

    const headers = data.ingestion_type === 'audio'
      ? [
        'upload_audiofiles_settings',
        'upload_audiofiles',
      ] : [
        'upload_settings',
        'upload',
        'tags_matching',
        'versions_matching',
      ];

    return (
      <StepsHeader>
        {headers.map(stepKey => (
          <StepTitle key={stepKey} active={currentStep === stepKey}>
            {t(`pages:meta.${entity}s.panel.${stepKey}_step`)}
          </StepTitle>
        ))}
      </StepsHeader>
    );
  };

  renderStepButtons = ({
    sidePanelActions,
    formIsSubmitting = false,
  } = {}) => {
    const { panel, t } = this.props;
    const { data, isModifying, isUploading } = panel;
    const { currentStep } = this.state;

    if (!data || !data.ingestion_type) return null;

    const prevStepDisabled = isModifying
      || panel['isModifying-launch']
      || panel['isModifying-tags'] // Applies only to ingestion_type file
      || panel['isModifying-versions'] // Applies only to ingestion_type file
      || isUploading // Applies only to ingestion_type file
      || formIsSubmitting;

    let nextStepDisabled = prevStepDisabled;

    let prevStep = null;
    let nextStep = null;

    if (data.ingestion_type === 'audio') {
      if (currentStep === 'upload_audiofiles_settings') {
        return (
          <StepButtonWrapper>
            <NextStepButton type="submit" disabled={nextStepDisabled}>
              {t(`pages:meta.${entity}s.panel.submit_and_next`)}
            </NextStepButton>
          </StepButtonWrapper>
        );
      }

      switch (currentStep) {
        case 'upload_audiofiles':
          prevStep = 'upload_audiofiles_settings';
          nextStep = data.status === 0 ? 'pre_success' : null;
          break;

        case 'pre_success':
          prevStep = 'upload_audiofiles';
          nextStep = data.status === 0 ? 'success' : null;
          break;

        default:
          break;
      }
    } else {
      if (currentStep === 'upload_settings') {
        return (
          <StepButtonWrapper>
            <NextStepButton type="submit" disabled={nextStepDisabled}>
              {t(`pages:meta.${entity}s.panel.submit_and_next`)}
            </NextStepButton>
          </StepButtonWrapper>
        );
      }

      switch (currentStep) {
        case 'upload':
          nextStepDisabled = nextStepDisabled || !data.has_file;
          prevStep = 'upload_settings';
          nextStep = 'tags_matching';
          break;

        case 'tags_matching':
          nextStepDisabled = nextStepDisabled || !data.unmatched_processed;
          prevStep = 'upload';
          nextStep = 'versions_matching';
          break;

        case 'versions_matching':
          nextStepDisabled = nextStepDisabled || !data.unmatched_processed;
          prevStep = 'tags_matching';
          nextStep = data.status === 0 ? 'pre_success' : null;
          break;

        case 'pre_success':
          prevStep = 'versions_matching';
          nextStep = data.status === 0 ? 'success' : null;
          break;

        default:
          break;
      }
    }

    return (
      <StepButtonWrapper>
        {prevStep && (
          <PrevStepButton
            onClick={() => this.setState({ currentStep: prevStep })}
            disabled={prevStepDisabled}
          >
            {t(`pages:meta.${entity}s.panel.back_to_prev`)}
          </PrevStepButton>
        )}
        {nextStep && (
          <NextStepButton
            onClick={async () => {
              if (nextStep === 'success') {
                await sidePanelActions.modifyAdditional(
                  'launch',
                  'post',
                  {},
                  'launch',
                );
              }

              return this.setState({ currentStep: nextStep });
            }}
            isLauncher={nextStep === 'success'}
            disabled={nextStepDisabled}
          >
            {nextStep === 'pre_success' && t(`pages:meta.${entity}s.panel.launch_ingestion`)}
            {nextStep === 'success' && t(`pages:meta.${entity}s.panel.launch_ingestion_confirm`)}
            {!['pre_success', 'success'].includes(nextStep) && t(`pages:meta.${entity}s.panel.submit_and_next`)}
          </NextStepButton>
        )}
      </StepButtonWrapper>
    );
  };

  renderSetTypeStep = (sidePanelActions) => {
    const { t } = this.props;

    return (
      <StepWrapper>
        <StepNotice>
          {t(`pages:meta.${entity}s.panel.set_type_step_notice`)}
        </StepNotice>
        <TypeSelectionWrapper>
          <TypeChoiceWrapper
            onClick={async () => {
              await sidePanelActions.modifyDocument({ ingestion_type: 'file' });
              this.setState({ currentStep: 'upload_settings' });
            }}
          >
            <MetadataIcon />
            <TypeChoiceText>
              {t(`pages:meta.${entity}s.panel.you_have_ingestion_file`)}
            </TypeChoiceText>
          </TypeChoiceWrapper>
          <TypeChoiceWrapper
            onClick={async () => {
              await sidePanelActions.modifyDocument({ ingestion_type: 'audio' });
              this.setState({ currentStep: 'upload_audiofiles_settings' });
            }}
          >
            <AudiofileIcon />
            <TypeChoiceText>
              {t(`pages:meta.${entity}s.panel.you_have_no_ingestion_file`)}
            </TypeChoiceText>
          </TypeChoiceWrapper>
        </TypeSelectionWrapper>
      </StepWrapper>
    );
  };

  renderUploadSettingsStep = (sidePanelActions) => {
    const { panel, t } = this.props;
    const { data, isModifying, isUploading } = panel;

    return (
      <StepWrapper>
        <StepNotice>
          {buildExternalLinkTextNode(
            t(`pages:meta.${entity}s.panel.upload_settings_step_notice`),
            docs.meta_ingestion,
          )}
        </StepNotice>
        <Form
          key="upload_settings"
          onSubmit={async (values) => {
            if (!data.has_file) await sidePanelActions.modifyDocument(values);
            this.setState({ currentStep: 'upload' });
          }}
          initialValues={{
            preferred_language: _get(data, 'preferred_language', ''),
            date_format: _get(data, 'date_format', ''),
            normalize_artists_names: _get(data, 'normalize_artists_names', false),
          }}
        >
          {({ handleSubmit, submitting }) => {
            const formDisabled = isModifying
              || panel['isModifying-launch']
              || panel['isModifying-tags']
              || panel['isModifying-versions']
              || isUploading
              || data.has_file;

            return (
              <StyledForm onSubmit={handleSubmit}>
                <SectionBox mb="0">
                  <InputWrapper>
                    <Field name="preferred_language">
                      {({ input, meta }) => (
                        <MewoInput
                          meta={meta}
                          input={{
                            ...input,
                            onChange: (value) => {
                              if (value) input.onChange(value);
                            },
                          }}
                          use={SelectInput}
                          options={Object.entries(languagesNames)
                            .map(([key, { nativeName }]) => ({
                              label: nativeName,
                              value: key,
                            }))}
                          label={t(`pages:meta.${entity}s.panel.preferred_language`)}
                          renderOptionsVariant="classic"
                          renderValuesVariant="text"
                          fullWidth
                          disabled={formDisabled}
                        />
                      )}
                    </Field>
                    <MiscHelper mt="2rem">
                      {t(`pages:meta.${entity}s.panel.preferred_language_desc`)}
                    </MiscHelper>
                    <Field name="date_format">
                      {({ input, meta }) => (
                        <MewoInput
                          meta={meta}
                          input={{
                            ...input,
                            onChange: (value) => {
                              if (value) input.onChange(value);
                            },
                          }}
                          use={SelectInput}
                          options={[
                            {
                              label: t('common:misc.date_format_DDMMYYYY'),
                              value: 'DD/MM/YYYY',
                            },
                            {
                              label: t('common:misc.date_format_MMDDYYYY'),
                              value: 'MM/DD/YYYY',
                            },
                          ]}
                          label={t(`pages:meta.${entity}s.panel.date_format`)}
                          renderOptionsVariant="classic"
                          renderValuesVariant="text"
                          fullWidth
                          disabled={formDisabled}
                        />
                      )}
                    </Field>
                    <MiscHelper mt="2rem">
                      {t(`pages:meta.${entity}s.panel.date_format_desc`)}
                    </MiscHelper>
                    <Field
                      name="normalize_artists_names"
                      component={MewoInput}
                      use={SwitchInput}
                      type="boolean"
                      textOn={t(`pages:meta.${entity}s.panel.normalize_artists_names_on`)}
                      textOff={t(`pages:meta.${entity}s.panel.normalize_artists_names_off`)}
                      disabled={formDisabled}
                    />
                    <MiscHelper mt="2rem">
                      {t(`pages:meta.${entity}s.panel.normalize_artists_names_desc`)}
                    </MiscHelper>
                  </InputWrapper>
                </SectionBox>
                {this.renderStepButtons({
                  sidePanelActions,
                  formIsSubmitting: submitting,
                })}
              </StyledForm>
            );
          }}
        </Form>
      </StepWrapper>
    );
  };

  audiofilesSettingsPickFormData = (data, init = false) => ({
    catalog: getApiSelectInputData(data.catalog, 'catalog', init),
  });

  renderUploadAudiofilesSettingsStep = (sidePanelActions) => {
    const { panel, t, triggerPanelAutosaveCheck } = this.props;
    const { data, isModifying } = panel;

    return (
      <StepWrapper>
        <StepNotice>
          {buildExternalLinkTextNode(
            t(`pages:meta.${entity}s.panel.upload_audiofiles_settings_step_notice`),
            docs.meta_ingestion,
          )}
        </StepNotice>
        <Form
          key="upload_audiofiles_settings"
          onSubmit={async (values) => {
            if (data.status === 0) {
              await sidePanelActions.modifyDocument(
                this.audiofilesSettingsPickFormData(values, false),
              );
            }

            this.setState({ currentStep: 'upload_audiofiles' });
          }}
          initialValues={this.audiofilesSettingsPickFormData(data, true)}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, submitting }) => {
            const formDisabled = isModifying
              || panel['isModifying-launch']
              || data.status !== 0;

            const getASIAction = (key, ASIEntity) => {
              if (!_get(data, key)) return null;

              return () => triggerPanelAutosaveCheck(
                'open',
                [spts.metaMapping[ASIEntity], _get(data, `${key}.id`)],
              );
            };

            return (
              <StyledForm onSubmit={handleSubmit}>
                <SectionBox mb="0">
                  <InputWrapper>
                    <Field
                      name="catalog"
                      component={MewoInput}
                      use={ApiSelectInput}
                      type="text"
                      label={t(`pages:meta.${entity}s.panel.catalog`)}
                      entity="meta/catalogs"
                      getEntityLabel={getDocName('catalog')}
                      getSearchEntityLabel={getSearchName('catalog')}
                      onTheFlyEnabled
                      onTheFlyField="name"
                      itemAction={getASIAction('catalog', 'catalog')}
                      fullWidth
                      validate={validator.catalog}
                      disabled={formDisabled}
                    />
                    <MiscHelper mt="2rem">
                      {t(`pages:meta.${entity}s.panel.catalog_desc`)}
                    </MiscHelper>
                  </InputWrapper>
                </SectionBox>
                {this.renderStepButtons({
                  sidePanelActions,
                  formIsSubmitting: submitting,
                })}
              </StyledForm>
            );
          }}
        </Form>
      </StepWrapper>
    );
  };

  handleOnBeforeUpload = (files) => {
    const { filePathExample, fileWithSubPathExample } = this.state;

    const nextState = {};

    if (!filePathExample) {
      const file = Object.values(files).find(f => !f.data.relativePath);
      if (file) nextState.filePathExample = file.data.name;
    }

    if (!fileWithSubPathExample) {
      const file = Object.values(files).find(f => f.data.relativePath);

      if (file) {
        nextState.fileWithSubPathExample = file.data.relativePath;

        if (nextState.fileWithSubPathExample.indexOf('/') === 0) {
          nextState.fileWithSubPathExample = nextState.fileWithSubPathExample.substr(1);
        }
      }
    }

    if (!_isEmpty(nextState)) {
      this.setState(nextState);
    }
  };

  renderUploadStep = (sidePanelActions) => {
    const { panel, t } = this.props;
    const { data, isModifying, isUploading, uploadProgress } = panel;

    const { filePathExample, fileWithSubPathExample } = this.state;

    const formDisabled = isModifying
      || panel['isModifying-launch']
      || panel['isModifying-tags']
      || panel['isModifying-versions']
      || isUploading
      || data.has_file;

    return (
      <StepWrapper>
        {!data.has_file ? (
          <div>
            <StepNotice>
              {buildExternalLinkTextNode(
                t(`pages:meta.${entity}s.panel.binaries_step_notice`),
                docs.meta_ingestion,
              )}
            </StepNotice>
            <MetaUploader
              context="ingestion"
              note={t('components:meta_uploader.note_ingestion')}
              endpoint={getApiUrl('meta/uploads/binaries')}
              onBeforeUpload={this.handleOnBeforeUpload}
            />
          </div>
        ) : (
          <GenericText mt="3rem">
            {t(`pages:meta.${entity}s.panel.binaries_step_disabled`)}
          </GenericText>
        )}
        <StepNotice>
          {buildExternalLinkTextNode(
            t(`pages:meta.${entity}s.panel.upload_step_notice`),
            'https://docs.google.com/spreadsheets/d/13O33HDcpYgd5hF9QVC5Pu7SmUPVzF0nBzrg8-WvvcwM/edit?usp=sharing',
          )}
          &nbsp;
          {buildExternalLinkTextNode(
            t(`pages:meta.${entity}s.panel.upload_step_notice_learn_more`),
            docs.meta_ingestion,
          )}
        </StepNotice>
        {((filePathExample || fileWithSubPathExample) && !data.has_file) && (
          <FilePathWrapper>
            {filePathExample && (
              <FilePathPreview>{filePathExample}</FilePathPreview>
            )}
            {fileWithSubPathExample && (
              <FilePathPreview>{fileWithSubPathExample}</FilePathPreview>
            )}
          </FilePathWrapper>
        )}
        {!data.has_file ? (
          <IngestionImporter
            onChange={sidePanelActions.uploadDocumentFile}
            onCancelUpload={sidePanelActions.cancelUploadDocumentFile}
            uploading={isUploading}
            uploadingProgress={uploadProgress}
            maxSize={entityConfig.panelUploads.csv.maxSize}
            lang={{
              cancelUpload: t(`pages:meta.${entity}s.panel.importer_cancel_upload`),
              dragAndDropOr: t(`pages:meta.${entity}s.panel.importer_drag_and_drop_or`),
              browse: t(`pages:meta.${entity}s.panel.importer_browse`),
              fileAccepted: t(`pages:meta.${entity}s.panel.importer_file_accepted`),
              fileNotAccepted: t(`pages:meta.${entity}s.panel.importer_file_not_accepted`),
              industryNotice: t(`pages:meta.${entity}s.panel.importer_industry_notice`),
            }}
            disabled={formDisabled}
          />
        ) : (
          <SectionBox mb="0" width="100%">
            <FileNameContainer>
              <FileName href={data.ingestion_file.url} download>
                {data.ingestion_file.file_name}
              </FileName>
              <UploadStatus />
            </FileNameContainer>
            <div>
              {t(`pages:meta.${entity}s.panel.ingestion_file_lines`, { count: data.lines })}
            </div>
          </SectionBox>
        )}
        {this.renderStepButtons({ sidePanelActions })}
      </StepWrapper>
    );
  };

  renderUploadAudiofilesStep = (sidePanelActions) => {
    const { panel, t } = this.props;
    const { data } = panel;

    return (
      <StepWrapper>
        {data.status === 0 ? (
          <div>
            <StepNotice>
              {buildExternalLinkTextNode(
                t(`pages:meta.${entity}s.panel.upload_audiofiles_step_notice`),
                docs.meta_ingestion,
              )}
            </StepNotice>
            <MetaUploader
              context="ingestion"
              endpoint={getApiUrl(`meta/uploads/binaries/ingestion/${data.id}`)}
            />
          </div>
        ) : (
          <GenericText mt="3rem">
            {t(`pages:meta.${entity}s.panel.upload_audiofiles_step_disabled`)}
          </GenericText>
        )}
        {this.renderStepButtons({ sidePanelActions })}
      </StepWrapper>
    );
  };

  renderTagsMatchingStep = (sidePanelActions) => {
    const { panel, t } = this.props;
    const { data, isModifying, isUploading } = panel;

    const formDisabled = isModifying
      || panel['isModifying-launch']
      || panel['isModifying-tags']
      || panel['isModifying-versions']
      || isUploading
      || data.status > 0;

    return (
      <StepWrapper>
        <StepNotice>
          {buildExternalLinkTextNode(
            t(`pages:meta.${entity}s.panel.tags_matching_step_notice`),
            docs.meta_ingestion,
          )}
        </StepNotice>
        <TagsMatching
          handleChange={(data.status === 0
            ? values => sidePanelActions.modifyAdditional(
              'tags',
              'put',
              values,
              'tags',
            )
            : null
          )}
          ingestionLaunched={data.status > 0}
          processing={!data.unmatched_processed}
          unmatched={data.unmatched_tags}
          dictionary={data.tags_dictionary}
          disabled={formDisabled}
        />
        {this.renderStepButtons({ sidePanelActions })}
      </StepWrapper>
    );
  };

  renderVersionsMatchingStep = (sidePanelActions) => {
    const { panel, t } = this.props;
    const { data, isModifying, isUploading } = panel;

    const formDisabled = isModifying
      || panel['isModifying-launch']
      || panel['isModifying-tags']
      || panel['isModifying-versions']
      || isUploading
      || data.status > 0;

    return (
      <StepWrapper>
        <StepNotice>
          {buildExternalLinkTextNode(
            t(`pages:meta.${entity}s.panel.versions_matching_step_notice`),
            docs.meta_ingestion,
          )}
        </StepNotice>
        <VersionsMatching
          handleChange={(data.status === 0
            ? values => sidePanelActions.modifyAdditional(
              'versions',
              'put',
              values,
              'versions',
            )
            : null
          )}
          ingestionLaunched={data.status > 0}
          processing={!data.unmatched_processed}
          unmatched={data.unmatched_versions}
          dictionary={data.versions_dictionary}
          disabled={formDisabled}
        />
        {this.renderStepButtons({ sidePanelActions })}
      </StepWrapper>
    );
  };

  renderPreSuccessStep = (sidePanelActions) => {
    const { t } = this.props;

    return (
      <StepWrapper>
        <StepNotice>
          {t(`pages:meta.${entity}s.panel.launch_ingestion_confirm_title`)}
        </StepNotice>
        <GenericText>
          {t(`pages:meta.${entity}s.panel.launch_ingestion_confirm_desc`)}
        </GenericText>
        {this.renderStepButtons({ sidePanelActions })}
      </StepWrapper>
    );
  };

  renderSuccessStep = () => {
    const { panel: { data }, t } = this.props;

    return (
      <StepWrapper>
        <GenericText mt="3rem">
          {t(`pages:meta.${entity}s.panel.ingestion_success`)}
        </GenericText>
        <StepButtonWrapper>
          <BackToBeginningButton
            onClick={() => this.setState({
              currentStep: data.ingestion_type === 'audio'
                ? 'upload_audiofiles_settings'
                : 'upload_settings',
            })}
          >
            {t(`pages:meta.${entity}s.panel.back_to_beginning`)}
          </BackToBeginningButton>
        </StepButtonWrapper>
      </StepWrapper>
    );
  };

  renderIngestionStep = (sidePanelActions) => {
    const { currentStep } = this.state;

    switch (currentStep) {
      case 'set_type':
        return this.renderSetTypeStep(sidePanelActions);

      case 'upload_settings':
        return this.renderUploadSettingsStep(sidePanelActions);

      case 'upload_audiofiles_settings':
        return this.renderUploadAudiofilesSettingsStep(sidePanelActions);

      case 'upload':
        return this.renderUploadStep(sidePanelActions);

      case 'upload_audiofiles':
        return this.renderUploadAudiofilesStep(sidePanelActions);

      case 'tags_matching':
        return this.renderTagsMatchingStep(sidePanelActions);

      case 'versions_matching':
        return this.renderVersionsMatchingStep(sidePanelActions);

      case 'pre_success':
        return this.renderPreSuccessStep(sidePanelActions);

      case 'success':
        return this.renderSuccessStep(sidePanelActions);

      default:
        return null;
    }
  };

  render() {
    const { panel, localPreferences, t } = this.props;
    const {
      data,
      isModifying,
      isDeleting,
    } = panel;

    const shareLink = _get(data, 'id')
      ? `${getSiteOrigin()}/meta/${entity}s?panel=${data.id}`
      : '';

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[META_INGESTION_PANEL].get}
        modify={panelActions[META_INGESTION_PANEL].modify}
        modifyAdditional={panelActions[META_INGESTION_PANEL].modifyAdditional}
        uploadFile={panelActions[META_INGESTION_PANEL].uploadFile}
        cancelUploadFile={panelActions[META_INGESTION_PANEL].cancelUploadFile}
        del={panelActions[META_INGESTION_PANEL].delete}
        lang={{
          deleteConfirmTitle: t(`pages:meta.${entity}s.delete_documents`),
          deleteConfirmDesc: t(`pages:meta.${entity}s.delete_documents_notice`),
        }}
      >
        {(sidePanelActions) => {
          if (!data) return null;

          return (
            <Wrapper>
              <DocumentStatus
                isModifying={isModifying}
                isDeleting={isDeleting}
                localPreferences={localPreferences}
                documentRights={{ isOwner: true }}
                updatedAt={data.updated_at}
                deleteDocument={sidePanelActions.deleteDocument}
              />
              <DocumentHeader>
                <DocumentInnerHeader>
                  <DocumentInformations>
                    <DocumentName>
                      {data.name}
                    </DocumentName>
                    <DocumentCreatedAt>
                      {t(`pages:meta.${entity}s.panel.created_at`)}
                      :&nbsp;
                      {formatDateString(data.created_at, localPreferences.dateFormat, true)}
                    </DocumentCreatedAt>
                  </DocumentInformations>
                  <CopyLink
                    disableMargin
                    link={shareLink}
                    content={t(`pages:meta.${entity}s.panel.copy_share_link`)}
                    tooltipContent={t(`pages:meta.${entity}s.panel.copy_share_link_tooltip`)}
                  />
                </DocumentInnerHeader>
                {this.renderIngestionStepsHeader()}
              </DocumentHeader>
              <DocumentBody>
                {this.renderIngestionStep(sidePanelActions)}
              </DocumentBody>
            </Wrapper>
          );
        }}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core }) {
  return {
    localPreferences: core.localPreferences,
  };
}

export default compose(
  withTranslation(['common', 'pages', 'components', 'errors']),
  connect(mapStateToProps, {
    triggerPanelAutosaveCheck: triggerAutosaveCheck,
  }),
)(MetaIngestion);
