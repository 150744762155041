// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { StyledGooglePlacesInput } from './styles';

// =============================
// Component
// =============================

const GooglePlacesInput = ({ t, ...rest }) => (
  <StyledGooglePlacesInput
    {...rest}
    autoComplete="off"
    dropdownClassName="ignore-react-onclickoutside"
    googleApiKey={process.env.GOOGLE_API_KEY}
    lang={{
      street_number: t('components:google_api_input.street_number'),
      route: t('components:google_api_input.route'),
      locality: t('components:google_api_input.locality'),
      postal_code: t('components:google_api_input.postal_code'),
      country: t('common:form.country'),
      missing_informations: t('components:google_api_input.missing_informations'),
      errors: {
        ZERO_RESULTS: t('components:google_api_input.errors.zero_results'),
        INVALID_REQUEST: t('components:google_api_input.errors.invalid_request'),
        UNKNOWN_ERROR: t('components:google_api_input.errors.unknown_error'),
        ERROR: t('components:google_api_input.errors.error'),
        default: t('components:google_api_input.errors.default'),
      },
      Label: {
        optional: t('components:mewo_input.Label.optional'),
      },
    }}
  />
);

GooglePlacesInput.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['components', 'common'])(GooglePlacesInput);
