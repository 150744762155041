// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { DropdownList } from '@mewo/components';
import { alpha } from '@material-ui/core';

import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const StyledDropdown = styled(DropdownList).attrs({
  contentStyles: css`
    max-height: none;
  `,
  dropdownStyles: css`
    background: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 0.4rem 1.5rem 0 rgba(19, 93, 125, 0.07);
    padding: 0.8rem 0;
    width: 20rem;
  `,
  placement: 'bottom-start',
  withoutStyles: true,
})``;

export const Option = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  font-size: 1.5rem;
  padding: 1.2rem 2.8rem;
  transition: background-color ${({ theme }) => theme.transition.medium};

  ${({ selected }) => !selected
    && css`
      &:hover {
        background-color: ${({ theme }) => alpha(theme.colors.primary, 0.2)};
      }
    `};

  ${({ theme, selected }) => selected
    && css`
      background-color: ${alpha(theme.colors.primary, 0.1)};
      cursor: default;
    `};
`;

export const OptionLink = styled(Option).attrs({
  as: Link,
})`
  cursor: pointer;
`;
