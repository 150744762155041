// =============================
// Imports
// =============================

// Helpers
import * as Sentry from '@sentry/nextjs';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Middleware
// =============================

const businessMiddleware = () => next => (action) => {
  switch (true) {
    case action.type === acts.SET_USER_INFO:
      if (typeof window !== 'undefined' && window.gtag && process.env.GA_CODE) {
        window.gtag('config', process.env.GA_CODE, {
          user_id: action.payload.email,
        });
      }

      Sentry.configureScope((scope) => {
        scope.setUser({
          id: action.payload.id,
          username: `${action.payload.first_name} ${action.payload.last_name}`,
          email: action.payload.email,
        });
      });

      return next(action);

    default:
      return next(action);
  }
};

export default businessMiddleware;
