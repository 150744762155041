// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const ModalForm = styled.form`
  width: 100%;
`;
