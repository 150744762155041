// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from '@nivo/line';

// Other
import colors from '../colors';

// =============================
// Component
// =============================

class LineChart extends Component {
  static propTypes = {
    charts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
      }),
    ).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    maxValue: PropTypes.number.isRequired,
    minCeilingSlice: PropTypes.number,
  };

  static defaultProps = {
    width: 680,
    height: 340,
    minCeilingSlice: 10,
  };

  render() {
    const { charts, width, height, maxValue, minCeilingSlice } = this.props;

    return (
      <Line
        data={charts}
        width={width}
        height={height}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'month',
        }}
        xFormat="time:%b %Y"
        yScale={{
          type: 'linear',
          stacked: false,
          max: (Math.floor(maxValue / minCeilingSlice) + 1) * minCeilingSlice,
        }}
        /* Axis configuration */
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          format: '%b %Y',
          tickValues: 'every month',
        }}
        /* Margins */
        margin={{ top: 10, right: 50, bottom: 50, left: 45 }}
        /* Colors */
        colors={colors}
        /* Curve of the line */
        curve="natural"
        /* Point for values */
        pointSize={12}
        /* Area below line */
        enableArea
        areaOpacity={0.3}
        /* Mesh */
        useMesh
      />
    );
  }
}

export default LineChart;
