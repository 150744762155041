// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import Header from '../header';
import Footer from '../footer';
import ModoSubHeader from '../modoSubHeader';
import ModoVerticalMenu from '../modoVerticalMenu';

import * as pth from '../../../helpers/proptypes';

import { Wrapper, ContentWrapper, Content } from './styles';

// =============================
// Component
// ===============s==============

function AppShell({
  children,
  faq,
  footer,
  isAdmin,
  HeaderDropdownProps,
  logo,
  MetaHeaderProps,
  ModoMenuProps,
  playerIsOpened,
  NotificationManagerProps,
}) {
  const isMeta = !!MetaHeaderProps;

  const isModo = !!ModoMenuProps;
  const isCustomModoPage = isModo && ModoMenuProps.activePage.indexOf('custompage') !== -1;

  return (
    <Wrapper>
      <Header
        faq={faq}
        HeaderDropdownProps={HeaderDropdownProps}
        logo={logo}
        NotificationManagerProps={NotificationManagerProps}
        MetaHeaderProps={isMeta ? MetaHeaderProps : null}
      />

      {isModo && <ModoVerticalMenu {...ModoMenuProps} />}

      <ContentWrapper
        disableTopPadding={isMeta}
        withPadding={isModo}
        playerIsOpened={isMeta && playerIsOpened}
      >
        {isModo && !isCustomModoPage && (
          <ModoSubHeader {...ModoMenuProps} />
        )}
        {children}
        <Footer isAdmin={isAdmin}>{footer}</Footer>
      </ContentWrapper>
    </Wrapper>
  );
}

AppShell.propTypes = {
  /** Page content */
  children: PropTypes.node,
  /** Player is opened */
  playerIsOpened: PropTypes.bool.isRequired,
  /** Faq node */
  faq: PropTypes.node,
  /** Footer text */
  footer: PropTypes.node,
  /** Object pass down to HeaderDropdown */
  HeaderDropdownProps: PropTypes.shape({
    /** Array of links */
    links: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.func,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    /** User object */
    user: pth.user,
  }).isRequired,
  /** Header logo */
  logo: PropTypes.node,
  /** Object pass down to MetaHeader */
  MetaHeaderProps: PropTypes.shape({
    /** Currently active page. */
    activePage: PropTypes.string.isRequired,
  }),
  /** Object pass down to ModoMenu */
  ModoMenuProps: PropTypes.shape({
    /** Current modo id. */
    modoId: PropTypes.string.isRequired,
    /** Current modo config. */
    modoData: pth.modoConfig.isRequired,
    /** Currently active page. */
    activePage: PropTypes.string.isRequired,
    /** Translation strings. */
    lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Modify Modo plan action */
    modifyModoPlan: PropTypes.func.isRequired,
    /** If True, status is ON */
    status: PropTypes.bool.isRequired,
    /** Activation tooltip */
    activationTooltip: PropTypes.node.isRequired,
  }),
  /** Object pass down to Notification Manager */
  NotificationManagerProps: PropTypes.shape({
    /** Function used render navigation link to Notification Manager */
    renderGotoManagerLink: PropTypes.func.isRequired,
    /** Translation strings */
    lang: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Function used to mark all notifications as read */
    markAllRead: PropTypes.func.isRequired,
    /** Array of notifications  */
    notifications: PropTypes.arrayOf(pth.userNotification).isRequired,
  }).isRequired,
};

AppShell.defaultProps = {
  children: null,
  faq: null,
  footer: '© 2021 Mewo. All rights reserved.',
  logo: null,
  MetaHeaderProps: null,
  ModoMenuProps: null,
};

AppShell.Content = Content;

export default AppShell;
