// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { StyledSelectInput } from './styles';

// =============================
// Component
// =============================

const SelectInput = ({ t, withBackground, ...rest }) => (
  <StyledSelectInput
    dropdownClassName="ignore-react-onclickoutside"
    lang={{
      selectAll: t('components:select_input.select_all'),
      unselectAll: t('components:select_input.unselect_all'),
      noOptions: t('components:select_input.no_options'),
      Label: {
        optional: t('components:mewo_input.Label.optional'),
      },
    }}
    withBackground={withBackground}
    {...rest}
  />
);

SelectInput.propTypes = {
  /** If True, will render Input with a white background. */
  withBackground: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

SelectInput.defaultProps = {
  withBackground: false,
};

export default withTranslation(['components'])(SelectInput);
