// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import Notification from '../notification';

import { withTranslation } from '../../../config/i18n';

import * as pth from '../../../helpers/proptypes';

import { Wrapper, ClearNotificationsButton } from './styles';

// =============================
// Component
// =============================

class NotificationsList extends Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(pth.notification).isRequired,
    clearNotification: PropTypes.func.isRequired,
    clearAllNotifications: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { notifications, clearNotification, clearAllNotifications, t } = this.props;

    const activeNotifications = notifications.map(n => (
      <Notification
        type={n.type}
        duration={n.duration}
        notificationKey={n.notificationKey}
        clearNotification={clearNotification}
        key={n.notificationKey}
        message={n.message}
        reqId={n.reqId}
        t={t}
      />
    ));

    return (
      <Wrapper>
        {activeNotifications}
        {activeNotifications.length > 1 && (
          <ClearNotificationsButton onClick={clearAllNotifications}>
            {t('components:notification_list.clear_all')}
          </ClearNotificationsButton>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation(['components'])(NotificationsList);
