// =============================
// Imports
// =============================

import _pickBy from 'lodash/pickBy';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import _pick from 'lodash/pick';
import _isArray from 'lodash/isArray';
import _isBoolean from 'lodash/isBoolean';

import { removePercentChar } from './misc';

// =============================
// Helpers
// =============================

// A few terms here:
//
// Redux Search Object - search representation in Redux state
// Router Query Object - search object in query parsed as object (provided by next router)
// API Search Object - search object sent to API as data
// Query String - search object in query string format for url bar
// Query Keys - list of keys within search object used for search
//
// Redux Search Object is the main, most comprehensive representation, the rest
// are projections for different consumers

// These helpers are used for search for the following pages:
//
// - [Meta] Tracks
// - [Meta] Albums
// - [Meta] Playlists
// - [Meta] Pitchs
// - [Meta] Briefs
// - [Meta] Catalogs
// - [Meta] Artists
// - [Meta] Labels
// - [Meta] Publishers
// - [Meta] Peoples

// In order to succesfully implement this, here are some pointers:
//
// - Within the redux store, all possible query values should be set as undefined.
// For example, if you check the "collaborators" object within the reducer "pages.js",
// you will see an object { sort: undefined } for the query key. This means that
// the only search query possible here is changing the sort key.
// - Always build the projections for different consumers using these helpers.

export const reduxObjectKeys = ['search', 'sort', 'sort_direction'];
export const queryStringKeys = ['search', 'sort', 'sort_direction', 'page'];

/**
 * Transform a js array of strings to a string for url
 * @param {string[]} value - Array of strings as JS (JSON.parse)
 * @returns {string}
 */
export const stringArrayToQueryStringArray = (value) => {
  if (!value || !value.length) return null;
  return removePercentChar(JSON.stringify(value));
};

/**
 * Transform a string that contains an array of strings to js
 * @param {string} value - Array of strings as string (JSON.stringify)
 * @returns {string[]|string[][]}
 */
export const queryStringArrayToStringArray = (value) => {
  if (!value || !value.length) return null;
  return JSON.parse(value);
};

/**
 * Transform a string to a boolean
 * @param {string} value - String
 * @param {boolean} trueOnly - Set to true to allow only true value
 * @returns {string}
 */
export const queryStringToBoolean = (value, trueOnly = false) => {
  if (trueOnly && value !== 'true') return null;
  if (!['true', 'false'].includes(value)) return null;
  return value === 'true';
};

/**
 * Transform a boolean to a string
 * @param {boolean} value - Boolean
 * @param {boolean} trueOnly - Set to true to allow only true value
 * @returns {string}
 */
export const booleanToQueryString = (value, trueOnly = false) => {
  if (trueOnly && value !== true) return null;
  if (!_isBoolean(value)) return null;
  return value.toString();
};

// Follow meta-entities.js keys here
export const filters = {
  track: {
    // Filter bar
    tags: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    tags_or: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    tags_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    versions: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    versions_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    // Similarity search (no exclusion)
    track_id: {
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    search_id: {
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    publishers: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    publishers_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    labels: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    labels_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    artists: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    artists_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    catalogs: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    catalogs_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    internal_refs: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    internal_refs_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    albums: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    albums_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    music_type: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
    music_type_not: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
    grouping: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    grouping_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    ref_id: {
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    lyrics: {
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    stems: {
      toString: val => booleanToQueryString(val, true),
      toObject: val => queryStringToBoolean(val, true),
      isArrayWithName: false,
    },
    bpm: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    year: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    duration: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    tempos: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    tempos_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    tonality_keys: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    tonality_keys_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: false,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  album: {
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    artists: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    artists_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    catalogs: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    catalogs_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    album_types: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    album_types_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  playlist: {
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    created_by: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    created_by_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  pitch: {
    type: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
    type_not: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
    // Related collections
    created_by: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    created_by_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  brief: {
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    created_by: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    created_by_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  catalog: {
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  artist: {
    artist_type: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
    artist_type_not: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  label: {
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  publisher: {
    // Related collections
    tenants: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    tenants_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    agents_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
    showcases_not: {
      isMultiArrayValue: true,
      toString: stringArrayToQueryStringArray,
      toObject: queryStringArrayToStringArray,
      isArrayWithName: true,
    },
  },
  people: {},
  ingestion: {
    ingestion_type: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
    ingestion_type_not: {
      toString: val => val,
      toObject: val => val,
      isArrayWithName: false,
    },
  },
};

/**
 * Get current page from Router Query Object
 *
 * @param {object} query - Router Query Object
 * @return {number} - Current page number (starts at 0)
 */
export const extractPageFromRouterQuery = query => query.page || 0;

/**
 * Transform Router Query Object to Redux Search Object
 *
 * @param {string} entity - Meta entity name
 * @param {object} query - Router Query Object
 * @return {object} - Redux Search Object
 */
export const routerQueryToReduxObject = (entity, query) => {
  // Build base query first
  const transformedQuery = {
    ..._pickBy(
      query,
      (v, k) => reduxObjectKeys.includes(k) && v,
    ),
    filters: {},
  };

  // Build filters
  Object.keys(filters[entity]).forEach((filterKey) => {
    // NOTE: Filters are in string format when coming from the router
    const filterValue = filters[entity][filterKey].toObject(query[filterKey]);

    if (filterValue) transformedQuery.filters[filterKey] = filterValue;
  });

  return transformedQuery;
};

/**
 * Transform Router Query Object to Query String
 *
 * @param {string} entity - Meta entity name
 * @param {object} query - Router Query Object
 * @return {string} - Query string
 */
export const routerQueryToQueryString = (entity, query) => {
  const queryParams = new URLSearchParams();

  // Build base params first
  const compactedParams = _pickBy(query, (v, k) => queryStringKeys.includes(k) && v);

  Object.keys(compactedParams).forEach((key) => {
    queryParams.append(key, compactedParams[key]);
  });

  // Build filters
  Object.keys(filters[entity]).forEach((filterKey) => {
    // NOTE: Filters are already in string format when coming from the router
    const filterValue = query[filterKey];

    if (filterValue) queryParams.append(filterKey, filterValue);
  });

  const stringQuery = queryParams.toString();

  return stringQuery ? `?${stringQuery}` : '';
};

/**
 * Transform Redux Search Object to Query String
 *
 * @param {string} entity - Meta entity name
 * @param {number} page - Current or target page
 * @param {object} query - Router Query Object
 * @return {string} - Query string
 */
export const reduxObjectToQueryString = (entity, page, query) => {
  const queryParams = new URLSearchParams();

  // Build base params first
  const compactedParams = _pickBy(query, (v, k) => queryStringKeys.includes(k) && v);

  Object.keys(compactedParams).forEach((key) => {
    queryParams.append(key, compactedParams[key]);
  });

  // Add page first if it's superior than 0 (aka first page)
  if (page) {
    queryParams.append('page', page);
  }

  // Build filters
  Object.keys(filters[entity]).forEach((filterKey) => {
    const filterValue = filters[entity][filterKey].toString(query.filters[filterKey]);

    if (filterValue) queryParams.append(filterKey, filterValue);
  });

  const stringQuery = queryParams.toString();

  return stringQuery ? `?${stringQuery}` : '';
};

/**
 * Transform Redux Search Object to Api Object
 *
 * @param {string} entity - Meta entity name
 * @param {number} page - Current or target page
 * @param {object} query - Router Query Object
 * @return {object} - Api Object
 */
export const reduxObjectToApiObject = ({ entity, page, query, tagCategories }) => {
  const keyMap = {
    versions: 'version',
    tenants: 'tenant',
    catalogs: 'catalog',
    albums: 'album',
    tempos: 'tempo',
    tonality_keys: 'tonality_key',
    showcases: 'showcase',
    album_types: 'album_type',
    year: 'release_date',
  };

  let similarity;
  const nextfilters = { ...query.filters };

  Object.entries(nextfilters).forEach(([key, value]) => {
    const multi = !!filters[entity][key].isMultiArrayValue;
    const hasName = !!filters[entity][key].isArrayWithName;

    // Map keys
    if (keyMap[key]) {
      nextfilters[keyMap[key]] = value;
      delete nextfilters[key];

      // eslint-disable-next-line no-param-reassign
      key = keyMap[key];
    }

    // For value like ["ID", "document name"] transform to "ID"
    // For value like [["ID", "document name"]] transform to ["ID"]
    if (hasName) {
      if (multi) {
        nextfilters[key] = value.map(v => (_isArray(v) ? v[0] : v));
      } else {
        // eslint-disable-next-line prefer-destructuring
        nextfilters[key] = _isArray(value) ? value[0] : value;
      }
    }

    switch (key) {
      case 'agents':
        nextfilters.public = true;
        break;

      case 'agents_not': {
        delete nextfilters[key];

        if (!nextfilters.or) {
          nextfilters.or = [];
        }

        let exclusionKey = key.replace('_not', '');
        if (keyMap[exclusionKey]) exclusionKey = keyMap[exclusionKey];

        nextfilters.or.push({
          nor: value.map(v => ({ [exclusionKey]: v[0] })),
        });

        nextfilters.or.push({
          or: value.map(v => ({ [exclusionKey]: v[0], public: false })),
        });

        break;
      }

      case 'ingestion_type_not':
      case 'artist_type_not':
      case 'type_not': // Pitching
      case 'music_type_not': {
        delete nextfilters[key];

        if (!nextfilters.nor) {
          nextfilters.nor = [];
        }

        let exclusionKey = key.replace('_not', '');
        if (keyMap[exclusionKey]) exclusionKey = keyMap[exclusionKey];

        nextfilters.nor.push({ [exclusionKey]: value });
        break;
      }

      case 'tenants_not':
      case 'created_by_not':
      case 'showcases_not':
      case 'artists_not':
      case 'catalogs_not':
      case 'publishers_not':
      case 'labels_not':
      case 'internal_refs_not':
      case 'albums_not': {
        delete nextfilters[key];

        if (!nextfilters.nor) {
          nextfilters.nor = [];
        }

        let exclusionKey = key.replace('_not', '');
        if (keyMap[exclusionKey]) exclusionKey = keyMap[exclusionKey];

        nextfilters.nor.push(...value.map(v => ({
          [exclusionKey]: v[0],
        })));
        break;
      }

      case 'album_types_not':
      case 'tonality_keys_not':
      case 'tempos_not':
      case 'versions_not': {
        delete nextfilters[key];

        if (!nextfilters.nor) {
          nextfilters.nor = [];
        }

        let exclusionKey = key.replace('_not', '');
        if (keyMap[exclusionKey]) exclusionKey = keyMap[exclusionKey];

        nextfilters.nor.push(...value.map(v => ({
          [exclusionKey]: v,
        })));
        break;
      }

      // Track similarity
      case 'track_id':
      case 'search_id':
        delete nextfilters[key];

        similarity = {
          [key.replace('_id', '')]: value[0],
        };
        break;

      case 'tags':
        delete nextfilters.tags;

        if (!nextfilters.and) {
          nextfilters.and = [];
        }

        nextfilters.and.push(...value.map(v => ({ tags: v })));
        break;

      case 'tags_or':
        delete nextfilters.tags_or;

        if (!nextfilters.and) {
          nextfilters.and = [];
        }

        nextfilters.and.push(...value.map(v => ({ tag_categories: v })));
        break;

      case 'tags_not':
        delete nextfilters.tags_not;

        if (!nextfilters.nand) {
          nextfilters.nand = [];
        }

        nextfilters.nand.push(...value.map(v => ({
          [tagCategories.indexOf(v) !== -1 ? 'tag_categories' : 'tags']: v,
        })));
        break;

      case 'grouping':
        delete nextfilters.grouping;

        if (!nextfilters.and) {
          nextfilters.and = [];
        }

        nextfilters.and.push(...value.map(v => ({ grouping: v })));
        break;

      case 'grouping_not':
        delete nextfilters.grouping_not;

        if (!nextfilters.nand) {
          nextfilters.nand = [];
        }

        nextfilters.nand.push(...value.map(v => ({ grouping: v })));
        break;

      case 'bpm':
        if (nextfilters.bpm.length) {
          const nextValue = {};

          nextValue.gte = +nextfilters.bpm[0];
          if (nextfilters.bpm[1]) nextValue.lte = +nextfilters.bpm[1];

          nextfilters.bpm = nextValue;
        }
        break;

      case 'release_date':
        if (nextfilters.release_date.length) {
          const nextValue = {};

          nextValue.gte = new Date(+nextfilters.release_date[0], 0, 1);
          if (nextfilters.release_date[1]) {
            nextValue.lte = new Date(+nextfilters.release_date[1], 0, 1);
          }

          nextfilters.release_date = nextValue;
        }
        break;

      case 'duration':
        if (nextfilters.duration.length) {
          const nextValue = {};

          nextValue.gte = +nextfilters.duration[0];
          if (nextfilters.duration[1]) nextValue.lte = +nextfilters.duration[1];

          nextfilters.duration = nextValue;
        }
        break;

      default:
    }
  });

  return {
    query: query.search || '',
    filters: nextfilters,
    similarity,
    page,
    size: 20,
    ...(query.sort ? { sort: {
      key: query.sort,
      direction: +query.sort_direction,
    } } : {}),
  };
};

/**
 * Clean Redux Search Object before updating store
 *
 * @param {string} entity - Meta entity name
 * @param {object} query - Redux Search Object
 * @return {object} - Redux Search Object
 */
export const cleanReduxQueryObjectForUpdate = (entity, query) => {
  // Build base query first
  const transformedQuery = {
    ..._omitBy(
      _pick(query, reduxObjectKeys),
      _isUndefined,
    ),
    filters: {},
  };

  // Build filters
  Object.keys(filters[entity]).forEach((filterKey) => {
    const filterValue = query.filters[filterKey];

    if (
      // Not empty array
      (_isArray(filterValue) && filterValue.length)
      // Not empty string
      || (typeof filterValue === 'string' && filterValue.length)
      // Boolean
      || _isBoolean(filterValue)
    ) {
      transformedQuery.filters[filterKey] = filterValue;
    }
  });

  return transformedQuery;
};
