// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import _find from 'lodash/find';

import { withTranslation } from '../../../config/i18n';

import Input from '../inputs/input';
import TextAreaInput from '../inputs/textAreaInput';

import * as pth from '../../../helpers/proptypes';

// =============================
// Component
// =============================

const TextEditor = dynamic(
  () => import('@mewo/components/dist/client').then(mod => mod.TextEditor),
  { ssr: false },
);

function getInputValue(translations, locale) {
  const result = _find(translations, t => t.locale === locale) || {};
  return result.value || '';
}

class InputForLanguage extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: pth.descriptions.isRequired,
    locale: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf(['classic', 'textarea', 'texteditor']),
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onChange: () => {},
    inputType: 'classic',
  };

  handleChangeInput = (inputValue) => {
    const { onChange, value: translations, locale } = this.props;

    const isIncluded = translations.some(v => v.locale === locale);

    if (isIncluded && inputValue) {
      onChange(
        translations.map((t) => {
          if (t.locale === locale) {
            return {
              ...t,
              value: inputValue,
            };
          }
          return t;
        }),
      );
    } else if (isIncluded && !inputValue) {
      onChange(translations.filter(t => t.locale !== locale));
    } else if (!isIncluded && inputValue) {
      onChange([...translations, { locale, value: inputValue }]);
    }
  };

  render() {
    const { value: translations, locale, inputType, t, ...rest } = this.props;

    let InputComponent = Input;
    if (inputType === 'textarea') InputComponent = TextAreaInput;
    if (inputType === 'texteditor') InputComponent = TextEditor;

    return (
      <InputComponent
        {...rest}
        onChange={this.handleChangeInput}
        value={getInputValue(translations, locale)}
        lang={{
          Label: {
            optional: t('components:mewo_input.Label.optional'),
          },
        }}
      />
    );
  }
}

export default withTranslation(['components'])(InputForLanguage);
