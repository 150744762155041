// =============================
// Imports
// =============================

import _get from 'lodash/get';

import * as renderersType from '../entities/renderers/types';

import { required, mustBeEmail } from '../../../../validators';

// =============================
// Config
// =============================

const config = {
  columns: [
    {
      key: 'full_name',
      renderComponent: {
        type: renderersType.TEXT,
        validator: required,
        isPanelOpener: true,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      immutableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'email',
      renderComponent: {
        type: renderersType.TEXT,
        validator: mustBeEmail,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'company_name',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'job',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'phone_number',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'secondary_phone_number',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'created_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'updated_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
  ],
  gridModifyPick: data => ({
    full_name: _get(data, 'full_name', ''),
    address: _get(data, 'address', ''),
    email: _get(data, 'email', ''),
    company_name: _get(data, 'company_name', ''),
    job: _get(data, 'job', ''),
    phone_number: _get(data, 'phone_number', ''),
    secondary_phone_number: _get(data, 'secondary_phone_number', ''),
  }),
};

export default config;
