// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Div } from '@mewo/components';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

// Actions
import { panelActions } from '../../../../store/actions/SidePanelActions';
import { sendUserRequest as sendUserRequestBase } from '../../../../store/actions/UserActions';

// Constants
import { MODO_USER_PANEL } from '../../../../store/constants/SidePanelTypes';

// Components
import { withTranslation } from '../../../../config/i18n';
import BaseSidePanel from '../../../faac/panels/base';
import Autosave from '../../../other/autosave';
import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import SwitchInput from '../../../presentationals/inputs/switchInput';
import DocumentStatus from '../documentStatus';
import CopyLink from '../copyLink';
import Stats from './stats';
import SelectInput from '../../../presentationals/inputs/selectInput';

// Helpers
import { getNameWithFallback } from '../../../../helpers/doc-names';
import { required } from '../../../../validators';
import { getSiteOrigin } from '../../../../helpers/misc';
import * as globalPth from '../../../../helpers/proptypes';
import { formatDateString } from '../../../../helpers/dates';
import * as pth from './proptypes';

// Styles
import { StyledButton, InputWrapper, InputDouble, InputTriple } from '../../global.styles';
import {
  LeftColumn,
  RightColumn,
  DocumentBody,
  SectionTitle,
  SectionNotice,
  SectionBox,
  DocumentName,
  DocumentCreatedAt,
  GenericCover,
  TabLinksWrapper,
  TabLink,
  TabElement,
} from '../common.styles';

// =============================
// Component
// =============================

class ModoUser extends Component {
  static propTypes = {
    panel: PropTypes.shape({
      data: globalPth.modoUser,
      additionalData: PropTypes.shape({
        'stats-search': pth.statsSearch,
        'stats-search-tags': pth.statsSearchTags,
        'stats-search-strings': pth.statsSearchStrings,
        'stats-search-maia': pth.statsSearchMaia,
        'stats-downloads': pth.statsDownload,
        'stats-plays': pth.statsPlays,
      }),
      isModifying: PropTypes.bool.isRequired,
      isDeleting: PropTypes.bool.isRequired,
      'isLoading-stats-downloads': PropTypes.bool,
      'isLoading-stats-plays': PropTypes.bool,
      'isLoading-stats-search': PropTypes.bool,
      'isLoading-stats-search-maia': PropTypes.bool,
      'isLoading-stats-search-strings': PropTypes.bool,
      'isLoading-stats-search-tags': PropTypes.bool,
    }).isRequired,
    localPreferences: globalPth.localPreferences.isRequired,
    modoPrivate: PropTypes.bool.isRequired,
    modoActive: PropTypes.bool.isRequired,
    statsInPlan: PropTypes.bool.isRequired,
    sendUserRequest: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
    territories: PropTypes.arrayOf(globalPth.territory.isRequired).isRequired,
    userIndustries: PropTypes.arrayOf(globalPth.userIndustry).isRequired,
  };

  pickFormData = (data, init = false) => {
    const keys = [
      'first_name',
      'last_name',
      'phone_number',
      'country',
      'company_name',
      'can_download',
      'can_download_stems',
      'can_access',
    ];

    if (init) {
      keys.push('email');
    } else {
      keys.push('industry');
    }

    if (init || data.verified) {
      keys.push('verified');
    }

    const doc = _pick(data, keys);

    if (data.industry && init) {
      doc.industry = data.industry.id;
    }

    return doc;
  };

  renderPanelAnchors = () => {
    const { t } = this.props;

    return (
      <TabLinksWrapper>
        <TabLink name="general_information">
          {t('pages:modo.users_panel.general_information_anchor')}
        </TabLink>
        <TabLink name="user_analytics">
          {t('pages:modo.users_panel.user_analytics_anchor')}
        </TabLink>
      </TabLinksWrapper>
    );
  };

  render() {
    const {
      panel,
      i18n: { language },
      localPreferences,
      modoActive,
      modoPrivate,
      statsInPlan,
      sendUserRequest,
      t,
      territories,
      userIndustries,
    } = this.props;
    const { data, additionalData, isModifying, isDeleting } = panel;

    const statsAreEnabled = statsInPlan && modoActive;

    const additionalList = statsAreEnabled
      ? [
        ['stats/plays', 'stats-plays', 'all'],
        ['stats/downloads', 'stats-downloads', 'all'],
        ['stats/search', 'stats-search'],
        ['stats/search/strings', 'stats-search-strings', 'all'],
        ['stats/search/maia', 'stats-search-maia', 'all'],
        ['stats/search/tags', 'stats-search-tags', 'all'],
        ['stats/last/played', 'stats-last-played'],
        ['stats/last/downloaded', 'stats-last-downloaded'],
      ]
      : [];

    // NOTE: We can use window location because this panel cannot
    // open another type of panel
    const shareLink = (typeof window !== 'undefined' && _get(data, 'id'))
      ? `${getSiteOrigin()}${window.location.pathname}?panel=${data.id}`
      : '';

    return (
      <BaseSidePanel
        {...this.props}
        get={panelActions[MODO_USER_PANEL].get}
        getAdditionalOptions={{
          fnc: panelActions[MODO_USER_PANEL].getAdditional,
          list: additionalList,
        }}
        modify={panelActions[MODO_USER_PANEL].modify}
        del={panelActions[MODO_USER_PANEL].delete}
      >
        {({ getAdditional, modifyDocument, deleteDocument }) => !!data && (
        <Fragment>
          <LeftColumn>
            <GenericCover color={_get(data, 'avatar_color', '')}>
              {_get(data, 'first_name', '').substr(0, 1).toUpperCase()}
              {_get(data, 'last_name', '').substr(0, 1).toUpperCase()}
            </GenericCover>
            <DocumentName>
              {`${_get(data, 'first_name', '')} ${_get(data, 'last_name', '')}`}
            </DocumentName>
            <DocumentCreatedAt>
              {t('pages:modo.users_panel.user_since')}
              :&nbsp;
              {formatDateString(data.created_at, localPreferences.dateFormat, true)}
            </DocumentCreatedAt>
            <CopyLink
              link={shareLink}
              content={t('pages:modo.users_panel.copy_share_link')}
              tooltipContent={t('pages:modo.users_panel.copy_share_link_tooltip')}
            />
          </LeftColumn>
          <RightColumn initTab="general_information">
            <DocumentStatus
              isModifying={isModifying}
              isDeleting={isDeleting}
              localPreferences={localPreferences}
              documentRights={{ isOwner: true }}
              updatedAt={_get(data, 'updated_at')}
              deleteDocument={deleteDocument}
            />
            {this.renderPanelAnchors()}
            <DocumentBody>
              <TabElement name="general_information">
                <SectionTitle>{t('pages:modo.users_panel.general_information')}</SectionTitle>
                <SectionNotice>
                  {t('pages:modo.users_panel.general_information_notice')}
                </SectionNotice>
                <SectionBox>
                  <Form
                    onSubmit={() => {}}
                    initialValues={this.pickFormData(data, true)}
                    subscription={{}}
                    keepDirtyOnReinitialize
                  >
                    {() => (
                      <Fragment>
                        <Autosave
                          debounce={3000}
                          save={modifyDocument}
                          nullValuesTransformer={this.pickFormData}
                          autosavePanelId={`${MODO_USER_PANEL}_${data.id}`}
                        />
                        <InputWrapper>
                          <Field
                            name="email"
                            component={MewoInput}
                            use={Input}
                            label={t('common:form.email')}
                            type="text"
                            validate={required}
                            disabled
                          />
                          <InputDouble>
                            <Field
                              name="first_name"
                              component={MewoInput}
                              use={Input}
                              label={t('common:form.first_name')}
                              type="text"
                              validate={required}
                            />
                            <Field
                              name="last_name"
                              component={MewoInput}
                              use={Input}
                              label={t('common:form.last_name')}
                              type="text"
                              validate={required}
                            />
                          </InputDouble>
                          <InputDouble>
                            <Field
                              name="phone_number"
                              component={MewoInput}
                              use={Input}
                              label={t('common:form.phone_number')}
                              type="text"
                              validate={required}
                            />
                            <Field
                              name="country"
                              component={MewoInput}
                              use={SelectInput}
                              type="text"
                              label={t('common:form.country')}
                              options={territories.map(({ code, name }) => ({
                                label: name,
                                value: code,
                              }))}
                              searchable
                            />
                          </InputDouble>
                          <InputDouble>
                            <Field
                              name="company_name"
                              component={MewoInput}
                              use={Input}
                              label={t('common:form.company_name')}
                              type="text"
                            />
                            <Field
                              name="industry"
                              component={MewoInput}
                              use={SelectInput}
                              type="text"
                              label={t('common:form.industry')}
                              options={userIndustries.map(userIndustry => ({
                                value: userIndustry.id,
                                label: getNameWithFallback(userIndustry.names, language),
                              }))}
                              searchable
                            />
                          </InputDouble>
                          <InputTriple>
                            <Field
                              name="can_download"
                              component={MewoInput}
                              use={SwitchInput}
                              type="boolean"
                              context="autosave"
                              label={t('common:misc.download')}
                            />
                            <Field
                              name="can_download_stems"
                              component={MewoInput}
                              use={SwitchInput}
                              type="boolean"
                              context="autosave"
                              label={t('common:misc.download_stems')}
                            />
                            <Field
                              name="verified"
                              component={MewoInput}
                              use={SwitchInput}
                              type="boolean"
                              context="autosave"
                              disabled={data.verified}
                              label={t('common:misc.verified_account')}
                            />
                          </InputTriple>
                          {modoPrivate && (
                            <Field
                              name="can_access"
                              component={MewoInput}
                              use={SwitchInput}
                              type="boolean"
                              context="autosave"
                              label={t('common:misc.access')}
                            />
                          )}
                        </InputWrapper>
                      </Fragment>
                    )}
                  </Form>
                </SectionBox>
              </TabElement>

              <TabElement name="user_analytics">
                <SectionTitle>{t('pages:modo.users_panel.user_analytics')}</SectionTitle>
                <SectionNotice>{t('pages:modo.users_panel.user_analytics_notice')}</SectionNotice>
                {!statsAreEnabled && (
                  <Fragment>
                    <Div mb="1.5rem">
                      {!modoActive && t('errors:modo.stats.modo_not_active')}
                      {modoActive && !statsAreEnabled && t('errors:modo.stats.not_in_plan')}
                    </Div>
                    {modoActive && (
                      <StyledButton
                        onClick={() => sendUserRequest({ context: 'modo_stats', description: 'not_activated' })}
                      >
                        {t('components:premium_modal.button')}
                      </StyledButton>
                    )}
                  </Fragment>
                )}
                {statsAreEnabled && (
                  <Stats panel={panel} getAdditional={getAdditional} data={additionalData} />
                )}
              </TabElement>
            </DocumentBody>
          </RightColumn>
        </Fragment>
        )}
      </BaseSidePanel>
    );
  }
}

function mapStateToProps({ core, modo, options }) {
  return {
    localPreferences: core.localPreferences,
    modoPrivate: _get(modo, 'config.fullData.private_access', false),
    modoActive: _get(modo, 'config.activeData.active', false),
    statsInPlan: _get(modo, 'config.fullData.permissions.USER_STATS.active', false),
    userIndustries: options.data.userindustries,
    territories: options.data.territories,
  };
}

export default compose(
  withTranslation(['common', 'pages', 'components', 'errors']),
  connect(mapStateToProps, { sendUserRequest: sendUserRequestBase }),
)(ModoUser);
