// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';
import * as states from '../constants/PlayerStates';
import * as requests from '../constants/PlayerRequests';

// =============================
// Initial state
// =============================

const initialState = {
  /* Player */
  isOpened: false,
  getCurrentTime: null,
  previousPlayerState: null,
  playerState: states.STOPPED,
  request: {
    [requests.PLAY]: false,
    [requests.PAUSE]: false,
    [requests.SEEK]: false,
    [requests.PREV_TRACK]: false,
    [requests.NEXT_TRACK]: false,
  },
  /* Media */
  current: null,
  trackListing: [],
  trackId: null,
  contextName: null,
  contextId: null,
  contextPosition: null,
  isFetchingAlbumTracks: false,
  isFetchingPlaylistTracks: false,
  isFetchingBriefTracks: false,
};

// =============================
// Reducer
// =============================

export default function player(state = initialState, action) {
  switch (action.type) {
    // =============================
    // Player actions
    // =============================
    case acts.OPEN_PLAYER:
      return {
        ...state,
        isOpened: true,
      };

    case acts.CLOSE_PLAYER:
      return {
        ...state,
        isOpened: false,
      };

    case acts.FULFILL_PLAYER_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          [action.payload]: false,
        },
      };

    case acts.SET_PLAYER_STATE:
      return {
        ...state,
        previousPlayerState: state.playerState,
        playerState: action.payload,
      };

    case acts.SET_PLAYER_GET_CURRENT_TIME_FNC:
      return {
        ...state,
        getCurrentTime: action.payload,
      };

    case acts.PLAYER_REQUEST_PLAY:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PLAY]: true,
        },
      };

    case acts.PLAYER_REQUEST_PAUSE:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PAUSE]: true,
        },
      };

    case acts.PLAYER_REQUEST_SET_SEEKING_TIME:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.SEEK]: action.payload,
        },
      };

    case acts.PLAYER_REQUEST_GO_TO_PREV_TRACK:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PREV_TRACK]: true,
        },
      };

    case acts.PLAYER_REQUEST_GO_TO_NEXT_TRACK:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.NEXT_TRACK]: true,
        },
      };

    // =============================
    // Media actions
    // =============================
    case acts.SET_PLAYER_CURRENT_TRACK:
      return {
        ...state,
        ...action.payload,
      };

    case acts.GET_AND_SET_ALBUM_TRACKS_LOADING:
      return {
        ...state,
        isFetchingAlbumTracks: true,
      };

    case acts.GET_AND_SET_ALBUM_TRACKS_SUCCESS:
    case acts.GET_AND_SET_ALBUM_TRACKS_FAILURE:
      return {
        ...state,
        isFetchingAlbumTracks: false,
      };

    case acts.GET_AND_SET_PLAYLIST_TRACKS_LOADING:
      return {
        ...state,
        isFetchingPlaylistTracks: true,
      };

    case acts.GET_AND_SET_PLAYLIST_TRACKS_SUCCESS:
    case acts.GET_AND_SET_PLAYLIST_TRACKS_FAILURE:
      return {
        ...state,
        isFetchingPlaylistTracks: false,
      };

    case acts.GET_AND_SET_BRIEF_TRACKS_LOADING:
      return {
        ...state,
        isFetchingBriefTracks: true,
      };

    case acts.GET_AND_SET_BRIEF_TRACKS_SUCCESS:
    case acts.GET_AND_SET_BRIEF_TRACKS_FAILURE:
      return {
        ...state,
        isFetchingBriefTracks: false,
      };

    // =============================
    // Global actions
    // =============================
    case acts.REINITIALIZE_PLAYER:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
