// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Flex, Div } from '@mewo/components';
import { Field, Form } from 'react-final-form';

import MewoInput from '../mewoInput';
import Input from '../inputs/input';

import { composeValidators, maxNumber, minNumber, mustBeNumber } from '../../../validators';

import { GoToPageButton, PgnButton, StyledArrowSingle, StyledArrowDouble } from './styles';

// =============================
// Component
// =============================

class Pagination extends PureComponent {
  static propTypes = {
    /** Props used by styled component to override styles. */
    className: PropTypes.string,
    /** Current page */
    currentPage: PropTypes.number.isRequired,
    /** Total number of pages */
    pageCount: PropTypes.number.isRequired,
    /** Function to change page */
    changeSearchPage: PropTypes.func.isRequired,
    /** Disable paginating */
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    disabled: false,
  };

  getDisplayPageNumber = pageNb => pageNb + 1;

  changePage = (...args) => {
    const { disabled, changeSearchPage } = this.props;

    if (!disabled) changeSearchPage(...args);
  };

  render() {
    const { className, currentPage, pageCount, disabled } = this.props;

    // If pageCount is 1, do not render anything
    if (pageCount <= 1) return null;

    const nbPageShowed = 2; // Number of page showed before & after currentage

    // Check if the page is far from beggining & end
    const farFromBeginning = currentPage - nbPageShowed >= 1;
    const farFromEnd = pageCount - 1 - nbPageShowed > currentPage;

    // Render Previous page from current one
    const prevPages = [];
    for (let i = 1; i <= nbPageShowed; i += 1) {
      if (currentPage - i >= 0) {
        prevPages.unshift(
          <PgnButton
            isAccessible={!disabled}
            key={currentPage - i}
            onClick={() => {
              this.changePage(currentPage - i);
            }}
          >
            {this.getDisplayPageNumber(currentPage - i)}
          </PgnButton>,
        );
      }
    }

    // Render Next page from current one
    const nextPages = [];
    for (let i = 1; i <= nbPageShowed; i += 1) {
      if (currentPage + i <= pageCount - 1) {
        nextPages.push(
          <PgnButton
            isAccessible={!disabled}
            key={currentPage + i}
            onClick={() => {
              this.changePage(currentPage + i);
            }}
          >
            {this.getDisplayPageNumber(currentPage + i)}
          </PgnButton>,
        );
      }
    }

    return (
      <Flex className={className} justify="center" align="center">
        <PgnButton
          isAccessible={currentPage > 0 && !disabled}
          onClick={() => {
            this.changePage(0);
          }}
        >
          <StyledArrowDouble dir="west" />
        </PgnButton>
        <PgnButton
          isAccessible={currentPage > 0 && !disabled}
          onClick={() => {
            this.changePage(currentPage - 1);
          }}
        >
          <StyledArrowSingle dir="west" />
        </PgnButton>
        {farFromBeginning && (
          <Flex>
            {currentPage - nbPageShowed > 1 && <PgnButton isAccessible={false}>...</PgnButton>}
          </Flex>
        )}

        {prevPages}
        <PgnButton active isAccessible={!disabled}>
          {this.getDisplayPageNumber(currentPage)}
        </PgnButton>
        {nextPages}

        {farFromEnd && (
          <Flex>
            {pageCount - 1 - (nbPageShowed + 1) !== currentPage && (
              <PgnButton isAccessible={false}>...</PgnButton>
            )}
          </Flex>
        )}
        <PgnButton
          isAccessible={currentPage < pageCount - 1 && !disabled}
          onClick={() => {
            this.changePage(currentPage + 1);
          }}
        >
          <StyledArrowSingle dir="east" />
        </PgnButton>
        <PgnButton
          isAccessible={currentPage < pageCount - 1 && !disabled}
          onClick={() => {
            this.changePage(pageCount - 1);
          }}
        >
          <StyledArrowDouble dir="east" />
        </PgnButton>
        {pageCount > 5 && (
          <Form onSubmit={({ page }) => this.changePage(page ? page - 1 : 0)}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Div width="10rem" ml="1rem">
                  <Field
                    name="page"
                    component={MewoInput}
                    use={Input}
                    type="number"
                    placeholder="#"
                    validate={composeValidators(
                      mustBeNumber,
                      minNumber(1),
                      maxNumber(pageCount),
                    )}
                  />
                  <GoToPageButton type="submit" disabled={disabled} fullWidth />
                </Div>
              </form>
            )}
          </Form>
        )}
      </Flex>
    );
  }
}

export default Pagination;
