// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Button, Icons } from '@mewo/components';

import { svgHoverCirclePath } from '../../../../../helpers/svg';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const OuterGridWrapper = styled.div`
  width: 100%;
`;

export const GridWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  {/* Title / Version / Duration / Tenant */}
  grid-template-columns: 2fr 1fr 5rem 1fr;
  width: 100%;
`;

export const CoverWrapper = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 2rem;
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const PlayIcon = styled(Icons.PlayOutline).attrs({
  width: '3rem',
})`
  ${svgHoverCirclePath('#fff', '#7f7f7f')};
`;

export const PauseIcon = styled(Icons.PauseOutline).attrs({
  width: '3rem',
})`
  ${svgHoverCirclePath('#fff', '#7f7f7f')};
`;

export const Cover = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ placeholder, cover }) => placeholder
  && !cover
  && css`
      background-image: url(${placeholder});
    `};

  ${({ cover }) => cover
  && css`
      background-image: url(${cover});
    `};
`;

export const ToggleBulkButton = styled(Button).attrs({
  size: 'small',
})`
  margin-bottom: 2rem;
  margin-left: auto;
  position: absolute;
  top: 3.2rem;
  right: 0;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;

  &:hover {
    opacity: 0.7;
  }
`;

export const DisplayArtists = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  width: 100%;
  margin-top: 0.7rem;
`;

export const DisplayArtist = styled.div`
  display: inline;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const VersionSelectWrapper = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
`;

export const Version = styled.div`
  align-items: center;
  display: flex;

  ${({ disabled }) => disabled
    && css`
      opacity: 0.7;
      pointer-events: none;
    `};
`;

export const VersionName = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  min-width: 0;
`;

export const DropdownIndicator = styled(Icons.ArrowSingle).attrs(({ opened }) => ({
  dir: opened ? 'north' : 'south',
}))`
  margin-left: 0.8rem;
`;

export const Duration = styled.div``;

export const Tenant = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const DeleteButton = styled(Button).attrs({
  color: 'error',
  fillVariant: 'ghost',
  size: 'small',
})`
  margin-left: 1rem;
`;
