// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _some from 'lodash/some';
import _find from 'lodash/find';

// Config
import metaEntities from '../../../config/meta-entities';

// Helpers
import * as searchHelpers from '../../../helpers/meta-search';

// =============================
// Component
// =============================

class WithMetaSearchRoute extends Component {
  static propTypes = {
    /** Children as function */
    children: PropTypes.func.isRequired,
    /** Meta entity target for search */
    entity: PropTypes.oneOf(metaEntities).isRequired,
    /** Filters to be added to current search query */
    // eslint-disable-next-line react/forbid-prop-types
    filters: PropTypes.object,
    /** Filters to be removed from current search query */
    // eslint-disable-next-line react/forbid-prop-types
    filtersOut: PropTypes.object,
    /** Asserts if the generated route should keep current page of search state */
    stayOnPage: PropTypes.bool,
    /** Asserts if the generated route should reset current query of search state */
    resetQuery: PropTypes.bool,
    /** Asserts if the generated route should completely replace passed filters */
    resetFilters: PropTypes.bool,
    /** Asserts if the generated route should reset all filters of search state */
    resetAllFilters: PropTypes.bool,
    /** Current search page for target entity */
    searchPage: PropTypes.number,
    /** Current search query for target entity */
    // eslint-disable-next-line react/forbid-prop-types
    searchQuery: PropTypes.object.isRequired,
  };

  static defaultProps = {
    filters: {},
    filtersOut: {},
    searchPage: null,
    stayOnPage: false,
    resetQuery: false,
    resetFilters: false,
    resetAllFilters: false,
  };

  render() {
    const {
      children,
      entity,
      filters,
      filtersOut,
      stayOnPage,
      resetQuery,
      resetFilters,
      resetAllFilters,
      searchPage,
      searchQuery,
    } = this.props;

    const nextPage = stayOnPage ? searchPage : 0;

    const nextFilters = {};

    // Some filters are array of arrays, we need to check if we need to
    // add the target filters to those arrays, or reset them
    Object.keys(filters).forEach((filterKey) => {
      const { isMultiArrayValue } = searchHelpers.filters[entity][filterKey];

      if (isMultiArrayValue) {
        if (!resetFilters && !resetAllFilters) {
          // If we do not need to reset the filters
          nextFilters[filterKey] = [
            // We look for all prev filters that are not equal to target filter
            ..._get(searchQuery.filters, filterKey, [])
              .filter(prevFilter => !_some(
                filters[filterKey],
                nextFilter => _isEqual(nextFilter, prevFilter),
              )),
            // And add our target filters to that list
            ...filters[filterKey],
          ].sort();
        } else {
          // If we need to reset the filters it means that we can create
          // a new array of arrays with the target filter as the only item.
          // This means that you should send a value that can totally replace
          // the old value in the `filters` prop
          nextFilters[filterKey] = filters[filterKey].sort();
        }
      } else {
        nextFilters[filterKey] = filters[filterKey];
      }
    });

    const nextQuery = {
      ...(!resetQuery ? searchQuery : {}),
      filters: {
        ...(!resetAllFilters ? searchQuery.filters : {}),
        ...nextFilters,
      },
    };

    // Filter out values
    Object.keys(filtersOut).forEach((filterKey) => {
      const { isMultiArrayValue } = searchHelpers.filters[entity][filterKey];

      if (isMultiArrayValue && nextQuery.filters[filterKey]) {
        nextQuery.filters[filterKey] = nextQuery.filters[filterKey]
          .filter(filterValue => !_find(
            filtersOut[filterKey], filterValueOut => _isEqual(filterValueOut, filterValue),
          ));
      } else if (nextQuery.filters[filterKey]) {
        delete nextQuery.filters[filterKey];
      }
    });

    const query = searchHelpers.reduxObjectToQueryString(entity, nextPage, nextQuery);

    return children(query);
  }
}

function mapStateToProps({ meta }, { entity }) {
  return {
    searchQuery: meta[`${entity}s`].query,
    searchPage: meta[`${entity}s`].page,
  };
}

export default connect(mapStateToProps)(WithMetaSearchRoute);
