// =============================
// Imports
// =============================

// External dependencies
import Router from 'next/router';
import _get from 'lodash/get';

// Actions
import {
  refreshMetaCatalogs,
  refreshMetaPlaylists,
  refreshMetaAlbums,
  refreshMetaTracks,
} from '../actions/MetaActions';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import * as searchHelpers from '../../helpers/meta-search';

// =============================
// Middleware
// =============================

/** Mapping between Meta entity name and functions for bulk update refresh */
const entityConfigMapping = {
  catalog: {
    refreshFnc: refreshMetaCatalogs,
  },
  playlist: {
    refreshFnc: refreshMetaPlaylists,
  },
  album: {
    refreshFnc: refreshMetaAlbums,
  },
  track: {
    refreshFnc: refreshMetaTracks,
  },
};

const metaMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.LAUNCH_SIMILARITY_SEARCH_SUCCESS: {
      const query = searchHelpers.reduxObjectToQueryString('track', 0, {
        filters: {
          search_id: [action.payload.value, action.payload.displayValue],
        },
      });

      Router.push(`/meta/tracks${query}`);

      return next(action);
    }

    case action.type === acts.UPDATE_META_BULK_SUCCESS: {
      const entityConfig = entityConfigMapping[action.payload.entity];
      const entityStore = store.getState().meta[`${action.payload.entity}s`];

      const docIds = _get(entityStore, 'data', []).map(doc => doc.id);

      if (docIds.length) {
        store.dispatch(entityConfig.refreshFnc(docIds));
      }

      return next(action);
    }

    default:
      return next(action);
  }
};

export default metaMiddleware;
