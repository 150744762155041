// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { StyledFade } from './styles';

// =============================
// Component
// =============================

class Fade extends Component {
  static propTypes = {
    /** Props used by styled component to override styles */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, ...props } = this.props;

    return <StyledFade className={className} {...props} />;
  }
}

export default Fade;
