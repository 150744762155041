// =============================
// Imports
// =============================

// External dependencies
import Router from 'next/router';
import _get from 'lodash/get';
import _some from 'lodash/some';

// Actions
import { triggerAutosaveCheck } from '../actions/SidePanelActions';
import { getModoUsers, refreshModoUsers } from '../actions/ModoActions';
import {
  getMetaAlbums,
  getMetaArtists,
  getMetaCatalogs,
  getMetaIngestions,
  getMetaLabels,
  getMetaPeoples,
  getMetaPlaylists,
  getMetaPitchs,
  getMetaBriefs,
  getMetaPublishers,
  getMetaTracks,
  refreshMetaAlbums,
  refreshMetaArtists,
  refreshMetaCatalogs,
  refreshMetaIngestions,
  refreshMetaLabels,
  refreshMetaPeoples,
  refreshMetaPlaylists,
  refreshMetaPitchs,
  refreshMetaBriefs,
  refreshMetaPublishers,
  refreshMetaTracks,
} from '../actions/MetaActions';

// Constants
import * as acts from '../constants/ActionTypes';
import * as spts from '../constants/SidePanelTypes';

// Helpers
import * as searchHelpers from '../../helpers/search';
import * as metaSearchHelpers from '../../helpers/meta-search';

// =============================
// Middleware
// =============================

/** Mapping between SidePanelTypes and Meta entity name */
const entityConfigMapping = {
  [spts.META_PEOPLE_PANEL]: {
    entity: 'people',
    fetchFnc: getMetaPeoples,
    refreshFnc: refreshMetaPeoples,
  },
  [spts.META_PUBLISHER_PANEL]: {
    entity: 'publisher',
    fetchFnc: getMetaPublishers,
    refreshFnc: refreshMetaPublishers,
  },
  [spts.META_LABEL_PANEL]: {
    entity: 'label',
    fetchFnc: getMetaLabels,
    refreshFnc: refreshMetaLabels,
  },
  [spts.META_ARTIST_PANEL]: {
    entity: 'artist',
    fetchFnc: getMetaArtists,
    refreshFnc: refreshMetaArtists,
  },
  [spts.META_CATALOG_PANEL]: {
    entity: 'catalog',
    fetchFnc: getMetaCatalogs,
    refreshFnc: refreshMetaCatalogs,
  },
  [spts.META_PLAYLIST_PANEL]: {
    entity: 'playlist',
    fetchFnc: getMetaPlaylists,
    refreshFnc: refreshMetaPlaylists,
  },
  [spts.META_PITCH_PANEL]: {
    entity: 'pitch',
    fetchFnc: getMetaPitchs,
    refreshFnc: refreshMetaPitchs,
  },
  [spts.META_BRIEF_PANEL]: {
    entity: 'brief',
    fetchFnc: getMetaBriefs,
    refreshFnc: refreshMetaBriefs,
  },
  [spts.META_ALBUM_PANEL]: {
    entity: 'album',
    fetchFnc: getMetaAlbums,
    refreshFnc: refreshMetaAlbums,
  },
  [spts.META_TRACK_PANEL]: {
    entity: 'track',
    fetchFnc: getMetaTracks,
    refreshFnc: refreshMetaTracks,
  },
  [spts.META_INGESTION_PANEL]: {
    entity: 'ingestion',
    fetchFnc: getMetaIngestions,
    refreshFnc: refreshMetaIngestions,
  },
};

const sidePanelMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.CREATE_META_PITCH_SUCCESS: {
      store.dispatch(triggerAutosaveCheck(
        'open',
        [spts.META_PITCH_PANEL, action.payload.id],
      ));

      // Could optimize by only refreshing current collection
      const playlistsIds = _get(store.getState(), 'meta.playlists.data', []).map(doc => doc.id);
      const albumsIds = _get(store.getState(), 'meta.albums.data', []).map(doc => doc.id);

      if (playlistsIds.length) {
        store.dispatch(refreshMetaPlaylists(playlistsIds));
      }

      if (albumsIds.length) {
        store.dispatch(refreshMetaAlbums(albumsIds));
      }

      return next(action);
    }

    case action.type === acts.MODIFY_SIDE_PANEL_DATA_SUCCESS:
    case action.type === acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS:
    case action.type === acts.DUPLICATE_SIDE_PANEL_DATA_SUCCESS:
    case action.type === acts.UPLOAD_SIDE_PANEL_FILE_SUCCESS:
    case action.type === acts.REQUEST_GRID_REFRESH_FROM_SIDE_PANEL:
    case action.type === acts.AUTOTAG_SIDE_PANEL_SUCCESS:
    case action.type === acts.DELETE_SIDE_PANEL_FILE_SUCCESS:
    case action.type === acts.DELETE_SIDE_PANEL_SUCCESS: {
      const { requestedClose, panels } = store.getState().sidepanel;

      switch (action.payload.type) {
        case spts.META_PEOPLE_PANEL:
        case spts.META_PUBLISHER_PANEL:
        case spts.META_LABEL_PANEL:
        case spts.META_ARTIST_PANEL:
        case spts.META_CATALOG_PANEL:
        case spts.META_PLAYLIST_PANEL:
        case spts.META_PITCH_PANEL:
        case spts.META_BRIEF_PANEL:
        case spts.META_ALBUM_PANEL:
        case spts.META_TRACK_PANEL:
        case spts.META_INGESTION_PANEL: {
          const entityConfig = entityConfigMapping[action.payload.type];
          const entityStore = store.getState().meta[`${entityConfig.entity}s`];

          const refreshPage = [
            acts.MODIFY_SIDE_PANEL_DATA_SUCCESS,
            acts.UPLOAD_SIDE_PANEL_FILE_SUCCESS,
            acts.DELETE_SIDE_PANEL_FILE_SUCCESS,
            acts.REQUEST_GRID_REFRESH_FROM_SIDE_PANEL,
          ].includes(action.type)
            || (
              action.type === acts.AUTOTAG_SIDE_PANEL_SUCCESS
              && !action.payload.deferred
            )
            || (
              action.type === acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS
              && action.payload.additionalDataKey === 'attachment'
            );

          const docIds = _get(entityStore, 'data', []).map(doc => doc.id);

          if (refreshPage && docIds.length) {
            store.dispatch(entityConfig.refreshFnc(docIds));
          }

          if (action.type === acts.DUPLICATE_SIDE_PANEL_DATA_SUCCESS) {
            store.dispatch(
              entityConfig.fetchFnc(
                entityStore.page,
                entityStore.query,
                _get(action.payload, 'refreshInterval', 0),
              ),
            );
          }

          if (action.type === acts.DELETE_SIDE_PANEL_SUCCESS) {
            // If there are no more results in page after deletion
            // go back to first page and remove search query if necessary
            if (
              (entityStore.page !== 0 || entityStore.query.search)
              && _get(entityStore, 'data', []).length === 1
            ) {
              const queryValue = metaSearchHelpers.reduxObjectToQueryString(
                entityConfig.entity,
                0,
                {
                  ...entityStore.query,
                  search: entityStore.page !== 0 ? entityStore.query.search : '',
                },
              );

              Router.push(`/meta/${entityConfig.entity}s${queryValue}`);
            } else {
              store.dispatch(
                entityConfig.fetchFnc(
                  entityStore.page,
                  entityStore.query,
                  _get(action.payload, 'refreshInterval', 0),
                ),
              );
            }
          }

          break;
        }

        case spts.MODO_USER_PANEL: {
          const configId = store.getState().modo.config.id;
          const modoUsers = store.getState().modo.users;

          if (action.type === acts.MODIFY_SIDE_PANEL_DATA_SUCCESS) {
            const modoUsersIds = _get(modoUsers, 'data', []).map(user => user.id);

            store.dispatch(refreshModoUsers(modoUsersIds));
          }

          if (action.type === acts.DELETE_SIDE_PANEL_SUCCESS) {
            // If there are no more results in page after deletion
            // go back to first page and remove search query if necessary
            if (
              (modoUsers.page !== 0 || modoUsers.query.search)
              && _get(modoUsers, 'data', []).length === 1
            ) {
              const queryValue = searchHelpers.reduxObjectToQueryString(0, {
                ...modoUsers.query,
                search: modoUsers.page !== 0 ? modoUsers.query.search : '',
              });

              Router.push(`/modo/[modoId]/users${queryValue}`, `/modo/${configId}/users${queryValue}`);
            } else {
              store.dispatch(
                getModoUsers(
                  modoUsers.page,
                  modoUsers.query,
                  _get(action.payload, 'refreshInterval', 0),
                ),
              );
            }
          }

          break;
        }

        default:
      }

      // Close when request was done
      // Close when deleting a panel and it's the last one
      const closeConditions = [
        requestedClose,
        panels.length <= 1 && action.type === acts.DELETE_SIDE_PANEL_SUCCESS,
      ];

      // Needs all closeConditions to be false
      // Remove when deleting a panel and there are more than one
      const removeConditions = [
        action.type === acts.DELETE_SIDE_PANEL_SUCCESS,
      ];

      if (_some(closeConditions)) {
        store.dispatch({
          type: acts.CLOSE_SIDE_PANEL,
        });

        return next(action);
      }

      if (_some(removeConditions)) {
        store.dispatch({
          type: acts.REMOVE_SIDE_PANEL,
          payload: _get(panels, '0.uuid'),
        });

        return next(action);
      }

      // NOTE: Special condition, if panel is not being closed or removed
      //  and a duplicate action was successful, we open the duplicated document
      if (action.type === acts.DUPLICATE_SIDE_PANEL_DATA_SUCCESS) {
        // Next needs to be called first
        next(action);

        return store.dispatch(triggerAutosaveCheck(
          'open',
          [action.payload.type, action.payload.duplicatedId],
        ));
      }

      return next(action);
    }

    default:
      return next(action);
  }
};

export default sidePanelMiddleware;
