// =============================
// Imports
// =============================

// External dependencies
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';
import * as playerCtx from '../constants/PlayerContexts';
import * as rqs from '../constants/RequestTypes';

// Helpers
import { pickPlayerTrackData } from '../../helpers/player';
import { getApiUrl, getXPreferredLanguage } from '../../helpers/misc';
import { cancelableRequest } from '../helpers/axios';

// =============================
// Player action
// =============================

export function open() {
  return {
    type: acts.OPEN_PLAYER,
  };
}

export function close() {
  return {
    type: acts.CLOSE_PLAYER,
  };
}

export function setPlayerState(state) {
  return {
    type: acts.SET_PLAYER_STATE,
    payload: state,
  };
}

export function setGetCurrentTime(time) {
  return {
    type: acts.SET_PLAYER_GET_CURRENT_TIME_FNC,
    payload: time,
  };
}

export function fulfillRequest(req) {
  return {
    type: acts.FULFILL_PLAYER_REQUEST,
    payload: req,
  };
}

export function play() {
  return {
    type: acts.PLAYER_REQUEST_PLAY,
  };
}

export function pause() {
  return {
    type: acts.PLAYER_REQUEST_PAUSE,
  };
}

export function goToPrevTrack() {
  return {
    type: acts.PLAYER_REQUEST_GO_TO_PREV_TRACK,
  };
}

export function goToNextTrack() {
  return {
    type: acts.PLAYER_REQUEST_GO_TO_NEXT_TRACK,
  };
}

export function seek(st) {
  return {
    type: acts.PLAYER_REQUEST_SET_SEEKING_TIME,
    payload: st,
  };
}

export function reinitializePlayer() {
  return {
    type: acts.REINITIALIZE_PLAYER,
  };
}

// =============================
// Media action
// =============================

export function setCurrentTrack(
  current,
  contextName,
  contextId,
  contextPosition,
  withTrackListing,
) {
  return (dispatch, getState) => {
    let trackListing = [];

    if (withTrackListing) {
      if (contextName === playerCtx.SEARCH) {
        trackListing = getState().meta.tracks.data
          .map(track => pickPlayerTrackData(track));
      } else {
        trackListing = withTrackListing;
      }
    }

    return dispatch({
      type: acts.SET_PLAYER_CURRENT_TRACK,
      payload: {
        current: pickPlayerTrackData(current),
        trackId: current.id,
        contextName,
        contextId,
        contextPosition,
        ...(withTrackListing ? { trackListing } : {}),
      },
    });
  };
}

export function getAndSetAlbumTracks(albumId) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_AND_SET_ALBUM_TRACKS_LOADING,
    });

    return cancelableRequest(
      rqs.GET_PLAYER_TRACKS_THROUGH_REQUEST,
      {
        method: 'get',
        url: getApiUrl(`meta/albums/${albumId}/tracks`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      },
    )
      .then((response) => {
        const trackListing = response.data;
        const contextPosition = _findIndex(trackListing, track => track.audiofile.original);

        if (contextPosition === -1) {
          throw new Error();
        }

        dispatch(setCurrentTrack(
          trackListing[contextPosition],
          playerCtx.ALBUM,
          albumId,
          contextPosition,
          trackListing,
        ));

        return dispatch({
          type: acts.GET_AND_SET_ALBUM_TRACKS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_AND_SET_ALBUM_TRACKS_FAILURE,
        payload: {
          message: i18n.t('errors:player.album_tracks_not_found'),
          reqId: _get(err, 'response.data.reqId'),
        },
      }));
  };
}

export function getAndSetPlaylistTracks(playlistId) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_AND_SET_PLAYLIST_TRACKS_LOADING,
    });

    return cancelableRequest(
      rqs.GET_PLAYER_TRACKS_THROUGH_REQUEST,
      {
        method: 'get',
        url: getApiUrl(`meta/playlists/${playlistId}/tracks`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      },
    )
      .then((response) => {
        const trackListing = response.data;
        const contextPosition = _findIndex(trackListing, track => track.audiofile.original);

        if (contextPosition === -1) {
          throw new Error();
        }

        dispatch(setCurrentTrack(
          trackListing[contextPosition],
          playerCtx.PLAYLIST,
          playlistId,
          contextPosition,
          trackListing,
        ));

        return dispatch({
          type: acts.GET_AND_SET_PLAYLIST_TRACKS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_AND_SET_PLAYLIST_TRACKS_FAILURE,
        payload: {
          message: i18n.t('errors:player.playlist_tracks_not_found'),
          reqId: _get(err, 'response.data.reqId'),
        },
      }));
  };
}

export function getAndSetBriefTracks(briefId) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_AND_SET_BRIEF_TRACKS_LOADING,
    });

    return cancelableRequest(
      rqs.GET_PLAYER_TRACKS_THROUGH_REQUEST,
      {
        method: 'get',
        url: getApiUrl(`meta/briefs/${briefId}/tracks`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      },
    )
      .then((response) => {
        const trackListing = response.data;
        const contextPosition = _findIndex(trackListing, track => track.audiofile.original);

        if (contextPosition === -1) {
          throw new Error();
        }

        dispatch(setCurrentTrack(
          trackListing[contextPosition],
          playerCtx.BRIEF,
          briefId,
          contextPosition,
          trackListing,
        ));

        return dispatch({
          type: acts.GET_AND_SET_BRIEF_TRACKS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_AND_SET_BRIEF_TRACKS_FAILURE,
        payload: {
          message: i18n.t('errors:player.brief_tracks_not_found'),
          reqId: _get(err, 'response.data.reqId'),
        },
      }));
  };
}
