// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { StyledTextArea } from './styles';

// =============================
// Component
// =============================

const TextAreaInput = ({ t, ...rest }) => (
  <StyledTextArea
    lang={{
      Label: {
        optional: t('components:mewo_input.Label.optional'),
      },
    }}
    {...rest}
  />
);

TextAreaInput.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['components'])(TextAreaInput);
