// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 0.2rem 0.4rem #777;
  height: 2rem;
  left: 0;
  position: fixed;
  top: 0;
  width: 2rem;
  z-index: ${({ theme }) => theme.zIndex.devTools};
  transition: all .2s;
`;

export const Content = styled.div`
  overflow: auto;
  margin: 1rem;
  height: calc(100% - 3rem);
`;

export const Toggle = styled.div`
  background-color: lightgrey;
  width: 2rem;
  height: 2rem;
`;

export const EnvViewer = styled.pre`
  margin: 0;
`;
