// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Button, Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const DocumentAutotagStatus = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  line-height: 1.2;
`;

export const AutotagActionWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 1.2rem;
`;

export const AutotagActionIcon = styled(Icons.Autotag).attrs({
  width: '1.5rem',
})`
  margin-right: 1rem;
`;

export const AutotagActionButton = styled(Button).attrs({
  size: 'small',
})``;
