// =============================
// Config
// =============================

// Locales that exist for Meta-v2
module.exports = {
  languagesList: ['en', 'fr', 'es', 'de', 'it', 'zh', 'ja', 'ko'],
  defaultLanguage: 'en',
  languagesNames: {
    en: {
      nativeName: 'English',
      name: 'English',
    },
    fr: {
      nativeName: 'Français',
      name: 'French',
    },
    es: {
      nativeName: 'Español',
      name: 'Spanish',
    },
    de: {
      nativeName: 'Deutsch',
      name: 'German',
    },
    it: {
      nativeName: 'Italiano',
      name: 'Italian',
    },
    zh: {
      nativeName: '中文',
      name: 'Chinese',
    },
    ja: {
      nativeName: '日本語',
      name: 'Japanese',
    },
    ko: {
      nativeName: '한국어',
      name: 'Korean',
    },
  },
};
