// =============================
// Imports
// =============================

// External Dependencies
import axios, { isCancel } from 'axios';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import _omit from 'lodash/omit';
import _some from 'lodash/some';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import _sortBy from 'lodash/sortBy';

// Helpers
import {
  getApiUrl,
  apiAdapterFile,
  apiFileState,
  getXPreferredLanguage,
  sleep,
} from '../../helpers/misc';
import determineError, { getModoActivateError } from '../../helpers/errors';
import { cancelableRequest, cancelRequest } from '../helpers/axios';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// =============================
// Modo Config Actions
// =============================

const configFileKeys = [
  { key: 'customisations.logo', name: 'logo' },
  { key: 'customisations.logo_variant', name: 'logo_variant' },
  { key: 'customisations.logo_modal', name: 'logo_modal' },
  { key: 'customisations.logo_email', name: 'logo_email' },
  { key: 'customisations.logo_filter_bar', name: 'logo_filter_bar' },
  { key: 'customisations.favicon', name: 'favicon' },
];

function apiAdapterConfig(config, type) {
  switch (type) {
    case 'active':
      return { active: _get(config, 'active', false) };

    case 'url':
      return { website_url: _get(config, 'url_config.website_url', '') };

    case 'customisations':
      return {
        customisations: _pick(_get(config, 'customisations', {}), ['font_family', 'colors']),
      };

    case 'otherCustomisations':
      return {
        customisations: _omit(_get(config, 'customisations', {}), [
          'logo',
          'logo_variant',
          'logo_modal',
          'logo_email',
          'logo_filter_bar',
          'favicon',
          'font_family',
          'colors',
        ]),
      };

    case 'integrations':
      return _pick(config, ['integrations']);

    case 'users':
      return _pick(config, ['default_user_rights', 'user_rights_whitelists']);

    default:
      return {
        ..._pick(config, [
          'website_title',
          'blog_url',
          'default_language',
          'languages',
          'global_contact',
          'private_access',
        ]),
      };
  }
}

export function getConfigs(ignoreError = false) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIGS_LOADING,
    });

    return axios({
      method: 'get',
      url: getApiUrl('modo/configs'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIGS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_MODO_CONFIGS_SUCCESS,
        });
      })
      .catch((err) => {
        // This happens when called from withMetaInitData
        if (ignoreError) {
          return dispatch({
            type: acts.GET_MODO_CONFIGS_SUCCESS,
          });
        }

        return dispatch({
          type: acts.GET_MODO_CONFIGS_FAILURE,
          payload: {
            message: determineError(err),
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function createConfig(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.CREATE_MODO_CONFIG_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('modo/configs'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.PUSH_MODO_CONFIG,
          payload: response.data,
        });

        return dispatch({
          type: acts.CREATE_MODO_CONFIG_SUCCESS,
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 402
            && err.response.data.key === 'permission_needed':
            errorMsg = i18n.t('errors:modo.listing.not_in_plan');
            break;

          case err.response
            && err.response.status === 402
            && err.response.data.key === 'quota_exceeded':
            errorMsg = i18n.t('errors:modo.listing.quota_exceeded');
            break;

          case err.response && err.response.status === 406:
            errorMsg = i18n.t('errors:modo.listing.duplicate_name');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        if (err.response && err.response.status === 402) {
          dispatch({
            type: acts.OPEN_PREMIUM_MODAL,
            payload: {
              message: errorMsg,
              context: 'modo_creation',
              description: errorMsg,
            },
          });

          return dispatch({
            type: acts.CREATE_MODO_CONFIG_FAILURE,
          });
        }

        return dispatch({
          type: acts.CREATE_MODO_CONFIG_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function getConfig(configId) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_LOADING,
    });

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: {
            id: response.data.id,
            data: apiAdapterConfig(response.data),
            urlData: apiAdapterConfig(response.data, 'url'),
            activeData: apiAdapterConfig(response.data, 'active'),
            customisationsData: apiAdapterConfig(response.data, 'customisations'),
            otherCustomisationsData: apiAdapterConfig(response.data, 'otherCustomisations'),
            integrationsData: apiAdapterConfig(response.data, 'integrations'),
            usersData: apiAdapterConfig(response.data, 'users'),
            fullData: response.data,
          },
        });

        configFileKeys.forEach((val) => {
          dispatch({
            type: acts.SET_MODO_CONFIG_IMAGE,
            payload: {
              [val.name]: {
                value: apiAdapterFile(_get(response, `data.${val.key}`)),
                status: _get(response, `data.${val.key}.original`) ? 2 : 0,
              },
            },
          });
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_MODO_CONFIG_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function modifyConfigPlan(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_PLAN_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/plan`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: {
            fullData: response.data,
          },
        });

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_PLAN_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.MODIFY_MODO_CONFIG_PLAN_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }));
  };
}

export function modifyConfigBase(data, options) {
  return (dispatch, getState) => {
    dispatch({
      type: options.loadingType,
    });

    const configId = getState().modo.config.id;
    const storeDataKey = options.dataKey === 'data' ? 'data' : `${options.dataKey}Data`;
    const prevData = getState().modo.config[storeDataKey];

    dispatch({
      type: acts.SET_MODO_CONFIG,
      payload: { [storeDataKey]: data },
    });

    return cancelableRequest(options.reqKey, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/config`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: {
            [storeDataKey]: apiAdapterConfig(response.data, options.dataKey),
            activeData: apiAdapterConfig(response.data, 'active'),
            fullData: response.data,
          },
        });

        return dispatch({
          type: options.successType,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: { [storeDataKey]: prevData },
        });

        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 400
            && err.response.data.key === 'modo_config_default_language':
            errorMsg = i18n.t('errors:modo.general.modo_config_default_language');
            break;

          case err.response
            && err.response.status === 400
            && err.response.data.key === 'modo_config_user_rights_whitelists':
            errorMsg = i18n.t('errors:modo.general.modo_config_user_rights_whitelists');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: options.failureType,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function modifyConfigMain(data) {
  return modifyConfigBase(data, {
    requestType: rqs.MODIFY_MODO_CONFIG_MAIN_SETTINGS,
    loadingType: acts.MODIFY_MODO_CONFIG_MAIN_SETTINGS_LOADING,
    successType: acts.MODIFY_MODO_CONFIG_MAIN_SETTINGS_SUCCESS,
    failureType: acts.MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE,
    dataKey: 'data',
  });
}

export function modifyConfigCustomisations(data) {
  return modifyConfigBase(data, {
    requestType: rqs.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS,
    loadingType: acts.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_LOADING,
    successType: acts.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_SUCCESS,
    failureType: acts.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE,
    dataKey: 'customisations',
  });
}

export function modifyConfigOtherCustomisations(data) {
  return modifyConfigBase(data, {
    requestType: rqs.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS,
    loadingType: acts.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_LOADING,
    successType: acts.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_SUCCESS,
    failureType: acts.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE,
    dataKey: 'otherCustomisations',
  });
}

export function modifyConfigIntegrations(data) {
  return modifyConfigBase(data, {
    requestType: rqs.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS,
    loadingType: acts.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_LOADING,
    successType: acts.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_SUCCESS,
    failureType: acts.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE,
    dataKey: 'integrations',
  });
}

export function modifyConfigUsers(data) {
  return modifyConfigBase(data, {
    requestType: rqs.MODIFY_MODO_CONFIG_USERS_SETTINGS,
    loadingType: acts.MODIFY_MODO_CONFIG_USERS_SETTINGS_LOADING,
    successType: acts.MODIFY_MODO_CONFIG_USERS_SETTINGS_SUCCESS,
    failureType: acts.MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE,
    dataKey: 'users',
  });
}

export function modifyConfigActiveSettings(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_LOADING,
    });

    const configId = getState().modo.config.id;
    const prevData = getState().modo.config.activeData;

    dispatch({
      type: acts.SET_MODO_CONFIG,
      payload: {
        activeData: data,
      },
    });

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS, {
      method: 'post',
      url: getApiUrl(`modo/${configId}/config/activate`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: {
            activeData: apiAdapterConfig(response.data, 'active'),
            fullData: response.data,
          },
        });

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: {
            activeData: prevData,
          },
        });

        if (
          err.response
          && err.response.status === 400
          && err.response.data.key === 'modo_unmet_active_conditions'
        ) {
          return dispatch({
            type: acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE,
            payload: {
              message: getModoActivateError(getState().modo.config.fullData),
              reqId: _get(err, 'response.data.reqId'),
            },
          });
        }

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE,
          payload: {
            message: determineError(err),
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function modifyConfigUrlSettings(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_URL_SETTINGS_LOADING,
    });

    const configId = getState().modo.config.id;
    const prevData = getState().modo.config.urlData;

    dispatch({
      type: acts.SET_MODO_CONFIG,
      payload: {
        urlData: data,
      },
    });

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_URL_SETTINGS, {
      method: 'post',
      url: getApiUrl(`modo/${configId}/config/domain`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: {
            urlData: apiAdapterConfig(response.data, 'url'),
            activeData: apiAdapterConfig(response.data, 'active'),
            fullData: response.data,
          },
        });

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_URL_SETTINGS_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        dispatch({
          type: acts.SET_MODO_CONFIG,
          payload: {
            urlData: prevData,
          },
        });

        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 422
            && err.response.data.key === 'heroku_domain_error'
            && err.response.data.message !== 'Domain format is invalid.':
            errorMsg = i18n.t('errors:modo.general.heroku_domain_already_set');
            break;

          case err.response
            && err.response.status === 422
            && err.response.data.key === 'heroku_domain_error'
            && err.response.data.message === 'Domain format is invalid.':
            errorMsg = i18n.t('errors:modo.general.heroku_domain_invalid_format');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'heroku_domain_error':
            errorMsg = i18n.t('errors:modo.general.heroku_old_domain_not_found');
            break;

          case err.response
            && err.response.status !== 404
            && err.response.status !== 422
            && err.response.data.key === 'heroku_domain_error':
            errorMsg = i18n.t('errors:modo.general.heroku_domain_error');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function uploadToConfig(name, file) {
  return (dispatch, getState) => {
    const configId = getState().modo.config.id;
    const prevFile = getState().modo.config.images[name];

    const url = getApiUrl(`modo/${configId}/config/${name}`);

    const state = apiFileState(file, prevFile.value);
    const { key } = configFileKeys.find(v => v.name === name);

    if (state.type === 'unchanged') return Promise.resolve();

    if (state.type === 'deleted') {
      dispatch({
        type: acts.SET_MODO_CONFIG_IMAGE,
        payload: {
          [name]: {
            value: prevFile.value,
            status: 1,
          },
        },
      });

      return cancelableRequest(`${rqs.UPLOAD_MODO_CONFIG_IMAGE}:${name}`, {
        method: 'delete',
        url,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      })
        .then(() => {
          dispatch({
            type: acts.SET_MODO_CONFIG_IMAGE,
            payload: {
              [name]: {
                value: undefined,
                status: 0,
              },
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: acts.SET_MODO_CONFIG_IMAGE,
            payload: {
              [name]: prevFile,
            },
          });

          if (isCancel(err)) return null;

          let errorMsg;
          switch (true) {
            case err.response
              && err.response.status === 404
              && err.response.data.key !== 'api_not_found'
              && err.response.data.key !== 'config_not_found':
              return null;

            default:
              errorMsg = determineError(err);
              break;
          }

          return dispatch({
            type: acts.DELETE_MODO_CONFIG_IMAGE_FAILURE,
            payload: {
              message: errorMsg,
              reqId: _get(err, 'response.data.reqId'),
            },
          });
        });
    }

    dispatch({
      type: acts.SET_MODO_CONFIG_IMAGE,
      payload: {
        [name]: {
          value: { name: file.name },
          status: 1,
        },
      },
    });

    return cancelableRequest(`${rqs.UPLOAD_MODO_CONFIG_IMAGE}:${name}`, {
      method: 'post',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: state.data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_IMAGE,
          payload: {
            [name]: {
              value: apiAdapterFile(_get(response, `data.${key}`)),
              status: 2,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_IMAGE,
          payload: {
            [name]: prevFile,
          },
        });

        if (isCancel(err)) return null;

        let errorMsg;
        switch (true) {
          case err.response && err.response.data.key === 'invalid_file_format':
            errorMsg = i18n.t('errors:common.invalid_file_format');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.UPLOAD_MODO_CONFIG_IMAGE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Menus
// =============================

export function getConfigMenus() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_MENUS_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config/menus`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_MENUS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_MENUS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_MODO_CONFIG_MENUS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function getConfigMenusOptions() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_MENUS_OPTIONS_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config/menus/options`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_MENUS_OPTIONS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_MENUS_OPTIONS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_MODO_CONFIG_MENUS_OPTIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function modifyConfigMenus(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_MENUS_LOADING,
    });

    const configId = getState().modo.config.id;

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_MENUS, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/menus`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_MENUS,
          payload: response.data,
        });

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_MENUS_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_MENUS_FAILURE,
          payload: {
            message: determineError(err),
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Legal
// =============================

export function getConfigLegal() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_LEGAL_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config/legal`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_LEGAL,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_LEGAL_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_MODO_CONFIG_LEGAL_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function modifyConfigLegal(legal) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_LEGAL_LOADING,
    });

    const configId = getState().modo.config.id;

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_LEGAL, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/legal`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { content: legal },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_LEGAL,
          payload: response.data,
        });

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_LEGAL_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_LEGAL_FAILURE,
          payload: {
            message: determineError(err),
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Pages
// =============================

// NOTE: Page param is not used for this but still necessary
// so that we can use the pageFetcher system
export function getConfigPages(page, { sort = undefined }) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_PAGES_LOADING,
    });

    const configId = getState().modo.config.id;

    let url = getApiUrl(`modo/${configId}/config/pages`);

    if (sort) {
      url += `?sort=${sort}`;

      const reverseSort = _some(['updated_at', 'created_at', 'active'], val => val === sort);
      const sortDirection = reverseSort ? '-1' : '1';

      url += `&sort_direction=${sortDirection}`;
    }

    return axios({
      method: 'get',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_PAGES,
          payload: {
            data: response.data,
            total: _get(response, 'data', []).length,
            query: _omitBy({ sort }, _isUndefined),
            // Fake values for pageFetcher system
            page: 0,
            nbPages: 1,
          },
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_PAGES_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_MODO_CONFIG_PAGES_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

const pageFileKeys = [
  { key: 'seo_preview', name: 'seo_preview' },
];

export function getConfigPage(pageId) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_PAGE_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_PAGE,
          payload: {
            id: response.data.id,
            data: response.data,
          },
        });

        pageFileKeys.forEach((val) => {
          dispatch({
            type: acts.SET_MODO_CONFIG_PAGE_IMAGE,
            payload: {
              [val.name]: {
                value: apiAdapterFile(_get(response, `data.${val.key}`)),
                status: _get(response, `data.${val.key}.original`) ? 2 : 0,
              },
            },
          });
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_PAGE_SUCCESS,
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.custom_page.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.GET_MODO_CONFIG_PAGE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function createConfigPage(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.CREATE_MODO_CONFIG_PAGE_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/config/pages`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then(response => dispatch({
        type: acts.CREATE_MODO_CONFIG_PAGE_SUCCESS,
        payload: response.data,
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          // NOTE: Not in plan is handled in page
          case err.response
            && err.response.status === 402
            && err.response.data.key === 'quota_exceeded':
            errorMsg = i18n.t('errors:modo.custom_page.quota_exceeded');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.CREATE_MODO_CONFIG_PAGE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function duplicateConfigPage(pageId, data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.DUPLICATE_MODO_CONFIG_PAGE_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}/duplicate`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then(response => dispatch({
        type: acts.DUPLICATE_MODO_CONFIG_PAGE_SUCCESS,
        payload: response.data,
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 404:
            errorMsg = i18n.t('errors:modo.custom_page.duplicate_not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.DUPLICATE_MODO_CONFIG_PAGE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function modifyConfigPage(pageId, data) {
  return (dispatch, getState) => {
    const configId = getState().modo.config.id;
    const initialData = getState().modo.configPage.data;

    dispatch({
      type: acts.SET_MODO_CONFIG_PAGE,
      payload: { data: {
        ...initialData,
        ...data,
      } },
    });

    // NOTE: For some reason, we need to trigger loading after setting next values
    // if not, the form values would "blink"
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_PAGE_LOADING,
    });

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_PAGE, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_PAGE,
          payload: { data: response.data },
        });

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_PAGE_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        dispatch({
          type: acts.SET_MODO_CONFIG_PAGE,
          payload: { data: initialData },
        });

        // NOTE: 402 is handled in page
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.custom_page.not_found');
            break;

          case err.response
            && err.response.status === 400
            && err.response.data.key === 'slug_duplicate':
            errorMsg = i18n.t('errors:modo.custom_page.slug_duplicate');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_PAGE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function deleteConfigPage(pageId, redirect = false) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.DELETE_MODO_CONFIG_PAGE_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'delete',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(() => dispatch({
        type: acts.DELETE_MODO_CONFIG_PAGE_SUCCESS,
        payload: { redirect },
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.custom_page.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.DELETE_MODO_CONFIG_PAGE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function uploadToConfigPage(name, file) {
  return (dispatch, getState) => {
    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;
    const prevFile = getState().modo.configPage.images[name];

    const url = getApiUrl(`modo/${configId}/config/pages/${pageId}/${name}`);

    const state = apiFileState(file, prevFile.value);
    const { key } = pageFileKeys.find(v => v.name === name);

    if (state.type === 'unchanged') return Promise.resolve();

    if (state.type === 'deleted') {
      dispatch({
        type: acts.SET_MODO_CONFIG_PAGE_IMAGE,
        payload: {
          [name]: {
            value: prevFile.value,
            status: 1,
          },
        },
      });

      return cancelableRequest(`${rqs.UPLOAD_MODO_CONFIG_PAGE_IMAGE}:${name}`, {
        method: 'delete',
        url,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      })
        .then(() => {
          dispatch({
            type: acts.SET_MODO_CONFIG_PAGE_IMAGE,
            payload: {
              [name]: {
                value: undefined,
                status: 0,
              },
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: acts.SET_MODO_CONFIG_PAGE_IMAGE,
            payload: {
              [name]: prevFile,
            },
          });

          if (isCancel(err)) return null;

          let errorMsg;
          switch (true) {
            case err.response
              && err.response.status === 404
              && err.response.data.key !== 'api_not_found'
              && err.response.data.key !== 'config_not_found':
              return null;

            default:
              errorMsg = determineError(err);
              break;
          }

          return dispatch({
            type: acts.DELETE_MODO_CONFIG_PAGE_IMAGE_FAILURE,
            payload: {
              message: errorMsg,
              reqId: _get(err, 'response.data.reqId'),
            },
          });
        });
    }

    dispatch({
      type: acts.SET_MODO_CONFIG_PAGE_IMAGE,
      payload: {
        [name]: {
          value: { name: file.name },
          status: 1,
        },
      },
    });

    return cancelableRequest(`${rqs.UPLOAD_MODO_CONFIG_PAGE_IMAGE}:${name}`, {
      method: 'post',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: state.data,
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_PAGE_IMAGE,
          payload: {
            [name]: {
              value: apiAdapterFile(_get(response, `data.${key}`)),
              status: 2,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_PAGE_IMAGE,
          payload: {
            [name]: prevFile,
          },
        });

        if (isCancel(err)) return null;

        let errorMsg;
        switch (true) {
          case err.response && err.response.data.key === 'invalid_file_format':
            errorMsg = i18n.t('errors:common.invalid_file_format');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.UPLOAD_MODO_CONFIG_PAGE_IMAGE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Config Page Modules
// =============================

export function getConfigPageModules() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_PAGE_MODULES_LOADING,
    });

    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}/modules`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_PAGE_MODULES,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_PAGE_MODULES_SUCCESS,
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.modules.page_not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.GET_MODO_CONFIG_PAGE_MODULES_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function createConfigPageModule(data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.CREATE_MODO_CONFIG_PAGE_MODULE_LOADING,
    });

    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}/modules`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.ADD_MODO_CONFIG_PAGE_MODULE,
          payload: {
            ...response.data,
            // Add this here so that module will be opened on init
            open_on_init: true,
          },
        });

        return dispatch({
          type: acts.CREATE_MODO_CONFIG_PAGE_MODULE_SUCCESS,
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'page_not_found':
            errorMsg = i18n.t('errors:modo.modules.page_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'module_not_found':
            errorMsg = i18n.t('errors:modo.modules.module_not_found');
            break;

          case err.response
            && err.response.status === 403
            && err.response.data.key === 'module_limit_exceeded':
            errorMsg = i18n.t('errors:modo.modules.max_modules');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function setDirtyConfigPageModules(data) {
  return {
    type: acts.SET_DIRTY_MODO_CONFIG_PAGE_MODULES,
    payload: data,
  };
}

export function modifyConfigPageModule(moduleId, data) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_PAGE_MODULE_LOADING,
      payload: { id: moduleId },
    });

    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;

    return axios({
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}/modules/${moduleId}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.UPDATE_MODO_CONFIG_PAGE_MODULE,
          payload: {
            id: moduleId,
            data: response.data,
          },
        });

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_PAGE_MODULE_SUCCESS,
          payload: { id: moduleId },
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'page_not_found':
            errorMsg = i18n.t('errors:modo.modules.page_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'module_not_found':
            errorMsg = i18n.t('errors:modo.modules.module_not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE,
          payload: {
            id: moduleId,
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function uploadConfigPageModuleImage(moduleId, file, itemId = null, isMobile = false) {
  return (dispatch, getState) => {
    const actions = itemId
      ? {
        loading: acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING,
        success: acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS,
        failure: acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE,
      }
      : {
        loading: acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING,
        success: acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS,
        failure: acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE,
      };

    dispatch({
      type: actions.loading,
      payload: { id: moduleId, name: file.name, itemId, isMobile },
    });

    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;

    const form = new FormData();
    form.append('file', file);
    form.append('isMobile', isMobile);

    if (itemId) {
      form.append('itemId', itemId);
    }

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}/modules/${moduleId}/upload`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: form,
    })
      .then((response) => {
        dispatch({
          type: acts.UPDATE_MODO_CONFIG_PAGE_MODULE,
          payload: {
            id: moduleId,
            data: response.data,
          },
        });

        return dispatch({
          type: actions.success,
          payload: { id: moduleId, itemId, isMobile },
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'page_not_found':
            errorMsg = i18n.t('errors:modo.modules.page_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'module_not_found':
            errorMsg = i18n.t('errors:modo.modules.module_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'module_item_not_found':
            errorMsg = i18n.t('errors:modo.modules.module_item_not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: actions.failure,
          payload: {
            id: moduleId,
            itemId,
            isMobile,
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function deleteConfigPageModuleImage(moduleId, itemId = null, isMobile) {
  return (dispatch, getState) => {
    const actions = itemId
      ? {
        loading: acts.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING,
        success: acts.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS,
        failure: acts.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE,
      }
      : {
        loading: acts.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING,
        success: acts.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS,
        failure: acts.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE,
      };

    dispatch({
      type: actions.loading,
      payload: { id: moduleId, itemId, isMobile },
    });

    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;

    const data = { isMobile };

    if (itemId) data.itemId = itemId;

    return axios({
      method: 'delete',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}/modules/${moduleId}/upload`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: acts.UPDATE_MODO_CONFIG_PAGE_MODULE,
          payload: {
            id: moduleId,
            data: response.data,
          },
        });

        return dispatch({
          type: actions.success,
          payload: { id: moduleId, itemId, isMobile },
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'page_not_found':
            errorMsg = i18n.t('errors:modo.modules.page_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'module_not_found':
            errorMsg = i18n.t('errors:modo.modules.module_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'module_item_not_found':
            errorMsg = i18n.t('errors:modo.modules.module_item_not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: actions.failure,
          payload: {
            id: moduleId,
            itemId,
            isMobile,
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function modifyConfigPageModulesOrder(nextIds) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_LOADING,
    });

    const initialData = getState().modo.configPageModules.data;
    const nextData = _sortBy(initialData, doc => nextIds.indexOf(doc.id));

    dispatch({
      type: acts.SET_MODO_CONFIG_PAGE_MODULES,
      payload: nextData,
    });

    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_MODULES_ORDER, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { modules: nextIds },
    })
      .then(() => dispatch({
        type: acts.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_SUCCESS,
      }))
      .catch((err) => {
        if (isCancel(err)) return null;

        dispatch({
          type: acts.SET_MODO_CONFIG_PAGE_MODULES,
          payload: initialData,
        });

        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.custom_page.not_found');
            break;

          // This error exists no matter what within this request
          case err.response
            && err.response.status === 400
            && err.response.data.key === 'slug_duplicate':
            errorMsg = i18n.t('errors:modo.custom_page.slug_duplicate');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function deleteConfigPageModule(moduleId) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.DELETE_MODO_CONFIG_PAGE_MODULE_LOADING,
    });

    const configId = getState().modo.config.id;
    const pageId = getState().modo.configPage.id;

    return axios({
      method: 'delete',
      url: getApiUrl(`modo/${configId}/config/pages/${pageId}/modules/${moduleId}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(() => {
        dispatch({
          type: acts.REMOVE_MODO_CONFIG_PAGE_MODULE,
          payload: moduleId,
        });

        return dispatch({
          type: acts.DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS,
          payload: { message: i18n.t('pages:modo.custom_page_modules.module_deleted') },
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'page_not_found':
            errorMsg = i18n.t('errors:modo.modules.page_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'module_not_found':
            errorMsg = i18n.t('errors:modo.modules.module_not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Suggested
// =============================

export function getConfigSuggestedPlaylists() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config/suggested/playlists`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_SUGGESTED_PLAYLISTS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function modifyConfigSuggestedPlaylists(ids) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING,
    });

    const configId = getState().modo.config.id;

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/suggested/playlists`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { ids },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_SUGGESTED_PLAYLISTS,
          payload: response.data,
        });

        if (!_isEqual(ids, _uniq(ids))) {
          dispatch({
            type: acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_ERROR,
            payload: {
              message: i18n.t('errors:modo.suggestions.playlist_duplicates'),
              reqId: _get(response, 'data.reqId'),
            },
          });
        }

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE,
          payload: {
            message: determineError(err),
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function getConfigSuggestedAlbums() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'get',
      url: getApiUrl(`modo/${configId}/config/suggested/albums`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_SUGGESTED_ALBUMS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function modifyConfigSuggestedAlbums(ids) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING,
    });

    const configId = getState().modo.config.id;

    return cancelableRequest(rqs.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/config/suggested/albums`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { ids },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_CONFIG_SUGGESTED_ALBUMS,
          payload: response.data,
        });

        if (!_isEqual(ids, _uniq(ids))) {
          dispatch({
            type: acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_ERROR,
            payload: {
              message: i18n.t('errors:modo.suggestions.album_duplicates'),
              reqId: _get(response, 'data.reqId'),
            },
          });
        }

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        return dispatch({
          type: acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE,
          payload: {
            message: determineError(err),
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// Users
// =============================

export function getModoUsers(page, query, refreshInterval = 0) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_MODO_USERS_LOADING,
    });

    const configId = getState().modo.config.id;

    if (refreshInterval) {
      await sleep(refreshInterval);
    }

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl(`modo/${configId}/users/search`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: {
          query: query.search || '',
          options: {
            page,
            max: 50,
            sort: query.sort,
            sort_direction: query.sort_direction,
          },
        },
      });

      dispatch({
        type: acts.SET_MODO_USERS,
        payload: {
          data: response.data.data,
          page: response.data.page,
          nbPages: response.data.nb_pages,
          total: response.data.total,
          query: _omitBy(
            _pick(query, [
              'sort',
              'search',
              'sort_direction',
            ]),
            _isUndefined,
          ),
        },
      });

      return dispatch({
        type: acts.GET_MODO_USERS_SUCCESS,
      });
    } catch (error) {
      return dispatch({
        type: acts.GET_MODO_USERS_FAILURE,
        payload: {
          message: determineError(error),
          reqId: _get(error, 'response.data.reqId'),
        },
      });
    }
  };
}

export function refreshModoUsers(ids) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.REFRESH_MODO_USERS_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/users/search-by-ids`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { ids },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_MODO_USERS,
          payload: {
            data: response.data,
          },
        });

        return dispatch({
          type: acts.REFRESH_MODO_USERS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.REFRESH_MODO_USERS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}

export function modifyModoUser(id, diff) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_MODO_USER_LOADING,
    });

    const configId = getState().modo.config.id;
    // Update first so that users grid show the right information
    const initialData = getState().modo.users.data;
    const modifiedData = initialData.reduce((acc, e) => {
      if (e.id === id) return [...acc, { ...e, ...diff }];
      return [...acc, e];
    }, []);

    dispatch({
      type: acts.SET_MODO_USERS,
      payload: { data: modifiedData },
    });

    return cancelableRequest(
      // The cancelable request must be unique so that
      // each user can be modified concurrently from the others
      `${rqs.MODIFY_MODO_USER}_${id}`,
      {
        method: 'put',
        url: getApiUrl(`modo/${configId}/users/${id}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: diff,
      },
    )
      .then((response) => {
        // Update again to make sure everything is alright
        const data = getState().modo.users.data.reduce((acc, e) => {
          if (e.id === response.data.id) return [...acc, response.data];
          return [...acc, e];
        }, []);

        dispatch({
          type: acts.SET_MODO_USERS,
          payload: { data },
        });

        return dispatch({
          type: acts.MODIFY_MODO_USER_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        // Reset initial data
        dispatch({
          type: acts.SET_MODO_USERS,
          payload: { data: initialData },
        });

        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.users.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_MODO_USER_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function deleteModoUser(id) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.DELETE_MODO_USER_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'delete',
      url: getApiUrl(`modo/${configId}/users/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(() => dispatch({
        type: acts.DELETE_MODO_USER_SUCCESS,
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.users.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.DELETE_MODO_USER_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function inviteModoUser(email) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.INVITE_MODO_USER_LOADING,
    });

    const configId = getState().modo.config.id;

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/users/invite`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { email },
    })
      .then(() => dispatch({
        type: acts.INVITE_MODO_USER_SUCCESS,
        payload: { message: i18n.t('pages:modo.users.invitation_success') },
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 406:
            errorMsg = i18n.t('errors:modo.users.invite_config_inactive');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.INVITE_MODO_USER_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// User Export
// =============================

export function exportModoUsers(email) {
  return (dispatch, getState) => {
    dispatch({
      type: acts.EXPORT_MODO_USERS_LOADING,
    });

    const configId = getState().modo.config.id;
    const { dateFormat } = getState().core.localPreferences;

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/users/export`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        email,
        date_format: dateFormat === 'DDMMYYYY'
          ? 'DD/MM/YYYY'
          : 'MM/DD/YYYY',
      },
    })
      .then(() => dispatch({
        type: acts.EXPORT_MODO_USERS_SUCCESS,
        payload: { message: i18n.t('pages:modo.users.export_success') },
      }),
      )
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.users.export_no_users');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.EXPORT_MODO_USERS_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

// =============================
// User Import
// =============================

export function createUserImport(dispatch, getState) {
  dispatch({
    type: acts.CREATE_MODO_USER_IMPORT_LOADING,
  });

  const configId = getState().modo.config.id;

  return cancelableRequest(rqs.CREATE_MODO_USER_IMPORT, {
    method: 'post',
    url: getApiUrl(`modo/${configId}/users/ingestions`),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Auth': getState().user.token,
      'x-preferred-language': getXPreferredLanguage(),
    },
    data: { name: new Date().toISOString() },
  })
    .then(({ data }) => {
      dispatch({
        type: acts.SET_MODO_USER_IMPORT_ID,
        payload: data.id,
      });

      return dispatch({
        type: acts.CREATE_MODO_USER_IMPORT_SUCCESS,
      });
    })
    .catch((err) => {
      // Throw error for chaining
      if (isCancel(err)) throw err;

      return dispatch({
        type: acts.CREATE_MODO_USER_IMPORT_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    });
}

export function modifyUserImport(update) {
  return (dispatch, getState) => {
    const {
      usersImport: { id, data: oldState },
    } = getState().modo;

    dispatch({
      type: acts.MODIFY_MODO_USER_IMPORT_LOADING,
    });

    const configId = getState().modo.config.id;

    dispatch({
      type: acts.SET_MODO_USER_IMPORT_DATA,
      payload: { ...oldState, ...update },
    });

    return cancelableRequest(rqs.MODIFY_MODO_USER_IMPORT, {
      method: 'put',
      url: getApiUrl(`modo/${configId}/users/ingestions/${id}`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: update,
    })
      .then(({ data }) => {
        dispatch({
          type: acts.SET_MODO_USER_IMPORT_DATA,
          payload: data,
        });

        return dispatch({
          type: acts.MODIFY_MODO_USER_IMPORT_SUCCESS,
        });
      })
      .catch((err) => {
        if (isCancel(err)) return null;

        dispatch({
          type: acts.SET_MODO_USER_IMPORT_DATA,
          payload: oldState,
        });

        let errorMsg;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.users_import.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.MODIFY_MODO_USER_IMPORT_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function uploadUserImport(file) {
  return (dispatch, getState) => createUserImport(dispatch, getState)
    .then(() => {
      dispatch({
        type: acts.UPLOAD_MODO_USER_IMPORT_LOADING,
      });

      const {
        config: { id: configId },
        usersImport: { id },
      } = getState().modo;

      const form = new FormData();
      form.append('file', file);

      return cancelableRequest(rqs.UPLOAD_MODO_USER_IMPORT, {
        method: 'post',
        url: getApiUrl(`modo/${configId}/users/ingestions/${id}/upload`),
        data: form,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        // Track the progress of upload
        onUploadProgress: (event) => {
          const progress = event.total ? event.loaded / event.total : 0;

          // Update progress state
          dispatch({
            type: acts.UPLOAD_MODO_USER_IMPORT_PROGRESS,
            payload: parseInt(progress * 100, 10),
          });
        },
      })
        .then(({ data }) => {
          dispatch({
            type: acts.SET_MODO_USER_IMPORT_DATA,
            payload: data,
          });

          return dispatch({
            type: acts.UPLOAD_MODO_USER_IMPORT_SUCCESS,
          });
        })
        .catch((err) => {
          if (isCancel(err)) return null;

          let errorMsg;
          switch (true) {
            case err.response
              && err.response.status === 400
              && err.response.data.key === 'file_already_uploaded':
              errorMsg = i18n.t('errors:modo.users_import.already_uploaded');
              break;

            case err.response
              && err.response.status === 404
              && err.response.data.key !== 'config_not_found':
              errorMsg = i18n.t('errors:modo.users_import.not_found');
              break;

            case err.response
                && err.response.status === 406
                && err.response.data.key === 'invalid_file_type':
              errorMsg = i18n.t('errors:modo.users_import.invalid_file_type');
              break;

            case err.response
                && err.response.status === 406
                && err.response.data.key === 'mandatory_headers_missing':
              errorMsg = i18n.t('errors:modo.users_import.mandatory_headers_missing');
              break;

            case err.response
                && err.response.status === 500
                && err.response.data.key === 'reading_row_error':
              errorMsg = i18n.t('errors:modo.users_import.reading_row_error');
              break;

            default:
              errorMsg = determineError(err);
              break;
          }

          return dispatch({
            type: acts.UPLOAD_MODO_USER_IMPORT_FAILURE,
            payload: {
              message: errorMsg,
              reqId: _get(err, 'response.data.reqId'),
            },
          });
        });
    })
    // Could only occur when chaining
    .catch(() => {});
}

export function launchUserImport() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.LAUNCH_MODO_USER_IMPORT_LOADING,
    });

    const {
      config: { id: configId },
      usersImport: { id },
    } = getState().modo;

    return axios({
      method: 'post',
      url: getApiUrl(`modo/${configId}/users/ingestions/${id}/start`),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {},
    })
      .then(() => {
        dispatch({
          type: acts.LAUNCH_MODO_USER_IMPORT_SUCCESS,
          payload: { message: i18n.t('pages:modo.users_import.launch_success') },
        });

        return dispatch({
          type: acts.RESET_MODO_USER_IMPORT,
        });
      })
      .catch((err) => {
        let errorMsg;
        switch (true) {
          case err.response && err.response.status === 406:
            errorMsg = i18n.t('errors:modo.users_import.modo_not_active');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            errorMsg = i18n.t('errors:modo.users_import.not_found');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        return dispatch({
          type: acts.LAUNCH_MODO_USER_IMPORT_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      });
  };
}

export function resetUserImport() {
  cancelRequest(rqs.CREATE_MODO_USER_IMPORT);
  cancelRequest(rqs.UPLOAD_MODO_USER_IMPORT);
  cancelRequest(rqs.MODIFY_MODO_USER_IMPORT);

  return {
    type: acts.RESET_MODO_USER_IMPORT,
  };
}

// =============================
// Stats
// =============================

export function getGlobalStats(endpointKey, storeKey, type, timeframe = null) {
  return (dispatch, getState) => {
    dispatch({
      type: acts[`GET_MODO_GLOBAL_${type}_STATS_LOADING`],
      payload: { key: storeKey },
    });

    const configId = getState().modo.config.id;

    let apiUrl = getApiUrl(`modo/${configId}/users/stats/${endpointKey}`);
    if (timeframe) apiUrl += `?timeframe=${timeframe}`;

    return axios({
      method: 'get',
      url: apiUrl,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts[`SET_MODO_GLOBAL_${type}_STATS`],
          payload: {
            data: response.data,
            timeframe,
            key: storeKey,
          },
        });

        return dispatch({
          type: acts[`GET_MODO_GLOBAL_${type}_STATS_SUCCESS`],
          payload: { key: storeKey },
        });
      })
      .catch(err => dispatch({
        type: acts[`GET_MODO_GLOBAL_${type}_STATS_FAILURE`],
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
          key: storeKey,
        },
      }),
      );
  };
}
