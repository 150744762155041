// =============================
// Imports
// =============================

// External Dependencies
import { Fragment } from 'react';
import { Typo } from '@mewo/components';

// Components
import Link from '../components/other/link';

// =============================
// Helpers
// =============================

export function buildTextNode(text, href, as = null) {
  const [beginning, link, end] = text.split(/\[|\]/);

  if (beginning && !link && !end) return beginning;

  return (
    <Fragment>
      {beginning}
      <Link inline href={href} nextAs={as || href}>
        <Typo color="primary">{link}</Typo>
      </Link>
      {end}
    </Fragment>
  );
}

export function buildExternalLinkTextNode(text, src) {
  const [beginning, link, end] = text.split(/\[|\]/);

  return (
    <Fragment>
      {beginning}
      <Link inline href={src} target="_blank" rel="noopener noreferrer">
        <Typo color="primary">
          {link}
        </Typo>
      </Link>
      {end}
    </Fragment>
  );
}

export function buildDownloadTextNode(text, src) {
  const [beginning, link, end] = text.split(/\[|\]/);

  return (
    <Fragment>
      {beginning}
      <Link inline href={src} download>
        <Typo color="primary">
          {link}
        </Typo>
      </Link>
      {end}
    </Fragment>
  );
}

export function buildActionTextNode(text, action) {
  const [beginning, link, end] = text.split(/\[|\]/);

  return (
    <Fragment>
      {beginning}
      <Typo inline onClick={action} color="primary">
        {link}
      </Typo>
      {end}
    </Fragment>
  );
}

export function buildStyledTextNode(text) {
  const [beginning, emphasis, end] = text.split(/\[|\]/);

  return (
    <Fragment>
      {beginning}
      <b>{emphasis}</b>
      {end}
    </Fragment>
  );
}
