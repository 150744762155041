// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { Item, ItemCheckBox, ItemName, ItemPrice, RenewDate, Wrapper } from './styles';

// =============================
// Component
// =============================

class ListInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        renewEach: PropTypes.string.isRequired,
      }),
    ).isRequired,
    value: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
    onChange: () => {},
  };

  handleChange = (val) => {
    const { onChange, value } = this.props;

    if (val === value) return null;
    return onChange(val);
  };

  render() {
    const { className, options, value } = this.props;

    return (
      <Wrapper className={className}>
        {options.map(option => (
          <Item
            onClick={() => this.handleChange(option.value)}
            key={option.value}
            selected={option.value === value}
          >
            <ItemCheckBox selected={option.value === value} />
            <ItemName>{option.label}</ItemName>
            <ItemPrice>
              {option.price}
              {option.currency}
              <RenewDate>
                /
                {option.renewEach}
              </RenewDate>
            </ItemPrice>
          </Item>
        ))}
      </Wrapper>
    );
  }
}

export default ListInput;
