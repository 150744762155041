// =============================
// Imports
// =============================

import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

import enLang from '@uppy/locales/lib/en_US';
import frLang from '@uppy/locales/lib/fr_FR';
import esLang from '@uppy/locales/lib/es_ES';
import deLang from '@uppy/locales/lib/de_DE';

import { withTranslation } from '../../../config/i18n';

import UppyMewo from './uppy-mewo';

import { StyledDragDrop, StyledStatusBar } from './styles';

// =============================
// Settings
// =============================

const languages = {
  en: enLang,
  fr: frLang,
  es: esLang,
  de: deLang,
};

const audioExtensions = [
  '.mp3',
  '.aac',
  '.m4a',
  '.m4p',
  '.m4b',
  '.mp4',
  '.3gp',
  '.aif',
  '.aiff',
  '.aifc',
  '.ogg',
  '.ogv',
  '.oga',
  '.ogx',
  '.spx',
  '.opus',
  '.ogm',
  '.flac',
  '.oga',
  '.alac',
  '.wav',
];

const imageExtensions = [
  '.jpeg',
  '.jpg',
  '.gif',
  '.png',
];

const stemsExtensions = [
  '.zip',
];

// =============================
// Component
// =============================

class MetaUploader extends Component {
  static propTypes = {
    /** Uploader context */
    context: PropTypes.oneOf(['ingestion', 'tracks']).isRequired,
    /** Disable upload */
    disabled: PropTypes.bool,
    /** Tus server endpoint */
    endpoint: PropTypes.string.isRequired,
    /** Height */
    height: PropTypes.string,
    /** Unique id, only unique uploaders within instance */
    id: PropTypes.string,
    /** Language for component */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Max number of files that can be uploaded */
    maxNumberOfFiles: PropTypes.number,
    /** Helper note for dashboard uploader */
    note: PropTypes.string,
    /** Function to run before uploading starts */
    onBeforeUpload: PropTypes.func,
    /** Function to run when uploading is finished for all files */
    onUploadComplete: PropTypes.func,
    /** X-Auth token */
    userToken: PropTypes.string.isRequired,
  };

  static defaultProps = {
    id: 'meta-uploader',
    disabled: false,
    height: undefined,
    maxNumberOfFiles: null,
    note: undefined,
    onBeforeUpload: null,
    onUploadComplete: null,
  };

  constructor(props) {
    super(props);

    const {
      context,
      endpoint,
      i18n: { language },
      id,
      maxNumberOfFiles,
      onBeforeUpload,
      userToken,
    } = props;

    this.state = {
      uploading: false,
      uploadComplete: false,
    };

    const allowedExtensions = [
      ...audioExtensions,
      ...(context === 'ingestion' ? [
        ...imageExtensions,
        ...stemsExtensions,
      ] : []),
    ];

    this.uppy = new Uppy({
      id,
      autoProceed: true,
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles,
      },
      onBeforeFileAdded: (current) => {
        const extension = `.${current.name.substr(current.name.lastIndexOf('.') + 1)}`.toLowerCase();

        if (!allowedExtensions.includes(extension)) return false;

        let maxSize;

        switch (true) {
          // 200 mb max
          case audioExtensions.includes(extension):
            maxSize = 200000000;
            break;

          // 20 mb max
          case imageExtensions.includes(extension):
            maxSize = 20000000;
            break;

          // 1 gb max
          case stemsExtensions.includes(extension):
          default:
            maxSize = 1000000000;
            break;
        }

        return current.data.size <= maxSize;
      },
      ...(typeof onBeforeUpload === 'function' ? {
        onBeforeUpload,
      } : {}),
      locale: languages[language],
    }).use(UppyMewo, {
      endpoint,
      concurrency: 4,
      autoRetry: true,
      headers: {
        'x-auth': userToken,
      },
    })
      .on('upload', () => this.setState({ uploading: true, uploadComplete: false }))
      .on('mewo-complete', () => {
        this.setState({ uploading: false, uploadComplete: true }, () => {
          // We reset the state of the component in case audio ingest queue is full or throws error
          setTimeout(() => this.setState({ uploading: false, uploadComplete: false }), 2000);
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { onUploadComplete } = this.props;
    const { uploadComplete: uploadWasComplete } = prevState;
    const { uploadComplete } = this.state;

    if (typeof onUploadComplete === 'function' && !uploadWasComplete && uploadComplete) {
      onUploadComplete();
    }
  }

  render() {
    const { context, disabled, height, note } = this.props;
    const { uploading, uploadComplete } = this.state;

    if (context === 'tracks') {
      // NOTE: Re-render uploader to do another upload in context tracks
      if (uploadComplete) return null;

      return (
        <Fragment>
          {!uploading ? (
            <StyledDragDrop
              height={height}
              allowMultipleFiles
              uppy={this.uppy}
              disabled={disabled}
            />
          ) : (
            <StyledStatusBar
              uppy={this.uppy}
              hidePauseResumeButton
              hideCancelButton
              showProgressDetails
            />
          )}
        </Fragment>
      );
    }

    return (
      <Dashboard
        doneButtonHandler={null}
        height={height}
        note={note}
        proudlyDisplayPoweredByUppy={false}
        showProgressDetails
        uppy={this.uppy}
        disabled={disabled}
      />
    );
  }
}

export default withTranslation([])(MetaUploader);
