// =============================
// Imports
// =============================

import styled from 'styled-components';
import ListInput from '../../../presentationals/inputs/listInput';

import ModoSitePreview from '../../../presentationals/modoSitePreview';

// =============================
// Styles
// =============================

export const NoSiteAvailable = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  width: 50rem;
`;

export const Title = styled.div`
  color: #191919;
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 2rem;
  text-align: center;
`;

export const Explanation = styled.div`
  color: #999999;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 4.8rem;
  line-height: 1.8rem;
  text-align: center;
`;

export const SiteAvailable = styled.div`
  color: #999999;
`;

export const Content = styled.div`
  display: flex;
  flex-basis: 20%;
  flex-wrap: wrap;
`;

export const StyledModoSitePreview = styled(ModoSitePreview)`
  margin-bottom: 1.6rem;
  margin-right: 1.6rem;
`;

export const ModalForm = styled.form`
  width: 100%;
`;

export const StyledListInput = styled(ListInput)`
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`;

export const PricingHelper = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 2rem;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
