// =============================
// Imports
// =============================

import { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Svg } from '@mewo/components';

// =============================
// Component
// =============================

const Metadata = memo(
  forwardRef(({ fill, onClick, theme, title, ...rest }, ref) => (
    <Svg
      fill={fill || theme.colors.text}
      onClick={onClick}
      ref={ref}
      title={title}
      viewBox="-24 -4 260 260"
      {...rest}
    >
      <path d="M93.882,53.882H34.5v15h59.382V53.882z M34.5,202.439h91.164v-15H34.5V202.439z M34.5,162.814v15h120.438v-15H34.5z   M87.191,138.188H34.5v15h52.691V138.188z M123.991,113.561H34.5v15h89.491V113.561z M95.555,88.934H34.5v15h61.055V88.934z" />
      <path d="M192.509,67.108l-50.922-49.527C134.333,10.526,120.725,5,110.605,5H27.5C15.093,5,5,15.093,5,27.5v197.203  c0,12.406,10.093,22.5,22.5,22.5h155.533c12.406,0,22.5-10.094,22.5-22.5V97.943C205.533,87.586,199.934,74.33,192.509,67.108z   M182.05,77.861c1.803,1.753,3.535,4.378,4.963,7.286h-56.329c-4.136,0-7.5-3.364-7.5-7.5V23.249  c3.154,1.44,6.032,3.226,7.944,5.086L182.05,77.861z M183.033,232.203H27.5c-4.136,0-7.5-3.364-7.5-7.5V27.5  c0-4.136,3.364-7.5,7.5-7.5h80.684v57.647c0,12.407,10.094,22.5,22.5,22.5h59.85v124.556  C190.533,228.839,187.169,232.203,183.033,232.203z" />
    </Svg>
  )),
);

Metadata.propTypes = {
  /** Icon fill color. */
  fill: PropTypes.string,
  /** onClick handler. */
  onClick: PropTypes.func,
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Icon title. */
  title: PropTypes.string,
  /** Icon width (passed to Svg via ...rest props). */
  width: PropTypes.string,
};

Metadata.defaultProps = {
  fill: '',
  onClick: null,
  theme: null,
  title: '',
  width: '2rem',
};

export default withTheme(Metadata);
