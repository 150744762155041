// =============================
// Imports
// =============================

import _get from 'lodash/get';

import * as renderersType from '../entities/renderers/types';

import { required } from '../../../../validators';

// =============================
// Config
// =============================

const config = {
  columns: [
    {
      key: 'image',
      renderComponent: {
        type: renderersType.IMAGE,
      },
      readableWidth: 5,
      immutableColumn: true,
      withoutColumnHeader: true,
    },
    {
      key: 'tenant',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (tenant) => {
          if (tenant.company_name) return tenant.company_name;
          return `${tenant.first_name} ${tenant.last_name}`;
        },
        getSearchProps: (document) => {
          const { tenant } = document;

          return {
            entity: 'label',
            filters: {
              tenants: [[
                tenant.id,
                tenant.company_name || `${tenant.first_name} ${tenant.last_name}`,
              ]],
            },
            resetQuery: true,
          };
        },
      },
      readableWidth: 12,
      immutableColumn: true,
    },
    {
      key: 'label_name',
      renderComponent: {
        type: renderersType.TEXT,
        validator: required,
        isPanelOpener: true,
        getSearchProps: document => ({
          entity: 'track',
          filters: { labels: [[document.id, document.label_name]] },
          resetQuery: true,
        }),
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      immutableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'label_code',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'ipn',
      renderComponent: {
        type: renderersType.TEXT,
      },
      readableWidth: 15,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'descriptions',
      renderComponent: {
        type: renderersType.TEXT,
        transformToDisplay: (descriptions, { gridsLanguage }) => {
          const descriptionInLanguage = descriptions.find(desc => desc.locale === gridsLanguage);

          return descriptionInLanguage ? descriptionInLanguage.value : '';
        },
        editVariant: 'descriptions',
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'notes',
      renderComponent: {
        type: renderersType.NOTES,
      },
      readableWidth: 20,
      writableWidth: 30,
      editable: true,
      editableColumn: true,
    },
    {
      key: 'agents',
      renderComponent: {
        type: renderersType.AGENTS,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'showcase',
      renderComponent: {
        type: renderersType.SHOWCASE,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'has_file',
      renderComponent: {
        type: renderersType.STATUS,
      },
      readableWidth: 10,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'has_conversion_error',
      dataKey: 'image.error_key',
      columnKey: 'conversion_error',
      renderComponent: {
        type: renderersType.TEXT,
        displayVariant: 'error',
        transformToDisplay: (errorKey, { t }) => t(`errors:conversion.${errorKey}`),
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'created_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
    {
      key: 'updated_at',
      renderComponent: {
        type: renderersType.DATE,
        withTime: true,
      },
      readableWidth: 20,
      editableColumn: true,
      sortableColumn: true,
    },
  ],
  gridModifyPick: data => ({
    label_name: _get(data, 'label_name', ''),
    descriptions: _get(data, 'descriptions', [])
      .map(({ locale, value }) => ({ locale, value })),
    label_code: _get(data, 'label_code', ''),
    ipn: _get(data, 'ipn', ''),
    notes: _get(data, 'notes', ''),
  }),
  gridUpload: {
    key: 'image',
    maxSize: 20000000,
  },
  panelUploads: {
    image: {
      maxSize: 20000000,
    },
  },
};

export default config;
