// =============================
// Imports
// =============================

// External Dependencies
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

// Components
import List from '../../../../../other/charts/list/index';

// Helpers
import { getTrackName } from '../../../../../../helpers/doc-names';
import * as pth from '../../proptypes';

// Styles
import {
  Inline,
  TitleAsSeparator,
} from '../../../../modo/stats/styles';

// =============================
// Component
// =============================

class LastDownloadedStats extends Component {
  static propTypes = {
    downloadsData: pth.statsLastDownloaded.isRequired,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { downloadsData, locale, t } = this.props;

    const lastDownloadedTracks = (downloadsData || [])
      .slice(0, 20) // Top 20 only
      .map(d => ({ value: getTrackName(d, locale), count: d.total }));

    return (
      <Fragment>
        <TitleAsSeparator mt="3rem" mb="2rem">
          {t('pages:modo.stats.last_downloads')}
        </TitleAsSeparator>
        <Inline>
          <List
            title={t('pages:modo.stats.last_downloaded_tracks')}
            header={{
              value: t('pages:modo.stats.track_title'),
              count: t('pages:modo.stats.number_of_downloads'),
            }}
            width={72.4}
            data={lastDownloadedTracks}
            disablePeriods
            maxItemsFolded={8}
          />
        </Inline>
      </Fragment>
    );
  }
}

export default LastDownloadedStats;
