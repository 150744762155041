/* eslint-disable max-classes-per-file */
// =============================
// Imports
// =============================

import axios from 'axios';
import { Plugin } from '@uppy/core';

// =============================
// Component
// =============================

/**
 * Create a wrapper around an event emitter with a `remove` method to remove
 * all events that were added using the wrapped emitter.
 */
function createEventTracker(emitter) {
  const events = [];

  return {
    on(event, fn) {
      events.push([event, fn]);
      return emitter.on(event, fn);
    },
    remove() {
      events.forEach(([event, fn]) => {
        emitter.off(event, fn);
      });
    },
  };
}

class Upload {
  constructor(uppy, plugin, opts, file) {
    this.uppy = uppy;
    this.plugin = plugin;
    this.opts = opts;
    this.file = file;

    const meta = { ...(file.meta || {}) };
    this.headers = { ...opts.headers, metadata: encodeURIComponent(JSON.stringify(meta)) };

    // Bindings
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }

  start() {
    const data = new FormData();

    data.append('file', this.file.data);

    this.source = axios.CancelToken.source();

    return axios
      .post(this.opts.endpoint, data, {
        headers: this.headers,
        cancelToken: this.source.token,
        onUploadProgress: this.onProgress,
      })
      .then(this.onSuccess)
      .catch(this.onError);
  }

  stop() {
    if (this.source) {
      this.source.cancel();
    }
  }

  onProgress(event) {
    this.uppy.emit('upload-progress', this.file, {
      uploader: this.plugin,
      id: this.file.id,
      bytesUploaded: event.loaded,
      bytesTotal: event.total,
    });
  }

  onSuccess(res) {
    this.uppy.emit('upload-success', this.file, res, null);
  }

  onError(err) {
    if (err instanceof axios.Cancel) {
      throw err;
    }

    this.uppy.log(err);
    this.uppy.emit('upload-error', this.file, err);

    throw err;
  }
}

class Uploader {
  constructor(uppy, plugin, opts) {
    this.uppy = uppy;
    this.plugin = plugin;
    this.opts = opts;
    this.queue = new Map();
    this.files = new Map();
    this.uploading = new Map();

    // bind Events
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.add = this.add.bind(this);
    this.enqueue = this.enqueue.bind(this);
    this.dequeue = this.dequeue.bind(this);
    this.onCancelAll = this.onCancelAll.bind(this);
    this.onPauseAll = this.onPauseAll.bind(this);
    this.onResumeAll = this.onResumeAll.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onRetry = this.onRetry.bind(this);
  }

  startUpload(upload) {
    upload
      .start()
      .then(() => {
        this.dequeue(upload.file.id);
        this.files.delete(upload.file.id);
        this.start();
      })
      .catch(() => {
        this.dequeue(upload.file.id);
        this.start();
      });
  }

  start() {
    if (!this.queue.size && !this.uploading.size) {
      // NOTE: This is a custom event used to signify that all uploads
      // are completed because Uppy seems to emit the "complete" event
      // although no files have been uploaded yet. We might change this one day
      this.uppy.emit('mewo-complete');
    }

    if (this.opts.concurrency > 0) {
      while (this.queue.size > 0) {
        if (this.uploading.size >= this.opts.concurrency) {
          break;
        }

        const upload = this.queue.values().next().value;

        if (!upload.file.isPaused) {
          this.uploading.set(upload.file.id, upload);
          this.queue.delete(upload.file.id);
          this.startUpload(upload);
        }
      }
    } else {
      // Upload all files at once
      this.queue.forEach((upload) => {
        this.uploading.set(upload.file.id, upload);
        this.queue.delete(upload.file.id);
        this.startUpload(upload);
      });
    }
  }

  stop() {
    this.queue.clear();
    this.uploading.forEach(upload => this.dequeue(upload.file.id));
  }

  add(file) {
    this.files.set(file.id, file);
    this.uppy.emit('upload-started', file);

    if (!file.isPaused) {
      this.enqueue(file);
    }
  }

  enqueue(file) {
    this.queue.set(file.id, new Upload(this.uppy, this.plugin, this.opts, file));
  }

  dequeue(fileId) {
    const upload = this.queue.get(fileId) || this.uploading.get(fileId);

    if (upload) {
      upload.stop();
    }

    this.queue.delete(fileId);
    this.uploading.delete(fileId);
  }

  // Events
  onCancelAll() {
    this.stop();
    this.files.clear();
  }

  onPauseAll() {
    this.stop();
    this.files.forEach((file) => {
      Object.assign(file, { isPaused: true });
    });
  }

  onResumeAll() {
    this.files.forEach((file) => {
      Object.assign(file, { isPaused: false });
      this.enqueue(file);
    });

    this.start();
  }

  onCancel(file) {
    this.dequeue(file.id);
    this.files.delete(file.id);
  }

  onPause(fileId, state) {
    const file = this.files.get(fileId);

    if (file) {
      file.isPaused = state;

      if (state === false) {
        this.enqueue(file);
        this.start();
      } else if (this.uploading.get(file.id)) {
        this.dequeue(file.id);
      }
    }
  }

  onRetry(_file) {
    const file = this.files.get(_file.id);

    if (file) {
      this.enqueue(file);
      this.start();
    }
  }
}

/**
 * Mewo uploader plugin for uppy
 * Upload files with metadata with concurrency limit
 */
class Mewo extends Plugin {
  /**
   * @param {Object} uppy Uppy instance
   * @param {Object} opts Options object
   * @param {string} opts.endpoint Server url to upload to
   * @param {boolean} opts.autoRetry Auto retry on connection lost
   * @param {number} opts.concurrency Upload concurency
   * @param {Object} opts.headers Headers
   */
  constructor(uppy, opts) {
    super(uppy, opts);

    this.type = 'uploader';
    this.id = 'Mewo';
    this.title = 'Mewo';
    this.events = createEventTracker(uppy);

    const defaultOptions = {
      endpoint: '',
      autoRetry: true,
      concurrency: 0,
      headers: {},
    };

    this.opts = { ...defaultOptions, ...opts };
    this.uploader = new Uploader(this.uppy, this, this.opts);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload(fileIDs) {
    if (fileIDs.length === 0) {
      this.uppy.log('Tus: no files to upload!');

      return;
    }

    fileIDs.forEach((fileID) => {
      const file = this.uppy.getFile(fileID);

      if (file) {
        this.uploader.add(file);
      }
    });

    this.uppy.log('Tus is uploading...');
    this.uploader.start();
  }

  install() {
    this.uppy.setState({
      capabilities: { ...this.uppy.getState().capabilities, resumableUploads: true },
    });

    this.uppy.addUploader(this.handleUpload);

    this.events.on('pause-all', this.uploader.onPauseAll);
    this.events.on('cancel-all', this.uploader.onCancelAll);
    this.events.on('resume-all', this.uploader.onResumeAll);
    this.events.on('upload-pause', this.uploader.onPause);
    this.events.on('upload-retry', this.uploader.onRetry);
    this.events.on('file-removed', this.uploader.onCancel);

    if (this.opts.autoRetry) {
      this.uppy.on('back-online', this.uppy.retryAll);
    }
  }

  uninstall() {
    this.uppy.setState({
      capabilities: { ...this.uppy.getState().capabilities, resumableUploads: false },
    });

    this.uppy.removeUploader(this.handleUpload);
    this.events.remove();

    if (this.opts.autoRetry) {
      this.uppy.off('back-online', this.uppy.retryAll);
    }
  }
}

export default Mewo;
