// =============================
// Imports
// =============================

// External Dependencies
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

// All of our reducers
import * as reducers from './reducers';

// All of our middlewares
import authMiddleware from './middlewares/authMiddleware';
import businessMiddleware from './middlewares/businessMiddleware';
import notificationsMiddleware from './middlewares/notificationsMiddleware';
import pagesMiddleware from './middlewares/pagesMiddleware';
import playerMiddleware from './middlewares/playerMiddleware';
import sidePanelMiddleware from './middlewares/sidePanelMiddleware';
import metaMiddleware from './middlewares/metaMiddleware';

// =============================
// configureStore
// =============================

const middlewares = [
  thunk,
  authMiddleware,
  businessMiddleware,
  notificationsMiddleware,
  pagesMiddleware,
  playerMiddleware,
  sidePanelMiddleware,
  metaMiddleware,
];

const bindMiddlewares = () => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middlewares));
  }

  return applyMiddleware(...middlewares);
};

// Reducers
const combinedReducers = combineReducers(reducers);

const reducer = (state, action) => {
  // NOTE: Since we do not use "getServerSideProps"
  // or "getStaticProps", we only need to hydrate once on init
  if (action.type === HYDRATE && !state.core.hydrated) {
    console.info('[Redux]: Hydrating state'); // eslint-disable-line no-console

    return {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
      core: {
        ...action.payload.core,
        hydrated: true,
      },
    };
  }

  return combinedReducers(state, action);
};

// Make store
const makeStore = () => createStore(reducer, bindMiddlewares());
const storeWrapper = createWrapper(makeStore, { debug: false });

export default storeWrapper;
