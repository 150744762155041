// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import dayjs from 'dayjs';
import { FORM_ERROR } from 'final-form';
import _pick from 'lodash/pick';

// Helpers
import determineError from '../../helpers/errors';
import { getApiUrl, getSiteOrigin, getXPreferredLanguage } from '../../helpers/misc';
import OpenReplay from '../../helpers/open-replay';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Actions
// =============================

export function getUserData(token, isAdmin = false) {
  return (dispatch) => {
    dispatch({
      type: acts.GET_USER_DATA_LOADING,
    });

    // Persist admin mode
    if (isAdmin) {
      localStorage.setItem('fromAdmin', true);
    } else {
      // eslint-disable-next-line no-param-reassign
      isAdmin = !!localStorage.getItem('fromAdmin');
    }

    return axios({
      method: 'get',
      url: getApiUrl('dashboard/user'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then(async (response) => {
        // Do not record admin session to OpenReplay
        // Redresh page with token, do not create new session
        if (response.status === 200 && !isAdmin) {
          await OpenReplay.get()?.start({
            metadata: {
              tenantId: response.data.id,
              name: `${response.data.first_name} ${response.data.last_name}`,
            },
            userID: response.data.email,
          });
        }

        dispatch({
          type: acts.SET_USER_SESSION_TOKEN,
          payload: token,
        });

        dispatch({
          type: acts.SET_USER_INFO,
          payload: response.data,
        });

        if (isAdmin) {
          dispatch({ type: acts.SET_ADMIN_MODE });
        }

        return dispatch({
          type: acts.GET_USER_DATA_SUCCESS,
        });
      })
      .catch(() => dispatch({
        type: acts.GET_USER_DATA_FAILURE,
      }),
      );
  };
}

export function getUserSessionToken({ email, password }) {
  return (dispatch) => {
    dispatch({
      type: acts.GET_USER_SESSION_TOKEN_LOADING,
    });

    localStorage.removeItem('adminToken');

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/user/login'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { email, password },
    })
      .then((response) => {
        // Regular login, we can record session to OpenReplay
        // Force new session
        if (response.status === 200) {
          OpenReplay.get()?.start({
            metadata: {
              tenantId: response.data.id,
              name: `${response.data.first_name} ${response.data.last_name}`,
            },
            userID: response.data.email,
            forceNew: true,
          });
        }

        dispatch({
          type: acts.SET_USER_SESSION_TOKEN,
          payload: response.headers['x-auth'],
        });

        dispatch({
          type: acts.SET_USER_INFO,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_USER_SESSION_TOKEN_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: acts.GET_USER_SESSION_TOKEN_FAILURE,
        });

        switch (true) {
          case err.response && err.response.status === 429:
            return Promise.resolve({
              [FORM_ERROR]: i18n.t('errors:auth.too_many_tries', {
                nextTry: dayjs().to(dayjs(err.response.data.error.nextValidRequestDate)),
              }),
            });

          case err.response && err.response.data.key === 'wrong_credentials':
            return Promise.resolve({ [FORM_ERROR]: i18n.t('errors:auth.wrong_credentials') });

          default:
            return Promise.resolve({ [FORM_ERROR]: determineError(err) });
        }
      });
  };
}

export function registerUser(_data) {
  const registerData = _pick(_data, [
    'first_name',
    'last_name',
    'email',
    'password',
    'job',
    'phone_number',
    'company_name',
    'company_type',
    'country',
  ]);

  return (dispatch) => {
    dispatch({
      type: acts.REGISTER_USER_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/user'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: {
        ...registerData,
        language: i18n.language,
        site_url: getSiteOrigin(),
        agree_to_tos: true, // mandatory value
      },
    })
      .then((res) => {
        dispatch({
          type: acts.SET_USER_SESSION_TOKEN,
          payload: res.headers['x-auth'],
        });

        dispatch({
          type: acts.SET_USER_INFO,
          payload: res.data,
        });

        return dispatch({
          type: acts.REGISTER_USER_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: acts.REGISTER_USER_FAILURE,
        });

        switch (true) {
          case err.response && err.response.data.key === 'email_already_exists':
            return Promise.resolve({ [FORM_ERROR]: i18n.t('errors:auth.email_already_taken') });

          default:
            return Promise.resolve({ [FORM_ERROR]: determineError(err) });
        }
      });
  };
}

export function sendRecoverPasswordMail({ email }) {
  return (dispatch) => {
    dispatch({
      type: acts.RECOVER_PASSWORD_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/users/password/reset'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { email, site_url: `${getSiteOrigin()}/resetpassword` },
    })
      .then(() => dispatch({
        type: acts.RECOVER_PASSWORD_SUCCESS,
      }),
      )
      .catch((err) => {
        dispatch({
          type: acts.RECOVER_PASSWORD_FAILURE,
        });

        switch (true) {
          case err.response && err.response.status === 404:
            return Promise.resolve({ [FORM_ERROR]: i18n.t('errors:auth.user_not_found') });

          default:
            return Promise.resolve({ [FORM_ERROR]: determineError(err) });
        }
      });
  };
}

export function resetUserPassword({ password, token }) {
  return (dispatch) => {
    dispatch({
      type: acts.RESET_PASSWORD_LOADING,
    });

    return axios({
      method: 'put',
      url: getApiUrl('dashboard/users/password'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { password, token },
    })
      .then((res) => {
        dispatch({
          type: acts.SET_USER_SESSION_TOKEN,
          payload: res.headers['x-auth'],
        });

        dispatch({
          type: acts.SET_USER_INFO,
          payload: res.data,
        });

        return dispatch({
          type: acts.RESET_PASSWORD_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: acts.RESET_PASSWORD_FAILURE,
        });

        switch (true) {
          case err.response && err.response.status === 429:
            return Promise.resolve({
              [FORM_ERROR]: i18n.t('errors:auth.too_many_tries', {
                nextTry: dayjs().to(dayjs(err.response.data.error.nextValidRequestDate)),
              }),
            });

          case err.response && err.response.status === 400:
            return Promise.resolve({
              [FORM_ERROR]: i18n.t('errors:auth.reset_password_token_required'),
            });

          case err.response && err.response.status === 403:
            return Promise.resolve({
              [FORM_ERROR]: i18n.t('errors:auth.reset_password_token_expired'),
            });

          case err.response && err.response.status === 404:
            return Promise.resolve({
              [FORM_ERROR]: i18n.t('errors:auth.reset_password_token_not_found'),
            });

          default:
            return Promise.resolve({ [FORM_ERROR]: determineError(err) });
        }
      });
  };
}

export function setUserPassword({ password, token }) {
  return (dispatch) => {
    dispatch({
      type: acts.SET_PASSWORD_LOADING,
    });

    return axios({
      method: 'post',
      url: getApiUrl('dashboard/users/register/activate'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'x-preferred-language': getXPreferredLanguage(),
      },
      data: { password, token },
    })
      .then((res) => {
        dispatch({
          type: acts.SET_USER_SESSION_TOKEN,
          payload: res.headers['x-auth'],
        });

        dispatch({
          type: acts.SET_USER_INFO,
          payload: res.data,
        });

        return dispatch({
          type: acts.SET_PASSWORD_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: acts.SET_PASSWORD_FAILURE,
        });

        switch (true) {
          case err.response && err.response.status === 429:
            return Promise.resolve({
              [FORM_ERROR]: i18n.t('errors:auth.too_many_tries', {
                nextTry: dayjs().to(dayjs(err.response.data.error.nextValidRequestDate)),
              }),
            });

          case err.response && err.response.status === 404:
            return Promise.resolve({
              [FORM_ERROR]: i18n.t('errors:auth.set_password_token_not_found'),
            });

          default:
            return Promise.resolve({ [FORM_ERROR]: determineError(err) });
        }
      });
  };
}

export function logout() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.LOGOUT_USER_LOADING,
    });

    const tracker = OpenReplay.get();

    if (tracker?.isActive()) {
      tracker.stop();
    }

    localStorage.removeItem('fromAdmin');

    // Regardless of the consequence of this action, we will
    // Treat it as a success
    return axios({
      method: 'post',
      url: getApiUrl('dashboard/user/logout'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .catch(() => {})
      .finally(() => dispatch({
        type: acts.LOGOUT_USER_SUCCESS,
      }));
  };
}
