/* eslint-disable max-len */
// =============================
// Imports
// =============================

// External Dependencies
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';

// Components
import MewoInput from '../../../../presentationals/mewoInput';

// Constants
import * as panelTypes from '../../../../../store/constants/SidePanelTypes';

// Actions
import {
  exportMetaAlbumTracks,
  exportMetaCatalogTracks,
  exportMetaPlaylistTracks,
  exportMetaBriefTracks,
  exportMetaTrack,
} from '../../../../../store/actions/MetaActions';

// Helpers
import * as pth from '../../../../../helpers/proptypes';
import { buildActionTextNode, buildStyledTextNode } from '../../../../../helpers/locales';

// Styles
import { ModalForm } from '../../../../layouts/meta/common.styles';
import { ConfirmationModal } from '../styles';

import {
  ModalContent,
  ModalTitle,
  ModalDesc,
  ButtonWrapper,
  StyledButton,
} from '../../../../layouts/global.styles';

import {
  InputsWrapper,
  InlineText,
  StyledSelectInput,
  AutotaggingTitle,
  AutotaggingDesc,
  MaiaCapacitiesDesc,
  PlaylistAgentTracksNotice,
  ConfirmInput,
} from './styles';

// =============================
// Component
// =============================

const actions = {
  [panelTypes.META_TRACK_PANEL]: {
    export: exportMetaTrack,
  },
  [panelTypes.META_ALBUM_PANEL]: {
    export: exportMetaAlbumTracks,
  },
  [panelTypes.META_PLAYLIST_PANEL]: {
    export: exportMetaPlaylistTracks,
  },
  [panelTypes.META_BRIEF_PANEL]: {
    export: exportMetaBriefTracks,
  },
  [panelTypes.META_CATALOG_PANEL]: {
    export: exportMetaCatalogTracks,
  },
};

class Autotag extends Component {
  static propTypes = {
    autotag: PropTypes.func,
    autotagModal: PropTypes.shape({
      opened: PropTypes.bool.isRequired,
      nbTracks: PropTypes.number.isRequired,
      nbBillableTracks: PropTypes.number.isRequired,
      nbTrackVersions: PropTypes.number,
      nbBillableTrackVersions: PropTypes.number,
    }).isRequired,
    exportFnc: PropTypes.func.isRequired,
    lang: PropTypes.shape({
      autotagTitle: PropTypes.string,
      autotagDesc: PropTypes.string,
    }),
    panel: pth.sidePanel.isRequired,
    t: PropTypes.func.isRequired,
    toggleAutotagModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autotag: null,
    lang: {},
  };

  state = {
    // Steps can be:
    // - selection
    // - launch
    currentStep: 'selection',
  };

  autotagDocument = async (data) => {
    const { autotag, panel, toggleAutotagModal } = this.props;

    if (autotag) {
      await autotag({
        strategy: data.strategy,
        affect_all_tracks: data.affect === 'affect_all_tracks',
        ...(panel.type === panelTypes.META_TRACK_PANEL ? {
          affect_track_versions: data.group === 'track_and_versions',
        } : {}),
      });
    }

    toggleAutotagModal(false);

    this.setState({ currentStep: 'selection' });
  };

  getBillableAutotagTracks = (formValues) => {
    const { autotagModal, panel } = this.props;

    let billableTracks = autotagModal.nbBillableTracks;

    if (panel.type === panelTypes.META_TRACK_PANEL && formValues.group === 'track_and_versions') {
      billableTracks += autotagModal.nbBillableTrackVersions;
    }

    return billableTracks;
  };

  getNonBillableAutotagTracks = (formValues) => {
    const { autotagModal, panel } = this.props;

    if (formValues.affect === 'affect_not_tagged_tracks') return 0;

    let nonBillableTracks = autotagModal.nbTracks - autotagModal.nbBillableTracks;

    if (panel.type === panelTypes.META_TRACK_PANEL && formValues.group === 'track_and_versions') {
      nonBillableTracks += autotagModal.nbTrackVersions - autotagModal.nbBillableTrackVersions;
    }

    return nonBillableTracks;
  };

  renderStepButtons = (formValues, formIsSubmitting) => {
    const { t, toggleAutotagModal } = this.props;
    const { currentStep } = this.state;

    if (currentStep === 'selection') {
      return (
        <ButtonWrapper>
          <StyledButton
            onClick={() => toggleAutotagModal(false)}
            disabled={formIsSubmitting}
            color="cancel"
            fillVariant="outline"
            type="button"
          >
            {t('common:form.cancel')}
          </StyledButton>

          <StyledButton
            key="stepper" // For firefox https://github.com/facebook/react/issues/8554#issuecomment-267095552
            type="button"
            onClick={() => this.setState({ currentStep: 'launch' })}
            disabled={
              formIsSubmitting
              || (
                !this.getBillableAutotagTracks(formValues)
                && !this.getNonBillableAutotagTracks(formValues)
              )
            }
          >
            {t('components:autotag.submit_and_next')}
          </StyledButton>
        </ButtonWrapper>
      );
    }

    return (
      <ButtonWrapper>
        <StyledButton
          onClick={() => this.setState({ currentStep: 'selection' })}
          disabled={formIsSubmitting}
          color="cancel"
          fillVariant="outline"
          type="button"
        >
          {t('components:autotag.back_to_prev')}
        </StyledButton>

        <StyledButton
          key="submitter" // For firefox https://github.com/facebook/react/issues/8554#issuecomment-267095552
          disabled={
            formIsSubmitting
            || formValues.confirm !== 'AUTOTAG'
            || (
              !this.getBillableAutotagTracks(formValues)
              && !this.getNonBillableAutotagTracks(formValues)
            )
          }
          type="submit"
        >
          {t('components:autotag.launch')}
        </StyledButton>
      </ButtonWrapper>
    );
  };

  renderSelectionForm = (formValues) => {
    const { panel, t } = this.props;

    return (
      <Fragment>
        <AutotaggingTitle>
          {t('components:autotag.what_i_want')}
        </AutotaggingTitle>
        <InputsWrapper>
          <InlineText>
            {t('components:autotag.i_want_to')}
          </InlineText>
          <Field name="strategy">
            {({ input, meta }) => (
              <MewoInput
                meta={meta}
                input={{
                  ...input,
                  onChange: (v) => {
                    if (v) input.onChange(v);
                  },
                }}
                use={StyledSelectInput}
                options={[
                  {
                    label: t('components:autotag.add_only'),
                    value: 'add_only',
                  },
                  {
                    label: t('components:autotag.add_and_remove'),
                    value: 'add_and_remove',
                  },
                ]}
                renderOptionsVariant="classic"
                renderValuesVariant="text"
                width="29rem"
              />
            )}
          </Field>
          <InlineText>
            {panel.type === panelTypes.META_TRACK_PANEL
              ? t('components:autotag.affect_this')
              : t('components:autotag.affect_tracks')}
          </InlineText>
          {panel.type === panelTypes.META_TRACK_PANEL && (
            <Field name="group">
              {({ input, meta }) => (
                <MewoInput
                  meta={meta}
                  input={{
                    ...input,
                    onChange: (v) => {
                      if (v) input.onChange(v);
                    },
                  }}
                  use={StyledSelectInput}
                  options={[
                    {
                      label: t('components:autotag.track_only'),
                      value: 'track_only',
                    },
                    {
                      label: t('components:autotag.track_and_versions'),
                      value: 'track_and_versions',
                    },
                  ]}
                  renderOptionsVariant="classic"
                  renderValuesVariant="text"
                  width="25rem"
                />
              )}
            </Field>
          )}
          {(panel.type !== panelTypes.META_TRACK_PANEL || formValues.group === 'track_and_versions') && (
            <Fragment>
              <Field name="affect">
                {({ input, meta }) => (
                  <MewoInput
                    meta={meta}
                    input={{
                      ...input,
                      onChange: (v) => {
                        if (v) input.onChange(v);
                      },
                    }}
                    use={StyledSelectInput}
                    options={[
                      {
                        label: t('components:autotag.affect_not_tagged_tracks'),
                        value: 'affect_not_tagged_tracks',
                      },
                      {
                        label: t('components:autotag.affect_tagged_tracks'),
                        value: 'affect_all_tracks',
                      },
                    ]}
                    renderOptionsVariant="classic"
                    renderValuesVariant="text"
                    width="28rem"
                  />
                )}
              </Field>
              <InlineText>
                {t('components:autotag.been_autotagged')}
              </InlineText>
            </Fragment>
          )}
        </InputsWrapper>
      </Fragment>
    );
  };

  renderWhatWillHappen = (formValues) => {
    const { panel, t } = this.props;
    const { currentStep } = this.state;

    let baseContent = <AutotaggingDesc>{t('components:autotag.nothing_to_autotag')}</AutotaggingDesc>;

    if (this.getBillableAutotagTracks(formValues) || this.getNonBillableAutotagTracks(formValues)) {
      let dynamicContent = null;

      switch (true) {
        case panel.type === panelTypes.META_TRACK_PANEL && formValues.group === 'track_only': {
          dynamicContent = (
            <AutotaggingDesc>
              {formValues.strategy === 'add_only'
                ? buildStyledTextNode(t('components:autotag.maia_will_enrich_track'))
                : buildStyledTextNode(t('components:autotag.maia_will_analyze_track'))}
            </AutotaggingDesc>
          );
          break;
        }

        case panel.type === panelTypes.META_TRACK_PANEL && formValues.group === 'track_and_versions': {
          const prefix = formValues.strategy === 'add_only'
            ? buildStyledTextNode(t('components:autotag.maia_will_enrich_track_and_versions'))
            : buildStyledTextNode(t('components:autotag.maia_will_analyze_track_and_versions'));

          const suffix = formValues.affect === 'affect_not_tagged_tracks'
            ? buildStyledTextNode(t('components:autotag.never_been_autotagged'))
            : buildStyledTextNode(t('components:autotag.even_if_autotagged'));

          dynamicContent = (
            <AutotaggingDesc>
              {prefix}
              &nbsp;
              {suffix}
            </AutotaggingDesc>
          );
          break;
        }

        case panel.type !== panelTypes.META_TRACK_PANEL: {
          const prefix = formValues.strategy === 'add_only'
            ? buildStyledTextNode(t('components:autotag.maia_will_enrich_tracks'))
            : buildStyledTextNode(t('components:autotag.maia_will_analyze_tracks'));

          const suffix = formValues.affect === 'affect_not_tagged_tracks'
            ? buildStyledTextNode(t('components:autotag.never_been_autotagged'))
            : buildStyledTextNode(t('components:autotag.even_if_autotagged'));

          dynamicContent = (
            <AutotaggingDesc>
              {prefix}
              &nbsp;
              {suffix}
            </AutotaggingDesc>
          );
          break;
        }

        default:
          break;
      }

      baseContent = (
        <div>
          {dynamicContent}
          <AutotaggingDesc>
            {formValues.strategy === 'add_only'
              ? buildStyledTextNode(t('components:autotag.will_add_only'))
              : buildStyledTextNode(t('components:autotag.will_add_and_remove'))}
          </AutotaggingDesc>
        </div>
      );
    }

    return (
      <ModalDesc
        ml="auto"
        mr="auto"
        mt={currentStep === 'launch' ? 0 : '4.8rem'}
        mb={currentStep === 'launch' ? '2rem' : '4.8rem'}
      >
        <AutotaggingTitle>
          {currentStep === 'launch'
            ? t('components:autotag.recap')
            : t('components:autotag.what_will_happen')}
        </AutotaggingTitle>
        {baseContent}
      </ModalDesc>
    );
  };

  renderPricing = (formValues) => {
    const { panel, t } = this.props;

    const billableAutotagTracks = this.getBillableAutotagTracks(formValues);
    const nonBillableAutotagTracks = this.getNonBillableAutotagTracks(formValues);

    if (!billableAutotagTracks && !nonBillableAutotagTracks) {
      return null;
    }

    return (
      <ModalDesc
        mt="4.8rem"
        mb="4.8rem"
        ml="auto"
        mr="auto"
      >
        <AutotaggingTitle>{t('components:autotag.how_we_charge')}</AutotaggingTitle>
        <AutotaggingDesc>{t('components:autotag.not_autotagged_pricing')}</AutotaggingDesc>
        <AutotaggingDesc>{t('components:autotag.already_autotagged_pricing')}</AutotaggingDesc>
        {!!billableAutotagTracks && (
          <AutotaggingDesc mt="2rem" bold>
            {t('components:autotag.pricing', {
              count: billableAutotagTracks,
              price: (
                parseFloat(process.env.AUTOTAG_PRICE)
                * billableAutotagTracks
              ).toFixed(2),
            })}
          </AutotaggingDesc>
        )}
        {!!nonBillableAutotagTracks && (
          <AutotaggingDesc mt="2rem" bold>
            {billableAutotagTracks
              ? t('components:autotag.without_cost', { count: nonBillableAutotagTracks })
              : t('components:autotag.without_cost_only', { count: nonBillableAutotagTracks })}
          </AutotaggingDesc>
        )}
        {panel.type === panelTypes.META_PLAYLIST_PANEL && (
          <PlaylistAgentTracksNotice>
            {t('components:autotag.playlist_agents_tracks_notice')}
          </PlaylistAgentTracksNotice>
        )}
      </ModalDesc>
    );
  };

  render() {
    const { autotagModal, exportFnc, lang, panel, t } = this.props;
    const { currentStep } = this.state;

    if (!autotagModal.opened) return null;

    return (
      <ConfirmationModal>
        <ModalContent width="90">
          <ModalTitle mb={currentStep === 'launch' ? '4.8rem' : '1.6rem'}>
            {lang.autotagTitle || t('components:autotag.title')}
          </ModalTitle>
          {currentStep === 'selection' && (
            <ModalDesc mb="4.8rem">
              {buildActionTextNode(
                lang.autotagDesc || t('components:autotag.desc'),
                () => exportFnc([panel.id]),
              )}
            </ModalDesc>
          )}

          <Form
            onSubmit={this.autotagDocument}
            initialValues={{
              strategy: 'add_only',
              affect: panel.type === panelTypes.META_TRACK_PANEL
                ? 'affect_all_tracks'
                : 'affect_not_tagged_tracks',
              group: 'track_only',
            }}
          >
            {({ handleSubmit, submitting, values }) => (
              <ModalForm onSubmit={handleSubmit}>
                {currentStep === 'selection' && (
                  <Fragment>
                    {this.renderSelectionForm(values)}
                    {this.renderWhatWillHappen(values)}
                    <MaiaCapacitiesDesc>
                      {t('components:autotag.maia_capacities_notice')}
                    </MaiaCapacitiesDesc>
                  </Fragment>
                )}

                {currentStep === 'launch' && (
                  <Fragment>
                    {this.renderWhatWillHappen(values)}
                    {this.renderPricing(values)}
                    <Field
                      label={t('components:autotag.confirm')}
                      name="confirm"
                      component={MewoInput}
                      use={ConfirmInput}
                      type="text"
                    />
                  </Fragment>
                )}

                {this.renderStepButtons(values, submitting)}
              </ModalForm>
            )}
          </Form>
        </ModalContent>
      </ConfirmationModal>
    );
  }
}

export default connect(() => ({}), (dispatch, { panel }) => ({
  exportFnc: (...args) => dispatch(actions[panel.type].export(...args)),
}))(Autotag);
