// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Div } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled(Div)`
  background-color: #fff;
  border-radius: 1rem;
  height: auto;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  line-height: 1.3;
  justify-content: space-between;
  padding: 2rem 2rem 0;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const Switchers = styled.div`
  display: inline-flex;
`;

export const Switcher = styled.div`
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &:not(:first-of-type) {
    margin-left: 1rem;
  }

  &:hover {
    border-bottom: 0.2rem solid #999999;
  }

  ${({ isCurrent }) => isCurrent
    && css`
      border-bottom: 0.2rem solid #999999;
    `};
`;

export const LineHeader = styled.div`
  align-items: center;
  border-bottom: 0.1rem solid #e5e5e5;
  display: flex;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.3;
  padding: 1rem 2rem;
`;

export const Line = styled(Div)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  padding: 1rem 2rem;
  width: 100%;
`;

export const Column = styled.span`
  width: ${({ width }) => width};
`;

export const NoData = styled.div`
  text-align: center;
  padding: 2rem;
`;

export const SeeMore = styled.div`
  border-top: 0.1rem solid #e5e5e5;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.3;
  padding: 1rem 2rem;
  text-align: center;
`;
