/* Core */
export const INITIALIZE_APP = 'INITIALIZE_APP';

export const OPEN_ROUTER_MODAL = 'OPEN_ROUTER_MODAL';
export const CLOSE_ROUTER_MODAL = 'CLOSE_ROUTER_MODAL';

export const SET_LOCAL_PREFERENCES = 'SET_LOCAL_PREFERENCES';
export const SET_LOCAL_META_COLUMNS_PREFERENCES = 'SET_LOCAL_META_COLUMNS_PREFERENCES';

/* Options */
export const SET_OPTIONS = 'SET_OPTIONS';

export const GET_OPTIONS_LOADING = 'GET_OPTIONS_LOADING';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';

/* Notifications */
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';

// =============================
// Sidepanel
// =============================

export const OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL';
export const REMOVE_SIDE_PANEL = 'REMOVE_SIDE_PANEL';
export const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';
export const REQUEST_CLOSE_SIDE_PANEL = 'REQUEST_CLOSE_SIDE_PANEL';
export const SET_PANEL_DATA = 'SET_PANEL_DATA';
export const SET_PANEL_ADDITIONAL_DATA = 'SET_PANEL_ADDITIONAL_DATA';
export const SET_PANEL_HAS_AUTOSAVE = 'SET_PANEL_HAS_AUTOSAVE';

export const REQUEST_GRID_REFRESH_FROM_SIDE_PANEL = 'REQUEST_GRID_REFRESH_FROM_SIDE_PANEL';

export const TRIGGER_PANEL_AUTOSAVE_CHECK = 'TRIGGER_PANEL_AUTOSAVE_CHECK';
export const RESOLVE_PANEL_AUTOSAVE_CHECK = 'RESOLVE_PANEL_AUTOSAVE_CHECK';

export const GET_SIDE_PANEL_DATA_LOADING = 'GET_SIDE_PANEL_DATA_LOADING';
export const GET_SIDE_PANEL_DATA_SUCCESS = 'GET_SIDE_PANEL_DATA_SUCCESS';
export const GET_SIDE_PANEL_DATA_FAILURE = 'GET_SIDE_PANEL_DATA_FAILURE';

export const GET_SIDE_PANEL_ADDITIONAL_DATA_LOADING = 'GET_SIDE_PANEL_ADDITIONAL_DATA_LOADING';
export const GET_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS = 'GET_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS';
export const GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE = 'GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE';

export const MODIFY_SIDE_PANEL_DATA_LOADING = 'MODIFY_SIDE_PANEL_DATA_LOADING';
export const MODIFY_SIDE_PANEL_DATA_SUCCESS = 'MODIFY_SIDE_PANEL_DATA_SUCCESS';
export const MODIFY_SIDE_PANEL_DATA_FAILURE = 'MODIFY_SIDE_PANEL_DATA_FAILURE';

export const MODIFY_SIDE_PANEL_ADDITIONAL_DATA_LOADING = 'MODIFY_SIDE_PANEL_ADDITIONAL_DATA_LOADING';
export const MODIFY_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS = 'MODIFY_SIDE_PANEL_ADDITIONAL_DATA_SUCCESS';
export const MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE = 'MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE';

export const AUTOTAG_SIDE_PANEL_LOADING = 'AUTOTAG_SIDE_PANEL_LOADING';
export const AUTOTAG_SIDE_PANEL_SUCCESS = 'AUTOTAG_SIDE_PANEL_SUCCESS';
export const AUTOTAG_SIDE_PANEL_FAILURE = 'AUTOTAG_SIDE_PANEL_FAILURE';

export const UPLOAD_SIDE_PANEL_FILE_PROGRESS = 'UPLOAD_SIDE_PANEL_FILE_PROGRESS';

export const UPLOAD_SIDE_PANEL_FILE_LOADING = 'UPLOAD_SIDE_PANEL_FILE_LOADING';
export const UPLOAD_SIDE_PANEL_FILE_SUCCESS = 'UPLOAD_SIDE_PANEL_FILE_SUCCESS';
export const UPLOAD_SIDE_PANEL_FILE_FAILURE = 'UPLOAD_SIDE_PANEL_FILE_FAILURE';

export const CANCEL_UPLOAD_SIDE_PANEL_FILE = 'CANCEL_UPLOAD_SIDE_PANEL_FILE';

export const DELETE_SIDE_PANEL_FILE_LOADING = 'DELETE_SIDE_PANEL_FILE_LOADING';
export const DELETE_SIDE_PANEL_FILE_SUCCESS = 'DELETE_SIDE_PANEL_FILE_SUCCESS';
export const DELETE_SIDE_PANEL_FILE_FAILURE = 'DELETE_SIDE_PANEL_FILE_FAILURE';

export const DUPLICATE_SIDE_PANEL_DATA_LOADING = 'DUPLICATE_SIDE_PANEL_DATA_LOADING';
export const DUPLICATE_SIDE_PANEL_DATA_SUCCESS = 'DUPLICATE_SIDE_PANEL_DATA_SUCCESS';
export const DUPLICATE_SIDE_PANEL_DATA_FAILURE = 'DUPLICATE_SIDE_PANEL_DATA_FAILURE';

export const DELETE_SIDE_PANEL_LOADING = 'DELETE_SIDE_PANEL_LOADING';
export const DELETE_SIDE_PANEL_SUCCESS = 'DELETE_SIDE_PANEL_SUCCESS';
export const DELETE_SIDE_PANEL_FAILURE = 'DELETE_SIDE_PANEL_FAILURE';

// =============================
// Auth
// =============================

/* Auth: session */
export const GET_USER_SESSION_TOKEN_LOADING = 'GET_USER_SESSION_TOKEN_LOADING';
export const GET_USER_SESSION_TOKEN_FAILURE = 'GET_USER_SESSION_TOKEN_FAILURE';
export const GET_USER_SESSION_TOKEN_SUCCESS = 'GET_USER_SESSION_TOKEN_SUCCESS';

export const GET_USER_DATA_LOADING = 'GET_USER_DATA_LOADING';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';

export const SET_USER_SESSION_TOKEN = 'SET_USER_SESSION_TOKEN';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_INFO_ONLY = 'SET_USER_INFO_ONLY';
export const SET_ADMIN_MODE = 'SET_ADMIN_MODE';

/* Auth: recover password */
export const RECOVER_PASSWORD_LOADING = 'RECOVER_PASSWORD_LOADING';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';

/* Auth: register user */
export const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

/* Auth: reset password */
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

/* Auth: set password */
export const SET_PASSWORD_LOADING = 'SET_PASSWORD_LOADING';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';

/* Auth: logout */
export const LOGOUT_USER_LOADING = 'LOGOUT_USER_LOADING';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

// =============================
// User
// =============================

/* User: subscriptions */
export const GET_USER_SUBSCRIPTIONS_LOADING = 'GET_USER_SUBSCRIPTIONS_LOADING';
export const GET_USER_SUBSCRIPTIONS_FAILURE = 'GET_USER_SUBSCRIPTIONS_FAILURE';
export const GET_USER_SUBSCRIPTIONS_SUCCESS = 'GET_USER_SUBSCRIPTIONS_SUCCESS';
export const SET_USER_SUBSCRIPTIONS = 'SET_USER_SUBSCRIPTIONS';

/* User: modify account */
export const MODIFY_USER_LOADING = 'MODIFY_USER_LOADING';
export const MODIFY_USER_FAILURE = 'MODIFY_USER_FAILURE';
export const MODIFY_USER_SUCCESS = 'MODIFY_USER_SUCCESS';

export const MODIFY_USER_PASSWORD_LOADING = 'MODIFY_USER_PASSWORD_LOADING';
export const MODIFY_USER_PASSWORD_FAILURE = 'MODIFY_USER_PASSWORD_FAILURE';
export const MODIFY_USER_PASSWORD_SUCCESS = 'MODIFY_USER_PASSWORD_SUCCESS';

/* User: Organization Settings */
export const GET_ORGANIZATION_SETTINGS_LOADING = 'GET_ORGANIZATION_SETTINGS_LOADING';
export const GET_ORGANIZATION_SETTINGS_FAILURE = 'GET_ORGANIZATION_SETTINGS_FAILURE';
export const GET_ORGANIZATION_SETTINGS_SUCCESS = 'GET_ORGANIZATION_SETTINGS_SUCCESS';
export const SET_ORGANIZATION_SETTINGS = 'SET_ORGANIZATION_SETTINGS';

export const MODIFY_ORGANIZATION_SETTINGS_LOADING = 'MODIFY_ORGANIZATION_SETTINGS_LOADING';
export const MODIFY_ORGANIZATION_SETTINGS_SUCCESS = 'MODIFY_ORGANIZATION_SETTINGS_SUCCESS';
export const MODIFY_ORGANIZATION_SETTINGS_FAILURE = 'MODIFY_ORGANIZATION_SETTINGS_FAILURE';

/* User: collaborators */
export const GET_USER_COLLABORATORS_LOADING = 'GET_USER_COLLABORATORS_LOADING';
export const GET_USER_COLLABORATORS_FAILURE = 'GET_USER_COLLABORATORS_FAILURE';
export const GET_USER_COLLABORATORS_SUCCESS = 'GET_USER_COLLABORATORS_SUCCESS';
export const SET_USER_COLLABORATORS = 'SET_USER_COLLABORATORS';

export const CREATE_USER_COLLABORATOR_LOADING = 'CREATE_USER_COLLABORATOR_LOADING';
export const CREATE_USER_COLLABORATOR_SUCCESS = 'CREATE_USER_COLLABORATOR_SUCCESS';
export const CREATE_USER_COLLABORATOR_FAILURE = 'CREATE_USER_COLLABORATOR_FAILURE';

export const MODIFY_USER_COLLABORATOR_LOADING = 'MODIFY_USER_COLLABORATOR_LOADING';
export const MODIFY_USER_COLLABORATOR_SUCCESS = 'MODIFY_USER_COLLABORATOR_SUCCESS';
export const MODIFY_USER_COLLABORATOR_FAILURE = 'MODIFY_USER_COLLABORATOR_FAILURE';

export const DELETE_USER_COLLABORATOR_LOADING = 'DELETE_USER_COLLABORATOR_LOADING';
export const DELETE_USER_COLLABORATOR_SUCCESS = 'DELETE_USER_COLLABORATOR_SUCCESS';
export const DELETE_USER_COLLABORATOR_FAILURE = 'DELETE_USER_COLLABORATOR_FAILURE';

/* User: collaborators */
export const GET_USER_AGENTS_LOADING = 'GET_USER_AGENTS_LOADING';
export const GET_USER_AGENTS_FAILURE = 'GET_USER_AGENTS_FAILURE';
export const GET_USER_AGENTS_SUCCESS = 'GET_USER_AGENTS_SUCCESS';
export const SET_USER_AGENTS = 'SET_USER_AGENTS';

export const CREATE_USER_AGENT_LOADING = 'CREATE_USER_AGENT_LOADING';
export const CREATE_USER_AGENT_SUCCESS = 'CREATE_USER_AGENT_SUCCESS';
export const CREATE_USER_AGENT_FAILURE = 'CREATE_USER_AGENT_FAILURE';

export const MODIFY_USER_AGENT_LOADING = 'MODIFY_USER_AGENT_LOADING';
export const MODIFY_USER_AGENT_SUCCESS = 'MODIFY_USER_AGENT_SUCCESS';
export const MODIFY_USER_AGENT_FAILURE = 'MODIFY_USER_AGENT_FAILURE';

export const DELETE_USER_AGENT_LOADING = 'DELETE_USER_AGENT_LOADING';
export const DELETE_USER_AGENT_SUCCESS = 'DELETE_USER_AGENT_SUCCESS';
export const DELETE_USER_AGENT_FAILURE = 'DELETE_USER_AGENT_FAILURE';

/* User: providers */
export const GET_USER_PROVIDERS_LOADING = 'GET_USER_PROVIDERS_LOADING';
export const GET_USER_PROVIDERS_FAILURE = 'GET_USER_PROVIDERS_FAILURE';
export const GET_USER_PROVIDERS_SUCCESS = 'GET_USER_PROVIDERS_SUCCESS';
export const SET_USER_PROVIDERS = 'SET_USER_PROVIDERS';

export const VALIDATE_USER_PROVIDER_LOADING = 'VALIDATE_USER_PROVIDER_LOADING';
export const VALIDATE_USER_PROVIDER_SUCCESS = 'VALIDATE_USER_PROVIDER_SUCCESS';
export const VALIDATE_USER_PROVIDER_FAILURE = 'VALIDATE_USER_PROVIDER_FAILURE';

export const DELETE_USER_PROVIDER_LOADING = 'DELETE_USER_PROVIDER_LOADING';
export const DELETE_USER_PROVIDER_SUCCESS = 'DELETE_USER_PROVIDER_SUCCESS';
export const DELETE_USER_PROVIDER_FAILURE = 'DELETE_USER_PROVIDER_FAILURE';

/* User: notifications */
export const GET_USER_NOTIFICATIONS_LOADING = 'GET_USER_NOTIFICATIONS_LOADING';
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';

export const GET_LATEST_NOTIFICATIONS_LOADING = 'GET_LATEST_NOTIFICATIONS_LOADING';
export const GET_LATEST_NOTIFICATIONS_FAILURE = 'GET_LATEST_NOTIFICATIONS_FAILURE';
export const GET_LATEST_NOTIFICATIONS_SUCCESS = 'GET_LATEST_NOTIFICATIONS_SUCCESS';
export const SET_LATEST_NOTIFICATIONS = 'SET_LATEST_NOTIFICATIONS';

export const SET_RELATIONS_NOTIFICATIONS = 'SET_RELATIONS_NOTIFICATIONS';
export const CLEAR_RELATIONS_NOTIFICATIONS = 'CLEAR_RELATIONS_NOTIFICATIONS';

export const CHECK_USER_NOTIFICATIONS_LOADING = 'CHECK_USER_NOTIFICATIONS_LOADING';
export const CHECK_USER_NOTIFICATIONS_SUCCESS = 'CHECK_USER_NOTIFICATIONS_SUCCESS';
export const CHECK_USER_NOTIFICATIONS_FAILURE = 'CHECK_USER_NOTIFICATIONS_FAILURE';

/* User: requests */
export const USER_REQUEST_LOADING = 'USER_REQUEST_LOADING';
export const USER_REQUEST_FAILURE = 'USER_REQUEST_FAILURE';
export const USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';

/* Premium */
export const OPEN_PREMIUM_MODAL = 'OPEN_PREMIUM_MODAL';
export const CLOSE_PREMIUM_MODAL = 'CLOSE_PREMIUM_MODAL';

/* Realtime */
export const SET_REALTIME_AUTH_KEY = 'SET_REALTIME_AUTH_KEY';
export const SET_REALTIME_CHANNELS = 'SET_REALTIME_CHANNELS';

export const GET_REALTIME_CHANNELS_LOADING = 'GET_REALTIME_CHANNELS_LOADING';
export const GET_REALTIME_CHANNELS_SUCCESS = 'GET_REALTIME_CHANNELS_SUCCESS';
export const GET_REALTIME_CHANNELS_FAILURE = 'GET_REALTIME_CHANNELS_FAILURE';

// =============================
// Meta
// =============================

/* Init */
export const SET_META_INITIALIZATION_DATA = 'SET_META_INITIALIZATION_DATA';

export const INITIALIZE_META_LOADING = 'INITIALIZE_META_LOADING';
export const INITIALIZE_META_SUCCESS = 'INITIALIZE_META_SUCCESS';
export const INITIALIZE_META_FAILURE = 'INITIALIZE_META_FAILURE';

/* Custom fields */
export const SET_META_CUSTOM_FIELDS = 'SET_META_CUSTOM_FIELDS';

export const GET_META_CUSTOM_FIELDS_LOADING = 'GET_META_CUSTOM_FIELDS_LOADING';
export const GET_META_CUSTOM_FIELDS_SUCCESS = 'GET_META_CUSTOM_FIELDS_SUCCESS';
export const GET_META_CUSTOM_FIELDS_FAILURE = 'GET_META_CUSTOM_FIELDS_FAILURE';

/* Bulk Modal */
export const SET_META_BULK_MODAL_DATA = 'SET_META_BULK_MODAL_DATA';

export const OPEN_META_BULK_MODAL = 'OPEN_META_BULK_MODAL';
export const CLOSE_META_BULK_MODAL = 'CLOSE_META_BULK_MODAL';

export const GET_META_BULK_MODAL_DATA_LOADING = 'GET_META_BULK_MODAL_DATA_LOADING';
export const GET_META_BULK_MODAL_DATA_SUCCESS = 'GET_META_BULK_MODAL_DATA_SUCCESS';
export const GET_META_BULK_MODAL_DATA_FAILURE = 'GET_META_BULK_MODAL_DATA_FAILURE';

export const UPDATE_META_BULK_LOADING = 'UPDATE_META_BULK_LOADING';
export const UPDATE_META_BULK_SUCCESS = 'UPDATE_META_BULK_SUCCESS';
export const UPDATE_META_BULK_FAILURE = 'UPDATE_META_BULK_FAILURE';

/* Filter bar */
export const TOGGLE_META_FILTER_BAR = 'TOGGLE_META_FILTER_BAR';

/* Search */
export const SET_META_SEARCH_SETTINGS = 'SET_META_SEARCH_SETTINGS';

/* Similarity search */
export const LAUNCH_SIMILARITY_SEARCH_LOADING = 'LAUNCH_SIMILARITY_SEARCH_LOADING';
export const LAUNCH_SIMILARITY_SEARCH_SUCCESS = 'LAUNCH_SIMILARITY_SEARCH_SUCCESS';
export const LAUNCH_SIMILARITY_SEARCH_FAILURE = 'LAUNCH_SIMILARITY_SEARCH_FAILURE';

/* Peoples */
export const SET_META_PEOPLES = 'SET_META_PEOPLES';

export const GET_META_PEOPLES_LOADING = 'GET_META_PEOPLES_LOADING';
export const GET_META_PEOPLES_SUCCESS = 'GET_META_PEOPLES_SUCCESS';
export const GET_META_PEOPLES_FAILURE = 'GET_META_PEOPLES_FAILURE';

export const REFRESH_META_PEOPLES_LOADING = 'REFRESH_META_PEOPLES_LOADING';
export const REFRESH_META_PEOPLES_SUCCESS = 'REFRESH_META_PEOPLES_SUCCESS';
export const REFRESH_META_PEOPLES_FAILURE = 'REFRESH_META_PEOPLES_FAILURE';

export const CREATE_META_PEOPLE_LOADING = 'CREATE_META_PEOPLE_LOADING';
export const CREATE_META_PEOPLE_SUCCESS = 'CREATE_META_PEOPLE_SUCCESS';
export const CREATE_META_PEOPLE_FAILURE = 'CREATE_META_PEOPLE_FAILURE';

export const MODIFY_META_PEOPLE_LOADING = 'MODIFY_META_PEOPLE_LOADING';
export const MODIFY_META_PEOPLE_SUCCESS = 'MODIFY_META_PEOPLE_SUCCESS';
export const MODIFY_META_PEOPLE_FAILURE = 'MODIFY_META_PEOPLE_FAILURE';

export const DELETE_META_PEOPLES_LOADING = 'DELETE_META_PEOPLES_LOADING';
export const DELETE_META_PEOPLES_SUCCESS = 'DELETE_META_PEOPLES_SUCCESS';
export const DELETE_META_PEOPLES_FAILURE = 'DELETE_META_PEOPLES_FAILURE';

export const EXPORT_META_PEOPLES_LOADING = 'EXPORT_META_PEOPLES_LOADING';
export const EXPORT_META_PEOPLES_SUCCESS = 'EXPORT_META_PEOPLES_SUCCESS';
export const EXPORT_META_PEOPLES_FAILURE = 'EXPORT_META_PEOPLES_FAILURE';

/* Publishers */
export const SET_META_PUBLISHERS = 'SET_META_PUBLISHERS';

export const GET_META_PUBLISHERS_LOADING = 'GET_META_PUBLISHERS_LOADING';
export const GET_META_PUBLISHERS_SUCCESS = 'GET_META_PUBLISHERS_SUCCESS';
export const GET_META_PUBLISHERS_FAILURE = 'GET_META_PUBLISHERS_FAILURE';

export const REFRESH_META_PUBLISHERS_LOADING = 'REFRESH_META_PUBLISHERS_LOADING';
export const REFRESH_META_PUBLISHERS_SUCCESS = 'REFRESH_META_PUBLISHERS_SUCCESS';
export const REFRESH_META_PUBLISHERS_FAILURE = 'REFRESH_META_PUBLISHERS_FAILURE';

export const CREATE_META_PUBLISHER_LOADING = 'CREATE_META_PUBLISHER_LOADING';
export const CREATE_META_PUBLISHER_SUCCESS = 'CREATE_META_PUBLISHER_SUCCESS';
export const CREATE_META_PUBLISHER_FAILURE = 'CREATE_META_PUBLISHER_FAILURE';

export const MODIFY_META_PUBLISHER_LOADING = 'MODIFY_META_PUBLISHER_LOADING';
export const MODIFY_META_PUBLISHER_SUCCESS = 'MODIFY_META_PUBLISHER_SUCCESS';
export const MODIFY_META_PUBLISHER_FAILURE = 'MODIFY_META_PUBLISHER_FAILURE';

export const SET_UPLOAD_META_PUBLISHER_FILE_PROGRESS = 'SET_UPLOAD_META_PUBLISHER_FILE_PROGRESS';
export const UPLOAD_META_PUBLISHER_FILE_LOADING = 'UPLOAD_META_PUBLISHER_FILE_LOADING';
export const UPLOAD_META_PUBLISHER_FILE_SUCCESS = 'UPLOAD_META_PUBLISHER_FILE_SUCCESS';
export const UPLOAD_META_PUBLISHER_FILE_FAILURE = 'UPLOAD_META_PUBLISHER_FILE_FAILURE';
export const CANCEL_UPLOAD_META_PUBLISHER_FILE = 'CANCEL_UPLOAD_META_PUBLISHER_FILE';

export const DELETE_META_PUBLISHERS_LOADING = 'DELETE_META_PUBLISHERS_LOADING';
export const DELETE_META_PUBLISHERS_SUCCESS = 'DELETE_META_PUBLISHERS_SUCCESS';
export const DELETE_META_PUBLISHERS_FAILURE = 'DELETE_META_PUBLISHERS_FAILURE';

export const EXPORT_META_PUBLISHERS_LOADING = 'EXPORT_META_PUBLISHERS_LOADING';
export const EXPORT_META_PUBLISHERS_SUCCESS = 'EXPORT_META_PUBLISHERS_SUCCESS';
export const EXPORT_META_PUBLISHERS_FAILURE = 'EXPORT_META_PUBLISHERS_FAILURE';

export const EXPORT_META_PUBLISHER_TRACKS_LOADING = 'EXPORT_META_PUBLISHER_TRACKS_LOADING';
export const EXPORT_META_PUBLISHER_TRACKS_SUCCESS = 'EXPORT_META_PUBLISHER_TRACKS_SUCCESS';
export const EXPORT_META_PUBLISHER_TRACKS_FAILURE = 'EXPORT_META_PUBLISHER_TRACKS_FAILURE';

/* Labels */
export const SET_META_LABELS = 'SET_META_LABELS';

export const GET_META_LABELS_LOADING = 'GET_META_LABELS_LOADING';
export const GET_META_LABELS_SUCCESS = 'GET_META_LABELS_SUCCESS';
export const GET_META_LABELS_FAILURE = 'GET_META_LABELS_FAILURE';

export const REFRESH_META_LABELS_LOADING = 'REFRESH_META_LABELS_LOADING';
export const REFRESH_META_LABELS_SUCCESS = 'REFRESH_META_LABELS_SUCCESS';
export const REFRESH_META_LABELS_FAILURE = 'REFRESH_META_LABELS_FAILURE';

export const CREATE_META_LABEL_LOADING = 'CREATE_META_LABEL_LOADING';
export const CREATE_META_LABEL_SUCCESS = 'CREATE_META_LABEL_SUCCESS';
export const CREATE_META_LABEL_FAILURE = 'CREATE_META_LABEL_FAILURE';

export const MODIFY_META_LABEL_LOADING = 'MODIFY_META_LABEL_LOADING';
export const MODIFY_META_LABEL_SUCCESS = 'MODIFY_META_LABEL_SUCCESS';
export const MODIFY_META_LABEL_FAILURE = 'MODIFY_META_LABEL_FAILURE';

export const SET_UPLOAD_META_LABEL_FILE_PROGRESS = 'SET_UPLOAD_META_LABEL_FILE_PROGRESS';
export const UPLOAD_META_LABEL_FILE_LOADING = 'UPLOAD_META_LABEL_FILE_LOADING';
export const UPLOAD_META_LABEL_FILE_SUCCESS = 'UPLOAD_META_LABEL_FILE_SUCCESS';
export const UPLOAD_META_LABEL_FILE_FAILURE = 'UPLOAD_META_LABEL_FILE_FAILURE';
export const CANCEL_UPLOAD_META_LABEL_FILE = 'CANCEL_UPLOAD_META_LABEL_FILE';

export const DELETE_META_LABELS_LOADING = 'DELETE_META_LABELS_LOADING';
export const DELETE_META_LABELS_SUCCESS = 'DELETE_META_LABELS_SUCCESS';
export const DELETE_META_LABELS_FAILURE = 'DELETE_META_LABELS_FAILURE';

export const EXPORT_META_LABELS_LOADING = 'EXPORT_META_LABELS_LOADING';
export const EXPORT_META_LABELS_SUCCESS = 'EXPORT_META_LABELS_SUCCESS';
export const EXPORT_META_LABELS_FAILURE = 'EXPORT_META_LABELS_FAILURE';

export const EXPORT_META_LABEL_TRACKS_LOADING = 'EXPORT_META_LABEL_TRACKS_LOADING';
export const EXPORT_META_LABEL_TRACKS_SUCCESS = 'EXPORT_META_LABEL_TRACKS_SUCCESS';
export const EXPORT_META_LABEL_TRACKS_FAILURE = 'EXPORT_META_LABEL_TRACKS_FAILURE';

/* Artists */
export const SET_META_ARTISTS = 'SET_META_ARTISTS';

export const GET_META_ARTISTS_LOADING = 'GET_META_ARTISTS_LOADING';
export const GET_META_ARTISTS_SUCCESS = 'GET_META_ARTISTS_SUCCESS';
export const GET_META_ARTISTS_FAILURE = 'GET_META_ARTISTS_FAILURE';

export const CREATE_META_ARTIST_LOADING = 'CREATE_META_ARTIST_LOADING';
export const CREATE_META_ARTIST_SUCCESS = 'CREATE_META_ARTIST_SUCCESS';
export const CREATE_META_ARTIST_FAILURE = 'CREATE_META_ARTIST_FAILURE';

export const REFRESH_META_ARTISTS_LOADING = 'REFRESH_META_ARTISTS_LOADING';
export const REFRESH_META_ARTISTS_SUCCESS = 'REFRESH_META_ARTISTS_SUCCESS';
export const REFRESH_META_ARTISTS_FAILURE = 'REFRESH_META_ARTISTS_FAILURE';

export const MODIFY_META_ARTIST_LOADING = 'MODIFY_META_ARTIST_LOADING';
export const MODIFY_META_ARTIST_SUCCESS = 'MODIFY_META_ARTIST_SUCCESS';
export const MODIFY_META_ARTIST_FAILURE = 'MODIFY_META_ARTIST_FAILURE';

export const SET_UPLOAD_META_ARTIST_FILE_PROGRESS = 'SET_UPLOAD_META_ARTIST_FILE_PROGRESS';
export const UPLOAD_META_ARTIST_FILE_LOADING = 'UPLOAD_META_ARTIST_FILE_LOADING';
export const UPLOAD_META_ARTIST_FILE_SUCCESS = 'UPLOAD_META_ARTIST_FILE_SUCCESS';
export const UPLOAD_META_ARTIST_FILE_FAILURE = 'UPLOAD_META_ARTIST_FILE_FAILURE';
export const CANCEL_UPLOAD_META_ARTIST_FILE = 'CANCEL_UPLOAD_META_ARTIST_FILE';

export const DELETE_META_ARTISTS_LOADING = 'DELETE_META_ARTISTS_LOADING';
export const DELETE_META_ARTISTS_SUCCESS = 'DELETE_META_ARTISTS_SUCCESS';
export const DELETE_META_ARTISTS_FAILURE = 'DELETE_META_ARTISTS_FAILURE';

export const EXPORT_META_ARTISTS_LOADING = 'EXPORT_META_ARTISTS_LOADING';
export const EXPORT_META_ARTISTS_SUCCESS = 'EXPORT_META_ARTISTS_SUCCESS';
export const EXPORT_META_ARTISTS_FAILURE = 'EXPORT_META_ARTISTS_FAILURE';

export const EXPORT_META_ARTIST_TRACKS_LOADING = 'EXPORT_META_ARTIST_TRACKS_LOADING';
export const EXPORT_META_ARTIST_TRACKS_SUCCESS = 'EXPORT_META_ARTIST_TRACKS_SUCCESS';
export const EXPORT_META_ARTIST_TRACKS_FAILURE = 'EXPORT_META_ARTIST_TRACKS_FAILURE';

/* Catalogs */
export const SET_META_CATALOGS = 'SET_META_CATALOGS';

export const GET_META_CATALOGS_LOADING = 'GET_META_CATALOGS_LOADING';
export const GET_META_CATALOGS_SUCCESS = 'GET_META_CATALOGS_SUCCESS';
export const GET_META_CATALOGS_FAILURE = 'GET_META_CATALOGS_FAILURE';

export const REFRESH_META_CATALOGS_LOADING = 'REFRESH_META_CATALOGS_LOADING';
export const REFRESH_META_CATALOGS_SUCCESS = 'REFRESH_META_CATALOGS_SUCCESS';
export const REFRESH_META_CATALOGS_FAILURE = 'REFRESH_META_CATALOGS_FAILURE';

export const CREATE_META_CATALOG_LOADING = 'CREATE_META_CATALOG_LOADING';
export const CREATE_META_CATALOG_SUCCESS = 'CREATE_META_CATALOG_SUCCESS';
export const CREATE_META_CATALOG_FAILURE = 'CREATE_META_CATALOG_FAILURE';

export const MODIFY_META_CATALOG_LOADING = 'MODIFY_META_CATALOG_LOADING';
export const MODIFY_META_CATALOG_SUCCESS = 'MODIFY_META_CATALOG_SUCCESS';
export const MODIFY_META_CATALOG_FAILURE = 'MODIFY_META_CATALOG_FAILURE';

export const SET_UPLOAD_META_CATALOG_FILE_PROGRESS = 'SET_UPLOAD_META_CATALOG_FILE_PROGRESS';
export const UPLOAD_META_CATALOG_FILE_LOADING = 'UPLOAD_META_CATALOG_FILE_LOADING';
export const UPLOAD_META_CATALOG_FILE_SUCCESS = 'UPLOAD_META_CATALOG_FILE_SUCCESS';
export const UPLOAD_META_CATALOG_FILE_FAILURE = 'UPLOAD_META_CATALOG_FILE_FAILURE';
export const CANCEL_UPLOAD_META_CATALOG_FILE = 'CANCEL_UPLOAD_META_CATALOG_FILE';

export const DELETE_META_CATALOGS_LOADING = 'DELETE_META_CATALOGS_LOADING';
export const DELETE_META_CATALOGS_SUCCESS = 'DELETE_META_CATALOGS_SUCCESS';
export const DELETE_META_CATALOGS_FAILURE = 'DELETE_META_CATALOGS_FAILURE';

export const EXPORT_META_CATALOGS_LOADING = 'EXPORT_META_CATALOGS_LOADING';
export const EXPORT_META_CATALOGS_SUCCESS = 'EXPORT_META_CATALOGS_SUCCESS';
export const EXPORT_META_CATALOGS_FAILURE = 'EXPORT_META_CATALOGS_FAILURE';

export const EXPORT_META_CATALOG_TRACKS_LOADING = 'EXPORT_META_CATALOG_TRACKS_LOADING';
export const EXPORT_META_CATALOG_TRACKS_SUCCESS = 'EXPORT_META_CATALOG_TRACKS_SUCCESS';
export const EXPORT_META_CATALOG_TRACKS_FAILURE = 'EXPORT_META_CATALOG_TRACKS_FAILURE';

export const ARCHIVE_META_CATALOG_TRACKS_LOADING = 'ARCHIVE_META_CATALOG_TRACKS_LOADING';
export const ARCHIVE_META_CATALOG_TRACKS_SUCCESS = 'ARCHIVE_META_CATALOG_TRACKS_SUCCESS';
export const ARCHIVE_META_CATALOG_TRACKS_FAILURE = 'ARCHIVE_META_CATALOG_TRACKS_FAILURE';

/* Albums */
export const SET_META_ALBUMS = 'SET_META_ALBUMS';

export const GET_META_ALBUMS_LOADING = 'GET_META_ALBUMS_LOADING';
export const GET_META_ALBUMS_SUCCESS = 'GET_META_ALBUMS_SUCCESS';
export const GET_META_ALBUMS_FAILURE = 'GET_META_ALBUMS_FAILURE';

export const REFRESH_META_ALBUMS_LOADING = 'REFRESH_META_ALBUMS_LOADING';
export const REFRESH_META_ALBUMS_SUCCESS = 'REFRESH_META_ALBUMS_SUCCESS';
export const REFRESH_META_ALBUMS_FAILURE = 'REFRESH_META_ALBUMS_FAILURE';

export const CREATE_META_ALBUM_LOADING = 'CREATE_META_ALBUM_LOADING';
export const CREATE_META_ALBUM_SUCCESS = 'CREATE_META_ALBUM_SUCCESS';
export const CREATE_META_ALBUM_FAILURE = 'CREATE_META_ALBUM_FAILURE';

export const MODIFY_META_ALBUM_LOADING = 'MODIFY_META_ALBUM_LOADING';
export const MODIFY_META_ALBUM_SUCCESS = 'MODIFY_META_ALBUM_SUCCESS';
export const MODIFY_META_ALBUM_FAILURE = 'MODIFY_META_ALBUM_FAILURE';

export const SET_UPLOAD_META_ALBUM_FILE_PROGRESS = 'SET_UPLOAD_META_ALBUM_FILE_PROGRESS';
export const UPLOAD_META_ALBUM_FILE_LOADING = 'UPLOAD_META_ALBUM_FILE_LOADING';
export const UPLOAD_META_ALBUM_FILE_SUCCESS = 'UPLOAD_META_ALBUM_FILE_SUCCESS';
export const UPLOAD_META_ALBUM_FILE_FAILURE = 'UPLOAD_META_ALBUM_FILE_FAILURE';
export const CANCEL_UPLOAD_META_ALBUM_FILE = 'CANCEL_UPLOAD_META_ALBUM_FILE';

export const DELETE_META_ALBUMS_LOADING = 'DELETE_META_ALBUMS_LOADING';
export const DELETE_META_ALBUMS_SUCCESS = 'DELETE_META_ALBUMS_SUCCESS';
export const DELETE_META_ALBUMS_FAILURE = 'DELETE_META_ALBUMS_FAILURE';

export const EXPORT_META_ALBUMS_LOADING = 'EXPORT_META_ALBUMS_LOADING';
export const EXPORT_META_ALBUMS_SUCCESS = 'EXPORT_META_ALBUMS_SUCCESS';
export const EXPORT_META_ALBUMS_FAILURE = 'EXPORT_META_ALBUMS_FAILURE';

export const EXPORT_META_ALBUM_TRACKS_LOADING = 'EXPORT_META_ALBUM_TRACKS_LOADING';
export const EXPORT_META_ALBUM_TRACKS_SUCCESS = 'EXPORT_META_ALBUM_TRACKS_SUCCESS';
export const EXPORT_META_ALBUM_TRACKS_FAILURE = 'EXPORT_META_ALBUM_TRACKS_FAILURE';

export const ARCHIVE_META_ALBUM_TRACKS_LOADING = 'ARCHIVE_META_ALBUM_TRACKS_LOADING';
export const ARCHIVE_META_ALBUM_TRACKS_SUCCESS = 'ARCHIVE_META_ALBUM_TRACKS_SUCCESS';
export const ARCHIVE_META_ALBUM_TRACKS_FAILURE = 'ARCHIVE_META_ALBUM_TRACKS_FAILURE';

export const DOWNLOAD_META_ALBUM_COPYRIGHT_LOADING = 'DOWNLOAD_META_ALBUM_COPYRIGHT_LOADING';
export const DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS = 'DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS';
export const DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE = 'DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE';

/* Playlists */
export const SET_META_PLAYLISTS = 'SET_META_PLAYLISTS';

export const GET_META_PLAYLISTS_LOADING = 'GET_META_PLAYLISTS_LOADING';
export const GET_META_PLAYLISTS_SUCCESS = 'GET_META_PLAYLISTS_SUCCESS';
export const GET_META_PLAYLISTS_FAILURE = 'GET_META_PLAYLISTS_FAILURE';

export const REFRESH_META_PLAYLISTS_LOADING = 'REFRESH_META_PLAYLISTS_LOADING';
export const REFRESH_META_PLAYLISTS_SUCCESS = 'REFRESH_META_PLAYLISTS_SUCCESS';
export const REFRESH_META_PLAYLISTS_FAILURE = 'REFRESH_META_PLAYLISTS_FAILURE';

export const CREATE_META_PLAYLIST_LOADING = 'CREATE_META_PLAYLIST_LOADING';
export const CREATE_META_PLAYLIST_SUCCESS = 'CREATE_META_PLAYLIST_SUCCESS';
export const CREATE_META_PLAYLIST_FAILURE = 'CREATE_META_PLAYLIST_FAILURE';

export const MODIFY_META_PLAYLIST_LOADING = 'MODIFY_META_PLAYLIST_LOADING';
export const MODIFY_META_PLAYLIST_SUCCESS = 'MODIFY_META_PLAYLIST_SUCCESS';
export const MODIFY_META_PLAYLIST_FAILURE = 'MODIFY_META_PLAYLIST_FAILURE';

export const SET_UPLOAD_META_PLAYLIST_FILE_PROGRESS = 'SET_UPLOAD_META_PLAYLIST_FILE_PROGRESS';
export const UPLOAD_META_PLAYLIST_FILE_LOADING = 'UPLOAD_META_PLAYLIST_FILE_LOADING';
export const UPLOAD_META_PLAYLIST_FILE_SUCCESS = 'UPLOAD_META_PLAYLIST_FILE_SUCCESS';
export const UPLOAD_META_PLAYLIST_FILE_FAILURE = 'UPLOAD_META_PLAYLIST_FILE_FAILURE';
export const CANCEL_UPLOAD_META_PLAYLIST_FILE = 'CANCEL_UPLOAD_META_PLAYLIST_FILE';

export const DELETE_META_PLAYLISTS_LOADING = 'DELETE_META_PLAYLISTS_LOADING';
export const DELETE_META_PLAYLISTS_SUCCESS = 'DELETE_META_PLAYLISTS_SUCCESS';
export const DELETE_META_PLAYLISTS_FAILURE = 'DELETE_META_PLAYLISTS_FAILURE';

export const EXPORT_META_PLAYLISTS_LOADING = 'EXPORT_META_PLAYLISTS_LOADING';
export const EXPORT_META_PLAYLISTS_SUCCESS = 'EXPORT_META_PLAYLISTS_SUCCESS';
export const EXPORT_META_PLAYLISTS_FAILURE = 'EXPORT_META_PLAYLISTS_FAILURE';

export const EXPORT_META_PLAYLIST_TRACKS_LOADING = 'EXPORT_META_PLAYLIST_TRACKS_LOADING';
export const EXPORT_META_PLAYLIST_TRACKS_SUCCESS = 'EXPORT_META_PLAYLIST_TRACKS_SUCCESS';
export const EXPORT_META_PLAYLIST_TRACKS_FAILURE = 'EXPORT_META_PLAYLIST_TRACKS_FAILURE';

export const ARCHIVE_META_PLAYLIST_TRACKS_LOADING = 'ARCHIVE_META_PLAYLIST_TRACKS_LOADING';
export const ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS = 'ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS';
export const ARCHIVE_META_PLAYLIST_TRACKS_FAILURE = 'ARCHIVE_META_PLAYLIST_TRACKS_FAILURE';

export const DOWNLOAD_META_PLAYLIST_COPYRIGHT_LOADING = 'DOWNLOAD_META_PLAYLIST_COPYRIGHT_LOADING';
export const DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS = 'DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS';
export const DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE = 'DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE';

/* Refs Tracks */
export const ADD_META_TRACKS_TO_REF_LOADING = 'ADD_META_TRACKS_TO_REF_LOADING';
export const ADD_META_TRACKS_TO_REF_SUCCESS = 'ADD_META_TRACKS_TO_REF_SUCCESS';
export const ADD_META_TRACKS_TO_REF_FAILURE = 'ADD_META_TRACKS_TO_REF_FAILURE';

export const REMOVE_META_TRACKS_FROM_REF_LOADING = 'REMOVE_META_TRACKS_FROM_REF_LOADING';
export const REMOVE_META_TRACKS_FROM_REF_SUCCESS = 'REMOVE_META_TRACKS_FROM_REF_SUCCESS';
export const REMOVE_META_TRACKS_FROM_REF_FAILURE = 'REMOVE_META_TRACKS_FROM_REF_FAILURE';

/* Pitchs */
export const SET_META_PITCHS = 'SET_META_PITCHS';

export const GET_META_PITCHS_LOADING = 'GET_META_PITCHS_LOADING';
export const GET_META_PITCHS_SUCCESS = 'GET_META_PITCHS_SUCCESS';
export const GET_META_PITCHS_FAILURE = 'GET_META_PITCHS_FAILURE';

export const REFRESH_META_PITCHS_LOADING = 'REFRESH_META_PITCHS_LOADING';
export const REFRESH_META_PITCHS_SUCCESS = 'REFRESH_META_PITCHS_SUCCESS';
export const REFRESH_META_PITCHS_FAILURE = 'REFRESH_META_PITCHS_FAILURE';

export const CREATE_META_PITCH_LOADING = 'CREATE_META_PITCH_LOADING';
export const CREATE_META_PITCH_SUCCESS = 'CREATE_META_PITCH_SUCCESS';
export const CREATE_META_PITCH_FAILURE = 'CREATE_META_PITCH_FAILURE';

export const MODIFY_META_PITCH_LOADING = 'MODIFY_META_PITCH_LOADING';
export const MODIFY_META_PITCH_SUCCESS = 'MODIFY_META_PITCH_SUCCESS';
export const MODIFY_META_PITCH_FAILURE = 'MODIFY_META_PITCH_FAILURE';

export const DELETE_META_PITCHS_LOADING = 'DELETE_META_PITCHS_LOADING';
export const DELETE_META_PITCHS_SUCCESS = 'DELETE_META_PITCHS_SUCCESS';
export const DELETE_META_PITCHS_FAILURE = 'DELETE_META_PITCHS_FAILURE';

export const EXPORT_META_PITCHS_LOADING = 'EXPORT_META_PITCHS_LOADING';
export const EXPORT_META_PITCHS_SUCCESS = 'EXPORT_META_PITCHS_SUCCESS';
export const EXPORT_META_PITCHS_FAILURE = 'EXPORT_META_PITCHS_FAILURE';

/* Projects */
export const SET_META_BRIEFS = 'SET_META_BRIEFS';

export const GET_META_BRIEFS_LOADING = 'GET_META_BRIEFS_LOADING';
export const GET_META_BRIEFS_SUCCESS = 'GET_META_BRIEFS_SUCCESS';
export const GET_META_BRIEFS_FAILURE = 'GET_META_BRIEFS_FAILURE';

export const REFRESH_META_BRIEFS_LOADING = 'REFRESH_META_BRIEFS_LOADING';
export const REFRESH_META_BRIEFS_SUCCESS = 'REFRESH_META_BRIEFS_SUCCESS';
export const REFRESH_META_BRIEFS_FAILURE = 'REFRESH_META_BRIEFS_FAILURE';

export const CREATE_META_BRIEF_LOADING = 'CREATE_META_BRIEF_LOADING';
export const CREATE_META_BRIEF_SUCCESS = 'CREATE_META_BRIEF_SUCCESS';
export const CREATE_META_BRIEF_FAILURE = 'CREATE_META_BRIEF_FAILURE';

export const MODIFY_META_BRIEF_LOADING = 'MODIFY_META_BRIEF_LOADING';
export const MODIFY_META_BRIEF_SUCCESS = 'MODIFY_META_BRIEF_SUCCESS';
export const MODIFY_META_BRIEF_FAILURE = 'MODIFY_META_BRIEF_FAILURE';

export const SET_UPLOAD_META_BRIEF_FILE_PROGRESS = 'SET_UPLOAD_META_BRIEF_FILE_PROGRESS';
export const UPLOAD_META_BRIEF_FILE_LOADING = 'UPLOAD_META_BRIEF_FILE_LOADING';
export const UPLOAD_META_BRIEF_FILE_SUCCESS = 'UPLOAD_META_BRIEF_FILE_SUCCESS';
export const UPLOAD_META_BRIEF_FILE_FAILURE = 'UPLOAD_META_BRIEF_FILE_FAILURE';
export const CANCEL_UPLOAD_META_BRIEF_FILE = 'CANCEL_UPLOAD_META_BRIEF_FILE';

export const DELETE_META_BRIEFS_LOADING = 'DELETE_META_BRIEFS_LOADING';
export const DELETE_META_BRIEFS_SUCCESS = 'DELETE_META_BRIEFS_SUCCESS';
export const DELETE_META_BRIEFS_FAILURE = 'DELETE_META_BRIEFS_FAILURE';

export const EXPORT_META_BRIEFS_LOADING = 'EXPORT_META_BRIEFS_LOADING';
export const EXPORT_META_BRIEFS_SUCCESS = 'EXPORT_META_BRIEFS_SUCCESS';
export const EXPORT_META_BRIEFS_FAILURE = 'EXPORT_META_BRIEFS_FAILURE';

export const EXPORT_META_BRIEF_TRACKS_LOADING = 'EXPORT_META_BRIEF_TRACKS_LOADING';
export const EXPORT_META_BRIEF_TRACKS_SUCCESS = 'EXPORT_META_BRIEF_TRACKS_SUCCESS';
export const EXPORT_META_BRIEF_TRACKS_FAILURE = 'EXPORT_META_BRIEF_TRACKS_FAILURE';

export const ARCHIVE_META_BRIEF_TRACKS_LOADING = 'ARCHIVE_META_BRIEF_TRACKS_LOADING';
export const ARCHIVE_META_BRIEF_TRACKS_SUCCESS = 'ARCHIVE_META_BRIEF_TRACKS_SUCCESS';
export const ARCHIVE_META_BRIEF_TRACKS_FAILURE = 'ARCHIVE_META_BRIEF_TRACKS_FAILURE';

export const DOWNLOAD_META_BRIEF_COPYRIGHT_LOADING = 'DOWNLOAD_META_BRIEF_COPYRIGHT_LOADING';
export const DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS = 'DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS';
export const DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE = 'DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE';

/* Tracks */
export const SET_META_TRACKS = 'SET_META_TRACKS';
export const REFRESH_META_TRACKS = 'REFRESH_META_TRACKS';

export const GET_META_TRACKS_LOADING = 'GET_META_TRACKS_LOADING';
export const GET_META_TRACKS_SUCCESS = 'GET_META_TRACKS_SUCCESS';
export const GET_META_TRACKS_FAILURE = 'GET_META_TRACKS_FAILURE';

export const REFRESH_META_TRACKS_LOADING = 'REFRESH_META_TRACKS_LOADING';
export const REFRESH_META_TRACKS_SUCCESS = 'REFRESH_META_TRACKS_SUCCESS';
export const REFRESH_META_TRACKS_FAILURE = 'REFRESH_META_TRACKS_FAILURE';

export const CREATE_META_TRACK_LOADING = 'CREATE_META_TRACK_LOADING';
export const CREATE_META_TRACK_SUCCESS = 'CREATE_META_TRACK_SUCCESS';
export const CREATE_META_TRACK_FAILURE = 'CREATE_META_TRACK_FAILURE';

export const MODIFY_META_TRACK_LOADING = 'MODIFY_META_TRACK_LOADING';
export const MODIFY_META_TRACK_SUCCESS = 'MODIFY_META_TRACK_SUCCESS';
export const MODIFY_META_TRACK_FAILURE = 'MODIFY_META_TRACK_FAILURE';

export const SET_UPLOAD_META_TRACK_FILE_PROGRESS = 'SET_UPLOAD_META_TRACK_FILE_PROGRESS';
export const UPLOAD_META_TRACK_FILE_LOADING = 'UPLOAD_META_TRACK_FILE_LOADING';
export const UPLOAD_META_TRACK_FILE_SUCCESS = 'UPLOAD_META_TRACK_FILE_SUCCESS';
export const UPLOAD_META_TRACK_FILE_FAILURE = 'UPLOAD_META_TRACK_FILE_FAILURE';
export const CANCEL_UPLOAD_META_TRACK_FILE = 'CANCEL_UPLOAD_META_TRACK_FILE';

export const DELETE_META_TRACKS_LOADING = 'DELETE_META_TRACKS_LOADING';
export const DELETE_META_TRACKS_SUCCESS = 'DELETE_META_TRACKS_SUCCESS';
export const DELETE_META_TRACKS_FAILURE = 'DELETE_META_TRACKS_FAILURE';

export const EXPORT_META_TRACK_LOADING = 'EXPORT_META_TRACK_LOADING';
export const EXPORT_META_TRACK_SUCCESS = 'EXPORT_META_TRACK_SUCCESS';
export const EXPORT_META_TRACK_FAILURE = 'EXPORT_META_TRACK_FAILURE';

export const ARCHIVE_META_TRACK_LOADING = 'ARCHIVE_META_TRACK_LOADING';
export const ARCHIVE_META_TRACK_SUCCESS = 'ARCHIVE_META_TRACK_SUCCESS';
export const ARCHIVE_META_TRACK_FAILURE = 'ARCHIVE_META_TRACK_FAILURE';

export const DOWNLOAD_META_TRACK_AUDIOFILE_LOADING = 'DOWNLOAD_META_TRACK_AUDIOFILE_LOADING';
export const DOWNLOAD_META_TRACK_AUDIOFILE_SUCCESS = 'DOWNLOAD_META_TRACK_AUDIOFILE_SUCCESS';
export const DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE = 'DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE';

export const DOWNLOAD_META_TRACK_COPYRIGHT_LOADING = 'DOWNLOAD_META_TRACK_COPYRIGHT_LOADING';
export const DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS = 'DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS';
export const DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE = 'DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE';

/* Ingestions */
export const SET_META_INGESTIONS = 'SET_META_INGESTIONS';

export const GET_META_INGESTIONS_LOADING = 'GET_META_INGESTIONS_LOADING';
export const GET_META_INGESTIONS_SUCCESS = 'GET_META_INGESTIONS_SUCCESS';
export const GET_META_INGESTIONS_FAILURE = 'GET_META_INGESTIONS_FAILURE';

export const REFRESH_META_INGESTIONS_LOADING = 'REFRESH_META_INGESTIONS_LOADING';
export const REFRESH_META_INGESTIONS_SUCCESS = 'REFRESH_META_INGESTIONS_SUCCESS';
export const REFRESH_META_INGESTIONS_FAILURE = 'REFRESH_META_INGESTIONS_FAILURE';

export const CREATE_META_INGESTION_LOADING = 'CREATE_META_INGESTION_LOADING';
export const CREATE_META_INGESTION_SUCCESS = 'CREATE_META_INGESTION_SUCCESS';
export const CREATE_META_INGESTION_FAILURE = 'CREATE_META_INGESTION_FAILURE';

export const DELETE_META_INGESTIONS_LOADING = 'DELETE_META_INGESTIONS_LOADING';
export const DELETE_META_INGESTIONS_SUCCESS = 'DELETE_META_INGESTIONS_SUCCESS';
export const DELETE_META_INGESTIONS_FAILURE = 'DELETE_META_INGESTIONS_FAILURE';

export const UPDATE_META_INGESTION_POST_JOB = 'UPDATE_META_INGESTION_POST_JOB';

export const UPDATE_META_INGESTION_POST_JOB_LOADING = 'UPDATE_META_INGESTION_POST_JOB_LOADING';
export const UPDATE_META_INGESTION_POST_JOB_SUCCESS = 'UPDATE_META_INGESTION_POST_JOB_SUCCESS';
export const UPDATE_META_INGESTION_POST_JOB_FAILURE = 'UPDATE_META_INGESTION_POST_JOB_FAILURE';

// =============================
// Player
// =============================

export const OPEN_PLAYER = 'OPEN_PLAYER';
export const CLOSE_PLAYER = 'CLOSE_PLAYER';

export const SET_PLAYER_STATE = 'SET_PLAYER_STATE';
export const FULFILL_PLAYER_REQUEST = 'FULFILL_PLAYER_REQUEST';
export const PLAYER_REQUEST_PLAY = 'PLAYER_REQUEST_PLAY';
export const PLAYER_REQUEST_PAUSE = 'PLAYER_REQUEST_PAUSE';
export const PLAYER_REQUEST_SET_SEEKING_TIME = 'PLAYER_REQUEST_SET_SEEKING_TIME';
export const PLAYER_REQUEST_GO_TO_PREV_TRACK = 'PLAYER_REQUEST_GO_TO_PREV_TRACK';
export const PLAYER_REQUEST_GO_TO_NEXT_TRACK = 'PLAYER_REQUEST_GO_TO_NEXT_TRACK';

export const SET_PLAYER_GET_CURRENT_TIME_FNC = 'SET_PLAYER_GET_CURRENT_TIME_FNC';

export const REINITIALIZE_PLAYER = 'REINITIALIZE_PLAYER';

export const SET_PLAYER_CURRENT_TRACK = 'SET_PLAYER_CURRENT_TRACK';

export const GET_AND_SET_ALBUM_TRACKS_LOADING = 'GET_AND_SET_ALBUM_TRACKS_LOADING';
export const GET_AND_SET_ALBUM_TRACKS_SUCCESS = 'GET_AND_SET_ALBUM_TRACKS_SUCCESS';
export const GET_AND_SET_ALBUM_TRACKS_FAILURE = 'GET_AND_SET_ALBUM_TRACKS_FAILURE';

export const GET_AND_SET_PLAYLIST_TRACKS_LOADING = 'GET_AND_SET_PLAYLIST_TRACKS_LOADING';
export const GET_AND_SET_PLAYLIST_TRACKS_SUCCESS = 'GET_AND_SET_PLAYLIST_TRACKS_SUCCESS';
export const GET_AND_SET_PLAYLIST_TRACKS_FAILURE = 'GET_AND_SET_PLAYLIST_TRACKS_FAILURE';

export const GET_AND_SET_BRIEF_TRACKS_LOADING = 'GET_AND_SET_BRIEF_TRACKS_LOADING';
export const GET_AND_SET_BRIEF_TRACKS_SUCCESS = 'GET_AND_SET_BRIEF_TRACKS_SUCCESS';
export const GET_AND_SET_BRIEF_TRACKS_FAILURE = 'GET_AND_SET_BRIEF_TRACKS_FAILURE';

// =============================
// Modo
// =============================

/* Configs */
export const GET_MODO_CONFIGS_LOADING = 'GET_MODO_CONFIGS_LOADING';
export const GET_MODO_CONFIGS_SUCCESS = 'GET_MODO_CONFIGS_SUCCESS';
export const GET_MODO_CONFIGS_FAILURE = 'GET_MODO_CONFIGS_FAILURE';

export const SET_MODO_CONFIGS = 'SET_MODO_CONFIGS';

export const CREATE_MODO_CONFIG_LOADING = 'CREATE_MODO_CONFIG_LOADING';
export const CREATE_MODO_CONFIG_SUCCESS = 'CREATE_MODO_CONFIG_SUCCESS';
export const CREATE_MODO_CONFIG_FAILURE = 'CREATE_MODO_CONFIG_FAILURE';

export const PUSH_MODO_CONFIG = 'PUSH_MODO_CONFIG';

/* Config */
export const GET_MODO_CONFIG_LOADING = 'GET_MODO_CONFIG_LOADING';
export const GET_MODO_CONFIG_SUCCESS = 'GET_MODO_CONFIG_SUCCESS';
export const GET_MODO_CONFIG_FAILURE = 'GET_MODO_CONFIG_FAILURE';

export const SET_MODO_CONFIG = 'SET_MODO_CONFIG';
export const SET_MODO_CONFIG_IMAGE = 'SET_MODO_CONFIG_IMAGE';

export const MODIFY_MODO_CONFIG_PLAN_LOADING = 'MODIFY_MODO_CONFIG_PLAN_LOADING';
export const MODIFY_MODO_CONFIG_PLAN_SUCCESS = 'MODIFY_MODO_CONFIG_PLAN_SUCCESS';
export const MODIFY_MODO_CONFIG_PLAN_FAILURE = 'MODIFY_MODO_CONFIG_PLAN_FAILURE';

export const UPLOAD_MODO_CONFIG_IMAGE_FAILURE = 'UPLOAD_MODO_CONFIG_IMAGE_FAILURE';
export const DELETE_MODO_CONFIG_IMAGE_FAILURE = 'DELETE_MODO_CONFIG_IMAGE_FAILURE';

export const MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_LOADING = 'MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_LOADING';
export const MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_SUCCESS = 'MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_SUCCESS';
export const MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE = 'MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE';

export const MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_LOADING = 'MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_LOADING';
export const MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_SUCCESS = 'MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_SUCCESS';
export const MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE = 'MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE';

export const MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_LOADING = 'MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_LOADING';
export const MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_SUCCESS = 'MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_SUCCESS';
export const MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE = 'MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE';

export const MODIFY_MODO_CONFIG_USERS_SETTINGS_LOADING = 'MODIFY_MODO_CONFIG_USERS_SETTINGS_LOADING';
export const MODIFY_MODO_CONFIG_USERS_SETTINGS_SUCCESS = 'MODIFY_MODO_CONFIG_USERS_SETTINGS_SUCCESS';
export const MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE = 'MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE';

export const MODIFY_MODO_CONFIG_URL_SETTINGS_LOADING = 'MODIFY_MODO_CONFIG_URL_SETTINGS_LOADING';
export const MODIFY_MODO_CONFIG_URL_SETTINGS_SUCCESS = 'MODIFY_MODO_CONFIG_URL_SETTINGS_SUCCESS';
export const MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE = 'MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE';

export const MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_LOADING = 'MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_LOADING';
export const MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_SUCCESS = 'MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_SUCCESS';
export const MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE = 'MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE';

export const MODIFY_MODO_CONFIG_MAIN_SETTINGS_LOADING = 'MODIFY_MODO_CONFIG_MAIN_SETTINGS_LOADING';
export const MODIFY_MODO_CONFIG_MAIN_SETTINGS_SUCCESS = 'MODIFY_MODO_CONFIG_MAIN_SETTINGS_SUCCESS';
export const MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE = 'MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE';

/* Config Menus */
export const SET_MODO_CONFIG_MENUS = 'SET_MODO_CONFIG_MENUS';

export const GET_MODO_CONFIG_MENUS_LOADING = 'GET_MODO_CONFIG_MENUS_LOADING';
export const GET_MODO_CONFIG_MENUS_SUCCESS = 'GET_MODO_CONFIG_MENUS_SUCCESS';
export const GET_MODO_CONFIG_MENUS_FAILURE = 'GET_MODO_CONFIG_MENUS_FAILURE';

export const MODIFY_MODO_CONFIG_MENUS_LOADING = 'MODIFY_MODO_CONFIG_MENUS_LOADING';
export const MODIFY_MODO_CONFIG_MENUS_SUCCESS = 'MODIFY_MODO_CONFIG_MENUS_SUCCESS';
export const MODIFY_MODO_CONFIG_MENUS_FAILURE = 'MODIFY_MODO_CONFIG_MENUS_FAILURE';

/* Config Menus Options */
export const SET_MODO_CONFIG_MENUS_OPTIONS = 'SET_MODO_CONFIG_MENUS_OPTIONS';

export const GET_MODO_CONFIG_MENUS_OPTIONS_LOADING = 'GET_MODO_CONFIG_MENUS_OPTIONS_LOADING';
export const GET_MODO_CONFIG_MENUS_OPTIONS_SUCCESS = 'GET_MODO_CONFIG_MENUS_OPTIONS_SUCCESS';
export const GET_MODO_CONFIG_MENUS_OPTIONS_FAILURE = 'GET_MODO_CONFIG_MENUS_OPTIONS_FAILURE';

/* Config Legal */
export const SET_MODO_CONFIG_LEGAL = 'SET_MODO_CONFIG_LEGAL';

export const GET_MODO_CONFIG_LEGAL_LOADING = 'GET_MODO_CONFIG_LEGAL_LOADING';
export const GET_MODO_CONFIG_LEGAL_SUCCESS = 'GET_MODO_CONFIG_LEGAL_SUCCESS';
export const GET_MODO_CONFIG_LEGAL_FAILURE = 'GET_MODO_CONFIG_LEGAL_FAILURE';

export const MODIFY_MODO_CONFIG_LEGAL_LOADING = 'MODIFY_MODO_CONFIG_LEGAL_LOADING';
export const MODIFY_MODO_CONFIG_LEGAL_SUCCESS = 'MODIFY_MODO_CONFIG_LEGAL_SUCCESS';
export const MODIFY_MODO_CONFIG_LEGAL_FAILURE = 'MODIFY_MODO_CONFIG_LEGAL_FAILURE';

/* Config pages */
export const SET_MODO_CONFIG_PAGES = 'SET_MODO_CONFIG_PAGES';

export const GET_MODO_CONFIG_PAGES_LOADING = 'GET_MODO_CONFIG_PAGES_LOADING';
export const GET_MODO_CONFIG_PAGES_SUCCESS = 'GET_MODO_CONFIG_PAGES_SUCCESS';
export const GET_MODO_CONFIG_PAGES_FAILURE = 'GET_MODO_CONFIG_PAGES_FAILURE';

/* Config page */
export const SET_MODO_CONFIG_PAGE = 'SET_MODO_CONFIG_PAGE';

export const GET_MODO_CONFIG_PAGE_LOADING = 'GET_MODO_CONFIG_PAGE_LOADING';
export const GET_MODO_CONFIG_PAGE_SUCCESS = 'GET_MODO_CONFIG_PAGE_SUCCESS';
export const GET_MODO_CONFIG_PAGE_FAILURE = 'GET_MODO_CONFIG_PAGE_FAILURE';

export const CREATE_MODO_CONFIG_PAGE_LOADING = 'CREATE_MODO_CONFIG_PAGE_LOADING';
export const CREATE_MODO_CONFIG_PAGE_SUCCESS = 'CREATE_MODO_CONFIG_PAGE_SUCCESS';
export const CREATE_MODO_CONFIG_PAGE_FAILURE = 'CREATE_MODO_CONFIG_PAGE_FAILURE';

export const DUPLICATE_MODO_CONFIG_PAGE_LOADING = 'DUPLICATE_MODO_CONFIG_PAGE_LOADING';
export const DUPLICATE_MODO_CONFIG_PAGE_SUCCESS = 'DUPLICATE_MODO_CONFIG_PAGE_SUCCESS';
export const DUPLICATE_MODO_CONFIG_PAGE_FAILURE = 'DUPLICATE_MODO_CONFIG_PAGE_FAILURE';

export const MODIFY_MODO_CONFIG_PAGE_LOADING = 'MODIFY_MODO_CONFIG_PAGE_LOADING';
export const MODIFY_MODO_CONFIG_PAGE_SUCCESS = 'MODIFY_MODO_CONFIG_PAGE_SUCCESS';
export const MODIFY_MODO_CONFIG_PAGE_FAILURE = 'MODIFY_MODO_CONFIG_PAGE_FAILURE';

export const MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_LOADING = 'MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_LOADING';
export const MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_SUCCESS = 'MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_SUCCESS';
export const MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE = 'MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE';

export const DELETE_MODO_CONFIG_PAGE_LOADING = 'DELETE_MODO_CONFIG_PAGE_LOADING';
export const DELETE_MODO_CONFIG_PAGE_SUCCESS = 'DELETE_MODO_CONFIG_PAGE_SUCCESS';
export const DELETE_MODO_CONFIG_PAGE_FAILURE = 'DELETE_MODO_CONFIG_PAGE_FAILURE';

export const SET_MODO_CONFIG_PAGE_IMAGE = 'SET_MODO_CONFIG_PAGE_IMAGE';

export const UPLOAD_MODO_CONFIG_PAGE_IMAGE_FAILURE = 'UPLOAD_MODO_CONFIG_PAGE_IMAGE_FAILURE';
export const DELETE_MODO_CONFIG_PAGE_IMAGE_FAILURE = 'DELETE_MODO_CONFIG_PAGE_IMAGE_FAILURE';

/* Config Page Modules */
export const SET_MODO_CONFIG_PAGE_MODULES = 'SET_MODO_CONFIG_PAGE_MODULES';

export const GET_MODO_CONFIG_PAGE_MODULES_LOADING = 'GET_MODO_CONFIG_PAGE_MODULES_LOADING';
export const GET_MODO_CONFIG_PAGE_MODULES_SUCCESS = 'GET_MODO_CONFIG_PAGE_MODULES_SUCCESS';
export const GET_MODO_CONFIG_PAGE_MODULES_FAILURE = 'GET_MODO_CONFIG_PAGE_MODULES_FAILURE';

export const ADD_MODO_CONFIG_PAGE_MODULE = 'ADD_MODO_CONFIG_PAGE_MODULE';

export const CREATE_MODO_CONFIG_PAGE_MODULE_LOADING = 'CREATE_MODO_CONFIG_PAGE_MODULE_LOADING';
export const CREATE_MODO_CONFIG_PAGE_MODULE_SUCCESS = 'CREATE_MODO_CONFIG_PAGE_MODULE_SUCCESS';
export const CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE = 'CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE';

export const UPDATE_MODO_CONFIG_PAGE_MODULE = 'UPDATE_MODO_CONFIG_PAGE_MODULE';

export const SET_DIRTY_MODO_CONFIG_PAGE_MODULES = 'SET_DIRTY_MODO_CONFIG_PAGE_MODULES';

export const MODIFY_MODO_CONFIG_PAGE_MODULE_LOADING = 'MODIFY_MODO_CONFIG_PAGE_MODULE_LOADING';
export const MODIFY_MODO_CONFIG_PAGE_MODULE_SUCCESS = 'MODIFY_MODO_CONFIG_PAGE_MODULE_SUCCESS';
export const MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE = 'MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE';

export const UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING';
export const UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS';
export const UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE';

export const UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING';
export const UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS';
export const UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE';

export const DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING = 'DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_LOADING';
export const DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS = 'DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_SUCCESS';
export const DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE = 'DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE';

export const DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING = 'DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_LOADING';
export const DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS = 'DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_SUCCESS';
export const DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE = 'DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE';

export const REMOVE_MODO_CONFIG_PAGE_MODULE = 'REMOVE_MODO_CONFIG_PAGE_MODULE';

export const DELETE_MODO_CONFIG_PAGE_MODULE_LOADING = 'DELETE_MODO_CONFIG_PAGE_MODULE_LOADING';
export const DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS = 'DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS';
export const DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE = 'DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE';

/* Config Suggested Playlists */
export const SET_MODO_CONFIG_SUGGESTED_PLAYLISTS = 'SET_MODO_CONFIG_SUGGESTED_PLAYLISTS';

export const GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING = 'GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING';
export const GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS = 'GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS';
export const GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE = 'GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE';
export const MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_ERROR = 'MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_ERROR';

export const MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING = 'MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_LOADING';
export const MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS = 'MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_SUCCESS';
export const MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE = 'MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE';
export const MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_ERROR = 'MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_ERROR';

/* Config Suggested Albums */
export const SET_MODO_CONFIG_SUGGESTED_ALBUMS = 'SET_MODO_CONFIG_SUGGESTED_ALBUMS';

export const GET_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING = 'GET_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING';
export const GET_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS = 'GET_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS';
export const GET_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE = 'GET_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE';

export const MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING = 'MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_LOADING';
export const MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS = 'MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_SUCCESS';
export const MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE = 'MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE';

/* Modo Users */
export const SET_MODO_USERS = 'SET_MODO_USERS';

export const GET_MODO_USERS_LOADING = 'GET_MODO_USERS_LOADING';
export const GET_MODO_USERS_SUCCESS = 'GET_MODO_USERS_SUCCESS';
export const GET_MODO_USERS_FAILURE = 'GET_MODO_USERS_FAILURE';

export const REFRESH_MODO_USERS_LOADING = 'REFRESH_MODO_USERS_LOADING';
export const REFRESH_MODO_USERS_SUCCESS = 'REFRESH_MODO_USERS_SUCCESS';
export const REFRESH_MODO_USERS_FAILURE = 'REFRESH_MODO_USERS_FAILURE';

export const DELETE_MODO_USER_LOADING = 'DELETE_MODO_USER_LOADING';
export const DELETE_MODO_USER_SUCCESS = 'DELETE_MODO_USER_SUCCESS';
export const DELETE_MODO_USER_FAILURE = 'DELETE_MODO_USER_FAILURE';

export const MODIFY_MODO_USER_LOADING = 'MODIFY_MODO_USER_LOADING';
export const MODIFY_MODO_USER_SUCCESS = 'MODIFY_MODO_USER_SUCCESS';
export const MODIFY_MODO_USER_FAILURE = 'MODIFY_MODO_USER_FAILURE';

export const INVITE_MODO_USER_LOADING = 'INVITE_MODO_USER_LOADING';
export const INVITE_MODO_USER_SUCCESS = 'INVITE_MODO_USER_SUCCESS';
export const INVITE_MODO_USER_FAILURE = 'INVITE_MODO_USER_FAILURE';

/* Modo Users Export */
export const EXPORT_MODO_USERS_LOADING = 'EXPORT_MODO_USERS_LOADING';
export const EXPORT_MODO_USERS_SUCCESS = 'EXPORT_MODO_USERS_SUCCESS';
export const EXPORT_MODO_USERS_FAILURE = 'EXPORT_MODO_USERS_FAILURE';

/* Modo Users Import */
export const SET_MODO_USER_IMPORT_ID = 'SET_MODO_USER_IMPORT_ID';

export const CREATE_MODO_USER_IMPORT_LOADING = 'CREATE_MODO_USER_IMPORT_LOADING';
export const CREATE_MODO_USER_IMPORT_SUCCESS = 'CREATE_MODO_USER_IMPORT_SUCCESS';
export const CREATE_MODO_USER_IMPORT_FAILURE = 'CREATE_MODO_USER_IMPORT_FAILURE';

export const SET_MODO_USER_IMPORT_DATA = 'SET_MODO_USER_IMPORT_DATA';

export const UPLOAD_MODO_USER_IMPORT_PROGRESS = 'UPLOAD_MODO_USER_IMPORT_PROGRESS';
export const UPLOAD_MODO_USER_IMPORT_LOADING = 'UPLOAD_MODO_USER_IMPORT_LOADING';
export const UPLOAD_MODO_USER_IMPORT_SUCCESS = 'UPLOAD_MODO_USER_IMPORT_SUCCESS';
export const UPLOAD_MODO_USER_IMPORT_FAILURE = 'UPLOAD_MODO_USER_IMPORT_FAILURE';

export const MODIFY_MODO_USER_IMPORT_LOADING = 'MODIFY_MODO_USER_IMPORT_LOADING';
export const MODIFY_MODO_USER_IMPORT_SUCCESS = 'MODIFY_MODO_USER_IMPORT_SUCCESS';
export const MODIFY_MODO_USER_IMPORT_FAILURE = 'MODIFY_MODO_USER_IMPORT_FAILURE';

export const LAUNCH_MODO_USER_IMPORT_LOADING = 'LAUNCH_MODO_USER_IMPORT_LOADING';
export const LAUNCH_MODO_USER_IMPORT_SUCCESS = 'LAUNCH_MODO_USER_IMPORT_SUCCESS';
export const LAUNCH_MODO_USER_IMPORT_FAILURE = 'LAUNCH_MODO_USER_IMPORT_FAILURE';

export const RESET_MODO_USER_IMPORT = 'RESET_MODO_USER_IMPORT';
/* Stats */
export const GET_MODO_GLOBAL_USER_STATS_LOADING = 'GET_MODO_GLOBAL_USER_STATS_LOADING';
export const GET_MODO_GLOBAL_USER_STATS_SUCCESS = 'GET_MODO_GLOBAL_USER_STATS_SUCCESS';
export const GET_MODO_GLOBAL_USER_STATS_FAILURE = 'GET_MODO_GLOBAL_USER_STATS_FAILURE';
export const SET_MODO_GLOBAL_USER_STATS = 'SET_MODO_GLOBAL_USER_STATS';

export const GET_MODO_GLOBAL_PLAYS_STATS_LOADING = 'GET_MODO_GLOBAL_PLAYS_STATS_LOADING';
export const GET_MODO_GLOBAL_PLAYS_STATS_SUCCESS = 'GET_MODO_GLOBAL_PLAYS_STATS_SUCCESS';
export const GET_MODO_GLOBAL_PLAYS_STATS_FAILURE = 'GET_MODO_GLOBAL_PLAYS_STATS_FAILURE';
export const SET_MODO_GLOBAL_PLAYS_STATS = 'SET_MODO_GLOBAL_PLAYS_STATS';

export const GET_MODO_GLOBAL_DOWNLOADS_STATS_LOADING = 'GET_MODO_GLOBAL_DOWNLOADS_STATS_LOADING';
export const GET_MODO_GLOBAL_DOWNLOADS_STATS_SUCCESS = 'GET_MODO_GLOBAL_DOWNLOADS_STATS_SUCCESS';
export const GET_MODO_GLOBAL_DOWNLOADS_STATS_FAILURE = 'GET_MODO_GLOBAL_DOWNLOADS_STATS_FAILURE';
export const SET_MODO_GLOBAL_DOWNLOADS_STATS = 'SET_MODO_GLOBAL_DOWNLOADS_STATS';

export const GET_MODO_GLOBAL_SEARCH_STATS_LOADING = 'GET_MODO_GLOBAL_SEARCH_STATS_LOADING';
export const GET_MODO_GLOBAL_SEARCH_STATS_SUCCESS = 'GET_MODO_GLOBAL_SEARCH_STATS_SUCCESS';
export const GET_MODO_GLOBAL_SEARCH_STATS_FAILURE = 'GET_MODO_GLOBAL_SEARCH_STATS_FAILURE';
export const SET_MODO_GLOBAL_SEARCH_STATS = 'SET_MODO_GLOBAL_SEARCH_STATS';

export const GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_LOADING = 'GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_LOADING';
export const GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_SUCCESS = 'GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_SUCCESS';
export const GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_FAILURE = 'GET_MODO_GLOBAL_SEARCH_STRINGS_STATS_FAILURE';
export const SET_MODO_GLOBAL_SEARCH_STRINGS_STATS = 'SET_MODO_GLOBAL_SEARCH_STRINGS_STATS';

export const GET_MODO_GLOBAL_SEARCH_MAIA_STATS_LOADING = 'GET_MODO_GLOBAL_SEARCH_MAIA_STATS_LOADING';
export const GET_MODO_GLOBAL_SEARCH_MAIA_STATS_SUCCESS = 'GET_MODO_GLOBAL_SEARCH_MAIA_STATS_SUCCESS';
export const GET_MODO_GLOBAL_SEARCH_MAIA_STATS_FAILURE = 'GET_MODO_GLOBAL_SEARCH_MAIA_STATS_FAILURE';
export const SET_MODO_GLOBAL_SEARCH_MAIA_STATS = 'SET_MODO_GLOBAL_SEARCH_MAIA_STATS';

export const GET_MODO_GLOBAL_SEARCH_TAGS_STATS_LOADING = 'GET_MODO_GLOBAL_SEARCH_TAGS_STATS_LOADING';
export const GET_MODO_GLOBAL_SEARCH_TAGS_STATS_SUCCESS = 'GET_MODO_GLOBAL_SEARCH_TAGS_STATS_SUCCESS';
export const GET_MODO_GLOBAL_SEARCH_TAGS_STATS_FAILURE = 'GET_MODO_GLOBAL_SEARCH_TAGS_STATS_FAILURE';
export const SET_MODO_GLOBAL_SEARCH_TAGS_STATS = 'SET_MODO_GLOBAL_SEARCH_TAGS_STATS';
